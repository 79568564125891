import { createApi } from '@reduxjs/toolkit/query/react'
import { TrendingTeacher } from 'src/app/models'
import { BroadcastList, Response, Result, Value, BroadcastEntity, TeacherEntity } from '@dokyo/common'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { CourseEntity, SeriesEntity } from '@dokyo/common'
import { SeriesLessonEntity } from 'src/app/models/schedule'


const params = {
  broadcast_order: 1,
  page_size: 10
}


export interface LearnPage {
  list: any
  title: string;
  type: string;
  learn_type: number;
  broadcast_list: BroadcastEntity[];
  meeting_list: any;
  start_key: string;
}

export interface LearnPageResponse {
  learn_page: LearnPage[]
}


export interface LearnSearchResponse {
  course: { course_list:CourseEntity[]},
  broadcast: BroadcastEntity[],
  teacher: {teacher_list:TeacherEntity[]},
  // teacher: TeacherEntity[],
  series: {series_list:SeriesEntity[]},
  series_lesson: {series_lesson_list:SeriesLessonEntity[]},
}

export const learnPageApi = createApi({
  reducerPath: 'learnPageApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      // You can replace any with your type
      getLearnPage: builder.query<Result<LearnPageResponse>, {
        schoolId?: string,
        masterclass?: boolean,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean,
        broadcastOrder?: number,
        learnType?: string
      }>({
        query: ({ schoolId, masterclass, pageSize, startKey, forwardOrder, broadcastOrder, learnType }) => ({
          url: `getlearnpage`,
          method: 'POST',
          body: {
            school_id: schoolId,
            masterclass,
            page_size: pageSize ?? 10,
            start_key: startKey ?? '',
            forward_order: forwardOrder ?? false,
            request_user_id: startKey ?? '',
            broadcast_order: broadcastOrder ?? 1,
            learn_type: learnType ?? ''
          }
        }),
        //  You can define your response type
        transformResponse: (res: any) => new Value<LearnPageResponse>(res)
      }),
      getFreeLearnPage: builder.query<Result<LearnPageResponse>, {
        pageSize?: number,
        learnType?: string
      }>({
        query: ({ pageSize, learnType }) => ({
          url: `getlearnpage`,
          method: 'POST',
          body: {
            page_size: pageSize ?? 20,
            learn_type: learnType ?? ''
          }
        }),
        //  You can define your response type
        transformResponse: (res: any) => new Value<LearnPageResponse>(res)
      }),

      // You can replace any with your type
      getPopulateTeacherList: builder.query<Array<any>, null>({
        query: () => ({
          url: `getpopulateteacherlist`,
          method: 'POST',
          body: params
        }),
        //  You can define your response type
        transformResponse: (res: any) => {
          const list = res?.data.teacher_list
          return generatePopularTeacherList(list)
        }
      }),

      getBroadcastState: builder.mutation<Result<any>, { list: BroadcastList[] }>
        ({
          query: ({ list }) => ({
            //return {
            url: `getbroadcaststate`,
            method: 'POST',
            body: {
              broadcast_list: list
            }
            //}
          }),
          transformResponse: (res: Response<any>) => new Value<any>(res)
        }),
      getLearnSchedule: builder.query<Result<any>, {
        learnType: number,
        startTime: number,
        endTime: number,
        school_id?: string
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean
      }>({
        query: ({ learnType, school_id, startTime, endTime, pageSize, startKey, forwardOrder }) => ({
          url: `getlearnschedule`,
          method: 'POST',
          body: {
            learn_type: learnType,
            start_time: startTime,
            end_time: endTime,
            page_size: pageSize ?? 20,
            start_key: startKey ?? '',
            forward_order: forwardOrder ?? false,
            school_id: school_id
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      }),
      getSchoolResourceList: builder.query<Result<any>, {
        request_type: number,
        school_id?: string,
        start_key?: string,
        page_size?: number,
        forward_order?: boolean,
        is_all_school?: boolean,
        category_id?: string,
        excellent?: number,
        teacher_id?: string,
      }>({
        query: ({ request_type, category_id, school_id, page_size, start_key, forward_order, is_all_school, excellent, teacher_id }) => ({
          url: 'getschoolresourcelist',
          method: 'POST',
          body: {
            request_type: request_type,
            school_id: school_id,
            page_size: page_size || 10,
            start_key: start_key,
            forward_order: forward_order,
            is_all_school: is_all_school,
            category_id: category_id,
            excellent: excellent,
            teacher_id: teacher_id
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      }),
      getLearnSearch: builder.query<Result<LearnSearchResponse>, {
        searchContent: string,
        searchType?: number,
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean,
        time: string
      }>({
        query: ({ searchContent, searchType, startKey, pageSize, forwardOrder, time }) => ({
          url: 'getlearnsearch',
          method: 'POST',
          body: {
            search_content: searchContent,
            search_type: searchType ?? 0,
            page_size: pageSize ?? 20,
            start_key: startKey ?? '',
            forward_order: forwardOrder ?? false,
            time
          }
        }),
        transformResponse: (res: Response<LearnSearchResponse>) => new Value<LearnSearchResponse>(res)
      }),
      sendUserSearchRequest: builder.mutation<Result<any>, {
        categoryName: string,
        teacherName: string,
        note: string
      }>({
        query: ({ categoryName, teacherName, note }) => ({
          url: 'user',
          method: 'POST',
          body: {
            request_type:"create_user_demand",
            request_data: {
              category: categoryName,
              teacher: teacherName,
              notes: note
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      }),
      recordSearchKeyword: builder.query<Result<any>, {
        searchContent: string, time: number,
      }>({
        query: ({ searchContent, time }) => ({
          url: 'search_report',
          method: 'POST',
          body: {
            request_type:"record",
            request_data: {
              content: searchContent,
              time: time,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      })
    }
  }
})

export const {
  useGetLearnPageQuery,
  useGetPopulateTeacherListQuery,
  useGetBroadcastStateMutation,
  useGetLearnScheduleQuery,
  useLazyGetLearnScheduleQuery,
  useGetSchoolResourceListQuery,
  useLazyGetSchoolResourceListQuery,
  useGetLearnSearchQuery,
  useGetFreeLearnPageQuery,
  useSendUserSearchRequestMutation,
  useRecordSearchKeywordQuery,
} = learnPageApi

//Please put this code into a proper place
const generatePopularTeacherList = (list: any[]): TrendingTeacher[] => {
  const teachers: TrendingTeacher[] = []
  list.forEach(element => {
    const teacher: TrendingTeacher = {
      id: element.user_id,
      avatar: element.user_avatar,
      name: element.user_name,
      introduction: element.personalized_signature,
      courseCount: element.course_count,
      liveCount: element.live_count,
      broadcastCount: element.broadcast_count
    }
    teachers.push(teacher)
  })
  return teachers
}
