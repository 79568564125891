import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import * as crypto from 'crypto';

export const forgetPasswordApi = createApi({
  reducerPath: 'forgetPasswordApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      forgetPasswordSendVerifyCode: builder.mutation<Result<any>, { userId: string }>({
        query: ({ userId }) => ({
          url: `forgetpassword`,
          method: 'POST',
          body: { user_id: userId }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
     
      forgetPasswordResetPassword: builder.mutation<Result<any>, { 
          userId: string,
          verifyCode: string,
          confirmPassword: string,
          clientSalt,
        }>({
        query: ({ userId, verifyCode, confirmPassword, clientSalt  }) => ({
          url: `forgetpassword`,
          method: 'POST',
          body: { 
            user_id: userId,
            code: verifyCode,
            client_salt: clientSalt,
            password_hash: crypto.createHash('sha256').update(confirmPassword + clientSalt).digest('base64'),
            client_type: 3,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { useForgetPasswordSendVerifyCodeMutation, useForgetPasswordResetPasswordMutation } = forgetPasswordApi
