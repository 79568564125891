import React from 'react'
import { Box, Typography } from '@mui/material'
import { useGetLearnPageQuery } from 'src/app/service/real/learnpage-slice'
import { Loader } from 'src/components/loader'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import { ClassroomListCard } from 'src/components/cards/ClassroomListCard'
import { useNavigate } from 'react-router-dom'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ListType, getBroadcastData } from './BroadcastList'
import { EmptyList } from '../EmptyList'
import SvgComponent from 'src/components/svgComponent'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  courseList: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '30px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '22px'
  },
  viewMoreInner: {
    width: '160px',
    height: '36px',
    background: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #CCCCCC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))

const SectionTitle = styled(Typography)({
  fontSize: '30px',
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginLeft: '12px',
  marginRight: '20px'
})


export interface PropsBroadcastList {
  onClickMore: (type: ListType) => void
}

export const LiveNowAndUpComing: React.FC<PropsBroadcastList> =({ onClickMore }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = (id:string) => {
    navigate("/livebroadcast?id=" + id)
  }


  let liveNow: ClassroomListCardEntity[] = []
  let upcoming: ClassroomListCardEntity[] = []

  const { data: liveData, isFetching: liveIsFetching, refetch } = useGetLearnPageQuery({
    learnType:'live_now',
    pageSize: 6
  }, {
    refetchOnMountOrArgChange: 60
  })

  let liveNowHasMore = false
  let upComingHasMore = false

  if (liveData && !liveData.isFailed && liveData.result?.learn_page) {
    if (liveData.result.learn_page[0]?.broadcast_list) {
      liveNow = getBroadcastData(liveData.result.learn_page[0]?.broadcast_list)
    } else {
      liveNow = []
    }
    if (liveData.result.learn_page[0]?.start_key) {
      liveNowHasMore = true
    }
  }

  const { data: upcomingData , isFetching: upcomingIsFetching, refetch: upcomingRefetch } = useGetLearnPageQuery({
    learnType:'upcoming_live',
    pageSize: 6
  })

  if (upcomingData && !upcomingData.isFailed && upcomingData.result?.learn_page) {
    if (upcomingData.result.learn_page[0]?.broadcast_list) {
      upcoming = getBroadcastData(upcomingData.result.learn_page[0]?.broadcast_list)
    } else {
      upcoming = []
    }
    if (upcomingData.result.learn_page[0]?.start_key) {
      upComingHasMore = true
    }
  }

  const handleClickMore =(type: ListType) => {
    onClickMore(type)
  }

  const handleRefresh = () => {
    refetch()
    upcomingRefetch()
  }

  const LiveAndUpcoming = () => {
    return (
      <Box>
        <Box className={classes.title}>
          <SectionTitle>
            Live Now 
          </SectionTitle>
          <SvgComponent onClick={handleRefresh} iconName={"refreshIcon"} style={{ width: "21px", height: "21px", cursor: 'pointer' }}/>
        </Box>
        <Box className={classes.courseList}>
          {
            liveNow && liveNow.length > 0?
            liveNow.map((broadcast) => {
              return (
                <Box key={broadcast.id} onClick={() => {handleClick(broadcast.id || '')}}>
                  <ClassroomListCard
                  card={broadcast}
                  label={'Live'}
                  />
                </Box>
              )
            }):
            <EmptyList name={t("discover.empty.stream.live")} />
          }
        </Box>
        {
          liveNowHasMore?
          <Box className={classes.viewMore}>
            <Box onClick={()=> handleClickMore(ListType.LiveNow)} className={classes.viewMoreInner}>
              <Box>View More</Box> <KeyboardArrowDownIcon/>
            </Box>
          </Box>:
          ''
        }
        <SectionTitle sx={{
          mb: '20px'
        }}>
        Upcoming Live
        </SectionTitle>
        <Box className={classes.courseList}>
          {
            upcoming && upcoming.length > 0?
            upcoming.map((broadcast) => {
              return (
                <Box key={broadcast.id} onClick={() => {handleClick(broadcast.id || '')}}>
                  <ClassroomListCard
                  card={broadcast}
                  showLabel={false}
                  label={'Live'}
                  />
                </Box>
              )
            }):
            <EmptyList name={t("discover.empty.stream.upcoming")} />
          }
        </Box>
        {
          upComingHasMore?
          <Box className={classes.viewMore}>
            <Box onClick={()=> handleClickMore(ListType.UpComing)} className={classes.viewMoreInner}>
              <Box>View More</Box> <KeyboardArrowDownIcon/>
            </Box>
          </Box>:
          ''
        }
      </Box>    
    )
  }

  const isLoading = liveIsFetching || upcomingIsFetching
  return (
    <>
    <Loader isLoading={isLoading} />
    {
      isLoading?
      '':
      <LiveAndUpcoming />
    }
    </>
  )
}