import { FC } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';


const fontFamily = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    ].join(',');
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& 	.MuiSelect-icon': {
        color: "#FFFFFF",
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: '#5D5D5D',
        borderRadius: '6px',
        border: '1px solid #808080',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily
    },
}));

const BootstrapInputBlack = styled(InputBase)(({ theme }) => ({
    '& 	.MuiSelect-icon': {
        color: "#FFFFFF",
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        borderRadius: '6px',
        border: '1px solid #000',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily
    },
}));


const BootstrapInputInline = styled(InputBase)(({ theme }) => ({
    '& 	.MuiSelect-icon': {
        color: "#FFFFFF",
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily
    },
}));

interface Props {
    label: string;
    inputId: string;
    onChange: (e: SelectChangeEvent<string>) => void;
    deviceList: {value: string, label: string}[];
    value: string;
    showLabel?: boolean;
    inline?: boolean;
}

const useStyles = makeStyles(() => ({
    inlineContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            flexBasis: '50%'
        }
    }
}));


const SelectInput: FC<Props> = ({
    onChange,
    label,
    inputId,
    value,
    deviceList,
    showLabel = true,
    inline = false,
}) => {
    const classes = useStyles();
    const labelId = `${inputId}-selection-select`;
    const theme = useTheme();
    return (
        <Box
            className={inline? classes.inlineContainer: ''}
        >
            {
                showLabel && 
                <InputLabel 
                    sx={{
                        color: '#FFFFFF',
                        mb: inline? 0:'18px'
                    }}
                    id={labelId}
                >
                    {label}    
                </InputLabel>
            }
            <Select
                labelId={labelId}
                id={inputId}
                onChange={onChange}
                value={value}
                sx={{
                    color: '#FFFFFF',
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                        width: inline? '50%': '90%',
                    }
                }}
                input={
                    inline? <BootstrapInputInline/>:
                    (showLabel ?<BootstrapInput />: <BootstrapInputBlack />)}
            >
                {
                   deviceList.map(({ value, label })=> <MenuItem key={value} value={value}>{label}</MenuItem>) 
                }
            </Select>
        </Box>
    );
}


export default SelectInput;