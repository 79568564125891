import {
  Avatar,
  Box,
  Typography,
  List,
  Button,
} from '@mui/material'
import { Comment } from 'src/app/models'
import React from 'react'
import { getDisplayUserName, howLongAgo } from 'src/utils/stringUtil'
import SvgComponent from 'src/components/svgComponent'
import defaultUserAvatar from 'src/images/class/ic-user-avatar-default.png'
import { t } from 'i18next'
import ExpandableText from '../ExpandableText/ExpandableText'

interface Props {
  userId?: string
  commentList: Comment[]
  onClickThumb: (commentId: string) => void
  onClickDeleteComment: (commentId: string) => void
}

export const CommentList: React.FC<Props> = ({ userId, commentList, onClickThumb, onClickDeleteComment }) => {
  
  return (
    <List dense style={{marginTop: "20px"}}>
    {commentList.map((item, index) => {
    return (
      <Box key={index} style={{marginBottom: "30px"}}>
        <Box style={{display: "flex", alignItems: "center"}}>
          <Avatar
            alt={`Avatar n°${item.commentId}`}
            style={{width: "40px", height: "40px", marginRight: "10px"}}
            src={item.userAvatar || defaultUserAvatar}
          />
          <Typography
              component="span"
              style={{fontSize: "14px", color: "black", fontWeight: "bold", fontFamily: "Arial-BoldMT, Arial"}}
            >
            {getDisplayUserName(item.userName, item.userId)}
          </Typography> 

          <Typography
              style={{marginLeft: "12px", fontSize: "12px", color: "#555555", fontFamily: "ArialMT"}}
            >
            {howLongAgo(item.time)}
          </Typography>

        </Box>

        <ExpandableText text={item.content} textId={item.commentId}/>

        <Box style={{marginTop: "12px", display: "flex"}} >
          <Box
            style={{display: "flex", alignItems: "center", width: "fit-content", marginRight: "10px"}}
            onClick={()=>{onClickThumb(item.commentId)}}
          >
            <SvgComponent iconName={item.isThumb? "icoLikeSmallRed" : "icoLikeSmall"} className="icon-source" style={{marginTop: "5px", cursor: "pointer", width: "18px", height: "17px"}}/>
            
            <Typography
              style={{marginLeft: "5px", paddingTop: "5px", fontSize: "12px", fontFamily: "ArialMT", verticalAlign: "middle"}}
            >
            {item.thumbUpCount}
          </Typography>
          </Box>

          <Box hidden={userId !== item.userId}>
            <Button 
              variant='text' 
              color='info' 
              onClick={() => onClickDeleteComment(item.commentId)}
              size='small'
              sx={{
                textTransform: "none"
              }}
              >
              {t("class.btn-delete")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  })}
  </List>
  )
}
