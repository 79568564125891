import { FC } from 'react';
import { MeetingDataConfigs } from '@dokyo/common';
import {
    BackgroundBlurProvider,
    // BackgroundReplacementProvider,
    MeetingProvider,
  } from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';
import { MeetingStateProvider, useMeetingState } from './providers/MeetingStateProvider'
import DeviceSetup from './containers/deviceSetup';
import MeetingView from './containers/meetingView';
import { demoDarkTheme } from './theme/demoTheme';
import { MeetingTypeEnum } from 'src/pages/videoClassroom/dialog/MeetingDialog';
interface Props {
    meetingType: MeetingTypeEnum;
    queryMaterialId: string;
    meetingId: string;
    meetingDataConfigs: MeetingDataConfigs;
    joinTimestamp: number;
    onExitMeeting: () => void;
}

const Meeting: FC = () => {
    const { meetingStarted } = useMeetingState();

    // 先显示device setting 
    // 然后显示会议
    return (
        
        <BackgroundBlurProvider>
                {
                    meetingStarted?
                    <MeetingView />:
                    <DeviceSetup />
                }
        </BackgroundBlurProvider>
    )
}
export const MeetingComponent: FC<Props> = ({
    meetingType,
    queryMaterialId,
    meetingId,
    meetingDataConfigs,
    onExitMeeting,
    joinTimestamp
}) => (
        <MeetingProvider>
            <ThemeProvider theme={demoDarkTheme}>
                <MeetingStateProvider 
                    meetingType={meetingType} 
                    queryMaterialId={queryMaterialId} meetingId={meetingId} 
                    meetingDataConfigs={meetingDataConfigs} onExitMeeting={onExitMeeting}
                    joinTimestamp={joinTimestamp}
                >
                    <Meeting />
                </MeetingStateProvider>
            </ThemeProvider>
        </MeetingProvider>
    )


