import React, { useEffect } from 'react';
import { TextareaAutosize, Box, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  textAreaAutoBox: {
    width: '100% !important',
    resize: 'none',
    backgroundColor: '#F9F9F9 !important',
    border: '1px solid #f3f3f3 !important',
    borderRadius: '8px !important',
    fontFamily: 'Arial !important',
    fontSize: '15px ',
    padding: '10px !important',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Arial !important',
      fontWeight: '400 !important',
      fontSize: '12px !important',
      lineHeight: '14px !important',
      color: '#808080 !important',
      height:'80px !important',
    },
  }
}))

interface Props {
  placeHolder: string,
  value: string,
  onChange: (e: any) => void,
  maxCount: number,
  onClick?: () => void,
}

const AutoCountInputArea: React.FC<Props> = ({
  placeHolder, value, onChange, maxCount, onClick
}) => {

  const classes = useStyles();

  useEffect(() => {

  }, [value])

  return (
    <Box className={classes.root}>
      <TextareaAutosize
        className={classes.textAreaAutoBox}
        placeholder={placeHolder}
        value={value}
        onChange={onChange}
        onClick={onClick}
        maxLength={maxCount}
        minRows={3}
        maxRows={10}
      />
      <Typography style={{ position: "absolute", bottom: 10, right: 10, color: "#999999", fontSize: "14px" }}>
        {`${value?.length || 0} / ${maxCount}`}
      </Typography>
    </Box>
  )
}

export default AutoCountInputArea