import * as React from 'react'
import { useState, useEffect } from 'react'
import ClassroomMaterialCard from 'src/components/cards/ClassroomMaterialCard'
import { ClassroomMaterialCardEntity } from 'src/components/cards/ClassroomMaterialCard'
import { Box, Button, Grid, IconButton, Typography, Dialog, Avatar, DialogActions, DialogTitle, Divider, setRef, Theme, Switch } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import { Formik, Form, Field } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { 
    useUploadFileMutation, 
    useListSeriesMaterialsMutation, 
    useUploadSeriesMaterialsMutation,
    useDeleteSeriesMaterialsMutation, 
    useLockSeriesMaterialMutation
  } from 'src/app/service/real/teacher.slice'
import { useSnackbar } from 'notistack'
import { Loader } from 'src/components/loader'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/app/toolkit/store'
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { getAuthRole } from 'src/utils/auth';
import { getLegalFileName } from 'src/utils/stringUtil'
import { MaterialTypeEnum } from 'src/app/models/victoriaClass'

const useStyles = makeStyles((theme:Theme) => ({
    container: {
      width: "90%",
      maxWidth:'1400px',
      margin:'0 auto',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]:{
        maxWidth: '100%',
        padding: '0px 10px 0px 10px',
      }
    },
    group: {
        width: '100%',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexFlow:'wrap',
        marginTop:'50px'
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 30,
      paddingTop:'0px',
      width:'600px'
    },
    formItem: {
      marginBottom: '10px',
      marginTop:'10px',
      display:'flex',
      justifyContent:'left',
      alignItems:'center'
    },
    itemTitle: {
      width: 100,
      verticalAlign: "middle",
      display: "inline-block",
      color: "rgba(0, 0, 0, 0.8) !important",
      fontWeight:'bold'
    },
    inputField: {
      height: 44,
      width: 300,
    },
    imageContainer: {
      display: "inline-flex",
      borderWidth: "1px",
      '&:hover': {
        cursor:'pointer'
      }
    },
    image: {
      width: '100px!important',
      height: '100px!important',
      cursor:'pointer'
    },
    pickerButton: {
      position: "absolute",
      zIndex: 3,
      opacity: 0,
      width: 150,
      height: 40,
      '&:hover': {
        cursor:'pointer'
      }
    },
    pickerButton1: {
      position: "absolute",
      zIndex: 3,
      opacity: 0,
      width: 100,
      height: 100,
      '&:hover': {
        cursor:'pointer'
      }
    },
    select: {
      color:"rgb(239,0,69)",
      '&:hover': {
        cursor:'pointer'
      }
    }
})
)

interface ClassroomMaterialProps {
  isSeriesTeacher:boolean
  seriesID:string
}

const ClassroomMaterial: React.FC<ClassroomMaterialProps> = ({isSeriesTeacher, seriesID}) => {
    const classes = useStyles()
    const [open, setOpen] =useState(false)
    const [uploadFile] = useUploadFileMutation()
    const [listSeriesMaterials] = useListSeriesMaterialsMutation()
    const [uploadSeriesMaterials] = useUploadSeriesMaterialsMutation()
    const [deleteSeriesMaterials, {
      error: deleteSeriesMaterialsError
    } ] = useDeleteSeriesMaterialsMutation()
    const [lockMaterial] = useLockSeriesMaterialMutation();

    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { credential } = useSelector(state => state.app)
    const [apiCanDownload, setAPICanDownload ] = useState(false)
    const [isUploading, setIsUploading] = useState(false)

    const [title, setTitle] = useState('')
    const [locked, setLocked] = useState(false)

    const [materialUrl, setmaterialUrl] = useState('');
    const [materialFile, setmaterialFile] = useState();
    const [materialFileName, setmaterialFileName] = useState("");
    const [materialFileType, setmaterialFileType] = useState("");
    const [materialFileSize, setmaterialFileSize] = useState(0)
    
    const [materialAvatarUrl, setmaterialAvatarUrl] = useState('')
    const [materialAvatarFile, setmaterialAvatarFile] = useState();
    const [materialAvatarFileName, setmaterialAvatarFileName] = useState("");
    const [materialAvatarFileType, setmaterialAvatarFileType] = useState("image/png");

    const [start_keyArr, setStart_KeyArr] = useState<string[]>([])
    const [nextDisabled, setnextDisabled] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [materialList,setMaterialList] = useState<ClassroomMaterialCardEntity[]>()
    const abortController = React.useRef<AbortController|null>(null);
   
    const authRole = getAuthRole()();

    // const authRole = React.useMemo(() => {
    //   console.log('getAuthRole:')
    //   return getAuthRole()();
    // }, []);
    // console.log('authRole:', authRole)
    const formValues ={
      title:'',
    }

    useEffect(() => {
      console.log('deleteSeriesMaterialsError:', deleteSeriesMaterialsError);
    }, [deleteSeriesMaterialsError])
    
    useEffect(() => {
      if(seriesID) {
        loadMaterialList('')
      } 
    },[seriesID, credential])

    const loadMaterialList = async(start_key:string) => {
      setIsLoading(true)
      const  response = await listSeriesMaterials({series_id:seriesID, page_size:12, start_key:start_key}).unwrap()
      if(response && response.result){
        setMaterialList(response.result.series_materials_list)
        setAPICanDownload(response.result.download)
        if(response.result.start_key){
          setStart_KeyArr(() => start_keyArr ? start_keyArr.concat(response.result.start_key): [response.result.start_key] )
          setnextDisabled(false)
          setIsLoading(false)
        }else{
          setnextDisabled(true)
          setIsLoading(false)
        }
      }else {
        setIsLoading(false)
      }
    }

    const onfileChange = async (event, type:string) => {
        var fileObj = event.target.files[0];
        if (fileObj) {
          let url = '';
          if (window.URL != undefined) {
            url = window.URL.createObjectURL(fileObj);
          } else if (window.webkitURL != undefined) {
            url = window.webkitURL.createObjectURL(fileObj);
          }
          if(type === 'material') {
            setmaterialUrl(url)
            setmaterialFile(fileObj)
            setmaterialFileName(fileObj.name)
            setmaterialFileType(fileObj.type)
            setmaterialFileSize(fileObj.size)
          }else if(type === 'avatar') {
            setmaterialAvatarUrl(url)
            setmaterialAvatarFile(fileObj)
            setmaterialAvatarFileName(fileObj.name)
            setmaterialAvatarFileType(fileObj.type)
          }
        }
    };

    const submitData = async (values) => {
      setIsUploading(true)
      let public1 = ''
      let public2 = ''

      if(title === ''){
        setIsUploading(false)
        enqueueSnackbar('Title cannot be empty！')
        return
      }
      if(materialUrl === '') {
        enqueueSnackbar('Material File is required!')
        setIsUploading(false)
        return
      }
      if (materialAvatarFileName === materialFileName) {
        enqueueSnackbar('Do not choose the same file')
        setIsUploading(false)
        return
      }

      abortController.current = new AbortController();
      if(materialAvatarUrl !== '') {

        const formatAvatarName = getLegalFileName(materialAvatarFileName);
        const response:any = await uploadFile({file_name: formatAvatarName,file_type:materialAvatarFileType})
        if (response && response.data && !response.data.isFailed && response.data.result?.upload) {
          try{
            const response2 = await fetch(response?.data?.result?.upload,{
              method: "PUT",
              body: materialAvatarFile,
              headers: {
                  "Content-Type" : materialAvatarFileType
              },
              signal: abortController?.current?.signal
            });     
           if(response2.status === 200){
            public2 = response?.data.result.public
            }else {
              enqueueSnackbar('Upload Material Avatar failed!')
              setIsUploading(false)
              return
            }
          }catch(err:any){
            setIsUploading(false)
            if (err.name === 'AbortError') {
              enqueueSnackbar('Upload is aborted!')
              setIsUploading(false)
              return
            } else {
              throw err;
            }
          }       
        }else {
          enqueueSnackbar('Upload Material Avatar failed!')
          setIsUploading(false)
          return
        }  
      }else {
        public2 = 'default_avatar'
      }

      const formatFileName = getLegalFileName(materialFileName);
      const response: any = await uploadFile({file_name:formatFileName, file_type:materialFileType})
      if (response && response.data && !response.data.isFailed && response.data.result?.upload) {
        setIsUploading(true)
        try{
          const response2 = await fetch(response?.data.result.upload, {
            method: "PUT",
            body: materialFile,
            headers: {
                "Content-Type" : materialFileType
            },
            signal: abortController?.current?.signal
          });
          if(response2.status === 200){
          public1 = response?.data.result.public
          }else {
            enqueueSnackbar('Upload Material File failed!')        
            setIsUploading(false)
            return
          }
          setmaterialFileName('');
        }catch(err:any){
          setIsUploading(false)
          if (err.name === 'AbortError') {
            enqueueSnackbar('Upload is aborted!')
            setIsUploading(false)
            return
          } else {
            enqueueSnackbar('Upload Material File failed!') 
            throw err;
          }
        }      
      }else {
        enqueueSnackbar('Upload Material File failed!')
        setIsUploading(false)
        return
      }  

      if(public1 !== '' && public2 !== ''){
        uploadFileData(public1, public2, formatFileName)
        setIsUploading(false)
      }
    }

    const uploadFileData =async (materialurl:string, materialavatarurl: string, base64FileName: string) => {
      setIsLoading(true)
       const params = {
         file_title: title,
         file_name: base64FileName,
         file_type: materialFileType,
         file_size: materialFileSize,
         file_avatar: materialavatarurl === 'default_avatar' ? '' : materialavatarurl,
         file_url:materialurl,
         series_id: seriesID,
         lock: locked,
       }
       const response =await uploadSeriesMaterials(params).unwrap()
       if(response && !response.isFailed && response.result){
         console.log(response)
         enqueueSnackbar('Upload Successfully!')
         handleClear()
         setOpen(false)
         loadMaterialList('')
         setIsLoading(false)
       }else{
        enqueueSnackbar('Upload Failed!')
        setIsLoading(false)
       }
    }

    const handleDeleteMaterial = async (name:string): Promise<void> => {
      setIsLoading(true)
      try {
        const deleteResponse = await deleteSeriesMaterials({series_id : seriesID, file_name : name}).unwrap();
        if (deleteResponse?.isFailed === false) {
          enqueueSnackbar('Delete Successfully!');
          loadMaterialList('');
        } else {
          enqueueSnackbar('Delete failed! ' + deleteResponse.message);
        }
      } catch(error) {
        enqueueSnackbar('Delete failed!');
        console.log(error);
      }
      setIsLoading(false);
    }

    const handleLock = async (name:string, lock: boolean): Promise<void> => {
      setIsLoading(true)
      try {
        const response = await lockMaterial({series_id : seriesID, file_name : name, lock: lock}).unwrap();
        if (response?.isFailed === false) {
          enqueueSnackbar(lock ? 'Lock Successfully': 'Unlock Successfully!', {variant:'success'});
          // refresh list
          loadMaterialList(start_keyArr[currentPage]);
        } else {
          enqueueSnackbar('Operation failed! ' + response.message);
        }
      } catch(error) {
        enqueueSnackbar('Operation failed!');
        console.log(error);
      }
      setIsLoading(false);
    }


    const handleTitleChange = (event) => {
       setTitle(event.target.value)
    }

    const handleLockChanged = (event) => {
      setLocked(event.target.checked)
    }
  
    const handleClick = () => {
      if (!credential) {
        dispatch(showSignInUpDialog())
        return
      }
      setOpen(true)
    }

    const handleClickNextPage = () => {
      loadMaterialList(start_keyArr[currentPage-1])
      setCurrentPage(() => currentPage + 1 )
    }
  
    const handleClickLastPage = () => {
      loadMaterialList(start_keyArr[currentPage-3])
      setCurrentPage(() => currentPage - 1 )
    }

    const handleClear = () => {
      abortController?.current?.abort()
      setOpen(false)
      setCurrentPage(1)
      setmaterialAvatarUrl('')
      setTitle('')
      setmaterialUrl('')
      setmaterialFile(undefined)
      setmaterialFileName('')
      setIsUploading(false)
      setIsLoading(false)
    }

    const handleClickSignInButton = () => {
      if (!credential) {
        dispatch(showSignInUpDialog())
      }
    }

    const enableCurrentUserDownload = (file:ClassroomMaterialCardEntity) : boolean => {
      if (!file.file_url) {
        return false;
      }
      if (!apiCanDownload) {
        return false;
      }
      if (authRole.isAdmin) {
        return true;
      }
      if (isSeriesTeacher) {
        return true;
      }
      return !file.lock;
    }
  
    // if (credential) {
    return (
      <Box className={classes.container}>
       { isSeriesTeacher  && authRole.isTeacher && 
       <Box 
        style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
        }}>
            <IconButton 
              aria-label="upload picture" 
              component="span"
              onClick={handleClick}
              style={{
                textTransform:'none',
                color:'#FFFFFF',
                fontFamily: 'ArialMT',
                background:'#F93B57',
                fontSize:'16px',
                marginLeft:'30px',
                marginTop:'20px',
                border: '1px solid',
                borderRadius: '8px',
                width:'136px',
                height:'48px',
                letterSpacing: '-0.38588231801986694px',
                marginBottom:'15px',
              }}
            >
             Upload
            </IconButton>
         <Typography 
         style={{
           marginLeft:'20px',
           fontSize:'12px',
           fontFamily: 'ArialMT',
         }}
         >Support document, picture, audio and video formats</Typography>
         </Box>
         }
         <Grid 
              container
              spacing={5}
              justifyContent="left"
              alignItems="center"
            >
            <Box className={classes.group}>

         {
            materialList?.map((e) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} display="flex" justifyContent="center" key={Math.random()}>
                <Box sx={{marginBottom:'20px'}}>
                <ClassroomMaterialCard 
                    materialType={MaterialTypeEnum.Course}
                    card={e}
                    enableLock={isSeriesTeacher && authRole.isTeacher}
                    enableDelete={isSeriesTeacher && authRole.isTeacher}
                    handleDelete={handleDeleteMaterial}
                    handleLock={handleLock}
                    canDownload={enableCurrentUserDownload(e)}
                />
                </Box>
              </Grid>
            ))
          }
          </Box>
          </Grid>
          {materialList?.length !==0 &&  materialList !== undefined ?
            <Box
            style={{
              marginTop: '20px',
              marginBottom: '30px',
              color: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="text"
              color="secondary"
              onClick={()=>handleClickLastPage()}
              disabled={currentPage === 1}
            >
                &lt;
            </Button>
            {currentPage}
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleClickNextPage()}
              disabled={nextDisabled}
            >
              &gt;
            </Button>
          </Box>
           :
           <Box
           sx={{
             position: 'relative',
             top: '100px',
             height: '400px',
             textAlign: 'center',
           }}
         >
           <FilePresentIcon style={{color:"#aaaaaa", width:"60px", height:"60px"}}/>
           <Typography
             sx={{
               fontSize: '18px',
               fontFamily: 'ArialMT',
               color: 'rgba(0, 0, 0, 1)',
               lineHeight: '21px',
               overflow: 'hidden',
               textOverflow: 'ellipsis',
               margin:'10px 20px'
             }}
           >There are no Classroom Material in this course yet.</Typography>
         </Box>
          }
      <Dialog
        open={open}
        maxWidth={'md'}
      >
        <DialogTitle id="alert-dialog-title" style={{fontSize:'16px',fontWeight:'bold',fontFamily:'ArialMT'}}>
          {"Upload Classroom Material"}
          <Divider style={{marginTop:'15px'}}></Divider>
        </DialogTitle>
        <Formik
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          submitData(values);
          setSubmitting(false);
        }}
        initialValues={formValues}
      >
        {({submitForm, isSubmitting}) => (
          <>
            <Form>
              <Box className={classes.formContainer}>
  
              <Box className={classes.formItem}>
                <Typography variant="subtitle2" className={classes.itemTitle}>
                  Title
                  </Typography>
                <input
                  disabled={isUploading}
                  name="title"
                  id="title"
                  style={{
                    width:'300px',
                    height: '40px',
                    borderRadius: '7px',
                    border: '1px solid rgb(183,183,183)',
                    paddingLeft:'10px',
                    paddingRight:'10px'
                  }}
                  value={title}
                  onChange={(e) => handleTitleChange(e)}
                  />
              </Box>
  
              <Box className={classes.formItem}>
                <Typography variant="subtitle2" className={classes.itemTitle}>
                  File Avatar
                </Typography>
                <Box className={classes.imageContainer}>
                  <Avatar variant="square" alt="" src={materialAvatarUrl} className={classes.image} >
                    <AddIcon style={{width:'100px',height:'100px'}}/>
                  </Avatar>
                  <Field >
                    {() => {
                      return (
                        <>
                          <input
                            disabled={isUploading}
                            id="input-logo"
                            title="Change Logo picture"
                            onChange={(e) => onfileChange(e, 'avatar')}
                            type="file"
                            accept="image/*"
                            className={classes.pickerButton1}
                          />
                        </>
                      );
                    }}
                  </Field>
                </Box>
              </Box>

              <Box className={classes.formItem}>
                <Typography variant="subtitle2" className={classes.itemTitle}>
                  File
                </Typography>
                <Box className={classes.imageContainer}>
                  <Typography className={classes.select}>
                     {materialFileName || 'Select file'}
                     <span 
                     style={{
                        color:'black',
                        fontSize:'12px'
                     }}>
                       (The same file name will be overwritten)</span>
                  </Typography>
                  <Field >
                    {() => {
                      return (
                        <>
                          <input
                            disabled={isUploading}
                            id="input-banner"
                            title="Change Banner picture"
                            onChange={(e) => onfileChange(e, 'material')}
                            type="file"
                            className={classes.pickerButton}
                          />
                        </>
                      );
                    }}
                  </Field>
                </Box>

              </Box>

              <Box className={classes.formItem}>
                <Typography variant="subtitle2" className={classes.itemTitle}>
                  Lock
                </Typography>
                <Switch color='primary' checked={locked} onChange={handleLockChanged}/>
              </Box>
              </Box>           
            </Form>
          </>
        )}
      </Formik>
      <Divider style={{marginLeft:'30px',width:'540px'}}></Divider>
         {
                isUploading?
                <LoadingButton
                size="small"
                color="secondary"
                loading={true}
                variant="text"
              >
                uploading
              </LoadingButton>
              : ""
            }
        <DialogActions>
          <Button onClick={(e) => handleClear()} style={{color:'#555555', textTransform:'none'}}>Cancel</Button>
          <Button onClick={submitData} style={{textTransform:'none',marginRight:'15px'}} autoFocus disabled={isUploading}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Loader isLoading={isLoading}></Loader> 
    </Box>
    )} 

    // else return (
    //   <Box
    //     sx={{
    //       height: '300px',
    //       textAlign: 'center',
    //     }}
    //   >
        
    //     <Button 
    //       onClick={handleClickSignInButton}
    //       sx={{
    //         width:'136px',
    //         height: '48px', 
    //         textTransform: "none", 
    //         backgroundColor: '#F93B57',  
    //         borderRadius: '8px',
    //         fontFamily: 'ArialMT',
    //         boxShadow: 'none',
    //         '&:hover': {
    //           boxShadow: 'none',
    //         },
    //         fontSize: '18px',
    //         marginTop: '100px'
    //       }}
    //       variant="contained">Sign In
    //     </Button>
    //     <Typography
    //       sx={{
    //         fontSize: '14px',
    //         fontFamily: 'ArialMT',
    //         color: '#555555',
    //         lineHeight: '16px',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //         marginTop:'10px'
    //       }}
    //     >Please sign in to get materials</Typography>
    //   </Box> 
    // )
// }

export default ClassroomMaterial;
