import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'

export const couponApi = createApi({
    reducerPath: 'coupon-api',
    baseQuery: baseQueryWithReauth,
    endpoints(builder) {
        return {
            getCouponList: builder.mutation<Result<any>, {
                request_type?: string,
                start_key?: string,
                page_size?: number,

            }>({
                query: ({ request_type, start_key, page_size }) => ({
                    url: `coupon`,
                    method: 'POST',
                    body: {
                        request_type: request_type || "All",
                        start_key: start_key || "",
                        page_size: page_size ?? 10,
                    }
                }),
                transformResponse: (res: Response<any>) => new Value<any>(res)
            }),
            
            getUsableCouponList: builder.mutation<Result<any>, {
                class_type: string, //private_lesson, group_lesson, series_lesson
                teacher_id: string,
                start_key?: string,
                page_size?: number,
            }>({
                query: ({ class_type, teacher_id, start_key, page_size }) => ({
                    url: `coupon`,
                    method: 'POST',
                    body: {
                        request_type: "Usable",
                        class_type: class_type,
                        teacher_id: teacher_id,
                        start_key: start_key || "",
                        page_size: page_size ?? 10,
                    }
                }),
                transformResponse: (res: Response<any>) => new Value<any>(res)
            })
        }
    }
})


export const { useGetCouponListMutation, useGetUsableCouponListMutation } = couponApi