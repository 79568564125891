import  Axios, { AxiosError, AxiosResponse } from 'axios';
import { apiWrapper, errorNotificationWith } from './api';

export interface LoginUserResponse {
    data: any;
    message: string;
    result: {
      message: string;
      result_code: number;
    }
}

export const signInRefreshToken = async (userId: string, refreshToken: string): Promise<AxiosResponse<LoginUserResponse> | void> => {
    console.log("start to refresh")
    const success = async (): Promise<AxiosResponse<LoginUserResponse>> => {
        const response = await Axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
          }).post<LoginUserResponse>('/signin', {
            user_id: userId,
            refresh_token: refreshToken,
            client_type: 3,
        });
        return response
    };

    const error = (e: AxiosError): AxiosResponse | void => {
        if (e.response?.status === 401) {
            e.response.data.message = 'Invalid user ID or token expired';
            return e.response;
        }
        if (e.response?.status === 403) {
            e.response.data.message =
            'This account is deactivated. Please contact the Veeko support';
            return e.response;
        }
        errorNotificationWith({
            message: e.response?.statusText || e.message,
        });
        return undefined;
    };

    return apiWrapper<LoginUserResponse>({
        success,
        error,
    });
};
