import React, { useState } from 'react'
import { 
  Avatar,
  Box,
  IconButton,
  Theme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ReactPlayer from 'react-player'
import IconPlayVideo from 'src/images/teacherLanding/icon-play-video.png';

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    width: "100%",
    height: "100%",
    margin: "0 auto",
  },
  video: {
    border: "none",
  },
  videoInfoContainer: {
    width: '100% !important',
    height: "inherit",
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      width: '50% !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    }
  },
  videoBGImage: {
    width: "100% !important", 
    height: "100% !important",
    objectPosition: "left",
    objectFit: "cover",
    borderRadius: "10px !important"
  },  
  videoInfoBox: {
    width: "100% !important", 
    textAlign:"center", 
    position: "absolute",
  },
  btnPlay:{
    background: "white", 
    width: "48px !important", 
    height: "48px !important",
    border: '1px solid white',
    [theme.breakpoints.down('sm')] : {
      borderRadius: "20px !important",
      width: "40px !important", 
      height: "40px !important",
    }
  }
}))

interface Props {
  videoUrl: string;
  imageUrl: string;
}

const MaskCoverPlayer: React.FC<Props> = ({ videoUrl, imageUrl }) => {
  const classes = useStyles();

  const [playUrl, setPlayUrl] = useState("");
  const [play, setPlay] = useState(false);

  React.useEffect(()=> {
    setPlayUrl(videoUrl);
  },[videoUrl])

  const handleClickPlay = () => {
    setPlay(true);
  }

  return (
    <Box>
      {play ?  
        <Box className={classes.container}>
          <ReactPlayer 
            url={playUrl}
            playing={play}
            muted={false} 
            width={"100%"}
            height={"auto"}
            controls
            className={classes.video} 
            // Disable download button
            config={{ file: { attributes: { controlsList: 'nodownload' }}}}
            // Disable right click
            onContextMenu={e => e.preventDefault()}
            playsinline={true}
          />
        </Box>
      : 
        <Box className={classes.container}>
          <Box className={classes.videoInfoContainer} sx={{justifyContent: "center"}}>
            <img alt="thumbnail" src={imageUrl} className={classes.videoBGImage}/>
            <Box
              sx={{
                width:'100%',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                background: '#000000',
                opacity: '0.3',
                borderRadius: '10px',
            }}/>
            <Box 
              className={classes.videoInfoBox}>
              <IconButton onClick={handleClickPlay}>
                <Avatar src={IconPlayVideo} className={classes.btnPlay}/>
              </IconButton>  
              
            </Box>
            
          </Box>
        </Box>
      }
        
    </Box>
  )
}
export default MaskCoverPlayer;