import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, TeacherEntity, Value } from '@dokyo/common'


export interface MasterTeacherResult {
  master_teacher: {
    title: string;
    type: string;
    teacher_list: TeacherEntity[];
  }
}

export interface TeacherListResult {
  teacher_list: TeacherEntity[],
  start_key: string
}

export const getTeacherInfoApi = createApi({
  reducerPath: 'teacher-api',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getMasterTeacherPage: builder.query<Result<MasterTeacherResult>, {
        categoryId? : string
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean
      }>({
      query: ({categoryId, startKey, pageSize, forwardOrder }) => ({
        url: `getmasterteacherpage`,
        method: 'POST',
        body: {
          category_id: categoryId?? '',
          start_key: startKey ?? '',
          page_size: pageSize ?? 10,
          forward_order: forwardOrder ?? false
          }
        }),
        transformResponse: (res: Response<MasterTeacherResult>) => new Value<MasterTeacherResult>(res)
      }),
      getPopulateTeacherList: builder.query<Result<TeacherListResult>, {
        categoryId? : string,
        teacherName?: string,
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean,
        is_all?:boolean,
        is_filter?:boolean,
        filterNumber?: number,
        privateLesson?: boolean
      }>({
      query: ({categoryId, teacherName, startKey, pageSize, forwardOrder, is_all ,is_filter, filterNumber, privateLesson}) => ({
        url: `getpopulateteacherlist`,
        method: 'POST',
        body: {
          category_id: categoryId?? '',
          teacher_name: teacherName?? '',
          start_key: startKey ?? '',
          page_size: pageSize ?? 10,
          forward_order: forwardOrder ?? false,
          is_all:is_all??'',
          is_filter:is_filter??'',
          filter_number: filterNumber,
          private_lesson: privateLesson
          }
        }),
        transformResponse: (res: Response<TeacherListResult>) => new Value<TeacherListResult>(res)
      }),
      getTeacherLiveList: builder.mutation<Result<any>, {
        user_id: string
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean,
        isVideobroadcast?: boolean,
      }>({
        query: ({user_id, startKey, pageSize, forwardOrder, isVideobroadcast }) => ({
          url: `getbroadcastlist`,
          method: 'POST',
          body: {
            user_id: user_id,
            start_key: startKey ?? '',
            page_size: pageSize ?? 10,
            forward_order: forwardOrder ?? false,
            videobroadcast: isVideobroadcast,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getUserUpcomingNowLiveList: builder.query<Result<any>, {
        user_id: string
        liveNow?: boolean,
        upcoming?: boolean,
        ended?: boolean,
      }>({
        query: ({user_id, liveNow, upcoming, ended }) => ({
          url: `getuserbroadcastlist`,
          method: 'POST',
          body: {
            user_id: user_id,
            live_now: liveNow,
            upcoming: upcoming,
            ended: ended,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getTeacherUpcomingInfoList: builder.mutation<Result<any>, {
        userID: string
      }>({
        query: ({userID }) => ({
          url: `getteacherliveinfo`,
          method: 'POST',
          body: {
            user_id: userID,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      uploadFile: builder.mutation<Result<any>, {
        file_name: string
        file_type: string,
      }>({
        query: ({file_name, file_type }) => ({
          url: `uploadfile`,
          method: 'POST',
          body: {
            file_name: file_name,
            file_type: file_type,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      uploadSeriesMaterials: builder.mutation<Result<any>, {
        file_name: string,
        file_title:string,
        file_type: string,
        file_size: number,
        file_url: string,
        file_avatar:string,
        series_id:string,
        lock: boolean,
      }>({
        query: ({file_name, file_type, file_size, file_url, file_avatar, series_id, file_title, lock }) => ({
          url: `seriesmaterials`,
          method: 'POST',
          body: {
            file_name: file_name,
            file_type: file_type,
            file_size: file_size,
            file_url: file_url,
            file_avatar:file_avatar,
            file_title:file_title,
            type:1,
            series_id:series_id,
            lock: lock,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      deleteSeriesMaterials: builder.mutation<Result<any>, {
        series_id:string,
        file_name:string,
      }>({
        query: ({ series_id, file_name }) => ({
          url: `seriesmaterials`,
          method: 'POST',
          body: {
            type:3,
            series_id:series_id,
            file_name:file_name,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      lockSeriesMaterial: builder.mutation<Result<any>, {
        series_id:string,
        file_name:string,
        lock: boolean,
      }>({
        query: ({ series_id, file_name, lock }) => ({
          url: `seriesmaterials`,
          method: 'POST',
          body: {
            type:4,
            series_id:series_id,
            file_name:file_name,
            lock: lock,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      ListSeriesMaterials: builder.mutation<Result<any>, {
        series_id:string,
        page_size:number,
        start_key:string
      }>({
        query: ({ series_id, start_key, page_size }) => ({
          url: `seriesmaterials`,
          method: 'POST',
          body: {
            type:2,
            series_id:series_id,
            start_key:start_key,
            page_size:page_size,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      LockSeriesMaterials: builder.mutation<Result<any>, {
        series_id: string,
        file_name: string,
        lock: boolean
      }>({
        query: ({ series_id, file_name, lock }) => ({
          url: `seriesmaterials`,
          method: 'POST',
          body: {
            type:4,
            series_id, 
            file_name, 
            lock
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getSeriesByTeacher: builder.query<Result<any>, {
        teacher_id:string,
        page_size?:number,
        start_key?:string, 
        forward_order?:boolean,
      }>({
        query: ({ teacher_id, start_key, page_size, forward_order }) => ({
          url: `getseriesbyteacher`,
          method: 'POST',
          body: {
            teacher_id: teacher_id,
            start_key: start_key,
            page_size: page_size,
            forward_order: forward_order,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),   

      getTeacherAllScheduledClass: builder.mutation<Result<any>, {
        request_type?:string,
        teacher_id:string,
        start_time: number,
        end_time: number, 
        page_size?:number,
        start_key?:string
      }>({
        query: ({ request_type, teacher_id, start_time, end_time, start_key, page_size }) => ({
          url: `getteacherschedule`,
          method: 'POST',
          body: {
            request_type: request_type,
            teacher_id: teacher_id,
            start_time: start_time,
            end_time: end_time,
            start_key: start_key,
            page_size: page_size,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),   
      
    }
  }
})


export const { 
  useGetMasterTeacherPageQuery, 
  useGetPopulateTeacherListQuery, 
  useGetTeacherLiveListMutation, 
  useGetUserUpcomingNowLiveListQuery,
  useGetTeacherUpcomingInfoListMutation,
  useUploadFileMutation, 
  useDeleteSeriesMaterialsMutation, 
  useLockSeriesMaterialMutation,
  useListSeriesMaterialsMutation ,
  useUploadSeriesMaterialsMutation,
  useGetSeriesByTeacherQuery,
  useGetTeacherAllScheduledClassMutation,
  useLockSeriesMaterialsMutation,
} = getTeacherInfoApi