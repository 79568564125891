import * as React from "react";
import { Button, Divider, Box, Avatar, Tooltip  } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import MeetingScreen from '../chimeMeeting/MeetingScreen'
import { MeetingDataConfigs, createEmptyMeetingDataConfigs } from "@dokyo/common";
import { Loader } from 'src/components/loader'
import { useSnackbar } from "notistack";
import WaitingRoom from "../chimeMeeting/WaitingRoom";
import { isAppleMobileDevice, isMobileDevice } from "src/utils/deviceUtil";
import { useSelector } from "src/app/toolkit/store";
import PersonIcon from '@mui/icons-material/Person';
import IconMute from 'src/images/meeting/ic_mute.svg'
import IconVoice from 'src/images/meeting/ic_voice.svg'
import IconVideoOn from 'src/images/meeting/ico-video-on.svg'
import IconVideoOff from 'src/images/meeting/ico-video-off.svg'
import IconQuit from 'src/images/meeting/ic_quit.svg'
import { ErrorCode } from "src/utils/constants";
import { useTranslation } from "react-i18next";
import { useJoinMeetingMutation } from "src/app/service/real/videoclassroom-slice";
import { useJoinSeriesLessonMeetingRoomMutation } from "src/app/service/real/serieslesson-slice";
// import { MeetingContainer } from "../chimeMeeting2"
import MeetingComponent from "../../../components/meeting";
import { useTheme } from '@material-ui/core/styles';

export enum MeetingTypeEnum {
  LiveVideoClass = "live_video_class",
  PrivateLesson = "private_lesson",
  SeriesLesson = "series_lesson",
}

interface PropsForm {
  meetingType: MeetingTypeEnum
  queryMaterialId: string
  tryCount: number
  meetingId: string
  teacherId: string
  secondTeachers: string[]
  showMeeting: boolean  
  onExitMeeting: (message?: string) => void
  showGrid?: boolean
}

const MeetingDialog: React.FC<PropsForm> = (props) => {
  const { meetingType, queryMaterialId, tryCount, meetingId, teacherId, secondTeachers, onExitMeeting, showMeeting, showGrid } = props;
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const {credential} = useSelector(state => state.app);
  const [meetingDataConfigs, setMeetingDataConfigs] = React.useState<MeetingDataConfigs>(createEmptyMeetingDataConfigs());
  const [joinMeeting, {isLoading: loading2}] = useJoinMeetingMutation();
  const [joinSeriesLessonMeetingRoom, {isLoading: loading3}] = useJoinSeriesLessonMeetingRoomMutation();

  const [dialogState, setDialogState] = React.useState(0);//0-not start; 1-waiting; 2-started
  const [count, setCount] = React.useState<number>(0);
  const [showConfirmExitDialog, setShowConfirmExitDialog] = React.useState(false);
  const [promptDialog, setPromptDialog] = React.useState({showDialog: false, message:""});
  const [joinTimestamp, setJoinTimestamp] = React.useState(0);
  const theme = useTheme();

  const COUNT_MAX = 50;

  React.useEffect(() => {
    tryJoinMeeting();
    setJoinTimestamp(new Date().getTime());
  }, [meetingId, showMeeting, tryCount])

  // React.useEffect(() => {
  //   setUserMediaPreview(previewConfig.videoOn);
  // }, [previewConfig.videoOn])

  React.useEffect(() => {
    if (dialogState === 1) {
      if (count < COUNT_MAX) {
        tryJoinMeeting();
      } else {
        setShowConfirmExitDialog(true);
      }
    }
  }, [dialogState, count])



  const tryJoinMeeting = () => {
    if (!meetingId || !showMeeting) {
      console.log("No Classroom ID")
      return
    }
    if (!credential) {
      return
    }
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      joinMeeting({meetingId: meetingId}).unwrap().then(res=> {
        if (!res?.isFailed) {
          const {attendee, meeting_info, meeting_title, 
            meeting_audio_muted, meeting_video_muted, meeting_chat_closed,
            meeting_transcription_muted, transcription_language, meeting_record, record_start, topping_user, forbid_share,
            meeting_layout
          } = res?.result;
          setMeetingDataConfigs({
            teacherId: teacherId,
            secondTeachers: secondTeachers,
            meetingTitle: meeting_title,
            meetingInfo: {
              MeetingId: meeting_info.chime_meeting_id,
              ExternalMeetingId: meeting_info.external_meeting_id,
              MediaPlacement: {
                AudioHostUrl: meeting_info.audio_host_url,
                AudioFallbackUrl: meeting_info.audio_fallback_url,
                ScreenDataUrl: meeting_info.screen_data_url,
                ScreenSharingUrl: meeting_info.screen_sharing_url,
                ScreenViewingUrl: meeting_info.screen_viewing_url,
                SignalingUrl: meeting_info.signaling_url,
                TurnControlUrl: meeting_info.turn_control_url,
                EventIngestionUrl: "",
              },
              MediaRegion: meeting_info.media_region,
            },
            attendeeInfo: {
              ExternalUserId: attendee.external_user_id,
              AttendeeId: attendee.attendee_id,
              JoinToken: attendee.join_token,
            },
            meetingControls: {
              muteAll: meeting_audio_muted,
              closeAllVideo: meeting_video_muted,
              closeChat: meeting_chat_closed,          
              closeTransition: meeting_transcription_muted,
              transitionLanguage: transcription_language,
              meetingRecord: meeting_record,
              recordStart: record_start,
              toppingUser: topping_user,
              forbidShare: forbid_share,
              layout: meeting_layout,
            },
          })
  
          setDialogState(2);
          setJoinTimestamp(new Date().getTime());
        } else if (res?.resultCode === 201606) {
          setDialogState(1);
          setTimeout(() => { 
            setCount(count+1);
          }, 10 * 1000)
        } else {
          handleExit(res?.message);
        }
      })
      .catch(error => {
        handleExit("join class failed");
      })
    } else if (meetingType === MeetingTypeEnum.SeriesLesson || meetingType === MeetingTypeEnum.PrivateLesson) {
      joinSeriesLessonMeeting();
    }
  }

  const joinSeriesLessonMeeting = () => {
    joinSeriesLessonMeetingRoom({classroomId: meetingId}).unwrap().then(res=> {
      if (!res?.isFailed) {
        const {attendee_info, meeting_info, meeting_room } = res?.result;
        setMeetingDataConfigs({
          teacherId: teacherId,
          secondTeachers: secondTeachers,
          meetingTitle: '',
          meetingInfo: {
            MeetingId: meeting_info.chime_meeting_id,
            ExternalMeetingId: meeting_info.external_meeting_id,
            MediaPlacement: {
              AudioHostUrl: meeting_info.audio_host_url,
              AudioFallbackUrl: meeting_info.audio_fallback_url,
              ScreenDataUrl: meeting_info.screen_data_url,
              ScreenSharingUrl: meeting_info.screen_sharing_url,
              ScreenViewingUrl: meeting_info.screen_viewing_url,
              SignalingUrl: meeting_info.signaling_url,
              TurnControlUrl: meeting_info.turn_control_url,
              EventIngestionUrl: "",
            },
            MediaRegion: meeting_info.media_region,
          },
          attendeeInfo: {
            ExternalUserId: attendee_info.external_user_id,
            AttendeeId: attendee_info.attendee_id,
            JoinToken: attendee_info.join_token,
          },
          meetingControls: {
            muteAll: meeting_room.meeting_audio_muted,
            closeAllVideo: meeting_room.meeting_video_muted,
            closeChat: meeting_room.meeting_chat_closed,          
            closeTransition: meeting_room.meeting_transcription_muted,
            transitionLanguage: meeting_room.transcription_language,
            meetingRecord: true,  // series lesson and private lesson always do record
            recordStart: meeting_room.record_start,
            toppingUser: meeting_room.meeting_topping_user,
            forbidShare: meeting_room.forbid_share,
            layout: 0,
          },
        })

        setDialogState(2);
        setJoinTimestamp(new Date().getTime());
      } else if (res?.resultCode === ErrorCode.PRIVATE_LESSON_WAITING_START) {
        setDialogState(1);
        setTimeout(() => { 
          setCount(count+1);
        }, 10 * 1000)
      } else if (res?.resultCode === ErrorCode.PRIVATE_LESSON_NOT_STARTED) {
        enqueueSnackbar(t('class.not-start-time-yet'), {variant:'info'})
      } else {
        handleExit(res?.message);
      }
    })
    .catch(error => {
      handleExit("join class failed");
    })
  }

  const handleExit = (message?: string) => {
    setDialogState(0);
    setCount(0);
    onExitMeeting(message);
  }

  const handleClickCancelWaiting = () => {
    setShowConfirmExitDialog(false);
    handleExit();
  }

  const handleClickContinueWaiting = () => {
    setShowConfirmExitDialog(false);
    setCount(0);
  }

  if(showMeeting) {
    return (
      <>
        <Dialog
          PaperProps={{style: { backgroundColor: '#252525', boxShadow: 'none'}}}
          BackdropProps={{style: {backgroundColor: '#ffffffcc'}}}
          fullScreen
          open={dialogState === 1}
          >
            
          <DialogContent>            
            <WaitingRoom onExitWaiting={() => handleExit()}/>
          </DialogContent>
        </Dialog>
  
        <Dialog
          fullWidth={true}
          open={dialogState === 1 && showConfirmExitDialog}
          onClose={()=>{setShowConfirmExitDialog(false)}}
        >
          <DialogTitle id="alert-dialog-title">{"This classroom is not started, keep waiting?"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClickCancelWaiting} color="info" style={{textTransform: "none"}}>
              No
            </Button>
            <Button onClick={handleClickContinueWaiting} color="secondary" autoFocus style={{textTransform: "none"}}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        

        <Dialog
          disableEscapeKeyDown
          PaperProps={{style: { backgroundColor: '#252525', boxShadow: 'none'}}}
          BackdropProps={{style: {backgroundColor: '#ffffffcc'}}}
          fullScreen
          open={dialogState === 2}
          >
            
          <Box
            sx={{
              minHeight: '100%',
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                
              }
            }}
          >
            <MeetingComponent 
                meetingType={meetingType} 
                queryMaterialId={queryMaterialId} 
                joinTimestamp={joinTimestamp}
                meetingId={meetingId} 
                meetingDataConfigs={meetingDataConfigs} 
                onExitMeeting={() => handleExit()} 
              />
            {/* { showGrid? 
              <MeetingComponent 
                meetingType={meetingType} 
                queryMaterialId={queryMaterialId} 
                joinTimestamp={joinTimestamp}
                meetingId={meetingId} 
                meetingDataConfigs={meetingDataConfigs} 
                onExitMeeting={() => handleExit()} 
              />:
              <MeetingScreen
                meetingType={meetingType}
                queryMaterialId={queryMaterialId}
                joinTimestamp={joinTimestamp}
                meetingId={meetingId}
                meetingDataConfigs={meetingDataConfigs}
                onExitMeeting={() => handleExit()}
              />  
            } */}
          </Box>
        </Dialog>


        <Dialog
          maxWidth="lg"
          open={promptDialog.showDialog}
        >
          <DialogTitle id="alert-dialog-title">Prompt</DialogTitle>
          <DialogContent>
            <Divider/>
            <Box style={{fontSize: "14px", fontFamily: "ArialMT", marginTop:"20px"}}>
              {promptDialog.message}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPromptDialog({showDialog: false, message:""})} color="info" style={{textTransform:"none"}}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        
        <Loader isLoading={loading2 || loading3}/>
      </>
    )
  } else {
    return <></>
  }
  
}
export default MeetingDialog;