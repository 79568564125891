import React from 'react'
import { CardItem } from 'src/components/cards/SampleClassesCard'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'
import SwipeableViews from 'react-swipeable-views';
import iconPre from 'src/images/homeNew/ico-pre.png'
import iconNext from 'src/images/homeNew/ico-next.png'
import { useGetQueryPublishedCourseListQuery } from 'src/app/service/real/coursedetail-slice';
import { CourseEntity, SeriesEntity } from '@dokyo/common'
import { CourseCard } from 'src/components/cards';



const useStyles = makeStyles(theme => ({
    card: {},
    root: {
        maxWidth: '1400px',
        minWidth: '1024px',
        width: '100%',
        position: 'relative',
        // padding: '0px 70px',
        margin: '0 auto',
        padding: '0 50px'
    },
    slide: {
        minHeight: 100,
        color: '#fff',
    },
    slide1: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden'
    },
    slide2: {
        display: 'none'
    }
}))

const SampleClassesSeries =  [
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Discover Art History with",
        "teacherNmae": "IManuela Sadovnik",
    
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/GraemeJohnson/Thumbnail/E2.png",
        "tilte": "Build Your Own Creativity in Piano Dr.Mckay",
        "teacherNmae": "Tristan Mckay",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Amanda Kaya",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Astra Liucija",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Observing Drawing With Astra!",
        "teacherNmae": "Astra Liucija",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Discover Art History with",
        "teacherNmae": "IManuela Sadovnik",
    
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/GraemeJohnson/Thumbnail/E2.png",
        "tilte": "Build Your Own Creativity in Piano Dr.Mckay",
        "teacherNmae": "Tristan Mckay",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Amanda Kaya",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Astra Liucija",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Observing Drawing With Astra!",
        "teacherNmae": "Astra Liucija",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Discover Art History with",
        "teacherNmae": "IManuela Sadovnik",
    
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/GraemeJohnson/Thumbnail/E2.png",
        "tilte": "Build Your Own Creativity in Piano Dr.Mckay",
        "teacherNmae": "Tristan Mckay",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Amanda Kaya",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Astra Liucija",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Observing Drawing With Astra!",
        "teacherNmae": "Astra Liucija",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Discover Art History with",
        "teacherNmae": "IManuela Sadovnik",
    
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/GraemeJohnson/Thumbnail/E2.png",
        "tilte": "Build Your Own Creativity in Piano Dr.Mckay",
        "teacherNmae": "Tristan Mckay",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Amanda Kaya",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "teacherNmae": "Astra Liucija",
        
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Observing Drawing With Astra!",
        "teacherNmae": "Astra Liucija",
    }
];


const areaList = ['', '', '', '', ''];

export const SampleClasses = () => {
    const classes = useStyles()
    const [index, setIndex] = React.useState<number>(0)
    const [courseList, setCourseList] = React.useState<CourseEntity[]>([]);

    const {data} = useGetQueryPublishedCourseListQuery({
        pageSize: 10
    })

    React.useEffect(() => {
        if (data) {
            if (data.isFailed === false && data.result?.course_list) {
                setCourseList(data.result.course_list)
            }
        }
    }, [data])


    const handleChangeIndex = (index: number) => {
        console.log('handleChangeIndex');
        console.log(index);
        setIndex(index)
    };

    const dataAreaLength = Math.floor(courseList.length / 5);
    const areaIndexList = [] as number[];
    for(let i = 0; i < dataAreaLength; i++) {
        areaIndexList.push(i)
    }
    let itemIndex = 0;

    const handleClickLeft = () => {
        let newIndex = index - 1 >= 0? index - 1: dataAreaLength - 1;
        handleChangeIndex(newIndex)
    }
    const handleClickRight = () => {
        let newIndex = (index + 1) % (dataAreaLength);
        handleChangeIndex(newIndex)
    }

    return (
        <div className={classes.root}>
            <img onClick={handleClickLeft} src={iconPre} alt="iconPre" style={{ width: '24px', position: 'absolute', top: '62px', left: '0', cursor: 'pointer' }} />
            <img onClick={handleClickRight}  src={iconNext} alt="iconNext" style={{ width: '24px', position: 'absolute', top: '62px', right: '0', cursor: 'pointer' }} />
            <SwipeableViews
                index={index}
                onChangeIndex={handleChangeIndex}
                // interval={3000000}
                enableMouseEvents
            >
                {areaIndexList.map((v, i) => (
                    <Box className={classes.slide1} key={i}>
                        {
                            areaList.map(() => {
                                const itemData = courseList[itemIndex++];
                                if (itemData) {
                                    return <CourseCard key={itemIndex} size={'small'} course={itemData} />;
                                } else {
                                    return (<></>);
                                }
                            })
                        }
                    </Box>
                ))}
            </SwipeableViews>
        </div>
    )
}
