import { Box, Typography, Theme, Avatar } from "@mui/material";
import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles'
import Icon from 'src/images/teacherinfo/ico-symbol.png'
import Score from 'src/images/teacherinfo/ico-star.png'
import Avatar1 from 'src/images/teacherinfo/Avatar.png'
import Avatar2 from 'src/images/teacherinfo/Avatar2.png'
import Avatar3 from 'src/images/teacherinfo/Avatar3.png'

const useStyles = makeStyles((theme: Theme) => ({
  rootBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleBox: {
    paddingTop: '70px',
    paddingBottom: '40px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
      paddingBottom: '20px',
    }
  },
  littleTitleText: {
    fontSize: "18px !important",
    fontFamily: "Avenir-Book, Avenir !important",
    fontWeight: "normal !important",
    color: "#307DCF !important",
    lineHeight: "30px !important",
  },
  titleText: {
    fontSize: "40px !important",
    fontFamily: "Avenir-Black, Avenir !important",
    fontWeight: "900 !important",
    color: "#000000 !important",
    lineHeight: "30px !important",
    marginTop:"23px !important",
    [theme.breakpoints.down('md')]: {
      lineHeight: "40px !important",
      fontSize: "30px !important",
      marginTop: "0 !important",
    }
  },
  listBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '100px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  listItemBox: {
    width: "332px",
    height: "480px",
    background: 'white',
    boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)",
    borderRadius: "8px",
    margin: '10px',
    paddingTop: "35px",
    paddingLeft: "24px",
    paddingRight: "24px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px',
    }
  },
  listItemIcon: {
    marginBottom: "27px"
  },
  listItemScore: {
    marginBottom: "15px"
  },
  sayText: {
    width: "284px !important",
    fontSize: "16px !important",
    fontFamily : "Avenir-Book, Avenir !important",
    fontWeight: "normal !important",
    color: "#555555 !important",
    lineHeight: "30px !important"
  },
  userInfoBox: {
    display: 'flex',
    justifyContent: 'left',
    width: '100%',
    alignItems: 'center',
    marginTop: "24px"
  },
  userAvatar: {
    width: '30px',
    height: '30px',
  },
  nameText: {
    fontSize: "18px !important",
    fontFamily: "Avenir-Black, Avenir !important",
    fontWeight: "900 !important",
    color: "#000000 !important",
    lineHeight: "24px !important",
    marginLeft: "16px !important",
  },

}))

const WhatParentsSayAboutTeacher: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const listData = [
    {
      id: '1',
      icon: Icon,
      score: Score,
      desc: 'Teacher Manu! Your classes are super cool, you explain every topic very well and I love how dynamic they are. You motivate me to continue being in the classes and to continue making art! ',
      userName: 'Olivia',
      userAvatar: Avatar1,
    },
    {
      id: '2',
      icon: Icon,
      score: Score,
      desc: 'In Manuela’s class, I feel the confidence that I don’t need to be great at drawing or painting to enjoy art! She gives me the motivation to explore art and experiment with mediums in such a playful and relaxed way that makes me want to learn more. ',
      userName: 'Nicolas',
      userAvatar: Avatar2,
    },
    {
      id: '3',
      icon: Icon,
      score: Score,
      desc: 'This class has given me the ability to see a painting not only as drawing with color on a sheet, but now as an expressed feeling that generates emotions in people! Each session has been very playful and makes me feel more interest and joy to continue learning about art. ',
      userName: 'Joseph',
      userAvatar: Avatar3,
    },
  ]
  return (
    <Box className={classes.rootBox} >
      <Box className={classes.titleBox}>
        <Typography className={classes.littleTitleText} >
          Testimonials
        </Typography>
        <Typography className={classes.titleText}>
          What The Parents Say?
        </Typography>
      </Box>
      <Box className={classes.listBox}>
        {listData.map(item => (
          <Box className={classes.listItemBox} key={item.id}>
            <Box className={classes.listItemIcon}><img src={item.icon} /></Box>
            <Box className={classes.listItemScore}><img src={item.score} /></Box>
            <Typography className={classes.sayText}>
              {item.desc}
            </Typography>
            <Box className={classes.userInfoBox}>
              <Avatar className={classes.userAvatar} src={item.userAvatar} />
              <Typography className={classes.nameText}>
                {item.userName}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
export default WhatParentsSayAboutTeacher