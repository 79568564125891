import { List } from '@dokyo/common'
import { createApi } from '@reduxjs/toolkit/query/react'
import { Video } from 'src/app/models'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'

export const demoApi = createApi({
  reducerPath: 'demo',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      searchPref: builder.mutation<string[], { type: string; text: string }>({
        query: ({ type, text }) => ({
          url: `search/${type}/${encodeURI(text)}`
        }),
        transformResponse: (res: List<string>) => res.items
      }),
      searchVideo: builder.mutation<Video[], string | null>({
        query: text => ({
          url: text ? `videos/search` : 'videos'
        }),
        transformResponse: (res: List<Video>) => res.items
      })
    }
  }
})

export const { useSearchPrefMutation, useSearchVideoMutation } = demoApi
