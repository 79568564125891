import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import { ErrorReportEntity } from 'src/app/models/error'
import { getExploreName } from 'src/utils/deviceUtil'

export enum ViewHistoryLessonType {
  Unknown = 0,
  RecordedClass = 1,
  Live = 2,
  Broadcast = 3,
  VideoBroadcast = 4,
  LiveVideoClass = 5,
  PrivateLesson = 6,
  SeriesLesson = 7,
}

export const logReportApi = createApi({
  reducerPath: 'logReportApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      reportErrorLog: builder.mutation<Result<any>, { entity: ErrorReportEntity
      }>({
        query: ({entity}) => ({
          url: `tracking`,
          method: 'POST',
          body: { 
            request_type: "create_tracking",
            request_data: {
              error_code: entity.error_code,
              error_message: entity.error_message,
              browser_type: getExploreName(),
              modules: entity.modules,
              request_url: entity.request_url,
              request_action: entity.request_action,
              request_params: entity.request_params,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      reportViewHistory: builder.mutation<Result<any>, { 
        course_type: ViewHistoryLessonType, course_id: string, teacher_id: string, user_id: string, visit_length: number,
      }>({
        query: ({course_type, course_id, teacher_id, user_id, visit_length}) => ({
          url: `saveuserviewhistory`,
          method: 'POST',
          body: { 
            course_type,//recorded class = 1, live = 2, broadcast = 3 video_broadcast = 4, private_lesson = 6, series_lesson = 7,
            course_id,
            teacher_id,
            visit_length, // seconds
            user_id,
            client_type: 3, // Unknown = 0, IOS = 1, Android = 2, Web = 3,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

    }
  }
})

export const { 
  useReportErrorLogMutation,
  useReportViewHistoryMutation,
} = logReportApi
