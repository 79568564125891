import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'

export const signUpApi = createApi({
  reducerPath: 'signUpApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      checkUserID: builder.mutation<Result<any>, { userId: string }>({
        query: ({ userId }) => ({
          url: `signupcheckname`,
          method: 'POST',
          body: { user_id: userId }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      signUpSendCode: builder.mutation<Result<any>, { 
          userId: string,
          uuid: string, 
          pubkey: string, 
          signature: string, 
          clientSalt: string, 
          passwordHashInfo: string, 
          verifyType: string,
        }>({
        query: ({ userId, uuid, pubkey, signature, clientSalt, passwordHashInfo, verifyType  }) => ({
          url: `signup`,
          method: 'POST',
          body: { 
            user_id: userId,
            reserved_name_id: uuid,
            public_key: pubkey,
            signature: signature,
            client_salt: clientSalt,
            password_hash: passwordHashInfo,
            phone: verifyType === "phone" ? `1-${userId}` : undefined,
            email: verifyType === "email" ? userId : undefined,
            client_type: 3,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      signUpVerifyCode: builder.mutation<Result<any>, { userId: string, verifyCode: string, referralID?: string }>({
        query: ({ userId, verifyCode, referralID }) => ({
          url: `signupverifycode`,
          method: 'POST',
          body: { user_id: userId, code: verifyCode, referral_id: referralID, client_type: 3 }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      signUpAsTeacher: builder.mutation<Result<any>, {
         userName: string, 
         email: string,
         category: string,
         location: string,
         ageUnder13: boolean,
         age13To18: boolean,
         ageOlder18: boolean,
         language: string,
         experience: string,
        }>({
        query: ({ userName, email, category, location, ageUnder13, age13To18, ageOlder18, language, experience }) => ({
          url: `applyteacher`,
          method: 'POST',
          body: { 
            user_name: userName, 
            email: email, 
            category: category,
            location: location,
            age_under_13: ageUnder13,
            age_range_13_18: age13To18,
            age_older_18: ageOlder18,
            languages: language,
            experience: experience, 
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { useCheckUserIDMutation, useSignUpSendCodeMutation, useSignUpVerifyCodeMutation, useSignUpAsTeacherMutation } = signUpApi
