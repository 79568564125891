import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';
import {
    AudioTransformDevice,
    Device,
    VideoTransformDevice,
} from 'amazon-chime-sdk-js';
import {
    getDeviceId
} from 'src/utils/chime';
import SelectInput from './SelectInput';


export type DeviceType = {
    deviceId: string;
    label: string;
};

interface Props {
    label: string;
    inputId: string;
    notFoundMsg: string;
    devices: DeviceType[];
    selectedDevice:
    | Device
    | AudioTransformDevice
    | VideoTransformDevice
    | null
    | undefined;
    onChange: (deviceId: string) => void;
    showLabel?: boolean;
    inline?: boolean;
}


const DeviceInput: FC<Props> = ({
    onChange,
    label,
    inputId,
    devices,
    selectedDevice,
    notFoundMsg,
    showLabel,
    inline,
}) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');

    useEffect(() => {
        const getSelectedDeviceId = async (): Promise<void> => {
          const selectedDeviceId = await getDeviceId(selectedDevice);
          setSelectedDeviceId(selectedDeviceId);
        };
    
        getSelectedDeviceId();
    }, [selectedDevice]);

    const deviceList = devices.length > 0? devices.map((device) => ({
        value: device.deviceId,
        label: device.label,
    })): [{ value: 'not-available', label: notFoundMsg }];

    const selectDevice = (e: SelectChangeEvent<string>): void => {
        const deviceId = e.target.value;
    
        if (deviceId === 'not-available') {
          return;
        }
        onChange(deviceId);
    };

    return (
        <Box>
            <SelectInput 
                onChange={selectDevice}
                label={label}
                inputId={inputId}
                value={selectedDeviceId}
                deviceList={deviceList}
                showLabel={showLabel}
                inline={inline}
            />
        </Box>
    );
}


export default DeviceInput;