import { Box, Avatar, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { useSelector } from 'src/app/toolkit/store'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import defaultLiveImage from 'src/images/class/bg-live.png'
import imageBecomeMembership from 'src/images/class/btn-become-member.svg'
import { getTimeInWordDescription2 } from 'src/utils/timeUtil'
import defaultUserAvatar from 'src/images/class/ic-user-avatar-default.png'
import { getDisplayUserName } from 'src/utils/stringUtil'
import { RelatedClassEntity, SeriesEntity } from '@dokyo/common'
import { storeSession } from 'src/utils/localStorage'
import { useNavigate } from 'react-router-dom';
import { appConfig } from 'src/app-config';
import { setSchoolTabValue } from 'src/app/service/school-slice'
import { Theme } from '@mui/system';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ClassroomStatusEnum, LessonClassesStatusEnum } from 'src/app/models/schedule'
import { AddToCartComponent } from '../addToCart'
import { useReportViewHistoryMutation, ViewHistoryLessonType } from 'src/app/service/real/logreport-slice'
import { useState } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  video: {
    border: "none",
    background: "black",
    width: '100% !important',
  },
  videoContainer: {
    border: "1px solid #dddddd",
    borderRadius: "5px",
  },
  videoInfoContainer: {    
    height: '500px',
    width: '100% !important ',
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  },
  videoBGImage: {
    width: "100% !important", 
    height: "100% !important",
    filter: 'brightness(40%)'
  },
  videoInfoBox: {
    width: "100% !important", 
    textAlign:"center", 
    position: "absolute",
  },
  videoTeacherInfo: {
    fontSize: "20px", 
    color: "white", 
    marginTop: "12px",
    fontFamily: "ArialMT !important",
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem'
    }
  },
  timeInfo:{
    fontSize: "26px", 
    color: "white", 
    margin: "32px 10px 0 10px",
    fontFamily: "ArialMT !important",
    [theme.breakpoints.down('md')]: {
      marginTop: "12px",
      fontSize: '0.875rem'
    }
  },
  membershipInfo:{
    fontSize: "30px", 
    color: "white", 
    marginTop: "10px",
    fontFamily: "ArialMT !important",
    marginLeft: "30px",
    marginRight: "30px",
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  teacherAvatar: {
    margin: "0 auto",
    border: "3px solid white"
  }
}))

export const getFinalFees = (courseInfo: SeriesEntity|null, classFees: number|undefined) => {
  if (courseInfo) {
    return courseInfo.series_fees || 0;
  } else {
    return classFees || 0;
  }
}

export const getFinalMemberFees = (courseInfo: SeriesEntity|null, classMemberFees: number|undefined) => {
  if (courseInfo) {
    return courseInfo.series_membership_fees || 0;
  } else {
    return classMemberFees || 0;
  }
}

export enum PlayerLearnTypeEnum {
  RECORDED_CLASS = 1,
  VIDEO_BROADCAST = 2,
  LIVE_STREAM = 3,
  LIVE_VIDEO_CLASS = 4,
  PRIVATE_LESSON = 5,
  SERIES_LESSON = 6,
}
interface LiveTypeData {
  thirdParty: boolean;
  enablePlayback: boolean;
  state: number;//0-no stream; 1-have stream
}

interface Props {
  learnType: PlayerLearnTypeEnum;
  learnInfo: RelatedClassEntity;
  courseInfo: SeriesEntity|null;
  playUrl: string
  onClickJoinMeeting?: () => void;
  onClickStartThirdPartyBroadcast?: () => void;
  liveTypeData?: LiveTypeData;
}

const PlayerContainer: React.FC<Props> = ({learnType, courseInfo, learnInfo, playUrl, onClickJoinMeeting, onClickStartThirdPartyBroadcast, liveTypeData}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { credential } = useSelector(state => state.app)
  const price = getFinalFees(courseInfo, learnInfo?.price)

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const needLogin = (): boolean => {
    if (learnType === PlayerLearnTypeEnum.RECORDED_CLASS || learnType === PlayerLearnTypeEnum.VIDEO_BROADCAST) {
      return learnInfo.price !== 0;
    } else if (learnType === PlayerLearnTypeEnum.LIVE_STREAM || learnType === PlayerLearnTypeEnum.LIVE_VIDEO_CLASS) {
      return learnInfo.status !== 3;
    } else {
      return true;
    }
  }

  const notNeedPurchase = ():boolean => {
    if (learnInfo?.purchasedSeperately) {
      return true;
    }
    if (price === 0) {
      return true;
    }
    if (credential?.roles?.includes("admin") || credential?.roles?.includes("teacher")) {
      return true;
    }
    if ((learnType === PlayerLearnTypeEnum.LIVE_STREAM || learnType === PlayerLearnTypeEnum.LIVE_VIDEO_CLASS) && learnInfo.status === 3) {
      return true;
    }
    return false;
  }

  if (needLogin() && !credential) {
    return <LoginBox learnType={learnType} learnInfo={learnInfo} liveTypeData={liveTypeData}/>
  } else if (notNeedPurchase()) {
    if (learnType === PlayerLearnTypeEnum.RECORDED_CLASS || learnType === PlayerLearnTypeEnum.VIDEO_BROADCAST) {
      return <PlayerBox playUrl={playUrl} learnType={learnType} resourceId={learnInfo.id} teacherId={learnInfo.teacherId||''}/>
    } else if (learnType === PlayerLearnTypeEnum.LIVE_VIDEO_CLASS || learnType === PlayerLearnTypeEnum.PRIVATE_LESSON || learnType === PlayerLearnTypeEnum.SERIES_LESSON) {
      return <ClassroomInfoBox learnType={learnType} learnInfo={learnInfo} onClickJoinMeeting={onClickJoinMeeting}/>
    } else { // live
      /**
      State === 1 
        普通直播： 正常播放
        第三方直播
          enable_playback- true 正常播放
          enable_playback- false
            老师：显示preview和start live按钮
            学生：显示时间描述
      State === 0  没推流，显示描述
      */
      if (liveTypeData?.state === 1) {
        if (!liveTypeData?.thirdParty) {
          return  <PlayerBox playUrl={playUrl} learnType={learnType} resourceId={learnInfo.id} teacherId={learnInfo.teacherId||''}/>
        } else {
          if (liveTypeData.enablePlayback) {
            return  <PlayerBox playUrl={playUrl} learnType={learnType} resourceId={learnInfo.id} teacherId={learnInfo.teacherId||''}/>
          } else {
            if (credential?.id === learnInfo.teacherId) {
              return (
              <Box className={classes.videoInfoContainer} sx={{justifyContent: "center"}}>
                <PlayerBox playUrl={playUrl} learnType={learnType} resourceId={learnInfo.id} teacherId={learnInfo.teacherId||''}/>
                <Box className={classes.videoInfoBox}>
                  <Button 
                    size='large'
                    variant='contained' 
                    color='primary' 
                    onClick={onClickStartThirdPartyBroadcast}
                    style={{ textTransform: "none", boxShadow: "none", borderRadius: "8px" }}
                    sx={matches? {marginTop: "70px"}:{marginTop: "280px",}}>
                    {t("class.start-live")}
                  </Button>
                </Box>
              </Box>
              )
            } else {
              return <LiveInfoBox learnInfo={learnInfo} liveTypeData={liveTypeData}/>
            }
          }
        }
      } else {
        return <LiveInfoBox learnInfo={learnInfo} liveTypeData={liveTypeData}/>
      } 
    }// type is live  -----end
  } else return (
    <NeedPurchaseBox 
      seriesInfo={courseInfo}
      learnInfo={learnInfo} 
      learnType={learnType}
      liveTypeData={liveTypeData}
    />
  )
}


const NeedPurchaseBox: React.FC<{
  seriesInfo: SeriesEntity|null;
  learnInfo: RelatedClassEntity,
  learnType: PlayerLearnTypeEnum;
  liveTypeData: LiveTypeData|undefined;
}> = ({seriesInfo, learnInfo, learnType, liveTypeData}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { credential } = useSelector(state => state.app)

  const handleClickLogin = () => {
    dispatch(showSignInUpDialog())
  }

  const getStatusText = () => {
    if (!credential) {
      return t("class.class-need-signin");
    } else  {
      return t("class.class-need-purchase");
    }
  }

  const getLiveStatusText = (): string => {
    if (learnInfo.status === 1) {
      return `${t('class.live-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000): "unknown time"}`;
    } else if (learnInfo.status === 2) {
      if (liveTypeData?.state === 0) {
        return t('class.live-no-stream');
      } else {
        return `${t('class.live-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000): "unknown time"}`;
      }
    } else if (learnInfo.status === 3) {
      return t('class.live-ended');
    } else {
      return '';
    }
  }

  return (
    <Box className={classes.videoContainer}>
      <Box className={classes.videoInfoContainer} sx={{justifyContent: "center"}}>
        <Avatar variant='square' src={ learnInfo?.icon || defaultLiveImage } className={classes.videoBGImage} />
        <Box 
          hidden={!learnInfo}
          className={classes.videoInfoBox}>
          <Avatar src={learnInfo?.teacherAvatar || defaultUserAvatar} 
            sx={(theme: Theme) => ({
              width: '96px',
              height: '96px',
              [theme.breakpoints.down('md')]: {
                width: '48px',
                height: '48px',
            }
          })} className={classes.teacherAvatar}/>
          <Box className={classes.videoTeacherInfo}>{getDisplayUserName(learnInfo.teacherName||"", learnInfo.teacherId||"")}</Box>
          {learnType === PlayerLearnTypeEnum.LIVE_STREAM ? <Box className={classes.timeInfo}>{getLiveStatusText()}</Box> : ""}
          {learnType === PlayerLearnTypeEnum.LIVE_STREAM && learnInfo.status === 3 ? "" : 
            <Box>
              <Box className={classes.membershipInfo}>{getStatusText()}</Box>
              {credential ?
                // <Avatar
                //   variant='square'
                //   src={imageBecomeMembership}
                //   onClick={handleClickBeMembership}
                //   sx={(theme: Theme) => ({
                //     width: "164px", 
                //     height: "48px", 
                //     cursor: "pointer", 
                //     margin: "30px auto",
                //     [theme.breakpoints.down('md')]: {
                //       width: "82px", 
                //       height: "24px", 
                //       cursor: "pointer", 
                //       margin: "10px auto",
                //     }
                //   })}/>
                (learnType !== PlayerLearnTypeEnum.PRIVATE_LESSON && learnType !== PlayerLearnTypeEnum.SERIES_LESSON &&
                <AddToCartComponent
                  purchasedSeparately={seriesInfo?.course_purchased || learnInfo.purchasedSeperately}
                  price={getFinalFees(seriesInfo, learnInfo.price)}
                  memberPrice={getFinalMemberFees(seriesInfo, seriesInfo?.series_membership_fees)}
                  seriesInfo={seriesInfo}
                  UIStyle={2}
                />
                )
                :
                <Button 
                  size='large'
                  variant='contained' 
                  color='primary' 
                  onClick={handleClickLogin}
                  sx={(theme: Theme) => ({
                    mt: "30px", 
                    textTransform: "none", 
                    boxShadow: "none",
                    borderRadius: "8px",
                    [theme.breakpoints.down('md')]: {
                      mt: "10px",
                      width:"70px",
                      height:"35px",
                      fontSize:"12px",
                      padding:"5px 10px",
                    }
                  })}
                  >
                  {t("studio.signIn")}
                </Button>
              }
            </Box>
          }
          
        </Box>
        
      </Box>
    </Box>
  )
}

interface PlayerBoxProps {
  playUrl:string, 
  learnType: PlayerLearnTypeEnum, 
  resourceId: string, 
  teacherId: string
}

const PlayerBox: React.FC<PlayerBoxProps> = ({playUrl, learnType, resourceId, teacherId}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const {credential} = useSelector(state => state.app);
  
  const [startTime, setStartTime] = useState(0);
  const [reportPlayHistory] = useReportViewHistoryMutation();

  const getParamLessonType = (learnType: PlayerLearnTypeEnum):ViewHistoryLessonType => {
    if (learnType === PlayerLearnTypeEnum.RECORDED_CLASS) {
      return ViewHistoryLessonType.RecordedClass;
    } else if (learnType === PlayerLearnTypeEnum.LIVE_STREAM) {
      return ViewHistoryLessonType.Live;
    } else if (learnType === PlayerLearnTypeEnum.LIVE_VIDEO_CLASS) {
      return ViewHistoryLessonType.LiveVideoClass;
    } else if (learnType === PlayerLearnTypeEnum.VIDEO_BROADCAST) {
      return ViewHistoryLessonType.VideoBroadcast;
    }
    return ViewHistoryLessonType.Unknown;
  }

  const tryToReport = () => {
    if (!credential) {
      return;
    }
    const timeLength = new Date().getTime() - startTime;
    if (timeLength < 1000) {
      return;
    }
    reportPlayHistory({
      course_type: getParamLessonType(learnType),
      course_id: resourceId,
      teacher_id: teacherId,
      user_id: credential?.id || '',
      visit_length: Math.round(timeLength / 1000),
    })
  }

  return (
    <Box className={classes.video} >
      <ReactPlayer 
        url={playUrl}
        playing={true}
        muted={false} 
        width={"100%"}
        height={matches? "auto": 500}
        controls
        // Disable download button
        config={{ file: { attributes: { controlsList: 'nodownload' }}}}
        // Disable right click
        onContextMenu={e => e.preventDefault()}
        playsinline={matches}
        onPlay={() => {setStartTime(new Date().getTime())}}
        onPause={() => {tryToReport()}}
      />
    </Box>
  )
}

const LiveInfoBox: React.FC<{learnInfo: RelatedClassEntity, liveTypeData: LiveTypeData|undefined}> = ({learnInfo, liveTypeData}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getLiveStatusText = (): string => {
    if (learnInfo.status === 1) {
      return `${t('class.live-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000): "unknown time"}`;
    } else if (learnInfo.status === 2) {
      if (liveTypeData?.state === 0) {
        return t('class.live-no-stream');
      } else {
        return `${t('class.live-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000): "unknown time"}`;
      }
    } else if (learnInfo.status === 3) {
      return t('class.live-ended');
    } else {
      return '';
    }
  }

  return (
    <Box className={classes.videoInfoContainer} sx={{justifyContent: "center"}}>
      <Avatar variant='square' src={ learnInfo?.icon || defaultLiveImage } className={classes.videoBGImage} />
      <Box className={classes.videoInfoBox}>
        <Avatar src={learnInfo?.teacherAvatar || defaultUserAvatar} 
          sx={(theme: Theme) => ({
            width: '96px',
            height: '96px',
            [theme.breakpoints.down('md')]: {
              width: '64px',
              height: '64px',
            }
          })}
          className={classes.teacherAvatar}/>
        <Box className={classes.videoTeacherInfo}>{getDisplayUserName(learnInfo?.teacherName||"", learnInfo?.teacherId||"")}</Box>
        <Box className={classes.timeInfo}>{getLiveStatusText()}</Box>
      </Box>
    </Box>
  )
}


const ClassroomInfoBox: React.FC<{learnType: PlayerLearnTypeEnum, learnInfo: RelatedClassEntity, onClickJoinMeeting?: () => void}> = ({learnType, learnInfo, onClickJoinMeeting}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { credential } = useSelector(state => state.app)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const getClassroomStatusText = (): string => {
    if (learnType === PlayerLearnTypeEnum.LIVE_VIDEO_CLASS) {
      if (learnInfo.status === 3) {
        return t('class.classroom-ended');
      } else {
        return `${t('class.classroom-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000) : "unknown time"}`;
      }
    } else if (learnType === PlayerLearnTypeEnum.PRIVATE_LESSON || learnType === PlayerLearnTypeEnum.SERIES_LESSON) {
      if (learnInfo.status === LessonClassesStatusEnum.Finish) {
        return 'Class has ended';
      } else if (learnInfo.status === LessonClassesStatusEnum.Cancel) {
        return 'Class has been canceled';
      } else {
        return `Class starting at ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time) : "unknown time"}`;
      }
    } else {
      return ''
    }
  }

  const isJoinEnable = () => {
    return (learnType === PlayerLearnTypeEnum.LIVE_VIDEO_CLASS && learnInfo.status !== 3) 
    || (learnType === PlayerLearnTypeEnum.PRIVATE_LESSON && learnInfo.status === LessonClassesStatusEnum.Scheduled)
    || (learnType === PlayerLearnTypeEnum.SERIES_LESSON && learnInfo.status === LessonClassesStatusEnum.Scheduled);
  }

  return (
  <Box className={classes.videoContainer}>
    <Box className={classes.videoInfoContainer} sx={{justifyContent: "center"}}>
      <Avatar variant='square' src={ learnInfo?.icon || defaultLiveImage } className={classes.videoBGImage} />
      <Box className={classes.videoInfoBox}>
        <Avatar src={learnInfo?.teacherAvatar || defaultUserAvatar}
          sx={(theme: Theme) => ({
            width: '96px',
            height: '96px',
            [theme.breakpoints.down('md')]: {
              width: '64px',
              height: '64px',
            }
          })}
          className={classes.teacherAvatar}/>
        <Box className={classes.videoTeacherInfo}>{getDisplayUserName(learnInfo.teacherName||"", learnInfo.teacherId||"")}</Box>
        <Box className={classes.timeInfo}>{getClassroomStatusText()}</Box>
        <Button
          disabled={!isJoinEnable()}
          size='large'
          variant='contained' 
          color='primary' 
          onClick={onClickJoinMeeting}
          sx={(theme: Theme) => ({ 
            textTransform: "none", 
            boxShadow: "none", 
            marginTop:"20px",
            borderRadius: "8px",
            '&.Mui-disabled': {
              background: '#888888',
              color: "#bbbbbb",
            },
            [theme.breakpoints.down('md')]: {
              mt: "10px",
              width:"70px",
              height:"35px",
              fontSize:"12px",
              padding:"5px 10px",
            }
          })}>
          {credential?.id === learnInfo.teacherId ? "Start" : "Join"}
        </Button>
        
      </Box>
    </Box>

  </Box>
  )
}


const LoginBox: React.FC<{learnInfo: RelatedClassEntity, learnType: PlayerLearnTypeEnum, liveTypeData: LiveTypeData|undefined}> = ({learnInfo, learnType, liveTypeData}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickLogin = () => {
    dispatch(showSignInUpDialog())
  }

  const getClassroomStatusText = (): string => {
    if (learnInfo.status === 3) {
      return t('class.classroom-ended');
    } else return `${t('class.classroom-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000) : "unknown time"}`;
  }

  const getLiveStatusText = (): string => {
    if (learnInfo.status === 1) {
      return `${t('class.live-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000): "unknown time"}`;
    } else if (learnInfo.status === 2) {
      if (liveTypeData?.state === 0) {
        return t('class.live-no-stream');
      } else {
        return `${t('class.live-time-info')} ${learnInfo?.time ? getTimeInWordDescription2(learnInfo?.time * 1000): "unknown time"}`;
      }
    } else if (learnInfo.status === 3) {
      return t('class.live-ended');
    } else {
      return '';
    }
  }
  return (
  <Box className={classes.videoContainer}>
    <Box className={classes.videoInfoContainer} sx={{justifyContent: "center"}}>
      <Avatar variant='square' src={ learnInfo?.icon || defaultLiveImage } className={classes.videoBGImage} />
      <Box className={classes.videoInfoBox}>
        <Avatar src={learnInfo?.teacherAvatar || defaultUserAvatar} 
          sx={(theme: Theme) => ({
            width: '96px',
            height: '96px',
            [theme.breakpoints.down('md')]: {
              width: '64px',
              height: '64px',
            }
          })}
          className={classes.teacherAvatar}/>
        <Box className={classes.videoTeacherInfo}>{getDisplayUserName(learnInfo.teacherName||"", learnInfo.teacherId||"")}</Box>
        {learnType === PlayerLearnTypeEnum.LIVE_STREAM && <Box className={classes.timeInfo}>{getLiveStatusText()}</Box> }
        {learnType === PlayerLearnTypeEnum.LIVE_VIDEO_CLASS && <Box className={classes.timeInfo}>{getClassroomStatusText()}</Box>}
        <Button 
          size='large'
          variant='contained' 
          color='primary' 
          onClick={handleClickLogin}
          sx={(theme: Theme) => ({
            mt: "30px", 
            textTransform: "none", 
            boxShadow: "none",
            borderRadius: "8px",
            [theme.breakpoints.down('md')]: {
              mt: "10px",
              width:"70px",
              height:"35px",
              fontSize:"12px",
              padding:"5px 10px",
            }
          })}
          >
          {t("studio.signIn")}
        </Button>
      </Box>
    </Box>

  </Box>
  )
}

export default PlayerContainer
