import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
// import DateRangeIcon from '@mui/icons-material/DateRange'
import { getTimeInWordDescription } from 'src/utils/timeUtil'
import pic_classroom from 'src/images/defaultIcon/pic-classroom.png'
import pic_live from 'src/images/defaultIcon/pic-live.png'
import SvgComponent from 'src/components/svgComponent'
import clsx from 'clsx'
import { getDisplayUserName } from 'src/utils/stringUtil'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  group: {
    width: '330px',
    height:'330px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    // padding:'0px 21px',
  },
  bgTransparent: {
    background: 'transparent !important'
  },
  spanvictoriaveeko: {
    position: 'absolute',
    left: '0',
    top: '218px',
    width: '245px',
    height: '27px',
    display: 'block',
    overflowWrap: 'break-word',
    color: 'rgba(0, 0, 0, 1)',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  spanvictoriaveeko1:{
    position:'absolute',
    left: '0',
    top: '284px',
    width: '62px',
    height: '24px',
    display: 'block',
    overflowWrap: 'break-word',
    color: 'rgba(85, 85, 85, 1)',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  spanvictoriaveeko2: {
    position: 'absolute',
    left: '0',
    top: '253px',
    width: '274px',
    height: '24px',
    display: 'flex',
    color: 'rgba(85, 85, 85, 1)',
    lineHeight: '20px'
  },
  spanvictoriaveeko3: {
    position: 'absolute',
    left: '15px',
    top: '367px',
    width: '120px',
    height: '27px',
    display: 'block',
    overflowWrap: 'break-word',
    color: 'rgba(0, 0, 0, 1)',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  label: {
    zIndex: 100,
    width: 56,
    height: 18,
    borderRadius: 5,
    position: 'absolute',
    left: 10,
    top: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '12px',
    letterSpacing: '-0.19200000166893005px',
    fontFamily: 'ArialMT',
    whiteSpace: 'nowrap',
    lineHeight: '18px',
    textAlign: 'left',
    backgroundColor: '#F20052',
  },
  labelIcon: {
    marginRight: 4
  },
  calendarIcon: {
    marginRight: '10px',
    width: "18px", 
    height: "17px"
  },
  liveIcon: {
    width: '10px',
    height: '11px'
  },
  titleMouseDown: {
    textDecoration: 'underline'
  }, 
  imgHover: {
    filter: 'contrast(70%)'
  },
}))

interface ClassroomListCardProps extends CardProps {
  card: ClassroomListCardEntity,
  label?: string,
  showLabel?: boolean,
  bgTransparent?: boolean;
  enable?: boolean;
}

export const ClassroomListCard: React.FC<ClassroomListCardProps> = ({card, label, enable, showLabel = true, bgTransparent = false , ...props}) => {
  const classes = useStyles()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ isMouseOver, setIsMouseOver] = React.useState<boolean>(false)

  let defaultImg = ''
  
  if (label === 'Live') {
    defaultImg = pic_live
  } else if (label === 'Video') {
    defaultImg = pic_classroom
  }

  const Label = () => {
    if (showLabel) {
      if (label === 'Video') {
        return (
          <Box className={classes.label}>
            <Box className={classes.labelIcon}>●</Box> {label} 
          </Box>
        )
      } else if (label === 'Live') {
        return (
          <Box className={classes.label}>
            <SvgComponent iconName="liveIcon" className={clsx(classes.labelIcon, classes.liveIcon)} /> {label} 
          </Box>
        )
      }   
    }
    return (<></>)
  }


  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  // const { color } =props
  return (
    <Box 
      className={classes.group}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      >
      <Card
        {...props}
        style={{
          position: 'relative',
          maxWidth: '330px',
          width: '100%',
          minWidth: '260px',
          height: '330px',
          
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          marginLeft: isMobile?'12px': '21px',
            // marginRight: '24px',
            // padding:'0px 21px',
            // margin:'0px 11px',
        }}
        className={clsx( {
          [classes.bgTransparent]: bgTransparent,
        })}
        sx={{ cursor: 'pointer', boxShadow: 'none', ml: 3, mr: 3 }}
      >
        <CardMedia
          component="img"
          image={card.media_image ?? defaultImg}
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            maxWidth: '330px',
            minWidth: '260px',
            width: '100%',
            height: '202px',
            borderRadius: '12px',
          }}
          className={clsx({[classes.imgHover]: isMouseOver})}
        />
        <CardContent >
          <Typography
            style={{
              width: '274px',
              fontSize: '24px',
              letterSpacing: '-0.578823447227478px',
              fontFamily: 'Arial-BoldMT',
              lineHeight: '24px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              opacity: enable ? "100%" : '50%',
            }}
            className={clsx(classes.spanvictoriaveeko, {[classes.titleMouseDown]: isMouseOver})}
          >
            {getDisplayUserName(card.content_header, card.id||"")}
          </Typography>
          <Typography
            className={classes.spanvictoriaveeko1}
            style={{
              fontSize: '16px',
              letterSpacing: '-0.578823447227478px',
              fontFamily: 'ArialMT',
              lineHeight: '24px',
            }}
          >
            {card.class}
          </Typography>
          <Typography
            className={classes.spanvictoriaveeko2}
            style={{
              fontSize: '16px',
              letterSpacing: '-0.38588231801986694px',
              fontFamily: 'ArialMT',
            }}
          >
            {/* <DateRangeIcon style={{marginRight:'10px'}}/> */}
            <SvgComponent iconName="calendar" className={classes.calendarIcon} />
            {getTimeInWordDescription(card.time * 1000)}
          </Typography>
        </CardContent>
        <Label />
      </Card>
    </Box>
  )
}
