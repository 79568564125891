import React from 'react';
import { AuthStatus  } from 'src/utils/auth';
import { Box, Typography, Button } from '@mui/material';
import SvgComponent from 'src/components/svgComponent'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showSignInUpDialog } from 'src/app/service/signinup-slice'




const useStyles = makeStyles(() => ({
  enrolled: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '484px',
    width: '100%',
    background: '#ffffff'
  },
  enrolledInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    width:'136px',
    height: '48px',
    
  }
}))


const EnrolledText = styled(Typography)({
  fontSize: '18px',
  marginTop: '18px'
})

export interface PropsEnrolled {
  authStatus: AuthStatus
}

export const Enrolled: React.FC<PropsEnrolled>= ({authStatus}) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClickButton = () => {
    dispatch(showSignInUpDialog())
  }

  return (
    <Box className={classes.enrolled}>
        <Box className={classes.enrolledInner}>
          <SvgComponent iconName="icMyCourse"  style={{width: '37px', height: '48px'}} />
          <EnrolledText>
          {t('studio.enrolled')}
          </EnrolledText>{
            !authStatus.isLogin? <Button 
            onClick={handleClickButton}
            className={classes.button}
            sx={{
              textTransform: "none", 
              backgroundColor: '#307DCF',  
              borderRadius: '8px',
              fontFamily: 'ArialMT',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              fontSize: '18px',
              marginTop: '20px'
            }}
            variant="contained">{t('studio.signIn')}</Button>:
            ''
          }
         
        </Box>
      </Box>
  )
}