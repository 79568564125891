import { SeriesTeacherEntity, TeacherEntity } from "@dokyo/common";
import { number, string } from "yup";
import { CategoryItem } from "./category";

export enum ScheduleEnum {
	Broadcast,
	ChimeMeeting,
	Mixed,
	TeacherAvailableTime,
	TeacherSideBookedTime,
	StudentSideBookedTime,
	StudentSideBookedSeriesLesson,
	TeacherSideSeriesLesson,
}

export enum ScheduleLessonTypeEnum {
	CustomPrivateLesson,
	OneToOneLesson,
	GroupLesson,
	SeriesLesson,
	Others,
}

export const getScheduleType = (type: ScheduleEnum) => {
    if (type === ScheduleEnum.Broadcast) {
      return "Live Stream";
    } else if (type === ScheduleEnum.ChimeMeeting) {
      return "Live Video Class"
    } else {
      return "Other Lesson";
    }
}
  
export const getScheduleTypeText = (type: ScheduleLessonTypeEnum) => {
	if (type === ScheduleLessonTypeEnum.GroupLesson) {
		return "Group Lesson";
	} else if (type === ScheduleLessonTypeEnum.OneToOneLesson) {
		return "Private Lesson";
	} else if (type === ScheduleLessonTypeEnum.SeriesLesson) {
		return "Series Lesson";
	} else if (type === ScheduleLessonTypeEnum.CustomPrivateLesson) {
		return "Custom Private Lesson"
	} else {
		return "Other Lesson";
	}
}

export enum BookStatusEnum {
	AVAILABLE_TIME = -1,
	UNPAID = 0,
	PAID = 1,
	EXPIRED = 2,
	CANCELED = 3,
	CLOSED = 4,
}
export interface AppointmentItem {
	id: string,
	title: string,
	startDate: Date,
	endDate: Date,
	schedule: ScheduleEnum,
	teacherName: string,
	teacherId: string,
	category?: string,	// used for live video class and live stream
	lessonType?: ScheduleLessonTypeEnum,
	bookList?: BookUserNote[],
	booked?: boolean,
	bookTimeLength?: number,// for 1 to 1 lesson
	bookFee?: number,// for 1 to 1 lesson
	bookStatus?: BookStatusEnum,
	timeRepeatId?: string,// for available time
	groupLessonFee?: number,// for group lesson
	classRoomId?: string,
	bookPaidNum?: number,
	bookUnpaidNum?: number,
	secondTeachers?: UserInfo[], // for private lesson and series lesson
}

export interface AppointmentDataItem {
	total: number;
	schedule: ScheduleEnum;
	startDate: Date;
	endDate: Date;
	infoList: AppointmentItem[];
}


export interface TimeInterval {
	start_time: number,
	end_time: number,
}

export interface AvailableTimeData {
	repeat_id: string,
	schedule_id: string,
	start_time: number,
	end_time: number,
	title: string,
	group_booking_number: number,//paid
	group_booking_unpaid_number: number,//unpaid
	group_fee: number,
	group_max: number,
	group_min: number,
	schedule_type: PrivateLessonTypeEnum,
}

export interface BookTimeData {
	start_time: number,
	end_time: number,
	message: string,
	student: {
		id: string,
		name: string,
	},
	booking_id: string,
	status: BookStatusEnum,
	create_time: number, // the book's create time	
	time: number, 	// minutes
	fee: number,
	title: string,
	schedule_type: PrivateLessonTypeEnum,
	classroom_id: string,
}

export interface BookedTeacherTimeData {
	start_time: number,
	end_time: number,
	message?: string,
	teacher?: {
		id: string,
		name: string,
		avatar: string,
	}
	booking_id: string,
	status: BookStatusEnum,
	create_time: number, // the book's create time
	time: number, 	// minutes
	fee: number,
	title: string,
	schedule_type: PrivateLessonTypeEnum,
	classroom_id: string,
}

export interface UserInfo {
	id: string,
	name: string,
	avatar: string,
}


export enum ClassroomStatusEnum {
	Open = 0,
	Closed = 1,
}

export interface BookUserNote {
	user: UserInfo,
	message: string,
}
export interface PrivateLessonOrderDetailEntity {
	id: string,
	booking_id: string,
	classroom_id: string,
	create_time: number,
	end_time: number,
	fee: number,
	message: string,
	start_time: number,
	status: BookStatusEnum,
	teacher: UserInfo,
	student: UserInfo,
	booking_list: BookUserNote[],
	time: number,
	title: string,
}
export interface PrivateLessonClassroomDetailEntity {
	id: string,
	title: string,
	teacher: UserInfo,
	student_list: UserInfo[],
	booking_list: BookUserNote[],
	start_time: number,
	create_time: number,
	end_time: number,
	message: string,
	fee: number,
	time: number,
	meeting_info: {
		chime_meeting_id: string,
	}
	status: ClassroomStatusEnum,
}

export const createEmptyPrivateLesson = (): PrivateLessonClassroomDetailEntity => ({
	id: '',
	title: '',
	teacher: {
		id: '',
		name: '',
		avatar: '',
	},
	student_list: [],
	booking_list: [],
	start_time: 0,
	create_time: 0,
	end_time: 0,
	message: '',
	fee: 0,
	time: 0,
	meeting_info: {
		chime_meeting_id: ''
	},
	status: 0,
})

export interface TeacherScheduleRepeatEntity {
	time_area: TimeInterval[],
	time_zone_offset: number,
	user_id: string,
	week_day: string,
}


export interface TimeFee {
	time: number,
	fee: number,
}

export interface TeacherPrivateLesson {
	time_fees: TimeFee[],
	thumbnail: string,
	introduction?: string,
	category_ids: string[],
	user_id: string,
	time_zone_offset: number,
	second_teacher: UserInfo[],
}

export enum RepeatTypeEnum {
	NO_REPEAT = 'no_repeat',
	DAY = 'every_day',
	Week = 'every_week',
}

export const enum PrivateLessonTypeEnum {
	Single = 'single',
	Group = 'group',
	Other = 'other',
}

export const getPrivateLessonType = (lessonType: ScheduleLessonTypeEnum | undefined): PrivateLessonTypeEnum => {
	switch (lessonType) {
		case ScheduleLessonTypeEnum.GroupLesson:
			return PrivateLessonTypeEnum.Group
		case ScheduleLessonTypeEnum.OneToOneLesson:
			return PrivateLessonTypeEnum.Single;
		case ScheduleLessonTypeEnum.SeriesLesson:
			return PrivateLessonTypeEnum.Other;
		default:
			return PrivateLessonTypeEnum.Other;
	}
}

export interface CreateAvailableTimeEntity {
	startTime: number,
	endTime: number,
	repeatType?: RepeatTypeEnum,
	repeatDays: number,
	title: string,
	scheduleType: PrivateLessonTypeEnum,
	minStudentNum: number,
	maxStudentNum: number,
	groupFee: number,
	categoryIds: string[],
	originalUserId: string | undefined,
}

export enum SeriesLessonStatusEnum {
	Draft = 0,
	Published = 1,
	Deleted = 2,
}

export interface SeriesLessonEntity {
	id: string;
	user: UserInfo;
	title: string;
	category_id: string;
	total_lesson: number;
	single_lesson_fee: number;
	total_lesson_fee: number;
	single_lesson_time: number;
	total_lesson_time: number;
	min_student: number;
	max_student: number;
	thumbnail: string;
	introduction: string;
	lesson_title_list: string[];
	create_time?: number;
	update_time?: number;
	status?: SeriesLessonStatusEnum;
	school_id: string;
	second_teacher?: UserInfo[];
}

export const createEmptySeriesLessonEntity = (): SeriesLessonEntity => {
	return {
		id: '',
		user: {
			id: '',
			name: '',
			avatar: '',
		},
		title: '',
		category_id: '',
		total_lesson: 0,
		single_lesson_fee: 0,
		total_lesson_fee: 0,
		single_lesson_time: 0,
		total_lesson_time: 0,
		min_student: 0,
		max_student: 0,
		thumbnail: '',
		introduction: '',
		lesson_title_list: [],
		school_id: '',
	}
}

export enum LessonClassesStatusEnum {
	Draft = 0,
	Published = 1,
	Scheduled = 2,
	Cancel = 3,
	Finish = 4,
}

export enum StudentJoinType {
	Booking = "booking",
	System_Add = "system_add",
}

export interface SemesterLesson {
	start_time: number;
	end_time: number;
	title: string;
	record_exist: boolean|string;
}

export interface SeriesLessonSemesterEntity {
	id: string;
	series_lesson_id: string;
	teacher_user_id: string;
	teacher_info: UserInfo,
	second_teacher: UserInfo[],
	series_lesson: SeriesLessonEntity,
	student_user_id_list: string[];
	student_info_list?: UserInfo[]; // only exist in details query
	start_time: number;
	end_time: number;
	lesson_list: SemesterLesson[];
	create_time: number;
	update_time: number;
	max_student: number;
	status: LessonClassesStatusEnum;
	classes_id?: string, //meeting id
}

export interface UserScheduleEntity {
	create_time: number,
	end_time: number,
	id: string,
	schedule_type: string,// planned
	series_lesson_classes?: SeriesLessonSemesterEntity,
	private_lesson_classes?: PrivateLessonClassSourceEntity,
	private_lesson?: PrivateLessonSourceEntity,
	source_id: string,
	source_type: UserScheduleSourceTypeEnum,
	start_time: number,
	user_id: string,
}

export enum UserScheduleSourceTypeEnum {
	PRIVATE_LESSON = 'private_lesson',
	PRIVATE_LESSON_CLASSES = 'private_lesson_classes',
	SERIES_LESSON_CLASSES = 'series_lesson',
}

export interface PrivateLessonSourceEntity {
	category_ids: string[],
	end_time: number,
	free_time_area: TimeInterval[],
	id: string,
	repeat_id: string,
	start_time: number,
	title: string,
	user_id: string,
	second_teacher: UserInfo[],
}
export enum PrivateLessonClassesTypeEnum {
	Student_Create = "Student_Create",
	Teacher_Create = "Teacher_Create",
  }
export interface PrivateLessonClassSourceEntity {
	id: string,
	classes_type: PrivateLessonClassesTypeEnum,
	teacher_user_id: string,
	student_user_id: string,
	title: string,
	classes_id: string,
	start_time: number,
	end_time: number,
	fee: number,
	status: LessonClassesStatusEnum,
	hold_time_status: number,
	thumbnail: string,
	private_lesson_teacher?: {
		category_ids: string[],
		introduction: string,
		thumbnail: string,
		time_fees: TimeFee[],
		time_zone_offset: number,
		user_id: string,
		user_info: UserInfo,
	}
	record_exist: boolean|string,
	second_teacher: UserInfo[],
	student_join_type: string,
	student_info: UserInfo
}


export interface CustomPrivateLessonClassEntity {
	id: string,
	teacher_user_id: string,
	student_user_id: string,
	title: string,
	time: number,
	start_time: number,
	end_time: number,
	status: LessonClassesStatusEnum,
	hold_time_status: number,
	fee: number,
}
