
import React, { useState } from 'react';
import { Theme, Box, Typography, List, ListItem, Button} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loader } from 'src/components/loader'
import { getScheduleType, ScheduleEnum, UserInfo } from 'src/app/models/schedule'
import { useSelector } from 'src/app/toolkit/store'
import { useSnackbar } from 'notistack';
import { getMonthInWord, getTimeInWordDescription2 } from 'src/utils/timeUtil';
import defaultLiveImage from 'src/images/defaultIcon/pic-live.png'
import defaultClassroomImage from 'src/images/defaultIcon/pic-classroom.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import { useLazyGetPurchasedListQuery } from 'src/app/service/real/purchase-api';
import { BroadcastEntity } from '@dokyo/common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  listBox: {
    marginTop: '30px',
  },
  listTitleItemBox: {
    marginBottom: "35px !important",
  },
  timeTitle: {
    textAlign: "left",
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "20px !important",
    lineHeight: "23px !important",
    marginBottom: "15px !important",
  },
  listItemBox: {
    width: "100%",
    borderBottom: '1px solid #dddddd',
    height: 'fit-content',
    display: "flex",
    flexDirection: "row",
    padding: "16px 0 26px 0",
    position: "relative",
    cursor: "pointer",
  },
  listItemMidBox: {
    marginLeft: "14px",
  },
  listItemTime: {
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "16px !important",
    color: "#307DCF !important",
    textAlign: "left",
  },
  scheduleTypeText: {
    marginLeft: "15px !important",
    fontFamily: "ArialMT !important",
    fontSize: "12px !important",
    textAlign: "center",
    color: "#555555",
    borderRadius: '5px',
    padding: '4px 8px',
  },
  listItemMessage: {
    marginTop: "13px !important",
    fontFamily: "ArialMT !important",
    fontSize: "14px !important",
    height: "16px !important",
    textAlign: "left",
    color: "black",
  },
  thumbnail: {
    width: '106px',
    height: '72px',
    borderRadius: '8px',
    objectFit: 'cover',
    position: 'absolute',
    right: 20,
    [theme.breakpoints.down('md')]: {
      display:"none"
    }
  },
  SignInInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: "60vh",
    paddingTop: "20vh",
  },
  queryTypeButton: {
    alignSelf: 'end',
    marginTop: '30px !important',
    width: '130px !important',
    height: '48px !important',
    borderRadius: '8px !important',
    border: '1px solid #7C849C !important',
    color: 'black !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',  
  },
  menu: {
    '& .MuiPopover-paper': {
      maxWidth: '130px !important',
    }
  },
  selectedMenu: {
    color: '#307DCF !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important', 
    height: '50px !important',
    width: '130px !important',
  },
  unSelectedMenu: {
    color: '#555555 !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',
    height: '50px !important', 
    width: '130px !important', 
  },
}))

interface FormatStudentScheduleEntity {
  id: string,
  title: string,
  type: ScheduleEnum,
  thumbnail: string,
  start_time: number,// million second
  status: number,
}

interface TimeScheduleEntity {
  time: number;// million second
  list: FormatStudentScheduleEntity[]
}

interface Props {
  queryTime: string; // Upcoming, Past
}

const MyLiveVideoClassList: React.FC<Props> = ({queryTime}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()
  const { credential } = useSelector(state => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState<TimeScheduleEntity[]>([]);

  const [getPurchasedList, { data: purchasedList, isFetching, isSuccess}] = useLazyGetPurchasedListQuery()

  React.useEffect(() => {
    if (credential) {
      getPurchasedList({});
    }
  }, [credential, queryTime])

  React.useEffect(() => {
    // purchased courses contain live stream and live video class
    if (isSuccess && purchasedList && !purchasedList.isFailed && purchasedList.result) {
      if (purchasedList.result.purchased_list) {
        const result : FormatStudentScheduleEntity[] = [];
        purchasedList.result.purchased_list.forEach(seriesItem => {       
          if (seriesItem.resource_list) {
            if (seriesItem.resource_list?.broadcast_list?.length > 0) {
              const data = broadcastToFormatSchedule(seriesItem.resource_list.broadcast_list)
              result.push(...data)
            }
            if (seriesItem.resource_list?.meeting_list?.length > 0) {
              const data = videoClassroomToFormatSchedule(seriesItem.resource_list.meeting_list)
              result.push(...data)
            }
  
          }
        })
        const timeSchedules = getCardList(result);
        setDataList(timeSchedules)
      }
    }
  }, [purchasedList])


  const broadcastToFormatSchedule = (list: BroadcastEntity[]): FormatStudentScheduleEntity[] => {
    if (!list || list.length === 0) {
      return [];
    }
    return list.map(item => {return {
      id: item.broadcast_id,
      type: ScheduleEnum.Broadcast,
      start_time: item.broadcast_start_time * 1000,
      title: item.broadcast_title,
      thumbnail: item.broadcast_cover || defaultLiveImage,
      status: item.broadcast_status,
    }})
  }

  const videoClassroomToFormatSchedule = (list: any[]): FormatStudentScheduleEntity[] => {
    if (!list || list.length === 0) {
      return [];
    }
    return list.map(item => {return {
      id: item.meeting_info?.meeting_id,
      type: ScheduleEnum.ChimeMeeting,
      start_time: item.meeting_start_time * 1000,
      title: item.meeting_title,
      thumbnail: item.meeting_thumbnail || defaultClassroomImage,
      status: item.meeting_status,
    }})
  }

  const getCardList = (list: FormatStudentScheduleEntity[]): TimeScheduleEntity[] => {
    let filterList;
    if (queryTime === "Upcoming") {
      filterList = list.filter(item => item.start_time >= new Date().getTime());
    } else {
      filterList = list.filter(item => item.start_time < new Date().getTime());
    }
    const resultList: TimeScheduleEntity[] = formatTimeSchedule(filterList);
    if (queryTime === "Upcoming") {
      resultList.sort((a, b) => a.time - b.time);
      resultList.forEach(item => {
        item.list.sort((a, b) => a.start_time - b.start_time);
      })
    } else {
      resultList.sort((a, b) => b.time - a.time);
      resultList.forEach(item => {
        item.list.sort((a, b) => b.start_time - a.start_time);
      })
    }
    setDataList(resultList);
    return resultList;
  }

  const formatTimeSchedule = (list: FormatStudentScheduleEntity[]): TimeScheduleEntity[] => {
    const resultList: TimeScheduleEntity[] = [];
    if (list && list.length > 0) {
      list.forEach(schedule => {
        const date = new Date(schedule.start_time);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const findDaySchedule = resultList.find(v => {
          const vDate = new Date(v.time);
          return year === vDate.getFullYear() && month === vDate.getMonth();
        })

        if (findDaySchedule) {
          findDaySchedule.list.push(schedule);
        } else {
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          const newDaySchedule: TimeScheduleEntity = {
            time: date.getTime(),
            list: [schedule],
          }
          resultList.push(newDaySchedule);
        }
      })
    }

    return resultList;
  }

  const handleItemClick = (item: FormatStudentScheduleEntity) => {
    if (item.type === ScheduleEnum.Broadcast) {
      navigate("/livebroadcast?id=" + item.id)
    } else if (item.type === ScheduleEnum.ChimeMeeting) {
      navigate("/videoclassroom?id=" + item.id)
    }
  }


  if (credential) {
    return (
      <Box className={classes.root}>
        <Box className={classes.container}>
          
          {dataList?.length > 0 ?
          <Box className={classes.listBox}>
            {dataList.map(data => (
              <Box key={data.time} className={classes.listTitleItemBox}>
                <Typography className={classes.timeTitle}>{getMonthInWord(new Date(data.time).getMonth()) + ", " + new Date(data.time).getFullYear()}</Typography>
                <List sx={{margin:"20px 0"}}>
                  {data.list?.length > 0 && data.list.map(item => (
                    <ListItem key={item.start_time} button sx={{padding: '8px 0 0 0'}}>
                      <Box className={classes.listItemBox} onClick={() => handleItemClick(item)}>
                        <Box sx={{width: '80px', marginRight: '16px'}}>
                          <Box sx={{
                            height: '32px', 
                            paddingTop: '8px',
                            borderRadius: '8px 8px 0px 0px', 
                            background: '#E2E2E2', 
                            textAlign:'center', 
                            fontSize: '14px',
                            fontFamily: 'Avenir-Black, Avenir',
                            color: 'white',
                            fontWeight: 900,
                          }}>
                            {getMonthInWord(new Date(data.time).getMonth(), true)}
                          </Box>
                          <Box sx={{
                            height: '40px', 
                            paddingTop: '10px',
                            borderRadius: '0px 0px 8px 8px', 
                            border: '1px solid #E6E6E6',
                            background: 'white', 
                            textAlign:'center', 
                            fontSize: '16px',
                            fontFamily: 'Avenir-Black, Avenir',
                            color: '#555555',
                            fontWeight: 900,
                          }}>
                            {new Date(item.start_time).getDate()}
                          </Box>
                        </Box>
                        <Box className={classes.listItemMidBox}>
                          <Box style={{ display: "flex", alignItems: "baseline" }}>
                            <Typography className={classes.listItemTime}>{getTimeInWordDescription2(item.start_time)}</Typography>
                            <Typography 
                              className={classes.scheduleTypeText} 
                              sx={item.type === ScheduleEnum.Broadcast ? {background: "#E0EDBD"} : {background: "#BDF3EB"}}>
                              {getScheduleType(item.type)}
                            </Typography>
                          </Box>
                          <Typography className={classes.listItemMessage}>{item.title}</Typography>
                          {/* <Typography className={classes.listItemMessage}>{item.title}</Typography> */}
                        </Box>
                        {/* <Box className={classes.bookStatusText} style={{ backgroundImage: getBookStatusBg(item.status), backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                          {getBookTimeStatusDesc(item.status)}
                        </Box> */}

                        {item.thumbnail && <img alt="lesson_thumbnail" src={item.thumbnail} className={classes.thumbnail}/>}
                        
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          :
          <Typography style={{fontSize: '18px', fontFamily: 'ArialMT', margin: "50px 0"}}>
            No data yet.
          </Typography>
          }
  
        </Box>
        <Loader isLoading={isFetching} />
      </Box>
    )
  } else {
    return (
      <Box className={classes.SignInInner}>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'> Please sign in
          <Button
            onClick={() => {dispatch(showSignInUpDialog())}}
            sx={{
              width:'136px',
              height: '48px',
              textTransform: "none", 
              backgroundColor: '#307DCF',  
              borderRadius: '8px',
              fontFamily: 'ArialMT',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              fontSize: '18px',
              marginTop: '20px'
            }}
            variant="contained">
            {t('studio.signIn')}
          </Button>
        </Box>
        
      </Box>
    )
  }
  
}

export default MyLiveVideoClassList;