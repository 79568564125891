import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, CardActions } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { VictoriaClassCardEntity } from 'src/app/models/victoriaClass'
import clsx from 'clsx'
import { getDisplayUserName } from 'src/utils/stringUtil'

const useStyles = makeStyles(theme => ({
  group: {
    zIndex: 'auto',
    width: '330px',
    height: '414px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  spanvictoriaveeko3: {
    position: 'absolute',
    left: '15px',
    top: '367px',
    width: '120px',
    height: '27px',
    display: 'block',
    overflowWrap: 'break-word',
    color: 'rgba(0, 0, 0, 1)',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  titleMouseDown: {
    textDecoration: 'underline'
  }, 
  imgHover: {
    filter: 'contrast(70%)'
  },
}))

interface VictoriaClassCardProps extends CardProps {
  card: VictoriaClassCardEntity,
  content_body_top?:number,
  content_body_height?:number,
  handleClick?: Function,
  isExcellent?:boolean
}

export const VictoriaClassCard: React.FC<VictoriaClassCardProps> = ({card,content_body_top,content_body_height,handleClick,isExcellent, ...props}) => {
  const classes = useStyles()
  const { color } =props
  const [ isMouseOver, setIsMouseOver] = React.useState<boolean>(false)
  
  const handleViewDetailsClick = (e) => {
    if(handleClick){
      handleClick()
    }
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  return (
    <Box 
      className={classes.group}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      sx={{
        height:`${ isExcellent === true ? 330 : 414}px`
      }}
      onClick={(e)=>{handleViewDetailsClick(e)}}
    >
      <Card
        {...props}
        style={{
          position: 'relative',
          maxWidth: '330px',
          width: '100%',
          minWidth: '260px',
          height:`${ isExcellent === true ? 330 : 414}px`,
          borderRadius: '12px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          background: `${color || '#F9F9F9' }`,
        }}
        sx={{ cursor: 'pointer', boxShadow: 'none', ml: 3, mr: 3 }}
      >
        <CardMedia
          component="img"
          image={card.media_thumbnail??card.media_image}
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            maxWidth: '330px',
            minWidth: '260px',
            width: '306px',
            height: '202px',
          }}
          className={clsx({[classes.imgHover]: isMouseOver})}
        />
        <CardContent>
          <Box
            sx={{
             display:'flex',
             flexDirection:'column',
             position:'absolute',
             top:'218px',
           }}
          >
            <Typography
            style={{
              width: '274px',
              fontSize: '20px',
              letterSpacing: '-0.578823447227478px',
              fontFamily: 'Arial-BoldMT',
              lineHeight: '22px',
              overflow: 'hidden',
              whiteSpace: 'normal',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient:'vertical',
              WebkitLineClamp:2,
            }}
            className={clsx({[classes.titleMouseDown]: isMouseOver})}
          >
            {getDisplayUserName(card.content_header, card.id||"")}
          </Typography>
          {
            card.content_subheader && 
            <Typography
              style={{
                fontSize: '16px',
                marginTop:'10px',
                width: '274px',
                letterSpacing: '-0.578823447227478px',
                fontFamily: 'ArialMT',
                lineHeight: '24px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              >
              {card.content_subheader}
            </Typography>
          }
          {
            isExcellent===true && 
                <Typography
                style={{
                  width: '274px',
                  fontSize: '16px',
                  marginTop:'10px',
                  letterSpacing: '-0.38588231801986694px',
                  fontFamily: 'ArialMT',
                  lineHeight: '24px',
                  top:`${content_body_top|| 253}px`,
                  height:'24px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {card.content_body}
              </Typography>
          }
          {
            isExcellent===undefined && 
                <Typography
                style={{
                  fontSize: '16px',
                  width: '274px',
                  marginTop:'10px',
                  letterSpacing: '-0.38588231801986694px',
                  fontFamily: 'ArialMT',
                  lineHeight: '24px',
                  top:`${content_body_top|| 253}px`,
                  height:'98px',
                  overflow: 'hidden',
                  whiteSpace: 'normal',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient:'vertical',
                  WebkitLineClamp:4,
                }}
              >
                {card.content_body}
              </Typography>
          }
          {
            card.content_body2 && 
            <Typography
              //className={classes.spanvictoriaveeko1}
              style={{
                fontSize: '16px',
                //top:'284px',
                width: '274px',
                letterSpacing: '-0.578823447227478px',
                fontFamily: 'ArialMT',
                lineHeight: '24px'
              }}
              >
              {card.content_body2}
            </Typography>
          }
          </Box>
        </CardContent>
        <CardActions>
          <Typography
            className={classes.spanvictoriaveeko3}
            style={{
              fontSize: '16px',
              letterSpacing: '-0.38588231801986694px',
              fontFamily: 'ArialMT',
              lineHeight: '27px',
            }}
            
          >
            View Details &gt;
          </Typography>
        </CardActions>
      </Card>
    </Box>
  )
}
