import { FC } from 'react';
import MicSelection from './MicSelection';
import MicrophoneActivityPreview from './MicrophoneActivityPreview';


const MicrophoneDevices: FC = () => {


    return (
        <>
            <MicSelection />
            <MicrophoneActivityPreview />
        </>
    )
}


export default MicrophoneDevices;