import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Theme, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles'
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  lineItemTitle: {
    fontSize: '14px',
    fontFamily: 'ArialMT !important',
    marginTop: '22px !important',
    color: '#555555 !important'
  },
}))


interface Props {
  open: boolean,
  onClickConfirm: () => void;
  onClickClose: () => void;
}

const RefundAlertDialog: React.FC<Props> = ({open, onClickConfirm, onClickClose }) => {
  const classes = useStyles()


  return (
    <Dialog open={open} maxWidth='lg'>
      <DialogTitle>Prompt</DialogTitle>
      <DialogContent>
        <Divider/>
        <Typography className={classes.lineItemTitle}>The lesson within 24 hours can not be refunded</Typography>
      </DialogContent>
      <DialogActions>
        <Button color='inherit' sx={{textTransform:'none'}} onClick={onClickClose}>Cancel</Button>
        <Button color='secondary' sx={{textTransform:'none'}} onClick={onClickConfirm}>Continue</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefundAlertDialog