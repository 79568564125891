import { Device, isVideoTransformDevice } from 'amazon-chime-sdk-js';
import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  useBackgroundBlur,
  // useBackgroundReplacement,
  useVideoInputs,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { SelectChangeEvent } from '@mui/material/Select';
import SelectInput from '../../deviceInput/SelectInput';

const VideoTransformOptions = {
    None: 'None',
    Blur: 'Background Blur',
    Replacement: 'Background Replacement',
};

type VideoTransformDropdownOptionType = {
    label: string;
    value: string;
};


export const VideoTransformSelection: FC = () => {
  const [transformOption, setTransformOption] = useState(VideoTransformOptions.None);
  const { isBackgroundBlurSupported, createBackgroundBlurDevice } =
    useBackgroundBlur();
  // const { isBackgroundReplacementSupported, createBackgroundReplacementDevice } =
  //   useBackgroundReplacement();
  const [isLoading, setIsLoading] = useState(false);
  const meetingManager = useMeetingManager();
  const { selectedDevice } = useVideoInputs();

  useEffect(() => {
    if (!isVideoTransformDevice(selectedDevice) && transformOption !== VideoTransformOptions.None) {
      setTransformOption(VideoTransformOptions.None);
    }
  }, [selectedDevice]);

//   const options: VideoTransformDropdownOptionType[] = [
//     {
//       label: VideoTransformOptions.None,
//       value: VideoTransformOptions.None,
//     },
//     {
//       label: VideoTransformOptions.Blur,
//       value: isBackgroundBlurSupported === undefined || isBackgroundBlurSupported === false ? 'Background Blur not supported' : VideoTransformOptions.Blur,
//     },
//     {
//       label: VideoTransformOptions.Replacement,
//       value: isBackgroundReplacementSupported === undefined || isBackgroundReplacementSupported === false ? 'Background Replacement not supported' : VideoTransformOptions.Replacement,
//     },
//   ];

//   const selectTransform = async (e: ChangeEvent<HTMLSelectElement>) => {
//     const selectedTransform = e.target.value;
//     let currentDevice = selectedDevice;

//     if (isLoading || currentDevice === undefined) {
//       return;
//     }
//     try {
//       setIsLoading(true);
//       if (isVideoTransformDevice(currentDevice)) {
//         const intrinsicDevice = await currentDevice.intrinsicDevice();
//         await currentDevice.stop();
//         currentDevice = intrinsicDevice;
//       }
//       if (selectedTransform === VideoTransformOptions.Blur && isBackgroundBlurSupported) {
//         currentDevice = await createBackgroundBlurDevice(currentDevice as Device);
//       } else if (selectedTransform === VideoTransformOptions.Replacement && isBackgroundReplacementSupported) {
//         currentDevice = await createBackgroundReplacementDevice(currentDevice as Device);
//       }
//       await meetingManager.startVideoInputDevice(currentDevice);
//       setTransformOption(selectedTransform);
//     } catch (e) {
//       console.error('Error trying to apply', selectTransform, e);
//     } finally {
//       setIsLoading(false);
//     }
//   };


  const deviceList = [
    {
      label: VideoTransformOptions.None,
      value: VideoTransformOptions.None,
    },
    {
      label: VideoTransformOptions.Blur,
      value: isBackgroundBlurSupported === undefined || isBackgroundBlurSupported === false ? 'Background Blur not supported' : VideoTransformOptions.Blur,
    }
    ,
    // {
    //   label: VideoTransformOptions.Replacement,
    //   value: isBackgroundReplacementSupported === undefined || isBackgroundReplacementSupported === false ? 'Background Replacement not supported' : VideoTransformOptions.Replacement,
    // },
  ];

  const handleSelect = async(e: SelectChangeEvent<string>): Promise<void> => {
    const selectedTransform = e.target.value;
    let currentDevice = selectedDevice;

    if (isLoading || currentDevice === undefined) {
      return;
    }
    try {
      setIsLoading(true);
      if (isVideoTransformDevice(currentDevice)) {
        const intrinsicDevice = await currentDevice.intrinsicDevice();
        await currentDevice.stop();
        currentDevice = intrinsicDevice;
      }
      if (selectedTransform === VideoTransformOptions.Blur && isBackgroundBlurSupported) {
        currentDevice = await createBackgroundBlurDevice(currentDevice as Device);
      } 
      // else if (selectedTransform === VideoTransformOptions.Replacement && isBackgroundReplacementSupported) {
      //   currentDevice = await createBackgroundReplacementDevice(currentDevice as Device);
      // }
      await meetingManager.startVideoInputDevice(currentDevice);
      setTransformOption(selectedTransform);
    } catch (e) {
      console.error('Error trying to apply', handleSelect, e);
    } finally {
      setIsLoading(false);
    }
};


  return (
    <Box>
        <SelectInput 
            label='Video Transform Dropdown'
            inputId='video-transform-selection-select'
            deviceList={deviceList}
            onChange={handleSelect}
            value={transformOption}
        />
    </Box>
  );
};

export default VideoTransformSelection;
