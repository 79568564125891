import { forwardRef } from 'react';
import Box from '@mui/material/Box';



const ActivityBar = forwardRef((props, ref: any) => (
    <Box 
        sx={{
            width: '100%',
            height: '8px',
            backgroundColor: `rgba(48,125,207,0.1)`,
            borderRadius: '4px',
        }}
    >
      <Box 
        sx={{
            height: '8px',
            backgroundColor: '#307DCF',
            borderRadius: '4px',
            transform: 'scaleX(0)',
            transformOrigin: 'left',
            transition: 'transform 33ms ease-in-out',
            willChange: 'transform',
        }}
        ref={ref}
       />
    </Box>
));

ActivityBar.displayName = 'ActivityBar';

export default ActivityBar;