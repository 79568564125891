import  { useMemo, Dispatch, SetStateAction, createContext, FC, useContext, useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    useMeetingManager,
    DeviceLabels,
    useAudioVideo,
    useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import {  MeetingManagerJoinOptions } from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types';
import { MeetingDataConfigs } from '@dokyo/common';
import { 
    MeetingSessionConfiguration,
    MeetingSessionStatusCode,
    AudioVideoObserver,
 } from 'amazon-chime-sdk-js';
import { useSelector } from 'src/app/toolkit/store';
import { getDisplayUserName } from 'src/utils/stringUtil';
import { useGetProfileMutation } from 'src/app/service/real/aws-api';
import { DataMessage, TranscriptionStatus, TranscriptionStatusType, Transcript } from 'amazon-chime-sdk-js';
import { useSnackbar } from 'notistack';
import { MeetingTypeEnum } from 'src/pages/videoClassroom/dialog/MeetingDialog';


interface Props {
    meetingType: MeetingTypeEnum;
    queryMaterialId: string;
    meetingId: string;
    meetingDataConfigs: MeetingDataConfigs;
    onExitMeeting: () => void;
    joinTimestamp: number;
}

export enum ControlDataMessagesType {
    Layout,
    MuteAll,
    CloseAllVideo,
    CloseChat,
    ForbiddenShareContent,
    TopUser,
    RaiseHand,
    LockMaterial
}


export interface ControlDataMessage  {
    type: ControlDataMessagesType,
    layout?: number;
    muteAll?: boolean;
    closeAllVideo?: boolean;
    closeChat?: boolean;
    forbiddenShareContent?: boolean;
    topUser?: string;
    raiseHand?: string;
    lockMaterial?: LockMaterial;
}

interface MeetingStateValue {
    recordStart: boolean;
    meetingRecord:boolean;
    meetingType: MeetingTypeEnum;
    queryMaterialId: string;
    meetingId: string;
    teacherId: string;
    meetingStarted: boolean;
    imageBlob: Blob | undefined;
    localAttendeeId: string;
    localUserName: string;
    localUserId: string;
    isTeacher: boolean;
    isStudent: boolean;
    layout: number;
    muteAll: boolean;
    closeAllVideo: boolean;
    closeChat: boolean;
    forbiddenShareContent: boolean;
    transitionLanguage: string;
    transitionText: string;
    transitionOn: boolean;
    displayTransition: boolean;
    initOpenVideo: boolean;
    topUser: string;
    raiseHand: string;
    meetingLoading: boolean;
    isMobile: boolean;
    joinTimestamp: number;
    lockMaterial: LockMaterial | undefined;
    hostTeacherId: string;
    setMeetingStarted: Dispatch<SetStateAction<boolean>>;
    onExitMeeting: () => void;
    setBlob: (imageBlob: Blob) => void;
    sendMessage: (message: ControlDataMessage) => void;
    setLayout: Dispatch<SetStateAction<number>>;
    setMuteAll: Dispatch<SetStateAction<boolean>>;
    setCloseAllVideo: Dispatch<SetStateAction<boolean>>;
    setCloseChat: Dispatch<SetStateAction<boolean>>;
    setForbiddenShareContent: Dispatch<SetStateAction<boolean>>;
    setTransitionLanguage: Dispatch<SetStateAction<string>>;
    setDisplayTransition: Dispatch<SetStateAction<boolean>>;
    setInitOpenVideo: Dispatch<SetStateAction<boolean>>;
    setTopUser: Dispatch<SetStateAction<string>>;
    setRaiseHand : Dispatch<SetStateAction<string>>;
    setLockMaterial: Dispatch<SetStateAction<LockMaterial|undefined>>;
}



const MeetingStateContext = createContext<MeetingStateValue | null>(null);

export function useMeetingState(): MeetingStateValue {
    const state = useContext(MeetingStateContext);

    if (!state) {
        throw new Error('useMeetingState must be used with MeetingStateProvider');
    }

    return state;
}

export const NO_LANGUAGE = "no-language";

export interface LockMaterial {
    lock: boolean;
    file_url: string;
    file_name: string;
    file_title: string;
    id?: string;
}

export const MeetingStateProvider: FC<Props> = ({ meetingType, queryMaterialId, meetingId, meetingDataConfigs, onExitMeeting, joinTimestamp: startTimeStamp, children }) => {
    const { roster } = useRosterState();
    const meetingManager = useMeetingManager();
    const audioVideo = useAudioVideo();
    const { credential } = useSelector(state => state.app);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [initOpenVideo, setInitOpenVideo] = useState<boolean>(true);

    const [meetingStarted, setMeetingStarted ] = useState<boolean>(false);
    const [imageBlob, setImageBlob] = useState<Blob | undefined>(undefined);
    const [getUserProfile] = useGetProfileMutation();

    const [layout, setLayout] = useState<number>(meetingDataConfigs.meetingControls.layout);
    const [muteAll, setMuteAll] = useState<boolean>(meetingDataConfigs.meetingControls.muteAll);
    const [closeAllVideo, setCloseAllVideo] = useState<boolean>(meetingDataConfigs.meetingControls.closeAllVideo);
    const [closeChat, setCloseChat] = useState<boolean>(meetingDataConfigs.meetingControls.closeChat);
    const [forbiddenShareContent, setForbiddenShareContent] = useState<boolean>(meetingDataConfigs.meetingControls.forbidShare);
    const [transitionLanguage, setTransitionLanguage] = useState<string>(meetingDataConfigs.meetingControls.transitionLanguage || NO_LANGUAGE);
    const [transitionText, setTransitionText] = useState<string>("");
    const [transitionOn, setTransitionOn] = useState(meetingDataConfigs.meetingControls.transitionLanguage ? true : false);
    const [displayTransition, setDisplayTransition] = useState<boolean>(true);
    const [topUser, setTopUser] = useState<string>(meetingDataConfigs.meetingControls.toppingUser);
    const [raiseHand, setRaiseHand] = useState<string>('');
    const [raiseHandTimeout, setRaiseHandTimeout] = useState<ReturnType<typeof setTimeout>>();
    const [alertDialogData, setAlertDialogData] = useState({
        showAlertDialog: false,
        alertDialogTitle: "",
        exitAfterClose: false,
    });
    const [meetingLoading, setMeetingLoading] = useState<boolean>(true);
    const [joinTimestamp] = useState<number>(startTimeStamp);
    const [lockMaterial, setLockMaterial] = useState<LockMaterial | undefined>(undefined);

    
    const isTeacher = useMemo(() => {
        return credential?.id === meetingDataConfigs.teacherId || meetingDataConfigs.secondTeachers.includes(credential?.id||"");
    }, [credential, meetingDataConfigs, ]);


    useEffect(() => {
        setTimeout(() => {
            // loading time out
            setMeetingLoading(false);
        }, 10000)
    }, [])


    const hostTeacherId = useMemo(() => {
        let mainTeacherId: string = meetingDataConfigs.teacherId;
        for (const key in roster) {
            const item = roster[key];
            if (item.externalUserId === meetingDataConfigs.teacherId) {
                mainTeacherId = meetingDataConfigs.teacherId;
                break;
            } else if (!meetingDataConfigs.secondTeachers.includes(mainTeacherId) && item.externalUserId && meetingDataConfigs.secondTeachers.includes(item.externalUserId)) {
                
                mainTeacherId = item.externalUserId;
            }
        }
        return mainTeacherId;
    }, [roster, meetingDataConfigs.secondTeachers, meetingDataConfigs.teacherId])

    // useEffect(() => {
    //     async function loadImage() {
    //       const canvas = document.createElement('canvas');
    //       canvas.width = 500;
    //       canvas.height = 500;
    //       const ctx = canvas.getContext('2d');
    //       if (ctx !== null) {
    //         const grd = ctx.createLinearGradient(0, 0, 250, 0);
    //         grd.addColorStop(0, '#000428');
    //         grd.addColorStop(1, '#004e92');
    //         ctx.fillStyle = grd;
    //         ctx.fillRect(0, 0, 500, 500);
    //         canvas.toBlob(function(blob) {
    //           if (blob !== null) {
    //             console.log('loaded canvas', canvas, blob);
    //             setImageBlob(blob);
    //           }
    //         });
    //       }
    //     }
    //     loadImage();
    // }, []);

    const setBlob = (imageBlob: Blob): void => {
        setImageBlob(imageBlob);
    };
    

    const meetingJoin = async () => {
        const {meetingInfo, attendeeInfo} = meetingDataConfigs;
        const meetingSessionConfiguration = new MeetingSessionConfiguration(meetingInfo,  attendeeInfo);
        const options: MeetingManagerJoinOptions = {
            deviceLabels: DeviceLabels.AudioAndVideo,
            enableWebAudio: true,
        };
        meetingManager.getAttendee = async (attendee_id, user_id) => {
            if (user_id) {
                try {
                    const result = await getUserProfile({userId: user_id}).unwrap()
                    console.log('getUserProfile', result);
                    if (result && !result.isFailed && result.result) {
                        const user_profile = result.result.user_profile;
                        if (user_profile) {
                            return {
                                name: getDisplayUserName(user_profile.user_name || '', user_id)
                            };
                        }
                        
                    }
                } catch (e) {
                    console.error(e);
                }
                return {
                    name: user_id
                };
            }
            return Promise.resolve({
                name: ''
            });
        }
        await meetingManager.join(meetingSessionConfiguration, options);
    }

    useEffect(() => {
        if (meetingDataConfigs) {
            meetingJoin();
        }
    }, [meetingDataConfigs])


    useEffect(() => {
        if (!audioVideo) {
          return;
        }
        audioVideo.realtimeSubscribeToReceiveDataMessage(CONTROL_DATA_MESSAGE_TOPIC, handler);
        return () => {
          audioVideo.realtimeUnsubscribeFromReceiveDataMessage(CONTROL_DATA_MESSAGE_TOPIC);
        };
    }, [audioVideo]);

    useEffect(() => {
        if (!audioVideo) {
          return;
        }
        audioVideo.transcriptionController?.subscribeToTranscriptEvent((transcriptEvent)=> {
            console.log('transcriptEvent:', transcriptEvent);
            if (transcriptEvent instanceof TranscriptionStatus) {
                if (transcriptEvent.type === TranscriptionStatusType.STARTED) {
                    enqueueSnackbar('Live transcription is started', {variant:'info'})
                    setTransitionOn(true);
                } else if (transcriptEvent.type === TranscriptionStatusType.STOPPED || transcriptEvent.type === TranscriptionStatusType.FAILED) {
                    enqueueSnackbar('Live transcription is stopped', {variant:'info'})
                    setTransitionOn(false);
                    setTransitionText("");
                }
            } else if (transcriptEvent instanceof Transcript) {
                for (const result of transcriptEvent.results) {
                      const transitionMessage = result.alternatives[0].transcript;
                      setTransitionText(transitionMessage);
                }
            }
        })
        return () => {
            audioVideo.transcriptionController?.unsubscribeFromTranscriptEvent(transcriptEvent => {
                console.log('unsubscribeFromTranscriptEvent:', transcriptEvent);
            })
        };
    }, [audioVideo]);


    const handler = useCallback(
        (dataMessage: DataMessage) => {
            const data = dataMessage.json();
            console.log('ControlDataMessageProvider:', data);
            switch(data.type) {
                case ControlDataMessagesType.Layout:
                    setLayout(data.layout);
                    break;
                case ControlDataMessagesType.MuteAll:
                    setMuteAll(data.muteAll);
                    break;
                case ControlDataMessagesType.CloseAllVideo:
                    setCloseAllVideo(data.closeAllVideo);
                    break;
                case ControlDataMessagesType.CloseChat:
                    setCloseChat(data.closeChat);
                    break;
                case ControlDataMessagesType.ForbiddenShareContent:
                    setForbiddenShareContent(data.forbiddenShareContent);
                    break;
                case ControlDataMessagesType.TopUser:
                    setTopUser(data.topUser)
                    break;
                case ControlDataMessagesType.RaiseHand:
                    setRaiseHand(data.raiseHand)
                    break;
                case ControlDataMessagesType.LockMaterial:
                    setLockMaterial(data.lockMaterial)
                    break;
                default: 
                    throw new Error('Incorrect action in ControlDataMessagesProvider');
            }
        }
    ,[])


    useEffect(() => {
        if (raiseHand) {
            if (raiseHandTimeout) {
                clearTimeout(raiseHandTimeout);
            }
            const timeOut = setTimeout(()=> {
                setRaiseHand('');
            }, 3000)
            setRaiseHandTimeout(timeOut);
        }
    }, [raiseHand])

    const sendMessage = useCallback(
        (message: ControlDataMessage) => {
            console.log('CONTROL_DATA_MESSAGE_TOPIC sendMessage', message)
            if (
                !meetingManager ||
                !meetingManager.meetingSession ||
                !meetingManager.meetingSession.configuration.credentials ||
                !meetingManager.meetingSession.configuration.credentials.attendeeId ||
                !audioVideo
              ) {
                return;
            }
            audioVideo.realtimeSendDataMessage(CONTROL_DATA_MESSAGE_TOPIC, message, 0);
        }
    , [audioVideo])


    useEffect(() => {
        console.log('meetingLoading :', meetingLoading);
    }, [meetingLoading])

    useEffect(() => {
        console.log('audioVideo:', audioVideo);
        if (!audioVideo) {
          return;
        }
    
        const observer: AudioVideoObserver = {
          audioVideoDidStart: () => {
            console.log('meetingLoading audioVideoDidStart');
            setMeetingLoading(false);
          },
          audioVideoDidStop: (sessionStatus) => {
            console.log('audioVideoDidStop:', sessionStatus);
            if (sessionStatus.statusCode() === MeetingSessionStatusCode.AudioJoinedFromAnotherDevice) {
              setAlertDialogData({
                showAlertDialog: true,
                alertDialogTitle: "Your account has joined in another place",
                exitAfterClose: true,
              })  
            } else if (sessionStatus.statusCode() === MeetingSessionStatusCode.MeetingEnded) {
              setAlertDialogData({
                showAlertDialog: true,
                alertDialogTitle: "This class has ended",
                exitAfterClose: true,
              })  
            }
          },
          connectionDidBecomePoor: () => {
            // enqueueSnackbar("Your connection is poor", {variant:'warning'})
            // setAlertDialogData({
            //   showAlertDialog: true,
            //   alertDialogTitle: "Your connection is poor",
            //   exitAfterClose: false,
            // })
            enqueueSnackbar("Your connection is poor", {variant:'info'})
          },
          connectionDidSuggestStopVideo: () => {
            const alertMessage = "Your internet connection is unstable";
            // enqueueSnackbar(alertMessage, {variant:'warning'})
            // setAlertDialogData({
            //   showAlertDialog: true,
            //   alertDialogTitle: alertMessage,
            //   exitAfterClose: false,
            // })

            enqueueSnackbar(alertMessage, {variant:'info'})
          },
        };
        audioVideo.addObserver(observer);
        
    
        return (): void => {
            Promise.resolve(() => audioVideo.removeObserver(observer));
            // setTimeout(() => audioVideo.removeObserver(observer), 500);
        }
    }, [audioVideo]);

    const handleClickConfirmAlertDialog = () => {
        const shouldExit = alertDialogData.exitAfterClose;
        setAlertDialogData({
          showAlertDialog: false,
          alertDialogTitle: "",
          exitAfterClose: false,
        })
        shouldExit && onExitMeeting();
    }
    
    
    const providerValue = {
        recordStart: meetingDataConfigs.meetingControls.recordStart,
        meetingRecord: meetingDataConfigs.meetingControls.meetingRecord,
        meetingType,
        queryMaterialId,
        meetingId,
        teacherId: meetingDataConfigs.teacherId,
        meetingStarted,
        imageBlob,
        layout,
        muteAll,
        closeAllVideo,
        closeChat,
        forbiddenShareContent,
        transitionLanguage,
        transitionText,
        transitionOn,
        displayTransition, 
        localAttendeeId: meetingDataConfigs.attendeeInfo.AttendeeId,
        localUserName: getDisplayUserName(credential?.name || '', credential?.id  || ''),
        localUserId: credential?.id  || '',
        isTeacher,
        isStudent: !isTeacher,
        initOpenVideo,
        topUser,
        raiseHand,
        meetingLoading,
        isMobile,
        joinTimestamp,
        lockMaterial,
        hostTeacherId,
        setMeetingStarted,
        onExitMeeting: () => {
            meetingManager.leave();
            onExitMeeting();
        },
        setBlob,
        setLayout,
        setMuteAll,
        setCloseAllVideo,
        setCloseChat,
        setForbiddenShareContent,
        sendMessage,
        setTransitionLanguage,
        setDisplayTransition,
        setInitOpenVideo,
        setTopUser,
        setRaiseHand,
        setLockMaterial,
    }

    return (
        <MeetingStateContext.Provider value={providerValue}>
            <>
                <Dialog
                    fullWidth={true}
                    open={alertDialogData.showAlertDialog}  
                >
                    <DialogTitle> {alertDialogData.alertDialogTitle} </DialogTitle>
                    <DialogActions>
                    <Button onClick={()=> handleClickConfirmAlertDialog()} color="primary">
                        OK
                    </Button>
                    </DialogActions>
                </Dialog>
                {children}
                </>
        </MeetingStateContext.Provider>
    );
}

const CONTROL_DATA_MESSAGE_TOPIC = 'VeekoControlDataMessage';