import { Avatar, Button, Checkbox, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CartItem } from 'src/app/models'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { removeFromCart, selectItem, unselectItem } from 'src/app/service/cart-slice'
import { useDispatch } from 'react-redux'

interface VideoCartItemProps {
  cartItem: CartItem
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '20px 0'
  },
  image: {
    height: 100
  },
  container: {
    margin: '0 20px',
    width: '100%'
  },
  title: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#000000',
    lineHeight: '22px',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },
  channel: {
    margin: '10px 0',
    fontSize: '14px',
    color: '#717171',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}))

export const VideoCartItem: React.FC<VideoCartItemProps> = ({ cartItem }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { video, isSelected, quantity } = cartItem

  const handleOnItemSelected = (e, selected) => {
    if (selected) {
      dispatch(selectItem(video))
    } else {
      dispatch(unselectItem(video))
    }
  }

  const handleItemRemoved = () => {
    dispatch(removeFromCart(video))
  }

  return (
    <div className={classes.root}>
      <img src={video.thumbnail} alt={video.title} className={classes.image} />
      <div className={classes.container}>
        <Typography variant="h5" color="primary" className={classes.title}>
          {video.title}
        </Typography>
        <div className={classes.channel}>
          <Avatar
            style={{ marginRight: 10 }}
            alt={video.channel?.name}
            src={video.channel?.avatar}
          />
          <div style={{ flexGrow: 1 }}>
            <Typography>{video.channel?.name}</Typography>
            <Typography variant="caption">
              {video.view} views * {video.timeCreated} housrs ago
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.channel}>
        <AttachMoneyIcon />
        <Typography>{video.price}</Typography>

        <div style={{ justifyContent: 'flex-start', marginLeft: 20 }}>
          <Typography variant="caption">Quantity: {quantity}</Typography>
          <Button onClick={handleItemRemoved}>Remove</Button>
        </div>
      </div>
      <div className={classes.channel} style={{ margin: '0 50px' }}>
        <Checkbox
          onChange={handleOnItemSelected}
          checked={isSelected}
          inputProps={{ 'aria-label': video.id }}
        />
      </div>
    </div>
  )
}
