import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
  showChatDialog: boolean,
  showCount: number,
  chatTeacherId?: string,
  unreadMessageCount: number,
}

const initState: AuthState = {
  showChatDialog: false,
  showCount: 0,
  unreadMessageCount: 0,
}

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState: initState,
  reducers: {
    showChatDialog(state, action: PayloadAction<string|undefined>) {
      state.showChatDialog = true;
      state.chatTeacherId = action.payload;
      state.showCount++;
    },
    closeChatDialog(state) {
      state.showChatDialog = false;
    },
    setUnreadMessageCount(state, action: PayloadAction<number>) {
      state.unreadMessageCount = action.payload;
    }
  }
})

export const { showChatDialog, closeChatDialog, setUnreadMessageCount } = chatSlice.actions

export default chatSlice.reducer