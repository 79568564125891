import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'

export const assignmentApi = createApi({
  reducerPath: 'assignmentApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getIsGoogleAuth: builder.mutation<Result<any>, { 
      }>({
        query: () => ({
          url: `oauth`,
          method: 'POST',
          body: { 
            request_type: 2,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      //TODO test
      getAssignment: builder.query<Result<any>, { 
        seriesId: string,
      }>({
        query: ({ seriesId }) => ({
          url: `assignment`,
          method: 'POST',
          body: {
            request_type: 6,
            search_assignment: {
              series_id: seriesId,
              page_size: 99,
              start_key: '',
            }
            
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      requestGoogleAuthUrl: builder.mutation<Result<any>, {
        callbackUrl: string 
      }>({
        query: ({callbackUrl}) => ({
          url: `oauth`,
          method: 'POST',
          body: {
            request_type: 3,
            callback_url: callbackUrl
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      submitGoogleAuth: builder.mutation<Result<any>, {
        code: string,
        scope: string,
        callbackUrl: string 
      }>({
        query: ({code, scope, callbackUrl}) => ({
          url: `oauth`,
          method: 'POST',
          body: {
            request_type: 4,
            code: code,
            scope: scope,
            callback_url: callbackUrl
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      // TODO test
      getIsAssignmentMember: builder.mutation<Result<any>, {
        assignmentId: string
      }>({
        query: ({
          assignmentId
        }) => ({
          url: `assignment`,
          method: 'POST',
          body: {
            request_type: 5,
            user_assignment_role: {
              assignment_id: assignmentId
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      joinAssignment: builder.mutation<Result<any>, {
        assignmentId: string,
      }>({
        query: ({assignmentId}) => ({
          url: `assignment`,
          method: 'POST',
          body: {
            request_type: 4,
            invitation_student: {
              assignment_id: assignmentId
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      userAssignmentRole: builder.mutation<Result<any>, {
        assignmentId: string,
      }>({
        query: ({assignmentId}) => ({
          url: `assignment`,
          method: 'POST',
          body: {
            request_type: 5,
            user_assignment_role: {
              assignment_id: assignmentId
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { 
  useGetIsGoogleAuthMutation,
  useGetAssignmentQuery,
  useRequestGoogleAuthUrlMutation,
  useSubmitGoogleAuthMutation,
  useGetIsAssignmentMemberMutation,
  useJoinAssignmentMutation,
  useUserAssignmentRoleMutation,
} = assignmentApi
