import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import { Box, Typography, Divider, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'src/app/toolkit/store'
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LiveAndUpcomingLive } from './LiveAndUpcomingLive'
import { LiveInfo, TAB_VALUES } from '../Course'
import { CourseEntity, SeriesTeacherEntity, TeacherEntity } from '@dokyo/common'
import { RecordedClass } from './RecordedClass'
import { ClassroomMaterialCardEntity } from 'src/components/cards/ClassroomMaterialCard'
import { MaterialList } from './MaterialList'
import { OtherCourses } from './OtherCourses'
import IcoBsck from 'src/images/course/ico-back.png'
import { MaterialTypeEnum } from 'src/app/models/victoriaClass'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "90%",
    maxWidth: '1400px',
    margin: '0 auto',
    textAlign: 'center',
    padding: '0px 50px 50px 50px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  title: {
    margin: '33px 0',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Avenir-Black, Avenir',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      margin: '20px 0 10px',
    }
  },
  aboutCourseInfo: {
    fontFamily: 'ArialMT',
    width: "100%",
    textAlign: "left",
    "&:>img":{
      width:"100% !important"
    }
  },
  aboutCourseInfoHidden: {
    fontFamily: 'ArialMT',
    width: "100%",
    textAlign: "left",
    overflow:"hidden",
    textOverflow: "ellipsis",
    marginBottom:"30px",
    // height: "190px",
    // whiteSpace: "nowrap",
    border:"1px solid black",
    display: "inline-block",
    "&:>img": {
      width: "100% !important"
    },
  },
  seeMore:{
    display: "flex",
    color: "#307DCF",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontFamily: "Arial-BoldMT, Arial",
    fontWeight: "normal",
    marginTop: "-20px",
    marginBottom: "27px",
    cursor: "pointer",
    "&:hover": {
      color: "#003976"
    },
  }
})
)
interface Props {
  courseId: string,
  seriesClassInfo: string,
  teachers: SeriesTeacherEntity[],
  recordedList: CourseEntity[],
  liveInfo: LiveInfo,
  meetingList: any[],
  materialList: ClassroomMaterialCardEntity[],
  enableDownloadMaterial: boolean,
  onSwitchTab: (tabValue: number) => void,
  isSeriesTeacher: boolean,
  handleLock: (id: string, lock: boolean, oldMaterial?: ClassroomMaterialCardEntity) => void,
}

export const CourseInfo: React.FC<Props> = ({ courseId, seriesClassInfo, teachers, recordedList, liveInfo, meetingList, materialList, enableDownloadMaterial, onSwitchTab, isSeriesTeacher, handleLock }) => {
  const classes = useStyles()
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSeeMore, setisSeeMore] = useState(true);

  // const seeMore=()=>{
  //   setisSeeMore(false)
  // }
  const aboutCourseInfoText = useRef<any>()
  // useEffect(()=>{
    
  //   const currentNode = aboutCourseInfoText.current
    
  //   const clientWidth = currentNode.clientWidth
  //   const scrollWidth = currentNode.scrollWidth
  //   console.log("node:", currentNode, clientWidth, scrollWidth)
  // },[])
  return (
    <Box>

      <LiveAndUpcomingLive courseId={courseId} liveInfo={liveInfo} meetingList={meetingList} />
      <RecordedClass courseId={courseId} recordedList={recordedList} onClickMore={() => onSwitchTab(TAB_VALUES.RECORDED_CLASS)} />

      <Box className={classes.container}>
        <Box className={classes.title}>
          About Course
        </Box>
        <Box
          ref={aboutCourseInfoText}
          className={classes.aboutCourseInfo}
          // className={isSeeMore ? classes.aboutCourseInfoHidden : classes.aboutCourseInfo}
          sx={matches ? { marginTop: '10px', fontSize: "12px", overflow: "scroll", '&::-webkit-scrollbar': { display: 'none' } } : { marginTop: '50px', fontSize: "16px" }}
        >
          {ReactHtmlParser(seriesClassInfo)}
        </Box>
        {/* {isSeeMore && <Box className={classes.seeMore}>
          <Typography onClick={seeMore}>View All</Typography>
          <img src={IcoBsck} alt="Back" style={{ width: "17px", height: "7px", marginLeft: "6px" }} />
        </Box>} */}
        
        {/* AI + VR course */}
        {(courseId === 'fc1158ac-7060-45b9-b143-75353bbed254' || courseId === '529a4683-1dd0-4d93-9995-1decc164d478') &&
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/YZCR2aROBp4"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ marginTop: "20px" }}
          >
          </iframe>
        }
      </Box>

      <MaterialList 
        materialType={MaterialTypeEnum.Course}
        materialList={materialList} 
        apiEnableDownload={enableDownloadMaterial} 
        onClickMore={() => onSwitchTab(TAB_VALUES.CLASSROOM_MATERIAL)} 
        isLessonTeacher={isSeriesTeacher}
        handleLock={handleLock} />

      <OtherCourses courseId={courseId} teacher={teachers?.length > 0 ? teachers[0] : undefined} />
    </Box>
  )
}