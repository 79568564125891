
import * as React from 'react';
import { Avatar, Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

interface PropsForm {
  showDialog: boolean;
  pictureUrl: string;
  onClose: () => void;
}

const PreviewPicture: React.FC<PropsForm> = (props) => {
  const { showDialog, onClose, pictureUrl } = props;
  
  return (
    <>
      <Dialog
        maxWidth="lg"
        open={showDialog}
      >
        <DialogContent style={{fontSize:'16px'}}>
          <img alt='preview-pic' style={{maxHeight: "500px", maxWidth: "100%", width:"auto", height: "auto", objectFit:"contain"}} src={pictureUrl}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={{color:'black', textTransform:'none'}}>Close</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default PreviewPicture;