import { FC } from 'react';
import Switch from '@mui/material/Switch';
import {
    useLocalVideo,
  } from 'amazon-chime-sdk-component-library-react';
  import {
    useMeetingState
} from '../../providers/MeetingStateProvider';



const VideoSwitch: FC = () => {
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const { setInitOpenVideo } = useMeetingState();
    
        
        const handleClick = () => {
            console.log('VideoSwitch:!isVideoEnabled', isVideoEnabled);
            setInitOpenVideo(!isVideoEnabled);
            toggleVideo()
        }

    return (
        <Switch
            checked={isVideoEnabled}
            onChange={handleClick}
            name="muteAll"
        />
    )
}

export default VideoSwitch;