import { CardProps } from '@mui/material/Card'
import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { Opacity } from '@mui/icons-material';


const useStyles = makeStyles(theme => ({

}))


const StudentsAreViewingSeries = {
    "card": [
        {
            "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
            "tilte": "Discover Art History with",
            "teacherNmae": "IManuela Sadovnik",
       
        },
        {
            "picture": "https://share.dev.veeko.com/us-east-1/GraemeJohnson/Thumbnail/E2.png",
            "tilte": "Build Your Own Creativity in Piano Dr.Mckay",
            "teacherNmae": "Tristan Mckay",
            
        },
        {
            "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
            "tilte": "Python for Data Analyst",
            "teacherNmae": "Amanda Kaya",
           
        },
        {
            "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
            "tilte": "Python for Data Analyst",
            "teacherNmae": "Astra Liucija",
           
        },
        {
            "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
            "tilte": "Observing Drawing With Astra!",
            "teacherNmae": "Astra Liucija",
        }

    ]
}


export const CardItem: React.FC<{
    itemData: any;
    index: number;
}> = ({itemData, index}) => {
    return (
        <Box
            key={index}
        >
            <Card
                    style={{
                        width:'240px',
                        // marginRight:'20px',
                        borderRadius:'12px',
                        backgroundColor: 'transparent',
                        boxShadow:'0px 0px 0px rgba(0,0,0,0)',
                        

                    }}>
                        <CardActionArea
                        style={{
                            marginBottom:'16px',
                            borderRadius:'12px'
                        }}>
                            <CardMedia
                                component="img"
                                // height="140"
                                image={itemData.picture}
                                alt="green iguana"
                                style={{
                                    width: '240px',
                                    height: '160px',
                                    marginBottom:'16px',
                                    borderRadius:'12px'
                                }}
                            />
                            <CardContent
                            style={{
                                padding:'0px',
                                marginBottom:'10px',
                            }}>
                                <Typography
                                    style={{
                                        width:'240px',
                                        height: '20px',
                                        fontSize: '18px',
                                        fontFamily: 'ArialMT',
                                        color: '#000000',
                                        lineHeight: '21px',
                                        letterSpacing: '0px',
                                    }}>
                                    {itemData.tilte}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions 
                        style={{
                            padding:'0px',
                            // marginBottom:'60px',
                        }}>
                            <Button
                                style={{
                                    height: '24px',
                                    fontSize: '14px',
                                    fontFamily: 'ArialMT',
                                    color: '#555555',
                                    lineHeight: '24px',
                                    letterSpacing: '-0.34px',
                                    padding:'0px'
                                    
                                }}>
                                {itemData.teacherNmae}
                            </Button>
                        </CardActions>
                    </Card>
        </Box>
    );
}


export const SampleClassesCard: React.FC = () => {
    const classes = useStyles()
    // const { color } = props
    return (
        <Box
            style={{
                display: "flex",
                justifyContent: 'space-between',
                // marginLeft: '70px',
                // marginRight: '70px',
                width: '100%',

            }}
        >
            {StudentsAreViewingSeries.card.map((v, i) => (
                <div key={i}>
                    <Card
                    style={{
                        width:'240px',
                        // marginRight:'20px',
                        borderRadius:'12px',
                        backgroundColor: 'transparent',
                        boxShadow:'0px 0px 0px rgba(0,0,0,0)',
                        

                    }}>
                        <CardActionArea
                        style={{
                            marginBottom:'16px',
                            borderRadius:'12px'
                        }}>
                            <CardMedia
                                component="img"
                                // height="140"
                                image={v.picture}
                                alt="green iguana"
                                style={{
                                    width: '240px',
                                    height: '160px',
                                    marginBottom:'16px',
                                    borderRadius:'12px'
                                }}
                            />
                            <CardContent
                            style={{
                                padding:'0px',
                                marginBottom:'10px',
                            }}>
                                <Typography
                                    style={{
                                        width:'240px',
                                        height: '20px',
                                        fontSize: '18px',
                                        fontFamily: 'ArialMT',
                                        color: '#000000',
                                        lineHeight: '21px',
                                        letterSpacing: '0px',
                                    }}>
                                    {v.tilte}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions 
                        style={{
                            padding:'0px',
                            // marginBottom:'60px',
                        }}>
                            <Button
                                style={{
                                    height: '24px',
                                    fontSize: '14px',
                                    fontFamily: 'ArialMT',
                                    color: '#555555',
                                    lineHeight: '24px',
                                    letterSpacing: '-0.34px',
                                    padding:'0px'
                                    
                                }}>
                                {v.teacherNmae}
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            ))}
        </Box>
    )
}


