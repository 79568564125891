import React from "react";
import { makeStyles } from '@mui/styles'
import { Box, Theme } from "@mui/material";
import picTeacher from 'src/images/teacherinfo/pic-teacher.png'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';
import Icon from 'src/images/teacherinfo/ico-sign.png'

const useStyles = makeStyles((theme: Theme) => ({
    rootBox: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "45px 131px",
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        }
    },
    ContentArea: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    teacherPhoto: {
        width: "464px",
        height: "353px",
        marginRight: "85px",
        [theme.breakpoints.down('md')]: {
            width: "50%",
            height: "100%",
            marginRight: "0px",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    teacherPhotoImg: {
        width: "100%"
    },
    teacherInfo: {
        [theme.breakpoints.down('md')]: {
            padding: "5px"
        }
    },
    teacherInfoTitle1: {
        fontSize: "18px",
        fontFamily: "Avenir",
        fontWeight: "normal",
        color: "#307DCF",
        lineHeight: "30px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "10px",
        }
    },
    teacherInfoTitle2: {
        fontSize: "40px",
        fontFamily: "Avenir",
        fontWeight: "900",
        color: "#000000",
        marginTop: "23px 0 37px",
        [theme.breakpoints.down('md')]: {
            fontSize: "30px",
            lineHeight: "40px"
        }
    },
    teacherInfoContent: {
        width: "514px",
        fontSize: "18px",
        fontFamily: "Avenir",
        fontWeight: "normal",
        color: "#555555",
        marginBottom: "20px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        }
    },
    teacherInfoListItem: {
        width: "537px",
        fontSize: "18px",
        fontFamily: "Avenir-Medium, Avenir",
        fontWeight: "500",
        color: "#000000",
        display: "flex",
        marginBottom: "10px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    teacherInfoListItemImage: {
        width: "21px",
        height: "21px",
        marginTop: "2px",
        marginRight: "9px"
    },
    teacherInfoListItemText: {
        fontSize: "18px",
        fontFamily: "Avenir-Medium, Avenir",
        fontWeight: "500",
        color: "#000000",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        }
    }
}))
const Instructor: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const listData = {
        teacherName: "Manuela Sadovnik",
        teacherMessage: "She blends artistic ability with awareness for art history as well as a pleasant teaching style. With her vast historical knowledge and captivating personality, she elevates history education to a whole new level. She teaches history in such a way that it is transformed from a dull subject into a thrilling tale of grandeur that everyone can enjoy and learn from.",
        teacherInfoList: [
            {
                id: "1",
                img: Icon,
                text: "Bachelor’s degree in Visual Arts from the Pontificia Universidad Javeriana"
            },
            {
                id: "2",
                img: Icon,
                text: "Specialization in Cultural and Art Management"
            },
            {
                id: "3",
                img: Icon,
                text: "International exchange program scholarship"
            }
        ]
    }
    return (
        <Box className={classes.rootBox}>
            <Box className={classes.ContentArea}>
                <Box className={classes.teacherPhoto}>
                    {isMobile ? <Box className={classes.teacherInfoTitle1} >Instructor</Box> :""}
                    <img src={picTeacher} className={classes.teacherPhotoImg} />
                </Box>
                <Box className={classes.teacherInfo}>
                    {isMobile ? "" : <Box className={classes.teacherInfoTitle1} >Instructor</Box>}
                    <Box >
                        <Box className={classes.teacherInfoTitle2} >{listData.teacherName}</Box>
                    </Box>
                    <Box className={classes.teacherInfoContent} >{listData.teacherMessage}</Box>
                    <Box >
                        {listData.teacherInfoList.map(item => (
                            <Box key={item.id} className={classes.teacherInfoListItem}>
                                <img src={item.img} className={classes.teacherInfoListItemImage} />
                                <Box className={classes.teacherInfoListItemText}>{item.text}</Box>
                            </Box>
                        ))}
                    </Box>
                </Box >
            </Box >
        </Box >
    )
}
export default Instructor

