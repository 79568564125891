import { FC, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SpeakerSelection from './SpeakerSelection';
import { TestSound } from 'src/utils/chime'
import {
    useAudioOutputs,
  } from 'amazon-chime-sdk-component-library-react';

const SpeakerDevices: FC = () => {
    const { selectedDevice } = useAudioOutputs();
    const [selectedOutput, setSelectedOutput] = useState(selectedDevice);
    const handleChange = (deviceId: string): void => {
        // setSelectedOutput(deviceId);
    };

    useEffect(() => {
        setSelectedOutput(selectedDevice);
    }, [selectedDevice])

    const handleTestSpeaker = () => {
        console.log(selectedDevice);
        new TestSound(selectedOutput);
    };
    return (
        <>
            <SpeakerSelection onChange={handleChange} />
            <Button 
                onClick={handleTestSpeaker} 
                variant="outlined"
                sx={{
                    textTransform: 'none'
                }}
            >
                Test speakers
            </Button>
        </>
    )
}


export default SpeakerDevices;