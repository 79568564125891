import { FC } from 'react';
import {
    useAudioOutputs,
    useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import DeviceInput from '../../deviceInput';
interface Props {
    onChange?: (selectedAudioOutputDevice: string) => void;
    label?: string;
    showLabel?: boolean;
    inline?: boolean;
}

const SpeakerSelection: FC<Props> = ({
    onChange,
    showLabel,
    inline,
    label = 'Speaker source'
}) => {
    const { devices, selectedDevice } = useAudioOutputs();
    const meetingManager = useMeetingManager();

    const handleSelect = async(deviceId: string): Promise<void> => {
        if (deviceId === 'not-available') {
            return;
        }
        try {
            await meetingManager.startAudioOutputDevice(deviceId);
            onChange && onChange(deviceId);
        } catch (error) {
            console.error('MicSelection failed to select mic', error);
            // throw new Error('MicSelection failed to select mic');
        }
    };

    
    return (
        <DeviceInput 
            label={label}
            inputId='speaker-selection-select'
            notFoundMsg='No speaker devices found'
            devices={devices}
            selectedDevice={selectedDevice}
            onChange={handleSelect}
            showLabel={showLabel}
            inline={inline}
        />
    )
}


export default SpeakerSelection;