
import * as React from 'react';
import { Button, Theme, Box, Typography, Avatar, Tooltip  } from "@mui/material";
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import SvgComponent from 'src/components/svgComponent';
import header_config from 'src/pages/home/header_config.json'
import { AdsSlider } from './AdsSlider';
const useStyles = makeStyles((theme : Theme) => 
  ({
    App : {
      width: "100%",
      height: "100%",
      background: "#252525",
      borderRadius: "5px"
    },
    videoBig: {
      width: "100%",
      height: "60%",
      borderRadius: "5px"
    },
    waitingBox: {
      height: "30%",
      textAlign: "center",
      paddingTop: "30px"
    },
    classroomControl: {
      height: "10%",
      paddingTop: "20px",
      alignSelf: "stretch",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    buttonControl: {
      cursor: "pointer",
      margin: "10px"
    },
  })
);

interface PropsForm {
  onExitWaiting: () => void;
}

const WaitingRoom: React.FC<PropsForm> = (props) => {
  const { onExitWaiting } = props;
  const classes = useStyles();
  
  const [showExitDialog, setShowExitDialog] = React.useState(false);
  
  // React.useEffect(()=>{

  // },[]);
  
  return (
    <>
      <Box>
        <Box className={classes.App}>
          <Box className={classes.videoBig}>
           <AdsSlider config={header_config} dots={header_config.Headers.length}/>
          </Box>
          <Box className={classes.waitingBox}>
            <SvgComponent iconName={"iconWaiting"} className="icon-source" style={{marginBottom: "8px"}}/>
            <Typography color="white">Waiting for teacher to start the classroom…</Typography>
          </Box>
          <Box className={classes.classroomControl} >
            <Tooltip title="Leave Classroom" placement="top">
              <Avatar className={classes.buttonControl} onClick={()=>{setShowExitDialog(true)}} src="https://portal.veeko.com/img/ic_quit.svg"/>
            </Tooltip>
          </Box>
        </Box>

        <Dialog
          fullWidth={true}
          open={showExitDialog}
          onClose={()=>{setShowExitDialog(false)}}
        >
          <DialogTitle id="alert-dialog-title">{"Leave this classroom?"}</DialogTitle>
          <DialogActions>
            <Button onClick={()=>{setShowExitDialog(false)}} color="info" style={{textTransform: "none"}}>
              Cancel
            </Button>
            <Button onClick={onExitWaiting} color="secondary" autoFocus style={{textTransform: "none"}}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        
      </Box>
    </>
  )
}

export default WaitingRoom;