import * as React from 'react'
import { Box } from '@mui/material'
import { getMonthFirstDayFormatTimestamp, getNextMonthFirstDayFormatTimestamp, getDayZeroClockFormatTime  } from 'src/utils/timeUtil'
import { Loader } from 'src/components/loader'
import { AppointmentItem, ScheduleEnum, TimeInterval, BookStatusEnum, UserScheduleEntity, ScheduleLessonTypeEnum, UserScheduleSourceTypeEnum, LessonClassesStatusEnum, PrivateLessonClassesTypeEnum } from 'src/app/models/schedule'
import { Calendar as CalendarComponent } from 'src/components/calendar'
import { useSelector } from 'src/app/toolkit/store'
import { getAuthRole } from 'src/utils/auth'
import { useGetTeacherAllScheduledClassMutation } from 'src/app/service/real/teacher.slice'
import { BroadcastEntity } from '@dokyo/common'
import { broadcastToAppointmentItem, videoClassroomToAppointmentItem } from 'src/pages/studio/mySchedule/MyScheduleCalendar'
import { useGetLessonScheduleByRoleMutation } from 'src/app/service/real/serieslesson-slice'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSnackbar } from 'notistack'

interface Props {
  teacherId: string;
  teacherName: string;
}

const TeacherScheduleCalendar: React.FC<Props> = ({teacherId, teacherName}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {credential} = useSelector(state => state.app);
  const authRole = getAuthRole()();
  const [ appointmentItems, setAppointmentItems ] = React.useState<AppointmentItem[]>([])
  const [ timeInterval, setTimeInterval ] = React.useState<TimeInterval>({
    start_time: 0,
    end_time: 0
  })
  const [getTeacherScheduleList, { isLoading: loading0 }] = useGetLessonScheduleByRoleMutation();
  //This API cost to much time
  const [getTeacherAllScheduledClass, {isLoading: loading1}] = useGetTeacherAllScheduledClassMutation();
  
  React.useEffect(() => {
    if (timeInterval.start_time > 0 && timeInterval.end_time > 0) {
      setAppointmentItems([])
      refreshData();
    }
  }, [timeInterval])

  const lessonClassToAppointmentItem = (schedules: UserScheduleEntity[]): AppointmentItem[] => {
    const appointmentItems: AppointmentItem[]  = []
    schedules.forEach(schedule => {
      if (schedule && schedule.end_time > new Date().getTime()) {
        if (schedule.source_type === UserScheduleSourceTypeEnum.SERIES_LESSON_CLASSES) {
          const newItem: AppointmentItem = {
            id: schedule.series_lesson_classes?.series_lesson?.id || (Math.random()+''),
            classRoomId: schedule.series_lesson_classes?.id || '',
            title: schedule.series_lesson_classes?.series_lesson?.title||'Series Lesson Class',
            startDate: new Date(schedule.start_time),
            endDate: new Date(schedule.end_time),
            schedule: ScheduleEnum.TeacherSideSeriesLesson,
            teacherName: teacherName,
            teacherId: teacherId,
            bookList: schedule.series_lesson_classes?.student_user_id_list ? schedule.series_lesson_classes.student_user_id_list?.map(value => {return{user: {id: value, name:'', avatar: ''}, message:''}}) : [],
            lessonType: ScheduleLessonTypeEnum.SeriesLesson,
            booked: true,
            bookStatus: getStatus(schedule.private_lesson_classes?.status|| LessonClassesStatusEnum.Published),
            secondTeachers: schedule.series_lesson_classes?.second_teacher || schedule.series_lesson_classes?.series_lesson?.second_teacher || [],
          }
          appointmentItems.push(newItem)
        } else if (schedule.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON_CLASSES) {
          const newItem: AppointmentItem = {
            id: schedule?.private_lesson_classes?.id || (Math.random()+''),
            classRoomId: schedule.private_lesson_classes?.classes_id || '',
            title: schedule.private_lesson_classes?.title||'Private Lesson Class',
            startDate: new Date(schedule.start_time),
            endDate: new Date(schedule.end_time),
            schedule: ScheduleEnum.TeacherSideBookedTime,
            teacherName: teacherName,
            teacherId: teacherId,
            bookList: schedule.private_lesson_classes?.student_user_id ? [{user: {id: schedule.private_lesson_classes?.student_user_id, name:'', avatar: ''}, message:''}] : [],
            lessonType: schedule.private_lesson_classes?.classes_type === PrivateLessonClassesTypeEnum.Student_Create ? ScheduleLessonTypeEnum.OneToOneLesson : ScheduleLessonTypeEnum.CustomPrivateLesson,
            booked: true,
            bookStatus: getStatus(schedule.private_lesson_classes?.status|| LessonClassesStatusEnum.Scheduled),
            secondTeachers: schedule.private_lesson_classes?.second_teacher || [],
          }

          appointmentItems.push(newItem)
        } else if (schedule.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON) {
          const times = schedule.private_lesson?.free_time_area;
          if (times && times?.length > 0) {
            times.forEach(item => {
              const newItem: AppointmentItem = {
                id: schedule.private_lesson?.id || (Math.random()+''), //TODO id不唯一
                title: schedule.private_lesson?.title || 'Available Time',
                startDate: new Date(item.start_time),
                endDate: new Date(item.end_time),
                schedule: ScheduleEnum.TeacherAvailableTime,
                teacherName: teacherName,
                teacherId: teacherId,
                lessonType: ScheduleLessonTypeEnum.OneToOneLesson,
                booked: false,
                bookStatus: BookStatusEnum.AVAILABLE_TIME,
                timeRepeatId: schedule.private_lesson?.repeat_id,
                secondTeachers: schedule.private_lesson?.second_teacher || [],
              }
              appointmentItems.push(newItem);
            })
          }
        }
      }
    })
    return appointmentItems
  }

  const getStatus = (status: LessonClassesStatusEnum): BookStatusEnum => {
    if (status === LessonClassesStatusEnum.Published) {
      return BookStatusEnum.PAID
    } else if (status === LessonClassesStatusEnum.Scheduled) {
      return BookStatusEnum.PAID
    } else if (status === LessonClassesStatusEnum.Cancel) {
      return BookStatusEnum.CANCELED
    } else if (status === LessonClassesStatusEnum.Finish) {
      return BookStatusEnum.CLOSED
    } else {
      return BookStatusEnum.CLOSED
    }
  }

  const refreshData = () => {
    setAppointmentItems([])

    // get series lessons as teacher
    getTeacherScheduleList({
      userId: teacherId,
      startTime: timeInterval.start_time,
      endTime: timeInterval.end_time,
      roleType: 'teacher',
    })
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const lessonList: UserScheduleEntity[] = dataRes.result.user_schedule_list;
        let allItems: AppointmentItem[] = [];
        if (lessonList) {
          const items = lessonClassToAppointmentItem(lessonList);
          allItems = allItems.concat(items);
        }
        setAppointmentItems(items => [...allItems, ...items]);
      } else {
        enqueueSnackbar(dataRes?.message || "Can not get series data")
      }
    })

    // get live stream and live video class data
    getTeacherAllScheduledClass({
      teacher_id: teacherId,
      start_time: timeInterval.start_time/1000,
      end_time: timeInterval.end_time/1000,
      page_size: 999,
    }).unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        const schedules = res.result.teacher_schedule;
        if (schedules?.length > 0) {
          let allItems: AppointmentItem[] = [];
          schedules.forEach(schedule => {
            if (schedule.title === 'live') {
              const liveSchedules = schedule.live_schedule;
              if (liveSchedules?.length > 0) {
                liveSchedules.forEach(element => {
                  const broadcasts: BroadcastEntity[] = element.list;
                  const items = broadcastToAppointmentItem(broadcasts);
                  allItems = allItems.concat(items);
                });
              }
            } else if (schedule.title === 'meeting') {
              const meetingSchedule = schedule.meeting_schedule;
              if (meetingSchedule?.length > 0) {
                meetingSchedule?.forEach(element => {
                  const meetings: any[] = element.list;
                  const items = videoClassroomToAppointmentItem(meetings);
                  allItems = allItems.concat(items);
                });
              }
            }
          });
          setAppointmentItems(items => [...allItems, ...items]);
        }
      }
    })
  }

  const calculationTimeInterval = (nowDate: Date, calendarType: string): TimeInterval => {
    const result = {
      start_time: 0,
      end_time: 0
    };
    if (calendarType === '1') { // week
      result.start_time = getDayZeroClockFormatTime(nowDate, true);
      result.end_time = result.start_time + 7 * 24 * 60 * 60 * 1000;
    } else if (calendarType === '2') { //month
      result.start_time = getMonthFirstDayFormatTimestamp(nowDate, true) - 7 * 24 * 60 * 60 * 1000;
      result.end_time = getNextMonthFirstDayFormatTimestamp(nowDate, true) + 7 * 24 * 60 * 60 * 1000;
    }
    return result;
  }

  const onCalendarChange = (currentDate: Date, calendarType: string) => {
    const timeInterval = calculationTimeInterval(currentDate, calendarType);
    setTimeInterval(timeInterval)
  }

  const enableCreateTime = (): boolean => {
    if (authRole.isAdmin) {
      return true;
    }
    // if (authRole.isSchoolAdmin) {
    //   return true;
    // }
    if (authRole.isTeacher && credential?.id === teacherId) {
      return true;
    }
    return false;
  }

  return(
    <>
    <Box
      sx={{
        display:'flex',
        justifyContent:'center',
        marginTop:'38px',
        flexDirection: isMobile ? "column" : "row"
      }}
    >
       <CalendarComponent
        enableCreateAvailableTime={enableCreateTime()}
        viewTeacherID={teacherId}
        onCalendarChange={onCalendarChange}  
        appointmentItems={appointmentItems}
        onRefresh={refreshData}/>
    </Box>
    <Loader isLoading={loading0||loading1} />
    </>
  )
}
export default TeacherScheduleCalendar