import React  from 'react';
import { Box, Typography, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAuthStatus } from 'src/utils/auth'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import { useSelector } from 'src/app/toolkit/store';
import { useGetSeriesByTeacherQuery } from 'src/app/service/real/teacher.slice';
import { SeriesEntity } from '@dokyo/common';
import { SeriesCard } from 'src/components/cards';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import SvgComponent from 'src/components/svgComponent'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    minHeight: 'calc(100vh - 369px)',
    padding: '34px 0 0 30px',
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexFlow:'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: "20px",
      justifyContent: 'center'
    }
  },
  item: {
    margin: '0 24px 36px 0',
    [theme.breakpoints.down('sm')]: {
      margin:"0 0 20px 0"
    }
  },
  SignInInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: "60vh",
    paddingTop: "20vh",
  },
  button: {
    width:'136px',
    height: '48px',
  }
}))


export const MyTeachingCourse: React.FC = () => {

  const classes = useStyles();
  const navigate = useNavigate()
  const authStatus = getAuthStatus()()
  const {credential} = useSelector(state => state.app);
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {data: seriesRes, isFetching, isSuccess} = useGetSeriesByTeacherQuery({
    teacher_id: credential?.id||"",
    page_size: 99,
  }, {refetchOnMountOrArgChange : true})

  let seriesList: SeriesEntity[] = [];
  if (isSuccess && seriesRes && !seriesRes.isFailed && seriesRes.result) {
    if (seriesRes.result.series_list) {
      seriesList = seriesRes.result.series_list;
    }
  }

  const handleClickButton = () => {
    dispatch(showSignInUpDialog())
  }

  if (isFetching) {
    return (
      <Loader isLoading={isFetching} />
    )
  } else if (seriesList.length > 0) {
    return (
      <Box className={classes.root}>
        {seriesList.map((series) => 
          <div key={series.series_id} className={classes.item}>
            <SeriesCard card={series} horizontal={true} />
          </div>
        )}
      </Box>
    )
  } else {
    return (
      <Box className={classes.SignInInner}>
        <SvgComponent  iconName={"seriesIcon"} style={{ width: "48px", height: "36px"}}/>
        <Typography style={{fontSize: '18px', fontFamily: 'ArialMT', marginTop: "20px"}}>
          No teaching course yet.
        </Typography>
        {!authStatus.isLogin? 
        <Button 
          onClick={handleClickButton}
          className={classes.button}
          sx={{
            textTransform: "none", 
            backgroundColor: '#F93B57',  
            borderRadius: '8px',
            fontFamily: 'ArialMT',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            fontSize: '18px',
            marginTop: '20px'
          }}
          variant="contained">
          {t('studio.signIn')}
        </Button>:
        ''
        }
        
      </Box>
    )
  }
}


