import React from 'react'
import { makeStyles } from '@mui/styles'
import { Avatar, Box } from '@mui/material';
import { Tooltip, Typography } from '@material-ui/core';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ThumbClassroom1 from 'src/images/meeting/thumb-classroom.png'
import ThumbClassroom2 from 'src/images/meeting/thumb-classroom2.png'
import ThumbClassroom3 from 'src/images/meeting/thumb-classroom3.png'
import ThumbClassroom4 from 'src/images/meeting/thumb-classroom4.png'
import ThumbClassroomNew from 'src/images/meeting/thumb-classroom-new.png'

import BgClassroom1 from 'src/images/meeting/bg-classroom.jpeg'
import BgClassroom2 from 'src/images/meeting/bg-classroom2.jpeg'
import BgClassroom3 from 'src/images/meeting/bg-classroom3.jpeg'
import BgClassroom4 from 'src/images/meeting/bg-classroom4.jpeg'
import { useGetMeetingBackgroundListMutation, useUploadMeetingBackgroundMutation } from 'src/app/service/real/meeting-background-slice';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  boxRoot: {
    borderRadius: "10px",
    background: '#fff',
    width: "300px",
    position:"absolute",
    bottom: "100px",
    padding: "20px",
    zIndex: 110,
  },
  titleBGPicker: {
    fontSize:"14px !important",
    fontFamily:"Arial-BoldMT, Arial",
    fontWeight: "600 !important",
    marginBottom: "20px !important",
  },
  firstPickerLine: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "20px",
  },
  bgItem: {
    width: "120px !important",
    height: "60px !important",
    borderRadius: "5px !important",
    cursor: "pointer",
    '& .MuiAvatar-img': {
      objectFit: "contain",
    }
  },
  selectedBorder: {
    border: "3px solid #bd0094",
    borderRadius: "5px !important",
  },
  boxMobileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    overflow:'auto',
  },
  mobileBgItem: {
    width: "50px !important",
    height: "50px !important",
    borderRadius: "5px !important",
    cursor: "pointer",
    margin: "5px 5px 0 0",
  },
  halfPicker: {
    position: "absolute",
    bottom: 0,
    zIndex: 3,
    opacity: 0,
    width: "120px",
    height: "20px",
    '&:hover': {
      cursor: 'pointer'
    }
  },
  halfShadow: {
    position: "absolute",
    bottom: 0,
    opacity: 0.7,
    width: "120px",
    height: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    background: "black",
    color: "white",
    textAlign: "center",
    borderRadius: "0 0 5px 5px !important",
  },
  mobileHalfShadow: {
    position: "absolute",
    bottom: 0,
    opacity: 0.7,
    width: "50px",
    height: "20px",
    fontSize: "12px",
    background: "black",
    color: "white",
    textAlign: "center",
    borderRadius: "0 0 5px 5px !important",
  },
  pickerButton1: {
    position: "absolute",
    zIndex: 3,
    opacity: 0,
    width: "120px",
    height: "60px",
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mobilePickerButton: {
    position: "absolute",
    zIndex: 3,
    opacity: 0,
    width: 50,
    height: 50,
    top: 0,
  }
}))

interface Props {
  currentSelectIndex: number;
  onSelectChange(index:number, imageUrl: string|null);
}

const VIRTUAL_BG_ARRAY = [
  {thumb:"", image:""},
  {thumb:"", image:""},
  {thumb:ThumbClassroom1, image: BgClassroom1},
  {thumb:ThumbClassroom2, image: BgClassroom2},
  {thumb:ThumbClassroom3, image: BgClassroom3},
  {thumb:ThumbClassroom4, image: BgClassroom4},
]

export const DEFAULT_VIRTUAL_BG = VIRTUAL_BG_ARRAY[2].image;

export const VirtialBackgroundPicker: React.FC<Props> = ({currentSelectIndex, onSelectChange}) => {
  const classes = useStyles()
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const TestPic = "https://share.dev.veeko.com/meeting-background/dev/wuchen/veeko-about.png";
  const [customBackground, setCustomBackground] = React.useState("");
  const [uploadMeetingBackground, {isLoading: loading0}] = useUploadMeetingBackgroundMutation();
  const [getMeetingBackgroundList, {isLoading: loading1}] = useGetMeetingBackgroundListMutation();
  // const TEST_URL = "https://s2.loli.net/2022/05/09/jmxTl9VrFEkUDC6.png";  

  React.useEffect(() => {
    showBackgroundList();
  }, [])

  const showBackgroundList = () => {
    getMeetingBackgroundList({}).unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        const list = res.result.background_list;
        if (list?.length > 0) {
          setCustomBackground(list[0].url);
        }
      } else {
        // enqueueSnackbar("Can not get custom virtual background pictures")
      }
    })
  }

  const handleChangeIndex = (index:number) => {
    let imageUrl = (index === 0 || index === 1) ? null : VIRTUAL_BG_ARRAY[index].image;
    onSelectChange(index, imageUrl);
  };

  const onfileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    var fileObj: File = event.target.files[0];
    if (fileObj) {
      let url = '';
      if (window.URL !== undefined) {
        url = window.URL.createObjectURL(fileObj);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(fileObj);
      }
      setCustomBackground(url);
      console.log("url: ", url);
      onSelectChange(6, url);

      uploadFile(fileObj);
    }
  }

  const uploadFile = async (fileObj: File) => {
    const response = await uploadMeetingBackground({fileName: fileObj.name, fileType: fileObj.type}).unwrap()
    if (response && !response.isFailed && response.result) {
      const pictureUrl = response.result.public;
      const response2 = await fetch(response?.result.upload, {
        method: "PUT",
        body: fileObj,
        headers: {
          "Content-Type": fileObj.type
        },
      });
      if (response2.status === 200) {
        // enqueueSnackbar('Upload File failed!');
      } else {
        enqueueSnackbar('Upload File failed!', {variant:'error'});
        // setIsUploading(false)
      }
    } else {
      enqueueSnackbar('Upload File failed!', {variant:'error'});
    }
    
  }

  if (isMobile) {
    return (
    <Box className={classes.boxMobileRoot}>
      <Avatar
        variant='square'
        onClick={() => handleChangeIndex(0)}
        className={clsx(classes.mobileBgItem, {[classes.selectedBorder]: currentSelectIndex === 0})}
        src={"https://portal.veeko.com/img/ic_none.svg"}>
      </Avatar>
      <BlurOnIcon
        style={{color:"white"}}
        onClick={() => handleChangeIndex(1)}
        className={clsx(classes.mobileBgItem, {[classes.selectedBorder]: currentSelectIndex === 1})}
        >
      </BlurOnIcon>
      <Avatar
        variant='square'
        onClick={() => handleChangeIndex(2)}
        className={clsx(classes.mobileBgItem, {[classes.selectedBorder]: currentSelectIndex === 2})}
        src={VIRTUAL_BG_ARRAY[2].thumb}>
      </Avatar>
      <Avatar
        variant='square'
        onClick={() => handleChangeIndex(3)}
        className={clsx(classes.mobileBgItem, {[classes.selectedBorder]: currentSelectIndex === 3})}
        src={VIRTUAL_BG_ARRAY[3].thumb}>
      </Avatar>
      <Avatar
        variant='square'
        onClick={() => handleChangeIndex(4)}
        className={clsx(classes.mobileBgItem, {[classes.selectedBorder]: currentSelectIndex === 4})}
        src={VIRTUAL_BG_ARRAY[4].thumb}>
      </Avatar>
      <Avatar
        variant='square'
        onClick={() => handleChangeIndex(5)}
        className={clsx(classes.mobileBgItem, {[classes.selectedBorder]: currentSelectIndex === 5})}
        src={VIRTUAL_BG_ARRAY[5].thumb}>
      </Avatar>

      {customBackground &&
      <Avatar
        variant='square'
        onClick={() => {onSelectChange(6, customBackground)}}
        className={clsx(classes.mobileBgItem, {[classes.selectedBorder]: currentSelectIndex === 6})}
        src={customBackground}>
      </Avatar>
      }
      
      <Box style={{position:"relative"}}>
        <Avatar
          variant='square'
          className={classes.mobileBgItem}
          src={ThumbClassroomNew}>
        </Avatar>

        <input
          id="input-logo"
          title="Change Logo picture"
          onChange={(e) => onfileChange(e)}
          type="file"
          accept="image/*"
          className={classes.mobilePickerButton}
        />

        {customBackground &&
        <Box className={classes.mobileHalfShadow}>
          Replace
        </Box>
        }
      </Box>
      

    </Box>
    )
  } else return (
    <Box className={classes.boxRoot}>
      <Typography className={classes.titleBGPicker}>
        Change Background
      </Typography>
      <Box className={classes.firstPickerLine}>
        <Tooltip title="No virtual background" placement="top">
          <Avatar
            variant='square'
            onClick={() => handleChangeIndex(0)}
            className={clsx(classes.bgItem, {[classes.selectedBorder]: currentSelectIndex === 0})}
            src={"https://portal.veeko.com/img/ic_none.svg"}>
          </Avatar>
        </Tooltip>
        <Tooltip title="blur background" placement="top">
          <BlurOnIcon
            onClick={() => handleChangeIndex(1)}
            className={clsx(classes.bgItem, {[classes.selectedBorder]: currentSelectIndex === 1})}
            >
          </BlurOnIcon>
        </Tooltip>
      </Box>

      <Box className={classes.firstPickerLine}>
        <Avatar
          variant='square'
          onClick={() => handleChangeIndex(2)}
          className={clsx(classes.bgItem, {[classes.selectedBorder]: currentSelectIndex === 2})}
          src={VIRTUAL_BG_ARRAY[2].thumb}>
        </Avatar>
        <Avatar
          variant='square'
          onClick={() => handleChangeIndex(3)}
          className={clsx(classes.bgItem, {[classes.selectedBorder]: currentSelectIndex === 3})}
          src={VIRTUAL_BG_ARRAY[3].thumb}>
        </Avatar>
      </Box>

      <Box className={classes.firstPickerLine}>
        <Avatar
          variant='square'
          onClick={() => handleChangeIndex(4)}
          className={clsx(classes.bgItem, {[classes.selectedBorder]: currentSelectIndex === 4})}
          src={VIRTUAL_BG_ARRAY[4].thumb}>
        </Avatar>
        <Avatar
          variant='square'
          onClick={() => handleChangeIndex(5)}
          className={clsx(classes.bgItem, {[classes.selectedBorder]: currentSelectIndex === 5})}
          src={VIRTUAL_BG_ARRAY[5].thumb}>
        </Avatar>
      </Box>

      <Box className={classes.firstPickerLine} sx={{position:"relative"}}>
        <img
          alt='custom_background'
          onClick={() => {onSelectChange(6, customBackground)}}
          className={clsx(classes.bgItem, {[classes.selectedBorder]: currentSelectIndex === 6})}
          src={customBackground || ThumbClassroomNew}>
        </img>

        <input
          id="input-logo"
          title="Select picture"
          onChange={(e) => onfileChange(e)}
          type="file"
          accept="image/*"
          className={customBackground ? classes.halfPicker : classes.pickerButton1}
        />
        
        {customBackground &&
        <Box className={classes.halfShadow}>
          Upload
        </Box>
        }
      </Box>
    </Box>
  )
}
