import React  from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { getAuthStatus } from 'src/utils/auth'
import { Enrolled } from '../Enrolled'
import { useNavigate } from 'react-router-dom'
import { useLazyGetPurchasedListQuery } from 'src/app/service/real/purchase-api'
import { CourseCard } from 'src/components/cards'
import { ClassroomListCard } from 'src/components/cards/ClassroomListCard'
import { getBroadcastData } from 'src/pages/discover/broadcastList'
import { getClassroomData } from 'src/pages/discover/videoClassroomList2'
import { Loader } from 'src/components/loader'
import { useSelector } from 'src/app/toolkit/store';
import { Result } from '@dokyo/common';
import { Theme } from '@mui/system'


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    minHeight: 'calc(100vh - 369px)',
    padding: '34px 0 0 30px',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    }
  },
  seriesContainer: {

  },
  seriesTitle: {
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign:"center",
    [theme.breakpoints.down('md')]: {
      fontSize: '25px !important'
    }
  },
  seriesTitleNA: {
    fontSize: '30px',
    fontFamily: 'Arial-BoldMT, Arial',
    fontWeight: 'normal',
    color: '#000000',
    lineHeight: '34px',
    opacity: '50%',
  },
  seriesResource: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  seriesCourse: {
    margin: '0 26px 26px 0',
    [theme.breakpoints.down('md')]: {
      margin:"0"
    }
  },
}))

const SectionTitle = styled(Typography)({
  fontSize: '30px',
  fontWeight: 'bold',
  paddingBottom: '20px',
})




export const MyCourse: React.FC = () => {

  const classes = useStyles();
  const navigate = useNavigate()
  const authStatus = getAuthStatus()()
  const {credential} = useSelector(state => state.app);
  const [myCourseList, setMyPurchasedList] = React.useState<any[]>([]);

  const [getPurchasedList, {isFetching}] = useLazyGetPurchasedListQuery();

  React.useEffect(() => {
    if (credential) {
      getPurchasedList({}).unwrap()
      .then(res => {
        handleQueryResultList(res);
      })
    }
  }, [credential])


  const handleQueryResultList = (purchasedList: Result<any>) => {
    if (purchasedList && !purchasedList.isFailed && purchasedList.result) {
      const result: any = []
      let hasResource = false
      if (purchasedList.result.purchased_list) {
        purchasedList.result.purchased_list.map(seriesItem => {
          const resultItem : any = {
            name: '',
            enable: seriesItem.enable,
            course: [],
            broadcastLive: [],
            videoClassroom: []
          }
          
          if (seriesItem.resource_list) {
            if (seriesItem.resource_list?.course_list?.length > 0) {
              hasResource = true
              resultItem.course = seriesItem.resource_list.course_list
              for (let i = 0; i < seriesItem.resource_list.course_list.length; i++) {
                if (seriesItem.resource_list.course_list[i]?.course_series?.series_title) {
                  resultItem.name = seriesItem.resource_list.course_list[i].course_series.series_title;
                  break;
                }
              }
            }
            if (seriesItem.resource_list?.broadcast_list?.length > 0) {
              hasResource = true
              resultItem.broadcastLive = getBroadcastData(seriesItem.resource_list.broadcast_list)
              if (resultItem.name === '') {
                for (let i = 0; i < seriesItem.resource_list.broadcast_list.length; i++) {
                  if (seriesItem.resource_list.broadcast_list[i]?.broadcast_series?.series_title) {
                    resultItem.name = seriesItem.resource_list.broadcast_list[i].broadcast_series.series_title;
                    break;
                  }
                }
              }
            }
            if (seriesItem.resource_list?.meeting_list?.length > 0) {
              hasResource = true
              resultItem.videoClassroom = getClassroomData(seriesItem.resource_list.meeting_list)
              if (resultItem.name === '') {
                for (let i = 0; i < seriesItem.resource_list.meeting_list.length; i++) {
                  if (seriesItem.resource_list.meeting_list[i]?.meeting_series?.series_title) {
                    resultItem.name = seriesItem.resource_list.meeting_list[i].meeting_series.series_title;
                    break;
                  }
                }
              }
            }
            result.push(resultItem)
          }
        })
      }
      if (hasResource) {
        setMyPurchasedList(result);
      }
    }
  }

  const handleBroadcastClick = (id: string) => {
    navigate("/livebroadcast?id=" + id)
  }

  const handleVideoClassroomClick = (id: string) => {
    navigate("/videoclassroom?id=" + id)
  }

  if (isFetching) {
    return (
      <Loader isLoading={isFetching} />
    )
  } else if (myCourseList.length > 0) {
    return (
      <Box className={classes.root}>
        {
          myCourseList && myCourseList.length > 0? 
          myCourseList.map(courseItem => {
            let CourseList = ''
            let BroadcastList = ''
            let VideoClassroomList = ''
            if (courseItem.course.length > 0) {
              CourseList = courseItem.course.map((item => {
                return (
                  <Box key={Math.random()} className={classes.seriesCourse}>
                    <CourseCard course={item} enable={courseItem.enable} />
                  </Box>
                )
              }))
            }
            if (courseItem.broadcastLive.length > 0) {
              BroadcastList = courseItem.broadcastLive.map((item => {
                return (
                  <Box key={Math.random()} onClick={() => {if(courseItem.enable){ handleBroadcastClick(item.id || '')}}}>
                    <ClassroomListCard
                      showLabel={false}
                      card={item}
                      label={'Live'}
                      enable={courseItem.enable}
                    />
                  </Box>
                )
              }))
            }
            if (courseItem.videoClassroom.length > 0) {
              VideoClassroomList = courseItem.videoClassroom.map((item => {
                return (
                  <Box key={Math.random()}  onClick={() => {if(courseItem.enable){handleVideoClassroomClick(item.id || '')}}}>
                    <ClassroomListCard
                      showLabel={false}
                      card={item}
                      label={'Video'}
                      enable={courseItem.enable}
                    />
                  </Box>
                )
              }))
            }
            return (
              <Box key={Math.random()} >
                <SectionTitle className={`${courseItem.enable ? classes.seriesTitle : classes.seriesTitleNA}`}>{`${courseItem.name} ${courseItem.enable ? '' : '(N/A)'}`}</SectionTitle>
                <Box className={classes.seriesResource}>
                {CourseList}
                {BroadcastList}
                {VideoClassroomList}
                </Box>
              </Box>
            )
          }):
          ''
        }
      </Box>
    )
  } else {
    return (
      <Enrolled authStatus={authStatus}/>
    )
  }
}


