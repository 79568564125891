import Slider from 'react-slick'
import { useSnackbar } from 'notistack'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Video } from '../../app/models'
import { addToCart } from 'src/app/service/cart-slice'
import { VideoCard } from 'src/components/cards'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  slider: {
    width: '100%',
    margin: 'auto',

    '& .slick-arrow': {
      zIndex: '1',
      '&:before': {
        fontSize: '60px',
        color: 'black',
        content: "''"
      }
    },

    // '& .slick-slide': {
    //   '& $card__body': {
    //     margin: '0 10px'
    //   }
    // },

    '& .slick-list': {
      margin: '0 0 0 calc(-3 * 4 * 1px)'
    }
  },
  slider__content: {
    margin: 'auto'
  },

  card__body: {}
}))

interface PropsForm {
  videos: any,
  learnType?: Number,
}

export const VideoSlider = (props: PropsForm) => {
  const {videos, learnType } = props;
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  let navigate = useNavigate()

  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon style={{ color: 'black', width: '70%', height: '70%' }} />
      </div>
    )
  }
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: 'black',
            width: '70%',
            height: '70%',
            transform: 'rotate(180deg)'
          }}
        />
      </div>
    )
  }

  const settings = {
    // dots: true,
    infinite: false,
    // swipeToSlide: true,
    speed: 300,
    slidesToShow: 4.3,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  const addVideoToCart = (video: Video) => {
    dispatch(addToCart(video))
    enqueueSnackbar(`${video.title} has been added to cart!`)
  }

  let isSwiping = false
  function updateSwipeState(state) {
    isSwiping = state
  }

  interface VideoId {
    id: string
  }

  function handleClick(id: VideoId , url:string) {
    if (isSwiping) {
      return
    }
    if (learnType === 0) {
      return
    } else if (learnType === 2) {
      navigate('/watch?v=' + id + "&type=" + learnType)
    } else if (learnType === 3) {
      navigate('/streaming?v=' + id + "&type=" + learnType +"&url=" + url)
    }
  }

  return (
    <div className={classes.slider}>
      <div className={classes.slider__content}>
        <Slider
          {...settings}
          afterChange={() => updateSwipeState(false)}
          onSwipe={() => updateSwipeState(true)}
        >
          {videos?.map((v, i) => (
            <div>
              <VideoCard
                key={v.id}
                elevation={0}
                video={v}
                className={classes.card__body}
                onCartAdded={addVideoToCart}
                onClick={() => handleClick(v.id,v.url)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
