import React from 'react'
// import { useGetPopulateTeacherListQuery } from 'src/app/service/real/teacher.slice'
import { useGetSeriesListQuery } from 'src/app/service/real/coursedetail-slice';
// import { TeacherList } from './TeacherList'
import { SeriesEntity } from '@dokyo/common'

import { SeriesList } from 'src/components/cardlist'

interface Props {
  startKey: string,
  onDataLoad: (startKey: string) => void,
  sortName: string,
}

export const TeacherListBySort: React.FC<Props> = ({ startKey, onDataLoad, sortName  }) => {

  const [seriesList, setSeriesList] = React.useState<SeriesEntity[]>([]);

  const { data, isFetching } = useGetSeriesListQuery({ 
    startKey, 
    pageSize: 20,
    forwardOrder: sortName === 'Trending'
  }
  // , {
  //   refetchOnMountOrArgChange: 60
  // }
  )
  
  // let teacherList: TeacherEntity[] = []
  // if (data && !data.isFailed && data.result) {
  //   console.log(data)
  //   // if (data.result.teacher_list) {
  //   //   teacherList = data.result.teacher_list
  //   // } 
  // }


  React.useEffect(() => {
    console.log("data:", data);
    if (data) {
      onDataLoad(data?.result?.start_key?? '')
      if (data.isFailed === false && data.result?.series_list) {
        setSeriesList(data.result.series_list);
      }
    }
    
  }, [data])


  return (
    <SeriesList  isLoading={isFetching} seriesList={seriesList} />
  )
}