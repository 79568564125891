import React, {useEffect, useState} from 'react'
import { Box, Typography, Divider, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled, Theme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { RelatedClassSlider } from 'src/components/slider'
import { TeacherInfo } from 'src/components/teacher-info'
import { useCloseBroadcastMutation, useGetRelatedBroadcastListQuery, useStartThirdPartyBroadcastMutation } from 'src/app/service/real/watch-slice'
import { BroadcastEntity, RelatedClassEntity, Result, SeriesEntity, User } from '@dokyo/common/'
import { useSelector } from 'src/app/toolkit/store'
import { useSnackbar } from 'notistack'
import { PlayerContainer } from 'src/components/playerContainer'
import { useGetSchoolListQuery } from 'src/app/service/real/school-slice'
import { useGetProfileMutation } from 'src/app/service/real/aws-api'
import { RawUser } from 'src/app/models'
import { getFinalFees, getFinalMemberFees, PlayerLearnTypeEnum } from 'src/components/playerContainer/PlayerContainer'
import SvgComponent from 'src/components/svgComponent'
import { useGetBroadcastStateMutation } from 'src/app/service/real/learnpage-slice'
import Loader from 'src/components/loader'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { SetPremiumMemberCourseDialog } from './setPremiumDialog'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChatMessageProps, ChatMessageComponent} from './BroadcastLiveDetail';
import { AddToCartComponent } from 'src/components/addToCart';
import { appConfig } from 'src/app-config'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  infoTitle: {
    display: "flex",
    fontSize: "15px !important",
    color: "#555555 !important",
    marginTop: "5px !important",
    fontFamily: "ArialMT !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "12px !important",
      marginLeft: "12px !important",
    }
  },
  infoValue: {
    fontSize: "15px !important",
    color: "black",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "12px !important",
    }
  },
  courseDesc: {
    fontSize: "15px !important",
    color: "#555555 !important",
    marginTop: "10px !important",
    fontFamily: "ArialMT !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "12px !important",
      marginLeft: "12px !important",
    }
  },
  chatContainer: {
    marginTop: '12px'
  },
  infoContainer: {
    [theme.breakpoints.down('md')]: {
      padding: '0 10px'
    }
  },
  titleBox: {
    display: "flex", 
    alignItems: "baseline",    
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse", 
    }
  },
  copyLinkButton: {
    color: "#000000 !important", 
    fontSize: "14px !important", 
    width: "120px !important",
    height: "40px !important",
    [theme.breakpoints.down('md')]: {
      marginLeft:"auto !important", 
    }
  },
  linkIcon: {
    margin: "0 0 0 5px", 
    maxWidth:"18px", 
    maxHeight:"18px",
  },
  closeLiveButton: {
    color: "#000000 !important", 
    fontSize: "16px !important", 
    height: "40px !important", 
    marginRight: "40px !important",
    [theme.breakpoints.down('md')]: {
      marginLeft:"auto !important",
      marginRight: "0px !important",
    }
  },
  titleText: {
    flexGrow: 1,
    fontWeight: "bold !important", 
    fontSize: "34px !important",
    marginTop: "20px !important", 
    marginBottom: "10px !important", 
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "24px !important",
      marginRight:"auto !important", 
      marginTop: "0 !important", 
      marginLeft: "12px !important",
    }
  }
}))

/**
 * copy page url
 * @param type 1-course; 2-video broadcast; 3-live; 4-video classroom
 * @param id resourse id
 */
  export const handleClickCopyLink = (type: number, id: string) => {
  let href = "";
  if (type === 1) {
    href = '/classdetail?id=' + id;
  } else if (type === 2) {
    href = '/videobroadcast?id=' + id;
  } else if (type === 3) {
    href = '/livebroadcast?id=' + id;
  } else if (type === 4) {
    href = '/videoclassroom?id=' + id;
  }
  const link = `${window.location.protocol}//${window.location.host}${href}`
  copyContent(link);
}

export const copyContent = (content: string) => {
  const aux = document.createElement('input')
  aux.setAttribute('value', content)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
}

const transBroadcastEntity = (learnInfo: BroadcastEntity): RelatedClassEntity => {
  return {
    id: learnInfo?.broadcast_id,
    type: 3, //1-course; 2-video broadcast; 3-live broadcast; 4-video classroom
    title: learnInfo?.broadcast_title,
    desc: learnInfo?.broadcast_introduction,
    icon: learnInfo?.broadcast_cover,
    videoUrl: learnInfo?.broadcast_channel?.playback_url,
    time: learnInfo?.broadcast_start_time,
    price: learnInfo?.broadcast_fees,
    teacherId: learnInfo?.teacher.user_id,
    teacherAvatar: learnInfo?.teacher.user_avatar,
    teacherName: learnInfo?.teacher.user_name,
    categoryName: learnInfo?.category.name,
    status: learnInfo?.broadcast_status,//1-not start; 2-livin
    purchasedSeperately: learnInfo?.broadcast_purchased || false,
  }
}

interface ClassInfoProps extends ChatMessageProps {
  seriesInfo: SeriesEntity | null,
  learnInfo: BroadcastEntity
  setWebScocketEnable: (enable: boolean) => void
  onCloseLive: () => void
}

const LiveInfo: React.FC<ClassInfoProps> = ({seriesInfo, learnInfo, setWebScocketEnable, onCloseLive, liveStatus, chatMessageList, shouldDisableChat, getInputChatFieldStatusHint, handleInputChatMessageChange,newChatMessage, onChatKeyDown, broadcastInfo, refreshSendFileStatus}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { credential } = useSelector(state => state.app);
  const [showCloseDialog, setShowCloseDialog] = React.useState(false);
  const [relatedList, setRelatedList] = useState<RelatedClassEntity[]>([]);
  const [playerLearnInfo, setPlayerLearnInfo] = useState<RelatedClassEntity>(transBroadcastEntity(learnInfo));
  const {data: relatedListRes} = useGetRelatedBroadcastListQuery({teacherId: learnInfo.teacher?.user_id});

  const originalVideoUrl = learnInfo ? learnInfo?.broadcast_channel?.playback_url: "";
  const [playUrl, setPlayUrl] = useState("");
  const [getBroadcastState, {isLoading: loading1}] = useGetBroadcastStateMutation();
  const [startThirdPartyBroadcast, {isLoading: loading2}] = useStartThirdPartyBroadcastMutation();
  const [closeBroadcast, {isLoading: loading3}] = useCloseBroadcastMutation();
    
  const { data: schoolData }= useGetSchoolListQuery({is_all: false})
  const [schoolName, setSchoolName] = useState("")
  // const [showPremuimMemberChoiceDialog, setShowPremiumMemberChoiceDialog] = useState(false);

  React.useEffect(()=>{
    if (learnInfo && schoolData && !schoolData.isFailed && schoolData.result?.schoolInfoList) {
       const schoolList = schoolData.result?.schoolInfoList;
       if (schoolList && schoolList.length > 0) {
         const findSchool = schoolList.find(item => item.school_id === learnInfo.school_id);
         if (findSchool) {
           setSchoolName(findSchool.school_name);
         }
       }
     }
  },[schoolData, learnInfo])



  useEffect(() => {
    if (learnInfo?.broadcast_id) {
      const newInfo = transBroadcastEntity(learnInfo);
      setPlayerLearnInfo(newInfo);
    }
  },[learnInfo.broadcast_status, learnInfo.broadcast_channel?.state])

  useEffect(() => {
    if (learnInfo) {
      const living = learnInfo.broadcast_channel?.state === 1;
      if (!credential) {
        setWebScocketEnable(living)
        return
      }
      if (credential?.roles?.includes("admin") || credential?.roles?.includes("teacher")) {
        setPlayUrl(originalVideoUrl||"")
        setWebScocketEnable(living)
        return
      }
      // TODO to be removed
      setPlayUrl(originalVideoUrl||"")
    }
    //eslint-disable-next-line
  }, [credential, learnInfo, seriesInfo])

  useEffect(() => {
    if (relatedListRes && !relatedListRes?.isFailed && relatedListRes.result) {
      // console.log("setRelatedList:", relatedListRes?.result?.broadcast_list)
      const transList = transRelatedClassList(relatedListRes?.result?.broadcast_list);
      // console.log("set transList:", transList)
      setRelatedList(transList);
    }
  }, [relatedListRes]) 

  const transRelatedClassList = (list: any[]): RelatedClassEntity[] => {
    if (!list) {
      return [];
    }
    return list.map((item, index) => {
      return {
        id: item.broadcast_id,
        icon: item.broadcast_cover,
        title: item.broadcast_title,
        desc: item.broadcast_introduction,
        videoUrl: item.broadcast_video_url,
        categoryName: item.category?.name,
        type: item.broadcast_video_url ? 2 : 3,
        purchasedSeperately: item.broadcast_purchased,
      }
    })
  }

  const handleStartThirdPartyBroadcast = () => {
    if (!learnInfo || !learnInfo.broadcast_id) {
      enqueueSnackbar("Can not get live stream id")
      return;
    }
    getBroadcastState({list: [{broadcast_id: learnInfo.broadcast_id, user_id: learnInfo.teacher.user_id}]})
    .unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        const result : BroadcastEntity = res.result.broadcast_list[0];
        if (result?.broadcast_channel?.state === 1) {
          handleStartBroadcast();
        } else {
          enqueueSnackbar("Stream is not started")
        }
      }
    })
  }

  const handleStartBroadcast = () => {    
    if (!learnInfo || !learnInfo.broadcast_id) {
      enqueueSnackbar("Can not get live stream id")
      return;
    }
    startThirdPartyBroadcast({broadcastID: learnInfo.broadcast_id})
    .unwrap()
    .then(res => {
      if (res && !res.isFailed) {
        window.location.reload();
      } else {
        enqueueSnackbar("Start failed: " + res?.message)
      }
    })
  }

  const handleClickCloseLive = () => {
    if (!learnInfo || !learnInfo.broadcast_id) {
      enqueueSnackbar("Can not get live stream id")
      return;
    }
    setShowCloseDialog(false);
    closeBroadcast({broadcastID: learnInfo.broadcast_id})
    .unwrap()
    .then(res => {
      if (res && !res.isFailed) {
        onCloseLive();
      } else {
        enqueueSnackbar("Close live failed: " + res?.message)
      }
    })
  }

  const SectionTitle = styled(Typography)({
    marginLeft: matches ? '18px' : '0px',
    fontSize: matches ? '20px' : '30px',
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: "Arial-BoldMT, Arial"
  })

  return (
    <>
      <Box className={classes.root}>
        <PlayerContainer
          learnType={PlayerLearnTypeEnum.LIVE_STREAM}
          courseInfo={seriesInfo}
          learnInfo={playerLearnInfo}
          playUrl={playUrl}
          liveTypeData={{
            thirdParty: learnInfo?.broadcast_thirdparty || false, 
            enablePlayback: learnInfo?.broadcast_channel?.enable_playback || false,
            state: learnInfo.broadcast_channel?.state || 0,
          }}
          onClickStartThirdPartyBroadcast={handleStartThirdPartyBroadcast}/>
        <Box className={classes.infoContainer}>
          {
            matches && 
            <Box className={classes.chatContainer}> 
              <ChatMessageComponent 
                liveStatus={liveStatus} 
                chatMessageList={chatMessageList} 
                shouldDisableChat={shouldDisableChat} 
                getInputChatFieldStatusHint={getInputChatFieldStatusHint} 
                handleInputChatMessageChange={handleInputChatMessageChange} 
                newChatMessage={newChatMessage} 
                onChatKeyDown={onChatKeyDown} 
                broadcastInfo={broadcastInfo} 
                refreshSendFileStatus={refreshSendFileStatus}
              />
            </Box>
          }
          <Box className={classes.titleBox}>
            <Typography  className={classes.titleText}>
              {learnInfo?.broadcast_title}
            </Typography>
            {learnInfo.broadcast_thirdparty && learnInfo.broadcast_status === 2 && learnInfo.teacher.user_id === credential?.id &&
            <Button
              variant='text'
              color='info'
              className={classes.closeLiveButton}
              style={{textTransform: "none"}}
              startIcon={<SvgComponent iconName="iconCloseLive" className="icon-source" style={{width: "14px", height: "14px"}}/>}
              onClick={()=>setShowCloseDialog(true)}
            >
              {t("class.close-live")}
            </Button>
            }
            <Button
                variant='text'
                color='info'
                className={classes.copyLinkButton}
                style={{textTransform: "none"}}
                startIcon={<SvgComponent iconName="iconLink" className={classes.linkIcon}/>}
                onClick={()=>handleClickCopyLink(3, learnInfo?.broadcast_id)}
              >
                {t("class.copy-link")}
              </Button>
            </Box>

          <Box className={classes.infoTitle}>
            {t("class.school")}: &nbsp;<Typography className={classes.infoValue}> {schoolName || "Veeko School"}</Typography>
          </Box> 
          <Box className={classes.infoTitle}>
            {t("class.course")}: &nbsp;
            <Typography className={classes.infoValue}
              sx={{cursor: 'pointer', textDecoration: 'underline'}} 
              onClick={() => navigate(`${appConfig.paths.course}?id=${learnInfo?.broadcast_series?.series_id}`)}
              > {learnInfo.broadcast_series?.series_title || seriesInfo?.series_title}
            </Typography>
          </Box> 
          <Box className={classes.infoTitle}>
          {t("class.category")}: &nbsp;<Typography className={classes.infoValue}> {seriesInfo?.series_category.name || learnInfo?.category.name}</Typography>
          </Box>
          {/* <Box className={classes.infoTitle}>
          {t("class.type")}: &nbsp;<Typography className={classes.infoValue}> {t("class.live-broadcast")}</Typography>
          </Box> */}
          <Typography gutterBottom className={classes.courseDesc}>
            {learnInfo?.broadcast_introduction}
          </Typography>
          {
            matches && 
            <Box hidden={liveStatus === 3} style={{marginLeft:"12px", marginTop:"20px"}}>
              <AddToCartComponent
                purchasedSeparately={seriesInfo?.course_purchased || broadcastInfo.broadcast_purchased} 
                price={getFinalFees(seriesInfo, broadcastInfo.broadcast_fees)}
                memberPrice={getFinalMemberFees(seriesInfo, broadcastInfo.broadcast_member_fees)}
                seriesInfo={seriesInfo}
              />
            </Box>
          }
          <Divider light style={matches? {margin: "15px 0 34px"}:{margin: "34px 0"}} />
          <TeacherInfo 
            teacherId={learnInfo?.teacher?.user_id}
            teacherName={learnInfo?.teacher?.user_name}
            teacherAvatar={learnInfo?.teacher?.user_avatar}
            teacherSignature={learnInfo?.teacher?.personalized_signature}
            showChat={false}/>
          <Divider light style={{margin: "30px 0"}} />

          <Box style={{paddingBottom: "60px"}}>
            <SectionTitle>
            {t("class.related")}
            </SectionTitle>
            <Box sx={matches ? {margin: "10px 10px 0 18px"} : {marginTop: "10px", marginRight: "-45%"}}>
              <RelatedClassSlider classList={relatedList} learnType={3}/>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        fullWidth={true}
        open={showCloseDialog}
        onClose={()=>{setShowCloseDialog(false)}}
      >
        <DialogTitle id="share-dialog-title">{"Close this live?"}</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setShowCloseDialog(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickCloseLive} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* <SetPremiumMemberCourseDialog
        seriesId={seriesInfo?.series_id||""}
        onCancel={()=>{setShowPremiumMemberChoiceDialog(false)}}
        onSetSuccess={()=> {setShowPremiumMemberChoiceDialog(false)}}
        open={showPremuimMemberChoiceDialog}/> */}

      <Loader isLoading={loading1 || loading2 || loading3}/>
    </>
  )
}

export default LiveInfo