
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Theme, Box, IconButton, Typography, Switch, Tabs, Tab, 
  Divider, MenuItem, Avatar, Snackbar, Tooltip, DialogContent, Menu, Drawer, Grid  } from "@mui/material";
import * as Chime from "amazon-chime-sdk-js";
import { Loader } from "src/components/loader";
import { makeStyles } from '@mui/styles'
import Badge from '@mui/material/Badge';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CameraSwitchIcon from '@mui/icons-material/Cameraswitch';
import HandIcon from '@mui/icons-material/PanTool';
import CloseIcon from '@mui/icons-material/HighlightOff';
import ChatIcon from '@mui/icons-material/Chat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MembersIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import MaterialsIcon from '@mui/icons-material/SnippetFolder';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { getDisplayUserName } from 'src/utils/stringUtil';
import { TextField as MaterialTextField } from "@mui/material";
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import { useSelector } from 'src/app/toolkit/store';
import { useCloseMeetingMutation, useDeleteAttendeeMutation, useDisableAttendeeShareMutation, useGetAttendeeListMutation, 
  useGetAttendeeShareInfoMutation, useSetMeetingFunctionMutation, useUpdateAttendeeShareInfoMutation,
  useSetMeetingViewModeMutation, useSetToppingStudentMutation, useRecordMeetingVideoMutation } from 'src/app/service/real/videoclassroom-slice';
import { MeetingDataConfigs, FileEntity, FileStatus} from '@dokyo/common';
import { useGetProfileMutation } from 'src/app/service/real/aws-api';
import { DEFAULT_VIRTUAL_BG, VirtialBackgroundPicker } from './VirtualBackgroundPicker';
import IconHandYellow from 'src/images/meeting/ico-hand-yellow.png'
import TeacherAvatarDefault from 'src/images/teacher/defaultAvatar.png'
import IconTopping from 'src/images/meeting/ic-topping.png'
// import IconGridView from 'src/images/meeting/ic_grid_view.png'
// import IconSpeechView from 'src/images/meeting/ic_speech_view.png'
import IconBackground from 'src/images/meeting/ic_background.svg'
import IconCloseRed from 'src/images/meeting/ic_close_red.svg'
import IconHand from 'src/images/meeting/ic_hand.svg'
import IconMute from 'src/images/meeting/ic_mute.svg'
import IconVoice from 'src/images/meeting/ic_voice.svg'
import IconQuit from 'src/images/meeting/ic_quit.svg'
import IconScreen from 'src/images/meeting/ic_screen.svg'
import IconScreenClose from 'src/images/meeting/ic_screen_close.svg'
import IconSend from 'src/images/meeting/ic_send.svg'
import IconVideoOn from 'src/images/meeting/ico-video-on.svg'
import IconVideoOff from 'src/images/meeting/ico-video-off.svg'
import { FilePickerButton, UsageType } from '../../../components/filePicker/FilePickerButton';
import { MeetingChatMessageList } from '../../../components/chat/MeetingChatMessageList';
import { ChatMessage } from 'src/app/models';
import { isMobileDevice } from 'src/utils/deviceUtil';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { 
  useStartSeriesLessonTranscriptionMutation,
  useStopSeriesLessonTranscriptionMutation,
  useSetSeriesLessonToppingUserMutation,
  useSetSeriesLessonLayoutMutation,
  useSetSeriesLessonFunctionMutation,
  useCloseSeriesLessonMeetingMutation,
  SeriesLessonAudioVideoFunctionEnum,
  useSetSeriesLessonCancelForbidShareMutation,
  useSetSeriesLessonForbiddenShareMutation, } from 'src/app/service/real/serieslesson-slice';
import { getTimeCountText } from 'src/utils/timeUtil';
import SvgComponent from 'src/components/svgComponent';
import { MeetingTypeEnum } from '../dialog/MeetingDialog';
import { reportError } from 'src/app/toolkit/helper';
import { ErrorReportEntity } from 'src/app/models/error';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useReportViewHistoryMutation, ViewHistoryLessonType } from 'src/app/service/real/logreport-slice';
import SwipeableViews from 'react-swipeable-views';
import SliderPagination from '../../../components/slider_pagination';
import clsx from 'clsx';
import MeetingMaterialList from './MeetingMaterialList';
import { ClassroomMaterialCardEntity } from 'src/components/cards/ClassroomMaterialCard';
import ReactPlayer from 'react-player';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack.js';
import { DraggablePaper } from 'src/components/draggable';


const useMobileStyles = makeStyles((theme: Theme) => ({
  mobileRoot: {
    position: "relative",
    width: "100%",
    height: "100%",
    background: "#252525",
    display: 'flex',
    flexDirection: 'column',
  },
  mobileTopBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
  },
  swipeBox:{
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mobileTimeCount: {
    fontSize: '12px',
    color: 'white',
  },
  mobileRaiseHandBox: {
    display: "flex", 
    alignItems: "center",
    zIndex: 100, 
    bottom: "180px", 
    position:"absolute",
  },
  mobileRaiseHandName: {
    color:"white", 
    fontSize: "10px", 
    fontFamily: "Arial-BoldMT, Arial",
    textShadow: "2px 2px 3px black, -2px 2px 3px black, 2px -2px 3px black, -2px -2px 3px black",
  },
  videoBox: {
    position: "relative", 
    width: "100%",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  gridVideoBox: {
    position: "relative",
    width: "100%",
    height: "80vh",
  },
  shareVideo: {
    width: "100%",  
    background: "#636363",
    borderRadius: "5px",
    maxHeight: "40%",
  },
  teacherVideo: {
    width: "100%",
    background: "#636363",
    borderRadius: "5px",
    marginTop: "10px",
    maxHeight: "40%",
  },
  mobileSelfVideo: {
    border: '1px solid black',
    marginTop: "10px",
    width: "100px",
    height: "100px",
    alignSelf: "end",
  },
  mobileControlBox: {
    marginBottom: "30px",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  mobileButtonControl: {
    cursor: "pointer",
    textAlign: 'center',
  },
  mobileButtonText: {
    fontSize: '10px',
    color: 'white',
  },
  mobileTransitonText: {
    position: "absolute",
    bottom: "120px",
    color: "white",
    zIndex: 101,
    width: "100%",
    margin: "10px auto",
    textAlign: "center",
    fontSize: "14px",
    fontFamily: "ArialMT",
    textShadow: "2px 2px 3px black, -2px 2px 3px black, 2px -2px 3px black, -2px -2px 3px black",
  },
  mobileChatLayout: {
    zIndex: 102,
    position: "fixed", 
    left: 0, 
    bottom: 0, 
    width: '100vw', 
    height: '70%', 
    background:'white', 
    borderRadius: '12px 12px 0 0', 
    display: 'flex', 
    flexDirection: 'column'
  },
  mobileSettingsLayout: {
    zIndex: 102,
    position: "fixed", 
    left: 0, 
    bottom: 0, 
    width: '100vw', 
    height: 'auto', 
    background:'black',
    display: 'flex', 
    flexDirection: 'column'
  },
  mobileChatTitle: {
    textAlign: 'center', 
    position: 'relative', 
    height: '50px', 
    paddingTop: '15px',
  },
  mobileChatClose: {
    width: '32px', 
    height: '32px', 
    cursor: 'pointer', 
    position: 'fixed', 
    right: "10px",
    padding: "10px",
  },
  mobileChatUserList: {
    display:"flex", 
    alignItems: "center",
    padding: '9px 0',
    borderBottom: '1px solid #dddddd',
  },
  mobileUserListName: {
    color: "#555555",
    flexGrow: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "14px !important",
  },
  mobileDeviceSelect: {
    margin: "10px 10px 0 0 !important",
    width: "100%",
    '& .MuiSvgIcon-root': {
      color:'white'
    }
  },
  mobileDeviceText: {
    color: "white !important"
  },
  setVirtualBGBox: {
    padding: "10px 0 30px 10px",
    position: "relative",
  },
  setVirtualBGBoxTitle: {
    height: "40px",
    display: "flex",
    flexDirection:"row",
    alignItems: "center",
    color: "white !important",
  },
  mobileUserVideo: {
    minWidth: "18vw",
    minHeight: "120px",
    alignSelf: "stretch",
    position: "relative",
    justifyContent: "center",
    margin: "5px 5px 0 0"
  },
  MobileVideoSmallContent: {
    width: "100%",
    height: "100px",
    background: "#636363",
    borderRadius: "5px",
    objectFit: "cover",
    // transform: "scale(-1,1) !important", 
  },
  mobileVideoSmallUserInfo: {
    height: "20px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
}))

const useStyles = makeStyles((theme : Theme) => 
  ({
    App : {
      width: "100%",
      height: "100%",
      display: "flex",
      background: "#252525",
      borderRadius: "5px"
    },
  
    videoConatiner: {
      width: "75%",
      height: "100%",
      gridGap: "5px",
      paddingRight: "10px",//no use
    },
    timeCountBox: {
      position: "fixed", 
      top: 26, 
      left: 30, 
      zIndex: 101,
      borderRadius: "10px",
      background: "#000000",
      opacity: '0.5',
      fontSize: '12px',
      color: 'white',
      padding: '2px 10px',
      width: '86px',
      textAlign: 'center',
    },
    shareInfoBox: {
      position: "fixed", 
      top: 26, 
      left: 150, 
      zIndex: 101,
      fontSize: '12px',
      color: 'white',
      padding: '2px 10px',
      textShadow: "2px 2px 3px black, -2px 2px 3px black, 2px -2px 3px black, -2px -2px 3px black",
    },
    videoBig: {
      position: "relative",
      width: "100%",
      height: "60%",
      background: "#636363",
      alignSelf: "stretch",
      borderRadius: "5px",
    },
    teacherBigVideo: {
      width: "100%",
      height: "100%", 
    },
    teacherSmallVideo: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "160px",
      height: "160px",
      background: "#636363",
      alignSelf: "stretch",
      borderRadius: "5px",
      border: "2px solid #252525",
      zIndex: 100,
    },
    teacherFullscreenSmallVideo: {
      position: "fixed",
      top: 0,
      right: 0,
      width: "160px",
      height: "160px",
      background: "#636363",
      alignSelf: "stretch",
      borderRadius: "5px",
      border: "2px solid #252525",
      zIndex: 100,
      // transform: "none !important"// fix safari teacher video fullscreen issue
    },
    teacherFullscreenBigVideo: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
      background: "#252525",
      // transform: "none !important"// fix safari teacher video fullscreen issue
    },
    shareVideo: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
      transform: "none !important",
    },
    shareFullscreenVideo: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
      background: "#252525",
      transform: "none !important",
    },
    toppingVideo: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
    },
    toppingFullscreenVideo: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
      background: "#252525",
    },
    rotateY : {
      transform: "scale(-1,1) !important",
    },
    rotateNone: {
      transform: "none !important",
    },
    closeFullscreenShareButton: {
      position: "fixed", 
      top: 0, 
      left: "45%", 
      zIndex: 100
    },
    closeShareButton: {
      zIndex: 100,
    },
    fullscreenTransitonText: {
      position: "fixed", 
      bottom: "50px",
      left: "100px",
      right: "100px",
      color: "white", 
      zIndex: 101,
      width: "fit-content",
      maxWidth: "70%",
      margin: "auto",
      textAlign: "center",
      fontSize: "22px",
      fontFamily: "ArialMT",
      textShadow: "2px 2px 3px black, -2px 2px 3px black, 2px -2px 3px black, -2px -2px 3px black",
    },
    transitonText: {
      position:"absolute",
      bottom: "10px",
      left: "100px",
      right: "100px",
      color: "white",
      zIndex: 101,
      width: "fit-content",
      maxWidth: "70%",
      margin: "auto",
      textAlign: "center",
      fontSize: "18px",
      fontFamily: "ArialMT",
      textShadow: "2px 2px 3px black, -2px 2px 3px black, 2px -2px 3px black, -2px -2px 3px black",
    },
    raiseHandBox: {
      display: "flex", 
      alignItems: "center",
      zIndex: 100, 
      bottom: 50, 
      left: 15, 
      position:"absolute",
    },
    raiseHandName: {
      color:"white", 
      fontSize: "14px", 
      fontFamily: "Arial-BoldMT, Arial",
      textShadow: "2px 2px 3px black, -2px 2px 3px black, 2px -2px 3px black, -2px -2px 3px black",
    },
    fullscreenButton: {
      bottom: 0,
      right: 0,
      zIndex: 101,
    },
    cancelFullscreenButton: {
      bottom: 20,
      right: 20,
      zIndex: 101,
    },
    fullscreenControlButton: {
      color:"white", 
      background: "#252525", 
      borderRadius: "17px"
    },
    videoListBox: {
      overflow: "auto", 
      display: "flex", 
      width: "100%",
      height: "30%",
    },
    videoSmall: {
      width: "20vh",
      height: "calc(20vh + 20px)",
      alignSelf: "stretch",
      position: "relative",
      justifyContent: "center",
      margin: "5px 5px 0 0"
    },
    videoSmallContent: {
      width: "20vh",
      height: "20vh",
      background: "#636363",
      borderRadius: "5px",
      objectFit: "cover",
    },
    videoSmallUserInfo: {
      height: "20px",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    videoSmallUserMic: {
      color: "white",
    },
    classroomControl: {
      width: "100%",
      height: "10%",
      paddingTop: "20px",
      alignSelf: "stretch",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    boxRight: {
      width: "25%",
      padding: "8px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    tabBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    switchBoxItem: {
      display:"flex", 
      alignItems: "center",
    },
    switchText: {
      color: "#fff",
      flexGrow: 1,
    },
    listNameText:{
      color: "#fff",
      flexGrow: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    listNumberText: {
      color: "#999",
      fontSize: "14px !important",
      marginBottom: "10px !important"
    },
    unReadMessage: {
      width: "26px", 
      height: "26px", 
      lineHeight: "26px",
      borderRadius: "13px", 
      background: "red",
      textAlign: "center",
      verticalAlign: "middle",
      position: "absolute",
      right: "40px",
      color: "white",
    },
    settingsBox: {
      flexGrow: 1, 
      overflowY: "auto", 
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    attendeeListBox: {
      height: "auto", 
      overflow: "auto", 
      flexGrow: 1, 
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    chatListBox: {
      flexGrow: 1, 
      overflow: "auto", 
      paddingBottom: "100px", 
      paddingRight: "10px",
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    chatBox: {
      width: "100%",
      background: "#252525",
      textAlign: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
      display: "flex",
      alignItems: "center",
      marginRight: "-10px"
    },
    chatTextField: {
      width: "100%",
      background: "#ddd",
      borderRadius: "5px",
      padding: "5px 0 5px 10px !important",
    },
    deviceSelect: {
      margin: "10px 10px 0 0 !important",
      width: "100%",
      '& .MuiSvgIcon-root': {
        color:'white !important'
      }
    },
    deviceText: {
      color: "white !important"
    },
    buttonControl: {
      cursor: "pointer",
      margin: "10px"
    },
    virtulBGLoading: {
      position:"absolute",
      left: "80px",
    },
    previewBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: "500px",
      height: "300px",
      position: "relative",
      [theme.breakpoints.down('sm')]:{
        width: "300px",
        height: "500px",
      }
    },
    previewAvatar: {
      width: '100px !important',
      height: '100px !important',
    },
    previewVideo: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      transform: "scale(-1,1) !important", 
    },
    previewText: {
      position: "absolute", 
      top: '20px', 
      left: '10px',
      borderRadius: "10px",
      background: "#000000",
      opacity: '0.5',
      fontSize: '12px',
      color: 'white',
      padding: '2px 10px',
    },
    bottomButtonBox: {
      display:'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
    },
    previewButtonBox: {
      display:'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      // position: 'relative',
      // [theme.breakpoints.down('sm')]:{
      //   justifyContent: "left",
      // }
    },
    recordVideoBox: {
      display:'flex', 
      justifyContent: 'left', 
      alignItems: 'center', 
      margin: '10px 0'
    },
    recordVideoSelect: {
      width: "100%",
      '& .MuiSvgIcon-root': {
        color:'white !important'
      },
      // '& .MuiInputBase-root': {
      //   border: '1px solid white'
      // }
    },
    recordVideoText: {
      width: '150px',
      fontSize: '14px',
      color: 'white !important',
    },
    recordVideoInput: {
      fontSize: '14px',
      color: 'white !important',
    },
  })
);

const TimeCountBox : React.FC<{joinTimestamp: number}> = ({joinTimestamp}) => {
  const classes = useStyles();
  const classesMobile = useMobileStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [joinTimeSeconds, setJoinTimeSeconds] = useState(0);

  setTimeout(() => {
    const timePeriod = new Date().getTime() - joinTimestamp;
    setJoinTimeSeconds(Math.floor(timePeriod/1000));
  }, 1000);

  return (
    <Box className={isMobile ? classesMobile.mobileTimeCount : classes.timeCountBox}>
    {getTimeCountText(joinTimeSeconds)}
    </Box>
  )
}

interface PropsForm {
  meetingType: MeetingTypeEnum;
  queryMaterialId: string;
  meetingId: string;
  joinTimestamp: number,
  meetingDataConfigs: MeetingDataConfigs
  onExitMeeting: () => void;
}

interface Attendee {
  attendeeId: string,
  userId: string,
  userName: string,
  userAvatar: string,
  mute: boolean,  
  speaking: boolean,
  videoOff: boolean
}

export const enum FuntionTypeEnum {
  StartTranscription = 1,
  EndTranscription = 2,
  StartRecord = 3,
  EndRecord = 4,
  Mute = 5,
  Unmute = 6,
  OpenVideo = 7,
  CloseVideo = 8,
  Layout = 9,
  CancelForbidShare = 11,
  ForbidShare = 12,
}

const MeetingScreen: React.FC<PropsForm> = (props) => {
  console.log("MeetingScreen init___________________________________________________")
  const logSwitch = process.env.REACT_APP_ENV === "dev";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { meetingId, joinTimestamp, meetingDataConfigs, onExitMeeting, meetingType, queryMaterialId } = props;
  const { teacherId, meetingInfo, attendeeInfo, meetingControls }  = meetingDataConfigs;
  const classes = useStyles();
  const classesMobile = useMobileStyles();
  const { credential } = useSelector(state => state.app)
  const currentUser = credential;
  const isSecondTeacher = meetingDataConfigs.secondTeachers.includes(currentUser?.id||"");
  const isStudent = currentUser?.id !== teacherId && !isSecondTeacher;
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const videoElements = React.useRef<any[]>([]);
  const [selfVideoIndex, setSelfVideoIndex] = React.useState(-1);
  const [mute, setMute] = React.useState(false);
  const [videoOn, setVideoOn] = React.useState(true);
  const [requestShareUser, setRequestShareUser] = React.useState({attendeeId:"", userId: "", userName: ""});
  const [shareScreenConfig, setShareScreenConfig] = React.useState({
    sharing: false,
    fullscreen: false,
    allowShareUserId: teacherId,
    shareContentAttendeeId: '',
  });
  const [toppingConfig, setToppingConfig] = React.useState({
    topping: !!meetingControls.toppingUser,
    toppingUserId: meetingControls.toppingUser,
    tileId: -1,
  });
  const [raiseHandUser, setRaiseHandUser] = React.useState("")
  const [recordVideo, setRecordVideo] = React.useState(meetingControls.meetingRecord);  
  const [recordVideoType, setRecordVideoType] = React.useState('teacher');  // all, teacher
  const [transitionOn, setTransitionOn] = React.useState(meetingControls.transitionLanguage ? true : false);
  const [displaytransition, setDisplayTransition] = React.useState<boolean>(true);
  const NO_LANGUAGE = "no-language";
  const [selectLanguage, setSelectLanguage] = React.useState<string>(meetingControls.transitionLanguage || NO_LANGUAGE);
  const [transitionText, setTransitionText] = React.useState<string>("");
  const [chatContent, setChatContent] = React.useState("");
  const [videoUserIDList, setVideoUserIDList] = React.useState<string[]>([]);
  const [attendeeList, setAttendeeList] = React.useState<Attendee[]>([]); // attendee list from chime
  const [apiAttendeeList, setAPIAttendeeList] = React.useState([]);// get attendee list from api
  const [previewConfig, setPreviewConfig] = React.useState({showPreviewDialog: false, previewMute: false, previewVideoOff: false});
  const [audioVideoStartFlag, setAudioVideoStartFlag] = React.useState(false);
  const [shareFileDialog, setShareFileDialog] = React.useState({title: "", openDialog: false, showFile: false, fileUrl: "", fileType: ""});
  const [showExitDialog, setShowExitDialog] = React.useState(false);
  const [showStopShareDialog, setShowStopShareDialog] = React.useState(false);
  const [showOpenMicDialog, setShowOpenMicDialog] = React.useState(false);
  const [showOpenVideoDialog, setShowOpenVideoDialog] = React.useState(false);
  const [showRequestShareDialog, setShowRequestShareDialog] = React.useState(false);
  const [showCloseMeetingDialog, setShowCloseMeetingDialog] = React.useState(false);
  const [showVirtualBGDialog, setShowVirtualBGDialog] = React.useState({showDialog: false, showLoading: false});
  const [virtualBGConfig, setVirtualBGConfig] = React.useState({index: 0, imageUrl: ""});
  const [virtualBGConfigBackUp, setVirtualBGConfigBackUp] = React.useState<any|null>(null);
  const [alertDialogData, setAlertDialogData] = React.useState({
    showAlertDialog: false,
    alertDialogTitle: "",
    exitAfterClose: false,
  });
  enum TabPosition {
    Control = 0,
    Materials = 1,
    Chat = 2,
  }
  const [tabPosition, setTabPosition] = React.useState(TabPosition.Control);
  const [showMobileChat, setShowMobileChat] = React.useState(false);

  const [selectAudioVideoDevice, setSelectAudioVideoDevice] = React.useState({
    audioInput: '',
    audioOutput: '',
    videoInput: '',
    videoOutput: '',
  });

  const [meetingConfigs, setMeetingConfigs] = React.useState({
    muteAll: meetingControls.muteAll ?? false,
    closeAllVideo: meetingControls.closeAllVideo ?? false,
    closeChat: meetingControls.closeChat ?? false,
    forbidShareContent: meetingControls.forbidShare ?? false,
  });

  const [audioInputList, setAudioInputList] = React.useState<MediaDeviceInfo[]>([]);
  const [audioOutputList, setAudioOutputList] = React.useState<MediaDeviceInfo[]>([]);
  const [videoInputList, setVideoInputList] = React.useState<MediaDeviceInfo[]>([]);

  const [chatMessageList, setChatMessageList] = React.useState<ChatMessage[]>([]);
  const messagesEndRef = React.useRef<any>(null);
  const [unReadMessageCount, setUnReadMessageCount] = React.useState(0);

  const [indexMap, setIndexMap] = React.useState({});
  const [mAudioVideo, setmAudioVideo] = React.useState<Chime.AudioVideoFacade>();
  const [notifyCount, setNotifyCount] = useState(0);
  const [notifyReceiveData, setNotifyReceiveData] = useState({count:0, data: null});
  const [sendFile, setSendFile] = useState<FileEntity>()
  const [leaveUserId, setLeaveUserId] = useState('');
  const [lastReportErrorData, setLastReportErrorData] = useState({errorCode: '', time: 0});
  // const [viewMode, setViewMode] = useState(0);//0-speech view;1-grid view
  
  const [reportPlayHistory] = useReportViewHistoryMutation();
  const [getAttendeeList] = useGetAttendeeListMutation();
  const [setMeetingFunction] = useSetMeetingFunctionMutation();
  const [recordMeetingVideo] = useRecordMeetingVideoMutation();
  const [setMeetingLayout] = useSetMeetingViewModeMutation();
  const [setToppingStudent] = useSetToppingStudentMutation();
  // const [deleteAttendee] = useDeleteAttendeeMutation();
  const [getUserProfile] = useGetProfileMutation();
  const [getAttendeeShareInfo] = useGetAttendeeShareInfoMutation();
  const [updateAttendeeShareInfo] = useUpdateAttendeeShareInfoMutation();
  const [disableAttendeeShare] = useDisableAttendeeShareMutation();
  const [requestCloseMeeting] = useCloseMeetingMutation();
  
  // private lesson and series lesson
  const [startSeriesLessonTranscription] = useStartSeriesLessonTranscriptionMutation();
  const [stopSeriesLessonTranscription] = useStopSeriesLessonTranscriptionMutation();
  const [setSeriesLessonToppingStudent] = useSetSeriesLessonToppingUserMutation();
  const [setSeriesLessonLayout] = useSetSeriesLessonLayoutMutation();
  const [setSeriesLessonAudioVideoFunction] = useSetSeriesLessonFunctionMutation();
  const [closeSeriesLessonMeeting] = useCloseSeriesLessonMeetingMutation();
  const [setSeriesLessonCancelForbidShare] = useSetSeriesLessonCancelForbidShareMutation();
  const [setSeriesLessonForbidShare] = useSetSeriesLessonForbiddenShareMutation();

  const logger = new Chime.ConsoleLogger("ChimeMeetingLogs", Chime.LogLevel.WARN);
  
  // exit fullscreen begin
  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      setShareScreenConfig(config=>{return{sharing: config.sharing, fullscreen: false, allowShareUserId: config.allowShareUserId, shareContentAttendeeId: config.shareContentAttendeeId}});
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);
  // exit fullscreen end

  React.useEffect(()=>{
    setupMeeting();
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      showAttendeeList();
    }
    
    const handleTabClose = event => {
      exitMeeting();
      return '';
    };

    window.addEventListener('unload', handleTabClose);
    return () => {
      window.removeEventListener('unload', handleTabClose);
    };
    // eslint-disable-next-line
  },[]);
  
  React.useEffect(() => {
    // 如果离开的用户是被置顶的学生，则自动取消置顶
    if (leaveUserId && !isStudent && toppingConfig.topping && leaveUserId === toppingConfig.toppingUserId) {
      cancelToppingStudent();
    }
  }, [leaveUserId])

  React.useEffect(() => {
    // 如果学生被置顶时，有人分享屏幕，则自动取消置顶学生
    if (toppingConfig.topping && !isStudent && shareScreenConfig.sharing) {
      logUtil("video classroom topping: cancelToppingStudent because sharing");
      enqueueSnackbar("Topping student is stopped", {variant:'info'})
      cancelToppingStudent();
    }
  }, [shareScreenConfig])

  useEffect(() => {
    if (sendFile) {
      if (sendFile.fileStatus === FileStatus.UPLOADING) {
        handleSelectFile(sendFile);
      } else if (sendFile.fileStatus === FileStatus.UPLOADED) {
        handleFileUploaded(sendFile);
      }
    }
  }, [sendFile])

  useEffect(() => {
    if (notifyCount > 0) {
      setTimeout(() => {
        notifySelfState();
      }, 3000)
    }
  }, [notifyCount])
  
  useEffect(() => {
    if (notifyReceiveData.data) {
      updateNotifyStateList(notifyReceiveData.data);
    }
  }, [notifyReceiveData])

  const notifySelfState = () => {
    const message = {
      // id: "",
      type: "notifyState",
      attendeeId: attendeeInfo.AttendeeId,
      userId: attendeeInfo.ExternalUserId,
      userName: credential?.name,
      userAvatar: credential?.avatarUrl,
      mute: mute,
      speaking: false,
      videoOff: !videoOn,
    }

    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    // logUtil("send attendee state: ", JSON.stringify(message))
  }

  const updateNotifyStateList = (message: Attendee) => {
    // logUtil("receive attendee state: ", JSON.stringify(message))
    const findAttendee = attendeeList.find(item => item.attendeeId === message.attendeeId);
    if (findAttendee) {
      setAttendeeList(list => list.map(item => (item.attendeeId=== message.attendeeId)? message: item));
      // findAttendee.mute = message.mute;
      // findAttendee.videoOff = message.videoOff;
    } else {
      setAttendeeList(list => [...list, message])
    }
    
  }

  const acquireVideoElement = (tileState: Chime.VideoTileState) => {
    // logUtil("video classroom acquireVideoElement -> tileState:", tileState)
    // Share screen
    if (tileState?.boundAttendeeId?.endsWith("#content")) {
      indexMap[0] = tileState?.tileId;
      // logUtil("video classroom acquireVideoElement return current[0]")
      return videoElements.current[0];
    }

    if (!tileState?.boundAttendeeId && !tileState?.boundExternalUserId) {
      // logUtil("video classroom acquireVideoElement return null")
      return null;
    }
    // Teacher is always shown in first video
    if (tileState?.boundExternalUserId === teacherId) {
      indexMap[1] = tileState?.tileId;
      // logUtil("video classroom acquireVideoElement return current[1]")
      return videoElements.current[1];
    }
    // Return the same video element if already bound.
    for (let i = 2; i < 25; i += 1) {
      if (indexMap[i] === tileState?.tileId) {
        // 用于手机布局显示学生自己的视频
        if (tileState?.boundExternalUserId === credential?.id) {
          setSelfVideoIndex(i);
        }
        return videoElements.current[i];
      }
    }
    // Return the next available video element.
    for (let i = 2; i < 25; i += 1) {
      if (!indexMap.hasOwnProperty(i)) {
        indexMap[i] = tileState?.tileId;
        // 用于手机布局显示学生自己的视频
        if (tileState?.boundExternalUserId === credential?.id) {
          setSelfVideoIndex(i);
        }
        return videoElements.current[i];
      }
    }
    throw new Error("no video element is available");
  };

  const releaseVideoElement = (tileId: string, shouldDelete: Boolean) => {
    for (let i = 0; i < 25; i += 1) {
        if (indexMap[i] === tileId) {
          if (shouldDelete) {
            delete indexMap[i];
          }
          return i;
        }
    }
  };

  const showAttendeeList = async ()=> {
    getAttendeeList({meetingId: meetingId})
    .unwrap()
    .then(res => {
      if (!res?.isFailed) {
        setAPIAttendeeList(res?.result?.attendee_list || [])
      } else {
        setAPIAttendeeList([])
      }
    })
  }

  const getAttendeeProfile = (attendeeId:string, externalUserId: string) => {
    getUserProfile({userId: externalUserId}).unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        const profile = res.result.user_profile;
        const newAttendee = {
          attendeeId: attendeeId || "",
          userId: externalUserId || "",
          userName: profile?.user_name,
          userAvatar: profile?.user_avatar,
          mute: externalUserId === credential?.id ? mute : false, //actually mute not work here
          speaking: false,
          videoOff: externalUserId === credential?.id ? !videoOn : false,//actually videoOn not work here
        };
        setAttendeeList(list => (list.some(item=>item.userId === externalUserId)) ? // prevent repeat user
          list :
          (externalUserId === teacherId ? [newAttendee, ...list]: [...list, newAttendee])) // if it is teacher, put the first order 
      } else {
        setDefaultNameUserToList(attendeeId, externalUserId);
      }
    }).catch(e => {setDefaultNameUserToList(attendeeId, externalUserId);})
    .finally(() => {
      if (externalUserId === credential?.id) {
        // to refresh attendee list mute and video status referring to preview config
        setAudioVideoStartFlag(true);
      }
    })
  }

  const setDefaultNameUserToList = (attendeeId:string, userId: string) => {
    setAttendeeList(list => (list.some(item=>item.userId === userId))? list : [...list, {
      attendeeId: attendeeId || "",
      userId: userId || "",
      userName: userId,
      userAvatar: "",
      mute: false,
      speaking: false,
      videoOff: false,
    }])
  }

  const tryToReportError = (errorCode: string, errorMessage: string) => {
    if (lastReportErrorData.errorCode !== errorCode || (new Date().getTime() - lastReportErrorData.time > 3 * 60 * 1000)) {
      setLastReportErrorData({errorCode: errorCode, time: new Date().getTime()});
      const params: ErrorReportEntity = {
        error_code: errorCode,
        error_message: errorMessage,
        modules: window.location.pathname,
        request_action: "meeting",
      }
      reportError(params);
    }
  }

  const setupMeeting = async () => {
    setIsLoading(true);

    console.log("MeetingScreen setup Meeting ___________________________________________________")
    const deviceController = new Chime.DefaultDeviceController(logger);
    const configuration = new Chime.MeetingSessionConfiguration(meetingInfo, attendeeInfo);
    const meetingSession = new Chime.DefaultMeetingSession(configuration, logger, deviceController);    

    setmAudioVideo(meetingSession.audioVideo);

    const observer = {
      audioVideoDidStart: () => {
        logUtil("video classroom  audioVideoDidStart ----------------- ")
        setAudioVideoStartFlag(true);
        if (currentUser && !videoUserIDList.includes(currentUser.id)) {
          const newList = [...videoUserIDList, currentUser.id];
          setVideoUserIDList(newList);
        }
        setIsLoading(false);
      },
      videoTileDidUpdate: (tileState: Chime.VideoTileState) => {
        
        logUtil("video classroom  videoTileDidUpdate ------------------",tileState)
        if (!tileState || !tileState.tileId) {
          return;
        }
        try {
          const element = acquireVideoElement(tileState);
          logUtil("video classroom  videoTileDidUpdate indexMap-------------",indexMap)
          if (element) {
            meetingSession.audioVideo.bindVideoElement(tileState.tileId ?? 0, element);  
          }
      
          if (toppingConfig.topping && toppingConfig.toppingUserId && tileState.boundExternalUserId === toppingConfig.toppingUserId && tileState.tileId) {
            meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElements.current[0]);
          }
        } catch (error) {
          logUtil("videoTileDidUpdate bindVideoElement error: ", error);
          tryToReportError("video", "videoTileDidUpdate bindVideoElement error: " + JSON.stringify(error));
        }
    
        //if don't reset indexMap, the video tile name will not refresh after attendee leave
        const newIndexMap = {};
        for (const key in indexMap) {
          newIndexMap[key] = indexMap[key]
        }
        setIndexMap(newIndexMap)
      },
      audioVideoDidStop: (sessionStatus) => {
        logUtil("video classroom  audioVideoDidStop ------------------", sessionStatus)
        if (sessionStatus._statusCode === Chime.MeetingSessionStatusCode.AudioJoinedFromAnotherDevice) {
          setAlertDialogData({
            showAlertDialog: true,
            alertDialogTitle: "Your account has joined in another place",
            exitAfterClose: true,
          })  
        } else if (sessionStatus._statusCode === Chime.MeetingSessionStatusCode.MeetingEnded) {
          setAlertDialogData({
            showAlertDialog: true,
            alertDialogTitle: "This class has ended",
            exitAfterClose: true,
          })  
        }
      },
      videoTileWasRemoved: (tileId) => {
        const index = releaseVideoElement(tileId, false);
        // logUtil("video classroom  videoTileWasRemoved ----------------- ", tileId)
        // logUtil("video classroom  videoTileWasRemoved indexMap---------", indexMap)
        // arrange video tiles
        if (index === 0 || index === 1) {
          delete indexMap[index];
          return
        }
        if (!index) {
          return
        }
        for (let i = index; i < 25; i += 1) {  
          if (!indexMap.hasOwnProperty(i+1)) {
            logUtil("video classroom  rebind return ", i+1)
            if (videoElements.current[i]) {
              videoElements.current[i].srcObject = null
              delete indexMap[i];
            }
            logUtil("video classroom  rebind indexMap finally-------------", indexMap)
            return
          } else {
            const element = videoElements.current[i];
            if (element && indexMap[i+1]) {
              meetingSession.audioVideo.bindVideoElement(indexMap[i+1], element);
            }
            indexMap[i] = indexMap[i+1];
            logUtil("video classroom  rebind indexMap-------------", indexMap)
          }
        }
      },
      // listener for attendee close or open video
      remoteVideoSourcesDidChange: videoSources => {
        const newList : any[] = [];
        if (videoOn) {
          newList.push(currentUser?.id);
        }
        videoSources.forEach(videoSource => {
          const { attendee } = videoSource;
          newList.push(attendee.externalUserId);
          logUtil(`An attendee has videoSource  (${attendee.attendeeId} ${attendee.externalUserId}) is sending video`);
        });
        setVideoUserIDList(newList);
        
        setAttendeeList(list => list.map(item => {
          if (item.userId === currentUser?.id) {
            return item;
          } else {
            const findItem = videoSources.find(source => item.attendeeId === source.attendee.attendeeId);
            item.videoOff = !findItem;
            return item;
          }
        }))
        
      },
      connectionDidBecomePoor: () => {
        // enqueueSnackbar("Your connection is poor", {variant:'warning'})
        setAlertDialogData({
          showAlertDialog: true,
          alertDialogTitle: "Your connection is poor",
          exitAfterClose: false,
        })
      },
      connectionDidSuggestStopVideo: () => {
        const alertMessage = "Your internet connection is unstable";
        // enqueueSnackbar(alertMessage, {variant:'warning'})
        setAlertDialogData({
          showAlertDialog: true,
          alertDialogTitle: alertMessage,
          exitAfterClose: false,
        })
      },
    };
    meetingSession.audioVideo.addObserver(observer);
    
    //attendee join or leave
    meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((attendeeId: string, present: boolean, externalUserId?: string, dropped?: boolean, posInFrame?) => {
      logUtil("video classroom  1111 attendeeId:", attendeeId);
      logUtil("video classroom  1111 present:", present);
      logUtil("video classroom  1111 externalUserId:", externalUserId);
      // Content shares are treated as regular audio-video attendees. 
      // The attendee ID of a content share has a suffix of #content. 
      // https://aws.github.io/amazon-chime-sdk-js/modules/contentshare.html
      if (attendeeId.endsWith("#content")) {
        logUtil("video classroom share screen:", present);
        setShareScreenConfig(config => { 
          return {
            sharing: present,
            fullscreen: config.fullscreen,
            allowShareUserId: present ? (externalUserId || config.allowShareUserId) : config.allowShareUserId,
            shareContentAttendeeId: present ? attendeeId: '',
          }
        })

        // 如果学生被置顶时，有人分享屏幕，则自动取消置顶学生
        // 这里toppingConfig不生效
        // if (present && toppingConfig.topping && !isStudent) {
        //   logUtil("video classroom topping: cancelToppingStudent because sharing");
        //   cancelToppingStudent();
        // }
        return;
      }

      // record video will auto create a attendee
      if (!externalUserId || externalUserId?.startsWith("MediaPipeline")) {
        return;
      }

      if (present) {
        // join in
        getAttendeeProfile(attendeeId, externalUserId);
        // send message to notify self video and mute state
        if (externalUserId !== credential?.id) {
          setNotifyCount(c => c + 1);
        }

        // listen for attendee's mute status
        meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
          attendeeId,
          (attendeeId, volume, muted, signalStrength) => {
            // A null value for any field means that it has not changed.
            // logUtil(`chime classroom ${attendeeId}'s volume data: `, {
            //   volume, // a fraction between 0 and 1
            //   muted, // a boolean
            //   signalStrength // 0 (no signal), 0.5 (weak), 1 (strong)
            // });
            //refresh list
            setAttendeeList(list => list.map(item => {
              if (item.attendeeId === attendeeId) {
                return {
                  attendeeId: item.attendeeId,
                  userId: item.userId,
                  userName: item.userName,
                  userAvatar: item.userAvatar,
                  mute: muted !== null ? muted : item.mute,
                  speaking: volume !== null && volume > 0,
                  videoOff: item.videoOff,
                }
              } else {
                return item;
              }
            }))
          }
        );
      } else {
        // leave
        meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId);
        setAttendeeList(list => list.filter(item=>item.userId !== externalUserId));

        setLeaveUserId(externalUserId);
        // 如果被置顶的学生离开，则自动取消置顶
        // 这里toppingConfig不生效
        // if (!isStudent && toppingConfig.topping && externalUserId === toppingConfig.toppingUserId) {
        //   logUtil("video classroom topping: cancelToppingStudent because student leave");
        //   cancelToppingStudent();
        // }
      }
    })

    meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio((muted)=>{
      logUtil("video classroom subscribe LocalAudio muted:", muted);
      setMute(muted);
    })

    try {
      //Audio output
      // TODO - Can not get audioOutputs in Safari, and set audioOutput device is not work in Chrome
      // const audioOutputs = await meetingSession.audioVideo.listAudioOutputDevices();
      const audioOutputElement = document.getElementById("audio");
      const element = audioOutputElement as HTMLAudioElement;
      meetingSession.audioVideo.bindAudioElement(element);

      // if (isMobile) {
      //   meetingSession.audioVideo.realtimeMuteLocalAudio();
      //   meetingSession.audioVideo.stopLocalVideoTile();
      // } else {
        //Audio input
        const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();
        setAudioInputList(audioInputs);
        let selectAudioInput = "";
        if (audioInputs?.length > 0) {
          selectAudioInput = audioInputs[0].deviceId;
          await meetingSession.audioVideo.startAudioInput(selectAudioInput);
        } else {
          enqueueSnackbar("Can not find audio input device", {variant:'warning'})
        }
        //Audio output
        // Can not get audioOutputs in Safari, and set audioOutput device is not work in Chrome
        // see https://github.com/aws/amazon-chime-sdk-js/issues/1499
        // Android Chrome browser does not support setSinkId, cannot select audio output device.
        /*
        const audioOutputs = await meetingSession.audioVideo.listAudioOutputDevices();
        setAudioOutputList(audioOutputs);
        let selectAudioOutput = "";
        if (audioOutputs?.length > 0) {
          selectAudioOutput = audioOutputs[0].deviceId;
          await meetingSession.audioVideo.chooseAudioOutput(selectAudioOutput);
        } else {
          enqueueSnackbar("Can not find audio output device", {variant:'warning'})
        }*/

        //Video input
        const videoInputs = await meetingSession.audioVideo.listVideoInputDevices();
        logUtil("video input divices list: " + videoInputList?.length)
        setVideoInputList(videoInputs);
        let firstVideoDeviceId = "";
        if (videoInputs?.length > 0) {
          firstVideoDeviceId = videoInputs[0].deviceId;
          await meetingSession.audioVideo.startVideoInput(firstVideoDeviceId);
        } else {
          enqueueSnackbar("Can not find video input device", {variant:'warning'})
        }

        setSelectAudioVideoDevice(devices => ({
          audioInput: selectAudioInput,
          audioOutput: devices.audioOutput,
          videoInput: firstVideoDeviceId,
          videoOutput: devices.videoOutput,
        }));

        // listener for audio video device list change
        const audioVideoDiviceObserver = {
          audioInputsChanged: (freshAudioInputDeviceList : MediaDeviceInfo[]) => {
            logUtil('Audio Input device list updated: ', freshAudioInputDeviceList);
            setAudioInputList(freshAudioInputDeviceList);
          },
          audioOutputsChanged: freshAudioOutputDeviceList => {
            logUtil('Audio outputs updated: ', freshAudioOutputDeviceList);
            setAudioOutputList(freshAudioOutputDeviceList);
          },
          videoInputsChanged: (freshVideoInputDeviceList : MediaDeviceInfo[]) => {
            logUtil('Video inputs updated: ', freshVideoInputDeviceList);
            setVideoInputList(freshVideoInputDeviceList);
          },
          audioInputMuteStateChanged: (device, muted) => {
            logUtil('Device ', device, muted ? ' is muted in hardware' : ' is not muted');
          },
        };
        meetingSession.audioVideo.addDeviceChangeObserver(audioVideoDiviceObserver);
      // }
    } catch (error) {
      enqueueSnackbar("Can not access audio or video deivce", {variant:'error'})
      tryToReportError("device", "Can not access audio or video deivce: " + JSON.stringify(error));
      setIsLoading(false);
    }
    
    // content share, take effect only when sharing user is teacher
    meetingSession.audioVideo.addContentShareObserver({
      contentShareDidStart: () => {
        logUtil("video classroom  contentShareDidStart");
        setShareScreenConfig(config => {
          return {
            sharing: true,
            fullscreen: config.fullscreen,
            allowShareUserId: config.allowShareUserId,
            shareContentAttendeeId: config.shareContentAttendeeId,
          }
        })
      },
      contentShareDidStop: () => {
        logUtil("video classroom  contentShareDidStop");
        setShareScreenConfig(config => {
          return {
            sharing: false,
            fullscreen: config.fullscreen,
            allowShareUserId: config.allowShareUserId,
            shareContentAttendeeId: config.shareContentAttendeeId,
          }
        })
      }
    });
    // receive chat message
    meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage("chat", (dataMessage: Chime.DataMessage)=> {
      const topic = dataMessage.topic;
      if (topic === "chat") {
        const messageStr = new TextDecoder("utf-8").decode(dataMessage.data);
        //message sample: {"content":"ooo","id":"6060ec00-72cc-4bdb-a04d-e355ab3208e2","sender_id":"wuchen","sender_name":"WuChen Test","type":"text"}
        logUtil("video classroom  receive chat message: ", messageStr)
        const message = JSON.parse(messageStr);
        message["self"] = false;

        const contains = chatMessageList.find(item => item.id === message.id);// filter repeat message
        if (!contains) {
          setChatMessageList(list => [...list, message]);
          scrollToBottom();

          logUtil("video classroom  receive message, tabPosition: ", tabPosition)
          if (tabPosition !== TabPosition.Chat) {
            setUnReadMessageCount(count => count+1);
          } else {
            setUnReadMessageCount(0);
          }
        }
      }
    })

    //receive control message
    meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage("control", (dataMessage: Chime.DataMessage)=> {
      logUtil("video classroom  33333 receive control message: ", dataMessage.topic)
      const topic = dataMessage.topic;
      if (topic === "control") {
        const messageStr = new TextDecoder("utf-8").decode(dataMessage.data);
        //message sample: {"content":"ooo","id":"6060ec00-72cc-4bdb-a04d-e355ab3208e2","sender_id":"wuchen","sender_name":"WuChen Test","type":"text"}
        logUtil("video classroom  33333 receive control message: ", messageStr)
        const message = JSON.parse(messageStr);
        if (message?.type === "audio") { // mute all
          setMeetingConfigs(config => {return {
            muteAll: message.muted,
            closeAllVideo: config.closeAllVideo,
            closeChat: config.closeChat,
            forbidShareContent: config.forbidShareContent,
          }})
          logUtil("video classroom  33333 mute all is student: ", isStudent)
          if (isStudent) { 
            if (message.muted) {
              setShowOpenMicDialog(false);
              meetingSession.audioVideo.realtimeMuteLocalAudio();
              setMute(true);
              enqueueSnackbar("Mic has been muted by teacher", {variant:'info'})
              // doesn't need refresh list, will refresh in mute listenter
            } else {
              setShowOpenMicDialog(true);
            }
          }
        } else if (message?.type === "video") { // close all video
          setMeetingConfigs(config => {return {
            muteAll: config.muteAll,
            closeAllVideo: message.muted,
            closeChat: config.closeChat,
            forbidShareContent: config.forbidShareContent,
          }})
          if (isStudent) {
            if (message.muted) {
              setShowOpenVideoDialog(false);
              meetingSession.audioVideo.stopLocalVideoTile();
              setVideoOn(false);
              enqueueSnackbar("Video has been closed by teacher", {variant:'info'});
              //refresh list, need refresh here because listenter doesn't work
              setAttendeeList(list => list.map(item => {
                if (item.userId === currentUser?.id) {
                  return {
                    attendeeId: item.attendeeId,
                    userId: item.userId,
                    userName: item.userName,
                    userAvatar: item.userAvatar,
                    mute: item.mute,                    
                    speaking: item.speaking,
                    videoOff: message.muted,
                  }
                } else {
                  return item;
                }
              }))
            } else {
              setShowOpenVideoDialog(true);
            }
          }
        } else if (message?.type === "chat") {// close chat
          setMeetingConfigs(config => {return {
            muteAll: config.muteAll,
            closeAllVideo: config.closeAllVideo,
            closeChat: message.muted,
            forbidShareContent: config.forbidShareContent,
          }})
          if (isStudent) {
            enqueueSnackbar(`Chat has been ${message.muted ? "closed" : "opened"} by teacher`, {variant:'info'});
          }
        } else if (message?.type === "requestShare") {
          if (!isStudent) {
            setShowRequestShareDialog(true);
            setRequestShareUser({
              attendeeId: message.attendeeId,
              userName: message.userName,
              userId: message.userId,
            })
          }
        } else if (message?.type === "agreeShare") {
          if (isStudent) {
            if (message.attendeeId === attendeeInfo.AttendeeId) {
              enqueueSnackbar('Teacher has agreed your share screen request, you can share now', {variant:'success'});
            } else {
              enqueueSnackbar(`Share screen permission is granted to ${getDisplayUserName(message.userName, message.userId)}`, {variant:'info'});
            }
            
            setShareScreenConfig(config => {
              return {
                sharing: config.sharing,
                fullscreen: config.fullscreen,
                allowShareUserId: message.userId,
                shareContentAttendeeId: config.shareContentAttendeeId,
              }
            })
          }
        } else if (message?.type === "disagreeShare") {
          if (isStudent) {
            if (message.attendeeId === attendeeInfo.AttendeeId) {
              enqueueSnackbar('Your share screen request is refused', {variant:'warning'});
            }
          }
        } else if (message?.type === "stopShare") {
          setShareScreenConfig(config => {
            return {
              sharing: false,
              fullscreen: config.fullscreen,
              allowShareUserId: teacherId,
              shareContentAttendeeId: config.shareContentAttendeeId,
            }
          })
          if (isStudent && currentUser?.id === message.userId) {
            enqueueSnackbar('Your screen share is stopped by teacher', {variant:'warning'});
            meetingSession.audioVideo.stopContentShare();
          }
        } else if (message?.type === "forbidShare") {
          setShareScreenConfig(config => {
            return {
              sharing: false,
              fullscreen: config.fullscreen,
              allowShareUserId: teacherId,
              shareContentAttendeeId: config.shareContentAttendeeId,
            }
          })
          if (isStudent && currentUser?.id === message.userId) {
            enqueueSnackbar("Screen share is forbidden by teacher", {variant:'info'})
            meetingSession.audioVideo.stopContentShare();
          }
          setMeetingConfigs(configs => {return {...configs, forbidShareContent: true}});
        } else if (message?.type === "cancelForbidShare") {
          setMeetingConfigs(configs => {return {...configs, forbidShareContent: false}});
        } else if (message?.type === "raiseHand") {
          setRaiseHandUser(message.userName);
          setTimeout(()=> {
            setRaiseHandUser("");
          }, 3000)
        } else if (message?.type === "notifyState") {
          setNotifyReceiveData(d => {
            return{ count: d.count + 1, data: message }
          })
        } else if (message?.type === "toppingStudent") {
          logUtil("indexMap", indexMap)
          const toppingUserId = message.userId;
          const toppingTileId = getTileIdByUserId(toppingUserId, meetingSession.audioVideo);
          logUtil("toppingTileId:", toppingTileId)
          if (toppingTileId) {
            meetingSession.audioVideo.bindVideoElement(toppingTileId, videoElements.current[0]);
            setToppingConfig({topping: true, tileId: toppingTileId, toppingUserId: toppingUserId})
          }
        } else if (message?.type === "cancelToppingStudent") {
          setToppingConfig({topping: false, tileId: -1, toppingUserId: ""})
        } else if (message?.type === "shareFile") {
          logUtil("shared file:", message.fileUrl)
          setShareFileDialog({title:"The teacher wants to share a file with you ", openDialog: true, showFile: false, fileUrl: message.fileUrl, fileType: message.fileType})
        }
      } 
    })

    meetingSession.audioVideo.transcriptionController?.subscribeToTranscriptEvent((transcriptEvent: Chime.TranscriptEvent) => {
      console.log('transcriptEvent:', transcriptEvent);
      if (transcriptEvent instanceof Chime.TranscriptionStatus) {
        if((transcriptEvent.type === Chime.TranscriptionStatusType.STARTED)){
          enqueueSnackbar('Live transcription is started', {variant:'info'})
          setTransitionOn(true);
        } else if ((transcriptEvent.type === Chime.TranscriptionStatusType.STOPPED || transcriptEvent.type === Chime.TranscriptionStatusType.FAILED)) {
          enqueueSnackbar('Live transcription is stopped', {variant:'info'})
          setTransitionOn(false);
          setTransitionText("");
        }
      } else if (transcriptEvent instanceof Chime.Transcript) {
        if (!displaytransition) {
          setTransitionText("");
          logUtil("Transition: !displaytransition ")
          return;
        }
        // if (selectLanguage === NO_LANGUAGE) {
        //   setTransitionText("");
        //   logUtil("Transition: NO_LANGUAGE ")
        //   return;
        // }
        for (const result of transcriptEvent.results) {
          // const isPartial = result.isPartial;
          // if (!isPartial) {
            logUtil("Transition: received ", transcriptEvent)
            const transitionMessage = result.alternatives[0].transcript;
            setTransitionText(transitionMessage);
          // }
        }
      }
    })

    // meetingSession.audioVideo.start();
    setPreviewConfig(config => ({...config, showPreviewDialog: true}))
    const previewVideoElement = document.getElementById('preview-video') as HTMLVideoElement;
    previewVideoElement && meetingSession.audioVideo.startVideoPreviewForVideoInput(previewVideoElement);
    setmAudioVideo(meetingSession.audioVideo);


    if (isStudent) {
      // 进入meeting时，老师已经设定过Close All video，则初始化关闭摄像头
      const shouldClose = meetingConfigs.closeAllVideo;
      setVideoOn(!shouldClose);

      // 进入meeting时，老师已经设定过muteAll，则初始化为关闭麦克风
      const shouldMute = meetingConfigs.muteAll
      setMute(shouldMute)

      if (shouldClose || shouldMute) {
        // 延迟几秒钟再mute和关闭video，否则attendeeList还没数据，列表刷新不成功
        setTimeout(() => {
          if (shouldClose) {
            meetingSession.audioVideo.stopLocalVideoTile();
            setAttendeeList(list => list.map(item => {
              if (item.userId === currentUser?.id) {
                return {
                  attendeeId: item.attendeeId,
                  userId: item.userId,
                  userName: item.userName,
                  userAvatar: item.userAvatar,
                  mute: shouldMute,
                  speaking: item.speaking,
                  videoOff: true,
                }
              } else {
                return item;
              }
            }))
          }
          if (shouldMute) {
            meetingSession.audioVideo.realtimeMuteLocalAudio();
            // doesn't need refresh list here because will refresh in listener
          }
          logUtil("Muteeee refresh list: ", attendeeList)
        }, 5 * 1000)

      }
    } else {
      // is teacher
    }

  };

  const handleEnterMeeting = () => {
    mAudioVideo?.start();
    setMute(previewConfig.previewMute);
    setVideoOn(!previewConfig.previewVideoOff);
    
    setPreviewConfig(config => ({
      ...config,
      showPreviewDialog: false
    }));
    
    if (!isStudent && !meetingControls.recordStart) {
      // Record video. Only live video class has a switch when creating.
      if ((meetingType === MeetingTypeEnum.LiveVideoClass && meetingControls.meetingRecord) || meetingType !== MeetingTypeEnum.LiveVideoClass) {
        recordMeetingVideo({
          meetingId: meetingId, 
          meetingType: (meetingType === MeetingTypeEnum.LiveVideoClass) ? "broadcast" : "class",
          recordType: recordVideoType,
        })
      }
    }
  }

  React.useEffect(() => {
    if (audioVideoStartFlag) {
      setAudioVideoStartFlag(false);
      mAudioVideo?.startVideoInput(selectAudioVideoDevice.videoInput);
      if (!previewConfig.previewVideoOff) {
        mAudioVideo?.startLocalVideoTile();
      }
      if (previewConfig.previewMute) {
        mAudioVideo?.realtimeMuteLocalAudio();
      } else {
        mAudioVideo?.realtimeUnmuteLocalAudio();
      }
      setAttendeeList(list => list.map(item => {
        if (item.userId === credential?.id) {
          const newAttendee = {
            attendeeId: item.attendeeId,
            userId: item.userId,
            userName: item.userName,
            userAvatar: item.userAvatar,
            mute: previewConfig.previewMute,
            speaking: item.speaking,
            videoOff: previewConfig.previewVideoOff,
          }
          return newAttendee;
        } else {
          return item;
        }
      })) 
    }
  }, [audioVideoStartFlag])


  const handleClickPreviewVideoButton = () => {
    const previewVideoElement = document.getElementById('preview-video') as HTMLVideoElement;
    if (previewConfig.previewVideoOff) {
      setPreviewConfig(config => ({
        ...config,
        previewVideoOff: false
      }));
      previewVideoElement && mAudioVideo?.startVideoPreviewForVideoInput(previewVideoElement);
    } else {
      setPreviewConfig(config => ({
        ...config,
        previewVideoOff: true
      }));
      previewVideoElement && mAudioVideo?.stopVideoPreviewForVideoInput(previewVideoElement);
    }
  }

  const exitMeeting = async () => {
    logUtil("video classroom  33333 exiting meeting: ", mAudioVideo)
    // report join meeting time length
    reportPlayHistory({
      course_type: getParamLessonType(meetingType),
      course_id: meetingId,
      teacher_id: teacherId,
      user_id: credential?.id || '',
      visit_length: Math.floor((new Date().getTime() - joinTimestamp)/1000),
    })

    if (mAudioVideo) {
      // mAudioVideo?.removeObserver(audioVideoObserver);
      // mAudioVideo?.removeDeviceChangeObserver(deviceChangeObserver);
      // mAudioVideo?.removeContentShareObserver(contentShareObserver);
      // mAudioVideo?.realtimeUnsubscribeToAttendeeIdPresence(attendeeIdPresenceObserver);
      // mAudioVideo?.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(localAudioMuteObserver);
      mAudioVideo?.realtimeUnsubscribeFromReceiveDataMessage("chat");
      mAudioVideo?.realtimeUnsubscribeFromReceiveDataMessage("control");
      // mAudioVideo?.transcriptionController?.unsubscribeFromTranscriptEvent(transcriptEventObserver);

      mAudioVideo?.stopAudioInput();
      mAudioVideo?.stopLocalVideoTile();
      mAudioVideo?.stopVideoInput();
      mAudioVideo?.stopContentShare();
      mAudioVideo?.stop();
    }
    onExitMeeting();
    // if (exitBySelf) {
    // deleteAttendee({meetingId: meetingId, attendeeId: attendeeInfo.AttendeeId})
    // .unwrap()
    // .then(res => {});
    // }
  }

  const getParamLessonType = (learnType: MeetingTypeEnum):ViewHistoryLessonType => {
    if (learnType === MeetingTypeEnum.LiveVideoClass) {
      return ViewHistoryLessonType.LiveVideoClass;
    } else if (learnType === MeetingTypeEnum.PrivateLesson) {
      return ViewHistoryLessonType.PrivateLesson;
    } else if (learnType === MeetingTypeEnum.SeriesLesson) {
      return ViewHistoryLessonType.SeriesLesson;
    }
    return ViewHistoryLessonType.Unknown;
  }

  const handleCloseMeeting = async() => {
    setIsLoading(true);
    setShowCloseMeetingDialog(false)
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      requestCloseMeeting({meetingId: meetingId})
      .unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          exitMeeting()
        } else {
          enqueueSnackbar(res?.message || "Close classroom failed", {variant:'error'})
        }
      })
    } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
      closeSeriesLessonMeeting({classroomId: meetingId})
      .unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          exitMeeting()
        } else {
          enqueueSnackbar(res?.message || "Close class failed", {variant:'error'})
        }
      })
    }
    setIsLoading(false);
  };


  const handleKeyPress = (event?) => {
    console.log('handleKeyPress event', event);
    if (meetingConfigs.closeChat && isStudent) {
      enqueueSnackbar("Chat has been closed", {variant:'info'})
      return
    }
    if(event && event.keyCode !== 13) { 
      return
    }
    const inputMsg = chatContent;
    if (!inputMsg) {
      return;
    }
    const message : ChatMessage= {
      id: new Date().getTime().toString(),
      type: "text",
      content: inputMsg,
      // thumbnail: "",
      sender_id: currentUser?.id||"",
      sender_name: currentUser?.name||"",
      sender_avatar: currentUser?.avatarUrl ||"",
    }
    mAudioVideo?.realtimeSendDataMessage("chat", JSON.stringify(message), 0);
    logUtil("video classroom  send message: ", inputMsg);
    setChatContent("");
    setChatMessageList(list => [...list, message]);
    scrollToBottom();

    if (tabPosition !== TabPosition.Chat) {
      setTabPosition(TabPosition.Chat);
    }
  
  }

  const refreshSendFileStatus = (entity: FileEntity) => {
    setSendFile({
      fileName: entity.fileName,
      filePath: entity.filePath,
      fileType: entity.fileType,
      fileSize: entity.fileSize,
      fileStatus: entity.fileStatus,
      publicUrl: entity.publicUrl
    })
  }

  const handleSelectFile = (entity: FileEntity) => {
    const message : ChatMessage = {
      id: entity.filePath,
      type: "file",
      content: JSON.stringify(entity),
      // thumbnail: "",
      sender_id: currentUser?.id || "",
      sender_name: currentUser?.name || "",
      sender_avatar: currentUser?.avatarUrl || "",
      status: FileStatus.UPLOADING,
    }
    setChatMessageList(list => [...list, message]);
    scrollToBottom();

    if (tabPosition !== TabPosition.Chat) {
      setTabPosition(TabPosition.Chat);
    }
  }

  const handleFileUploaded = (entity: FileEntity) => {
    const newChatList = chatMessageList.map(item => {
      if (item.id === entity.filePath) {
        item.status = FileStatus.UPLOADED;
        item.content = JSON.stringify(entity);
        mAudioVideo?.realtimeSendDataMessage("chat", JSON.stringify(item), 0);
        return item;
      } else {
        return item;
      }
    });
    setChatMessageList(newChatList);
  }

  const handleMuteAll = async (event) => {
    //topic = "control"
    //{"type":"audio/video/chat", "muted":true/false,  "attendees":["123", "456"]}
    const message = {
      // id: "",
      type: "audio",
      muted: event.target.checked,
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    logUtil("video classroom  send mute all : ", message);

    setMeetingConfigs(config => {return {
      muteAll: event.target.checked,
      closeAllVideo: config.closeAllVideo,
      closeChat: config.closeChat,
      forbidShareContent: config.forbidShareContent,
    }})

    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      setMeetingFunction({meetingId: meetingId, type: event.target.checked ? FuntionTypeEnum.Mute : FuntionTypeEnum.Unmute})
      .unwrap()
      .then(res => {})
    } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
      setSeriesLessonAudioVideoFunction({
        classroomId: meetingId, 
        type: event.target.checked ? SeriesLessonAudioVideoFunctionEnum.MUTE_ALL : SeriesLessonAudioVideoFunctionEnum.UNMUTE_ALL
      })
    }
  }

  /*
  const handleMuteSingleUser = async (user: any) => {
    logUtil("video classroom  handle video: user :", user)
    if (user.user_id === currentUser?.id) {
      handleMuteAudioInput(!mute)
    } else {
      const list: any[] = [];
      list.push(user.attendee_id);
      //topic = "control"
      //{"type":"audio/video/chat", "muted":true/false,  "attendees":["123", "456"]}
      const message = {
        attendees: list,
        type: "audio",
        muted: !user.mute,
      }
      mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
      logUtil("video classroom  send mute user : ", message);
    }
    //refresh list
    setAttendeeList(list => list.map(item => {
      if (item.userId === user.userId) {
        return {
          attendeeId: user.attendeeId,
          userId: user.userId,
          userName: user.userName,
          userAvatar: user.userAvatar,
          mute: !user.mute,
          videoOff: user.videoOff,
        }
      } else {
        return item;
      }
    }))
  }
  */

  const handleCloseAllVideo = async (event) => {
    //topic = "control"
    //{"type":"audio/video/chat", "muted":true/false,  "attendees":["123", "456"]}
    const message = {
      // id: "",
      type: "video",
      muted: event.target.checked,
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    logUtil("video classroom  send close all video: ", message);

    setMeetingConfigs(config => {return {
      muteAll: config.muteAll,
      closeAllVideo: event.target.checked,
      closeChat: config.closeChat,
      forbidShareContent: config.forbidShareContent,
    }})
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      setMeetingFunction({meetingId: meetingId, type: event.target.checked ? FuntionTypeEnum.CloseVideo : FuntionTypeEnum.OpenVideo})
      .unwrap()
      .then(res => {})
    } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
      setSeriesLessonAudioVideoFunction({
        classroomId: meetingId, 
        type: event.target.checked ? SeriesLessonAudioVideoFunctionEnum.CLOSE_ALL_VIDEO : SeriesLessonAudioVideoFunctionEnum.OPEN_ALL_VIDEO
      })
    }
  }

  /*
  const handleVideoSingleUser = async (user: any) => {
    logUtil("video classroom  handle video: user :", user)
    if (user.user_id === currentUser?.id) {
      handleCloseLocalVideo(videoOn)
    } else {
      const list: any[] = [];
      list.push(user.attendee_id);
      //topic = "control"
      //{"type":"audio/video/chat", "muted":true/false,  "attendees":["123", "456"]}
      const message = {
        attendees: list,
        type: "video",
        muted: !user.videoOff,
      }
      mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
      logUtil("video classroom  send mute user : ", message);
    }
    //refresh list
    setAttendeeList(list => list.map(item => {
      if (item.userId === user.userId) {
        return {
          attendeeId: user.attendeeId,
          userId: user.userId,
          userName: user.userName,
          userAvatar: user.userAvatar,
          mute: user.mute,
          videoOff: !user.videoOff,
        }
      } else {
        return item;
      }
    }))
  }
  */

  const handleCloseChat = async (event) => {
    const message = {
      // id: "",
      type: "chat",
      muted: event.target.checked,
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 1000 * 60 * 5);
    logUtil("video classroom  send close all video: ", message);

    setMeetingConfigs(config => {return {
      muteAll: config.muteAll,
      closeAllVideo: config.closeAllVideo,
      closeChat: event.target.checked,
      forbidShareContent: config.forbidShareContent,
    }})
    setChatContent("");
  }

  const handleForbidShareScreen = (event) => {
    const forbid = event.target.checked;
    setMeetingConfigs(configs => {return {...configs, forbidShareContent: forbid}});
    if (forbid) {
      const message = {
        // id: "",
        type: "forbidShare",
        userId: shareScreenConfig.allowShareUserId,
      }
      mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
      teacherDisableCurrrentShareByApi();

      if (meetingType === MeetingTypeEnum.LiveVideoClass) {
        setMeetingFunction({meetingId: meetingId, type: FuntionTypeEnum.ForbidShare});
      } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
        setSeriesLessonForbidShare({classroomId: meetingId});
      }
    } else {
      const message = {
        // id: "",
        type: "cancelForbidShare",
      }
      mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);

      if (meetingType === MeetingTypeEnum.LiveVideoClass) {
        setMeetingFunction({meetingId: meetingId, type: FuntionTypeEnum.CancelForbidShare});
      } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
        setSeriesLessonCancelForbidShare({classroomId: meetingId});
      }
    }
  }

  
  const handleDisplayTransitionChange = async (event) => {
    const shouldDisplay = event.target.checked;
    setDisplayTransition(shouldDisplay);
    if (!shouldDisplay) {
      setTransitionText("");
    }
  }

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  /*
  const handleRecordVideoChange = async (event) => {
    setRecordVideo(event.target.checked);

    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      setMeetingFunction({meetingId: meetingId, type: event.target.checked ? FuntionTypeEnum.StartRecord : FuntionTypeEnum.EndRecord})
      .unwrap()
      .then(res => {})
    } else {
      if (event.target.checked) {
        enqueueSnackbar("Current class has started record video automatically", {variant: 'warning'})
      }
    }
  }
  */

  const handleChatContentChange = (event) => {
    setChatContent(event.target.value);
  }

  const handleClickLocalMuteButton = (shouldMute: boolean) => {
    if (isStudent && meetingConfigs.muteAll) {
      enqueueSnackbar("The teacher has muted All", {variant:'info'})
      return;
    }
    handleMuteAudioInput(shouldMute);
  }

  const handleMuteAudioInput = (shouldMute: boolean) : boolean => {
    logUtil("video classroom  33333 handleMuteAudioInput shouldMute ", shouldMute)
    logUtil("video classroom  33333 handleMuteAudioInput: mAudioVideo", mAudioVideo)
    if (mAudioVideo === null || shouldMute === undefined) {
      return false;
    }
    if (!shouldMute) {
      mAudioVideo?.realtimeUnmuteLocalAudio();
    } else {
      mAudioVideo?.realtimeMuteLocalAudio();
    }

    setMute(shouldMute)
    // here don't need refresh attendee list, will refresh in mute listener
    return true;
  }

  const handleClickLocalVideoButton = (shouldClose: boolean) => {
    if (isStudent && meetingConfigs.closeAllVideo) {
      enqueueSnackbar('The teacher has closed all videos', {variant:'info'})
      return;
    }
    handleCloseLocalVideo(shouldClose);
  }

  const handleCloseLocalVideo = (shouldClose: boolean): boolean => {
    logUtil("video classroom  handleCloseLocalVideo: shouldClose", shouldClose)
    logUtil("video classroom  handleCloseLocalVideo: mAudioVideo", mAudioVideo)
    if (mAudioVideo === null || shouldClose === undefined) {
      return false;
    }
    if (shouldClose) {
      mAudioVideo?.stopLocalVideoTile();
      // backup and clear virtual background
      if (virtualBGConfig.index !== 0) {
        setVirtualBGConfigBackUp(virtualBGConfig);
        handleSelectVirturlBackground(0, "", selectAudioVideoDevice.videoInput);
      }
      setVideoUserIDList(list => list.filter(item => item !== currentUser?.id))
    } else {
      mAudioVideo?.startLocalVideoTile();
      // recover virtual background
      if (virtualBGConfigBackUp) {
        handleSelectVirturlBackground(virtualBGConfigBackUp.index, virtualBGConfigBackUp.imageUrl, selectAudioVideoDevice.videoInput);
        setVirtualBGConfigBackUp(null)
      }
      setVideoUserIDList(list => [...list, currentUser?.id||""])
    }
    //refresh list, need refresh here because listenter doesn't work
    setAttendeeList(list => list.map(item => {
      if (item.userId === currentUser?.id) {
        return {
          attendeeId: item.attendeeId,
          userId: item.userId,
          userName: item.userName,
          userAvatar: item.userAvatar,
          mute: item.mute,
          speaking: item.speaking,
          videoOff: shouldClose,
        }
      } else {
        return item;
      }
    }))

    setVideoOn(!shouldClose)
    return true;
  }

  const handleClickRaiseHand = () => {
    setRaiseHandUser(currentUser?.name||"");
    setTimeout(()=> {
      setRaiseHandUser("");
    }, 3000)

    const message = {
      // id: "",
      type: "raiseHand",
      attendeeId: attendeeInfo.AttendeeId,
      userId: currentUser?.id,
      userName: currentUser?.name,
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
  }

  const handleClickRequestShareIcon = () => {
    if (meetingConfigs.forbidShareContent) {
      enqueueSnackbar("Screen share has been forbidden by teacher", {variant:"warning"})
      return;
    }
    const message = {
      // id: "",
      type: "requestShare",
      attendeeId: attendeeInfo.AttendeeId,
      userId: currentUser?.id,
      userName: currentUser?.name,
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    enqueueSnackbar("Screen share request is sent, please waiting", {variant:'info'})
  }

  const handleAgreeStudentShareScreen = () => {
    setShowRequestShareDialog(false);
    setMeetingConfigs(configs => {return {...configs, forbidShareContent: false}});
    // stop current sharing
    stopShareContent();
    
    setShareScreenConfig(config => {
      return {
        sharing: config.sharing,
        fullscreen: config.fullscreen,
        allowShareUserId: requestShareUser.userId,
        shareContentAttendeeId: config.shareContentAttendeeId,
      }
    })

    if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
      const message = {
        // id: "",
        type: "agreeShare",
        attendeeId: requestShareUser.attendeeId,
        userId: requestShareUser.userId,
        userName: requestShareUser.userName,
      }
      mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    } else if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      // request api to update the attendees who have share permission 
      // 防止给已有分享权限的人设置错误
      let stopAttendeeId = '';
      if (shareScreenConfig.allowShareUserId !== requestShareUser.userId) {
        const stopShareAttendee = attendeeList.find(item => item.userId === shareScreenConfig.allowShareUserId);
        stopAttendeeId = stopShareAttendee?.attendeeId||'';
      }
      setIsLoading(true);
      updateAttendeeShareInfo({
        meetingId: meetingId, 
        shareAttendeeId: requestShareUser.attendeeId, 
        stopShareAttendeeId: stopAttendeeId,
      })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          // send message to tell attendee the share request is agreed 
          const message = {
            // id: "",
            type: "agreeShare",
            attendeeId: requestShareUser.attendeeId,
            userId: requestShareUser.userId,
            userName: requestShareUser.userName,
          }
          mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);

          logUtil("video classroom agree share and api success, send message ===", message)
        } else {
          enqueueSnackbar("set share permission failed", {variant:'error'})
        }
      }).finally(() => {setIsLoading(false);})
    }
  }
  
  const handleDisagreeStudentShareScreen = () => {
    setShowRequestShareDialog(false);
    const message = {
      // id: "",
      type: "disagreeShare",
      attendeeId: requestShareUser.attendeeId,
      userId: requestShareUser.userId,
      userName: requestShareUser.userName,
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
  }

  const handleClickShareScreenIcon = async() => {
    if (!isStudent) {
      startShareContent();
    } else {
      if (meetingConfigs.forbidShareContent) {
        enqueueSnackbar("Screen share has been forbidden by teacher", {variant:"warning"})
        return;
      }
      if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
        startShareContent();
      } else if (meetingType === MeetingTypeEnum.LiveVideoClass) {
        setIsLoading(true);
        getAttendeeShareInfo({meetingId: meetingId, attendeeId: attendeeInfo.AttendeeId})
        .unwrap()
        .then(res => {
          if (res && !res.isFailed && res.result?.share_screen) {
            startShareContent();
          } else {
            setShareScreenConfig(config => {
              return {
                sharing: config.sharing,
                fullscreen: config.fullscreen,
                allowShareUserId: teacherId,
                shareContentAttendeeId: config.shareContentAttendeeId,
              }
            })
            enqueueSnackbar("You don't have the share permission, please request again", {variant:'error'})
          }
        })
        .catch(e => {
          enqueueSnackbar('query share permission failed', {variant:'error'})
        })
        .finally(() => {setIsLoading(false)})
      }
    }
  }

  const startShareContent = async () => {
    try {
      if (!shareScreenConfig.sharing) {
        await mAudioVideo?.startContentShareFromScreenCapture();
      } else {
        enqueueSnackbar('You must Stop current sharing before start a new sharing', {variant:'info'})
      }
    } catch(error) {
      enqueueSnackbar('share screen failed or canceled, please try again', {variant:'error'})
    }
  }

  // teacher or sharing student will click dialog button to run this function
  const stopAndDisableShare = () => {
    stopShareContent();
    teacherDisableCurrrentShareByApi();
  }
  
  const teacherDisableCurrrentShareByApi = async () => {
    if (!isStudent && shareScreenConfig.allowShareUserId !== teacherId) {
      setShareScreenConfig(config => {
        return {
          sharing: false,
          fullscreen: config.fullscreen,
          allowShareUserId: teacherId,
          shareContentAttendeeId: '',
        }
      })
      if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
        return;
      }
      // disable current sharing user's share permission
      setIsLoading(true);
      const sharingAttendee = attendeeList.find(item => item.userId === shareScreenConfig.allowShareUserId);
      if (sharingAttendee) {
        disableAttendeeShare({meetingId: meetingId, disableAttendeeId: sharingAttendee.attendeeId})
        .unwrap()
        .then(res => {
          if (res && !res.isFailed) {
            enqueueSnackbar(`${getDisplayUserName(sharingAttendee.userName, sharingAttendee.userId)}'s share is disabled`, {variant:'info'})
          } else {
            enqueueSnackbar(res && res.message? "Failed to stop share:" + res.message: "Failed to stop share", {variant:'error'})
          }
        })
      }
      setIsLoading(false);
    }
  }

  
  const stopShareContent = () => {
    if (!shareScreenConfig.sharing) {
      return;
    }
    
    if (shareScreenConfig.allowShareUserId === currentUser?.id) { // is self sharing
      mAudioVideo?.stopContentShare();
      setShareScreenConfig(config=>{
        return {
          sharing: false,
          fullscreen: config.fullscreen,
          allowShareUserId: config.allowShareUserId,
          shareContentAttendeeId: '',
        }
      });
    } else { // other student is sharing
      const message = {
        // id: "",
        type: "stopShare",
        userId: shareScreenConfig.allowShareUserId,
      }
      mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    }
    setShowStopShareDialog(false);
  }
  
  const handleShareFile = async(file : ClassroomMaterialCardEntity) => {
    if (!isStudent) {
      setShareFileDialog({title:"This file is sent to students", openDialog: true, showFile: true, fileUrl: file.file_url, fileType: file.file_type});
      // enqueueSnackbar("this file share request is sent", {variant: "info"});
      const message = {
        // id: "",
        type: "shareFile",
        fileUrl: file.file_url,
        fileType: file.file_type,
      }
      mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    }
  }

  const showSharedFile = () => {
    if (shareFileDialog.fileType.startsWith("image") || shareFileDialog.fileType.startsWith("video")) {
      setShareFileDialog({title: "Shared file" ,openDialog: true, showFile: true, fileUrl: shareFileDialog.fileUrl, fileType: shareFileDialog.fileType});
    } else if (shareFileDialog.fileType === "application/pdf") {
      // window.open(shareFileDialog.fileUrl, "_blank");
      // setShareFileDialog({title:"", openDialog: false, showFile: false, fileUrl: '', fileType: ''});
      setShareFileDialog({title: "Shared PDF" ,openDialog: true, showFile: true, fileUrl: shareFileDialog.fileUrl, fileType: shareFileDialog.fileType});
    } else {
      window.open(shareFileDialog.fileUrl, "_blank");
    }
  }

  const handleSwitchTab = (event, newValue) => {
    setTabPosition(newValue);
    if (newValue === 2) {
      scrollToBottom();
      setUnReadMessageCount(0);
    }
  }

  const handleSelectVirturlBackground = async (index: number, imageUrl: string|null, device: Chime.Device) => {
    if (!mAudioVideo) {
      return;
    }
    setShowVirtualBGDialog({showDialog: false, showLoading:true});

    if (index === 0) {
      await mAudioVideo?.startVideoInput(device);
    } else if (index === 1) {
      try {
        const processors:Chime.VideoFrameProcessor[] = [];
        if (await Chime.BackgroundBlurVideoFrameProcessor.isSupported()) {
            const blurProcessor = await Chime.BackgroundBlurVideoFrameProcessor.create();
            if (blurProcessor) {
              processors.push(blurProcessor);
            }
        }
        let transformDevice = new Chime.DefaultVideoTransformDevice(logger, device, processors);
        await mAudioVideo?.startVideoInput(transformDevice);
      } catch(error) {
        enqueueSnackbar("set blur background failed", {variant:'error'});
        tryToReportError("Blur background", "Set blur background failed: " + JSON.stringify(error));
      }
    } else {
      const url = imageUrl || DEFAULT_VIRTUAL_BG;
      const processors : Chime.BackgroundReplacementProcessor[] = [];
      if (await Chime.BackgroundReplacementVideoFrameProcessor.isSupported()) {
        try {
          const image = await fetch(url); 
          const imageBlob = await image.blob();
          const options = { imageBlob };
          // use processor.setImageBlob(imageBlob) for replace background image, doesn't work
            const processor = await Chime.BackgroundReplacementVideoFrameProcessor.create(undefined, options); 
            if (processor) {
              processors.push(processor);
            }
            const transformDevice = new Chime.DefaultVideoTransformDevice(
              logger,
              device,
              processors
            );
            await mAudioVideo?.startVideoInput(transformDevice);
        } catch(error) {
          enqueueSnackbar("set virtual background failed", {variant:'error'});
          tryToReportError("virtual background", "Set virtual background failed: " + JSON.stringify(error));
        }
      }
    }

    setVirtualBGConfig({index:index, imageUrl: imageUrl||DEFAULT_VIRTUAL_BG});
    setShowVirtualBGDialog({showDialog: false, showLoading:false});
    // 如果自己的视频被置顶，则刷新置顶的视频背景
    if (toppingConfig.topping && toppingConfig.toppingUserId === credential?.id) {
      mAudioVideo?.bindVideoElement(toppingConfig.tileId, videoElements.current[0]);
    }
  }

  const handleAudioInputDeviceChange = (event) => {
    mAudioVideo?.startAudioInput(event.target.value);
    setSelectAudioVideoDevice(devices => ({
      audioInput: event.target.value,
      audioOutput: devices.audioOutput,
      videoInput: devices.videoInput,
      videoOutput: devices.videoOutput,
    }));
  }

  const handleAudioOutputDeviceChange = (event) => {
    mAudioVideo?.chooseAudioOutput(event.target.value);
    // const audioOutputElement = document.getElementById("audio");
    // const element = audioOutputElement as HTMLAudioElement;
    // mAudioVideo?.bindAudioElement(element);
    
    setSelectAudioVideoDevice(devices => ({
      audioInput: devices.audioInput,
      audioOutput: event.target.value,
      videoInput: devices.videoInput,
      videoOutput: devices.videoOutput,
    }));
  }

  const handleVideoInputDeviceChange = (event) => {
    switchCamera(event.target.value);
  }  
  
  const switchCamera = (cameraId: string) => {
    mAudioVideo?.startVideoInput(cameraId);
    setSelectAudioVideoDevice(devices => ({
      audioInput: devices.audioInput,
      audioOutput: devices.audioOutput,
      videoInput: cameraId,
      videoOutput: devices.videoOutput,
    }));
    // set virtual background
    handleSelectVirturlBackground(virtualBGConfig.index, virtualBGConfig.imageUrl, cameraId);
  }

  const handlePreviewVideoInputDeviceChange = (event) => {
    // not work
    const previewVideoElement = document.getElementById('preview-video') as HTMLVideoElement;
    // previewVideoElement && mAudioVideo?.stopVideoPreviewForVideoInput(previewVideoElement);
    mAudioVideo?.startVideoInput(event.target.value);
    previewVideoElement && mAudioVideo?.startVideoPreviewForVideoInput(previewVideoElement);
    setSelectAudioVideoDevice({
      audioInput: selectAudioVideoDevice.audioInput,
      audioOutput: selectAudioVideoDevice.audioOutput,
      videoInput: event.target.value,
      videoOutput: selectAudioVideoDevice.videoOutput,
    });
  }

  const handleSelectLanguageChange = async (event) => {
    setSelectLanguage(event.target.value);
    if (NO_LANGUAGE === event.target.value) {
      setTransitionText("");
    }
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      setMeetingFunction({meetingId: meetingId, type: NO_LANGUAGE === event.target.value ? FuntionTypeEnum.EndTranscription : FuntionTypeEnum.StartTranscription, language: NO_LANGUAGE === event.target.value ? "" : event.target.value})
      .unwrap()
      .then(res => {})
    } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
      if (NO_LANGUAGE === event.target.value) {
        stopSeriesLessonTranscription({classroomId: meetingId});
      } else {
        startSeriesLessonTranscription({classroomId: meetingId, language:event.target.value})
      }
    }
  }


  const getVideoTileState = (tileId: number, paramAudioVideo?: Chime.AudioVideoFacade) => {
    const audioVideo = paramAudioVideo || mAudioVideo;
    if (!audioVideo) {
      return null;
    }
    if (!tileId) {
      return null;
    }

    // TODO if video is closed, can not get video tile
    const videoTile = audioVideo.getVideoTile(tileId);
    if (videoTile) {
      return videoTile.state();
    }
    return null;
  }

  const getVideoTileUserName = (tileId: number) => {
    const videoTileState = getVideoTileState(tileId);
    // logUtil("video classroom  getVideoTileUserName: tileId=="+ tileId + ", tileState == " + videoTileState?.boundExternalUserId)
    const attendee = attendeeList.find(item => item.userId === videoTileState?.boundExternalUserId);
    return attendee ? getDisplayUserName(attendee.userName, attendee.userId) : "";
  }

  const getVideoTileUserId = (tileId: number) => {
    const videoTileState = getVideoTileState(tileId);
    // logUtil("video classroom  getVideoTileUserName: tileId=="+ tileId + ", tileState == " + videoTileState?.boundExternalUserId)
    const attendee = attendeeList.find(item => item.userId === videoTileState?.boundExternalUserId);
    return attendee?.userId || "";
  }

  const getVideoTileAttendeeId = (tileId: number) => {
    const videoTileState = getVideoTileState(tileId);
    if (videoTileState) {
      return videoTileState.boundAttendeeId;
    }
    return "";
  }

  const getIsUserMute = (tileId: number): boolean => {
    const attendeeId = getVideoTileAttendeeId(tileId);
    const findUser = attendeeList.find(item => item.attendeeId === attendeeId);
    // logUtil("video classroom  find user mute:" + attendeeId +", mute:"+ findUser?.mute);
    return findUser ? findUser.mute : false;
  }

  const isTeacherVideoOff = (): boolean => {
    const findTeacher = attendeeList.find(item => item.userId === teacherId);
    return findTeacher? findTeacher.videoOff : true;
  }

  const getTeacherVideoStyle = () => {
    if (shareScreenConfig.sharing || toppingConfig.topping) {
      return shareScreenConfig.fullscreen ? classes.teacherFullscreenSmallVideo : classes.teacherSmallVideo;
    } else {
      return shareScreenConfig.fullscreen ? classes.teacherFullscreenBigVideo : classes.teacherBigVideo;
    }
  }

  const getToppingOrShareClass = () => {
    if (toppingConfig.topping) {
      return shareScreenConfig.fullscreen ? classes.toppingFullscreenVideo : classes.toppingVideo;
    } else if (shareScreenConfig.sharing) {
      return shareScreenConfig.fullscreen ? classes.shareFullscreenVideo : classes.shareVideo;
    } 
  } 

  const shouldHideVideoTile = (index: number) => {
    const tileId = indexMap[index+2];
    const tile = getVideoTileState(tileId);
    if (!tile || !tile.boundExternalUserId || !videoUserIDList.includes(tile.boundExternalUserId)) {
      return true;
    }
    const length = attendeeList?.length;
    if (index >= length) {
      return true;
    }
    const findTeacher = attendeeList.find(item => item.userId === teacherId);
    if (findTeacher) {
      return index >= length -1; 
    } else {
      return index >= length;
    }
  }

  const IsTeacherJoinIn = () : boolean => {
    if (attendeeList?.length === 0) {
      return false;
    }
    const findTeacher = attendeeList.find(item => item.userId === teacherId);
    return !!findTeacher;
  }

  const handleClickConfirmAlertDialog = () => {
    const shouldExit = alertDialogData.exitAfterClose;
    setAlertDialogData({
      showAlertDialog: false,
      alertDialogTitle: "",
      exitAfterClose: false,
    })
    shouldExit && exitMeeting();
  }

  const handleClickToppingStudent = (index: number) => {
    if (shareScreenConfig.sharing) {
      enqueueSnackbar("Please stop current screen share", {variant:'info'});
      return;
    }
    const tileId = indexMap[index+2];
    const tileState = getVideoTileState(tileId);

    mAudioVideo?.bindVideoElement(tileId, videoElements.current[0]);
    setToppingConfig({topping: true, tileId: tileId, toppingUserId: tileState?.boundExternalUserId||""})
    
    const message = {
      type: "toppingStudent",
      userId: tileState?.boundExternalUserId||"",
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      setToppingStudent({meetingId: meetingId, topping_user: tileState?.boundExternalUserId||""})
    } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
      setSeriesLessonToppingStudent({classroomId: meetingId, toppingUserId: tileState?.boundExternalUserId||""})
    }
  }

  const cancelToppingStudent = () => {
    setToppingConfig({topping: false, tileId: -1, toppingUserId: ""})

    const message = {
      type: "cancelToppingStudent",
    }
    mAudioVideo?.realtimeSendDataMessage("control", JSON.stringify(message), 0);
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      setToppingStudent({meetingId: meetingId, topping_user: ""})
    } else if (meetingType === MeetingTypeEnum.PrivateLesson || meetingType === MeetingTypeEnum.SeriesLesson) {
      setSeriesLessonToppingStudent({classroomId: meetingId, toppingUserId: ""})
    }
  }

  const getTileIdByUserId = (userId: string, audioVideo: Chime.AudioVideoFacade) => {
    for (const key in indexMap) {
      const tileId = indexMap[key];
      const tileState = getVideoTileState(tileId, audioVideo);
      if (tileState?.boundExternalUserId === userId) {
        return tileId;
      }
    }
    return undefined;
  }

  const handleSwitchMobileCamera = () => {
    if (videoInputList?.length < 2) {
      return;
    }
    const newCameraId = (videoInputList[0].deviceId === selectAudioVideoDevice.videoInput) ?
    videoInputList[1].deviceId : videoInputList[0].deviceId;
    switchCamera(newCameraId);
  }

  const handleClickMobileChat = () => {
    //TODO
    // scrollToBottom();
    setUnReadMessageCount(0);
    setShowMobileChat(true);
  }

  const logUtil = (message: string, ...optionalParams: any[]) => {
    logSwitch && console.log(message, optionalParams);
  }

  const getShareScreenUserName = () => {
    if (!shareScreenConfig.sharing) {
      return "";
    }
    const shareUser = attendeeList.find(item => item.userId === shareScreenConfig.allowShareUserId);
    return shareUser?.userName || shareScreenConfig.allowShareUserId;
  }

  const [moreButtonAnchorEl, setMoreButtonAnchorEl] = React.useState<null | HTMLElement>(null);
  const showMobileMore = Boolean(moreButtonAnchorEl);
  const [showMobileMaterials, setShowMobileMaterials] = React.useState(false);
  const [showMobileMembers, setShowMobileMembers] = React.useState(false);
  const [showMobileSettings, setShowMobileSettings] = React.useState(false);
  const [swipeIndex, SetSwipeIndex] = React.useState(0)

  const handleChangeSwipeIndex = (index:number) => {
    SetSwipeIndex(index)
  };

  const handleClickMobileMore = (event: React.MouseEvent<HTMLElement>) => {
    setMoreButtonAnchorEl(event.currentTarget);
  };
  const handleCloseMobileMore = () => {
    setMoreButtonAnchorEl(null);
  };

  const handleClickMobileMaterials = () => {
    handleCloseMobileMore();
    setShowMobileMaterials(true);
  }

  const handleClickMobileMembers = () => {
    handleCloseMobileMore();
    setShowMobileMembers(true);
  }

  const handleClickMobileSettings = () => {
    handleCloseMobileMore();
    setShowMobileSettings(true);
  }

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <>
      <audio id="audio" style={{display: "none"}}/>
      {isMobile? 
       <Box className={classesMobile.mobileRoot}>
        <Box className={classesMobile.mobileTopBox}>
          <IconButton onClick={handleSwitchMobileCamera}>
            <CameraSwitchIcon fontSize='medium' style={{color:'white'}}/>
          </IconButton>
          <TimeCountBox joinTimestamp={joinTimestamp} />
          <IconButton onClick={()=>{setShowExitDialog(true)}}>
            <PowerSettingsNewIcon fontSize='medium' style={{color:'white'}} />
          </IconButton>
        </Box>
        <Box className={classesMobile.swipeBox}>
          <SwipeableViews 
            index={swipeIndex}
            onChangeIndex={handleChangeSwipeIndex}
            enableMouseEvents>
            <Box className={classesMobile.videoBox}>
              <video
                hidden={!shareScreenConfig.sharing && !toppingConfig.topping} 
                className={classesMobile.shareVideo} 
                // style={toppingConfig.topping ? {transform: "scale(-1,1) !important"}:{transform:"none"}}
                ref={(el) => (videoElements.current[0] = el)}>
              </video>
              <video
                className={classesMobile.teacherVideo} 
                style={teacherId === credential?.id ? {transform: "scale(-1,1) !important"}:{transform:"none"}}
                ref={(el) => (videoElements.current[1] = el)}>
              </video>
              {/* {selfVideoIndex !== -1 && isStudent &&
              <video
                className={classesMobile.mobileSelfVideo} 
                ref={(el) => (videoElements.current[selfVideoIndex] = el)}>
              </video>
              } */}
            </Box>
            <Box className={classesMobile.gridVideoBox}>
              <Grid container spacing={2} display="flex" justifyContent="space-around" alignItems="center" >
                {[...Array(23)].map((val, i) => (
                <Grid key={i} item xs={4} sm={4} md={4} lg={4} xl={4} hidden={shouldHideVideoTile(i)}>
                  <Box key={i} className={classesMobile.mobileUserVideo} >
                    <video className={classesMobile.MobileVideoSmallContent} ref={(el) => (videoElements.current[i+2] = el)}></video>
                    {/* {!isStudent &&
                      <Tooltip title="Topping this video" placement="top">
                        <Avatar style={{position:"absolute", top:0, right: 0, cursor:"pointer", width: "20px", height:"20px"}} onClick={()=> handleClickToppingStudent(i)} src={IconTopping}/>
                      </Tooltip>
                    } */}
                    <Box className={classesMobile.mobileVideoSmallUserInfo}> 
                      <Typography style={{color: "white", fontSize: "12px", display: "inline-block" }}>{getVideoTileUserName(indexMap[i+2])}</Typography>
                      { getIsUserMute(indexMap[i+2]) ? <MicOffIcon fontSize="small" className={classes.videoSmallUserMic}/> : <MicIcon fontSize="small" className={classes.videoSmallUserMic}/>}
                    </Box>
                  </Box>
                </Grid>
              ))}
              </Grid>
            </Box>
          </SwipeableViews>
        </Box>
        <Box style={{display:"flex", justifyContent:"center", position:"relative"}}>
          <SliderPagination dots={2} index={swipeIndex} onChangeIndex={handleChangeSwipeIndex} />
        </Box>
        {displaytransition &&
        <Box className={classesMobile.mobileTransitonText}>
          {transitionText}
        </Box> 
        }
        {raiseHandUser &&
        <Box className={classesMobile.mobileRaiseHandBox}>
          <Typography className={classesMobile.mobileRaiseHandName}>{`${raiseHandUser} raised hand `}</Typography>
          <img src={IconHandYellow} alt='raise_hand' style={{width: '18px', height: '18px', marginLeft: '10px'}}/>
        </Box>
        }

        <Box className={classesMobile.mobileControlBox}>
          <Box className={classesMobile.mobileButtonControl}>
            <IconButton onClick={()=>handleClickLocalMuteButton(!mute)}>
              {mute? <MicOffIcon style={{color:'white'}}/> : <MicIcon style={{color:'white'}}/>}
            </IconButton>
            <Box className={classesMobile.mobileButtonText}>{mute ? "Unmute" : "Mute"}</Box>
          </Box>

          <Box className={classesMobile.mobileButtonControl}>
            <IconButton onClick={()=>handleClickLocalVideoButton(videoOn)}>
              {videoOn? <VideocamIcon style={{color:'white'}}/> : <VideocamOffIcon style={{color:'white'}}/>}
            </IconButton>
            <Box className={classesMobile.mobileButtonText}>{videoOn ? "Close Video" : "Open Video"}</Box>
          </Box>

          {isStudent ?
          <Box className={classesMobile.mobileButtonControl}>
            <IconButton onClick={handleClickRaiseHand}>
              <HandIcon style={{color:'white'}}/>
            </IconButton>
            <Box className={classesMobile.mobileButtonText}>Raise Hand</Box>
          </Box>
          :
          <Box className={classesMobile.mobileButtonControl}>
            <IconButton onClick={()=>{setShowCloseMeetingDialog(true)}}>
              <CloseIcon color='error'/>
            </IconButton>
            <Box className={classesMobile.mobileButtonText}>Close Class</Box>
          </Box>
          }
          <Box className={classesMobile.mobileButtonControl}>
            <IconButton onClick={handleClickMobileChat}>
              <Badge badgeContent={unReadMessageCount} color="secondary" showZero={false}>
                <ChatIcon style={{color:'white'}}/>
              </Badge>
            </IconButton>
            <Box className={classesMobile.mobileButtonText}>Chat</Box>
          </Box>
          <Box className={classesMobile.mobileButtonControl}>
            <IconButton onClick={handleClickMobileMore}>
              <MoreHorizIcon style={{color:'white'}}/>
            </IconButton>
            <Box className={classesMobile.mobileButtonText}>More</Box>
          </Box>
          {/* 
          {!isStudent &&
            <Tooltip title="Close Class" placement="top">
              <Avatar className={classesMobile.buttonControl} onClick={()=>{setShowCloseMeetingDialog(true)}} src={IconCloseRed}/>
            </Tooltip>
          }
          <Tooltip title="set virtual background" placement="top">
            <Avatar className={classes.buttonControl} style={{position:"absolute", left:"20px"}} onClick={()=>setShowVirtualBGDialog({showDialog: !showVirtualBGDialog.showDialog, showLoading: showVirtualBGDialog.showLoading})} src={IconBackground}/>
          </Tooltip>
          */}
        </Box>

        <Menu
          id="more-menu"
          anchorEl={moreButtonAnchorEl}
          open={showMobileMore}
          onClose={handleCloseMobileMore}
        >
          <MenuItem onClick={handleClickMobileMaterials} >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '10px'}}>
              <MaterialsIcon /> Materials
            </Box>
          </MenuItem>
          <MenuItem onClick={handleClickMobileMembers} >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '10px'}}>
              <MembersIcon /> Members
            </Box>
          </MenuItem>
          <MenuItem onClick={handleClickMobileSettings}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '10px'}}>
              <SettingsIcon/> Settings
            </Box>
          </MenuItem>
        </Menu>

        {showMobileMaterials &&
        <Box className={classesMobile.mobileChatLayout}>
          <Box className={classesMobile.mobileChatTitle}>Materials
            <SvgComponent iconName='ico-close-grey' className={classesMobile.mobileChatClose} onClick={() => setShowMobileMaterials(false)} />
          </Box>
          <Box sx={{overflow: 'auto', flexGrow: 1, padding: '12px'}}>
            <MeetingMaterialList 
              meetingType={meetingType} 
              dataId={queryMaterialId} 
              isStudent={isStudent} 
              onShareFile={handleShareFile}/>
          </Box>
        </Box>
        }

        {showMobileChat && 
        <Box className={classesMobile.mobileChatLayout}>
          <Box className={classesMobile.mobileChatTitle}>Chat
            <SvgComponent iconName='ico-close-grey' className={classesMobile.mobileChatClose} onClick={() => setShowMobileChat(false)} />
          </Box>
          <Box sx={{overflow: 'auto', flexGrow: 1, padding: '12px'}}>
            <MeetingChatMessageList chatMessageList={chatMessageList}/>
          </Box>
          <Box sx={{height: '50px', width: '100%', display: 'flex', alignItems: 'center', background: '#f5f5f5'}}>
            {!isStudent &&
            <FilePickerButton 
              usage={UsageType.MEETING}
              onSendFailed={(message, isError) => {enqueueSnackbar(message, {variant:isError?'error':'info'})}} 
              closeChat={meetingConfigs.closeChat && isStudent} 
              onFileSelected={refreshSendFileStatus} 
              onFileUploaded={refreshSendFileStatus}/>
            }
            <MaterialTextField placeholder={isStudent && meetingConfigs.closeChat? "Chat is closed by teacher": "Type a chat message"} 
              sx={{flexGrow: 1, paddingLeft: '10px'}}
              variant="standard"      
              onKeyDown={handleKeyPress}
              value={chatContent}
              onChange={handleChatContentChange}
              disabled={isStudent && meetingConfigs.closeChat}/>
            <IconButton onClick={()=>{handleKeyPress()}}>
              <SendIcon color='inherit' />
            </IconButton>
          </Box>
        </Box>
        }

        {showMobileMembers &&
        <Box className={classesMobile.mobileChatLayout}>
          <Box className={classesMobile.mobileChatTitle}>Members
            <SvgComponent iconName='ico-close-grey' className={classesMobile.mobileChatClose} onClick={() => setShowMobileMembers(false)} />
          </Box>
          <Box sx={{overflow: 'auto', flexGrow: 1, padding: '12px'}}>
            <Typography variant="body1" className={classes.listNumberText}>{`${attendeeList?.length} ${attendeeList?.length > 1 ? "members":"member"}`}</Typography>
            {attendeeList.map((row, index) => (
              <Box key={index} className={classesMobile.mobileChatUserList}>
                <Avatar src={row.userAvatar} style={{width: "32px", height: "32px", marginRight: "10px"}}/>
                <Typography component="span" variant="body1" className={classesMobile.mobileUserListName}>{getDisplayUserName(row.userName, row.userId)}</Typography>
                <IconButton /* onClick={()=>{handleVideoSingleUser(row)}} disabled={isStudent} */ disabled>
                  {row.videoOff ? <VideocamOffIcon fontSize="small" style={{color: "#999999"}}/> : <VideocamIcon fontSize="small" style={{color: "#999999"}}/>}
                </IconButton>
                <IconButton /* onClick={()=>{handleMuteSingleUser(row)}} disabled={isStudent} */ disabled>
                  {row.mute ? <MicOffIcon fontSize="small" style={{color: "#999999"}}/> 
                    : row.speaking? <MicIcon fontSize="small" style={{color: "#0000aa"}}/> : <MicIcon fontSize="small" style={{color: "#999999"}}/>}
                </IconButton> 
              </Box>
            ))}  
          </Box>
        </Box>
        }

        {showMobileSettings && 
        <Box className={classesMobile.mobileSettingsLayout} >
          <Box className={classesMobile.mobileChatTitle} sx={{color:"white"}}>Settings
            <SvgComponent iconName='ico-close-grey' className={classesMobile.mobileChatClose} onClick={() => setShowMobileSettings(false)} />
          </Box>
          {!isStudent &&
          <MaterialTextField
            select
            value={selectLanguage}
            onChange={handleSelectLanguageChange}
            type="text"
            size="small"
            color="secondary"
            className={classesMobile.mobileDeviceSelect}
            InputProps={{
              className: classesMobile.mobileDeviceText
            }} >
              <MenuItem value={NO_LANGUAGE} >Live transcription Off </MenuItem>
              <MenuItem value="en-US" >English </MenuItem>
              <MenuItem value="zh-CN" >Chinese </MenuItem>
              <MenuItem value="fr-FR" >French </MenuItem>
              <MenuItem value="de-DE" >German </MenuItem>
          </MaterialTextField>
          }

          {transitionOn &&
          <Box className={classes.switchBoxItem} style={{marginTop: "10px", marginLeft: "10px"}}>
            <Typography component="span" variant="body1" className={classes.switchText}>Display Subtitles</Typography>
            <Switch
              checked={displaytransition}
              onChange={handleDisplayTransitionChange}
              color="secondary"
              name="displaySubtitles"
            />
          </Box>              
          }

          {isStudent ? "" :
          <Box style={{marginLeft: "10px"}}>
            {/* <Box className={classes.switchBoxItem}>
              <Typography component="span" variant="body1" className={classes.switchText}>Record Video</Typography>
              <Switch
                checked={recordVideo}
                onChange={handleRecordVideoChange}
                color="secondary"
                name="recordVideo"
              />
            </Box> */}
            <Box className={classes.switchBoxItem}>
              <Typography component="span" variant="body1" className={classes.switchText}>Mute All</Typography>
              <Switch
                checked={meetingConfigs.muteAll}
                onChange={handleMuteAll}
                color="secondary"
                name="muteAll"
              />
            </Box>

            <Box className={classes.switchBoxItem}>
              <Typography component="span" variant="body1" className={classes.switchText}>Close All Videos</Typography>
              <Switch
                checked={meetingConfigs.closeAllVideo}
                onChange={handleCloseAllVideo}
                color="secondary"
                name="closeAllVideos"
              />
            </Box>

            <Box className={classes.switchBoxItem}>
              <Typography component="span" variant="body1" className={classes.switchText}>Close Chat</Typography>
              <Switch
                checked={meetingConfigs.closeChat}
                onChange={handleCloseChat}
                color="secondary"
                name="closeChat"
              />
            </Box> 
            
            <Box className={classes.switchBoxItem}>
              <Typography component="span" variant="body1" className={classes.switchText}>Forbidden Screen Sharing</Typography>
              <Switch
                checked={meetingConfigs.forbidShareContent}
                onChange={handleForbidShareScreen}
                color="secondary"
                name="forbidShareScreen"
              />
            </Box> 

          </Box>  
          }
          <Box className={classesMobile.setVirtualBGBox} >
            <Box className={classesMobile.setVirtualBGBoxTitle}>
              Set Virtual Background
              {showVirtualBGDialog.showLoading &&
                <CircularProgress sx={{marginLeft:"10px"}}/>
              }
            </Box>
            <VirtialBackgroundPicker currentSelectIndex={virtualBGConfig.index} onSelectChange={(index, imageUrl) => handleSelectVirturlBackground(index, imageUrl, selectAudioVideoDevice.videoInput)}/>
          </Box>
        </Box>
        }
      </Box> // mobile layout end
      :
      <Box className={classes.App}>
        <Box className={classes.videoConatiner}>
          <Box className={classes.videoBig} style={shareScreenConfig.fullscreen? {position: "static"}: {}}>
            <TimeCountBox joinTimestamp={joinTimestamp}/>
            {shareScreenConfig.sharing && <Box className={classes.shareInfoBox}>{`${getShareScreenUserName()} is sharing screen`}</Box>}
            <video 
              hidden={!shareScreenConfig.sharing && !toppingConfig.topping} 
              className={toppingConfig.topping && toppingConfig.toppingUserId === credential?.id  ? clsx(getToppingOrShareClass(), classes.rotateY):  clsx(getToppingOrShareClass(), classes.rotateNone)} 
              ref={(el) => (videoElements.current[0] = el)}>
            </video>
            <video 
              hidden={(!videoOn && !isStudent) || (isStudent && isTeacherVideoOff()) } 
              className={isStudent ? clsx(getTeacherVideoStyle(), classes.rotateNone):  clsx(getTeacherVideoStyle(), classes.rotateY)} 
              ref={(el) => (videoElements.current[1] = el)}>
            </video>
            {!isStudent && toppingConfig.topping && 
            <Tooltip title="Cancel topping student" placement="top">
              <Avatar style={{position:"absolute", top:2, right: 2, cursor:"pointer",  width: "25px", height:"25px", zIndex: 101}} onClick={()=> cancelToppingStudent()} src={IconTopping}/>
            </Tooltip>
            }
            {raiseHandUser &&
              <Box className={classes.raiseHandBox}>
                <Typography className={classes.raiseHandName}>{`${raiseHandUser} raised hand `}</Typography>
                <img src={IconHandYellow} alt='raise_hand' style={{width: '35px', height: '36px', marginLeft: '10px'}}/>
              </Box>
            }
            <Box hidden={!shareScreenConfig.sharing || (isStudent && shareScreenConfig.allowShareUserId !== currentUser?.id)} className={shareScreenConfig.fullscreen? classes.closeFullscreenShareButton : classes.closeShareButton} sx={{textAlign: "center"}}>
              <Button onClick={()=>{setShowStopShareDialog(true)}} color='secondary' variant='contained' size='small'
                sx={{zIndex: 101, textTransform: "none", borderRadius: "2px 2px 5px 5px", }}>
                Stop Sharing
              </Button>
            </Box>            
            <Box hidden={(!IsTeacherJoinIn() || isTeacherVideoOff())&& !shareScreenConfig.sharing && !toppingConfig.topping}>
              {shareScreenConfig.fullscreen ? 
              <Tooltip title="Cancel fullscreen" placement="top">
                <Button onClick={()=>{setShareScreenConfig(config=>{return{sharing: config.sharing, fullscreen: false, allowShareUserId: config.allowShareUserId, shareContentAttendeeId: config.shareContentAttendeeId}})}}  className={classes.cancelFullscreenButton} style={{position: "fixed"}}>
                  <FullscreenExitRoundedIcon fontSize='large' className={classes.fullscreenControlButton} />
                </Button>
              </Tooltip>
              :
              <Tooltip title="Fullscreen" placement="top">   
                <Button onClick={()=>{setShareScreenConfig(config=>{return{sharing: config.sharing, fullscreen: true, allowShareUserId: config.allowShareUserId, shareContentAttendeeId: config.shareContentAttendeeId}})}}  className={classes.fullscreenButton} style={{position: "absolute"}}>
                  <FullscreenRoundedIcon fontSize='large' className={classes.fullscreenControlButton} />
                </Button>
              </Tooltip> 
              }
            </Box>
            {displaytransition &&
            <Box className={shareScreenConfig.fullscreen? classes.fullscreenTransitonText : classes.transitonText}>
              {transitionText}
            </Box> 
            }
          </Box>
          <Box className={classes.videoListBox}>
            {[...Array(23)].map((val, i) => (
              <Box key={i} className={classes.videoSmall} hidden={shouldHideVideoTile(i)}>
                <video 
                  className={classes.videoSmallContent} 
                  style={getVideoTileUserId(indexMap[i+2]) === credential?.id ? {transform: "scale(-1,1) !important"}:{transform:"none"}}
                  ref={(el) => (videoElements.current[i+2] = el)} 
                  />
                {!isStudent &&
                  <Tooltip title="Topping this video" placement="top">
                    <Avatar style={{position:"absolute", top:0, right: 0, cursor:"pointer", width: "20px", height:"20px"}} onClick={()=> handleClickToppingStudent(i)} src={IconTopping}/>
                  </Tooltip>
                }
                <Box className={classes.videoSmallUserInfo}> 
                  <Typography style={{color: "white", fontSize: "12px", display: "inline-block" }}>{getVideoTileUserName(indexMap[i+2])}</Typography>
                  { getIsUserMute(indexMap[i+2]) ? <MicOffIcon fontSize="small" className={classes.videoSmallUserMic}/> : <MicIcon fontSize="small" className={classes.videoSmallUserMic}/>}
                </Box>
              </Box>
            ))}
          </Box>
          <Box className={classes.classroomControl} >
            <Tooltip title="set virtual background" placement="top">
              <Avatar className={classes.buttonControl} style={{position:"absolute", left:"20px"}} onClick={()=>setShowVirtualBGDialog({showDialog: !showVirtualBGDialog.showDialog, showLoading: showVirtualBGDialog.showLoading})} src={IconBackground}/>
            </Tooltip>
            {showVirtualBGDialog.showLoading &&
              <CircularProgress className={classes.virtulBGLoading}/>
            }
            {/* <Tooltip title={viewMode === 0 ? "grid view" : "speech view"} placement="top" >
              <Avatar className={classes.buttonControl} style={{position:"absolute", left:"80px"}} onClick={()=>setViewMode(1-viewMode)} src={viewMode === 0 ? IconGridView : IconSpeechView}/>
            </Tooltip> */}
            <Tooltip title={mute ? "Unmute" : "Mute"} placement="top">
              <Avatar className={classes.buttonControl} onClick={()=>handleClickLocalMuteButton(!mute)} src={mute ? IconMute : IconVoice}/>
            </Tooltip>
            <Tooltip title={videoOn ? "Close Video" : "Open Video"} placement="top">
              <Avatar className={classes.buttonControl} onClick={()=>handleClickLocalVideoButton(videoOn)} src={videoOn ? IconVideoOn : IconVideoOff}/>
            </Tooltip>
            {isStudent &&
              <Tooltip title="Raise Hand" placement="top">
                <Avatar className={classes.buttonControl} onClick={handleClickRaiseHand} src={IconHand}/>
              </Tooltip>
            }
            {isStudent && shareScreenConfig.allowShareUserId !== currentUser?.id ? 
              <Tooltip title="Request Share" placement="top">
                <Avatar className={classes.buttonControl} onClick={()=>{handleClickRequestShareIcon()}} src={IconScreenClose}/>
              </Tooltip> :
              <Tooltip title="Share Screen" placement="top">
                <Avatar className={classes.buttonControl} onClick={()=>{handleClickShareScreenIcon()}} src={IconScreen}/>
              </Tooltip>
            }
            <Tooltip title="Leave Class" placement="top">
              <Avatar className={classes.buttonControl} onClick={()=>{setShowExitDialog(true)}} src={IconQuit}/>
            </Tooltip>
            {!isStudent &&
              <Tooltip title="Close Class" placement="top">
                <Avatar className={classes.buttonControl} onClick={()=>{setShowCloseMeetingDialog(true)}} src={IconCloseRed}/>
              </Tooltip>
            }
          </Box>
          
        </Box>

        <Box className={classes.boxRight}>
          <Tabs
            value={tabPosition}
            onChange={handleSwitchTab}
            indicatorColor="secondary"
            className={classes.tabBox}
          >
            <Tab label="Classroom" style={{textTransform:"none", color: "#fff", flexGrow: 1}}/>
            <Tab label="Materials" style={{textTransform:"none", color: "#fff", flexGrow: 1}}/>
            <Tab label="Chat" style={{textTransform:"none", color: "#fff", flexGrow: 1}}/>
            <Box className={classes.unReadMessage} hidden={tabPosition === TabPosition.Chat || unReadMessageCount === 0}>
              {unReadMessageCount > 9 ? "..." : unReadMessageCount}
            </Box>
          </Tabs>

          <Box className={classes.settingsBox} hidden={tabPosition !== TabPosition.Control}>
            <MaterialTextField
              select
              value={selectAudioVideoDevice.audioInput}
              onChange={handleAudioInputDeviceChange}
              type="text"
              size="small"
              color="secondary"
              className={classes.deviceSelect}
              InputProps={{
                className: classes.deviceText,
              }}>
                {audioInputList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.deviceId} >{item.label} </MenuItem>
                  );
                })}  
            </MaterialTextField>

            {/* <MaterialTextField
              select
              value={selectAudioVideoDevice.audioOutput}
              onChange={handleAudioOutputDeviceChange}
              type="text"
              size="small"
              color="secondary"
              className={classes.deviceSelect}
              InputProps={{
                className: classes.deviceText
              }} >
                {audioOutputList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.deviceId} >{item.label} </MenuItem>
                  );
                })}  
            </MaterialTextField> */}

            <MaterialTextField
              select
              value={selectAudioVideoDevice.videoInput}
              onChange={handleVideoInputDeviceChange}
              type="text"
              size="small"
              color="secondary"
              className={classes.deviceSelect}
              InputProps={{
                className: classes.deviceText
              }} >
                {videoInputList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.deviceId} >{item.label} </MenuItem>
                  );
                })}  
            </MaterialTextField>

            {!isStudent &&
            <MaterialTextField
              select
              value={selectLanguage}
              onChange={handleSelectLanguageChange}
              type="text"
              size="small"
              color="secondary"
              className={classes.deviceSelect}
              InputProps={{
                className: classes.deviceText
              }} >
                <MenuItem value={NO_LANGUAGE} >Live transcription Off </MenuItem>
                <MenuItem value="en-US" >English </MenuItem>
                <MenuItem value="zh-CN" >Chinese </MenuItem>
                <MenuItem value="fr-FR" >French </MenuItem>
                <MenuItem value="de-DE" >German </MenuItem>
            </MaterialTextField>
            }

            {transitionOn &&
            <Box className={classes.switchBoxItem} style={{marginTop: "10px", marginLeft: "10px"}}>
              <Typography component="span" variant="body1" className={classes.switchText}>Display Subtitles</Typography>
              <Switch
                checked={displaytransition}
                onChange={handleDisplayTransitionChange}
                color="secondary"
                name="displaySubtitles"
              />
            </Box>              
            }

            {isStudent ? "" :
            <Box style={{marginTop: "10px", marginLeft: "10px"}}>
              {/* <Box className={classes.switchBoxItem}>
                <Typography component="span" variant="body1" className={classes.switchText}>Record Video</Typography>
                <Switch
                  checked={recordVideo}
                  onChange={handleRecordVideoChange}
                  color="secondary"
                  name="recordVideo"
                />
              </Box> */}
              <Box className={classes.switchBoxItem}>
                <Typography component="span" variant="body1" className={classes.switchText}>Mute All</Typography>
                <Switch
                  checked={meetingConfigs.muteAll}
                  onChange={handleMuteAll}
                  color="secondary"
                  name="muteAll"
                />
              </Box>

              <Box className={classes.switchBoxItem}>
                <Typography component="span" variant="body1" className={classes.switchText}>Close All Videos</Typography>
                <Switch
                  checked={meetingConfigs.closeAllVideo}
                  onChange={handleCloseAllVideo}
                  color="secondary"
                  name="closeAllVideos"
                />
              </Box>

              <Box className={classes.switchBoxItem}>
                <Typography component="span" variant="body1" className={classes.switchText}>Close Chat</Typography>
                <Switch
                  checked={meetingConfigs.closeChat}
                  onChange={handleCloseChat}
                  color="secondary"
                  name="closeChat"
                />
              </Box> 
              
              <Box className={classes.switchBoxItem}>
                <Typography component="span" variant="body1" className={classes.switchText}>Forbidden Screen Sharing</Typography>
                <Switch
                  checked={meetingConfigs.forbidShareContent}
                  onChange={handleForbidShareScreen}
                  color="secondary"
                  name="forbidShareScreen"
                />
              </Box> 

            </Box>  
            }

            <Divider style={{margin: "20px 10px 20px 0", background: "grey", height: "0.5px"}}/>
            <Typography variant="body1" className={classes.listNumberText}>{`${attendeeList?.length} ${attendeeList?.length > 1 ? "members":"member"} in total`}</Typography>
            <Box className={classes.attendeeListBox}>
              {attendeeList.map((row, index) => (
                <Box key={index} className={classes.switchBoxItem}>
                  <Avatar src={row.userAvatar} style={{width: "20px", height: "20px", marginRight: "10px"}}/>
                  <Typography component="span" variant="body1" className={classes.listNameText}>{getDisplayUserName(row.userName, row.userId)}</Typography>
                  <IconButton /* onClick={()=>{handleVideoSingleUser(row)}} disabled={isStudent} */ disabled>
                    {row.videoOff ? <VideocamOffIcon fontSize="small" style={{color: "#fff"}}/> : <VideocamIcon fontSize="small" style={{color: "#fff"}}/>}
                  </IconButton>
                  <IconButton /* onClick={()=>{handleMuteSingleUser(row)}} disabled={isStudent} */ disabled>
                    {row.mute ? <MicOffIcon fontSize="small" style={{color: "#fff"}}/> 
                      : row.speaking? <MicIcon fontSize="small" style={{color: "#0000aa"}}/> : <MicIcon fontSize="small" style={{color: "#fff"}}/>}
                  </IconButton> 
                </Box>
              ))}       
            </Box>
          </Box>
          
          <Box className={classes.settingsBox} hidden={tabPosition !== TabPosition.Materials}>
            <MeetingMaterialList 
              meetingType={meetingType} 
              dataId={queryMaterialId} 
              isStudent={isStudent} 
              onShareFile={handleShareFile} />
          </Box>
          
          <Box className={classes.chatListBox} hidden={tabPosition !== TabPosition.Chat}>
            <MeetingChatMessageList chatMessageList={chatMessageList}/>
            <div ref={messagesEndRef} />
          </Box>

          <Box className={classes.chatBox}>

            <MaterialTextField placeholder={isStudent && meetingConfigs.closeChat? "Chat is closed by teacher": "Type a chat message"} 
              className={classes.chatTextField}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}        
              onKeyDown={handleKeyPress}
              value={chatContent}
              onChange={handleChatContentChange}
              disabled={isStudent && meetingConfigs.closeChat}/>
            {!isStudent &&
            <FilePickerButton 
              usage={UsageType.MEETING}
              onSendFailed={(message, isError) => {enqueueSnackbar(message, {variant:isError?'error':'info'})}} 
              closeChat={meetingConfigs.closeChat && isStudent} 
              onFileSelected={refreshSendFileStatus} 
              onFileUploaded={refreshSendFileStatus}/>
            }
            <Avatar className={classes.buttonControl} sx={isStudent ? {}:{marginLeft:"0px"}} onClick={()=>{handleKeyPress()}} src={IconSend}/>
          </Box>
        </Box>
      </Box>
      }
      
      {showVirtualBGDialog.showDialog &&
        <VirtialBackgroundPicker currentSelectIndex={virtualBGConfig.index} onSelectChange={(index, imageUrl) => handleSelectVirturlBackground(index, imageUrl, selectAudioVideoDevice.videoInput)}/>
      }
      <Dialog
        maxWidth='lg'
        open={previewConfig.showPreviewDialog}
        PaperProps={{style: { backgroundColor: '#252525', boxShadow: 'none'}}}
        BackdropProps={{style: {backgroundColor: '#000000'}}}
      >
        <DialogContent>
          <Box className={classes.previewBox}>
            <Avatar src={TeacherAvatarDefault} className={classes.previewAvatar} variant='circular'/>
            <video
              id='preview-video'
              className={classes.previewVideo} 
              ref={(el) => (videoElements.current[19] = el)}>
            </video>
            <Box className={classes.previewText}>preview</Box>
          </Box>

          {recordVideo && !meetingControls.recordStart && !isStudent && 
          <Box className={classes.recordVideoBox}>
            <Box className={classes.recordVideoText}>Record Video</Box>
            <MaterialTextField
              select
              value={recordVideoType}
              onChange={(e) => setRecordVideoType(e.target.value)}
              type="text"
              size="small"
              className={classes.recordVideoSelect}
              InputProps={{
                className: classes.recordVideoInput
              }} >
                <MenuItem value="teacher" > Teacher Screen </MenuItem>
                <MenuItem value="all" disabled={meetingType === MeetingTypeEnum.LiveVideoClass}> All Screens </MenuItem>  
            </MaterialTextField> 
          </Box>
          }

          <Box className={classes.bottomButtonBox}>
            <Box className={classes.previewButtonBox}>
              <Tooltip title={mute ? "Unmute" : "Mute"} placement="top">
                <Avatar className={classes.buttonControl} onClick={()=>setPreviewConfig(config => ({...config, previewMute: !config.previewMute}))} src={previewConfig.previewMute ? IconMute : IconVoice}/>
              </Tooltip>
              <Tooltip title={videoOn ? "Close Preview" : "Open Preview"} placement="top">
                <Avatar className={classes.buttonControl} onClick={handleClickPreviewVideoButton} src={previewConfig.previewVideoOff ? IconVideoOff : IconVideoOn}/>
              </Tooltip>
              {/* <MaterialTextField
                select
                value={selectAudioVideoDevice.videoInput}
                onChange={handlePreviewVideoInputDeviceChange}
                type="text"
                size="small"
                color="secondary"
                className={classes.deviceSelect}
                InputProps={{
                  className: classes.deviceText
                }} >
                  {videoInputList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.deviceId} >{item.label} </MenuItem>
                    );
                  })}  
              </MaterialTextField> */}
              <Tooltip title="Leave Class" placement="top">
                <Avatar className={classes.buttonControl} onClick={()=>{setShowExitDialog(true)}} src={IconQuit}/>
              </Tooltip>
            </Box>
            <Button 
              variant='contained' 
              color='secondary' 
              onClick={handleEnterMeeting}
              style={{background: '#307DCF'}}
              >Enter
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={showExitDialog}
        onClose={()=>{setShowExitDialog(false)}}
      >
        <DialogTitle id="alert-dialog-title">{"Leave this class?"}</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setShowExitDialog(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={exitMeeting} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={showCloseMeetingDialog}
        onClose={()=>{setShowCloseMeetingDialog(false)}}
      >
        <DialogTitle>{"Close this class?"}</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setShowCloseMeetingDialog(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseMeeting} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        fullWidth={true}
        open={showStopShareDialog}
        onClose={()=>{setShowStopShareDialog(false)}}
      >
        <DialogTitle id="share-dialog-title">{"Stop current sharing?"}</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setShowStopShareDialog(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={stopAndDisableShare} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        fullWidth={true}
        open={showOpenMicDialog}
        onClose={()=>{setShowOpenMicDialog(false)}}
      >
        <DialogTitle id="share-dialog-title">The teacher wants you open the mic</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setShowOpenMicDialog(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{handleMuteAudioInput(false);setShowOpenMicDialog(false);}} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        fullWidth={true}
        open={showOpenVideoDialog}
        onClose={()=>{setShowOpenVideoDialog(false)}}
      >
        <DialogTitle id="share-dialog-title">The teacher wants you open the video</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setShowOpenVideoDialog(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{handleCloseLocalVideo(false);setShowOpenVideoDialog(false);}} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
              
      <Dialog
        fullWidth={true}
        open={showRequestShareDialog}
        onClose={()=>{setShowRequestShareDialog(false)}}
      >
        <DialogTitle>{ `${getDisplayUserName(requestShareUser.userName, requestShareUser.userId)} is applying for screen sharing`}</DialogTitle>
        {toppingConfig.topping && 
        <DialogContent> Current topping user video will be stopped if share started.</DialogContent>
        }
        <DialogActions>
          <Button onClick={()=>{handleDisagreeStudentShareScreen()}} color="primary">
            Disagree
          </Button>
          <Button onClick={()=>{handleAgreeStudentShareScreen()}} color="secondary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        fullWidth={true}
        open={alertDialogData.showAlertDialog}  
      >
        <DialogTitle> {alertDialogData.alertDialogTitle} </DialogTitle>
        <DialogActions>
          <Button onClick={()=> handleClickConfirmAlertDialog()} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={shareFileDialog.openDialog}
        PaperComponent={DraggablePaper}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{shareFileDialog.title}</DialogTitle>
        <DialogContent style={{fontSize:'16px', minWidth: '40vw', textAlign: 'center'}}>
          {isStudent && shareFileDialog.showFile && shareFileDialog.fileUrl && shareFileDialog.fileType.startsWith("image") &&
          <img alt='preview-pic' style={{maxHeight: "500px", maxWidth: "100%", width:"auto", height: "auto", objectFit:"contain"}} src={shareFileDialog.fileUrl}/>
          }
          {isStudent && shareFileDialog.showFile && shareFileDialog.fileUrl && shareFileDialog.fileType.startsWith("video") &&
          <ReactPlayer
            url={shareFileDialog.fileUrl}
            playing={true}
            muted={false} 
            width={"100%"}
            height={500}
            controls
            // Disable download button
            config={{ file: { attributes: { controlsList: 'nodownload' }}}}
            // Disable right click
            onContextMenu={e => e.preventDefault()}
            playsinline={true}
          />
          }
          {isStudent && shareFileDialog.showFile && shareFileDialog.fileUrl && shareFileDialog.fileType === "application/pdf" &&
          <Box sx={{textAlign: 'center'}}>
            <Document
              // className={classes.pdfShareDocument}
              file={shareFileDialog.fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page 
                // className={classes.pdfSharePage} 
                pageNumber={pageNumber} />
            </Document>
            <Box className="flex space-x-4">
              <Box color='black' textAlign='center' sx={{margin: '10px 0'}}>
                {pageNumber} / {numPages}
              </Box>

              {numPages > 1 && (
              <Box justifyContent='center' display='flex'> 
                <Button
                  disabled={pageNumber === 1}
                  size='small'
                  variant='contained'
                  color='primary'
                  // className={classes.pageButton}
                  sx={{marginRight: '20px'}}
                  onClick={() => {
                    setPageNumber(pageNumber - 1)
                  }}
                >
                  Prev
                </Button>
                <Button
                  disabled={pageNumber === numPages}
                  size='small'
                  variant='contained'
                  color='primary'
                  // className={classes.pageButton}
                  onClick={() => {
                    setPageNumber(pageNumber + 1)
                  }}
                >
                  Next
                </Button>
              </Box>
              )}
            </Box>
          </Box>  
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShareFileDialog({title:"", openDialog: false, showFile: false, fileUrl: '', fileType: ''})} style={{color:'black', textTransform:'none'}}>Close</Button>
          {!shareFileDialog.showFile &&
          <Button color='primary' onClick={showSharedFile} style={{textTransform:'none'}}>Open</Button>
          }
        </DialogActions>
      </Dialog>

      <Loader isLoading={isLoading} />
    </>
  )
}

export default MeetingScreen;