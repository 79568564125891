import Slider from 'react-slick'

import { ArrowForwardIos } from '@mui/icons-material'
import CategoryTag from './CategoryTag'

import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  category_list__wrapper: {
    padding: '8px 40px',
    background: 'white',
    boxShadow: '0 3px 5px #f9f9f926',

    '& .slick-arrow': {
      zIndex: '1',
      '&:before': {
        fontSize: '60px',
        color: 'black',
        content: "''"
      }
    },

    '& .slick-list': {
      margin: '0 0 0 calc(-2 * 4px)'
    }
  },
  slider: {},
  slider__item: {
    // border: '1px solid black',
  }
}))

export const CategorySlider = ({ categories }) => {
  const styles = useStyles()

  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '20px',
          height: '20px',
          // background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClick}
      >
        <ArrowForwardIos style={{ color: 'black', width: '70%', height: '70%' }} />
      </div>
    )
  }
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '20px',
          height: '20px',
          // background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClick}
      >
        <ArrowForwardIos
          style={{
            color: 'black',
            width: '70%',
            height: '70%',
            transform: 'rotate(180deg)'
          }}
        />
      </div>
    )
  }

  const settings = {
    // dots: true,
    infinite: false,
    // swipeToSlide: true,
    speed: 500,
    // slidesToShow: 4,
    slidesToScroll: 4,
    swipe: false,
    initialSlide: 0,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <div className={clsx([styles.category_list__wrapper])}>
      <Slider {...settings} className={clsx([styles.slider])}>
        {categories &&
          categories.map((category, i) => (
            <div>
              <CategoryTag key={i} category={category} className={clsx([styles.slider__item])} />
            </div>
          ))}
      </Slider>
    </div>
  )
}
