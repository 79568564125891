
import React, { useCallback, useEffect, useState } from 'react';
import { Theme, Box, Typography, Avatar, Divider, Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle, Checkbox, IconButton,  Menu, MenuItem,} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loader } from 'src/components/loader'
import { useCreatePrivateLessonClassMutation, useDeleteTeacherAvailableTimeMutation, } from 'src/app/service/real/privatelesson-slice'
import { TeacherPrivateLesson, BookStatusEnum, PrivateLessonTypeEnum, BookUserNote, ScheduleEnum, UserScheduleEntity, ScheduleLessonTypeEnum, getScheduleTypeText, LessonClassesStatusEnum, PrivateLessonClassSourceEntity, UserScheduleSourceTypeEnum, PrivateLessonClassesTypeEnum, UserInfo } from 'src/app/models/schedule'
import { useSelector } from 'src/app/toolkit/store'
import { useSnackbar } from 'notistack';
import { ListPagination } from 'src/components/pagination';
import { getDateInWordDescription, getTimeAreaDesc } from 'src/utils/timeUtil';
import SvgComponent from 'src/components/svgComponent';
import { genRandom, getDisplayUserName } from 'src/utils/stringUtil';
import { getBookTimeStatusDesc, StudentBookLayout } from 'src/components/calendar/BookLayout';
import DeleteIcon from '@mui/icons-material/Delete';
import IconPaid from 'src/images/class/bg-label-paid.png'
import IconUnPaid from 'src/images/class/bg-label-unpaid.png'
import IconAvailable from 'src/images/class/bg-label-availeble.png'
import { useNavigate, useParams } from 'react-router-dom';
import { appConfig, buildTeacherScheduleListUrl } from 'src/app-config';
import { useGetCheckoutSessionMutation } from 'src/app/service/real/payment.slice';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import { useDispatch } from 'react-redux';
import { ErrorCode } from 'src/utils/constants';
import { useTranslation } from 'react-i18next';
import NP from "number-precision";
import { storeSession } from 'src/utils/localStorage';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutOneTimeItem, PaymentMode, PaymentType, PaymentProductInfo, PaidInfo, Product_Type, PaymentCurrency } from '@dokyo/common'
import { MembershipConfigInfo } from 'src/app/models';
import CloseIcon from '@mui/icons-material/Close';
import { useGetCouponListMutation } from "src/app/service/real/coupon-slice";
import { useGetLessonScheduleByRoleMutation } from 'src/app/service/real/serieslesson-slice';
import { useCreateBookingMutation } from 'src/app/service/real/booking-slice';
import { getAuthRole } from 'src/utils/auth';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import TeacherLiveVideoClassList from './TeacherLiveVideoClassList';
import { useSearchParam } from 'react-use';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  pageTitle: {
    marginTop: "38px !important",
    fontSize: "24px !important",
    fontFamily: "Arial-BoldMT, Arial !important"
  },
  privateLessonBox: {
    display: "flex",
    marginTop: '38px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  privateLessonImage: {
    width: "238px !important",
    height: "160px !important",
    borderRadius: "10px !important",
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
      height: "160px !important",
    }
  },
  privateLessonInfoBox: {
    flexGrow: 1,
    paddingLeft: "16px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "5px",
      paddingTop: "10px",
    }
  },
  categoryText: {
    fontSize: "20px !important",
    fontFamily: "ArialMT !important",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineClamp: 1,
    WebkitLineClamp: 1,
  },
  feeBox: {
    marginTop: "10px",
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  minutesText: {
    fontSize: "14px !important",
    fontFamily: "ArialMT !important",
    color: "#555555",
    marginRight: 10,
    lineHeight: '24px !important',
  },
  priceText: {
    fontSize: "14px !important",
    fontWeight: "bold !important",
    fontFamily: "ArialMT !important"
  },
  introduction: {
    marginTop: "10px !important",
    textAlign: "left",
    fontSize: "14px !important",
    fontFamily: 'ArialMT !important',
    color: '#000000 !important',
    lineHeight: '24px !important',
  },
  listBox: {
    marginTop: '30px',
  },
  listTitleItemBox: {
    marginBottom: "35px !important",
  },
  timeTitle: {
    textAlign: "left",
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "20px !important",
    lineHeight: "23px !important",
    marginBottom: "15px !important",
  },
  listItemBox: {
    width: "100%",
    borderRadius: "12px",
    border: "1px solid #E9E9E9",
    height: 'fit-content',
    display: "flex",
    flexDirection: "row",
    padding: "16px 0 16px 16px",
    position: "relative"
  },
  listItemMidBox: {
    marginLeft: "14px",
  },
  listItemTime: {
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "16px !important",
    color: "#307DCF !important",
    textAlign: "left",
  },
  listItemMessage: {
    marginTop: "13px !important",
    fontFamily: "ArialMT !important",
    fontSize: "14px !important",
    height: "16px !important",
    textAlign: "left",
    color: "black",
  },
  listItemNote: {
    marginTop: "13px !important",
    fontFamily: "ArialMT !important",
    fontSize: "14px !important",
    color: "#555555 !important",
    height: "16px !important",
    textAlign: "left",
  },
  buttonBox: {
    marginTop: "25px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  },
  bookStatusText: {
    fontSize: '10px',
    fontFamily: 'Arial-BoldMT, Arial',
    color: 'white',
    width: '75px',
    height: '26px',
    textAlign: 'center',
    paddingTop: '6px',
    paddingLeft: '10px',
    position: 'absolute',
    right: '0px',
  },
  viewDetailsBtn: {
    minWidth: "72px !important",
    height: "36px !important",
    background: "#307DCF !important",
    color: 'white !important',
    fontFamily: 'ArialMT !important',
    fontSize: '12px !important',
    fontWeight: 'bold !important',
    boxShadow: 'none !important',
    borderRadius: '18px !important',
  },
  cancelBookBtn: {
    height: "36px !important",
    marginLeft: '20px !important',
    color: '#307DCF !important',
    fontFamily: 'ArialMT !important',
    fontWeight: 'bold !important',
    boxShadow: 'none !important',
    borderRadius: '18px !important',
  },
  queryTypeButton: {
    alignSelf: 'end',
    width: '250px !important',
    height: '48px !important',
    borderRadius: '8px !important',
    border: '1px solid #7C849C !important',
    color: 'black !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',  
  },
  menu: {
    '& .MuiPopover-paper': {
      maxWidth: '250px !important',
    }
  },
  selectedMenu: {
    color: '#307DCF !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important', 
    height: '50px !important',
    width: '250px !important',
  },
  unSelectedMenu: {
    color: '#555555 !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',
    height: '50px !important', 
    width: '250px !important', 
  },
}))

interface FormattedScheduleEntity {
  id: string,
  booking_id?: string | undefined,
  classroom_id?: string | undefined,
  create_time: number | undefined,
  start_time: number,
  end_time: number,
  fee?: number | undefined,
  title: string,
  booking_list?: BookUserNote[] | undefined,
  status: BookStatusEnum,
  time?: number | undefined,
  // teacher: {
  // 	id: string,
  // 	name: string,
  //  avatar: string,
  // },
  // meeting_info: {
  // 	chime_meeting_id: string,
  // }
  repeat_id?: string | undefined,
  schedule_id?: string | undefined,
  schedule_type: ScheduleLessonTypeEnum,
  groupLessonFee?: number,
  bookPaidNum?: number,
  bookUnpaidNum?: number,
  secondTeachers: UserInfo[],
}

interface TimeScheduleEntity {
  time: number;
  list: FormattedScheduleEntity[]
}

interface Props {
  teacherId: string;
  teacherName: string;
  privateLessonData: TeacherPrivateLesson|undefined;
}

const TeacherScheduleList: React.FC<Props> = ({ teacherId, teacherName, privateLessonData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()
  const { credential } = useSelector(state => state.app);
  const authRole = getAuthRole()();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startKeyArray, setStartKeyArray] = useState(["", ""]);
  const [maxPage, setMaxPage] = useState(1)
  const PAGE_SIZE = 20;
  const [dataList, setDataList] = useState<TimeScheduleEntity[]>([]);

  const [showBookDialog, setShowBookDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteRepeat, setDeleteRepeat] = useState(false);
  const [deletingItem, setDeletingItem] = useState<FormattedScheduleEntity>();
  const [bookingItem, setBookingItem] = useState<FormattedScheduleEntity>();

  const listType = useSearchParam("listType");
  const [queryType, setQueryType] = useState(listType ? parseInt(listType) : 1); // 1-Private lesson and series lesson; 2-live stream and live video class
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const showQueryMenu = Boolean(moreMenuAnchorEl);


  const [getTeacherScheduleList, { isLoading: loading0 }] = useGetLessonScheduleByRoleMutation();
  // const [getTeacherPrivateLessonList, { isLoading: loading2 }] = useGetTeacherScheduleListByTimeSortMutation();
  const [deleteAvailableTime, { isLoading: loading3 }] = useDeleteTeacherAvailableTimeMutation();
  const [createPrivateLessonClass, { isLoading: loading4 }] = useCreatePrivateLessonClassMutation();
  const [createBooking, { isLoading: loading7 }] = useCreateBookingMutation();
  const [getcheckoutsession, { isLoading: loading5 }] = useGetCheckoutSessionMutation();
  const [couponList, setcouponList] = useState<any>([])//coupon列表
  const [currentCouponList, setcurrentCouponList] = useState<any>([])//当前可使用的coupon
  const [isCoupon, setisCoupon] = useState<boolean>(true)
  const [currentCoupon, setcurrentCoupon] = useState<string>('')//当前的coupon
  const [getCouponList] = useGetCouponListMutation();
  const [configMembership, setConfigMembership] = useState<MembershipConfigInfo>({ type: "", stripe_publishable_key: "", list: [] });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getMembershipConfig = async ({ url }): Promise<MembershipConfigInfo> => {
    return new Promise((resolve) => {
      fetch(url, {
        cache: "no-cache"
      })
        .then(response => resolve(response.json()))
        .catch(error => {
          console.error(error);
        });
    });
  }

  React.useEffect(() => {
    if (credential) {
      getCouponCodeList()
    }
  }, [credential])

  const getCouponCodeList = () => {
    getCouponList({
      request_type: "All",
      start_key: "",
      page_size: 99,
    }).unwrap().then((res) => {
      if (res && !res.isFailed && res.result) {
        const UnusedCoupon = res.result.unused_list;
        // console.log("所有的coupon:", res.result)
        setcouponList(UnusedCoupon)
        // 从所有的coupon中筛选出当前老师的coupon
        let currentTeacherCoupon: Array<object> = []
        UnusedCoupon?.forEach(element => {
          if (element?.can_use_info[0]?.coupon_use_teacher_id == teacherId) {
            currentTeacherCoupon.push(element)
          }
        });
        setcurrentCouponList(currentTeacherCoupon)
      }
    })
  }
  const getMembershipConfigCallback = useCallback(async () => {
    const url = `${process.env.REACT_APP_MEMBERSHIP_URL}`;
    getMembershipConfig({ url: url })
      .then((res) => {
        setConfigMembership(res)
      })
      .catch((error) => {
        enqueueSnackbar(error || "Get membership info failed!")
      });
  }, [])

  useEffect(() => {
    getMembershipConfigCallback()
  }, [getMembershipConfigCallback])

  useEffect(() => {
    if (teacherId) {
      handleRefreshList();
    }
  }, [teacherId])

  const handleRefreshList = () => {
    setStartKeyArray(["", ""]);
    setMaxPage(1);
    handleSearch(1);
  }


  const handleSearch = async (pageIndex: number) => {
    setCurrentPage(pageIndex);
    getTeacherScheduleList({
      userId: teacherId,
      roleType: 'teacher',
      startTime: new Date().getTime() - 2 * 60 * 60 * 1000,
      pageSize: PAGE_SIZE,
      startKey: startKeyArray[pageIndex],
      forwardOrder: true
    })
      .unwrap()
      .then(dataRes => {
        if (dataRes && !dataRes.isFailed && dataRes.result) {
          const lessonList: UserScheduleEntity[] = dataRes.result.user_schedule_list;
          const cardList = getCardList(lessonList);
          setDataList(cardList);
          const nextStartKey = dataRes.result.start_key;
          startKeyArray[pageIndex + 1] = nextStartKey;
          setStartKeyArray(startKeyArray);
          setHasMore(nextStartKey && nextStartKey !== "");
          if (nextStartKey && pageIndex + 1 > maxPage) {
            setMaxPage(pageIndex + 1)
          }
        } else {
          setDataList([]);
          setHasMore(false);
          enqueueSnackbar(dataRes?.message || "Can not get data")
        }
      })

  }

  const getCardList = (list: UserScheduleEntity[]): TimeScheduleEntity[] => {
    const resultList: TimeScheduleEntity[] = formatTimeSchedule(list);
    resultList.sort((a, b) => a.time - b.time);
    resultList.forEach(item => {
      item.list.sort((a, b) => a.start_time - b.start_time);
    })
    setDataList(resultList);
    return resultList;
  }

  const formatTimeSchedule = (list: UserScheduleEntity[]): TimeScheduleEntity[] => {
    const resultList: TimeScheduleEntity[] = [];
    if (list && list.length > 0) {
      list.forEach(schedule => {
        const date = new Date(schedule.start_time);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const findDaySchedule = resultList.find(v => {
          const vDate = new Date(v.time);
          return year === vDate.getFullYear() && month === vDate.getMonth() && day === vDate.getDate();
        })

        const newList: FormattedScheduleEntity[] = [];
        if (schedule.source_type === UserScheduleSourceTypeEnum.SERIES_LESSON_CLASSES) {
          const newSchedule: FormattedScheduleEntity = {
            id: schedule.series_lesson_classes?.series_lesson?.id || (Math.random() + ''),
            classroom_id: schedule.series_lesson_classes?.id || '',
            title: schedule.series_lesson_classes?.series_lesson?.title || '',
            create_time: schedule.create_time,
            start_time: schedule.start_time,
            end_time: schedule.end_time,
            status: getStatus(schedule.series_lesson_classes?.status || LessonClassesStatusEnum.Scheduled),
            schedule_type: getScheduleType(schedule.source_type),
            booking_list: schedule.series_lesson_classes ? schedule.series_lesson_classes.student_user_id_list?.map(item => { return { user: { id: item, name: '', avatar: '' }, message: '' } }) : [],
            secondTeachers: schedule.series_lesson_classes?.second_teacher || schedule.series_lesson_classes?.series_lesson?.second_teacher || [],
          }
          newList.push(newSchedule);
        } else if (schedule.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON_CLASSES) {
          const newSchedule: FormattedScheduleEntity = {
            id: schedule.private_lesson_classes?.id || (Math.random() + ''),
            classroom_id: schedule.private_lesson_classes?.classes_id || '',
            title: schedule.private_lesson_classes?.title || '',
            create_time: schedule.create_time,
            start_time: schedule.start_time,
            end_time: schedule.end_time,
            status: getStatus(schedule.private_lesson_classes?.status || LessonClassesStatusEnum.Scheduled),
            schedule_type: getScheduleType(schedule.source_type, schedule.private_lesson_classes?.classes_type),
            booking_list: schedule.private_lesson_classes ? [{ user: { id: schedule.private_lesson_classes.student_user_id, name: '', avatar: '' }, message: '' }] : [],
            secondTeachers: schedule.private_lesson_classes?.second_teacher || [],
          }
          newList.push(newSchedule);
        } else if (schedule.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON) {
          const times = schedule.private_lesson?.free_time_area;
          if (times && times?.length > 0) {
            times.forEach(item => {
              const newSchedule: FormattedScheduleEntity = {
                id: schedule.private_lesson?.id || Math.random() + '', //TODO id不唯一
                title: schedule.private_lesson?.title || "Available Time",
                create_time: undefined,
                start_time: item.start_time,
                end_time: item.end_time,
                status: BookStatusEnum.AVAILABLE_TIME,
                schedule_type: ScheduleLessonTypeEnum.OneToOneLesson,
                repeat_id: schedule.private_lesson?.repeat_id,
                secondTeachers: schedule.private_lesson?.second_teacher || [],
              }
              newList.push(newSchedule);
            })
          }
        }
        if (findDaySchedule) {
          findDaySchedule.list.push(...newList);
        } else {
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          const newDaySchedule: TimeScheduleEntity = {
            time: date.getTime(),
            list: newList
          }
          resultList.push(newDaySchedule);
        }
      })
    }

    return resultList;
  }

  const getScheduleType = (type: UserScheduleSourceTypeEnum, privateLessonClassType?: PrivateLessonClassesTypeEnum) => {
    if (type === UserScheduleSourceTypeEnum.PRIVATE_LESSON) {
      return ScheduleLessonTypeEnum.OneToOneLesson;
    } else if (type === UserScheduleSourceTypeEnum.PRIVATE_LESSON_CLASSES) {
      return privateLessonClassType === PrivateLessonClassesTypeEnum.Student_Create ? ScheduleLessonTypeEnum.OneToOneLesson : ScheduleLessonTypeEnum.CustomPrivateLesson;
    } else if (type === UserScheduleSourceTypeEnum.SERIES_LESSON_CLASSES) {
      return ScheduleLessonTypeEnum.SeriesLesson;
    } else {
      return ScheduleLessonTypeEnum.Others;
    }
  }

  const getStatus = (status: LessonClassesStatusEnum): BookStatusEnum => {
    if (status === LessonClassesStatusEnum.Published) {
      return BookStatusEnum.PAID
    } else if (status === LessonClassesStatusEnum.Scheduled) {
      return BookStatusEnum.PAID
    } else if (status === LessonClassesStatusEnum.Cancel) {
      return BookStatusEnum.CANCELED
    } else if (status === LessonClassesStatusEnum.Finish) {
      return BookStatusEnum.CLOSED
    } else {
      return BookStatusEnum.CLOSED
    }
  }

  const handleClickBook = (item: FormattedScheduleEntity) => {
    if (item.schedule_type === ScheduleLessonTypeEnum.SeriesLesson) {
      navigate(appConfig.paths.seriesLesson + "?id=" + item.id);
    } else {
      if (!credential) {
        dispatch(showSignInUpDialog())
        return;
      }
      setShowBookDialog(true);
      setBookingItem(item);
    }
  }

  const handleBook = (startTime: number, timeLength: number, price: number, note: string, coupon?: string) => {
    if (!credential) {
      dispatch(showSignInUpDialog())
      return;
    }
    if (!bookingItem) {
      return;
    }
    setShowBookDialog(false);
    createPrivateLessonClass({ scheduleId: bookingItem.id, teacherId: teacherId, startTime: startTime, timeMinutes: timeLength })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          const privateLessonClass: PrivateLessonClassSourceEntity = res.result.private_lesson_classes;
          if (privateLessonClass?.id) {
            handleBooking(privateLessonClass?.id, price, note, coupon);
          }
        } else {
          enqueueSnackbar(res?.message || 'Create class failed');
        }
      })
  }
  
  const handleBooking = (classId: string, price: number, note: string, coupon?: string) => {
    createBooking({source_type: 'private_lesson', source_id: classId, message: note, coupon}).unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        const bookId = res.result.id;
        if (bookId) {
          if (isCoupon && coupon) {
            const returnUrl = `${appConfig.paths.commonOrder}?id=${bookId}&coupon=${coupon}`;
            navigate(returnUrl)
          } else {
            handlePurchase(bookId, price);
          }
        } else {
          enqueueSnackbar('Can not get book info', { variant: 'error' })
        }
      } else if (res?.resultCode === ErrorCode.TEACHER_TIME_IS_BOOKED) {
        enqueueSnackbar(t("teacher.timeIsBooked"), { variant: 'error' })
      } else if (res?.resultCode === ErrorCode.TEACHER_TIME_START_IS_PAST) {
        enqueueSnackbar(t("teacher.startTimeIsPast"), { variant: 'error' })
      } else {
        enqueueSnackbar(res?.message || "Book failed")
      }
    })
    .catch(e => {
      enqueueSnackbar("Book failed");
    })
  }
  
  const handleContinuePurchase = (item: FormattedScheduleEntity) => {
    handlePurchase(item.booking_id || "", item.fee ?? 0);
  }

  const handlePurchase = async (bookId: string, price: number) => {
    const image = privateLessonData?.thumbnail || '';
    const desc = privateLessonData?.introduction || '';
    // onClickAddToCart && onClickAddToCart();
    const CHECKOUT_SESSION_ID: string = await genRandom();
    const paiedInfo: PaidInfo = {
      payment_type: PaymentType.Stripe,
      customer_id: credential?.id ?? "",
      cources: bookId,
      amounts: [NP.times(price / 100 ?? 0, 1)]
    };
    const productInfo: PaymentProductInfo = {
      product_id: '2',
      product_type: 'booking',
      paid_info: paiedInfo
    };
    const checkoutOneTimeItem: CheckoutOneTimeItem[] = [
      {
        product_name: bookingItem?.title || '',
        description: desc,
        images: [image],
        amount: price,
        quantity: 1,
        currency: PaymentCurrency.USD
      } as CheckoutOneTimeItem
    ];

    const returnUrl = `${window.location.protocol}//${window.location.host}${appConfig.paths.commonOrder}?id=${bookId}`;
    const params = {
      paymenttype: PaymentType.Stripe,
      price_id: "",
      quantity: 1,
      mode: PaymentMode.Payment,
      coupon: "",
      allow_input_code: true,
      success_url: `${window.location.protocol}//${window.location.host}${appConfig.paths.cartSuccess}?session_id=${CHECKOUT_SESSION_ID}&productid=${2}&payment_value=${price ?? 0}&returnurl=${returnUrl}`,
      cancel_url: window.location.href,
      product_info: productInfo,
      description: desc,
      one_time_items: checkoutOneTimeItem
    };
    const response = await getcheckoutsession(params).unwrap();
    if (response && !response.isFailed && response?.session_id) {
      storeSession(CHECKOUT_SESSION_ID, response?.session_id);
      const stripe = await loadStripe(configMembership?.stripe_publishable_key);
      if (stripe) {
        // Call Stripe.js method to redirect to the new Checkout page
        stripe.redirectToCheckout({
          sessionId: response?.session_id
        });
      }
    } else {
      enqueueSnackbar(response?.message || 'Request failed');
    }
    // setLayoutLoading(false);
  };

  const handleClickViewDetails = (item: FormattedScheduleEntity) => {
    if (item.schedule_type === ScheduleLessonTypeEnum.SeriesLesson) {
      navigate(`${appConfig.paths.seriesLessonClass}?id=${item.classroom_id}`)
    } else {
      navigate(`${appConfig.paths.privateLesson}?id=${item.id}`)
    }
  }

  const handleClickDelete = (item: FormattedScheduleEntity) => {
    setShowDeleteDialog(true);
    setDeletingItem(item)
  }

  const handleClickConfirmDelete = async () => {
    if (!credential) {
      dispatch(showSignInUpDialog())
      return;
    }
    if (!deletingItem) {
      return;
    }
    setShowDeleteDialog(false);
    const originalUserId = authRole.isAdmin ? teacherId : undefined;
    deleteAvailableTime({ schedule_id: deletingItem.id, repeat: deleteRepeat, originalUserId: originalUserId })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          enqueueSnackbar("Delete Successfully", { variant: 'success' });
          handleRefreshList();
        } else {
          enqueueSnackbar(res?.message || 'Delete failed', { variant: 'error' })
        }
      })
  }

  const handleOnClickPrev = () => {
    handleSearch(currentPage - 1)
  }

  const handleOnClickNext = () => {
    handleSearch(currentPage + 1)
  }

  const handleOnClickPage = (page) => {
    handleSearch(page)
  }

  const getBookStatusBg = (status: BookStatusEnum) => {
    if (status === BookStatusEnum.AVAILABLE_TIME) {
      return `url(${IconAvailable})`;
    } else if (status === BookStatusEnum.PAID) {
      return `url(${IconPaid})`;
    } else {
      return `url(${IconUnPaid})`;
    }
  }

  const getPurchasedNum = (item: FormattedScheduleEntity | undefined): string => {
    if (item) {
      if (item.bookPaidNum !== undefined) {
        return `${item.bookPaidNum} purchased`;
      } else if (item.booking_list && item.status === BookStatusEnum.PAID) {
        return `${item.booking_list.length} purchased`;
      } else {
        return '';
      }
    }
    return '';
  }

  const getPrivateLessonScheduleType = (type: ScheduleLessonTypeEnum | undefined): PrivateLessonTypeEnum => {
    if (type === ScheduleLessonTypeEnum.GroupLesson) {
      return PrivateLessonTypeEnum.Group;
    } else if (type === ScheduleLessonTypeEnum.OneToOneLesson) {
      return PrivateLessonTypeEnum.Single;
    } else {
      return PrivateLessonTypeEnum.Other;
    }
  }

  const handleQueryTypeChange = (newQueryType: number) => {
    handleCloseMoreMenu(); 
    setQueryType(newQueryType);
    const newUrl = buildTeacherScheduleListUrl(teacherId||'', newQueryType);
    window.history.pushState({}, "", newUrl);
  }

  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  }

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>

        <Button 
          variant='outlined' 
          className={classes.queryTypeButton} 
          style={{textTransform:"none"}}
          onClick={(event) => handleOpenMoreMenu(event)}
          >
          {queryType === 1 ? "Private lesson/Series lesson" : "Live video class/Live stream"}
          <SvgComponent iconName={showQueryMenu ? 'ico-arrow-top-black' : 'ico-arrow-down-black'} style={{width: '12px', height: '8px', marginLeft: '9px'}} />
        </Button>

        <Menu
          id="more-menu"
          anchorEl={moreMenuAnchorEl}
          open={showQueryMenu}
          onClose={handleCloseMoreMenu}
          className={classes.menu}
        >
          <MenuItem
            onClick={() => {handleQueryTypeChange(1)}}
            className={queryType === 1 ? classes.selectedMenu : classes.unSelectedMenu}
            >
            Private lesson/Series lesson
          </MenuItem>
          <MenuItem 
            onClick={() => {handleQueryTypeChange(2)}} 
            className={queryType === 2 ? classes.selectedMenu : classes.unSelectedMenu}
            >
            Live video class/Live stream
          </MenuItem>
        </Menu>
    
        {queryType === 1 && 
        <Box>
          {/* <Typography className={classes.pageTitle}>Private Lessons</Typography>
          {privateLessonData &&
            <Box className={classes.privateLessonBox}>
              <Avatar variant='square' src={privateLessonData?.thumbnail} className={classes.privateLessonImage} />
              <Box className={classes.privateLessonInfoBox}>
                <Typography className={classes.categoryText}>{getCategoriesText()}</Typography>
                <Box className={classes.feeBox}>
                  {privateLessonData?.time_fees.map(item => (
                    <Box component='span' key={item.time} style={{ display: 'flex', marginRight: '50px' }}>
                      <Typography
                        component='span'
                        className={classes.minutesText}>
                        {`${item.time} mins: `}&nbsp;
                      </Typography>
                      <Typography
                        component='span'
                        className={classes.priceText}
                        color="secondary">
                        {`$${item.fee}`}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Typography
                  className={classes.introduction}>
                  <span className={classes.minutesText}>Introduction:&nbsp;</span>
                  {privateLessonData?.introduction}
                </Typography>
              </Box>
            </Box>
          }

          <Divider style={{ margin: "30px 0" }} /> */}

          {dataList?.length > 0 &&
            <Box className={classes.listBox}>
              {dataList.map(data => (
                <Box key={data.time} className={classes.listTitleItemBox}>
                  <Typography className={classes.timeTitle}>{getDateInWordDescription(data.time)}</Typography>
                  <Grid container display='flex' justifyContent='left' spacing={10}>
                    {data.list?.length > 0 && data.list.map(item => (
                      <Grid key={item.id} item xs={12} sm={6} md={6} lg={6} xl={6} justifyContent='center' display='flex'>
                        <Box className={classes.listItemBox} sx={item.schedule_type === ScheduleLessonTypeEnum.SeriesLesson ? { background: '#307DCF11' } : {}}>
                          <SvgComponent iconName='ico-time-clock' style={{ width: "24px", height: "24px" }} />
                          <Box className={classes.listItemMidBox}>
                            <Typography className={classes.listItemTime}>{getTimeAreaDesc(new Date(item.start_time), new Date(item.end_time))}</Typography>
                            <Box style={{ display: "flex", alignItems: "baseline" }}>
                              <Typography className={classes.listItemMessage}>{getScheduleTypeText(item.schedule_type)}</Typography>
                              {item.schedule_type === ScheduleLessonTypeEnum.GroupLesson &&
                                <Typography style={{ fontSize: "14px", color: "#307DCF", marginLeft: "20px" }} >{getPurchasedNum(item)}</Typography>
                              }
                            </Box>
                            <Typography className={classes.listItemMessage}>{item.title}</Typography>

                            {item.status === BookStatusEnum.UNPAID &&
                              <Box>
                                {item.booking_list?.map((e, i) => (
                                  <Box className={classes.listItemNote} key={i}>
                                    {getDisplayUserName(e.user.name || "", e.user?.id || "") || "Unknown User"}:&nbsp;
                                    <Typography component='span' className={classes.listItemMessage}>{e.message || "None"}</Typography>
                                  </Box>

                                ))}
                              </Box>
                            }

                            <Box className={classes.buttonBox}>
                              {item.schedule_type !== ScheduleLessonTypeEnum.SeriesLesson && item.status === BookStatusEnum.AVAILABLE_TIME && credential && (credential?.id === teacherId || authRole.isAdmin) &&
                                <Button
                                  onClick={() => handleClickDelete(item)}
                                  sx={{
                                    textTransform: "none",
                                    color: "#FF004E",
                                    fontFamily: 'ArialMT',
                                    boxShadow: 'none',
                                    '&:hover': {
                                      boxShadow: 'none',
                                    },
                                  }}
                                  variant="text">
                                  <DeleteIcon />
                                  Delete
                                </Button>
                              }
                              {item.schedule_type !== ScheduleLessonTypeEnum.SeriesLesson && item.status === BookStatusEnum.AVAILABLE_TIME && (!credential || (credential?.id !== teacherId && !item.secondTeachers.some(v => v.id === credential?.id))) && !authRole.isAdmin &&
                                <Button
                                  onClick={() => handleClickBook(item)}
                                  className={classes.viewDetailsBtn}
                                  sx={{ textTransform: 'none' }}
                                  variant="contained">
                                  Book
                                </Button>
                              }
                              {item.schedule_type === ScheduleLessonTypeEnum.SeriesLesson && 
                              (!credential || (credential && credential.id !== teacherId && !item.secondTeachers.some(v => v.id === credential?.id) && !item.booking_list?.find(v => v.user.id === credential.id))) &&
                                <Button
                                  onClick={() => handleClickBook(item)}
                                  className={classes.viewDetailsBtn}
                                  sx={{ textTransform: 'none' }}
                                  variant="contained">
                                  Book
                                </Button>
                              }
                              {item.schedule_type === ScheduleLessonTypeEnum.SeriesLesson && 
                              credential && (credential.id === teacherId || item.secondTeachers.some(v => v.id === credential?.id) || item.booking_list?.some(v => v.user.id === credential.id)) &&
                                <Button
                                  onClick={() => handleClickViewDetails(item)}
                                  className={classes.viewDetailsBtn}
                                  sx={{ textTransform: 'none' }}
                                  variant="contained">
                                  View Details
                                </Button>
                              }
                              {item.schedule_type !== ScheduleLessonTypeEnum.SeriesLesson
                                && credential && (credential?.id === teacherId || item.secondTeachers.some(v => v.id === credential?.id) || item.booking_list?.some(v => v.user.id === credential?.id)) && item.classroom_id &&
                                <Button
                                  onClick={() => handleClickViewDetails(item)}
                                  className={classes.viewDetailsBtn}
                                  sx={{ textTransform: 'none' }}
                                  variant="contained">
                                  View Details
                                </Button>
                              }

                              {item.schedule_type !== ScheduleLessonTypeEnum.SeriesLesson && credential && item.booking_list?.some(v => v.user.id === credential?.id) && item.status === BookStatusEnum.UNPAID &&
                                <Button
                                  onClick={() => handleContinuePurchase(item)}
                                  className={classes.viewDetailsBtn}
                                  sx={{ textTransform: 'none' }}
                                  variant="contained">
                                  Continue Payment
                                </Button>
                              }

                            </Box>
                          </Box>

                          {item.schedule_type !== ScheduleLessonTypeEnum.SeriesLesson && item.schedule_type !== ScheduleLessonTypeEnum.CustomPrivateLesson &&
                            <Box className={classes.bookStatusText} style={{ backgroundImage: getBookStatusBg(item.status), backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                              {getBookTimeStatusDesc(item.status)}
                            </Box>
                          }
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
          }

          {
            (!privateLessonData || !dataList || dataList.length === 0) &&
            <Box
              sx={{
                width: '100%',
                height: '300px',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <img src={noCourse} alt="no_data"></img> */}
              <Typography
                sx={{
                  fontSize: '18px',
                  fontFamily: 'ArialMT',
                  color: '#555555',
                  lineHeight: '21px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >No available time or scheduled class yet.</Typography>
            </Box>
          }

          {(currentPage !== 1 || dataList?.length !== 0) &&
            <ListPagination
              currentPage={currentPage}
              hasMore={hasMore}
              // totalCount={totalCount}
              rowsPerPage={PAGE_SIZE}
              maxPage={maxPage}
              onClickPage={handleOnClickPage}
              onClickPrev={handleOnClickPrev}
              onClickNext={handleOnClickNext}
            />
          }

          <Dialog
            maxWidth="lg"
            open={showBookDialog}
          >
            <Box style={{ display: "flex", alignItems: "center", padding: "20px 20px 0" }}>
              <Typography style={{ flexGrow: 1, fontSize: "24px", fontFamily: "ArialMT" }}>Book</Typography>
              <IconButton onClick={() => setShowBookDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography style={{ paddingLeft: "20px", fontSize: "14px", fontFamily: "ArialMT" }}>{bookingItem?.schedule_type ? getScheduleTypeText(bookingItem.schedule_type) : ''}</Typography>
              <Typography style={{ fontSize: "14px", color: "#307DCF", marginLeft: "20px" }} >{getPurchasedNum(bookingItem)}</Typography>
            </Box>
            <Divider style={{ margin: "10px 20px 0 20px" }} />

            <DialogContent sx={{ minWidth: isMobile ? '100%' : '500px', position: "relative" }}>
              <StudentBookLayout
                groupFee={bookingItem?.groupLessonFee ?? 0}
                scheduleType={getPrivateLessonScheduleType(bookingItem?.schedule_type)}
                startDate={new Date(bookingItem?.start_time || 0)}
                endDate={new Date(bookingItem?.end_time || 0)}
                title={bookingItem?.title || ""}
                privateLesson={privateLessonData}
                coupon={currentCouponList}
                selectCoupon={(res) => { setisCoupon(res) }}
                currentCouponCode={(res) => { setcurrentCoupon(res) }}
                onClickBook={handleBook} />
            </DialogContent>
          </Dialog>

          <Dialog
            maxWidth="lg"
            open={showDeleteDialog}
          >
            <DialogTitle id="alert-dialog-title">Prompt</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
              <Divider />
              <Box style={{ fontSize: "14px", fontFamily: "ArialMT", marginTop: "20px" }}>
                Do you want to delete this available time?
              </Box>
              {deletingItem?.repeat_id &&
                <Box style={{ display: 'flex', alignItems: 'center', fontSize: "12px", fontFamily: "ArialMT", marginTop: "20px" }}>
                  <Checkbox
                    value={deleteRepeat}
                    onChange={(e) => setDeleteRepeat(e.target.checked)} />
                  Delete all duplicate records for this time.
                </Box>
              }
            </DialogContent>
            <DialogActions>
              <Button variant='text' onClick={() => setShowDeleteDialog(false)} color="inherit" style={{ textTransform: "none" }}>
                Cancel
              </Button>
              <Button variant='text' onClick={handleClickConfirmDelete} color="secondary" autoFocus style={{ textTransform: "none" }}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Loader isLoading={loading0 || loading3 || loading4 || loading5 || loading7} />
        </Box>
        }
        {queryType === 2 &&
          <TeacherLiveVideoClassList teacherId={teacherId}/>
        }
      </Box>

    </Box>
  )
}

export default TeacherScheduleList;