import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  CardProps,
  IconButton,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Video } from 'src/app/models'
import {
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  AddShoppingCart as AddShoppingCartIcon
} from '@mui/icons-material'
import React from 'react'
import clsx from 'clsx'
import { Link as RouteLink } from 'react-router-dom'
import { useSelector } from 'src/app/toolkit/store'

const useStyles = makeStyles(theme => ({
  card__wrapper: { display: 'flex', marginTop: '16px' },

  avatar: {
    width: 30,
    height: 30,
    marginRight: 10
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '48px'
  },
  title: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#000000',
    lineHeight: '22px',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },
  channel: {
    fontSize: '14px',
    // color: '#717171',
    fontWeight: 600,
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },

  image__container: {
    position: 'relative',
    // width: '100%',
    // paddingTop: '56.25%'
    // maxWidth: '360px',
    width: '360px',
    minWidth: '240px',
    flexShrink: 0,

    '&::before': {
      display: 'block',
      content: "''",
      paddingTop: '56.25%'
    }
  },
  image: {
    position: 'absolute',
    width: '100%',
    top: '0',
    left: '0',
    height: '100%',
    // inset: '0',
    background: 'white'
  },

  cart: {
    width: '36px',
    height: '36px',
    visibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',

    '&.active': {
      visibility: 'visible'
    }
  }
}))

interface VideoCardProps extends CardProps {
  video: Video
  onCartAdded: (video: Video) => void
}

export const VideoCardV3: React.FC<VideoCardProps> = ({ video, onCartAdded, ...props }) => {
  const classes = useStyles()
  const [active, setActive] = React.useState(false)
  const { items } = useSelector(s => s.cart)

  return (
    <Card
      {...props}
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={{ background: 'transparent' }}
      className={clsx([classes.card__wrapper])}
    >
      <RouteLink to={`/watch?v=${video.id}`}>
        <Box className={classes.image__container}>
          <CardMedia
            component="img"
            height="160"
            image={video.thumbnail}
            alt="Paella dish"
            className={classes.image}
          />
        </Box>
      </RouteLink>
      <CardContent
        sx={{
          padding: '0px',
          paddingBottom: '0px !important',
          marginLeft: '16px',
          flexGrow: 1,
          flexShrink: 1
        }}
      >
        <RouteLink to={`/watch?v=${video.id}`} style={{ textDecoration: 'none' }}>
          <Typography
            sx={{ fontSize: '18px' }}
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {video.title}
          </Typography>
        </RouteLink>
        <Typography variant="caption">
          {video.view} views * {video.timeCreated} housrs ago
        </Typography>
        <div className={classes.container}>
          <Avatar
            className={classes.avatar}
            alt={video.channel?.name}
            src={video.channel?.avatar}
          />
          <div style={{ flexGrow: 1 }}>
            <Typography className={classes.channel}>{video.channel?.name}</Typography>
          </div>

          {items?.some(v => v.video.id === video.id) ? (
            <ShoppingCartOutlinedIcon color="secondary" />
          ) : (
            <IconButton
              className={clsx([classes.cart, { active: active }])}
              onClick={() => onCartAdded && onCartAdded(video)}
            >
              <AddShoppingCartIcon
                style={{
                  position: 'relative',
                  top: '1px',
                  left: '1px',
                  width: '24px',
                  height: '24px'
                }}
              />
            </IconButton>
          )}
        </div>
        <Typography variant="caption">{video.summary}</Typography>
      </CardContent>
    </Card>
  )
}
