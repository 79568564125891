import { createApi } from '@reduxjs/toolkit/query/react'
import { List } from '@dokyo/common'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { TrendingTeacher } from '../../models'

export const trendingTeachersApi = createApi({
  reducerPath: 'trendingTeachersApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getTrendingTechers: builder.query<TrendingTeacher[], null>({
        query: () => ({
          url: `trending-teachers`
        }),
        transformResponse: (res: List<TrendingTeacher>) => res.items
      })
    }
  }
})

export const { useGetTrendingTechersQuery } = trendingTeachersApi
