import React from 'react'
import { 
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Typography,
  Theme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import defaultAvatar from 'src/images/teacher/defaultAvatar.png'
import { useDispatch } from 'react-redux';
import { setSchool } from 'src/app/service/school-slice'
import { useNavigate } from 'react-router-dom'
import { School } from 'victoria-veeko/src/app/models'
import clsx from 'clsx'
import { SeriesEntity } from '@dokyo/common'
import { appConfig } from 'src/app-config';
import { Link as RouteLink } from 'react-router-dom';
import { TAB_VALUES } from 'src/pages/course/Course';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer'
  },
  verticalSize: {
    width: 220,
    height: 390,
  },
  horizontalSize: {
    width: 280,
    height: 270,
    [theme.breakpoints.down('sm')]: {
      width: 300,
    }
  },
  bgTransparent: {
    background: 'transparent !important'
  },
  avatar: {
    borderRadius: '8px',
  },
  name: {
    width: '220px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  total: {
    color: '#555555',
    
  },
  content: {
    boxShadow: 'none',
  },
  imgHover: {
    filter: 'contrast(70%)'
  },
  nameHover: {
    textDecoration: 'underline'
  },
  link: {
    color: '#000',
    textDecoration: 'none'
  }
}))

interface Props extends CardProps {
  // card: {
  //   id: string;
  //   avatar: string;
  //   avatarHd: string;
  //   name: string;
  //   signature: string;
  //   category_tittle?: string;
  //   school_id: string;
  // },
  card: SeriesEntity;
  horizontal?: boolean;
  bgTransparent?: boolean;
  jumpTab?: number;
}



export const SeriesCard: React.FC<Props> = ({ card, horizontal = true, bgTransparent = false, jumpTab }) => {
  const classes = useStyles();
  // const dispatch = useDispatch()
  // const navigate = useNavigate()

  const [ imgSrc, setImgSrc ] = React.useState<string>(!!card.series_thumbnail? card.series_thumbnail: defaultAvatar)
  const [ isMouseOver, setIsMouseOver] = React.useState<boolean>(false)


  const handleOnImgError = () => {
    setImgSrc(
      defaultAvatar
    )
  }

  const width: string  = horizontal? '280': '220';
  const height: string = horizontal? '186': '287';

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }
  // console.log('jumpTab:', jumpTab)
  const jumpTabUrl = jumpTab? `&tabValue=${jumpTab}`: `&tabValue=${TAB_VALUES.COURSE_INFO}`;
  // console.log(`${appConfig.paths.course}?id=${card.series_id}${jumpTabUrl}`)
  return (
    <Card
      className={clsx(classes.root, horizontal? classes.horizontalSize: classes.verticalSize, {
        [classes.bgTransparent]: bgTransparent,
      })}
      // onClick={handleClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      sx={{
        boxShadow: 'none'
      }}
    >
      <RouteLink className={classes.link} to={`${appConfig.paths.course}?id=${card.series_id}${jumpTabUrl}`}>
      <CardMedia
        onError={handleOnImgError} 
        component="img"
        width={width}
        height={height}
        image={imgSrc}
        className={classes.avatar}
      />
      <CardContent
       className={clsx(classes.content, {
        [classes.bgTransparent]: bgTransparent,
      })}
       sx={{
        padding: 0,
       }}
      >
        <Typography 
          title={card.series_title}  
          className={
            clsx(
              classes.name, 
              {[classes.nameHover]: isMouseOver}
            )
          }
          sx={{
            margin: '11px 0 4px 0',
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: 'Arial-BoldMT, Arial;',
          }}
        >
          {card.series_title}
        </Typography>
        <Typography 
          className={classes.total}
          sx={{
            fontSize: '12px',
            fontFamily: 'Arial;',
            lineHeight: '12px',
            marginTop:'10px',
            width: '220px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {card.series_category?.name + ", " + (card.series_course_count ?? 0) + " classes"}
        </Typography>
      </CardContent>
      </RouteLink>
    </Card>
  )
}