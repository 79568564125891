import React from 'react'
import { CourseList } from './CourseList'
import { useGetQueryPublishedCourseListQuery } from 'src/app/service/real/coursedetail-slice'
import { CourseEntity } from '@dokyo/common'


export interface PropsCourseListBySort {
  startKey: string,
  onDataLoad: (startKey: string) => void,
  sortName: string,
}

export const CourseListBySort: React.FC<PropsCourseListBySort> =({ startKey, onDataLoad, sortName }) => {

  // startKey
  const { error, data, isFetching} = useGetQueryPublishedCourseListQuery({
    startKey,
    pageSize: 12,
    forwardOrder: sortName === 'Trending' || sortName === 'Recently Added'
  })

  let courseList: CourseEntity[] = []
  if (data && !data.isFailed && data.result) {
    if (data.result.course_list) {
      courseList = data.result.course_list
    } else {
      courseList = []
    }
    
    onDataLoad(data.result.start_key?? '')
  }
  console.log(error)
  
  return (
    <CourseList 
      isLoading={isFetching} 
      courseList={courseList} 
    />
  )
}