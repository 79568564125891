import React from 'react'
import Slider from 'react-slick'
import { ClassesCard } from 'src/components/cards/ClassesCard'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import SliderPagination from '../slider_pagination'

const useStyles = makeStyles(theme => ({
  masterCardSlider: {
    width: '100%',
    margin: 'auto',
    marginLeft: '6px',
    '& .slick-arrow': {
      zIndex: '1',
      '&:before': {
        fontSize: '60px',
        color: 'black',
        content: "''"
      },
      margin: "0 35px 0 35px",
    },

    '& .slick-list': {
      margin: '0 -12px'
    }
  },
  slider__content: {
    margin: 'auto'
  },

  card: {},
  root: {
    position: 'relative',
    width: 'calc(100% - 50px)',
    height:'430px',
    marginTop:'30px',
    display:'flex',
    justifyContent:'center'
  },
  slide: {
    minHeight: 100,
    color: '#fff',
  },
  slide1: {
    position:'relative',
    width:'100%',
    display:'flex',
    justifyContent:'space-around'
    // background:'red'
  },
  slide2: {
    display:'none'
  }
}))

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const ExcellentClassesSlider = ({ excellentClassSeries, handleClick, }) => {
  const classes = useStyles()
  const [index,SetIndex] = React.useState<number>(0)
  let cardIndex = 0
  let Keyindex = 0
  let configLength1:number[] = []

  const handleChangeIndex = (index:number) => {
    if (index >= 0)
      SetIndex(index)
  };

  // React.useEffect(() => {
  //   if(excellentClassSeries){
  //     for(let i=0;i<(excellentClassSeries.length)/4;i++){
  //       configLength1.push(i)
  //     }
  //     console.log(configLength1)
  //   }
  //   console.log(excellentClassSeries)
  // },[JSON.stringify(excellentClassSeries)])
  if(excellentClassSeries){
      for(let i=0;i<(excellentClassSeries.length)/4;i++){
        configLength1.push(i)
      }
  }

  React.useEffect(()=>{
    if(configLength1.length > 0){
      handleChangeIndex(0)
    }
  },[JSON.stringify(configLength1)])

  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon style={{ color: 'black', width: '70%', height: '70%' }} />
      </div>
    )
  }
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: 'black',
            width: '70%',
            height: '70%',
            transform: 'rotate(180deg)'
          }}
        />
      </div>
    )
  }
  return (
     <div className={classes.root}>
     <AutoPlaySwipeableViews
       index={index}
       onChangeIndex={handleChangeIndex}
       interval={3000}
       enableMouseEvents
     >
          {
            configLength1?.map((v, i) => (
              <div className={classes.slide1} key={i}>
                {excellentClassSeries.length > cardIndex && (
                    <Box>
                    <ClassesCard
                      key={excellentClassSeries[Keyindex++].id}
                      card={excellentClassSeries[cardIndex++]}
                      color={'#FFFFFF'}
                      handleClick={handleClick}
                    />
                    </Box>
                   )
                }
                {excellentClassSeries.length > cardIndex && (
                    <Box>
                    <ClassesCard
                      key={excellentClassSeries[Keyindex++].id}
                      card={excellentClassSeries[cardIndex++]}
                      color={'#FFFFFF'}
                      handleClick={handleClick}
                    />
                    </Box>
                   )
                }
                {excellentClassSeries.length > cardIndex && (
                    <Box>
                    <ClassesCard
                      key={excellentClassSeries[Keyindex++].id}
                      card={excellentClassSeries[cardIndex++]}
                      color={'#FFFFFF'}
                      handleClick={handleClick}
                    />
                    </Box>
                   )
                }
                {excellentClassSeries.length > cardIndex && (
                    <Box>
                    <ClassesCard
                      key={excellentClassSeries[Keyindex++].id}
                      card={excellentClassSeries[cardIndex++]}
                      color={'#FFFFFF'}
                      handleClick={handleClick}
                    />
                    </Box>
                   )
                }
              </div>
            ) 
          )
          }
          {/* {excellentClassSeries?.map((v, i) => (
            <div className={classes.slide1}>
            <Box onClick={() => handleClick(v)}>
              <ClassesCard
                key={v.id}
                card={v}
                color={'#FFFFFF'}
              />
            </Box>
            </div>
          ))} */}
     </AutoPlaySwipeableViews> 
     {
       configLength1.length > 1 && <SliderPagination dots={configLength1.length} index={index} onChangeIndex={handleChangeIndex} />
     }
   </div>
  )
}
