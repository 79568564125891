import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value, PaymentType, PaymentMode, PaymentProductInfo, SessionInfo, CheckoutOneTimeItem} from '@dokyo/common'

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getCheckoutSession: builder.mutation<Result<any>, {
        paymenttype: PaymentType,
        price_id: string,
        quantity: number,
        mode: PaymentMode,
        coupon: string,
        allow_input_code: boolean,
        success_url: string,
        cancel_url: string,
        product_info: PaymentProductInfo,
        description?: string,
        one_time_items?: CheckoutOneTimeItem[],
        trial_period_days?: number,
      }>({
        query: ({ paymenttype, price_id, quantity, mode, coupon, allow_input_code, success_url, cancel_url, product_info, description, one_time_items, trial_period_days }) => ({
          url: `getcheckoutsession`,
          method: 'POST',
          body: { 
            paymenttype: paymenttype,
            price_id: price_id,
            quantity: quantity,
            mode: mode,
            coupon: coupon,
            allow_input_code: allow_input_code,
            success_url: success_url,
            cancel_url: cancel_url,
            product_info: product_info,
            description: description,
            one_time_items,
            trial_period_days: trial_period_days
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      makePayment: builder.mutation<Result<any>, {
        payment_request_id: string,
        session_info: SessionInfo,
        product_info: PaymentProductInfo,
        cart?: string
      }>({
        query: ({ payment_request_id, session_info, product_info, cart }) => ({
          url: `makepayment`,
          method: 'POST',
          body: { 
            payment_request_id: payment_request_id,
            session_info: session_info,
            product_info: product_info,
            cart
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      applyAgentCode: builder.mutation<Result<any>, {
        user_id: string,
        promoCode: string
      }>({
        query: ({ user_id, promoCode }) => ({
          url: `uploaduserprofile`,
          method: 'POST',
          body: { 
            user_id: user_id,
            refer_code: promoCode,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      })
    }
  }
})

export const { 
  useGetCheckoutSessionMutation,
  useMakePaymentMutation,
  useApplyAgentCodeMutation,
} = paymentApi