import * as React from 'react'
import { useState} from 'react'
import { Box, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LiveInfo, TAB_VALUES } from '../Course'
import { BroadcastEntity } from '@dokyo/common'
import { CourseInfoSilder } from './CourseInfoSilder'
import { CardTypeEnum, CourseInfoCard } from './card/CourseInfoCard'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'

const useStyles = makeStyles((theme: Theme) => ({
  root:{
    textAlign: "center",
    paddingBottom: "50px",
  },
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '0px 50px 0px 50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  title: {
    margin: '33px 0',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Avenir-Black, Avenir',
  },  
  card: {
    padding: "0px 5px"
  },
  button: {
    width: "172px !important",
    height: "48px !important",
    background: "#307DCF !important",
    borderRadius: "24px !important",
    margin: "30px auto !important",
    color: "white",
    fontSize: "14px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
  },
})
)
interface Props {
  courseId: string,
  liveInfo: LiveInfo,
  meetingList: any[],
  // onClickMore: () => void,
}

export const LiveAndUpcomingLive: React.FC<Props> = ({courseId, liveInfo, meetingList}) => {
  const classes=useStyles()
  const navigate = useNavigate();
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [listData, setListData] = useState<ClassroomListCardEntity[]>([]);

  React.useEffect(()=> {
    if (liveInfo) {
      let newList : BroadcastEntity[] = [];
      if (liveInfo.liveNow?.length > 0) {
        newList = newList.concat(liveInfo.liveNow);
      }
      if (liveInfo.upcoming?.length > 0) {
        newList = newList.concat(liveInfo.upcoming);
      }
      const transferList = getBroadcastData(newList);
      setListData([...listData, ...transferList])
    }
  }, [liveInfo])

  React.useEffect(()=>{
    if (meetingList?.length > 0) {
      const newList = getUpcomingMeetingList(meetingList);
      setListData([...listData, ...newList]);
    }
  },[meetingList])

  const getBroadcastData = (broadcastList: BroadcastEntity[]): ClassroomListCardEntity[] => {
    const classRoomDataList: ClassroomListCardEntity[] = [] 
    
    broadcastList.forEach(learnPage => {
      if (learnPage && learnPage.broadcast_id) {
        classRoomDataList.push({
          id: learnPage.broadcast_id,
          media_image: learnPage.broadcast_cover,
          class: learnPage.category.name,
          content_header: learnPage.broadcast_title,
          time: learnPage.broadcast_start_time,
          type: CardTypeEnum.LiveStream,
          teacherId: learnPage.teacher.user_name,
          teacherName: learnPage.teacher.user_id,
          teacherAvatar: learnPage.teacher.user_avatar,
        })
      }
    })
    return classRoomDataList
  }

  const getUpcomingMeetingList = (list: any[]) : ClassroomListCardEntity[] => {
    if (!list) {
      return [];
    }
    const resultList : ClassroomListCardEntity[] = [];
    const nowTime = Date.now();
    list.forEach((item) => {
      const classroomTime = item.meeting_start_time ?? item.meeting_created_time;
      const diff = nowTime - classroomTime * 1000;
      const twoHour = 2 * 60 * 60 * 1000;

      const newItem : ClassroomListCardEntity = {
        id: item.meeting_info.meeting_id,
          media_image: item.meeting_thumbnail,
          class: item.category.name,
          content_header: item.meeting_title,
          time: (item.meeting_start_time?? item.meeting_created_time),
          teacherId: item.teacher?.user_id,
          teacherName: item.teacher?.user_name,
          teacherAvatar: item.teacher?.user_avatar,
          fees: item.meeting_fees,
          type: CardTypeEnum.LiveVideoClass,
      } 
      if (item.status === 1 || item.status === 2) {
        resultList.push(newItem)
      } else if (diff < twoHour) {
        resultList.push(newItem)
      }
    })
    return resultList;
  }

  const handleClickButton = () => {
    navigate(`${appConfig.paths.course}?id=${courseId}&tabValue=${TAB_VALUES.LIVE_STREAM}`, {replace:true})
  }

  return (
    <>
    {listData?.length > 0 ?
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.title}>
          Live & Upcoming Live
        </Box>
        <CourseInfoSilder arrowTop={65} maxShowNumber={4} itemWidth={350} marginCount={300}>
          {
            listData.map((item => {
              return (
                <Box key={item.id} className={classes.card}>
                  <CourseInfoCard
                    bgTransparent={false}
                    showLabel={true}
                    card={item}
                    enable={true}
                  />
                </Box>
              )
            }))
          }
          </CourseInfoSilder>

          {/* 
          <Button className={classes.button} sx={{textTransform:"none"}} onClick={onClickMore}>
            Explore Class
            <SvgComponent iconName='ico-next-small' style={{width:"8px", height:"13px", marginLeft:"13px"}}/>
          </Button> 
          */}
        </Box>
      </Box>
      :
      ""
    }
  </>  
  )
}