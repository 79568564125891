import React from 'react'
import { CourseList } from './CourseList'
import { useGetCourseListBySchoolQuery } from 'src/app/service/real/coursedetail-slice'
import { CourseEntity } from '@dokyo/common'


export interface PropsCourseListBySchoolId {
  schoolId: string,
  startKey: string,
  onDataLoad: (startKey: string) => void
}

export const CourseListBySchoolId: React.FC<PropsCourseListBySchoolId> =({ startKey, schoolId, onDataLoad }) => {

  const { data, isFetching } = useGetCourseListBySchoolQuery({ startKey, schoolId, pageSize: 12 })

  let courseList: CourseEntity[] = []
  if (data && !data.isFailed && data.result) {
    if (data.result?.course_list) {
      courseList = data.result.course_list
    } else {
      courseList = []
    }
    
    onDataLoad(data.result.start_key?? '')
  }


  return (
    <CourseList isLoading={isFetching} courseList={courseList} />
  )
}