import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'


export const chatApi = createApi({
  reducerPath: 'chat-api',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      createChat: builder.mutation<Result<any>, {
        userIds: string[],
      }>({
        query: ({ userIds }) => ({
          url: `chat`,
          method: 'POST',
          body: {
            message_to: userIds,
            request_type: 1
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
        
      }),

      getChatList: builder.mutation<Result<any>, {
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean,
        
      }>({
        query: ({ startKey, pageSize, forwardOrder }) => ({
          url: `chat`,
          method: 'POST',
          body: { 
            start_key: startKey || '',
            page_size: pageSize ?? 99,
            forward_order: forwardOrder ?? false,
            request_type: 2,
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
        // transformResponse: (res: any) => new Value<any>({
        //   ...res, data: {
        //     chat_list: res.chat_list
        //   }
        // })
      }),

      deleteChat: builder.mutation<Result<any>, {
        chatId: string,
      }>({
        query: ({ chatId }) => ({
          url: `chat`,
          method: 'POST',
          body: {
            chat_id: chatId,
            request_type: 3
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      }),

      readChat: builder.mutation<Result<any>, {
        chatId: string,
        messageIds: string[],
      }>({
        query: ({ chatId, messageIds }) => ({
          url: `chat`,
          method: 'POST',
          body: {
            chat_id: chatId,
            messages_id: messageIds,
            request_type: 4
          }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      }),
    }
  }
})


export const { useGetChatListMutation, useCreateChatMutation, useDeleteChatMutation, useReadChatMutation } = chatApi