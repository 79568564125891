import { FC } from 'react';
import CameraSelection from './CameraSelection';
import QualitySelection from './QualitySelection';
import VideoTransformSelection from './VideoTransformSelection';
import {
    useMeetingState
} from '../../../providers/MeetingStateProvider';
import {
    PreviewVideo,
} from 'amazon-chime-sdk-component-library-react';

const CameraDevices: FC = () => {
    const { initOpenVideo } = useMeetingState();
    return (
        <>
            <CameraSelection />
            <QualitySelection />
            <VideoTransformSelection />
            {
                initOpenVideo && 
                <PreviewVideo />
            }
        </>
    );
}

export default CameraDevices;