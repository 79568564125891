import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'src/app/toolkit/helper';
import { Response, Result, Value, Cart } from '@dokyo/common'


export type GetCartResponse = Cart;

export interface GetItemInCart {
  itemInCart: boolean;
}

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      addCartItem: builder.mutation<Result<{}>, {
        seriesId: string;
      }>({
        query: ({ seriesId }) => ({
          url: `addcartitem`,
          method: 'POST',
          body: { 
            series_id: seriesId
          }
        }),
        //  You can define your response type
        transformResponse: (res: Response<{}>) => new Value<{}>(res)
      }),
      getCart: builder.query<Result<GetCartResponse>, {}>({
        query: () => ({
          url: `getcart`,
          method: 'POST',
        }),
        //  You can define your response type
        transformResponse: (res: Response<GetCartResponse>) => new Value<GetCartResponse>(res)
      }),
      deleteCartItem: builder.mutation<Result<undefined>, {
        seriesId: string;
      }>({
        query: ({ seriesId }) => ({
          url: `deletecartitem`,
          method: 'POST',
          body: { 
            series_id: seriesId,
          },
        }),
        //  You can define your response type
        transformResponse: (res: Response<undefined>) => new Value<undefined>(res)
      }),
      getItemInCart: builder.query<Result<GetItemInCart>, {
        seriesId: string;
      }>({
        query: ({ seriesId }) => ({
          url: `getitemincart`,
          method: 'POST',
          body: { 
            series_id: seriesId,
          },
        }),
        //  You can define your response type
        transformResponse: (res: Response<GetItemInCart>) => new Value<GetItemInCart>(res)
      }),
    }
  }
})


export const {
  useDeleteCartItemMutation,
  useAddCartItemMutation,
  useGetCartQuery,
  useGetItemInCartQuery,
  useLazyGetCartQuery,
  useLazyGetItemInCartQuery
} = cartApi;