import * as crypto from 'crypto';
import * as jwt from "jsonwebtoken";
import { useSelector } from 'src/app/toolkit/store';

const genRandom = (length = 32): any => {
  return new Promise((resolve, reject) => {
    
    crypto.randomBytes(length, (err: any, buf: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(buf.toString('base64'));
      }
    });
  });
};

export interface TokenData {
  userid: string;
  exp: number;
  iat: number;
}

export const decodeJWT = function (token: string): TokenData {
  const decoded = jwt.decode(token, { complete: true });
  return decoded.payload;
};

export default genRandom;


export interface AuthStatus {
  isLogin: boolean,
  // isMember: boolean
}

export interface GetAuthStatus {
  (): AuthStatus
}

export const getAuthStatus = (): GetAuthStatus => {
  return (): AuthStatus => {
    const result = useSelector(state => {
      const authStatus: AuthStatus = {
        isLogin: false,
        // isMember: false
      };
      const { credential } = state.app;
      if (credential) {
        authStatus.isLogin = true;
        // if (credential.membership_info?.ending_date && credential.membership_info.ending_date > Date.now()) {
        //   authStatus.isMember = true;
        // }
      }
      
      return authStatus;
    })
    return result;
  }
}

export interface AuthRole {
  isAdmin: boolean;
  isTeacher: boolean;
  isAgent: boolean;
  isSchoolAdmin: boolean;
}

export interface GetAuthRole {
  (): AuthRole;
}

export const getAuthRole = (): GetAuthRole => {
  return (): AuthRole => {
    const result = useSelector(state => {
      const authRole: AuthRole = {
        isAdmin: false,
        isTeacher: false,
        isAgent: false,
        isSchoolAdmin: false,
      };
      const { credential } = state.app;
      if (credential && Array.isArray(credential.roles) && credential.roles.length > 0) {
        credential.roles.forEach(item => {
          if (item === 'admin') {
            authRole.isAdmin = true;
          } else if (item === 'teacher') {
            authRole.isTeacher = true;
          } else if (item === 'agent') {
            authRole.isAgent = true;
          } else if (item === 'school_admin') {
            authRole.isSchoolAdmin = true;
          }
        })
      }
      return authRole;
    })
    return result;
  }
}
