import { List } from '@dokyo/common'
import { createApi } from '@reduxjs/toolkit/query/react'
import { Video } from 'src/app/models'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'

export const historyApi = createApi({
  reducerPath: 'historyApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getHistoryVideosSeen: builder.query<Video[], string>({
        query: type => ({
          url: `history/videos/seen/${type}`
        }),
        transformResponse: (res: List<Video>) => res.items
      })
    }
  }
})

export const { useGetHistoryVideosSeenQuery } = historyApi
