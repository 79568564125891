import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useGetLearnPageQuery } from 'src/app/service/real/learnpage-slice'
import { Loader } from 'src/components/loader'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import { ClassroomListCard } from 'src/components/cards/ClassroomListCard'
import { useNavigate } from 'react-router-dom'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import SvgComponent from 'src/components/svgComponent'
import { ListPagination } from 'src/components/pagination'
import { getBroadcastData } from './BroadcastList'


const useStyles = makeStyles(() => ({
  courseList: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '22px'
  },
  listTitle: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    marginBottom: '20px',
    marginLeft: '12px'  
  }
}))

const ListTitle = styled(Typography)({
  fontSize: '30px',
  fontWeight: 'bold',
  lineHeight: '40px',
  marginLeft: '15px'
})


export interface PropsBroadcastList {
  onClickBack: () => void
}

export const LiveNow: React.FC<PropsBroadcastList> =({ onClickBack }) => {
  const classes = useStyles();
  const navigate = useNavigate()


  const [hasMore, setHasMore] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalCount = 0;
  const [startKeyArray, setStartKeyArray] = React.useState(["", ""]);
  const [maxPage, setMaxPage] = React.useState(1)
  const [isShowPagination, setIsShowPagination] = React.useState<Boolean>(false)


  const handleOnClickPrev = () => {
    setCurrentPage(currentPage - 1)
  }
  const handleOnClickNext = () => {
    
    setCurrentPage(currentPage + 1)
    // if (maxPage <= currentPage) {
    //   setMaxPage(currentPage + 1)
    // }
  }

  const handleOnClickPage = (page) => {
    setCurrentPage(page)
  }

  const handleClick = (id:string) => {
    navigate("/livebroadcast?id=" + id)
  }

  let listData: ClassroomListCardEntity[] = []

  const { data: liveData, isFetching: liveIsFetching } = useGetLearnPageQuery({
    learnType:'live_now',
    startKey: startKeyArray[currentPage],
    pageSize: 12
  }, {
    refetchOnMountOrArgChange: 60
  })

  if (liveData && !liveData.isFailed && liveData.result?.learn_page) {
    if (liveData.result.learn_page[0]?.broadcast_list) {
      listData = getBroadcastData(liveData.result.learn_page[0]?.broadcast_list)
    } else {
      listData = []
    }
  }

  useEffect(() => {
    if (liveData) {
      const newStartKeyArray = [...startKeyArray]
      let newStartKey = liveData.result.learn_page[0]?.start_key
      newStartKey = typeof newStartKey === 'string'? newStartKey: ''
      newStartKeyArray[currentPage + 1] = newStartKey
      setStartKeyArray(newStartKeyArray)
      setHasMore(newStartKey !== '')
      setIsShowPagination(true)
      if (newStartKey && currentPage + 1 > maxPage) {
        setMaxPage(currentPage + 1)
      }
    }
  }, [liveData])

  const handleBack= () => {
    onClickBack()
  }

  return (
    <>
      <Loader isLoading={liveIsFetching} />
      <Box>
        <Box className={classes.listTitle}>
          <SvgComponent onClick={handleBack} iconName={"leftArrow"} style={{ marginRight: "8px", width: "20px", height: "18px", cursor: 'pointer'}}/>
          <ListTitle>
            Live Now
          </ListTitle>
        </Box>
        <Box className={classes.courseList}>
          {
            listData?
            listData.map((broadcast) => {
              return (
                <Box key={broadcast.id} onClick={() => {handleClick(broadcast.id || '')}}>
                  <ClassroomListCard
                    card={broadcast}
                    label={'Live'}
                  />
                </Box>
              )
            }):
            ''
          }
        </Box>
        {
          isShowPagination?
          <ListPagination
            currentPage={currentPage} 
            hasMore={hasMore} 
            rowsPerPage={5}
            maxPage={maxPage}
            totalCount={totalCount}
            onClickPage={handleOnClickPage}
            onClickPrev={handleOnClickPrev} 
            onClickNext={handleOnClickNext}
          />:
          ''
        }
      </Box>
    </>
  )
}