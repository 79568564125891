import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'


export const watchPageApi = createApi({
  reducerPath: 'watchPageApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      // You can replace any with your type
      getSignedUrl: builder.mutation<Result<any>, { id: string, classType: number }>({
        query: ({ id, classType }) => ({
            url: `getsignedurl`,
            method: 'POST',
            body: {
              class_type: classType,
              class_id: id
            }
        }),
        //  You can define your response type
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      updateBroadcastSave: builder.mutation<Result<any>, { 
        broadcastId: string,
        // 1-save, 2-cancel save 
        type: number
      }>({
        query: ({ broadcastId, type }) => ({
            url: `broadcastfavorite`,
            method: 'POST',
            body: {
              broadcast_id: broadcastId, 
              type: type,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      addFollow: builder.mutation<Result<any>, {
        followerId: string, 
        teacherId: string 
      }>({
        query: ({ followerId, teacherId }) => ({
            url: `addfollow`,
            method: 'POST',
            body: {
              follower_user_id: followerId, 
              user_id: teacherId,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      deleteFollow: builder.mutation<Result<any>, {
        followerId: string, 
        teacherId: string 
      }>({
        query: ({ followerId, teacherId }) => ({
            url: `deletefollow`,
            method: 'POST',
            body: {
              follower_user_id: followerId, 
              user_id: teacherId,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getRelatedBroadcastList: builder.query<Result<any>, {
        teacherId: string
      }>({
        query: ({ teacherId }) => ({
            url: `getbroadcastlist`,
            method: 'POST',
            body: {
              user_id: teacherId,
              page_size: 10,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getUserProfile: builder.mutation<Result<any>, {
        userId: string,
        teacherId: string
      }>({
        query: ({ userId, teacherId }) => ({
            url: `getuserprofile`,
            method: 'POST',
            body: {
              user_id: teacherId,
              request_user_id: userId
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getLiveInfo: builder.query<Result<any>, {
        startKey?: string,
        pageSize?: string,
        forwardOrder?: boolean
      }>({
        query: ({ startKey, pageSize, forwardOrder }) => ({
          url: `getliveinfo`,
          method: 'POST',
          body: {
            start_key: startKey ?? '',
            page_size: pageSize ?? 10,
            forward_order: forwardOrder ?? false
            }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      }),
      startThirdPartyBroadcast: builder.mutation<Result<any>, {
        broadcastID: string
      }>({
        query: ({ broadcastID }) => ({
            url: `updatebroadcast`,
            method: 'POST',
            body: {
              broadcast_id: broadcastID,
              enable_playback: true,
              type: 1,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      closeBroadcast: builder.mutation<Result<any>, {
        broadcastID: string
      }>({
        query: ({ broadcastID }) => ({
            url: `closebroadcast`,
            method: 'POST',
            body: {
              broadcast_id: broadcastID,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { 
  useGetSignedUrlMutation,
  useUpdateBroadcastSaveMutation,
  useAddFollowMutation, 
  useDeleteFollowMutation, 
  useGetLiveInfoQuery,
  useGetRelatedBroadcastListQuery, 
  useGetUserProfileMutation,
  useStartThirdPartyBroadcastMutation,
  useCloseBroadcastMutation,
 } = watchPageApi
