import * as React from 'react'
import { useState} from 'react'
import { Box, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CourseEntity, SeriesEntity, SeriesTeacherEntity } from '@dokyo/common'
import { CourseInfoSilder } from './CourseInfoSilder'
import { CardTypeEnum, CourseInfoCard } from './card/CourseInfoCard'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import { getDisplayUserName } from 'src/utils/stringUtil'
import { useGetSeriesByTeacherQuery } from 'src/app/service/real/teacher.slice'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    padding: "40px 0",
    // background: '#F9F9F9',
  },
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '0px 50px 0px 50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  title: {
    marginBottom: '33px',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Avenir-Black, Avenir',
  },  
  card: {
    padding: "0px 5px"
  },
})
)
interface Props {
  courseId: string,
  teacher: SeriesTeacherEntity|undefined,
}

export const OtherCourses: React.FC<Props> = ({courseId, teacher}) => {
  const classes=useStyles()
  const navigate = useNavigate();
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  // const [seriesList, setSeriesList] = React.useState<SeriesEntity[]>([]);
  const [listData, setListData] = useState<ClassroomListCardEntity[]>([]);
  const {data: seriesRes} = useGetSeriesByTeacherQuery({
    teacher_id: teacher?.teacher_id||"",
    page_size: 16,
  })


  React.useEffect(()=> {
    if (seriesRes && !seriesRes.isFailed && seriesRes.result) {
      const seriesData : SeriesEntity[] = seriesRes.result.series_list;
      if (seriesData && seriesData.length > 0) {
        // setSeriesList(seriesData);
        const transferList = getClassCardList(seriesData, courseId);
        setListData(transferList);
      }
    }
  }, [seriesRes])

  const getClassCardList = (list: SeriesEntity[], courseId: string): ClassroomListCardEntity[] => {
    const classRoomDataList: ClassroomListCardEntity[] = [] 
    
    list.forEach(learnPage => {
      const teachers : SeriesTeacherEntity[] = learnPage.series_teachers;
      const teacher : SeriesTeacherEntity|undefined = teachers?.length > 0 ? teachers[0] : undefined;
      if (learnPage && learnPage.series_id && learnPage.series_id !== courseId) {
        classRoomDataList.push({
          id: learnPage.series_id,
          media_image: learnPage.series_thumbnail,
          class: learnPage.series_category.name,
          content_header: learnPage.series_title,
          time: learnPage.course_purchased_time,
          fees: learnPage.series_fees,
          memberFees: learnPage.series_membership_fees,
          type: CardTypeEnum.Course,
          teacherId: teacher?.teacher_id || '',
          teacherName: teacher?.teacher_name || '',
          teacherAvatar: teacher?.teacher_avatar || '',
          videoClassCount: learnPage.series_course_count ?? 0,
        })
      }
    })
    return classRoomDataList
  }


  return (
    <>
    {listData?.length > 0 ?
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.title}>
          {`Other Courses By ${getDisplayUserName(teacher?.teacher_name||"", teacher?.teacher_id||"")}`}
        </Box>
        <CourseInfoSilder arrowTop={65} maxShowNumber={4} itemWidth={350} marginCount={300}>
          {
            listData.map((item => {
              return (
                <Box key={item.id} className={classes.card}>
                  <CourseInfoCard
                    bgTransparent={false}
                    showLabel={false}
                    card={item}
                    enable={true}
                    showTime={false}
                  />
                </Box>
              )
            }))
          }
          </CourseInfoSilder>
        </Box>
      </Box>
      :
      ""
    }
  </>  
  )
}