// export interface ChatDataMessage {
//   message: string;
//   senderAttendeeId: string;
//   timestamp: number;
//   senderName: string;
//   isSelf: boolean;
// }

import { FileStatus } from "@dokyo/common";
import { ChatMessage } from "src/app/models";

export interface State {
  messages: ChatMessage[];
  onChatTab: boolean;
  unReadMessageCount: number;
}

export enum DataMessagesActionType {
  ADD,
  ChangeTab
}

export interface AddAction {
  type: DataMessagesActionType.ADD;
  payload: ChatMessage;
}

export interface ChangeTabAction {
  type: DataMessagesActionType.ChangeTab;
  payload: boolean;
}

export const initialState: State = {
  messages: [],
  onChatTab: false,
  unReadMessageCount: 0
};

export type Action = 
  | AddAction 
  | ChangeTabAction;

export function reducer(state: State, action: Action): State {
  const { type, payload } = action;
  let newState: State;
  switch (type) {
    case DataMessagesActionType.ADD:
      const message = payload as ChatMessage;
      const findMessage = state.messages.find(item => (item.id === message.id && item.type === "file" && message.status === FileStatus.UPLOADED));
      
      if (findMessage) {
        findMessage.status = FileStatus.UPLOADED;
        newState = { ...state, messages: [...state.messages] };
      } else {
        newState = { ...state, messages: [...state.messages, message] };
      }
      if (!state.onChatTab) {
        newState.unReadMessageCount++;
      }
      return newState;
    case DataMessagesActionType.ChangeTab:
      const onChatTab = payload as boolean;
      newState = { ...state, onChatTab };
      if (onChatTab) {
        newState.unReadMessageCount = 0;
      } 
      return newState;
    default:
      throw new Error('Incorrect action in DataMessagesProvider reducer');
  }
}


export const DATA_MESSAGE_LIFETIME_MS = 300000;
export const DATA_MESSAGE_TOPIC = 'VeekoDataMessage';
