import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TeacherMeetingInfo from './TeacherMeetingInfo'
import Loader from 'src/components/loader'
import { AddToCartComponent } from 'src/components/addToCart'
import { useSnackbar } from 'notistack'
import { createEmptyVideoClassroomEntity, SeriesEntity, VideoClassroomEntity } from '@dokyo/common'
import { useGetSeriesInfoMutation, useLazyGetLearnInfoQuery } from 'src/app/service/real/coursedetail-slice'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, Theme } from '@mui/system'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: "0 auto",
    minHeight: 'calc(100% - 98px)',
    paddingTop: '34px',
    [theme.breakpoints.down('md')]: {
      padding: "15px 0 0 0"
    }
  },
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
}))

const useMobileStyles = makeStyles(() => ({
  rootPage: {
    backgroundColor: '#FFFFFF',
    margin: "30px 10px",
  },
}))

interface Props {
  isTeacherPage: boolean
  videoId: string
  paramSeriesInfo: SeriesEntity|null; 
  meetingList: any[]
}

const VideoClassroomDetailTab: React.FC<Props> = ({isTeacherPage, videoId, paramSeriesInfo, meetingList}) => {
  const classes = useStyles();
  const classesMobile = useMobileStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [meetingInfo, setMeetingInfo] = useState<VideoClassroomEntity>(createEmptyVideoClassroomEntity());
  const [seriesInfo, setSeriesInfo] = useState<SeriesEntity|null>(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [getSeriesInfo] = useGetSeriesInfoMutation();
  const [getLearnInfo, { data: learnInfoRes, isFetching: learnInfoIsFetching} ] = useLazyGetLearnInfoQuery()

  useEffect(() => {
    if (paramSeriesInfo?.series_id) {
      setSeriesInfo(paramSeriesInfo)
    }
    //eslint-disable-next-line
  }, [paramSeriesInfo])

  useEffect(() => {
    if (videoId) {
      getLearnInfo({courseId: videoId, type: 2})
    }
    //eslint-disable-next-line
  }, [videoId])

  useEffect(() => {
    if (learnInfoRes && !learnInfoRes.isFailed && learnInfoRes.result) {
      const info = learnInfoRes.result;
      if (info) {
        console.log('info', info);
        console.log('info.meeting_purchased', info.meeting_purchased);
        setMeetingInfo({
          meetingId: info.meeting_info?.meeting_id || videoId,
          meetingTitle: info.meeting_title,
          meetingFees: info.meeting_fees,
          meetingMemberFees: info.meeting_member_fees,
          meetingStartTime: info.meeting_start_time || info.meeting_created_time,
          meetingCategoryId: info.category?.id,
          meetingCategoryName: info.category?.name,
          meetingCategoryIcon: info.category?.icon,
          meetingThumbnail: info.meeting_thumbnail,
          meetingSeries: info.meeting_series,
          teacherId: info.teacher?.user_id,
          teacherName: info.teacher?.user_name,
          teacherAvatar: info.teacher?.user_avatar,
          meetingPurchased: info.meeting_purchased,
          schoolId: info.school_id,
          status: info.status,
        });
        console.log('meetingInfo', meetingInfo);

        if (!paramSeriesInfo && info.meeting_series?.series_id) {
          getSeriesInfo({
            seriesId: info.meeting_series.series_id
          }).unwrap()
          .then(seriesData => {
            if (!seriesData || seriesData.isFailed || !seriesData.result) {
              return
            }
            setSeriesInfo(seriesData.result)  
          })
        }

      }
    } else if (learnInfoRes) {
      enqueueSnackbar(learnInfoRes.message)
    }
    // eslint-disable-next-line
  }, [learnInfoRes])

  return (
    <Box className={classes.root}>

      <Box className={classes.container}>
        <TeacherMeetingInfo 
          isTeacherPage={isTeacherPage}
          learnInfo={meetingInfo} 
          seriesInfo={seriesInfo || paramSeriesInfo}
          meetingList={meetingList}/>
      </Box>
      
      <Loader isLoading={learnInfoIsFetching}/>
    </Box>
  )
}

export default VideoClassroomDetailTab