import * as React from 'react'
import { Box, Typography, Button, Theme, Avatar } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TeacherAbout } from '../teacher/teacherAbout'
import { styled } from '@mui/system'
import { SeriesPreRecorded } from './seriesPreRecorded'
// import { SeriesAssignment } from './seriesAssignment';
import { useSelector } from 'src/app/toolkit/store'
import TeacherDiscussion from '../teacher/teacherDiscussion';
import { PageBackButton } from 'src/components/pageBackButton'
import Classroom from '../teacher/classroom'
import { useGetSeriesBroadcastListQuery, useQuerySeriesInfoQuery } from 'src/app/service/real/coursedetail-slice';
import { AssignmentEntity, BroadcastEntity, CourseEntity, Result, SeriesEntity, SeriesTeacherEntity, useQuery, VideoClassroomEntity } from '@dokyo/common';
import { getFormatDisplayTime } from 'src/utils/timeUtil';
import {
  useGetAssignmentQuery,
  // useGetIsGoogleAuthMutation, 
  // useSubmitGoogleAuthMutation 
} from 'src/app/service/real/assignment-slice';
import ClassroomMaterial from './material'
// import { useDispatch } from 'react-redux';
import SeriesTeachers from './SeriesTeachers';
import { TeacherLive } from '../teacher/teacherLive';
import SvgComponent from 'src/components/svgComponent'
import { useTranslation } from 'react-i18next'
import { copyContent } from '../broadcastLive/LiveInfo';
import { appConfig, buildTeacherUrl } from 'src/app-config';
import Loader from 'src/components/loader';
import { makeStyles } from '@mui/styles'
import { useVisitSeriesMutation } from 'src/app/service/real/coursedetail-slice'
import { getAuthStatus } from 'src/utils/auth';
import { useSearchParam } from 'react-use';
// import { useNavigate } from 'react-router-dom'
// import { useGetSchoolTeacherListMutation } from 'src/app/service/real/school-slice';
import KeepAlive, { useAliveController } from 'react-activation'
import { AVATAR_DEFAULT, isMobileDevice } from 'src/utils/deviceUtil';
import { Helmet } from 'react-helmet';
import { getDisplayUserName } from 'src/utils/stringUtil';
import { AddToCartComponent } from 'src/components/addToCart';
import { useGetProfileMutation } from 'src/app/service/real/aws-api';
import { RawUser } from 'src/app/models';
import { useSnackbar } from 'notistack';
import ExpandableText from 'src/components/ExpandableText/ExpandableText';
import { useListSeriesMaterialsMutation, useLockSeriesMaterialMutation } from 'src/app/service/real/teacher.slice';
import { CourseInfo } from './courseInfo/CourseInfo';
import { ClassroomMaterialCardEntity } from 'src/components/cards/ClassroomMaterialCard';
import { useNavigate } from 'react-router-dom';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: "90%",
    maxWidth: '1400px',
    margin: '0 auto',
    textAlign: 'center',
    padding: '0px 50px 0px 50px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  icoTopBox: {
    position: "fixed",
    cursor: "pointer !important",
    zIndex: 99,
    background: "#555555 !important",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
    width: "40px !important",
    height: "40px !important",
    borderRadius: "20px !important"
  },
  iconTop: {
    width: "40px",
    height: "40px",
    padding: "14px 10px 10px 10px",
  },
  courseInfoBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "30px 0",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  courseImage: {
    width: '426px',
    height: '240px',
    objectFit: 'cover',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    }
  },
  courseIntroBox: {
    maxWidth: '60%',
    marginLeft: '20px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin:"15px 5px 0",
      borderBottom:"1px solid #E9E9E9"
    }
  },
  courseTitle: {
    marginTop: '40px !important',
    fontSize: '40px !important',
    fontFamily: 'Arial-BoldMT, Arial !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 1,
    WebkitLineClamp: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px !important',
      overflow:"visible",
      whiteSpace: "normal",
      marginTop: '25px !important',
      fontWeight: '600'
    }
  },
  courseIntroduction: {
    marginTop: '10px !important',
    minHeight: '20px !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Medium, Avenir !important',
    color: '#555555 !important',
    lineHeight: '20px !important',
    maxHeight: "40px !important",
    height: "40px !important",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    lineClamp: 2,
    WebkitLineClamp: 2,
  },
  courseItemTitle: {
    height: '28px',
    fontSize: '14px !important',
    fontFamily: 'Arial !important',
    color: '#555555 !important',
    lineHeight: '28px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 1,
    WebkitLineClamp: 1,
  },
  courseItemValue: {
    height: '28px',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Medium, Avenir !important',
    fontWeight: '500 !important',
    color: '#000000 !important',
    lineHeight: '28px !important',
    marginRight: '50px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 1,
    WebkitLineClamp: 1,
  },
  TabBox: {
    display: "flex",
    overflowX: "scroll",
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  teacherBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    cursor: 'pointer',
    width: 'fit-content',
  },
  teacherAvatar: {
    width: '32px !important',
    height: '32px !important',
  },
  teacherName: {
    marginLeft: '10px',
    color: 'black',
    fontSize: '14px',
    fontFamily: 'Avenir-Medium, Avenir',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const SecondTab = styled(Tab)({
  marginRight: '22px',
  color: 'black',
  background: 'white',
  height: '40px !important',
  minHeight: '40px !important',
  borderRadius: '20px',
  border: '1px solid #DDDDDD !important',
  fontSize: '12px !important',
  textTransform: 'none',
  fontFamily: 'Arial-BoldMT, Arial !important',
  '&.Mui-selected': {
    color: 'white',
    background: '#307DCF',
    border: 'none !important',
  }
})


export const SecondTabs = styled(Tabs)({
  overflow: "unset",
  width: "max-content",
  '& .MuiTabs-scroller': {
    display: "flex",
    overflowX: "auto",
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  '& .MuiTabs-flexContainer': {
    display: "flex",
    overflowX: "auto",
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgb(0,0,0)',
  }
})

export const CustomIconButton = styled(Button)({
  position: 'absolute',
  bottom: isMobileDevice() ? "20px" : "30px",
  left: isMobileDevice() ? "20px" : "50px",
  // width: "102px",
  height: "36px",
  textTransform: "none",
  color: "#000000",
  fontSize: "14px",
  fontFamily: "ArialMT",
  background: "white",
  opacity: 0.7,
  borderRadius: "6px",
  paddingLeft: "8px",
  '& .MuiButton-startIcon': {
    marginLeft: "0px",
  },
  '&:hover': {
    opacity: 1,
    background: "white",
  }
})

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  );
}

export const scrollToAnchor = (anchorName) => {
  if (anchorName) {
    const anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }
}

export interface LiveInfo {
  liveNow: BroadcastEntity[],
  upcoming: BroadcastEntity[],
  ended: BroadcastEntity[]
}


export const TAB_VALUES = {
  COURSE_INFO: 0,
  RECORDED_CLASS: 1,
  LIVE_VIDEO_CLASS: 2,
  LIVE_STREAM: 3,
  // GOOGLE_CLASSROOM: 4,
  CLASSROOM_MATERIAL: 4,
  TEACHERS: 5,
  DISCUSSION: 6,
}

const Course: React.FC = () => {
  const authStatus = getAuthStatus()();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // const authCode = query.get('code')?.toString()
  // const authScope = query.get('scope')?.toString()  
  const dataID = useSearchParam('id');
  const tabValue = useSearchParam('tabValue');
  const purchaseNow = useSearchParam('purchase');
  const DEFAULT_TAB_VALUE = TAB_VALUES.RECORDED_CLASS;
  const [value, setValue] = React.useState<number>(Number(tabValue) ?? DEFAULT_TAB_VALUE);
  const [liveVideoClassID, setLiveVideoClassID] = React.useState('')
  const [liveList, setLiveList] = React.useState<LiveInfo>({
    liveNow: [],
    upcoming: [],
    ended: []
  });
  const [showMaterialTab, setShowMaterialTab] = React.useState(false);
  // const navigate = useNavigate()
  const [meeting_list, setMeetingList] = React.useState<any[]>([]);
  const [recordedList, setRecordedList] = React.useState<CourseEntity[]>([]);
  const [materialList, setMaterialList] = React.useState<ClassroomMaterialCardEntity[]>([])
  const [enableDownloadMaterial, setEnableDownloadMaterial] = React.useState(false);
  // const [isGoogleAuth, setIsGoogleAuth] = React.useState(false);
  const [seriesPurchased, setSeriesPurchased] = React.useState(false);

  // const { courseTab } = useSelector(state => state.courseInfo);

  const { credential } = useSelector(state => state.app)
  const [isSeriesTeacher, setIsSeriesTeacher] = React.useState(false)
  const [seriesTeachers, setSeriesTeachers] = React.useState<SeriesTeacherEntity[]>([]);
  const [seriesInfo, setSeriesInfo] = React.useState<SeriesEntity | null>(null);
  const [assignmentList, setAssignmentList] = React.useState<AssignmentEntity[]>([]);
  // const [showPremuimMemberChoiceDialog, setShowPremiumMemberChoiceDialog] = React.useState(false);

  const { data: liveRes, isLoading: loading1 } = useGetSeriesBroadcastListQuery({ seriesId: dataID || "", liveNow: true, upcoming: true, ended: true });
  const { data: seriesRes, isLoading: loading2 } = useQuerySeriesInfoQuery({ seriesId: dataID || "" })
  const { data: assignmentRes, isLoading: loading3 } = useGetAssignmentQuery({ seriesId: dataID || "" });
  const [listSeriesMaterials] = useListSeriesMaterialsMutation();
  const [lockMaterial, {isLoading: loadingLock}] = useLockSeriesMaterialMutation();

  // const [getGoogleAuthInfo] = useGetIsGoogleAuthMutation();
  // const [submitGoogleAuth] = useSubmitGoogleAuthMutation();
  const [visitSeries] = useVisitSeriesMutation()
  const isMobile = isMobileDevice()


  React.useEffect(() => {
    if (purchaseNow === '1') {
      if (!credential) {
        dispatch(showSignInUpDialog(true));
      } else {
        setTimeout(() => {
          const buttonPurchase = document.getElementById('button-purchase');
          if (buttonPurchase) {
            buttonPurchase.click();
          }
        }, 1000);
      }
    }
  }, [purchaseNow, credential])

  React.useEffect(() => {
    if (dataID) {
      getSeriesMaterial();
    }
  }, [dataID, credential])

  React.useEffect(() => {
    const findTeacher = seriesInfo?.series_teachers.some(item => item.teacher_id === credential?.id);
    setIsSeriesTeacher(!!findTeacher);

    if (materialList?.length > 0) {
      setShowMaterialTab(true);
    } else {
      if (findTeacher) {
        setShowMaterialTab(true);
      } else {
        setShowMaterialTab(false);
      }
    }
  }, [seriesInfo, materialList, credential])


  React.useEffect(() => {
    if (authStatus.isLogin && dataID) {
      visitSeries({ seriesId: dataID })
    }
  }, [credential]);

  React.useEffect(() => {
    const tab = Number(tabValue);
    if (value !== tab) {
      setValue(tab > 0 ? tab : DEFAULT_TAB_VALUE);
    }
  }, [tabValue])

  // React.useEffect(() => {
  //   if (authCode && authScope) {
  //     const callbackUrl = window.location.origin + appConfig.paths.course
  //     submitGoogleAuth({
  //       code: authCode,
  //       scope: decodeURI(authScope),
  //       callbackUrl
  //     })
  //     .unwrap()
  //     .then(res => {
  //       if (res && !res.isFailed) {
  //         setIsGoogleAuth(true);
  //         setValue(TAB_VALUES.GOOGLE_CLASSROOM);
  //         const oauthCallbackUrl = localStorage.getItem('oauthCallbackUrl');
  //         if (oauthCallbackUrl) {
  //           window.location.href = oauthCallbackUrl;
  //         }
  //       }
  //     })
  //   }
  // },[authCode, authScope])

  React.useEffect(() => {
    handleCourseAndMeetingList(seriesRes)
  }, [seriesRes]);

  React.useEffect(() => {
    handleLiveSections(liveRes)
  }, [liveRes]);

  // React.useEffect(() => {
  //   handleAssignment(assignmentRes);
  // }, [assignmentRes]);

  // const handleAssignment = (res: Result<any>|undefined) => {
  //   if (res && !res.isFailed && res.result) {
  //     console.log('getAssignmentInfo', res);
  //     if (res.result?.assignment_list?.length > 0) {
  //       const assignment = res.result.assignment_list[0];
  //       setAssignmentList([{
  //         classroom_id: assignment.classroom_id,
  //         title: assignment.title,
  //         thumbnail: assignment.thumbnail,
  //         introduction: assignment.introduction,
  //         link: assignment.link,
  //         series_id: assignment.series_id,
  //       }])
  //       requestGoogleAuth();
  //     }
  //   }
  // }

  // const requestGoogleAuth = () => {
  //   getGoogleAuthInfo({})
  //   .unwrap()
  //   .then(authData => {
  //     if (authData && !authData.isFailed) {
  //       if (authData.result?.validate) {
  //         setIsGoogleAuth(true);
  //       } else {
  //         setIsGoogleAuth(false);
  //       }
  //     }
  //   })
  // }

  const getSeriesMaterial = () => {
    if (!dataID) {
      return;
    }
    listSeriesMaterials({ series_id: dataID, page_size: 10, start_key: '' })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed && res.result) {
          const list = res.result.series_materials_list;
          setEnableDownloadMaterial(res.result.download);
          setMaterialList(list);
        }
      })
  }

  const handleLock = async (name:string, lock: boolean): Promise<void> => {
    try {
      const response = await lockMaterial({series_id : dataID||'', file_name : name, lock: lock}).unwrap();
      if (response?.isFailed === false) {
        enqueueSnackbar(lock ? 'Lock Successfully': 'Unlock Successfully!', {variant:'success'});
        // refresh list
        getSeriesMaterial();
      } else {
        enqueueSnackbar('Operation failed! ' + response.message);
      }
    } catch(error) {
      enqueueSnackbar('Operation failed!');
      console.log(error);
    }
  }

  const handleCourseAndMeetingList = (seriesData: Result<any> | undefined) => {
    if (!seriesData || seriesData.isFailed || !seriesData.result) {
      return
    }
    setSeriesInfo(seriesData.result);

    setSeriesPurchased(seriesData.result.course_purchased);
    setSeriesTeachers(seriesData?.result?.series_teachers);

    const courseList = seriesData.result.series_course_list;
    if (courseList && courseList.length > 0) {
      setRecordedList(courseList);
    }

    const meetingList = seriesData.result.series_meeting_list;
    if (meetingList && meetingList.length > 0) {
      setMeetingList(meetingList);

      const liveVideoClassID = findTimeClosestClassroomID(meetingList);
      setLiveVideoClassID(liveVideoClassID)
    }

  }

  const handleLiveSections = (res: Result<any> | undefined) => {
    if (res && !res.isFailed && res.result) {
      const liveNowList = res.result.live_now;
      const upcomingList = res.result.upcoming;
      const endedList = res.result.ended;
      if (liveNowList?.length > 0 || upcomingList?.length > 0) {
        setLiveList({
          liveNow: liveNowList,
          upcoming: upcomingList,
          ended: endedList,
        })
      }
    }
  }

  const findTimeClosestClassroomID = (totalList: any[]) => {
    if (!totalList || totalList.length === 0) {
      return "";
    }
    const meetingList = totalList.filter(item => item.status !== 3);
    if (meetingList.length === 0) {
      return "";
    }
    if (meetingList[0].status === 2) {
      return meetingList[0].meeting_info?.meeting_id;
    }
    const nowTimeSeconds = new Date().getTime() / 1000;
    const meetingTime0 = meetingList[0].meeting_start_time || meetingList[0].meeting_created_time;
    let findTimeInterval = Math.abs(meetingTime0 - nowTimeSeconds);
    let findMeeting = meetingList[0];

    meetingList.forEach(item => {
      const itemTime = item.meeting_start_time || item.meeting_created_time;
      const timeInterval = Math.abs(itemTime - nowTimeSeconds);
      console.log("meeting: " + item.meeting_title + ", time: ", getFormatDisplayTime(itemTime * 1000) + ", time interval: " + timeInterval)
      if (timeInterval < findTimeInterval) {
        findMeeting = item;
        findTimeInterval = timeInterval;
      }
    })
    return findMeeting.meeting_info?.meeting_id;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleSwitchTab(newValue);
  };

  const handleSwitchTab = (newValue: number) => {
    setValue(newValue);
    window.history.pushState({}, "", `${appConfig.paths.course}?id=${dataID}&tabValue=${newValue}`);
  }

  const handleClickCopyLink = () => {
    const href = `${appConfig.paths.course}?id=${dataID}`
    const link = `${window.location.protocol}//${window.location.host}${href}`
    copyContent(link);
  }

  const getSeriesTeacherNames = (teachers: any[]) => {
    if (!teachers || teachers.length === 0) {
      return "";
    }
    let names = "";
    teachers.forEach(item => {
      if (item && item.teacher_id) {
        names = names + getDisplayUserName(item.teacher_name, item.teacher_id) + ", ";
      }
    })
    names = names.substring(0, names.length - 2);
    return names;
  }

  const handleClickTeacher = (id: string) => {
    if (id) {
      const teacherUrl = buildTeacherUrl(id);
      navigate(teacherUrl);
    }
  }

  return (
    <>
      <Helmet>
        {/*Google Tag Manager*/}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KX6N85S');`}</script>
        {/*End Google Tag Manager*/}
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KX6N85S"
          title='Google Tag Manager'
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      {/* This is used for scroll to top */}
      <Box className={classes.icoTopBox} onClick={() => { scrollToAnchor("page-top") }}
        sx={isMobile ? { right: "20px", bottom: "20px" } : { right: "60px", bottom: "100px", }}>
        <SvgComponent iconName="icon-scroll-top" className={classes.iconTop} />
      </Box>

      <Box className={classes.root}>
        <Box className={classes.container}>
          <Box sx={{ margin: '35px 0' }} >
            <PageBackButton />
            <Box>
              <Typography className={classes.courseTitle}>{seriesInfo?.series_title || ''}</Typography>
              <Box className={classes.courseInfoBox}>
                <img alt='course_image' className={classes.courseImage} src={seriesInfo?.series_thumbnail} />
                <Box className={classes.courseIntroBox}>
                  <Box className={classes.teacherBox} onClick={() => handleClickTeacher(seriesTeachers?.length > 0 ? seriesTeachers[0].teacher_id : '')}>
                    <Avatar src={seriesTeachers?.length > 0 ? (seriesTeachers[0].teacher_avatar || AVATAR_DEFAULT) : AVATAR_DEFAULT} className={classes.teacherAvatar}></Avatar>
                    <Box className={classes.teacherName}>{seriesTeachers?.length > 0 ? getDisplayUserName(seriesTeachers[0].teacher_name || "", seriesTeachers[0].teacher_id || "") : ""}</Box>
                  </Box>
                  {/* <Box style={{marginTop:'-5px', marginBottom:'5px', minHeight: '50px'}}>
                    <ExpandableText maxLines={1} text={seriesInfo?.series_introduction||''} textId={seriesInfo?.series_id||liveVideoClassID}/>
                  </Box> */}
                  <Box className={classes.courseItemTitle}>
                    <Typography component='span' className={classes.courseItemValue}>{seriesInfo?.series_category.name}</Typography>
                    {/* School:&nbsp;<Typography component='span' className={classes.courseItemValue}>{seriesInfo?.series_school_name||'Unknown'}</Typography> */}
                  </Box>
                  <Typography className={classes.courseIntroduction}>{seriesInfo?.series_introduction || ''}</Typography>

                  {/* <Box className={classes.courseItemTitle}>
                    Teacher:&nbsp;<Typography component='span' className={classes.courseItemValue}>{getSeriesTeacherNames(seriesInfo?.series_teachers||[])}</Typography>
                  </Box> */}

                  <AddToCartComponent
                    purchasedSeparately={seriesInfo?.course_purchased}
                    price={seriesInfo?.series_fees || 0}
                    memberPrice={seriesInfo?.series_membership_fees || 0}
                    seriesInfo={seriesInfo}
                    UIStyle={1}
                  />
                </Box>
              </Box>

              <Box className={classes.TabBox}>
                <SecondTabs value={value} onChange={handleChange} TabIndicatorProps={{ hidden: true }} >
                  <SecondTab label="Course Info" value={TAB_VALUES.COURSE_INFO} />
                  <SecondTab label="Classes" value={TAB_VALUES.RECORDED_CLASS} />
                  {liveVideoClassID && <SecondTab label="Live Video Class" value={TAB_VALUES.LIVE_VIDEO_CLASS} />}
                  {(liveList.upcoming?.length !== 0 || liveList.liveNow?.length !== 0) &&
                    <SecondTab label="Live Stream" value={TAB_VALUES.LIVE_STREAM} />
                  }
                  {/* <SecondTab label="Google Classroom"  value={TAB_VALUES.GOOGLE_CLASSROOM}/> */}
                  {showMaterialTab && <SecondTab label="Course Material" value={TAB_VALUES.CLASSROOM_MATERIAL} />}
                  <SecondTab label="Teachers" value={TAB_VALUES.TEACHERS} />
                  <SecondTab label="Discussion" value={TAB_VALUES.DISCUSSION} />
                </SecondTabs>
              </Box>

            </Box>
          </Box>
        </Box>

        <Box>
          <TabPanel value={value} index={TAB_VALUES.COURSE_INFO}>
            <CourseInfo
              courseId={dataID || ""}
              teachers={seriesInfo?.series_teachers || []}
              seriesClassInfo={seriesInfo?.series_classinfo || seriesInfo?.series_introduction || ""}
              recordedList={recordedList}
              liveInfo={liveList}
              meetingList={meeting_list}
              materialList={materialList}
              enableDownloadMaterial={enableDownloadMaterial}
              onSwitchTab={handleSwitchTab}
              isSeriesTeacher={isSeriesTeacher}
              handleLock={handleLock}
            />
          </TabPanel>

          <TabPanel value={value} index={TAB_VALUES.RECORDED_CLASS}>
            <SeriesPreRecorded seriesId={seriesInfo?.series_id || ""} />
          </TabPanel>

          {liveVideoClassID &&
            <TabPanel value={value} index={TAB_VALUES.LIVE_VIDEO_CLASS}>
              <Classroom
                isTeacherPage={false}
                videoId={liveVideoClassID}
                paramSeriesInfo={seriesInfo}
                meetingList={meeting_list} />
            </TabPanel>
          }

          {(liveList.upcoming?.length !== 0 || liveList.liveNow?.length !== 0) &&
            <TabPanel value={value} index={TAB_VALUES.LIVE_STREAM}>
              <TeacherLive liveInfo={liveList} />
            </TabPanel>
          }

          {/* <TabPanel value={value} index={TAB_VALUES.GOOGLE_CLASSROOM}>
            <SeriesAssignment isPurchased={seriesPurchased} isAuth={isGoogleAuth} assignmentList={assignmentList} seriesInfo={seriesInfo}/>
          </TabPanel> */}

          {showMaterialTab &&
            <TabPanel value={value} index={TAB_VALUES.CLASSROOM_MATERIAL}>
              <ClassroomMaterial seriesID={dataID || ""} isSeriesTeacher={isSeriesTeacher} />
            </TabPanel>
          }

          <TabPanel value={value} index={TAB_VALUES.TEACHERS}>
            <SeriesTeachers schoolID={seriesInfo?.series_school_id || ""} seriesTeachers={seriesTeachers} />
          </TabPanel>

          <TabPanel value={value} index={TAB_VALUES.DISCUSSION}>
            <TeacherDiscussion resourceId={dataID || ""} />
          </TabPanel>
        </Box>

        {/* <SetPremiumMemberCourseDialog
          seriesId={seriesInfo?.series_id || ""}
          onCancel={() => { setShowPremiumMemberChoiceDialog(false) }}
          onSetSuccess={() => { setShowPremiumMemberChoiceDialog(false) }}
          open={showPremuimMemberChoiceDialog} /> */}

        <Loader isLoading={loading1 || loading2 || loading3 || loadingLock} />
      </Box>
    </>
  )
}


const KeepAliveCourse: React.FC = (props) => {
  const { refresh } = useAliveController();
  const query = useQuery();
  const seriesId = query.get("id");

  React.useEffect(() => {
    const lastSeriesId = sessionStorage.getItem("lastSeries");
    if (seriesId && seriesId !== lastSeriesId) {
      sessionStorage.setItem("lastSeries", seriesId);
      refresh("KeepAliveCourse");
    }
  }, [seriesId])

  return (
    <KeepAlive name="KeepAliveCourse">
      <Course {...props} />
    </KeepAlive>
  )
}
export default KeepAliveCourse