import React from 'react'
// import Typography from '@mui/material/Typography'
import { Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import noCourse from 'src/images/teacher/noCourse.png'
import { SeriesEntity } from '@dokyo/common'
import { SeriesSortByOrder } from 'src/utils/sort';
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass';
import { CourseInfoCard } from '../course/courseInfo/card/CourseInfoCard';
import { getClassCardEntity } from 'src/components/cardlist/SeriesList';
import quotes from 'src/images/teacher/ico-symbol@2x.png'


const useStyles = makeStyles((theme:Theme) => ({
  group: {
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexFlow:'wrap'
  },
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'left',
    padding: '0px 50px 50px 50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  title: {
    margin: "30px",
    fontSize: "30px",
    fontWeight: "600",
    fontFamily: "Arial ",
    color: "#000000",
    [theme.breakpoints.down('sm')]: {
      margin: "10px",
      fontSize: "30px",
      textAlign: "center"
    }
  },
  item: {
    margin: '0 24px 36px 0',
    width: '280px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 20px 0',
      width: '100%',
    }
  },
  describe: {
    margin: "30px",
    fontSize: "20px",
    fontFamily: "Arial ",
    color: "#000000",
    backgroundColor: "#F9F9F9",
    display: "flex",
    padding: "28px 37px",
    borderRadius: "12px",
    [theme.breakpoints.down('sm')]: {
      padding: "10px"
    }
  },
  CourseBox:{
    flex: '1 0 300px', 
    display: 'flex', 
    flexWrap: 'wrap', 
    margin: "30px",
    [theme.breakpoints.down('sm')]: {
      margin: "0",
      justifyContent:"center"
    }
  },
  Image: {
    width: "40px",
    height: "32px",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  },
  contentText: {
    fontSize: "18px !important",
    fontFamily: "AndaleMono !important",
    color: "#555555 !important",
    marginTop: "39px !important",
    [theme.breakpoints.down('sm')]: {
      marginTop: "0px !important",
    }
  }
}))

interface Props {
  seriesList: SeriesEntity[]
  loading: boolean
  teacherID: string
}


// function SeriesSort(seriesOne:SeriesEntity, seriesTwo: SeriesEntity)  {
//   if (seriesOne.series_order > seriesTwo.series_order){
//     return 1;
//   }

//   if (seriesOne.series_order < seriesTwo.series_order){
//     return -1;
//   }
//   return 0;
// }

export const TeacherSeries: React.FC<Props> = ({seriesList, loading, teacherID }) => {
  const classes = useStyles()
  const seriesMasterclassList: SeriesEntity[] = [];
  const seriesAfterschoolList: SeriesEntity[] = [];
  seriesList.forEach((series => {
    if (series.series_masterclass) {
      seriesMasterclassList.push(series);
    } else {
      seriesAfterschoolList.push(series);
    }
  }))

  return (
    <>
      <Box className={classes.container}>
        {
          seriesMasterclassList.length > 0 &&
          <>
            <Box className={classes.title}>
              Master Class
            </Box>
            <Box className={classes.CourseBox}>
              {
                seriesMasterclassList.sort(SeriesSortByOrder).map((series) => {
                  const card : ClassroomListCardEntity = getClassCardEntity(series);
                  return (
                    <div key={series.series_id} className={classes.item}>
                      <CourseInfoCard card={card} showTime={false}/>
                    </div>
                  )
                })
              }
            </Box>
            {(teacherID == "EricSilberger" || teacherID == "BelaHorvath" || teacherID == "IgorPikayzen" || teacherID == "SulimanTekali") && <Box className={classes.describe}>
              <img src={quotes} alt="symbol" className={classes.Image}></img>
              <Typography className={classes.contentText}>
                {teacherID == "EricSilberger" ? "Learning to play the violin online has never been easier with this online violin course designed and presented by expert violinist Eric SIlberger. Learn different techniques from beginner to advanced and truly cultivate your skills with the violin." : ""}
                {teacherID == "BelaHorvath" ? "Don’t limit yourself to studying the violin just once or twice a week in person with expensive classes. Sign up for our online violin course that’s been designed specifically for people who want to grow their violin skills as much as possible. With Bela’s expertly presented violin course, you don’t have to worry about trying to find a violin class near you, you can log in from anywhere in the world and take your online violin lessons at any time you choose!" : ""}
                {teacherID == "IgorPikayzen" ? "You may be struggling with finding a good violin course, wondering “Where is the best violin class near me?”. Veeko has good news! Thanks to our unique streaming platform, you don’t have to limit your search to your own zip code—you can take our expertly prepared online violin course from the comfort of your own home!" : ""}
                {teacherID == "SulimanTekali" ? "Have you exhausted your google search looking for “best violin classes near me”? Have you spent hours trying to find the right violin teacher at a price you can afford but come up short? Suliman Tekali’s expertly crafted online violin class will help you improve your skills and cultivate a love for playing the violin, all from the comfort of your own home. You don’t need to worry about driving to and from classes after a busy day in the office when you can log in to Veeko and learn anything from beginner violin classes to more advanced violin repertoire!" : ""}
              </Typography>
            </Box>}
          </>
        }
        {
          seriesAfterschoolList.length > 0 &&
          <>
            <Box className={classes.title}>
              Afterschool Course
            </Box>
            <Box className={classes.CourseBox}>
              {
                seriesAfterschoolList.sort(SeriesSortByOrder).map((series) => {
                  const card : ClassroomListCardEntity = getClassCardEntity(series);
                  return (
                    <div key={series.series_id} className={classes.item}>
                      <CourseInfoCard card={card} showTime={false}/>
                    </div>
                  )
                })
              }
            </Box>
            {(teacherID == "EricSilberger" || teacherID == "BelaHorvath" || teacherID == "IgorPikayzen" || teacherID == "SulimanTekali") && <Box className={classes.describe}>
              <img src={quotes} alt="symbol" className={classes.Image}></img>
              <Typography className={classes.contentText}>
                {teacherID == "EricSilberger" ? "Learning to play the violin online has never been easier with this online violin course designed and presented by expert violinist Eric SIlberger. Learn different techniques from beginner to advanced and truly cultivate your skills with the violin." : ""}
                {teacherID == "BelaHorvath" ? "Don’t limit yourself to studying the violin just once or twice a week in person with expensive classes. Sign up for our online violin course that’s been designed specifically for people who want to grow their violin skills as much as possible. With Bela’s expertly presented violin course, you don’t have to worry about trying to find a violin class near you, you can log in from anywhere in the world and take your online violin lessons at any time you choose!" : ""}
                {teacherID == "IgorPikayzen" ? "You may be struggling with finding a good violin course, wondering “Where is the best violin class near me?”. Veeko has good news! Thanks to our unique streaming platform, you don’t have to limit your search to your own zip code—you can take our expertly prepared online violin course from the comfort of your own home!" : ""}
                {teacherID == "SulimanTekali" ? "Have you exhausted your google search looking for “best violin classes near me”? Have you spent hours trying to find the right violin teacher at a price you can afford but come up short? Suliman Tekali’s expertly crafted online violin class will help you improve your skills and cultivate a love for playing the violin, all from the comfort of your own home. You don’t need to worry about driving to and from classes after a busy day in the office when you can log in to Veeko and learn anything from beginner violin classes to more advanced violin repertoire!" : ""}
              </Typography>
            </Box>}
            
          </>
        }
        {
          seriesAfterschoolList.length === 0 && seriesMasterclassList.length === 0 && !loading &&
          <Box className={classes.CourseBox} >
            <Box
              sx={{
                position: 'relative',
                left: '50%',
                top: '100px',
                height: '300px',
                width: '300px',
                marginLeft: '-150px',
                textAlign: 'center',
              }}
            >
              <img alt="teacher-course" src={noCourse}></img>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontFamily: 'ArialMT',
                  color: 'rgba(0, 0, 0, 1)',
                  lineHeight: '21px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginTop:'10px'
                }}
              >This teacher has no course yet.</Typography>
            </Box>
          </Box>
        }
      </Box>
    </>
  )
}
