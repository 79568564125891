import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settings } from '../settings'

const keys = settings.keys

interface AccessState {
  token?: string | null,
  refreshToken?: string | null,
}

function _initialState(): AccessState {
  return {
    token: localStorage.getItem(keys.accessToken),
    refreshToken: localStorage.getItem(keys.refreshToken),
  }
}

const accessSlice = createSlice({
  name: keys.accessToken,
  initialState: _initialState(),
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
      localStorage.setItem(keys.accessToken, action.payload)
    },
    clearToken(state) {
      state.token = null
      localStorage.removeItem(keys.accessToken)
    },
    setRefreshToken(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload
      localStorage.setItem(keys.refreshToken, action.payload)
    },
    clearRefreshToken(state) {
      state.refreshToken = null
      localStorage.removeItem(keys.refreshToken)
    }
  }
})

export const { setToken, clearToken, setRefreshToken, clearRefreshToken } = accessSlice.actions
export default accessSlice.reducer
