import React from 'react';
import { Box, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next'
import SvgComponent from 'src/components/svgComponent'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative"
  },
  expandedText: {
    fontSize: '14px',
    letterSpacing: '-0.34px',
    fontFamily: 'ArialMT',
    marginTop: '18px',
    color: "#555555",
    whiteSpace: "pre-wrap",
    height: "auto",
    lineClamp: "inherit",
    WebkitLineClamp: "inherit",
    [theme.breakpoints.down('sm')]: {
      height: "auto",
      lineClamp: "inherit",
      WebkitLineClamp: "inherit",
      width: '100%',
      fontFamily: 'Arial !important',
      fontStyle: 'normal',
      fontWeight: '400 !important',
      fontSize: '12px !important',
      lineHeight: '18px !important',
      letterSpacing: '-0.385882px !important',
      textTransform: 'capitalize',
      color: '#808080 !important',
    },
  },
  exTractText: {
    fontSize: '14px',
    letterSpacing: '-0.34px',
    fontFamily: 'ArialMT',
    marginTop: '18px',
    color: "#555555",
    whiteSpace: "pre-wrap",
    width: '100%',
    maxHeight: "150px",
    height: "fit-content",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontFamily: 'Arial !important',
      fontStyle: 'normal',
      fontWeight: '400 !important',
      fontSize: '12px !important',
      letterSpacing: '-0.385882px !important',
      textTransform: 'capitalize',
      color: '#808080 !important',
    },
  },
}))

interface Props {
  text: string
  textId: string
  maxLines?: number
}

const ExpandableText: React.FC<Props> = ({ text, textId, maxLines }) => {

  const classes = useStyles();
  const MAX_LINES = maxLines || 5;
  const { t } = useTranslation();
  const [expand, setExpand] = React.useState(false);
  const [hideExpand, setHideExpand] = React.useState(true);

  React.useEffect(() => {
    const hide = hideExpandButton();
    setHideExpand(hide);
  }, [text])

  const handleClickExpand = () => {
    setExpand(!expand);
  }

  const hideExpandButton = (): boolean => {
    const contentDiv = document.getElementById(`content-${textId}`);
    if (contentDiv) {
      const divHeight = contentDiv.offsetHeight;
      const lineHeight = parseInt(contentDiv.style.lineHeight);
      const lines = Math.ceil(divHeight / lineHeight);
      return lines < MAX_LINES;
    } else {
      return true;
    }

  }

  return (
    <Box className={classes.root}>
      <div
        id={`content-${textId}`}
        className={expand ? classes.expandedText : classes.exTractText}
        style={expand ? {lineHeight: '18px'} : {lineHeight: '18px', lineClamp: maxLines, WebkitLineClamp: maxLines}}
      >
        {text}
      </div>

      {!hideExpand &&
        <Button
          variant='text'
          style={{
            textTransform: "none",
            color: "rgba(43, 115, 200, 1)",
            paddingLeft: "0px",
            fontSize: "14px",
            fontFamily: "ArialMT",
            boxShadow: "none",
            textAlign: "left",
          }}
          endIcon={<SvgComponent iconName={expand ? "iconTextExtract" : "iconTextExpand"} className="icon-source" style={{ paddingTop: "2px", width: "18px", height: "14px" }} />}
          onClick={handleClickExpand}
        >
          {expand ? t("class.close-view-all") : t("class.view-all")}
        </Button>
      }

    </Box>
  )
}

export default ExpandableText