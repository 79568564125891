import React from 'react'
import { Box } from '@mui/material'
import { CourseEntity } from '@dokyo/common'
import { makeStyles } from '@mui/styles'
import { CourseCard } from 'src/components/cards'
import { Loader } from 'src/components/loader'
import SvgComponent from 'src/components/svgComponent'

export interface PropsCourseList {
  isLoading: boolean;
  courseList: CourseEntity[];
}

const useStyles = makeStyles(() => ({
  courseList: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  courseItem: {
    // margin: '0px',
    margin: '0 26px 26px 0'
  }
}))

const EmptyList: React.FC =() => {
  return (
    <Box
        sx={{
          display: 'flex',
          height: '400px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <SvgComponent  iconName={"courseIcon"} style={{ width: "48px", height: "44px"}}/>
        <Box
          sx={{
            mt: '20px'
          }}
          >
          No data yet.
        </Box>
      </Box>
  )
}

export const CourseList: React.FC<PropsCourseList> = ({ isLoading, courseList}) => {
  const classes = useStyles();
  return (
    <Box className={classes.courseList}>
      <Loader isLoading={isLoading} />
      {
        courseList && courseList.length > 0?
        courseList.map((course) => {
          return course? (
            <div key={course.course_id} className={classes.courseItem}>
              <CourseCard course={course} />
            </div>
          ): null;
        }):
        isLoading? '' : <EmptyList />
      }
    </Box>
  )
}