import { FC } from 'react';
import Box from '@mui/material/Box';
import DeviceSelection from '../../components/deviceSelection';
import MeetingJoinDetails from '../../components/meetingJoinDetails';
import { useTheme } from '@material-ui/core/styles';



const DeviceSetup: FC = () => {
    const theme = useTheme();
    return (
        <Box 
            sx={{
                minHeight: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: '1080px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    justifyContent: 'normal',
                }
            }}
        >
            
            <Box 
                sx={{
                    color: "#FFFFFF", 
                    fontWeight: "bold",
                    fontFamily: ' Avenir-Black, Avenir;',
                    fontSize: '40px',
                    mb: '63px',
                    [theme.breakpoints.down('sm')]: {
                        margin: '10px 0'
                    }
                }}
            >
                Device Settings
            </Box>
            <Box
                sx={{
                    background: '#313131',
                    boxShadow: '0px 5px 40px 0px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                    py: '40px',
                    px: '58px',
                    width: '1056px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        padding: '30px 15px',
                    }
                }}
            >
                <DeviceSelection />
                <MeetingJoinDetails />
            </Box>
        </Box>
    );
}


export default DeviceSetup;