// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import {
  useActiveSpeakersState,
  useAudioVideo,
  useContentShareState,
  useLocalVideo,
  useMeetingManager,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import {
  AudioVideoObserver,
  VideoSource,
  // MeetingSessionStatusCode,
} from 'amazon-chime-sdk-js';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Layout } from '../../types';
import { useMeetingState } from '../MeetingStateProvider';
import {
  Controls,
  initialState,
  reducer,
  State,
  VideoTileGridAction,
} from './state';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';


const VideoTileGridStateContext = createContext<State | undefined>(undefined);
const VideoTileGridControlContext = createContext<Controls | undefined>(undefined);

const VideoTileGridProvider: React.FC = ({ children }) => {
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();
  const activeSpeakers = useActiveSpeakersState();
  const { roster } = useRosterState();
  const { layout } = useMeetingState();
  const { isVideoEnabled } = useLocalVideo();
  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [alertDialogData, setAlertDialogData] = React.useState({
  //   showAlertDialog: false,
  //   alertDialogTitle: "",
  //   exitAfterClose: false,
  // });

  // const handleClickConfirmAlertDialog = () => {
  //   const shouldExit = alertDialogData.exitAfterClose;
  //   setAlertDialogData({
  //     showAlertDialog: false,
  //     alertDialogTitle: "",
  //     exitAfterClose: false,
  //   })
  //   shouldExit && onExitMeeting();
  // }


  useEffect(() => {
    dispatch({
      type: VideoTileGridAction.UpdateAttendeeStates,
      payload: { roster },
    });
  }, [roster]);

  useEffect(() => {
    dispatch({
      type: VideoTileGridAction.UpdateActiveSpeakers,
      payload: {
        activeSpeakers,
      },
    });
  }, [activeSpeakers]);

  useEffect(() => {
    console.log('audioVideo:', audioVideo);
    if (!audioVideo) {
      return;
    }

    const localAttendeeId =
      meetingManager.meetingSession?.configuration.credentials?.attendeeId ||
      null;

    const observer: AudioVideoObserver = {
      remoteVideoSourcesDidChange: (videoSources: VideoSource[]): void => {
        dispatch({
          type: VideoTileGridAction.UpdateVideoSources,
          payload: {
            videoSources,
            localAttendeeId,
          },
        });
      },
      // audioVideoDidStop: (sessionStatus) => {
      //   if (sessionStatus.statusCode() === MeetingSessionStatusCode.AudioJoinedFromAnotherDevice) {
      //     setAlertDialogData({
      //       showAlertDialog: true,
      //       alertDialogTitle: "Your account has joined in another place",
      //       exitAfterClose: true,
      //     })  
      //   } else if (sessionStatus.statusCode() === MeetingSessionStatusCode.MeetingEnded) {
      //     setAlertDialogData({
      //       showAlertDialog: true,
      //       alertDialogTitle: "This class has ended",
      //       exitAfterClose: true,
      //     })  
      //   }
      // },
      // connectionDidBecomePoor: () => {
      //   // enqueueSnackbar("Your connection is poor", {variant:'warning'})
      //   setAlertDialogData({
      //     showAlertDialog: true,
      //     alertDialogTitle: "Your connection is poor",
      //     exitAfterClose: false,
      //   })
      // },
      // connectionDidSuggestStopVideo: () => {
      //   const alertMessage = "Your internet connection is unstable";
      //   // enqueueSnackbar(alertMessage, {variant:'warning'})
      //   setAlertDialogData({
      //     showAlertDialog: true,
      //     alertDialogTitle: alertMessage,
      //     exitAfterClose: false,
      //   })
      // },
    };
    audioVideo.addObserver(observer);
    

    return (): void => audioVideo.removeObserver(observer);
  }, [audioVideo]);

  useEffect(() => {
    const localAttendeeId =
      meetingManager.meetingSession?.configuration.credentials?.attendeeId ||
      null;

    dispatch({
      type: VideoTileGridAction.UpdateLocalSourceState,
      payload: {
        isVideoEnabled,
        localAttendeeId,
        isLocalUserSharing,
        sharingAttendeeId,
      },
    });
  }, [
    isLocalUserSharing,
    isVideoEnabled,
    meetingManager.meetingSession,
    sharingAttendeeId,
  ]);

  useEffect(() => {
    dispatch({
      type: VideoTileGridAction.UpdateLayout,
      payload: {
        layout,
      },
    });
  }, [layout]);

  useEffect(() => {
    if (sharingAttendeeId && layout === Layout.Gallery) {
      // setLayout(Layout.Featured);
      dispatch({
        type: VideoTileGridAction.UpdateLayout,
        payload: {
          layout,
        },
      });
    }
  }, [sharingAttendeeId]);

  const zoomIn = (): void => dispatch({ type: VideoTileGridAction.ZoomIn });

  const zoomOut = (): void => dispatch({ type: VideoTileGridAction.ZoomOut });

  const controls: Controls = {
    zoomIn,
    zoomOut,
  };

  return (
    <VideoTileGridStateContext.Provider value={state}>
      <VideoTileGridControlContext.Provider value={controls}>
        {children}
      </VideoTileGridControlContext.Provider>
    </VideoTileGridStateContext.Provider>
  );
};

const useVideoTileGridState = (): State => {
  const state = useContext(VideoTileGridStateContext);

  if (!state) {
    throw new Error(
      'useVideoTileGridState must be used within a VideoTileGridProvider'
    );
  }

  return state;
};
const useVideoTileGridControl = (): Controls => {
  const context = useContext(VideoTileGridControlContext);

  if (!context) {
    throw new Error(
      'useVideoTileGridControl must be used within VideoTileGridProvider'
    );
  }
  return context;
};
export { VideoTileGridProvider, useVideoTileGridState, useVideoTileGridControl };
