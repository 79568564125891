import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AxiosRequestConfig } from 'axios';
import { getStoredAccessToken } from './localStorage';

export const axiosApi = (): AxiosInstance =>
  Axios.create({
    baseURL: process.env.REACT_APP_API_URL,//"https://rest-test.dev.veeko.com",
    headers: {
      ...axiosDefaultConfig().headers,
    },
  });
const axiosDefaultConfig = (): AxiosRequestConfig =>
  getStoredAccessToken()
    ? {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${getStoredAccessToken()}`,
        },
      }
    : {
        headers: {
          'Content-Type': 'application/json',
        },
      };
export const errorNotificationWith = ({
  message,
  description,
}: any): void => {
  //notification.error({
  //  message,
  //  description,
  //});
};

export const successNotificationWith = ({
  message,
  description,
}: any): void => {
  //notification.success({
  //  message,
  //  description,
  //});
};

export const statusCodesCheck = (response?: AxiosResponse): boolean => {
  return (
    !!response &&
    ((response.status < 300 &&
      response.status >= 200 &&
      response.status !== 201) ||
      response.data.status_code === 201000)
  );
};

export const checkResponse =(response?: AxiosResponse): boolean => {
  return (
    !!response && 
    !!response.data &&
    response.status === 200 &&
    response.data.result.result_code === 201000
  );
};

function handleAxiosErrors<T>(
  e: AxiosError,
  error?: (er: AxiosError<T>) => AxiosResponse<T> | void,
): AxiosResponse<T> | void {
  if (!e || !e.response || e.response.status >= 500) {
    errorNotificationWith({
      message: e.response?.data?.message || e.message,
    });
    return undefined;
  }
  if (error) {
    return error(e);
  }
  if (e?.response?.status === 401) {
    //logoutUser();
    errorNotificationWith({ message: e?.response?.data?.message });
    return undefined;
  }
  if (e?.response?.status === 409) {
    errorNotificationWith({
      message: e.response.data.message || e.message,
    });
    return undefined;
  }
  if (!Axios.isCancel(e)) {
    errorNotificationWith({
      message:
        e?.response?.data?.message ||
        Object.values(e?.response?.data?.errors)?.[0] ||
        e.message,
    });
    return undefined;
  }
  return undefined;
}

interface ApiWrapper<T = unknown> {
  success: () => Promise<AxiosResponse<T> | void>;
  error?: (e: AxiosError<T>) => AxiosResponse<T> | void;
}

const apiWrapperInternal = async <T = unknown>({
  success,
  error,
}: ApiWrapper<T>): Promise<AxiosResponse<T> | void> => {
  try {
    // store.dispatch(showLoader());
    return await success();
  } catch (e: any) {
    if (e.isAxiosError) {
      return handleAxiosErrors(e, error);
    }
    errorNotificationWith({
      message: e?.response?.data?.message || e.message,
    });
    throw e.response;
  } finally {
    // store.dispatch(hideLoader());
  }
};

// let timeoutId: NodeJS.Timeout;
export const apiWrapper = async <T = unknown>({
  success,
  error,
}: ApiWrapper<T>): Promise<AxiosResponse<T> | void> => {
  // if (timeoutId) {
  //   clearTimeout(timeoutId);
  // }
  // timeoutId = setTimeout(() => logoutUser(), LOGOUT_TIMEOUT);

  return apiWrapperInternal<T>({
    success,
    error,
  });
};
