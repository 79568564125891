import React from 'react'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import { ClassroomNowAndUpcoming, ClassroomNow } from './index'
import { Dispatch } from 'redux'
import { NavigateFunction } from 'react-router-dom'




export const navigateVideoClassroom = (v: ClassroomListCardEntity, dispatch: Dispatch<any>, navigate: NavigateFunction) => {
  navigate("/videoclassroom?id=" + v.id)
}

export const getClassroomData = (meetingList: any): ClassroomListCardEntity[] => {
  const newVideoClassroomList: ClassroomListCardEntity[] = []
  meetingList.forEach(classroom => {
    if (classroom?.meeting_info?.meeting_id) {
      newVideoClassroomList.push({
        id: classroom.meeting_info.meeting_id,
        media_image: classroom.meeting_thumbnail,
        class: classroom.category.name,
        content_header: classroom.meeting_title,
        time: (classroom.meeting_start_time?? classroom.meeting_created_time),
        teacherId: classroom.teacher?.user_id,
        teacherName: classroom.teacher?.user_name,
        teacherAvatar: classroom.teacher?.user_avatar,
        fees: classroom.meeting_fees,
      })
    }
  })

  return newVideoClassroomList
}

export const isClassroomNow = (classroomTime: number): boolean => {
  const nowTime = Date.now();
  classroomTime = classroomTime * 1000
  const diff = nowTime - classroomTime
  if (7200000 < diff || -7200000 > diff) {
    return false
  }
  return true
}

export enum ListType {
  Now,
  UpComing
}

export const VideoClassroomList: React.FC =() => {


  const [listType, setListType] = React.useState<ListType>(ListType.Now)
  const [isShowList, setIsShowList] = React.useState<Boolean>(false)

  const handleOnClickMore = (type: ListType) => {
    setIsShowList(true)
    setListType(type)
  }

  const handleOnClickBack = () => {
    setIsShowList(false)
  }

  
  return (
    <>
    {
      isShowList?
        listType === ListType.Now?
        <ClassroomNow type={ListType.Now} onClickBack={handleOnClickBack} />:
        listType === ListType.UpComing?
        <ClassroomNow type={ListType.UpComing} onClickBack={handleOnClickBack} />:
        '':
      <ClassroomNowAndUpcoming onClickMore={handleOnClickMore} />
    }
    </>
  )
}