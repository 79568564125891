import Card, { CardProps } from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { MasterClass } from 'src/app/models'

interface MasterClassCardProps extends CardProps {
  masterClass: MasterClass
}

export const MasterClassCard: React.FC<MasterClassCardProps> = ({ masterClass, ...props }) => {
  return (
    <Card
      {...props}
      style={{
        background: 'transparent'
      }}
      sx={{ cursor: 'pointer', boxShadow: 'none', ml: 3, mr: 3 }}
    >
      <Box
        sx={{
          position: 'relative',
          paddingBottom: 'calc(116/91 * 100%)'
        }}
      >
        <CardMedia
          component="img"
          image={String(masterClass?.thumbnail)}
          alt={String(masterClass?.author)}
          sx={{
            position: 'absolute',
            inset: '0',
            width: '100%',
            height: '100%',
            //borderRadius: '4px'
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            inset: '0',
            width: '100%',
            height: '100%',
            display: 'flex'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
              marginTop: 'auto',
              textAlign: 'center',
              flex: 1
            }}
          >
            {/* {masterClass.author} */}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}
