import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'

export const bookingApi = createApi({
    reducerPath: 'bookingApi',
    baseQuery: baseQueryWithReauth,
    endpoints(builder) {
        return {
            createBooking: builder.mutation<Result<any>, { source_type: string, source_id: string, message: string, coupon?: string }>({
                query: ({ source_type, source_id, message, coupon }) => ({
                    url: `booking`,
                    method: 'POST',
                    body: {
                        request_type: "create_booking",
                        request_data: {
                            source_type,
                            source_id,
                            message,
                            coupon,
                        }
                    }
                }),
                transformResponse: (res: Response<any>) => new Value(res)
            }),
            getBooking: builder.mutation<Result<any>, { booking_id: string }>({
                query: ({ booking_id }) => ({
                    url: `booking`,
                    method: 'POST',
                    body: {
                        request_type: "get_booking",
                        request_data: {
                            booking_id
                        }
                    }
                }),
                transformResponse: (res: Response<any>) => new Value(res)
            }),
            selectUserBooking: builder.mutation<Result<any>, { page_size: number, start_key: string, forward_order: boolean }>({
                query: ({ page_size, start_key, forward_order }) => ({
                    url: `booking`,
                    method: 'POST',
                    body: {
                        request_type: "select_user_booking",
                        request_data: {
                            page_size,
                            start_key,
                            forward_order
                        }
                    }
                }),
                transformResponse: (res: Response<any>) => new Value(res)
            }),

            studentCancelBooking: builder.mutation<Result<any>, {
                bookingId: string
            }>({
                query: ({ bookingId }) => ({
                    url: `booking`,
                    method: 'POST',
                    body: {
                        request_type: 'student_cancel_booking',
                        request_data: {
                            booking_id: bookingId,
                        }
                    }
                }),
                transformResponse: (res: Response<any>) => new Value(res)
            }),
            courseBookingUseCoupon: builder.mutation<Result<any>, {
                Coupon: string,
                seriesId: string
            }>({
                query: ({ Coupon, seriesId }) => ({
                    url: `booking`,
                    method: 'POST',
                    body: {
                        request_type: 'course_booking_use_coupon',
                        request_data: {
                            coupon: Coupon,
                            series_id: seriesId
                        }
                    }
                }),
                transformResponse: (res: Response<any>) => new Value(res)
            }),
        }
    }
})

export const {
    useCreateBookingMutation,
    useGetBookingMutation,
    useSelectUserBookingMutation,
    useStudentCancelBookingMutation,
    useCourseBookingUseCouponMutation,
} = bookingApi