import { FC } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system'
import MicrophoneDevice from './microphoneDevices';
import SpeakerDevices from './speakerDevices';
import CameraDevices from './cameraDevices';
import AudioSwitch from './AudioSwitch';
import VideoSwitch from './VideoSwitch';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    subTitle: {
        fontSize: '18px',
        fontFamily: ' Avenir-Black, Avenir;',
        fontWeight: 'bold',
        color: '#FFFFFF',
        marginBottom: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
        }
    }
}));




const DeviceSelection: FC = () => {
    const theme = useTheme();
    const classes = useStyles(); 
    return (
        <Box
            sx={{
                display: 'flex',
                '> *' : {
                    flexBasis: '50%'
                },
                width: '100%',
                height: '670px',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    height: 'auto',
                }
            }}
        >
            <Box
                sx={{
                    borderRight: "1px solid #555555",
                    pr: "62px",
                    '> *' : {
                        mb: '33px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        borderRight: '0',
                        pr: '0',
                        borderBottom: "1px solid #555555",
                        paddingBottom: '20px',
                        marginBottom: '20px'
                    }
                }}
            >
                <Box className={classes.subTitle}>
                    <Box>Audio</Box>
                    <AudioSwitch />
                </Box>
                <MicrophoneDevice />
                <SpeakerDevices />
            </Box>
            <Box
                sx={{
                    pl: '58px',
                    '> *' : {
                        mb: '33px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        pl: '0'
                    }
                }}
            >
                <Box className={classes.subTitle}>
                    <Box>Video</Box>
                    <VideoSwitch />
                </Box>
                <CameraDevices />
            </Box>
        </Box>
    )
}





export default DeviceSelection;