import * as React from 'react'
import { Box, Paper, Tabs, Tab, Button, Theme, Popover, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Divider, Checkbox, } from '@mui/material'
import { ViewState } from "@devexpress/dx-react-scheduler"
import {
  Scheduler,
  DayView,
  Appointments,
  MonthView,
  Resources,
  AppointmentTooltip
} from "@devexpress/dx-react-scheduler-material-ui";
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles'
// import { Theme } from "@material-ui/core"
import clsx from "clsx"
import { styled } from '@mui/system'
import { indigo, blue, teal } from "@material-ui/core/colors"
import { alpha } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close';
// import SearchIcon from '@mui/icons-material/Search'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import SvgComponent from 'src/components/svgComponent'
import { getFormatDisplayTime, getMonthInWord, getStrTime } from 'src/utils/timeUtil'
import { Link, useNavigate } from 'react-router-dom'
import { getFormatDateHour, getFormatDate } from 'src/utils/timeUtil'
import { genRandom, getDisplayUserName } from 'src/utils/stringUtil'
import { AppointmentDataItem, AppointmentItem, ScheduleEnum, TeacherPrivateLesson, PrivateLessonTypeEnum, BookStatusEnum, ScheduleLessonTypeEnum, getPrivateLessonType, PrivateLessonClassSourceEntity } from 'src/app/models/schedule';
import { useSnackbar } from 'notistack';
import { useCreatePrivateLessonClassMutation, useDeleteTeacherAvailableTimeMutation, useGetTeacherPrivateLessonDetailMutation } from 'src/app/service/real/privatelesson-slice';
import { useSelector } from 'src/app/toolkit/store';
import { useDispatch } from 'react-redux';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import Loader from 'src/components/loader';
import { getAuthRole } from 'src/utils/auth';
import { getTimeAreaDesc } from 'src/utils/timeUtil'
import { appConfig } from 'src/app-config';
import { StudentBookLayout, BookedTimeLayout, TeacherAvailableTimeLayout, getBookTimeStatusDesc } from './BookLayout';
import { CreateAvailableTimeDialog } from './CreateAvailableTimeDialog';
import { useGetCheckoutSessionMutation } from 'src/app/service/real/payment.slice';
import { CheckoutOneTimeItem, PaymentMode, PaymentType, PaymentProductInfo, PaidInfo, Product_Type, SeriesEntity, PaymentCurrency } from '@dokyo/common'
import NP from "number-precision";
import { storeSession } from 'src/utils/localStorage';
import { loadStripe } from '@stripe/stripe-js';
import { MembershipConfigInfo } from 'src/app/models';
import { ErrorCode } from 'src/utils/constants';
import { useTranslation } from 'react-i18next';
import { useGetCouponListMutation } from "src/app/service/real/coupon-slice";
import { useCreateBookingMutation } from 'src/app/service/real/booking-slice';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

const PopoverTitle = styled(Typography)({
  height: '38px',
  flexGrow: 1,
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: '30px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer'
})


const resources = [
  {
    fieldName: "schedule",
    title: "schedule",
    instances: [
      { id: ScheduleEnum.TeacherSideSeriesLesson, text: "Series Lesson Class", color: '#CCDCFF' },
      { id: ScheduleEnum.StudentSideBookedSeriesLesson, text: "Booked Series Lesson", color: '#CCDCFF' },
      { id: ScheduleEnum.StudentSideBookedTime, text: "Booked Time", color: '#FAD3D3' },
      { id: ScheduleEnum.TeacherSideBookedTime, text: "Private Lesson", color: '#FEE0C6' },
      { id: ScheduleEnum.TeacherAvailableTime, text: "Available Time", color: '#EFEFEF' },
      { id: ScheduleEnum.Broadcast, text: "Broadcast", color: '#E0EDBD' },
      { id: ScheduleEnum.ChimeMeeting, text: "Video Classroom", color: '#BDF3EB' },
      { id: ScheduleEnum.Mixed, text: "Views Total Classes >", color: 'rgba(59, 130, 246, 0.5)' }
    ]
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: "inline"
  },
  divider: {
    marginTop: 10,
    marginBottom: 30,
  },
  buttonLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  viewButton: {
    marginRight: 30,
    height: 40,
    width: 150,
    fontSize: '14px !important',
    fontFamily: 'ArialMT',
    textTransform: 'none',
    background: "#f50057",
    color: "#fff",
    boxShadow: 'none',
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popoverArrow: {
    position: "relative",
    marginTop: "10px",
    "&::before": {
      backgroundColor: "white",
      content: '""',
      display: "block",
      position: "absolute",
      width: 24,
      height: 24,
      top: -8,
      boxShadow: '-2px -2px 5px rgba(0, 0, 0, 0.12)',
      transform: "rotate(45deg)",
      left: "calc(50% - 12px)",
      [theme.breakpoints.down('sm')]: {
        display: "none"
      }
    },
    "&::after": {
      backgroundColor: "white",
      content: '""',
      display: "block",
      position: "absolute",
      width: '34px',
      height: 24,
      left: "calc(50% - 17px)",
      [theme.breakpoints.down('sm')]: {
        display: "none"
      }
    }
  },
  popoverContent: {
    background: '#ffffff',
    width: '480px',
    padding: '20px',
    maxWidth: '480px !important',
  },
  popoverContentMenu: {
    width: '260px',
    background: '#F6F6F6',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '65px',
    }
  },
  popoverContentMenuChoice: {
    background: '#fff',
    fontWeight: 'bold'
  },
  popoverContentMenuItem: {
    height: '38px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    width: '260px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 18px',
    lineHeight: '38px',
    flexShrink: 0,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '90px',
    }
  },
  popoverType: {
    display: 'flex',
    alignItems: 'baseline',
    height: '24px',
    fontSize: '16px',
    color: '#555555',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      marginBottom: '0px',
    }
  }
}));

const PopoverCloseIcon = styled(CloseIcon)({
  width: '25px',
  height: '25px',
  opacity: 0.4,
  position: 'relative',
  cursor: 'pointer'
})

const PopoverItem = styled(Typography)(({ theme }) => ({
  height: '18px',
  lineHeight: '18px',
  fontSize: '16px',
  color: '#555555',
  [theme.breakpoints.down("sm")]: {
    fontSize: '14px'
  },
}))

const PopoverItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '17px'
})


const PopoverLine = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '1px',
  background: '#E6E6E6',
  marginBottom: '24px',
  [theme.breakpoints.down("sm")]: {
    marginBottom: '10px',
  },
}))

const styles = ({ palette }: Theme) =>
  createStyles({
    appointment: {
      borderRadius: 0,
      borderBottom: 0,
      height: '100%',

    },
    highPriorityAppointment: {
      borderLeft: `4px solid ${teal[500]}`
    },
    mediumPriorityAppointment: {
      borderLeft: `4px solid ${blue[500]}`
    },
    lowPriorityAppointment: {
      borderLeft: `4px solid ${indigo[500]}`
    },
    weekEndCell: {
      backgroundColor: alpha(palette.action.disabledBackground, 0.04),
      "&:hover": {
        backgroundColor: alpha(palette.action.disabledBackground, 0.04)
      },
      "&:focus": {
        backgroundColor: alpha(palette.action.disabledBackground, 0.04)
      }
    },
    weekEndDayScaleCell: {
      backgroundColor: alpha(palette.action.disabledBackground, 0.06)
    },
    dayViewDayScaleCell: {
      '& .Cell-dayView': {
        marginTop: '10px'
      }
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: '#000000',
      width: '100%',
      textAlign: 'center',
      padding: '0 5px',
      fontSize: '12px',
    },
    content: {
      marginTop: '5px',
      opacity: 0.7
    },
    mixedText: {
      fontSize: '12px',
      lineHeight: '12px'
    },
    container: {
      width: "100%",
      lineHeight: '100%',
      height: "100%",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      //!
    },
    tabs: {
      "& .MuiTab-root": {
        "& .Mui-selected": {
          color: "#FFFFFF",
        },
        color: "#FFFFFF",
        textTransform: 'none',
        fontFamily: 'Helvetica',
        fontSize: '16px',
        marginTop: '10px'
      },
    },
    monthTableHeaderCell: {
      height: '50px !important'
    },
    monthTableCell: {
      height: '100px !important',
    }
  });

const SecondTab = styled(Tab)({
  color: '#FFFFFF',
  '&.Mui-selected': {
    color: '#FFFFFF',
  }
})

const SecondTabs = styled(Tabs)({
  "& .MuiTab-root": {
    "& .Mui-selected": {
      color: "#FFFFFF",
    },
    color: "#FFFFFF",
    textTransform: 'none',
    fontFamily: 'Helvetica',
    fontSize: '16px',
    marginTop: '10px'
  },
})
interface CalendarChange {
  (currentDate: Date, calendarType: string): void
}

interface PropsCalendar {
  enableCreateAvailableTime: boolean,
  viewTeacherID?: string,
  onRefresh?: () => void,
  onCalendarChange: CalendarChange,
  appointmentItems: AppointmentItem[]
}


export const Calendar: React.FC<PropsCalendar> = ({
  enableCreateAvailableTime,
  viewTeacherID,
  onRefresh,
  onCalendarChange,
  appointmentItems
}) => {

  /**
   * This is the popup layout when click the schedule item
   */
  const Layout = ({ appointmentMeta, visible, onHide }: any) => {
    const classes = useStyles();
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { credential } = useSelector(state => state.app);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [deleteRepeat, setDeleteRepeat] = React.useState(false);
    const target = appointmentMeta && appointmentMeta.target;
    const [itemIndex, setItemIndex] = React.useState<number>(0);
    const data = appointmentMeta?.data as AppointmentDataItem;
    const showItem = data?.infoList[itemIndex];
    const [deleteAvailableTime] = useDeleteTeacherAvailableTimeMutation();
    const [createPrivateLessonClass] = useCreatePrivateLessonClassMutation();
    const [createBooking] = useCreateBookingMutation();
    const [getcheckoutsession] = useGetCheckoutSessionMutation();
    const [isCoupon, setisCoupon] = React.useState<boolean>(true)
    const [currentCoupon, setcurrentCoupon] = React.useState<string>('')//当前的coupon
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    // React.useEffect(() => {
    //   if(!target) {
    //     onHide()
    //   }
    // }, [target])

    React.useEffect(() => {
      setItemIndex(0);
    }, [data])

    const handleClose = () => {
      onHide();
    }

    const handleClickDelete = () => {
      setShowDeleteDialog(true);
      // onHide();
    }

    const handleClickConfirmDelete = async () => {
      if (!credential) {
        dispatch(showSignInUpDialog())
        onHide();
        return;
      }
      onHide();
      // pass empty time area to delete available time

      setLayoutLoading(true);
      const originalUserId = authRole.isAdmin ? viewTeacherID : undefined;
      deleteAvailableTime({ schedule_id: showItem.id, repeat: deleteRepeat, originalUserId: originalUserId })
        .unwrap()
        .then(res => {
          setLayoutLoading(false);
          if (res && !res.isFailed) {
            enqueueSnackbar("Delete Successfully", { variant: 'success' });
            onRefresh && onRefresh();
          } else {
            enqueueSnackbar(res?.message || 'Delete failed', { variant: 'error' })
          }
        })
    }

    const handleClickBook = async (startTime: number, timeLength: number, price: number, note: string, coupon?: string) => {
      if (!credential) {
        dispatch(showSignInUpDialog())
        onHide();
        return;
      }
      onHide();
      setLayoutLoading(true);
      const params = {
        scheduleId: showItem.id,
        teacherId: showItem.teacherId,
        startTime: startTime,
        message: note,
        coupon
        // category: category,//TODO
      };
      if (showItem.lessonType === ScheduleLessonTypeEnum.OneToOneLesson) {
        params['timeMinutes'] = timeLength;
      }
      createPrivateLessonClass({ scheduleId: showItem.id, teacherId: showItem.teacherId, startTime: startTime, timeMinutes: timeLength })
        .unwrap()
        .then(res => {
          if (res && !res.isFailed) {
            const privateLessonClass: PrivateLessonClassSourceEntity = res.result.private_lesson_classes;
            if (privateLessonClass?.id) {
              handleBooking(privateLessonClass?.id, price, note, coupon);
            } else {
              setLayoutLoading(false);
            }
          } else {
            enqueueSnackbar(res?.message || 'Create class failed');
            setLayoutLoading(false);
          }
        })
        .catch(e => {
          enqueueSnackbar("Book failed");
          setLayoutLoading(false);
        })
    }

    const handleBooking = (classId: string, price: number, note: string, coupon?: string) => {
      createBooking({ source_type: 'private_lesson', source_id: classId, message: note, coupon }).unwrap()
        .then(res => {
          if (res && !res.isFailed && res.result) {
            const bookId = res.result.id;
            if (bookId) {
              if (isCoupon && coupon) {
                const returnUrl = `${appConfig.paths.commonOrder}?id=${bookId}&coupon=${coupon}`;
                navigate(returnUrl)
              } else {
                const image = privateLessonData?.thumbnail || '';
                const description = privateLessonData?.introduction || '';
                handlePurchase(bookId, price, image, description);
              }
            } else {
              enqueueSnackbar('Can not get book info', { variant: 'error' })
              setLayoutLoading(false);
            }
          } else if (res?.resultCode === ErrorCode.TEACHER_TIME_IS_BOOKED) {
            enqueueSnackbar(t("teacher.timeIsBooked"), { variant: 'error' })
            setLayoutLoading(false);
          } else if (res?.resultCode === ErrorCode.TEACHER_TIME_START_IS_PAST) {
            enqueueSnackbar(t("teacher.startTimeIsPast"), { variant: 'error' })
            setLayoutLoading(false);
          } else {
            enqueueSnackbar(res?.message || "Book failed")
            setLayoutLoading(false);
          }
        })
        .catch(e => {
          enqueueSnackbar("Book failed");
          setLayoutLoading(false);
        })
    }

    const handleContinuePurchase = (teacherId: string, bookId: string, price: number) => {
      onHide();
      // if (showItem.privateLessonScheduleType === PrivateLessonTypeEnum.Single) {
      setLayoutLoading(true);
      getTeacherPrivateLessonDetail({ teacherId: teacherId })
        .unwrap()
        .then(res => {
          if (res && !res.isFailed && res.result) {
            const lessonData: TeacherPrivateLesson = res.result.teacher;
            const image = lessonData?.thumbnail || '';
            const description = lessonData?.introduction || '';
            handlePurchase(bookId, price, image, description);
          } else {
            setLayoutLoading(false);
            enqueueSnackbar(res?.message || 'Can not get teacher private lesson details')
          }
        })
      // } else {
      //   handlePurchase(classroomId, bookId, price, '', '');
      // }
    }

    const handlePurchase = async (bookId: string, price: number, image: string, desc: string) => {
      // onClickAddToCart && onClickAddToCart();
      setLayoutLoading(true);
      const CHECKOUT_SESSION_ID: string = await genRandom();
      const paiedInfo: PaidInfo = {
        payment_type: PaymentType.Stripe,
        customer_id: credential?.id ?? "",
        cources: bookId,
        amounts: [NP.times(price / 100 ?? 0, 1)]
      };
      const productInfo: PaymentProductInfo = {
        product_id: '2',
        product_type: 'booking',
        paid_info: paiedInfo
      };
      const checkoutOneTimeItem: CheckoutOneTimeItem[] = [
        {
          product_name: showItem.lessonType === ScheduleLessonTypeEnum.OneToOneLesson ? '1 to 1 Lesson' : 'Group Lesson',
          description: desc,
          images: [image],
          amount: price,
          quantity: 1,
          currency: PaymentCurrency.USD
        } as CheckoutOneTimeItem
      ];

      const returnUrl = `${window.location.protocol}//${window.location.host}${appConfig.paths.commonOrder}?id=${bookId}`;
      const params = {
        paymenttype: PaymentType.Stripe,
        price_id: "",
        quantity: 1,
        mode: PaymentMode.Payment,
        coupon: "",
        allow_input_code: true,
        success_url: `${window.location.protocol}//${window.location.host}${appConfig.paths.cartSuccess}?session_id=${CHECKOUT_SESSION_ID}&productid=${2}&payment_value=${price ?? 0}&returnurl=${returnUrl}`,
        cancel_url: window.location.href,
        product_info: productInfo,
        description: desc,
        one_time_items: checkoutOneTimeItem
      };
      const response = await getcheckoutsession(params).unwrap();
      if (response && !response.isFailed && response?.session_id) {
        storeSession(CHECKOUT_SESSION_ID, response?.session_id);
        const stripe = await loadStripe(configMembership?.stripe_publishable_key);
        if (stripe) {
          // Call Stripe.js method to redirect to the new Checkout page
          stripe.redirectToCheckout({
            sessionId: response?.session_id
          });
        }
      } else {
        enqueueSnackbar(response?.message || 'Request failed');
        setLayoutLoading(false);
      }
      // setLayoutLoading(false);
    };

    const handleClickDetail = React.useCallback(() => {
      if (showItem.schedule === ScheduleEnum.Broadcast) {
        navigate("/livebroadcast?id=" + showItem.id)
      } else if (showItem.schedule === ScheduleEnum.ChimeMeeting) {
        navigate("/videoclassroom?id=" + showItem.id)
      }
    }, [showItem])

    const handleClickMenu = React.useCallback((index) => {
      setItemIndex(index)
    }, [data, itemIndex])


    if (!data?.infoList || !showItem) {
      return (<>
      </>)
    }

    let title = showItem.title ?? ''
    let type = ''
    if (showItem.schedule === ScheduleEnum.ChimeMeeting) {
      type = "Live Video Class";
    } else if (showItem.schedule === ScheduleEnum.Broadcast) {
      type = "Live Schedule";
    } else if (showItem.schedule === ScheduleEnum.TeacherSideBookedTime
      || showItem.schedule === ScheduleEnum.StudentSideBookedTime) {
      if (showItem.lessonType === ScheduleLessonTypeEnum.GroupLesson) {
        type = "Group Lesson";
      } else if (showItem.lessonType === ScheduleLessonTypeEnum.CustomPrivateLesson) {
        type= "Custom Private Lesson";
      } else {
        type = "Private Lesson";
      }
    } else if (showItem.schedule === ScheduleEnum.TeacherAvailableTime) {
      if (showItem.lessonType === ScheduleLessonTypeEnum.GroupLesson) {
        type = "Group Lesson";
      } else {
        type = "Private Lesson";
      }
      if (credential?.id !== showItem.teacherId) {
        title = "Book a class with " + getDisplayUserName(showItem.teacherName, showItem.teacherId);
      }
    } else if (showItem.schedule === ScheduleEnum.StudentSideBookedSeriesLesson || showItem.schedule === ScheduleEnum.TeacherSideSeriesLesson) {
      type = "Series Lesson";
    }

    let strDate = ''
    if (showItem?.startDate) {
      // const startDate = data.startDate
      if (showItem.schedule === ScheduleEnum.TeacherSideBookedTime ||
        showItem.schedule === ScheduleEnum.TeacherAvailableTime ||
        showItem.schedule === ScheduleEnum.StudentSideBookedTime) {
        strDate = getTimeAreaDesc(showItem.startDate, showItem.endDate);
      } else {
        const strMonth = getMonthInWord(showItem.startDate.getMonth())
        const strTime = getStrTime(showItem.startDate)
        strDate = `${strMonth} ${showItem.startDate.getDate()}, ${strTime}`
      }
    }
    // const teacher = showItem?.teacherName
    const teacher = getDisplayUserName(showItem.teacherName, showItem.teacherId)
    const courseCategory = showItem.category


    const handleClickViewDetails = () => {
      if (showItem.schedule === ScheduleEnum.StudentSideBookedSeriesLesson || showItem.schedule === ScheduleEnum.TeacherSideSeriesLesson) {
        navigate(`${appConfig.paths.seriesLessonClass}?id=${showItem.classRoomId}`)
      } else {
        navigate(`${appConfig.paths.privateLesson}?id=${showItem.id}`)
      }
    }

    const getPurchasedNum = (): string => {
      if (showItem.booked) {
        if (showItem.schedule === ScheduleEnum.TeacherSideBookedTime && showItem.lessonType === ScheduleLessonTypeEnum.GroupLesson && showItem.bookStatus === BookStatusEnum.PAID) {
          return showItem && showItem.bookList && showItem.bookList?.length > 0 ? `${showItem.bookList?.length} purchased` : '';
        } else {
          return '';
        }
      } else {
        return showItem.bookPaidNum !== undefined ? `${showItem.bookPaidNum} purchased` : '';
      }
    }

    return (
      <Popover
        open={!!visible}
        anchorEl={target}
        onClose={onHide}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorPosition={{
          top: 10,
          left: 0
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: classes.popover,
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: "10px",
            padding: '5px',
          }
        }}
      >
        <Box className={classes.popoverArrow} />
        <Box
          sx={{
            display: 'flex',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          }}
        >
          {
            data.total > 1 ?
              <Box className={classes.popoverContentMenu} >
                {
                  data.infoList.sort((prev, next) => {
                    if (prev.startDate < next.startDate) {
                      return -1
                    }
                    if (prev.startDate > next.startDate) {
                      return 1;
                    }
                    return 0;
                  }).map((item, index) => (
                    <Box
                      onClick={() => handleClickMenu(index)}
                      key={item.id}
                      title={item.title}
                      className={clsx(classes.popoverContentMenuItem, { [classes.popoverContentMenuChoice]: index === itemIndex })}
                    >
                      {item.title}
                    </Box>
                  ))
                }
              </Box> : ''
          }

          {showItem.schedule === ScheduleEnum.TeacherSideBookedTime
            || showItem.schedule === ScheduleEnum.TeacherAvailableTime
            || showItem.schedule === ScheduleEnum.StudentSideBookedTime
            || showItem.schedule === ScheduleEnum.StudentSideBookedSeriesLesson
            || showItem.schedule === ScheduleEnum.TeacherSideSeriesLesson ?
            <Box className={classes.popoverContent} sx={{ maxWidth: isMobile ? "280px !important" : "" }}>
              <Box sx={{ display: 'flex' }}>
                <PopoverTitle title={title}
                  sx={{
                    fontSize: isMobile ? "18px" : "",
                    height: isMobile ? "30px" : "",
                  }}>
                  {title}
                </PopoverTitle>
                <PopoverCloseIcon onClick={handleClose} />
              </Box>
              <Box className={classes.popoverType}>
                {type}&nbsp;&nbsp;
                {showItem.lessonType === ScheduleLessonTypeEnum.GroupLesson &&
                  <Typography style={{ fontSize: "14px", color: "#307DCF" }} >{getPurchasedNum()}</Typography>
                }
              </Box>
              <PopoverLine />

              {/* student/unlogin view unbook time */}
              {!showItem.booked && (!credential || (credential?.id !== showItem.teacherId && !showItem.secondTeachers?.some(item => item.id === credential?.id))) && showItem.schedule !== ScheduleEnum.TeacherSideSeriesLesson && !authRole.isAdmin &&
                <StudentBookLayout
                  groupFee={showItem.groupLessonFee ?? 0}
                  scheduleType={PrivateLessonTypeEnum.Single}
                  startDate={showItem.startDate}
                  endDate={showItem.endDate}
                  title={showItem.title}
                  privateLesson={privateLessonData}
                  coupon={currentCouponList}
                  selectCoupon={(res) => { setisCoupon(res) }}
                  currentCouponCode={(res) => { setcurrentCoupon(res) }}
                  onClickBook={handleClickBook} />
              }

              {/* teacher view unbook time */}
              {!showItem.booked && credential && (credential?.id === showItem.teacherId || showItem.secondTeachers?.some(item => item.id === credential?.id) || authRole.isAdmin) && showItem.schedule !== ScheduleEnum.TeacherSideSeriesLesson &&
                <TeacherAvailableTimeLayout
                  startDate={showItem.startDate}
                  endDate={showItem.endDate}
                  title={showItem.title}
                  isSecondTeacher={showItem.secondTeachers ? showItem.secondTeachers.some(item => item.id === credential?.id) : false}
                  onClickDelete={handleClickDelete}
                  privateLessonScheduleType={getPrivateLessonType(showItem.lessonType || ScheduleLessonTypeEnum.Others)} />
              }

              {/* view booked time */}
              {(showItem.booked || showItem.schedule === ScheduleEnum.TeacherSideSeriesLesson) &&
                <BookedTimeLayout
                  showItem={showItem}
                  onClickViewDetails={handleClickViewDetails}
                  onClickContinuePayment={handleContinuePurchase} />
              }

            </Box>

            :

            <Box className={classes.popoverContent} sx={{ maxWidth: isMobile ? "240px !important" : "" }}>
              <Box sx={{ display: 'flex' }}>
                <PopoverTitle title={title} onClick={handleClickDetail} sx={{
                  fontSize: isMobile ? "18px" : "",
                  height: isMobile ? "30px" : "",
                }} >
                  {title}
                </PopoverTitle>
                <PopoverCloseIcon onClick={handleClose} />
              </Box>
              <Box className={classes.popoverType}>
                {type}
              </Box>
              <PopoverLine />
              <PopoverItemBox>
                <SvgComponent iconName={"calendar"} style={{ marginRight: "8px", width: "18px", height: "17px" }} />
                <PopoverItem>
                  {strDate}
                </PopoverItem>
              </PopoverItemBox>
              <PopoverItemBox>
                <SvgComponent iconName={"person"} style={{ marginRight: "8px", width: "17px", height: "18px" }} />
                <PopoverItem>
                  {teacher}
                </PopoverItem>
              </PopoverItemBox>
              <Box>
                <PopoverItem>
                  {courseCategory}
                </PopoverItem>
              </Box>
              <Box>
                <Button
                  onClick={handleClickDetail}
                  sx={{
                    textTransform: "none",
                    backgroundColor: '#F93B57',
                    borderRadius: '8px',
                    fontFamily: 'ArialMT',
                    boxShadow: 'none',
                    mt: '16px',
                    '&:hover': {
                      boxShadow: 'none',
                    },
                  }}
                  variant="contained">
                  Details
                </Button>
              </Box>
            </Box>
          }
        </Box>

        <Dialog
          maxWidth="lg"
          open={showDeleteDialog}
        >
          <DialogTitle id="alert-dialog-title">Prompt</DialogTitle>
          <DialogContent sx={{ width: '400px' }}>
            <Divider />
            <Box style={{ fontSize: "14px", fontFamily: "ArialMT", marginTop: "20px" }}>
              Do you want to delete this available time?
            </Box>
            {showItem.timeRepeatId &&
              <Box style={{ display: 'flex', alignItems: 'center', fontSize: "12px", fontFamily: "ArialMT", marginTop: "20px" }}>
                <Checkbox
                  value={deleteRepeat}
                  onChange={(e) => setDeleteRepeat(e.target.checked)} />
                Delete all duplicate records for this time.
              </Box>
            }
          </DialogContent>
          <DialogActions>
            <Button variant='text' onClick={() => setShowDeleteDialog(false)} color="inherit" style={{ textTransform: "none" }}>
              Cancel
            </Button>
            <Button variant='text' onClick={handleClickConfirmDelete} color="secondary" autoFocus style={{ textTransform: "none" }}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </Popover>
    );
  };
  const [getCouponList] = useGetCouponListMutation();
  const [couponList, setcouponList] = React.useState<any>([])//coupon列表
  const [currentCouponList, setcurrentCouponList] = React.useState<any>([])//当前可使用的coupon
  const classes = useStyles();
  const { t } = useTranslation();
  const [CurrentDate, setCurrentDate] = React.useState<Date>(new Date())
  const [appointmentDataItems, setAppointmentDataItems] = React.useState<AppointmentDataItem[]>([]);
  const [tabValue, setTabValue] = React.useState<string>('2');
  const isWeekEnd = (date: Date): boolean => date.getDay() === 0 || date.getDay() === 6;
  const authRole = getAuthRole()();
  const dispatch = useDispatch();
  const { credential } = useSelector(state => state.app);
  const { enqueueSnackbar } = useSnackbar();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [alertDialogData, setAlertDialogData] = React.useState({ open: false, message: '', showTutorial: false });
  const [startDate, setStartDate] = React.useState<Date | undefined>();
  const [privateLessonData, setPrivateLessonData] = React.useState<TeacherPrivateLesson>();
  const [layoutLoading, setLayoutLoading] = React.useState(false);
  const [getTeacherPrivateLessonDetail, { isLoading: loading3 }] = useGetTeacherPrivateLessonDetailMutation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [configMembership, setConfigMembership] = React.useState<MembershipConfigInfo>({ type: "", stripe_publishable_key: "", list: [] });
  const [currentCourseList, setCurrentCourseList] = React.useState([
    {
      id: "1",
      time: "7:00AM",
      list: [
        { title: "Expressive Drawing", category: "1 to 1Lesson" },
        { title: "Strong Arms Yoga", category: "1 to 1lesson" }
      ]
    },
    {
      id: "2",
      time: "8:00AM",
      list: [
        { title: "Piano with Dr. Mckay", category: "Series Lesson" }
      ]
    },
  ])
  const [isShowCourseList, setIsShowCourseList] = React.useState(false)
  const getMembershipConfig = async ({ url }): Promise<MembershipConfigInfo> => {
    return new Promise((resolve) => {
      fetch(url, {
        cache: "no-cache"
      })
        .then(response => resolve(response.json()))
        .catch(error => {
          console.error(error);
        });
    });
  }

  React.useEffect(() => {
    if (credential) {
      getCouponCodeList()
    }
  }, [credential])

  const getCouponCodeList = () => {
    getCouponList({
      request_type: "All",
      start_key: "",
      page_size: 10,
    }).unwrap().then((res) => {
      if (res && !res.isFailed && res.result) {
        const UnusedCoupon = res.result.unused_list;
        // console.log("所有的coupon:", res.result)
        setcouponList(UnusedCoupon)
        // 从所有的coupon中筛选出当前老师的coupon
        let currentTeacherCoupon: Array<object> = []
        UnusedCoupon?.forEach(element => {
          if (element?.can_use_info[0]?.coupon_use_teacher_id == viewTeacherID) {
            currentTeacherCoupon.push(element)
          }
        });
        setcurrentCouponList(currentTeacherCoupon)
      }
    })
  }
  const getMembershipConfigCallback = React.useCallback(async () => {
    const url = `${process.env.REACT_APP_MEMBERSHIP_URL}`;
    getMembershipConfig({ url: url })
      .then((res) => {
        setConfigMembership(res)
      })
      .catch((error) => {
        enqueueSnackbar(error || "Get membership info failed!")
      });
  }, [])

  React.useEffect(() => {
    getMembershipConfigCallback()
  }, [getMembershipConfigCallback])

  React.useEffect(() => {
    if (credential?.id === viewTeacherID && !authRole.isTeacher) {
      // current user is not a teacher
      return;
    }
    if (viewTeacherID) {
      getTeacherPrivateLessonDetail({ teacherId: viewTeacherID })
        .unwrap()
        .then(res => {
          if (res && !res.isFailed && res.result) {
            setPrivateLessonData(res.result.teacher);
          } else {
            // enqueueSnackbar(res?.message || 'Can not get teacher private lesson details')
          }
        })
    }
  }, [viewTeacherID])


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    onCalendarChange(CurrentDate, tabValue);
  }, [CurrentDate, tabValue])

  React.useEffect(() => {
    const appointmentDataItems: AppointmentDataItem[] = []
    const appointmentDataItemHash: Record<string, AppointmentDataItem> = {};
    appointmentItems.forEach(item => {
      let startTimeStamp = item.startDate.getTime();
      let endTimeStamp = item.endDate.getTime();
      // console.log('startTimeStamp', startTimeStamp);
      if (tabValue === '1') { // week
        // hour
        if (item.schedule === ScheduleEnum.Broadcast || item.schedule === ScheduleEnum.ChimeMeeting) {
          startTimeStamp -= startTimeStamp % 3600000;
          endTimeStamp -= endTimeStamp % 3600000;
        }
        // console.log('startTimeStamp: '+ startTimeStamp + ", " + getFormatDisplayTime(startTimeStamp));
        // console.log('endTimeStamp: '+ endTimeStamp + ", " + getFormatDisplayTime(endTimeStamp));
        const dateHourStr = getFormatDateHour(startTimeStamp);
        if (appointmentDataItemHash[dateHourStr]) {
          appointmentDataItemHash[dateHourStr].total++;
          if (appointmentDataItemHash[dateHourStr].schedule !== ScheduleEnum.Mixed && appointmentDataItemHash[dateHourStr].schedule !== item.schedule) {
            appointmentDataItemHash[dateHourStr].schedule = ScheduleEnum.Mixed
          }
          appointmentDataItemHash[dateHourStr].startDate = item.startDate;
          appointmentDataItemHash[dateHourStr].endDate = item.endDate;
        } else {
          appointmentDataItemHash[dateHourStr] = {
            total: 1,
            schedule: item.schedule,
            startDate: new Date(startTimeStamp),//init value
            endDate: new Date(endTimeStamp),// init value
            infoList: []
          }
        }
        appointmentDataItemHash[dateHourStr].infoList.push(item)

        // item.startDate -> year-month-day-hour
      } else if (tabValue === '2') { // month
        // day
        // debugger;
        // console.log('getTimezoneOffset', item.startDate.getTimezoneOffset())
        // console.log('startTimeStamp', startTimeStamp);
        // startTimeStamp -= (item.startDate.getTimezoneOffset() * 60000);
        // startTimeStamp -= startTimeStamp % 86400000 ;
        // console.log('startTimeStamp', startTimeStamp);
        const dateStr = getFormatDate(startTimeStamp);
        if (appointmentDataItemHash[dateStr]) {
          appointmentDataItemHash[dateStr].total++;
          if (appointmentDataItemHash[dateStr].schedule !== ScheduleEnum.Mixed && appointmentDataItemHash[dateStr].schedule !== item.schedule) {
            appointmentDataItemHash[dateStr].schedule = ScheduleEnum.Mixed
          }
        } else {
          appointmentDataItemHash[dateStr] = {
            total: 1,
            schedule: item.schedule,
            startDate: new Date(startTimeStamp),
            endDate: new Date(endTimeStamp),
            infoList: []
          }
        }
        appointmentDataItemHash[dateStr].infoList.push(item)
        // item.startDate -> year-month-day
      }
    })
    for (let key in appointmentDataItemHash) {
      // find the max end time and min start time in the schedule array
      const item: AppointmentDataItem = appointmentDataItemHash[key];
      const minStartTime = Math.min.apply(Math, item.infoList.map(schedule => { return schedule.startDate.getTime() }))
      let maxEndTime = Math.max.apply(Math, item.infoList.map(schedule => { return schedule.endDate.getTime() }))

      const endDate = new Date(maxEndTime);
      const endDateHour = endDate.getHours();
      if (endDateHour === 0) {
        maxEndTime -= 60 * 1000; // - 1 minute. If not handle this, will show time on the next day on calendar
      }
      item.startDate = new Date(minStartTime);
      item.endDate = new Date(maxEndTime);
      appointmentDataItems.push(item)
    }
    // setAppointmentDataItems(isMobile ? [] : appointmentDataItems)
    setAppointmentDataItems(appointmentDataItems)
  }, [appointmentItems])

  type AppointmentProps = Appointments.AppointmentProps & WithStyles<typeof styles>;
  type AppointmentContentProps = Appointments.AppointmentContentProps & WithStyles<typeof styles>;
  type TimeTableCellProps = MonthView.TimeTableCellProps & WithStyles<typeof styles>;
  type DayScaleCellProps = MonthView.DayScaleCellProps & WithStyles<typeof styles>;


  const MonthViewDayHeader = withStyles(styles)(
    ({ startDate, classes, ...restProps }: DayScaleCellProps) => (
      <MonthView.DayScaleCell
        // className={clsx(classes.monthTableCell, { [classes.weekEndDayScaleCell]: isWeekEnd(startDate) })}
        className={classes.monthTableHeaderCell}
        startDate={startDate}
        {...restProps}
        sx={{ borderRight: isMobile ? "none !important" : "" }}
      />
    )
  );

  const MonthViewDayCell = withStyles(styles)(
    ({ startDate, classes, ...restProps }: TimeTableCellProps) => (
      <MonthView.TimeTableCell
        // className={clsx(classes.monthTableCell, {[classes.weekEndCell]: isWeekEnd(startDate!)})}
        className={classes.monthTableCell}
        startDate={startDate}
        {...restProps}
        onDoubleClick={() => handleClickCreate(startDate)}
        sx={{
          height: isMobile ? "50px !important" : "100px !important",
          alignItems: isMobile ? "center !important" : "",
          borderRight: isMobile ? "none !important" : "",
          verticalAlign: isMobile ? "middle !important" : "top",
          borderCollapse: isMobile ? "separate !important" : ""
        }}
      />
    )
  );

  const DayViewTableCell = withStyles(styles)(
    ({ startDate, endDate, ...restProps }: DayView.TimeTableCellProps) => (
      <DayView.TimeTableCell
        {...restProps}
        onDoubleClick={() => handleClickCreate(startDate)}
      />
    )
  );

  const DayViewDayScaleCell = withStyles(styles)(
    ({ classes, ...restProps }: DayScaleCellProps) => (
      <DayView.DayScaleCell
        className={clsx(classes.dayViewDayScaleCell)}
        {...restProps}
      />
    )
  );

  const Appointment = withStyles(styles)(
    ({ classes, data, ...restProps }: AppointmentProps) => (
      <Appointments.Appointment
        {...restProps}
        className={clsx({
          [classes.highPriorityAppointment]: data.schedule === ScheduleEnum.Broadcast,
          [classes.mediumPriorityAppointment]: data.priority === ScheduleEnum.ChimeMeeting,
          [classes.lowPriorityAppointment]: data.priority === 3,
          [classes.appointment]: true
        })}
        sx={{
          opacity: isMobile ? "0.6" : "",
          transform: isMobile ? "translateY(-8px)" : "",
          height: isMobile ? "100% !important" : "",
          zIndex: "3"
        }}
        data={data}
      />
    )
  );

  const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(
    ({
      classes,
      data,
      ...restProps
    }: // #FOLD_BLOCK
      AppointmentContentProps) => {
      let scheduleType: string = "";
      let title: string = "";

      if (data.total > 1) {
        title = `View ${data.total} Classes >`;
      } else {
        if (data.schedule === ScheduleEnum.ChimeMeeting) {
          scheduleType = "Live Video Class";
        } else if (data.schedule === ScheduleEnum.Broadcast) {
          scheduleType = "Live Stream";
        } else if (data.schedule === ScheduleEnum.TeacherSideBookedTime
          || data.schedule === ScheduleEnum.StudentSideBookedTime) {
          if (data.total === 1) {
            const appoint: AppointmentItem = (data.infoList)[0];
            if (appoint.bookTimeLength && appoint.bookTimeLength > 30) {
              // if time length is 30 mins, show status text will be overlapped
              scheduleType = getBookTimeStatusDesc(appoint.bookStatus)
            }
          }
        }
        title = data.infoList[0].title
      }


      return (
        <Appointments.AppointmentContent className={classes.appointment} {...restProps} data={data} style={{
          justifyContent: isMobile ? 'center' : '',
          padding: isMobile ? '0' : '',
        }}>
          <div className={classes.container} style={{
            width: isMobile ? "0px" : "",
            height: isMobile ? "0px" : "",
            // borderRadius: isMobile ? "50%" : "",
            // background: isMobile ? "#DADADA" : "",

          }}>
            <Box title={title} className={clsx(classes.text, { [classes.mixedText]: scheduleType === '' })} sx={{ color: isMobile ? "rgba(255,255,255,0) !important" : "" }}>{title}</Box>
            {
              scheduleType !== '' ?
                (<div className={clsx(classes.text, classes.content)}>
                  {scheduleType}
                </div>) :
                ''
            }
          </div>
        </Appointments.AppointmentContent>
      );
    }
  );

  const handleDateChange = (action: string) => {
    setIsShowCourseList(false)
    if (tabValue === '1') {
      if (action === 'increment') {
        CurrentDate.setDate(CurrentDate.getDate() + 7)
      } else {
        CurrentDate.setDate(CurrentDate.getDate() - 7)
      }
    } else if (tabValue === '2') {
      if (action === 'increment') {
        CurrentDate.setMonth(CurrentDate.getMonth() + 1)
      } else {
        CurrentDate.setMonth(CurrentDate.getMonth() - 1)
      }
    }
    setCurrentDate((): Date => { return new Date(CurrentDate) })
  }



  const handleClickCreate = (startDate?: Date) => {
    if (!credential) {
      dispatch(showSignInUpDialog());
    } else if (!enableCreateAvailableTime) {
      console.log("Teacher and admin can create available time")
    } else if (!privateLessonData || !privateLessonData?.time_fees || privateLessonData?.time_fees?.length === 0) {
      setAlertDialogData({
        open: true,
        message: 'You have not set private lesson, please set on portal.\n',
        showTutorial: true,
      })
    } else {
      if (startDate) {
        setStartDate(startDate);
      } else {
        setStartDate(undefined);
      }
      setShowCreateDialog(true);
    }
  }

  const handleCreateTimeSuccess = async () => {
    setShowCreateDialog(false);
    onRefresh && onRefresh();
  }

  return (
    <>
      {isMobile ?
        // 移动端日历
        <>
          <Paper sx={{
            marginBottom: '10px',
            width: "100%",
          }}>
            <Scheduler data={appointmentDataItems}>
              <ViewState currentViewName={tabValue} currentDate={CurrentDate} />
              <MonthView
                name='2'
                dayScaleCellComponent={MonthViewDayHeader}
                timeTableCellComponent={MonthViewDayCell}
              />
              <DayView
                name='1'
                startDayHour={0}
                endDayHour={24}
                intervalCount={7}
                timeTableCellComponent={DayViewTableCell}
                dayScaleCellComponent={DayViewDayScaleCell}
              />
              <Appointments
                appointmentComponent={Appointment}
                appointmentContentComponent={AppointmentContent}
              />
              <Resources data={resources} />
              <AppointmentTooltip layoutComponent={Layout} />
              {/* header */}
              <Box
                sx={{
                  width: '100%',
                  height: '60px',
                  background: '#307DCF',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '12px 12px 0px 0px',
                  padding: "10px",
                  overflow: "hidden"
                }}
              >
                {/* 创建 */}
                <Box sx={{ width: "64px", height: "32px" }} >
                {enableCreateAvailableTime &&
                  <Box sx={{ width: "64px", height: "32px" }} >
                    <Button
                      sx={{
                        color: '#FFFFFF',
                        width: "30px",
                        minWidth: "32px !important",
                        height: "32px",
                        border: "1px solid #FFFFFF",
                        padding: "6px !important"
                      }}
                      onClick={() => handleClickCreate()}
                    >
                      <AddIcon />
                    </Button>
                  </Box>
                }
                </Box>
                {/* 切换月份 */}
                <Box sx={{ color: '#FFFFFF' }}>
                  <Button sx={{
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    minWidth: "30px",
                  }}
                    onClick={() => handleDateChange('decrement')
                    }>&#60; </Button>
                  {`${getMonthInWord(CurrentDate.getMonth())} ${CurrentDate.getFullYear()}`}
                  <Button sx={{
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    minWidth: "30px"
                  }}
                    onClick={() => handleDateChange('increment')
                    }>&#62; </Button>
                </Box>
                {/* today */}
                <Button
                  sx={{
                    color: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    height: '32px',
                    width: '60px!important',
                    textTransform: 'none',
                  }}
                  onClick={() => setCurrentDate(new Date())}
                >
                  Today
                </Button>
              </Box>
            </Scheduler>
          </Paper>
        </>
        :
        // pc端日历
        <Paper
          sx={{
            marginBottom: '200px',
          }}
        >
          <Scheduler data={appointmentDataItems}>
            <ViewState currentViewName={tabValue} currentDate={CurrentDate} />
            <MonthView
              name='2'
              dayScaleCellComponent={MonthViewDayHeader}
              timeTableCellComponent={MonthViewDayCell}
            />
            <DayView
              name='1'
              startDayHour={0}
              endDayHour={24}
              intervalCount={7}
              timeTableCellComponent={DayViewTableCell}
              dayScaleCellComponent={DayViewDayScaleCell}
            />
            <Appointments
              appointmentComponent={Appointment}
              appointmentContentComponent={AppointmentContent}
            />
            <Resources data={resources} />
            <AppointmentTooltip layoutComponent={Layout} />

            <Box
              sx={{
                width: '100%',
                height: '60px',
                background: '#307DCF',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '12px 12px 0px 0px',
              }}
            >
              <Box sx={{ width: '275px' }}>
                <SecondTabs onChange={handleChange} value={tabValue} TabIndicatorProps={{ style: { backgroundColor: '#FFFFFF', width: '50px', marginLeft: '20px', marginBottom: '10px' } }}>
                  <SecondTab label="Month" value='2'></SecondTab>
                  <SecondTab label="Week" value='1' ></SecondTab>
                </SecondTabs>
              </Box>
              <Box
                sx={{
                  color: '#FFFFFF',
                }}
              >
                <Button sx={{ color: '#FFFFFF', fontWeight: 'bold' }} onClick={() => handleDateChange('decrement')}>&#60; </Button>
                {`${getMonthInWord(CurrentDate.getMonth())} ${CurrentDate.getFullYear()}`}
                <Button sx={{ color: '#FFFFFF', fontWeight: 'bold' }} onClick={() => handleDateChange('increment')}>&#62; </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  marginRight: '20px',
                  width: '275px',
                  justifyContent: 'flex-end'
                }}
              >
                {enableCreateAvailableTime &&
                  <Button
                    sx={{
                      color: '#FFFFFF',
                      border: '1px solid #FFFFFF',
                      height: '32px',
                      width: '180px',
                      minWidth: 'auto',
                      textTransform: 'none',
                      marginRight: '20px',
                    }}
                    onClick={() => handleClickCreate()}
                  >
                    + Create Available Time
                  </Button>
                }
                <Button
                  sx={{
                    color: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    height: '32px',
                    width: '60px!important',
                    textTransform: 'none'
                  }}
                  onClick={() => setCurrentDate(new Date())}
                >
                  Today
                </Button>
                {/* <img src={search} style={{width:'18px',height:'18px',marginLeft:'30px',marginTop:'5px'}}></img> */}
                {/* <Box onClick={()=>handleSearch()}>
                    <SearchIcon
                    style={{
                        color:'#FFFFFF',
                        marginLeft:'30px',
                        fontSize:'30px'
                    }} 
                    />
                </Box> */}
              </Box>
            </Box>
          </Scheduler>
        </Paper>}

      <Dialog
        open={alertDialogData.open}>
        <DialogTitle>Prompt</DialogTitle>
        <DialogContent>
          {alertDialogData.message}
          {alertDialogData.showTutorial &&
            <Link style={{ textDecoration: "underline" }} to={appConfig.paths.followTutorials}> Follow the tutorials </Link>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setAlertDialogData({ open: false, message: '', showTutorial: false }) }}>Close</Button>
        </DialogActions>
      </Dialog>

      <CreateAvailableTimeDialog
        custom={false}
        teacherId={viewTeacherID || ''}
        timeFees={privateLessonData?.time_fees||[]}
        privateLessonCategoryIDs={privateLessonData?.category_ids || []}
        startDate={startDate}
        open={showCreateDialog}
        onClickCancel={() => setShowCreateDialog(false)}
        onCreateSuccess={handleCreateTimeSuccess} />
      <Loader isLoading={loading3 || layoutLoading} />
    </>
  )
}
