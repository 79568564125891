import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import { SeriesLessonStatusEnum, UserScheduleSourceTypeEnum } from 'src/app/models/schedule'
import { MeetingTypeEnum } from 'src/pages/videoClassroom/dialog/MeetingDialog'
import { getLegalFileName } from 'src/utils/stringUtil'


export enum SeriesLessonAudioVideoFunctionEnum {
  MUTE_ALL = 'audio_mute',
  UNMUTE_ALL = 'audio_unmute',
  OPEN_ALL_VIDEO = 'video_open',
  CLOSE_ALL_VIDEO = 'video_close',
}

export const seriesLessonApi = createApi({
  reducerPath: 'seriesLessonApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getSeriesLessonCategories: builder.query<Result<any>,{pageSize?:number, startKey?: string,forwardOrder?: boolean}>({
        query: ({pageSize, startKey, forwardOrder}) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_series_lesson_category",
            request_data: {
              page_size: pageSize ?? 999,
              start_key: startKey ?? '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getSeriesLessonListByCategory: builder.query<Result<any>, {categoryId?: string,pageSize?:number, startKey?: string,forwardOrder?: boolean, schoolId?: string}>({
        query: ({categoryId, pageSize, startKey, forwardOrder, schoolId}) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_published_series_lesson",
            request_data: {
              school_id: schoolId,
              category_id: categoryId,
              page_size: pageSize ?? 10,
              start_key: startKey ?? '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getTeacherSeriesLessonList: builder.mutation<Result<any>,{teacherId: string,pageSize?:number, startKey?: string,forwardOrder?: boolean}>({
        query: ({teacherId, pageSize, startKey, forwardOrder}) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_user_series_lesson",
            request_data: {
              user_id: teacherId,
              page_size: pageSize ?? 10,
              start_key: startKey ?? '',
              forward_order: forwardOrder || false,
              status: SeriesLessonStatusEnum.Published,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getSeriesLessonDetail: builder.mutation<Result<any>,{seriesLessonId: string}>({
        query: ({seriesLessonId}) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "get_series_lesson",
            request_data: {
              id: seriesLessonId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getSemesterDetail: builder.mutation<Result<any>,{semesterId: string}>({
        query: ({semesterId}) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "get_series_lesson_classes",
            request_data: {
              id: semesterId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      // include draft，published，scheduled
      getAllSemesterList: builder.mutation<Result<any>,{seriesLessonId: string,pageSize?:number, startKey?: string,forwardOrder?: boolean}>({
        query: ({seriesLessonId, pageSize, startKey, forwardOrder}) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_series_lesson_classes",
            request_data: {
              series_lesson_id: seriesLessonId,
              page_size: pageSize ?? 10,
              start_key: startKey ?? '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),      
      
      getPublishedSemesterList: builder.mutation<Result<any>,{seriesLessonId: string,pageSize?:number, startKey?: string,forwardOrder?: boolean}>({
        query: ({seriesLessonId, pageSize, startKey, forwardOrder}) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_published_series_lesson_classes",
            request_data: {
              series_lesson_id: seriesLessonId,
              page_size: pageSize ?? 20,
              start_key: startKey ?? '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getSeriesLessonMeetingRoomId: builder.mutation<Result<any>,{classId}>({
        query: ({classId}) => ({
          url: `classes`,
          method: 'POST',
          body: {
            request_type: "get_classes_meeting_room",
            request_data: {
              id: classId
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      JoinSeriesLessonMeetingRoom: builder.mutation<Result<any>,{classroomId}>({
        query: ({classroomId}) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "join_meeting_room",
            request_data: {
              id: classroomId
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      startSeriesLessonTranscription: builder.mutation<Result<any>, { 
        classroomId: string,
        language: string,
      }>({
        query: ({ classroomId, language }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "start_transcription",
            request_data: {
              id: classroomId,
              engine: "transcribe",
              language: language || "en-US"
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),


      stopSeriesLessonTranscription: builder.mutation<Result<any>, { 
        classroomId: string,
      }>({
        query: ({ classroomId }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "end_transcription",
            request_data: {
              id: classroomId,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),


      setSeriesLessonToppingUser: builder.mutation<Result<any>, { 
        classroomId: string,
        toppingUserId: string,
      }>({
        query: ({ classroomId, toppingUserId }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "update_topping_user",
            request_data: {
              id: classroomId,
              topping_user_id: toppingUserId
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      setSeriesLessonCancelForbidShare: builder.mutation<Result<any>, { 
        classroomId: string,
      }>({
        query: ({ classroomId }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "share_allow",
            request_data: {
              id: classroomId,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      setSeriesLessonForbiddenShare: builder.mutation<Result<any>, { 
        classroomId: string,
      }>({
        query: ({ classroomId }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "share_forbidden",
            request_data: {
              id: classroomId,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      setSeriesLessonLayout: builder.mutation<Result<any>, { 
        classroomId: string,
        layout: number,
      }>({
        query: ({ classroomId, layout }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "update_layout",
            request_data: {
              id: classroomId,
              layout: layout
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      setSeriesLessonFunction: builder.mutation<Result<any>, { 
        classroomId: string,
        type: SeriesLessonAudioVideoFunctionEnum,
      }>({
        query: ({ classroomId, type }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: type,
            request_data: {
              id: classroomId,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      closeSeriesLessonMeeting: builder.mutation<Result<any>, { 
        classroomId: string,
      }>({
        query: ({ classroomId }) => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "close_meeting_room",
            request_data: {
              id: classroomId
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getAllLessonSchedule: builder.mutation<Result<any>, { 
        userId: string, startTime?: number, endTime?: number, scheduleType: string, pageSize?: number, startKey?: string, forwardOrder?: boolean
      }>({
        query: ({ userId, startTime, endTime, scheduleType, pageSize, startKey, forwardOrder }) => ({
          url: `schedule`,
          method: 'POST',
          body: {
            request_type: "select_user_schedule",
            request_data: {
              user_id: userId,
              start_time: startTime,
              end_time: endTime,
              schedule_type: scheduleType,
              page_size: pageSize || 10,
              start_key: startKey || '',
              forward_order: forwardOrder || false,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getLessonScheduleByRole: builder.mutation<Result<any>, { 
        userId: string, startTime: number, endTime?: number, roleType: string, pageSize?: number, startKey?: string, forwardOrder?: boolean
      }>({
        query: ({ userId, startTime, endTime, roleType, pageSize, startKey, forwardOrder }) => ({
          url: `schedule`,
          method: 'POST',
          body: {
            request_type: "select_user_schedule",
            request_data: {
              user_id: userId,
              start_time: startTime,
              end_time: endTime,
              role_type: roleType,
              page_size: pageSize || 10,
              start_key: startKey || '',
              forward_order: forwardOrder || false,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getPurchasedSeriesLesson: builder.mutation<Result<any>, { 
        pageSize?: number, startKey?: string, forwardOrder?: boolean
      }>({
        query: ({ pageSize, startKey, forwardOrder }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_student_series_lesson_classes",
            request_data: {
              page_size: pageSize || 10,
              start_key: startKey || '',
              forward_order: forwardOrder || false,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getMeetingRecordVideoUrl: builder.mutation<Result<any>, { 
        classId: string, meetingType: MeetingTypeEnum, startTime: number
      }>({
        query: ({ classId, meetingType, startTime }) => ({
          url: `classes`,
          method: 'POST',
          body: {
            request_type: "get_lesson_signed_url",
            request_data: {
              classes_id: classId,
              course_type: meetingType === MeetingTypeEnum.LiveVideoClass ? "broadcast" : meetingType,
              start_time: startTime,
            }
          } 
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getSeriesLessonMaterialList: builder.mutation<Result<any>, {
        seriesLessonId: string,
        lessonNum?: number,
        page_size: number,
        start_key: string,
        forwardOrder?: boolean,
      }>({
        query: ({ seriesLessonId, lessonNum, start_key, page_size, forwardOrder }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_series_lesson_material",
            request_data: {
              series_lesson_id: seriesLessonId,
              series_lesson_num: lessonNum,
              page_size: page_size || 10,
              start_key: start_key || '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getSeriesLessonClassesMaterialList: builder.mutation<Result<any>, {
        seriesLessonClassId: string,
        lessonNum?: number,
        page_size: number,
        start_key: string,
        forwardOrder?: boolean,
      }>({
        query: ({ seriesLessonClassId, lessonNum, start_key, page_size, forwardOrder }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_series_lesson_classes_material",
            request_data: {
              series_lesson_classes_id: seriesLessonClassId,
              series_lesson_num: lessonNum,
              page_size: page_size || 10,
              start_key: start_key || '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      lockSeriesLessonMaterial: builder.mutation<Result<any>, {
        seriesLessonId: string,
        materialId: string,
        lock: boolean,
        originalUserId: string|undefined,
      }>({
        query: ({ seriesLessonId, materialId, lock, originalUserId }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "lock_series_lesson_material",
            request_data: {
              series_lesson_id: seriesLessonId,
              id: materialId,
              lock: lock,
              original_user_id: originalUserId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      lockSeriesLessonClassesMaterial: builder.mutation<Result<any>, {
        seriesLessonClassId: string,
        materialId: string,
        lock: boolean,
        originalUserId?: string|undefined,
      }>({
        query: ({ seriesLessonClassId, materialId, lock, originalUserId }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "lock_series_lesson_classes_material",
            request_data: {
              series_lesson_classes_id: seriesLessonClassId,
              id: materialId,
              lock: lock,
              original_user_id: originalUserId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      studentUploadSeriesLessonFile: builder.mutation<Result<any>, {
        seriesLessonId:string,
        fileName:string,
        fileType:string,
      }>({
        query: ({ seriesLessonId, fileName, fileType }) => ({
          url: `uploadfile`,
          method: 'POST',
          body: {
            update_type: 'student_material',
            file_name: getLegalFileName(fileName),
            file_type: fileType,
            file_source_type: 'series_lesson',
            file_source_id: seriesLessonId,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      studentCreateSeriesLessonMaterial: builder.mutation<Result<any>, {
        series_lesson_id: string,
        series_lesson_classes_id: string,
        series_lesson_num: number,
        file_name: string,
        file_title:string,
        file_type: string,
        file_size: number,
        file_url: string,
      }>({
        query: ({ series_lesson_id, series_lesson_classes_id, series_lesson_num, file_name, file_type, file_size, file_url, file_title }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "create_series_lesson_student_material",
            request_data: {
              series_lesson_id: series_lesson_id,
              series_lesson_classes_id: series_lesson_classes_id,
              series_lesson_num: series_lesson_num,
              file_name: file_name,
              file_type: file_type,
              file_size: file_size,
              file_url: file_url,
              file_title:file_title,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      deleteSeriesLessonStudentMaterial: builder.mutation<Result<any>, {
        seriesLessonId: string,
        fileId: string,
      }>({
        query: ({seriesLessonId, fileId }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "delete_series_lesson_student_material",
            request_data: {
              series_lesson_id: seriesLessonId,
              id: fileId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getSeriesLessonStudentMaterials: builder.mutation<Result<any>, {
        seriesLessonId: string,
        series_lesson_classes_id?: string,
        series_lesson_num?: number,
        page_size?: number,
        start_key?: string,
        forwardOrder?: boolean,
      }>({
        query: ({seriesLessonId, series_lesson_classes_id, series_lesson_num, start_key, page_size, forwardOrder  }) => ({
          url: `series_lesson`,
          method: 'POST',
          body: {
            request_type: "select_series_lesson_student_material",
            request_data: {
              series_lesson_id: seriesLessonId,
              series_lesson_classes_id,
              series_lesson_num,
              page_size: page_size || 10,
              start_key: start_key || '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const {
  useGetSeriesLessonCategoriesQuery,
  useGetSeriesLessonListByCategoryQuery,
  useGetTeacherSeriesLessonListMutation,
  useGetSeriesLessonDetailMutation,
  useGetSemesterDetailMutation,
  useGetAllSemesterListMutation,
  useGetPublishedSemesterListMutation,
  useGetSeriesLessonMeetingRoomIdMutation,
  useJoinSeriesLessonMeetingRoomMutation,
  useStartSeriesLessonTranscriptionMutation,
  useStopSeriesLessonTranscriptionMutation,
  useSetSeriesLessonCancelForbidShareMutation,
  useSetSeriesLessonForbiddenShareMutation,
  useSetSeriesLessonToppingUserMutation,
  useSetSeriesLessonLayoutMutation,
  useSetSeriesLessonFunctionMutation,
  useCloseSeriesLessonMeetingMutation,
  useGetAllLessonScheduleMutation,
  useGetLessonScheduleByRoleMutation,
  useGetPurchasedSeriesLessonMutation,
  useGetMeetingRecordVideoUrlMutation,
  useGetSeriesLessonMaterialListMutation,
  useGetSeriesLessonClassesMaterialListMutation,
  useLockSeriesLessonMaterialMutation,
  useLockSeriesLessonClassesMaterialMutation,
  useStudentUploadSeriesLessonFileMutation,
  useStudentCreateSeriesLessonMaterialMutation,
  useDeleteSeriesLessonStudentMaterialMutation,
  useGetSeriesLessonStudentMaterialsMutation,
} = seriesLessonApi
