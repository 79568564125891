import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { RelatedCard1 } from 'src/components/cards'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { RelatedClassEntity } from '@dokyo/common'
import { useWindowSize } from 'react-use'
import nextArrowImg from 'src/images/homeSecond/slider/ico-next.png'
import prevArrowImg from 'src/images/homeSecond/slider/ico-pre.png'

const useStyles = makeStyles(theme => ({
    slider: {
        width: '100%',
        margin: 'auto',
        textAlign: 'left',

        '& .slick-arrow': {
            zIndex: '1',
            '&:before': {
                fontSize: '60px',
                color: 'black',
                content: "''"
            }
        },
        '& .slick-list': {
            margin: '0 0 0 calc(-3 * 4 * 1px)'
        },
        '& .slick-track': {
            display: 'inline-flex'
        }
    },
    slider__content: {
        margin: 'auto'
    },

    card__body: {}
}))

interface PropsForm {
    classList: RelatedClassEntity[],
    learnType: number, //1-Course; 2-video broadcast; 3-live broadcast; 4-video classroom
    emitEmptyNext?: (id: string) => void,
    emitId?: string,
    arrowTop?: number,
    maxShowNumber: number,
    itemWidth: number,
    marginCount: number,
}

function getSlideShow(width: number, marginCount: number, itemWidth: number, slidesToShow: number, maxShowNumber: number): number {
    const newSlidesToShow = Math.ceil((width - marginCount) / itemWidth);
    if (newSlidesToShow !== slidesToShow) {
        // setSlidesToShow(newSlidesToShow);
        return newSlidesToShow <= maxShowNumber ? newSlidesToShow : maxShowNumber;
    } else {
        return slidesToShow;
    }
}

export const RelatedClassSlider1 = (props: PropsForm) => {
    const { classList, learnType, emitEmptyNext, emitId, arrowTop, maxShowNumber, itemWidth, marginCount, } = props;
    const classes = useStyles()
    const navigate = useNavigate()
    const [isSwiping, setIsSwiping] = useState(false);

    const { width } = useWindowSize();
    const [slidesToShow, setSlidesToShow] = useState<number>(getSlideShow(width, marginCount, itemWidth, 0, maxShowNumber))
    useEffect(() => {
        const newSlidesToShow = getSlideShow(width, marginCount, itemWidth, slidesToShow, maxShowNumber);
        setSlidesToShow(newSlidesToShow);
    }, [width, marginCount, itemWidth, maxShowNumber])



    const SampleNextArrow = props => {
        const { className, style, onClick, top } = props;
        useEffect(() => {
            if (!onClick) {
                // empty next
                if (emitEmptyNext) {
                    emitEmptyNext(emitId ?? '')
                }
            }
        }, [onClick]);
        return (
            <Box
                className={className}
                style={{
                    ...style,
                    width: '40px',
                    height: '40px',
                    background: '#F93B57',
                    borderRadius: '50%',
                    display: onClick === null ? "none" : "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                    top: 'calc(50% - 56px)'
                }}
                onClick={onClick}
                component="img"
                src={nextArrowImg}
            >
                {/* <ArrowForwardIosIcon
                    style={{
                        color: 'white',
                        width: '50%',
                        height: '50%',
                    }}
                /> */}
            </Box>
        )
    }
    const SamplePrevArrow = props => {
        const { className, style, onClick, top } = props
        return (
            <Box
                className={className}
                style={{
                    ...style,
                    width: '40px',
                    height: '40px',
                    background: '#F93B57',
                    borderRadius: '50%',
                    display: onClick === null ? "none" : "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                    top: 'calc(50% - 56px)'
                }}
                onClick={onClick}
                component="img"
                src={prevArrowImg}
            >
                {/* <ArrowForwardIosIcon
                    style={{
                        color: 'white',
                        width: '50%',
                        height: '50%',
                        transform: 'rotate(180deg)'
                    }}
                /> */}
            </Box>
        )
    }

    const settings = {
        // dots: true,
        infinite: false,
        // swipeToSlide: true,
        speed: 300,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        initialSlide: 0,
        nextArrow: <SampleNextArrow top={arrowTop} />,
        prevArrow: <SamplePrevArrow top={arrowTop} />
    }

    const addVideoToCart = (video: RelatedClassEntity) => {
    }

    const handleClick = (entity: RelatedClassEntity) => {
        if (isSwiping) {
            return
        }
        if (entity.type === 0) {
            return
        } else if (entity.type === 1) {//course
            navigate('/classdetail?id=' + entity.id)
        } else if (entity.type === 2) {//video broadcast
            navigate('/videobroadcast?id=' + entity.id)
        } else if (entity.type === 3) {//live broadcast
            navigate("/livebroadcast?id=" + entity.id)
        } else if (entity.type === 4) { // video classroom
            navigate("/videoclassroom?id=" + entity.id)
        }
        // navigate('/videobroadcast?id=' + "6aab4ba1-0300-4efb-9d87-fb3da43dd551")
    }

    return (
        <Box className={classes.slider}>
            <Box className={classes.slider__content}>
                <Slider
                    {...settings}
                    afterChange={() => setIsSwiping(false)}
                    onSwipe={() => setIsSwiping(true)}
                >
                    {classList?.map((v, i) => (
                        <Box data-id={v.id} key={v.id}>
                            <RelatedCard1
                                key={v.id}
                                elevation={0}
                                classData={v}
                                learnType={learnType}
                                className={classes.card__body}
                                onCartAdded={addVideoToCart}
                                onClick={() => handleClick(v)}
                            />
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    )
}
