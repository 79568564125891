import { SeriesEntity } from '@dokyo/common';
import { TeacherItem } from 'src/app/models/category';

export function SeriesSortByOrder(seriesOne: SeriesEntity, seriesTwo: SeriesEntity)  {
    if (seriesOne.series_order > seriesTwo.series_order){
      return 1;
    }
  
    if (seriesOne.series_order < seriesTwo.series_order){
      return -1;
    }
    return 0;
}

export function TeacherSortByOrder(teacherOne: TeacherItem, teacherTwo: TeacherItem)  {
    if (teacherOne.order > teacherTwo.order){
      return 1;
    }
  
    if (teacherOne.order < teacherTwo.order){
      return -1;
    }
    return 0;
}