import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Salt, Value } from '@dokyo/common'

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getPasscode: builder.mutation<Result<Salt>, { userId: string }>({
        query: ({ userId }) => ({
          url: `signinpw`,
          method: 'POST',
          body: { user_id: userId, client_type: 3 }
        }),
        transformResponse: (res: Response<Salt>) => new Value(res)
      }),
      login: builder.mutation<Result<{ token: string, refresh_token:string }>, { userId: string; passwordHash: string, verificationCode?: string }>({
        query: ({ userId, passwordHash, verificationCode }) => ({
          url: `signinpw`,
          method: 'POST',
          body: { user_id: userId, client_type: 3, password_hash: passwordHash, code: verificationCode }
        }),
        transformResponse: (res: Response<{ token: string, refresh_token:string }>) => new Value(res)
      }),
      refreshToken: builder.mutation<Result<{ token: string, refresh_token:string }>, { userId: string; refreshToken: string }>({
        query: ({ userId, refreshToken }) => ({
          url: `signin`,
          method: 'POST',
          body: { user_id: userId, refresh_token: refreshToken }
        }),
        transformResponse: (res: Response<{ token: string, refresh_token:string }>) => new Value(res)
      })
    }
  }
})

export const { useGetPasscodeMutation, useLoginMutation, useRefreshTokenMutation } = authApi
