import React from 'react';
import PaginationDot from './SliderPagenationDot';
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: 20,
        display: 'flex',
        flexDirection: 'row',
      },
}))

interface PaginationProps {
    dots:number,
    index:number,
    onChangeIndex:Function
}

const Pagination: React.FC<PaginationProps> = ({dots, index, onChangeIndex, ...props}) => {
    const classes=useStyles()
    const handleClick = (index:number) => {
        onChangeIndex(index);
    };

    const children:JSX.Element[] = [];

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot key={i} index={i} active={i === index} onClick={handleClick} />,
      );
    }

    return (
       <div className={classes.root}>
           {children}
        </div>
    )
}

export default Pagination;