import React from 'react'
import { TeacherList } from './TeacherList'
import { useGetMasterTeacherPageQuery } from 'src/app/service/real/teacher.slice'
import { TeacherEntity } from '@dokyo/common'


export interface Props {
  categoryId: string,
  startKey: string,
  onDataLoad: (startKey: string) => void,
  category_tittle?: string,
}

export const TeacherListByCategoryId: React.FC<Props> =({ categoryId, startKey, onDataLoad, category_tittle }) => {

  const { data, isFetching } = useGetMasterTeacherPageQuery({ startKey, categoryId, pageSize: 12}, {
    refetchOnMountOrArgChange: 60
  })

  let teacherList: TeacherEntity[] = []
  if (data && !data.isFailed && data.result?.master_teacher[0]) {
    if (data.result?.master_teacher[0]?.teacher_list) {
      teacherList = data.result?.master_teacher[0].teacher_list
    } 
    
  }

  React.useEffect(() => {
    if (data) {
      onDataLoad(data?.result?.master_teacher[0]?.start_key?? '')
    }
  }, [data])

  return (
    <TeacherList isSeries={false} isLoading={isFetching} teacherList={teacherList} category_tittle={category_tittle}/>
  )
}