
import React, { useEffect, useState } from 'react';
import { Theme, Box, Typography, Grid, Button,} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loader } from 'src/components/loader'
import { getScheduleType, ScheduleEnum } from 'src/app/models/schedule'
import { getDateInWordDescription, getTimeInWordDescription } from 'src/utils/timeUtil';
import SvgComponent from 'src/components/svgComponent';
import { useNavigate } from 'react-router-dom';
import { useGetTeacherAllScheduledClassMutation } from 'src/app/service/real/teacher.slice';
import { BroadcastEntity } from '@dokyo/common';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  minutesText: {
    fontSize: "14px !important",
    fontFamily: "ArialMT !important",
    color: "#555555",
    marginRight: 10,
    lineHeight: '24px !important',
  },
  priceText: {
    fontSize: "14px !important",
    fontWeight: "bold !important",
    fontFamily: "ArialMT !important"
  },
  introduction: {
    marginTop: "10px !important",
    textAlign: "left",
    fontSize: "14px !important",
    fontFamily: 'ArialMT !important',
    color: '#000000 !important',
    lineHeight: '24px !important',
  },
  listBox: {
    marginTop: '30px',
  },
  listTitleItemBox: {
    marginBottom: "35px !important",
  },
  timeTitle: {
    textAlign: "left",
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "20px !important",
    lineHeight: "23px !important",
    marginBottom: "15px !important",
  },
  listItemBox: {
    width: "100%",
    borderRadius: "12px",
    height: 'fit-content',
    display: "flex",
    flexDirection: "row",
    padding: "16px 0 16px 16px",
    position: "relative"
  },
  listItemMidBox: {
    marginLeft: "14px",
  },
  listItemTime: {
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "16px !important",
    color: "#307DCF !important",
    textAlign: "left",
  },
  listItemMessage: {
    marginTop: "13px !important",
    fontFamily: "ArialMT !important",
    fontSize: "14px !important",
    height: "16px !important",
    textAlign: "left",
    color: "black",
  },
  buttonBox: {
    marginTop: "25px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  },
  viewDetailsBtn: {
    minWidth: "72px !important",
    height: "36px !important",
    background: "#307DCF !important",
    color: 'white !important',
    fontFamily: 'ArialMT !important',
    fontSize: '12px !important',
    fontWeight: 'bold !important',
    boxShadow: 'none !important',
    borderRadius: '18px !important',
  },
  refundText: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'flex-start', 
    width: '100%', 
    margin: '15px 0',
    color: '#307DCF',
    fontSize:'14px',
    fontFamily: 'Avenir-Book, Avenir',
  },
  refundTextInfo:{
    [theme.breakpoints.down('sm')]: {
      width:"90%",
      marginTop: "-3px",
      textAlign: "left"
    }
  },
}))

interface FormattedScheduleEntity {
  id: string,
  start_time: number, // million second
  title: string,
  status: number,
  schedule: ScheduleEnum,
}

interface TimeScheduleEntity {
  time: number; // million second
  list: FormattedScheduleEntity[]
}

interface Props {
  teacherId: string;
}

const TeacherLiveVideoClassList: React.FC<Props> = ({ teacherId }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [dataList, setDataList] = useState<TimeScheduleEntity[]>([]);

  const [getTeacherAllScheduledClass, {isLoading: loading1}] = useGetTeacherAllScheduledClassMutation();
  
  useEffect(() => {
    if (teacherId) {
      handleSearch();
    }
  }, [teacherId])

  const handleSearch = async () => {
    getTeacherAllScheduledClass({
      teacher_id: teacherId,
      start_time: Math.floor((new Date().getTime() - 2 * 60 * 60 * 1000)/1000), // 2 hours ago
      end_time: Math.floor((new Date().getTime() + 365 * 24 * 60 * 60 * 1000)/1000), // 1 year
      page_size: 999, // this API has no start_key
    })
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const schedules = dataRes.result.teacher_schedule;
        if (schedules?.length > 0) {
          let allItems: FormattedScheduleEntity[] = [];
          schedules.forEach(schedule => {
            if (schedule.title === 'live') {
              const liveSchedules = schedule.live_schedule;
              if (liveSchedules?.length > 0) {
                liveSchedules.forEach(element => {
                  const broadcasts: BroadcastEntity[] = element.list;
                  const items = broadcastToFormatSchedule(broadcasts);
                  allItems = allItems.concat(items);
                });
              }
            } else if (schedule.title === 'meeting') {
              const meetingSchedule = schedule.meeting_schedule;
              if (meetingSchedule?.length > 0) {
                meetingSchedule?.forEach(element => {
                  const meetings: any[] = element.list;
                  const items = videoClassroomToFormatSchedule(meetings);
                  allItems = allItems.concat(items);
                });
              }
            }
          });
          const timeSchedules = getCardList(allItems);
          setDataList(timeSchedules);
        } else {
          setDataList([]);
        }
      } else {
        setDataList([]);
      }
    })

  }

  const broadcastToFormatSchedule = (list: BroadcastEntity[]): FormattedScheduleEntity[] => {
    if (!list || list.length === 0) {
      return [];
    }
    return list.map(item => {return {
      id: item.broadcast_id,
      start_time: item.broadcast_start_time * 1000,
      title: item.broadcast_title,
      status: item.broadcast_status,
      schedule: ScheduleEnum.Broadcast,
    }})
  }

  const videoClassroomToFormatSchedule = (list: any[]): FormattedScheduleEntity[] => {
    if (!list || list.length === 0) {
      return [];
    }
    return list.map(item => {return {
      id: item.meeting_info?.meeting_id,
      start_time: item.meeting_start_time * 1000,
      title: item.meeting_title,
      status: item.meeting_status,
      schedule: ScheduleEnum.ChimeMeeting,
    }})
  }

  const getCardList = (list: FormattedScheduleEntity[]): TimeScheduleEntity[] => {
    const resultList: TimeScheduleEntity[] = formatTimeSchedule(list);
    resultList.sort((a, b) => a.time - b.time);
    resultList.forEach(item => {
      item.list.sort((a, b) => a.start_time - b.start_time);
    })
    setDataList(resultList);
    return resultList;
  }

  const formatTimeSchedule = (list: FormattedScheduleEntity[]): TimeScheduleEntity[] => {
    const resultList: TimeScheduleEntity[] = [];
    if (list && list.length > 0) {
      list.forEach(schedule => {
        const date = new Date(schedule.start_time);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const findDaySchedule = resultList.find(v => {
          const vDate = new Date(v.time);
          return year === vDate.getFullYear() && month === vDate.getMonth() && day === vDate.getDate();
        })

        if (findDaySchedule) {
          findDaySchedule.list.push(schedule);
        } else {
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          const newDaySchedule: TimeScheduleEntity = {
            time: date.getTime(),
            list: [schedule],
          }
          resultList.push(newDaySchedule);
        }
      })
    }

    return resultList;
  }

  const handleClickViewDetails = (item: FormattedScheduleEntity) => {
    if (item.schedule === ScheduleEnum.Broadcast) {
      navigate("/livebroadcast?id=" + item.id)
    } else if (item.schedule === ScheduleEnum.ChimeMeeting) {
      navigate("/videoclassroom?id=" + item.id)
    }
  }

  return (
    <Box className={classes.container}>

      <Box className={classes.refundText}>
        <SvgComponent iconName="ico-info-blue" style={{width: '18px', height: '18px', marginRight: '8px'}}/>
        <Box className={classes.refundTextInfo}>
          Live video classes and live streams witin 1 year from now will be shown.
        </Box>
      </Box>

      {dataList?.length > 0 &&
        <Box className={classes.listBox}>
          {dataList.map(data => (
            <Box key={data.time} className={classes.listTitleItemBox}>
              <Typography className={classes.timeTitle}>{getDateInWordDescription(data.time)}</Typography>
              <Grid container display='flex' justifyContent='left' spacing={10}>
                {data.list?.length > 0 && data.list.map(item => (
                  <Grid key={item.id} item xs={12} sm={6} md={6} lg={6} xl={6} justifyContent='center' display='flex'>
                    <Box className={classes.listItemBox} sx={item.schedule === ScheduleEnum.Broadcast ? {border: "1px solid #E0EDBD" } : {border: '1px solid #BDF3EB'}}>
                      <SvgComponent iconName='ico-time-clock' style={{ width: "24px", height: "24px" }} />
                      <Box className={classes.listItemMidBox}>
                        <Typography className={classes.listItemTime}>{getTimeInWordDescription(item.start_time)}</Typography>
                        <Box style={{ display: "flex", alignItems: "baseline" }}>
                          <Typography className={classes.listItemMessage}>{getScheduleType(item.schedule)}</Typography>
                        </Box>
                        <Typography className={classes.listItemMessage}>{item.title}</Typography>

                        <Box className={classes.buttonBox}>
                          <Button
                            onClick={() => handleClickViewDetails(item)}
                            className={classes.viewDetailsBtn}
                            sx={{ textTransform: 'none' }}
                            variant="contained">
                            View Details
                          </Button>

                        </Box>
                      </Box>

                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
      }

      {(!dataList || dataList.length === 0) &&
        <Box
          sx={{
            width: '100%',
            height: '300px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* <img src={noCourse} alt="no_data"></img> */}
          <Typography
            sx={{
              fontSize: '18px',
              fontFamily: 'ArialMT',
              color: '#555555',
              lineHeight: '21px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >No class yet.</Typography>
        </Box>
      }

      <Loader isLoading={loading1} />
    </Box>

  )
}

export default TeacherLiveVideoClassList;