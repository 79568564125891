import { FC, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import {
    useAudioVideo,
    useLogger
} from 'amazon-chime-sdk-component-library-react';
import { SelectChangeEvent } from '@mui/material/Select';
import SelectInput from '../../deviceInput/SelectInput';

const VIDEO_INPUT_QUALITY = {
    '360p': '360p (nHD) @ 15 fps (600 Kbps max)',
    '540p': '540p (qHD) @ 15 fps (1.4 Mbps max)',
    '720p': '720p (HD) @ 15 fps (1.4 Mbps max)',
    '1080p': '1080p (HD) @ 15 fps (4 Mbps max)',
};

export type VideoQuality = '360p' | '540p' | '720p' | '1080p';

const QualitySelection: FC = () => {
    // const selectVideoQuality = useSelectVideoQuality();
    const [videoQuality, setVideoQuality] = useState('unselected');
    const audioVideo = useAudioVideo();
    const logger = useLogger();

    const deviceList = [
        {
          label: 'Select video quality',
          value: 'unselected',
        },
        {
          label: VIDEO_INPUT_QUALITY['1080p'],
          value: '1080p',
        },
        {
          label: VIDEO_INPUT_QUALITY['720p'],
          value: '720p',
        },
        {
          label: VIDEO_INPUT_QUALITY['540p'],
          value: '540p',
        },
        {
          label: VIDEO_INPUT_QUALITY['360p'],
          value: '360p',
        },
    ];

    const selectVideoQuality = useCallback(
      (quality: VideoQuality) => {
        if (!audioVideo) {
          return;
        }
  
        logger.info(`Selecting video quality: ${quality}`);
  
        switch (quality) {
          case '360p':
            audioVideo.chooseVideoInputQuality(640, 360, 15);
            audioVideo.setVideoMaxBandwidthKbps(600);
            break;
          case '540p':
            audioVideo.chooseVideoInputQuality(960, 540, 15);
            audioVideo.setVideoMaxBandwidthKbps(1400);
            break;
          case '720p':
            audioVideo.chooseVideoInputQuality(1280, 720, 15);
            audioVideo.setVideoMaxBandwidthKbps(1400);
            break;
          case '1080p':
            audioVideo.chooseVideoInputQuality(1980, 1080, 15);
            audioVideo.setVideoMaxBandwidthKbps(4096);
            break;
          default:
            logger.warn(`Unsupported video quality: ${quality}`);
        }
      },
      [audioVideo]
    );

    const handleSelect = async(e: SelectChangeEvent<string>): Promise<void> => {
        const quality = e.target.value as VideoQuality;
        setVideoQuality(quality);
        selectVideoQuality(quality);
    };

    
    return (
        <Box>
            <SelectInput 
                label='Video quality'
                inputId='video-quality-selection-select'
                deviceList={deviceList}
                onChange={handleSelect}
                value={videoQuality}
            />
        </Box>
    )
}


export default QualitySelection;