import Introduction from "./Introduction";
import ChooseCourse from "./ChooseCourse";
import Instructor from "./Instructor";
import AboutCourse from "./AboutCourse";
import RecordedClasses from "./RecordedClasses";
import WhatParentsSayAboutTeacher from "./WhatParentsSayAboutTeacher";
const TeacherSampleAbout: React.FC = () => {
  return (
    <>
      <Introduction />
      <ChooseCourse />
      <Instructor/>
      <AboutCourse/>
      {/* <RecordedClasses /> */}
      <WhatParentsSayAboutTeacher />
    </>
  )
}
export default TeacherSampleAbout;