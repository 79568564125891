import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'

export const getSchoolsInfo = createApi({
  reducerPath: 'schoolInfoApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getSchoolPostList: builder.mutation<Result<any>, {
        request_school_id: string,
        request_user_id:string,
        page_size?:number,
        start_key?:string
      }>({
        query: ({ request_school_id,request_user_id,page_size,start_key }) => ({
          url: 'getuserpostinfo',
          method: 'POST',
          body: {
            user_id:request_school_id,
            request_user_id:request_user_id,
            request_type: 2,
            page_size: page_size,
            start_key:start_key
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      uploadSchoolPost: builder.mutation<Result<any>, {
        request_school_id?: string,
        message_type:string,
        message_content?:string,
        userid?:string,
        message_title?:string,
        thumb_up?:boolean,
        parent_message_id?:string
      }>({
        query: ({ request_school_id,message_title,message_type,message_content,userid, thumb_up, parent_message_id  }) => ({
          url: 'uploaduserpostinfo',
          method: 'POST',
          body: {
            user_id:userid,
            request_type: 2,
            message_title:message_title,
            message_type:message_type,
            message_content:message_content,
            request_id:request_school_id,
            thumb_up:thumb_up,
            parent_message_id:parent_message_id
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      deleteUserPostInfo: builder.mutation<Result<any>, {
        user_id: string,
        message_id:string,
      }>({
        query: ({ user_id, message_id }) => ({
          url: 'deleteuserpostinfo',
          method: 'POST',
          body: {
            user_id:user_id,
            message_id:message_id,
            request_type:2
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { 
  useGetSchoolPostListMutation,
  useUploadSchoolPostMutation,
  useDeleteUserPostInfoMutation
} = getSchoolsInfo
