import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settings } from '../settings'
import { School } from 'src/app/models/index'

const keys = settings.keys

interface SchoolTab {
  school_id?: string,
  tabValue: string,
}

interface SchoolState {
  school?: School
  schoolTabValue?: SchoolTab,
}

const defaultSchool:School ={
    school_id: '',
    school_name: '',
    school_picture: '',
    school_introduction: '',
    school_privilege: 1,
    school_about: '',
}

const defaultSchoolTab: SchoolTab = {
  school_id: '',
  tabValue: '',
}

function _initialState(): SchoolState {
    const schoolRaw = sessionStorage.getItem(keys.school)
    const schoolTabRaw = sessionStorage.getItem(keys.schoolTab)
    if (schoolRaw !== null) {
      return  {
        school: JSON.parse(schoolRaw) as School,
        schoolTabValue:  schoolTabRaw? JSON.parse(schoolTabRaw) as SchoolTab : defaultSchoolTab,
      }
    } 
    return {
        school: defaultSchool,
        schoolTabValue: defaultSchoolTab,
      }
}

const schoolSlice = createSlice({
  name: keys.school,
  initialState: _initialState(),
  reducers: {
    setSchool(state, action: PayloadAction<School>) {
      state.school = action.payload
      state.schoolTabValue = defaultSchoolTab
      sessionStorage.setItem(keys.school, JSON.stringify(action.payload))
    },
    clearSchool(state) {
      state = {school: defaultSchool}
      sessionStorage.removeItem(keys.school)
    },
    setSchoolTabValue(state, action: PayloadAction<SchoolTab>) {
      state.schoolTabValue = action.payload;
      sessionStorage.setItem(keys.schoolTab, JSON.stringify(action.payload))
    },
    clearSchoolTabValue(state) {
      state = {school: defaultSchool}
    }
  }
})

export const { setSchool, clearSchool, setSchoolTabValue, clearSchoolTabValue } = schoolSlice.actions
export default schoolSlice.reducer
