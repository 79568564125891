import { FC, useState, useEffect } from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/system";
import { useWindowSize } from 'react-use';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import nextArrowImg from 'src/images/homeSecond/slider/ico-next.png'
import prevArrowImg from 'src/images/homeSecond/slider/ico-pre.png'

const useStyles = makeStyles(theme => ({
    slider: {
        width: '100%',
        margin: 'auto',
        textAlign: 'left',

        '& .slick-arrow': {
            zIndex: '1',
            '&:before': {
                fontSize: '60px',
                color: 'black',
                content: "''"
            }
        },
        '& .slick-list': {
            // margin: '0 0 0 calc(-3 * 4 * 1px)'
            marginLeft: '-12px',
        },
        '& .slick-track': {
            display: 'inline-flex'
        }
    },
    slider__content: {
        margin: 'auto'
    },

    card__body: {}
}));

const NextArrow = (props) => {
    const { className, style, onClick, top } = props;
    return (
        <Box
            className={className}
            style={{
                ...style,
                display: onClick === null ? "none" : "block",
                width: '40px',
                height: '40px',
                background: '#F93B57',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                top: 'calc(50% - 56px)'
            }}
            onClick={onClick}
            component="img"
            src={nextArrowImg}
        // onClick={onClick}
        >
            {/* <ArrowForwardIosIcon
                style={{
                    color: 'white',
                    width: '50%',
                    height: '50%',
                    transform: 'rotate(180deg)'
                }}
            /> */}
        </Box>
    )
}

const PrevArrow = (props) => {
    const { className, style, onClick, top } = props;
    return (
        <Box
            className={className}
            style={{
                ...style,
                display: onClick === null ? "none" : "block",
                width: '40px',
                height: '40px',
                background: '#F93B57 !important',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                top: 'calc(50% - 56px)'
            }}
            onClick={onClick}
            component="img"
            src={prevArrowImg}
        // onClick={onClick}
        >
        </Box>
    )
}

interface Params {
    arrowTop?: number,
    maxShowNumber: number,
    itemWidth: number,
    marginCount: number,
}

function getSlideShow(width: number, marginCount: number, itemWidth: number, slidesToShow: number, maxShowNumber: number): number {
    const newSlidesToShow = Math.ceil((width - marginCount) / itemWidth);
    if (newSlidesToShow !== slidesToShow) {
        // setSlidesToShow(newSlidesToShow);
        return newSlidesToShow <= maxShowNumber ? newSlidesToShow : maxShowNumber;
    } else {
        return slidesToShow;
    }
}

export const SeriesCourseSlider: FC<Params> = ({ arrowTop, maxShowNumber, itemWidth, marginCount, children }) => {
    const classes = useStyles()
    const { width } = useWindowSize();

    const [slidesToShow, setSlidesToShow] = useState<number>(getSlideShow(width, marginCount, itemWidth, 0, maxShowNumber))

    useEffect(() => {
        const newSlidesToShow = getSlideShow(width, marginCount, itemWidth, slidesToShow, maxShowNumber);
        setSlidesToShow(newSlidesToShow);
    }, [width, marginCount, itemWidth, maxShowNumber])

    const settings = {
        // dots: true,
        infinite: false,
        // swipeToSlide: true,
        speed: 300,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        initialSlide: 0,
        // variableWidth: true,
        nextArrow: <NextArrow top={arrowTop} />,
        prevArrow: <PrevArrow top={arrowTop} />,
    };


    return (
        <Box className={classes.slider}>
            <Box className={classes.slider__content}>
                <Slider
                    {...settings}
                >
                    {children}
                </Slider>
            </Box>
        </Box>
    );
}

