import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { Backdrop, Box, CircularProgress, Typography, Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "bule",
      backgroundColor: "rgba(255,255,255,0.2)",
    },
  }),
);

export interface LoaderProps {
  children?: ReactElement | ReactNode;
  isLoading?: boolean;
}

export default function Loader(LoaderProps): ReactElement {
  const { isLoading } = LoaderProps;
  const classes = useStyles();

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={!!(isLoading)}
      >
        <Box justifyContent="center" flexWrap="nowrap">
          <Box
            display="flex"
            justifyContent="center"
          >
          <CircularProgress/>
          </Box>
          <Typography>Loading</Typography>
        </Box>
      </Backdrop>
    </>
  );
}
