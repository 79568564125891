import * as React from 'react'
import { useState} from 'react'
import { Box, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isMobileDevice } from 'src/utils/deviceUtil'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import TeacherSampleAbout from './about/TeacherSampleAbout'
import { RawUser, TeacherAboutEntity } from 'src/app/models'
import { getDisplayUserName, isJSON } from 'src/utils/stringUtil'
import { SeriesEntity } from '@dokyo/common'
import SvgComponent from 'src/components/svgComponent'
import { OtherCourses } from '../course/courseInfo/OtherCourses'
import defaultAvatar from 'src/images/teacher/defaultAvatar.png'

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '50px 10px 50px 10px',
    }
  },
  instructor:{
    fontFamily: 'venir-Book, Avenir',
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#307DCF',
  },
  teacherInfoBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column-reverse',
    }
  },
  courseInfoBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column',
    }
  },
  teacherPhoto: {
    width: 'auto',
    maxWidth: '50%',
    height: '360px',
    marginRight: '40px',
    marginTop: '90px',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
      marginRight: '20px',
      marginTop: '20px',
    }
  },  
  coursePhoto: {
    width: 'auto',
    maxWidth: '50%',
    height: '261px',
    marginLeft: '40px',
    marginTop: '90px',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
      marginLeft: '0px',
      marginTop: '20px',
    }
  },
  teacherInfoRightBox: {
    flexGrow: 1,
  },
  teacherName: {
    textAlign: 'left',
    marginTop: '23px',
    fontFamily: 'venir-Book, Avenir',
    fontSize: '40px',
    fontWeight: 900,
    color: '#000000',
  },
  teacherDesc: {
    textAlign: 'left',
    marginTop: '30px',
    fontFamily: 'venir-Book, Avenir',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#555555',
    [theme.breakpoints.down('sm')]:{
      fontSize: '14px',
    }
  },
  viewButton: {
    marginTop: "50px !important",
    width: "172px !important",
    height: "48px !important",
    background: "#307DCF !important",
    borderRadius: "24px !important",
    color: "white !important",
    fontSize: "14px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      width: "108px !important",
      height: "30px !important",
      borderRadius: "15px !important",
      fontSize: "10px !important",
    }
  },
})
)

interface Props {
  teacherProfile: RawUser|undefined
  sampleCourse: SeriesEntity|undefined
}

export const TeacherAbout:React.FC<Props> = ({teacherProfile, sampleCourse}) => {
  const classes=useStyles()
  const navigate = useNavigate();
  const [teacherAbout1, setTeacherAbout1] = useState("");
  const [teacherAbout2, setTeacherAbout2] = useState("");

  React.useEffect(() => {
    if (teacherProfile && teacherProfile.about) {
      updateTeacherAbout(teacherProfile.about);
    }
  }, [teacherProfile])

  const updateTeacherAbout = (about: string) => {
    const isJsonString = isJSON(about);
    if (isJsonString) {
      const aboutObj: TeacherAboutEntity = JSON.parse(about);
      setTeacherAbout1(aboutObj.description1);
      setTeacherAbout2(aboutObj.description2);

    } else {
      if (!about || about.length < 20) {
        setTeacherAbout1(about);
        return;
      }
      const length = about.length;
      const midIndex = length / 2;
      let dividedIndex = midIndex;
      for (let index = midIndex; index < length; index++) {
        const c = about.charAt(index);
        if (c === ".") {
          dividedIndex = index + 1;
          break;
        }
      }

      setTeacherAbout1(about.substring(0, dividedIndex));
      setTeacherAbout2(about.substring(dividedIndex));
    }
  }

  return (
    <Box sx={isMobileDevice() && {minHeight:"100vh"}}>
      {teacherProfile?.user_id === "ManuelaSadovnik" ? 
      <TeacherSampleAbout/>
      :
      <Box>
        {/* Teacher introduction */}
        <Box className={classes.container}>
          <Box className={classes.teacherInfoBox}>
            <img src={teacherProfile?.user_avatar_hd || teacherProfile?.user_avatar || defaultAvatar} className={classes.teacherPhoto} alt="teacher_avatar" />
            <Box className={classes.teacherInfoRightBox}>
              <Box className={classes.instructor}>Instructor</Box>
              <Box className={classes.teacherName}>{getDisplayUserName(teacherProfile?.user_name||'', teacherProfile?.user_id||'')}</Box>
              {teacherAbout1 ?
              <Box className={classes.teacherDesc}>
                {teacherAbout1}
                <br/><br/>
                {teacherAbout2||''}
              </Box>
              :
              <Box className={classes.teacherDesc}>
                {teacherProfile?.personalized_signature}
              </Box>
              }
            </Box>
          </Box>
        </Box>

        {/* teacher course */}
        {/* {sampleCourse &&
        <Box style={{background:"#f9f9f9", width: '100%'}}>
          <Box className={classes.container}>
            <Box className={classes.courseInfoBox}>
              <Box className={classes.teacherInfoRightBox}>
                <Box className={classes.instructor}>Course</Box>
                <Box className={classes.teacherName}>{sampleCourse?.series_title||''}</Box>
                <Box className={classes.teacherDesc}>
                  {sampleCourse?.series_introduction}
                </Box>
              </Box>              
              <img src={sampleCourse?.series_thumbnail} className={classes.coursePhoto} alt="course_thumbnail" />
            </Box>
            <Button 
              className={classes.viewButton} 
              sx={{textTransform:"none"}}
              onClick={() => navigate(appConfig.paths.course + "?id=" + sampleCourse?.series_id)}>
              View Details
              <SvgComponent iconName='ico-next-small' style={{width:"8px", height:"13px", marginLeft: "10px"}}/>
            </Button>
          </Box>
        </Box>
        } */}

        {/* Other courses by this teacher */}
        {/* {sampleCourse &&
        <OtherCourses 
          courseId={sampleCourse?.series_id} 
          teacher={{teacher_id: teacherProfile?.user_id||'', teacher_name: teacherProfile?.user_name||'', teacher_avatar: teacherProfile?.user_avatar||''}}/>
        } */}
      </Box>      
      }

    </Box>
    )
}