
import React, { useEffect, useState } from 'react';
import { Theme, Box, Typography, List, ListItem, Button, Menu, MenuItem, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loader } from 'src/components/loader'
import { BookStatusEnum, getScheduleTypeText, ScheduleLessonTypeEnum, UserInfo, UserScheduleEntity, UserScheduleSourceTypeEnum } from 'src/app/models/schedule'
import { useSelector } from 'src/app/toolkit/store'
import { useSnackbar } from 'notistack';
import { ListPagination } from 'src/components/pagination';
import { getMonthInWord, getTimeAreaDesc } from 'src/utils/timeUtil';
import IconPaid from 'src/images/class/bg-label-paid.png'
import IconUnPaid from 'src/images/class/bg-label-unpaid.png'
import IconAvailable from 'src/images/class/bg-label-availeble.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetAllLessonScheduleMutation } from 'src/app/service/real/serieslesson-slice';
import { appConfig, buildStudioScheduleListUrl } from 'src/app-config';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import { SvgComponent } from 'src/components/svgComponent';
import MyLiveVideoClassList from './MyLiveVideoClassList';
import { useSearchParam } from 'react-use';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  listBox: {
    marginTop: '30px',
  },
  listTitleItemBox: {
    marginBottom: "35px !important",
  },
  timeTitle: {
    textAlign: "left",
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "20px !important",
    lineHeight: "23px !important",
    marginBottom: "15px !important",
  },
  listItemBox: {
    width: "100%",
    borderBottom: '1px solid #dddddd',
    height: 'fit-content',
    display: "flex",
    flexDirection: "row",
    padding: "16px 0 26px 0",
    position: "relative",
    cursor: "pointer",
  },
  listItemMidBox: {
    marginLeft: "14px",
  },
  listItemTime: {
    fontFamily: "Arial-BoldMT, Arial !important",
    fontSize: "16px !important",
    color: "#307DCF !important",
    textAlign: "left",
  },
  scheduleTypeText: {
    marginLeft: "15px !important",
    fontFamily: "ArialMT !important",
    fontSize: "12px !important",
    textAlign: "center",
    color: "#2A86E8",
    background: "#D7EAFF",
    borderRadius: '5px',
    padding: '4px 8px',
  },
  listItemMessage: {
    marginTop: "13px !important",
    fontFamily: "ArialMT !important",
    fontSize: "14px !important",
    height: "16px !important",
    textAlign: "left",
    color: "black",
  },
  thumbnail: {
    width: '106px',
    height: '72px',
    borderRadius: '8px',
    objectFit: 'cover',
    position: 'absolute',
    right: 20,
    [theme.breakpoints.down('md')]: {
      display:"none"
    }
  },
  SignInInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: "60vh",
    paddingTop: "20vh",
  },
  queryTimeButton: {
    marginLeft: '20px !important',
    marginTop: '30px !important',
    width: '130px !important',
    height: '48px !important',
    borderRadius: '8px !important',
    border: '1px solid #7C849C !important',
    color: 'black !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',  
  },
  timeMenu: {
    '& .MuiPopover-paper': {
      maxWidth: '130px !important',
    }
  },
  selectedTimeMenu: {
    color: '#307DCF !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important', 
    height: '50px !important',
    width: '130px !important',
  },
  unSelectedTimeMenu: {
    color: '#555555 !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',
    height: '50px !important', 
    width: '130px !important', 
  },

  queryTypeButton: {
    alignSelf: 'end',
    width: '250px !important',
    height: '48px !important',
    borderRadius: '8px !important',
    border: '1px solid #7C849C !important',
    color: 'black !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',  
  },
  menu: {
    '& .MuiPopover-paper': {
      maxWidth: '250px !important',
    }
  },
  selectedMenu: {
    color: '#307DCF !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important', 
    height: '50px !important',
    width: '250px !important',
  },
  unSelectedMenu: {
    color: '#555555 !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Heavy, Avenir !important',
    height: '50px !important', 
    width: '250px !important', 
  },
}))

interface FormatStudentScheduleEntity {
  id: string,
  title: string,
  type: ScheduleLessonTypeEnum,
  thumbnail: string,
  start_time: number,
  end_time: number,
  status: number,
  teacher?: UserInfo,
}

interface TimeScheduleEntity {
  time: number;
  list: FormatStudentScheduleEntity[]
}

const MyScheduleList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()
  const { credential } = useSelector(state => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startKeyArray, setStartKeyArray] = useState(["", ""]);
  const [maxPage, setMaxPage] = useState(1)
  const PAGE_SIZE = 10;
  const [dataList, setDataList] = useState<TimeScheduleEntity[]>([]);

  const listTime = useSearchParam("listTime");
  const [queryTime, setQueryTime] = useState(listTime || "Upcoming"); // Upcoming or Past
  const [timeMenuAnchorEl, setTimeMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const showQueryTimeMenu = Boolean(timeMenuAnchorEl);

  const listType = useSearchParam("listType");
  const [queryType, setQueryType] = useState(listType ? parseInt(listType) : 1); // 1-Private lesson and series lesson; 2-live stream and live video class
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const showQueryMenu = Boolean(moreMenuAnchorEl);

  const [getLessonSchedules, {isLoading: loading5}] = useGetAllLessonScheduleMutation();
  
  useEffect(() => {
    if (credential) {
      handleRefreshList();
    }
  }, [credential, queryTime])

  const handleRefreshList = () => {
    setStartKeyArray(["", ""]);
    setMaxPage(1);
    handleSearch(1);
  }

  const handleSearch = async (pageIndex: number) => {
    setCurrentPage(pageIndex);
    const params = queryTime === "Past" ? {
      userId: credential?.id||'',
      endTime: new Date().getTime(),
      scheduleType: 'planned',
      pageSize: PAGE_SIZE,
      startKey: startKeyArray[pageIndex],
      forwardOrder: false,
    } : {
      userId: credential?.id||'',
      startTime: new Date().getTime() - 2 * 60 * 60 * 1000, //before 2 hours
      scheduleType: 'planned',
      pageSize: PAGE_SIZE,
      startKey: startKeyArray[pageIndex],
      forwardOrder: true,
    };
    // get private lesson and series lesson schedules (include schedules as a student and schedules as a teacher)
    getLessonSchedules(params)
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const bookedList: UserScheduleEntity[] = dataRes.result.user_schedule_list;
        const cardList : TimeScheduleEntity[] = formatSchedule(bookedList);
        setDataList(cardList);

        const nextStartKey = dataRes.result.start_key;
        startKeyArray[pageIndex + 1] = nextStartKey;
        setStartKeyArray(startKeyArray);
        setHasMore(nextStartKey && nextStartKey !== "");
        if (nextStartKey && pageIndex + 1 > maxPage) {
          setMaxPage(pageIndex + 1)
        }
      } else {
        setDataList([]);
        setHasMore(false);
        // enqueueSnackbar(dataRes?.message || "Can not get data")
      }
    })
  }


  /**
   * transfer series lesson to formatted schedule entity
   * @param list series lesson list
   * @returns 
   */
  const formatSchedule= (list: UserScheduleEntity[]): TimeScheduleEntity[] => {
    const resultList: TimeScheduleEntity[] = [];
    if (list && list.length > 0) {
      list.forEach(item => {
        const date = new Date(item.start_time);
        const year = date.getFullYear();
        const month = date.getMonth();

        let entity : FormatStudentScheduleEntity|undefined = undefined;
        if (item.source_type === UserScheduleSourceTypeEnum.SERIES_LESSON_CLASSES) {
          entity = {
            id: item.series_lesson_classes?.id || (Math.random()+''),
            title: item.series_lesson_classes?.series_lesson?.title || '',
            type: ScheduleLessonTypeEnum.SeriesLesson,
            thumbnail: item.series_lesson_classes?.series_lesson?.thumbnail || '',
            start_time: item.start_time,
            end_time: item.end_time,
            status: item.series_lesson_classes?.status || -1,
            teacher: item.series_lesson_classes?.teacher_info,
          }
        } else if (item.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON_CLASSES) {
          entity = {
            id: item.private_lesson_classes?.id || (Math.random()+''),
            title: item.private_lesson_classes?.title || 'Private Lesson',
            type: ScheduleLessonTypeEnum.OneToOneLesson,
            thumbnail: item.private_lesson_classes?.thumbnail || '',
            start_time: item.start_time,
            end_time: item.end_time,
            status: item.private_lesson_classes?.status || -1,
            teacher: {id: item.private_lesson_classes?.teacher_user_id||'', name: 'Unknown', avatar: ''},
          }
        }
        if (entity) {
          const findDaySchedule = resultList.find(v => {
            const vDate = new Date(v.time);
            return year === vDate.getFullYear() && month === vDate.getMonth();
          })

          if (findDaySchedule) {
            findDaySchedule.list.push(entity);
          } else {
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            const newDaySchedule: TimeScheduleEntity = {
              time: date.getTime(),
              list: [entity]
            }
            resultList.push(newDaySchedule);
          }
        }
      })
    }

    return resultList;
  }


  const handleOnClickPrev = () => {
    handleSearch(currentPage - 1)
  }

  const handleOnClickNext = () => {
    handleSearch(currentPage + 1)
  }

  const handleOnClickPage = (page) => {
    handleSearch(page)
  }

  const getBookStatusBg = (status: BookStatusEnum) => {
    if (status === BookStatusEnum.AVAILABLE_TIME) {
      return `url(${IconAvailable})`;
    } else if (status === BookStatusEnum.PAID) {
      return `url(${IconPaid})`;
    } else {
      return `url(${IconUnPaid})`;
    }
  }

  const handleItemClick = (item: FormatStudentScheduleEntity) => {
    if (item.type === ScheduleLessonTypeEnum.SeriesLesson) {
      navigate(appConfig.paths.seriesLessonClass+"?id="+item.id)
    } else {
      navigate(appConfig.paths.privateLesson+"?id="+item.id)
    }
  }

  const handleOpenTimeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setTimeMenuAnchorEl(event.currentTarget);
  }

  const handleCloseTimeMenu = () => {
    setTimeMenuAnchorEl(null);
  }

  const handleQueryTypeChange = (newQueryType: number) => {
    handleCloseMoreMenu(); 
    setQueryType(newQueryType);
    const newUrl = buildStudioScheduleListUrl(newQueryType, queryTime);
    window.history.pushState({}, "", newUrl);
  }

  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  }

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  }

  const handleQueryTimeChange = (newQueryTime: string) => {
    handleCloseTimeMenu(); 
    setQueryTime(newQueryTime);
    const newUrl = buildStudioScheduleListUrl(queryType, newQueryTime);
    window.history.pushState({}, "", newUrl);
  }

  if (credential) {
    return (
      <Box className={classes.root}>
        <Box className={classes.container}>
          <Box style={{display:'flex', alignItems: 'center', justifyContent:'flex-end'}}>
            <Button 
              variant='outlined' 
              className={classes.queryTypeButton} 
              style={{textTransform:"none"}}
              onClick={(event) => handleOpenMoreMenu(event)}
              >
              {queryType === 1 ? "Private lesson/Series lesson" : "Live video class/Live stream"}
              <SvgComponent iconName={showQueryMenu ? 'ico-arrow-top-black' : 'ico-arrow-down-black'} style={{width: '12px', height: '8px', marginLeft: '9px'}} />
            </Button>

            <Menu
              id="more-menu"
              anchorEl={moreMenuAnchorEl}
              open={showQueryMenu}
              onClose={handleCloseMoreMenu}
              className={classes.menu}
            >
              <MenuItem
                onClick={() => {handleQueryTypeChange(1)}}
                className={queryType === 1 ? classes.selectedMenu : classes.unSelectedMenu}
                >
                Private lesson/Series lesson
              </MenuItem>
              <MenuItem 
                onClick={() => {handleQueryTypeChange(2)}} 
                className={queryType === 2 ? classes.selectedMenu : classes.unSelectedMenu}
                >
                Live video class/Live stream
              </MenuItem>
            </Menu>
        
            <Button 
              variant='outlined' 
              className={classes.queryTimeButton} 
              style={{textTransform:"none"}}
              onClick={(event) => handleOpenTimeMenu(event)}
              >
              {queryTime}
              <SvgComponent iconName={showQueryTimeMenu ? 'ico-arrow-top-black' : 'ico-arrow-down-black'} style={{width: '12px', height: '8px', marginLeft: '9px'}} />
            </Button>

            <Menu
              id="time-menu"
              anchorEl={timeMenuAnchorEl}
              open={showQueryTimeMenu}
              onClose={handleCloseTimeMenu}
              className={classes.menu}
            >
              <MenuItem
                onClick={() => {handleQueryTimeChange("Upcoming");}}
                className={queryTime === "Upcoming" ? classes.selectedTimeMenu : classes.unSelectedTimeMenu}
                >
                Upcoming
              </MenuItem>
              <MenuItem 
                onClick={() => { handleQueryTimeChange("Past"); }} 
                className={queryTime === "Past" ? classes.selectedTimeMenu : classes.unSelectedTimeMenu}
                >
                Past
              </MenuItem>
            </Menu>
          </Box>

          {queryType === 1 &&
          <Box>
            {dataList?.length > 0 ?
            <Box className={classes.listBox}>
              {dataList.map(data => (
                <Box key={data.time} className={classes.listTitleItemBox}>
                  <Typography className={classes.timeTitle}>{getMonthInWord(new Date(data.time).getMonth()) + ", " + new Date(data.time).getFullYear()}</Typography>
                  <List sx={{margin:"20px 0"}}>
                    {data.list?.length > 0 && data.list.map(item => (
                      <ListItem key={item.start_time} button sx={{padding: '8px 0 0 0'}}>
                        <Box className={classes.listItemBox} onClick={() => handleItemClick(item)}>
                          <Box sx={{width: '80px', marginRight: '16px'}}>
                            <Box sx={{
                              height: '32px', 
                              paddingTop: '8px',
                              borderRadius: '8px 8px 0px 0px', 
                              background: '#E2E2E2', 
                              textAlign:'center', 
                              fontSize: '14px',
                              fontFamily: 'Avenir-Black, Avenir',
                              color: 'white',
                              fontWeight: 900,
                            }}>
                              {getMonthInWord(new Date(data.time).getMonth(), true)}
                            </Box>
                            <Box sx={{
                              height: '40px', 
                              paddingTop: '10px',
                              borderRadius: '0px 0px 8px 8px', 
                              border: '1px solid #E6E6E6',
                              background: 'white', 
                              textAlign:'center', 
                              fontSize: '16px',
                              fontFamily: 'Avenir-Black, Avenir',
                              color: '#555555',
                              fontWeight: 900,
                            }}>
                              {new Date(item.start_time).getDate()}
                            </Box>
                          </Box>
                          <Box className={classes.listItemMidBox}>
                            <Box style={{ display: "flex", alignItems: "baseline" }}>
                              <Typography className={classes.listItemTime}>{getTimeAreaDesc(new Date(item.start_time), new Date(item.end_time))}</Typography>
                              <Typography className={classes.scheduleTypeText}>{getScheduleTypeText(item.type)}</Typography>
                            </Box>
                            <Typography className={classes.listItemMessage}>{item.title}</Typography>
                            {/* <Typography className={classes.listItemMessage}>{item.title}</Typography> */}
                          </Box>
                          {/* <Box className={classes.bookStatusText} style={{ backgroundImage: getBookStatusBg(item.status), backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                            {getBookTimeStatusDesc(item.status)}
                          </Box> */}

                          {item.thumbnail && <img alt="lesson_thumbnail" src={item.thumbnail} className={classes.thumbnail}/>}
                          
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Box>
            :
            <Typography style={{fontSize: '18px', fontFamily: 'ArialMT', margin: "50px 0"}}>
              No data yet.
            </Typography>
            }
    
            {(currentPage !== 1 || dataList?.length !== 0) &&
              <ListPagination
                currentPage={currentPage}
                hasMore={hasMore}
                // totalCount={totalCount}
                rowsPerPage={PAGE_SIZE}
                maxPage={maxPage}
                onClickPage={handleOnClickPage}
                onClickPrev={handleOnClickPrev}
                onClickNext={handleOnClickNext}
              />
            }
            <Loader isLoading={loading5} />
          </Box>
          }

          {queryType === 2 &&
          <MyLiveVideoClassList queryTime={queryTime}/>
          }
        </Box>
      </Box>
    )
  } else {
    return (
      <Box className={classes.SignInInner}>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'> Please sign in
          <Button
            onClick={() => {dispatch(showSignInUpDialog())}}
            sx={{
              width:'136px',
              height: '48px',
              textTransform: "none", 
              backgroundColor: '#307DCF',  
              borderRadius: '8px',
              fontFamily: 'ArialMT',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              fontSize: '18px',
              marginTop: '20px'
            }}
            variant="contained">
            {t('studio.signIn')}
          </Button>
        </Box>
        
      </Box>
    )
  }
  
}

export default MyScheduleList;