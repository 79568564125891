import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'src/app/toolkit/helper';
import { Response, Result, Value } from '@dokyo/common'
import { NotificationEntity } from 'src/app/models/notification'

export interface NotificationList {
    data: NotificationEntity[],
    start_key: string
}
export interface NotificationReadInfo {
    type: string,
    message_id: string
}

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: baseQueryWithReauth,
    endpoints(builder) {
        return {
            getusernotification: builder.query<Result<NotificationList>, {
                pageSize: number;
                startKey: string;
            }>({
                query: ({ pageSize, startKey }) => ({
                    url: 'getusernotification',
                    method: 'POST',
                    body: {
                        page_size: pageSize,
                        start_key: startKey
                    }
                }),
                //  You can define your response type
                transformResponse: (res: Response<NotificationList>) => new Value<NotificationList>(res)
            }),

            setUserNotificationRead: builder.mutation<Result<{}>, {
                messageIds: string[];
            }>({
                query: ({ messageIds }) => ({
                    url: 'getusernotification',
                    method: 'POST',
                    body: {
                        type: "set_read",
                        message_id: messageIds
                    }
                }),
                //  You can define your response type
                transformResponse: (res: Response<{}>) => new Value<{}>(res)
            })
        }
    }
})

export const {
    useGetusernotificationQuery,
    useLazyGetusernotificationQuery,
    useSetUserNotificationReadMutation
} = notificationApi;
