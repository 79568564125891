import { RootState } from 'src/app/toolkit/store'
import { defaultLanguage } from '@dokyo/common'
import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { LoginUserResponse, signInRefreshToken } from 'src/utils/refreshToken'
import { apiWrapper, checkResponse, errorNotificationWith } from 'src/utils/api'
import { getStoredAccessToken, storeAccessToken } from 'src/utils/localStorage'
import { setRefreshToken, setToken } from '../service/access-slice'
import { decodeJWT } from 'src/utils/auth'
import { showLogoutDialog } from '../service/signinup-slice'
import { getFormatDisplayTime } from 'src/utils/timeUtil'
// import { getFormatDisplayTime } from 'src/utils/timeUtil'
import { clear, setIdentity } from 'src/app/service/app-slice'
import { clearRefreshToken, clearToken } from 'src/app/service/access-slice'
import { removeStoredAccessToken, removeStoredPaymentPackage, removeStoredPaymentProduct, removeStoredPaymentSession, removeStoredPromotion } from 'src/utils/localStorage'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { ErrorReportEntity } from '../models/error'
import { getExploreName } from 'src/utils/deviceUtil'
import  Axios, { AxiosError, AxiosResponse } from 'axios';
import { ThunkDispatch } from '@reduxjs/toolkit'

const TOKEN_REFRESH_WHITE_LIST_APIS = [
  'signinpw',
  'thirdpartylogin',
  'signupcheckname',
  'signup',
  'signupverifycode',
  'getuserprofile',
  'signin',
  'forgetpassword',
];

const queryBuilder = (path?: string) => ({
  baseUrl: path ?? process.env.REACT_APP_API_URL,
  prepareHeaders(headers, { getState }) {
    const { tokenRes, app } = getState() as RootState
    if (Boolean(tokenRes.token)) {
      headers.set('Authorization', `${tokenRes.token}`)
    }
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Accept-Language', app.language ?? defaultLanguage)

    return headers
  }
})

const baseQuery = fetchBaseQuery(queryBuilder());

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { tokenRes } = api.getState() as RootState
  if (!tokenRes || !tokenRes.token) {
    return queryWithReportError(args, api, extraOptions);
  }
  const tokenData = decodeJWT(tokenRes.token);
  const currentTimeSeconds = new Date().getTime()/1000;
  console.log("old token expire time: ", getFormatDisplayTime(tokenData?.exp * 1000)) 
  if (tokenData && tokenData.exp > currentTimeSeconds) {
  // if (tokenData.exp - currentTimeSeconds > 59*60) {
    return queryWithReportError(args, api, extraOptions);
  }
  if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
    console.log("This api doesn't need token refresh: ", (args as any).url)
    return queryWithReportError(args, api, extraOptions);
  }
  console.log("token is expired!!!!!! start refresh...")
  await requestRefreshToken(api.dispatch, tokenData.userid, tokenRes.refreshToken);
  return queryWithReportError(args, api, extraOptions);
};

const requestRefreshToken = async (dispatch: ThunkDispatch<any, any, any>, userId: string, refreshToken: string|null|undefined) => {
  const response = await signInRefreshToken(userId, refreshToken || "");
  if (response && checkResponse(response)) {
    const { token, refresh_token } = response.data?.data;
    storeAccessToken(token);
    dispatch(setToken(token));
    dispatch(setRefreshToken(refresh_token));
    console.log("refresh token success save new token : ", token)
  } else {
    console.log("refresh token failed: user id is " + userId)
    if (process.env.REACT_APP_ENV === 'prod') {
      dispatch(setIdentity(undefined))
      dispatch(clear())
      dispatch(clearToken())
      dispatch(clearRefreshToken())
      
      removeStoredAccessToken()
      removeStoredPaymentSession()
      removeStoredPaymentProduct()
      removeStoredPaymentPackage()
      removeStoredPromotion()
    } else {
      dispatch(showLogoutDialog())
    }
  }
}
const queryWithReportError = async (args, api, extraOptions) => {
  const res = await baseQuery(args, api, extraOptions);
  if (res.error || !res.data || res.meta?.response?.status !== 200) {
    console.log("api error == "+JSON.stringify(res))
    const errorParam: ErrorReportEntity = {
      error_code: res.meta?.response?.status || (res.data as any)?.result?.result_code,
      error_message: res.error ? JSON.stringify(res.error) : (res.data as any)?.result?.message,
      modules: window.location.pathname,
      request_url: (args as any).url,
      request_action: 'api',
      request_params: (args as any).body ? JSON.stringify((args as any).body) : '',
    }
    reportError(errorParam);
  }
  
  if (res.meta?.response?.status === 200 && res.data && (res.data as any).result?.result_code === 401) {
    console.log("api return 401 token expired, begin to refresh token...")
    const { tokenRes } = api.getState() as RootState;
    if (tokenRes && tokenRes.token) {
      const tokenData = decodeJWT(tokenRes.token);
      await requestRefreshToken(api.dispatch, tokenData.userid, tokenRes.refreshToken);
      console.log("api 401 token refresh finished, request again...")
      return await baseQuery(args, api, extraOptions);
    }
  }
  return res;
}


export const reportError = async (entity: ErrorReportEntity): Promise<AxiosResponse<LoginUserResponse> | void> => {
  const success = async (): Promise<AxiosResponse<LoginUserResponse>> => {
      const response = await Axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
              'Content-Type': 'application/json',
              'authorization': getStoredAccessToken(),
          },
        }).post<LoginUserResponse>('/tracking', {
          request_type: "create_tracking",
          request_data: {
            error_code: entity.error_code,
            error_message: entity.error_message,
            browser_type: getExploreName(),
            modules: entity.modules,
            request_url: entity.request_url,
            request_action: entity.request_action,
            request_params: entity.request_params,
          }
      });
      return response
  };

  const error = (e: AxiosError): AxiosResponse | void => {
      return e.response;
  };

  return apiWrapper<LoginUserResponse>({
      success,
      error,
  });
};
