import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import { getLegalFileName } from 'src/utils/stringUtil'


export const meetingBackgroundApi = createApi({
  reducerPath: 'meetingBackgroundApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      uploadMeetingBackground: builder.mutation<Result<any>,{fileName:string, fileType: string}>({
        query: ({fileName, fileType}) => ({
          url: `uploadfile`,
          method: 'POST',
          body: {
            update_type: "background",
            file_name: getLegalFileName(fileName),
            file_type: fileType,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      deleteMeetingBackground: builder.mutation<Result<any>,{fileId: string}>({
        query: ({fileId}) => ({
          url: `deletefile`,
          method: 'POST',
          body: {
            file_id: fileId,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getMeetingBackgroundList: builder.mutation<Result<any>,{}>({
        query: () => ({
          url: `meeting_room`,
          method: 'POST',
          body: {
            request_type: "list_meeting_background",
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

    }
  }
})

export const {
  useUploadMeetingBackgroundMutation,
  useDeleteMeetingBackgroundMutation,
  useGetMeetingBackgroundListMutation,
} = meetingBackgroundApi
