import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { VictoriaClassCardEntity } from 'src/app/models/victoriaClass'
import image_course_default from 'src/images/defaultIcon/pic-course.png'
import clsx from 'clsx'
import { getDisplayUserName } from 'src/utils/stringUtil'

const useStyles = makeStyles(theme => ({
  group: {
    zIndex: 'auto',
    width: '330px',
    height:'350px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  spanvictoriaveeko3: {
    position: 'absolute',
    left: '15px',
    top: '367px',
    width: '120px',
    height: '27px',
    display: 'block',
    overflowWrap: 'break-word',
    color: 'rgba(0, 0, 0, 1)',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  titleMouseDown: {
    textDecoration: 'underline'
  }, 
  imgHover: {
    filter: 'contrast(70%)'
  },
  bgTransparent: {
    background: 'transparent !important'
  },
}))

interface VictoriaClassCardProps extends CardProps {
  card: VictoriaClassCardEntity,
  handleClick?: Function,
  bgTransparent?: boolean;
}

export const TeacherCourseCard: React.FC<VictoriaClassCardProps> = ({card, handleClick, bgTransparent = false, ...props}) => {
  const classes = useStyles()
  const [ isMouseOver, setIsMouseOver] = React.useState<boolean>(false)
  
  const handleViewDetailsClick = (e) => {
    if(handleClick){
      handleClick()
    }
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  return (
    <Box 
      className={classes.group}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <Card
        {...props}
        style={{
          position: 'relative',
          maxWidth: '330px',
          width: '100%',
          minWidth: '260px',
          borderRadius: '12px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          background: '#FFFFF',
        }}
        sx={{ cursor: 'pointer', boxShadow: 'none' }}
        className={clsx({
          [classes.bgTransparent]: bgTransparent,
        })}
      >
        <CardMedia
          component="img"
          image={card.media_image? card.media_image: image_course_default}
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            maxWidth: '330px',
            minWidth: '260px',
            width: '306px',
            height: '172px',
            borderRadius:'16px'
          }}
          className={clsx({[classes.imgHover]: isMouseOver})}
        />
        <CardContent>
          <Box
            sx={{
             display:'flex',
             flexDirection:'column',
             position:'absolute',
             top:'188px',
             marginLeft:'-12px'
           }}
          >
            <Typography
            style={{
              width: '300px',
              fontSize: '20px',
              letterSpacing: '-0.578823447227478px',
              fontFamily: 'Arial-BoldMT',
              lineHeight: '22px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            className={clsx({[classes.titleMouseDown]: isMouseOver})}
          >
            {getDisplayUserName(card.content_header, card.id||"")}
          </Typography>
            <Typography
              style={{
                fontSize: '16px',
                marginTop:'10px',
                width: '300px',
                letterSpacing: '-0.578823447227478px',
                fontFamily: 'ArialMT',
                lineHeight: '24px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              >
              {card.content_subheader}
            </Typography>
                <Typography
                style={{
                  width: '300px',
                  fontSize: '16px',
                  marginTop:'10px',
                  letterSpacing: '-0.38588231801986694px',
                  fontFamily: 'ArialMT',
                  lineHeight: '24px',
                  top:'289px',
                  height:'24px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {card.content_body}
              </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
