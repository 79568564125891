import { CardProps } from '@mui/material/Card'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles';
import feedback3 from 'src/images/home/feedback/3.png'
import { Feedback } from 'src/app/models/feedback'

const useStyles = makeStyles(theme => ({
  group: {
    zIndex: 'auto',
    width: '660px',
    height: '320px',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    background: '#F9F9F9',
    borderRadius: '12px',
    margin: '47px 0 0 150px'
  },
}))

interface FeedbackCardProps extends CardProps {
  feedback: Feedback
}

export const FeedbackCard: React.FC<FeedbackCardProps> = ({feedback, ...props}) => {
  const classes = useStyles()
  return (
    <Box className={classes.group}>
      <img 
        src={feedback3}
        alt=''
        style={{
          width: '30px',
          height: '28px',
          marginTop: '28px',
          marginLeft: '30px'
        }}
      />
      <Box
        style={{
          justifyContent: 'flex-start',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          style={{
            width: '434px',
            height: '165px',
            margin: '76px 0 0 6px'
          }}
        >
          <Typography
            style={{
              zIndex: '240',
              width: '434px',
              height: '165px',
              display: 'block',
              overflowWrap: 'break-word',
              color: 'rgba(0, 0, 0, 1)',
              fontSize: '18px',
              letterSpacing: '-0.4341175854206085px',
              fontFamily: 'ArialMT',
              lineHeight: '30px',
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {feedback.content}
          </Typography>
        </Box>
        <Box
            style={{
              margin: '0 0 0 6px',
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'row'
            }}
        >
            <Typography
              style={{
                zIndex: '241',
                //position: 'absolute',
                //left: '68px',
                //top: '240px',
                //width: '71px',
                //height: '38px',
                //display: 'block',
                //overflowWrap: 'break-word',
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '18px',
                letterSpacing: '-0.4341175854206085px',
                fontFamily: 'ArialMT',
                lineHeight: '30px',
                //textAlign: 'left',
                //overflow: 'hidden',
                //textOverflow: 'ellipsis'
              }}
            >
                {feedback.user}
            </Typography>
            <Typography
              style={{
                zIndex: '242',
                //width: '256px',
                //height: '38px',
                //display: 'block',
                //overflowWrap: 'break-word',
                color: 'rgba(85, 85, 85, 1)',
                fontSize: '14px',
                letterSpacing: '-0.337647020816803px',
                fontFamily: 'ArialMT',
                lineHeight: '30px',
                //textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                margin: "0 0 0 10px",
              }}
            >
                {feedback.from}
            </Typography>
        </Box>
      </Box>
    </Box>
  )
}
