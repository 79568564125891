import { FC } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

import MicrophoneActivityPreviewBar from './MicrophoneActivityPreviewBar';

const MicrophoneActivityPreview: FC = () => {
  return (
    <Box>
      <InputLabel 
            sx={{
                color: '#FFFFFF',
                mb: '29px'
            }}
            id="mic-activity-preview-label"
        >
        Microphone activity
      </InputLabel>
      <MicrophoneActivityPreviewBar />
    </Box>
  );
};

export default MicrophoneActivityPreview;
