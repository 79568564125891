import React from 'react'
import { makeStyles } from '@mui/styles'
import { CourseEntity } from '@dokyo/common'
import { useNavigate } from 'react-router-dom'
import defaultImg from 'src/images/defaultIcon/pic-course.png'
import Box from '@mui/material/Box';
import clsx from 'clsx'
import { getDisplayUserName } from 'src/utils/stringUtil'

const useStyles = makeStyles(() => ({
  root: {
    // width: '300px',
    // height: '350px',
    borderRadius: '12px',
    // background: '#F9F9F9',
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none'
  },
  defaultSize: {
    width: '300px',
    height: '350px'
  },
  smallSize: {
    maxWidth: '240px',
    minWidth: '200px',
    width: '100%',
    height: '290px'
  },
  courseImgContainer: {
    // width: '300px',
    // height: '202px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  courseImg: {
    // maxWidth: '300px',
    // maxHeight: '202px',
    // width: '300px',
    // height: '202px',
    borderRadius: '12px',
    objectFit: 'cover'
  },
  defaultImgSize: {
    width: '306px',
    height: '172px',
  },
  smallImgSize: {
    maxWidth: '240px',
    minWidth: '200px',
    height: '160px',
  },
  courseContent: {
    padding: '15px 0 15px'
  },
  courseTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    // width: '274px',
    maxHeight: '50px',
    lineHeight: '24px',
    overflow: 'hidden',
    marginBottom: '14px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical'
  },
  courseTitleNA: {
    fontSize: '20px',
    fontWeight: 'bold',
    // width: '274px',
    maxHeight: '50px',
    lineHeight: '24px',
    overflow: 'hidden',
    marginBottom: '14px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    opacity: '50%',
  },
  courseTitleMouseDown: {
    textDecoration: 'underline'
  },  
  introduction: {
    color: '#555555',
    height: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '5px',
    // lineHeight: '24px',
    whiteSpace: 'nowrap'
  },
  lesson : {
    fontSize: '16px',
    color: '#555555'
  },
  imgHover: {
    filter: 'contrast(70%)'
  },
}))

type Size = 'default' | 'small'

interface PropsCourse {
  course: CourseEntity;
  enable?: boolean;
  size?: Size
}

const ContainerSizeMap = {
  'default': 'defaultSize',
  'small': 'smallSize'
};

const ImageSizeMap = {
  'default': 'defaultImgSize',
  'small': 'smallImgSize'
};

export const CourseCard: React.FC<PropsCourse> = ({course, enable=true, size = 'default'}) => {

  const classes = useStyles();
  const navigate = useNavigate()
  const [ imgSrc, setImgSrc ] = React.useState<string>(course.course_thumbnail?? defaultImg)
  const [ isMouseOver, setIsMouseOver] = React.useState<boolean>(false)

  const containerSize = ContainerSizeMap[size];
  const imageSize = ImageSizeMap[size];

  const handleClickCourse = (course: any) => {
    if (enable){
      navigate('/classdetail?id=' + course?.course_id)
    }
  }

  

  const handleOnImgError = () => {
    setImgSrc(
      defaultImg
    )
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  return (
    <Box 
    onMouseOut={handleMouseOut}
    onMouseOver={handleMouseOver}
    onClick={()=>handleClickCourse(course)} 
    className={clsx(classes.root, classes[containerSize])}>
      <div className={clsx(classes.courseImgContainer)}>
        <img 
          onError={handleOnImgError} 
          alt={course.course_title} 
          className={clsx(classes.courseImg, classes[imageSize], {[classes.imgHover]: isMouseOver})}
          src={imgSrc} 
        />
      </div>
      <div className={classes.courseContent}>
        <div 
          title={course.course_title} 
          className={clsx(`${enable ? classes.courseTitle : classes.courseTitleNA}`, {[classes.courseTitleMouseDown]: isMouseOver})}
        >
          {course.course_title}
        </div>
        <div title={course.course_introduction} className={classes.introduction}>
        {course.course_introduction}
        </div>
        {
          course?.course_series?.series_order?
          <div className={classes.lesson}>
           {getDisplayUserName(course?.teacher?.user_name, course?.teacher?.user_id)}
          </div>:
          ''
        }
      </div>
    </Box>
  )
}