import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { RelatedCard } from 'src/components/cards'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { RelatedClassEntity } from '@dokyo/common'
import { isMobileDevice } from 'src/utils/deviceUtil'

const useStyles = makeStyles(theme => ({
  slider: {
    width: '100%',
    margin: 'auto',
    textAlign: 'left',

    '& .slick-arrow': {
      zIndex: '1',
      '&:before': {
        fontSize: '60px',
        color: 'black',
        content: "''"
      }
    },
    '& .slick-list': {
      margin: '0 0 0 calc(-3 * 4 * 1px)'
    },
    '& .slick-track': {
      display: 'inline-flex'
    }
  },
  slider__content: {
    margin: 'auto'
  },

  card__body: {}
}))

interface PropsForm {
  classList: RelatedClassEntity[],
  learnType: number, //1-Course; 2-video broadcast; 3-live broadcast; 4-video classroom
  emitEmptyNext?: (id: string) => void,
  emitId?: string,
}

export const RelatedClassSlider = (props: PropsForm) => {
  const {classList, learnType, emitEmptyNext, emitId } = props;
  const classes = useStyles()
  const navigate = useNavigate()
  const [isSwiping, setIsSwiping] = useState(false);

  const SampleNextArrow = props => {
    const { className, style, onClick } = props;
    useEffect(() => {
      if (!onClick) {
        // empty next
        if (emitEmptyNext) {
          emitEmptyNext(emitId?? '')
        }
      }
    }, [onClick]);
    return (
      <Box
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: '#F93B57',
          borderRadius: '50%',
          display: onClick === null ? "none" : "flex",
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        }}
        sx={isMobileDevice() ? {right: "-12px",top: 'calc(50% - 46px)'}:{right: "-12px", top: 'calc(50% - 50px)'}}
        onClick={onClick}
      >
        <ArrowForwardIosIcon 
          style={{ 
            color: 'white', 
            width: '50%', 
            height: '50%',
          }}
        />
      </Box>
    )
  }
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <Box
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: '#F93B57',
          borderRadius: '50%',
          display: onClick === null ? "none" : "flex",
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        }}
        sx={isMobileDevice() ? {left: "-18px", top: 'calc(50% - 46px)'} : {left: "-20px", top: 'calc(50% - 50px)'}}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: 'white',
            width: '50%',
            height: '50%',
            transform: 'rotate(180deg)'
          }}
        />
      </Box>
    )
  }

  const settings = {
    // dots: true,
    infinite: false,
    // swipeToSlide: true,
    speed: 300,
    slidesToShow: isMobileDevice() ? 2 : 6,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  const addVideoToCart = (video: RelatedClassEntity) => {
  }

  const handleClick = (entity: RelatedClassEntity) => {
    if (isSwiping) {
      return
    }
    if (entity.type === 0) {
      return
    } else if (entity.type === 1) {//course
      navigate('/classdetail?id=' + entity.id)
    } else if (entity.type === 2) {//video broadcast
      navigate('/videobroadcast?id=' + entity.id)
    } else if (entity.type === 3) {//live broadcast
      navigate("/livebroadcast?id=" + entity.id)
    } else if (entity.type === 4) { // video classroom
      navigate("/videoclassroom?id=" + entity.id)
    }
    // navigate('/videobroadcast?id=' + "6aab4ba1-0300-4efb-9d87-fb3da43dd551")
  }

  return (
    <Box className={classes.slider}>
      <Box className={classes.slider__content}>
        <Slider
          {...settings}
          afterChange={() => setIsSwiping(false)}
          onSwipe={() => setIsSwiping(true)}
        >
          {classList?.map((v, i) => (
            <Box data-id={v.id} key={v.id}>
              <RelatedCard
                key={v.id}
                elevation={0}
                classData={v}
                learnType={learnType}
                className={classes.card__body}
                onCartAdded={addVideoToCart}
                onClick={() => handleClick(v)}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  )
}
