import { useEffect, useReducer, createContext, useContext, FC, useCallback } from 'react';
import { 
  useAudioVideo, 
  useMeetingManager, 
  // useContentShareControls,
  useContentShareState,
} from 'amazon-chime-sdk-component-library-react';
import { DataMessage } from 'amazon-chime-sdk-js';
import { useMeetingState } from '../MeetingStateProvider';
import { ShareDataMessage, SHARE_DATA_MESSAGE_TOPIC, initialState, reducer, ActionType, ShareDataMessagesType, ShareUserInfo } from './state';
import { useSnackbar } from 'notistack';
import { getDisplayUserName } from 'src/utils/stringUtil';


interface ShareDataMessagesStateContextType {
    sendMessage: (message: ShareDataMessage) => void;
    closeRequestShare: () => void;
    NotShareContent: () => void;
    requestShare: boolean;
    requestShareUserInfo: ShareUserInfo
    allowShare: boolean;
  }


const ShareDataMessagesStateContext = createContext<ShareDataMessagesStateContextType | undefined>(undefined);

export const ShareDataMessageProvider: FC = ({ children }) => {
    const { isTeacher, isStudent ,localAttendeeId } = useMeetingState();
    const [state, dispatch] = useReducer(reducer, initialState(isTeacher));
    const meetingManager = useMeetingManager();
    const audioVideo = useAudioVideo();
    const { enqueueSnackbar } = useSnackbar();
    const { sharingAttendeeId, isLocalUserSharing } = useContentShareState();
    // const { toggleContentShare } = useContentShareControls();

    useEffect(() => {
        if (!audioVideo) {
          return;
        }
        audioVideo.realtimeSubscribeToReceiveDataMessage(SHARE_DATA_MESSAGE_TOPIC, handler);
        return () => {
          audioVideo.realtimeUnsubscribeFromReceiveDataMessage(SHARE_DATA_MESSAGE_TOPIC);
        };
    }, [audioVideo]);

    const NotShareContent = useCallback(() => {
      dispatch({
        type: ActionType.ShareAction,
        payload: {
          type: ShareDataMessagesType.DisagreeShare,
          senderAttendeeId: '',
          senderName: '',
          senderUserId: '',
        }
      })
    }, []);


    const handler = useCallback(
        (dataMessage: DataMessage) => {
            const data = dataMessage.json();
            console.log('ShareDataMessageProvider:', data);
            switch(data.type) {
              case ShareDataMessagesType.RequestShare:
                if (isTeacher) {
                  dispatch({
                    type: ActionType.ShareAction,
                    payload: data
                  })
                }
                break;
              // case ShareDataMessagesType.RequestShare:
              //     if (isTeacher) {
              //       dispatch({
              //         type: ActionType.ShareAction,
              //         payload: {
              //             type: data.type,
              //             senderAttendeeId: '',
              //             senderName: '',
              //             senderUserId: '',
              //         }
              //       })
              //     }
              //     break;
              case ShareDataMessagesType.AgreeShare:
                  if (data.senderAttendeeId === localAttendeeId) {
                    dispatch({
                      type: ActionType.ShareAction,
                      payload: data
                    })
                    enqueueSnackbar('Teacher has agreed your share screen request, you can share now', {variant:'success'});
                  } else {
                    audioVideo?.stopContentShare();
                    if (isStudent) {
                      dispatch({
                        type: ActionType.ShareAction,
                        payload: {
                          type: ShareDataMessagesType.DisagreeShare,
                          senderAttendeeId: '',
                          senderName: '',
                          senderUserId: '',
                        }
                      })
                      enqueueSnackbar(`Share screen permission is granted to ${getDisplayUserName(data.senderName, data.senderUserId)}`, {variant:'info'});
                    }
                  }
                  
                  break;
              case ShareDataMessagesType.DisagreeShare:
                if (data.senderAttendeeId === localAttendeeId) {
                  dispatch({
                    type: ActionType.ShareAction,
                    payload: data
                  })
                  enqueueSnackbar('Your share screen request is refused', {variant:'warning'});
                }
                  break;
              case ShareDataMessagesType.StopShare:
                console.log('data.senderAttendeeId:', data.senderAttendeeId);
                console.log('localAttendeeId:', localAttendeeId);
                console.log('sharingAttendeeId:', sharingAttendeeId);
                console.log('isLocalUserSharing:', isLocalUserSharing);
                if (data.senderAttendeeId === localAttendeeId) {
                  audioVideo?.stopContentShare();
                  enqueueSnackbar('Your screen share is stopped by teacher', {variant:'warning'});
                  dispatch({
                    type: ActionType.ShareAction,
                    payload: {
                      type: ShareDataMessagesType.DisagreeShare,
                      senderAttendeeId: '',
                      senderName: '',
                      senderUserId: '',
                    }
                  })
                }
                  break;
              default: 
                  throw new Error('Incorrect action in ShareDataMessagesProvider');
            }
        }
    , [audioVideo])


    const sendMessage = useCallback(
        (message: ShareDataMessage) => {
            if (
                !meetingManager ||
                !meetingManager.meetingSession ||
                !meetingManager.meetingSession.configuration.credentials ||
                !meetingManager.meetingSession.configuration.credentials.attendeeId ||
                !audioVideo
              ) {
                return;
            }
            audioVideo.realtimeSendDataMessage(SHARE_DATA_MESSAGE_TOPIC, message, 0);
        }
    , [audioVideo])


    const closeRequestShare = useCallback(
      () => {
          dispatch({
            type: ActionType.ShareAction,
            payload: {
                type: ShareDataMessagesType.CloseRequestShare,
                senderAttendeeId: '',
                senderName: '',
                senderUserId: '',
            }
          })
      }
  , [state.requestShare])


    const value = {
        sendMessage,
        closeRequestShare,
        NotShareContent,
        requestShare: state.requestShare,
        requestShareUserInfo: state.requestShareUserInfo,
        allowShare: state.allowShare,
    };
    return <ShareDataMessagesStateContext.Provider value={value}>{children}</ShareDataMessagesStateContext.Provider>;
}



export function useShareDataMessage (): ShareDataMessagesStateContextType{
  const state = useContext(ShareDataMessagesStateContext);
  
  if (!state) {
    throw new Error('useShareDataMessage must be used with ShareDataMessageProvider');
  }

  return state;
}
