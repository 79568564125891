import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import { CreateAvailableTimeEntity, PrivateLessonTypeEnum, RepeatTypeEnum } from 'src/app/models/schedule'
import { CategoryItem } from 'src/app/models/category'
import { getLegalFileName } from 'src/utils/stringUtil'

export enum PrivateLessonAudioVideoFunctionEnum {
  MUTE_ALL = 'audio_mute_meeting',
  UNMUTE_ALL = 'audio_unmute_meeting',
  OPEN_ALL_VIDEO = 'video_open_meeting',
  CLOSE_ALL_VIDEO = 'video_close_meeting',
}

export const privateLessonApi = createApi({
  reducerPath: 'privateLessonApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getTeacherPrivateLessonDetail: builder.mutation<Result<any>,{teacherId: string}>({
        query: ({teacherId}) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "get_teacher",
            request_data:{
              user_id: teacherId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getTeacherScheduledPrivateLessonByTimeArea: builder.query<Result<any>, {
        userId : string, startTime: number, endTime: number
      }>({
        query: ({ userId, startTime, endTime }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "select_teacher_schedule",
            request_data: {
              user_id: userId,
              start_time: startTime,
              end_time: endTime,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getTeacherScheduleListByTimeSort: builder.mutation<Result<any>, {
        userId : string, startTime: number, pageSize: number, startKey: string
      }>({
        query: ({ userId, startTime, pageSize, startKey }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "select_teacher_schedule",
            request_data: {
              select_type: "list",
              user_id: userId,
              start_time: startTime,
              page_size: pageSize ?? 20,
              start_key: startKey || '', 
              forward_order: true,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      createTeacherAvailableTime: builder.mutation<Result<any>, {
        entity: CreateAvailableTimeEntity
      }>({
        query: ({ entity }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "create_teacher_schedule",
            request_data: {
              start_time: entity.startTime,
              end_time: entity.endTime,
              repeat_type: entity.repeatType,
              repeat_days: entity.repeatDays,
              title: entity.title,
              category_ids: entity.categoryIds,
              original_user_id: entity.originalUserId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),  
      
      deleteTeacherAvailableTime: builder.mutation<Result<any>, {
        schedule_id : string, repeat: boolean, originalUserId: string|undefined
      }>({
        query: ({ schedule_id, repeat, originalUserId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "delete_teacher_schedule",
            request_data: {
              schedule_id: schedule_id,
              repeat: repeat,
              original_user_id: originalUserId
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      createPrivateLessonClass: builder.mutation<Result<any>, {
        scheduleId:string, teacherId : string, startTime: number, timeMinutes?: number
      }>({
        query: ({ scheduleId, teacherId, startTime, timeMinutes}) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "create_private_lesson_classes",
            request_data: {
              teacher_schedule_id: scheduleId,
              teacher_user_id: teacherId,
              start_time: startTime,
              time: timeMinutes,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      createCustomPrivateLessonClass: builder.mutation<Result<any>, {
        startTime: number, timeMinutes: number, fee: number, title: string, teacherId: string|undefined, 
      }>({
        query: ({ teacherId, startTime, timeMinutes, fee, title}) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "create_private_lesson_classes_custom",
            request_data: {
              original_user_id: teacherId,
              start_time: startTime,
              time: timeMinutes,
              fee: fee,
              title: title,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getCustomPrivateLessonClasses: builder.mutation<Result<any>, {
        startKey: string, pageSize: number, forwardOrder?: boolean, teacherId?: string, 
      }>({
        query: ({ teacherId, pageSize, startKey, forwardOrder }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "select_teacher_private_lesson_classes_custom",
            request_data: {
              original_user_id: teacherId,
              page_size: pageSize,
              start_key: startKey,
              forward_order: forwardOrder || false,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      deleteCustomPrivateLessonClass: builder.mutation<Result<any>, {
        classId: string, teacherId: string|undefined
      }>({
        query: ({ classId, teacherId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "delete_private_lesson_classes_custom",
            request_data: {
              id: classId,
              original_user_id: teacherId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getCustomPrivateLessonClassCode: builder.mutation<Result<any>, {
        classId: string, teacherId?: string, 
      }>({
        query: ({ teacherId, classId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "get_private_lesson_classes_custom_code",
            request_data: {
              original_user_id: teacherId,
              id: classId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      bindCustomPrivateLessonClassCode: builder.mutation<Result<any>, {
        classId: string, privateCode: string, 
      }>({
        query: ({ privateCode, classId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "bind_private_lesson_classes_custom_student",
            request_data: {
              booking_code: privateCode,
              id: classId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      // get the student list that booked my time
      getTimeBookedStudentList: builder.mutation<Result<any>, {
        startKey?: string, pageSize?: number, forwardOrder?: boolean
      }>({
        query: ({ startKey, pageSize, forwardOrder }) => ({
          url: `privateLesson/Booking`,
          method: 'POST',
          body: {
            request_type: "select_teacher_booking",
            select_teacher_booking: {
              page_size: pageSize,
              start_key: startKey,
              forward_order: forwardOrder,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      // get the booked info
      getBookedInfo: builder.mutation<Result<any>, {
        book_id: string
      }>({
        query: ({ book_id }) => ({
          url: `privateLesson/Booking`,
          method: 'POST',
          body: {
            request_type: "get_booking",
            get_booking: {
              id: book_id,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),    

      // get the booked info
      getClassroomInfo: builder.mutation<Result<any>, {
        classroomId: string
      }>({
        query: ({ classroomId }) => ({
          url: `privateLesson/Booking`,
          method: 'POST',
          body: {
            request_type: "get_classroom",
            request_data: {
              id: classroomId,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getPrivateLessonClassInfo: builder.mutation<Result<any>, {
        classId: string
      }>({
        query: ({ classId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "get_private_lesson_classes",
            request_data: {
              id: classId,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),


      getPrivateLessonMaterialList: builder.mutation<Result<any>, {
        id: string,
        page_size: number,
        start_key: string,
        forwardOrder?: boolean,
      }>({
        query: ({ id, start_key, page_size, forwardOrder }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "select_private_lesson_material",
            request_data: {
              private_lesson_id: id,
              page_size: page_size || 10,
              start_key: start_key || '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getPrivateLessonClassesMaterialList: builder.mutation<Result<any>, {
        privateLessonClassId: string,
        page_size: number,
        start_key: string,
        forwardOrder?: boolean,
      }>({
        query: ({ privateLessonClassId, start_key, page_size, forwardOrder }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "select_private_lesson_classes_material",
            request_data: {
              private_lesson_classes_id: privateLessonClassId,
              page_size: page_size || 10,
              start_key: start_key || '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      lockPrivateLessonMaterial: builder.mutation<Result<any>, {
        privateLessonId: string,
        materialId: string,
        lock: boolean,
        originalUserId: string|undefined,
      }>({
        query: ({ privateLessonId, materialId, lock, originalUserId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "lock_private_lesson_material",
            request_data: {
              private_lesson_id: privateLessonId,
              id: materialId,
              lock: lock,
              original_user_id: originalUserId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      lockPrivateLessonClassesMaterial: builder.mutation<Result<any>, {
        privateLessonClassId: string,
        materialId: string,
        lock: boolean,
        originalUserId?: string|undefined,
      }>({
        query: ({ privateLessonClassId, materialId, lock, originalUserId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "lock_private_lesson_classes_material",
            request_data: {
              private_lesson_classes_id: privateLessonClassId,
              id: materialId,
              lock: lock,
              original_user_id: originalUserId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      studentUploadPrivateLessonFile: builder.mutation<Result<any>, {
        privateLessonClassId:string,
        fileName:string,
        fileType:string,
      }>({
        query: ({ privateLessonClassId, fileName, fileType }) => ({
          url: `uploadfile`,
          method: 'POST',
          body: {
            update_type: 'student_material',
            file_name: getLegalFileName(fileName),
            file_type: fileType,
            file_source_type: 'private_lesson',
            file_source_id: privateLessonClassId,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      studentCreatePrivateLessonMaterial: builder.mutation<Result<any>, {
        private_lesson_classes_id: string,
        file_name: string,
        file_title:string,
        file_type: string,
        file_size: number,
        file_url: string,
      }>({
        query: ({ private_lesson_classes_id, file_name, file_type, file_size, file_url, file_title }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "create_private_lesson_student_material",
            request_data: {
              private_lesson_classes_id: private_lesson_classes_id,
              file_name: file_name,
              file_type: file_type,
              file_size: file_size,
              file_url: file_url,
              file_title:file_title,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      deletePrivateLessonStudentMaterial: builder.mutation<Result<any>, {
        privateLessonClassId: string,
        fileId: string,
      }>({
        query: ({privateLessonClassId, fileId }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "delete_private_lesson_student_material",
            request_data: {
              private_lesson_classes_id: privateLessonClassId,
              id: fileId,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getPrivateLessonStudentMaterials: builder.mutation<Result<any>, {
        private_lesson_classes_id?: string,
        page_size?: number,
        start_key?: string,
        forwardOrder?: boolean,
      }>({
        query: ({private_lesson_classes_id, start_key, page_size, forwardOrder  }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "select_private_lesson_student_material",
            request_data: {
              private_lesson_classes_id,
              page_size: page_size || 10,
              start_key: start_key || '',
              forward_order: forwardOrder || false,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      addClassesCustomStudent: builder.mutation<Result<any>, {
        private_lesson_classes_id: string,
        student_user_id: string,
        original_user_id?: string,
      }>({
        query: ({private_lesson_classes_id, student_user_id, original_user_id,  }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "add_private_lesson_classes_custom_student",
            request_data: {
              private_lesson_classes_id,
              student_user_id,
              original_user_id,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      removeClassesCustomStudent: builder.mutation<Result<any>, {
        private_lesson_classes_id: string,
        original_user_id?: string,
      }>({
        query: ({private_lesson_classes_id, original_user_id,  }) => ({
          url: `private_lesson`,
          method: 'POST',
          body: {
            request_type: "remove_private_lesson_classes_custom_student",
            request_data: {
              private_lesson_classes_id,
              original_user_id,
            }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const {
  useGetTeacherPrivateLessonDetailMutation,
  useLazyGetTeacherScheduledPrivateLessonByTimeAreaQuery,
  useGetTeacherScheduleListByTimeSortMutation,
  useCreateTeacherAvailableTimeMutation,
  useDeleteTeacherAvailableTimeMutation,
  useCreatePrivateLessonClassMutation,
  useCreateCustomPrivateLessonClassMutation,
  useGetCustomPrivateLessonClassesMutation,
  useDeleteCustomPrivateLessonClassMutation,
  useGetCustomPrivateLessonClassCodeMutation,
  useBindCustomPrivateLessonClassCodeMutation,
  useGetTimeBookedStudentListMutation,
  useGetBookedInfoMutation,
  useGetClassroomInfoMutation,
  useGetPrivateLessonClassInfoMutation,
  useGetPrivateLessonMaterialListMutation,
  useGetPrivateLessonClassesMaterialListMutation,
  useLockPrivateLessonMaterialMutation,
  useLockPrivateLessonClassesMaterialMutation,
  useStudentUploadPrivateLessonFileMutation,
  useStudentCreatePrivateLessonMaterialMutation,
  useDeletePrivateLessonStudentMaterialMutation,
  useGetPrivateLessonStudentMaterialsMutation,
  useAddClassesCustomStudentMutation,
  useRemoveClassesCustomStudentMutation,
} = privateLessonApi
