import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '@dokyo/common'
import { settings } from '../settings'
import { defaultLanguage } from '@dokyo/common'

const keys = settings.keys

interface AppState {
  credential?: User
  language: string
}

// load auth identity from local storage
function _loadState(): AppState {
  const appRaw = localStorage.getItem(keys.app)
  if (appRaw !== null) {
    return JSON.parse(appRaw) as AppState
  }
  return {
    language: defaultLanguage
  }
}

const appSlice = createSlice({
  name: keys.app,
  initialState: _loadState(),
  reducers: {
    setIdentity(state, action: PayloadAction<User | undefined>) {
      state.credential = action.payload
      localStorage.setItem(keys.app, JSON.stringify(state))
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload
      localStorage.setItem(keys.app, JSON.stringify(state))
    },
    clear(state) {
      state = { language: defaultLanguage }
      localStorage.removeItem(keys.app)
    }
  }
})

export const { setIdentity, setLanguage, clear } = appSlice.actions
export default appSlice.reducer
