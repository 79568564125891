import { FC } from 'react';
import Switch from '@mui/material/Switch';
import {
    useToggleLocalMute,
  } from 'amazon-chime-sdk-component-library-react';




const AudioSwitch: FC = () => {
    const { muted, toggleMute } = useToggleLocalMute();


    return (
        <Switch
            checked={!muted}
            onChange={toggleMute}
            name="muteAll"
        />
    )
}

export default AudioSwitch;