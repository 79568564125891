import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TeacherCard } from 'src/components/cards'
import { Loader } from 'src/components/loader'
import { TeacherEntity } from '@dokyo/common'
import SvgComponent from 'src/components/svgComponent'
import { getDisplayUserName } from 'src/utils/stringUtil'

export interface PropsCourseList {
  isSeries: boolean;
  isLoading: boolean;
  teacherList: TeacherEntity[];
  category_tittle?: string;
}

const useStyles = makeStyles(() => ({
  list: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  item: {
    margin: '0 24px 36px 0'
  }
}))


const EmptyList: React.FC =() => {
  return (
    <Box
        sx={{
          display: 'flex',
          height: '400px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <SvgComponent  iconName={"teacherIcon"} style={{ width: "48px", height: "44px"}}/>
        <Box
          sx={{
            mt: '20px'
          }}
          >
          No Data yet.
        </Box>
      </Box>
  )
}

export const TeacherList: React.FC<PropsCourseList> = ({ isSeries, isLoading, teacherList , category_tittle}) => {
  const classes = useStyles();

  return (
    <Box className={classes.list}>
      <Loader isLoading={isLoading} />
      {
        teacherList && teacherList.length > 0?
        teacherList.map((teacher) => {
          const card = {
            id: teacher.user_id,
            avatar: teacher.user_avatar ?? teacher.user_avatar_hd ,
            avatarHd: teacher.user_avatar_hd?? teacher.user_avatar,
            name: teacher.user_name,
            totalLesson: teacher.course_count,
            signature: teacher.personalized_signature?? '',
            //category_tittle: category_tittle ?? `${teacher?.category_list?.map((c)=>{return `${c.name}`})}`,
            category_tittle: category_tittle ?? `${teacher?.category_list && teacher?.category_list?.length>0 ? teacher?.category_list[0].name : getDisplayUserName(teacher.user_name, teacher.user_id)}`,
          }
          return (
            <div key={card.id} className={classes.item}>
              <TeacherCard key={card.id} card={card} isSeries={isSeries}/>
            </div>
          )
        }):
        isLoading? '': <EmptyList />
      }
    </Box>
  )
}