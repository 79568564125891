// use case: Library page

import { Box, Card, CardContent, CardMedia, CardProps, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Video } from 'src/app/models'
import React from 'react'
import { Link as RouteLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  content: {
    padding: '10px 0 0 0 !important'
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 10
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  title: {
    fontWeight: '600 !important',
    fontSize: '1rem !important',
    color: '#000000',
    lineHeight: '1.2rem !important',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },
  channel: {
    fontSize: '14px',
    color: '#717171',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },

  image__container: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%'
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: '0',
    background: 'white',
    top: '0',
  },

  cart: {
    visibility: 'hidden',

    '&.active': {
      visibility: 'visible'
    }
  }
}))

interface VideoCardProps extends CardProps {
  video: Video
  onCartAdded: (video: Video) => void
}

export const VideoCardV2: React.FC<VideoCardProps> = ({ video, onCartAdded, ...props }) => {
  const classes = useStyles()
  const [, setActive] = React.useState(false)

  return (
    <Card
      {...props}
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={{ background: 'transparent' }}
    >
      <RouteLink to={`/watch?v=${video.id}`}>
        <Box className={classes.image__container}>
          <CardMedia
            component="img"
            height="160"
            image={video.thumbnail}
            alt="Paella dish"
            className={classes.image}
          />
        </Box>
      </RouteLink>
      <CardContent className={classes.content} >
        <RouteLink to={`/watch?v=${video.id}`} style={{ textDecoration: 'none' }}>
          <Typography variant="h5" className={classes.title}>
            {video.title}
          </Typography>
        </RouteLink>
        <div className={classes.container}>
          <div style={{ flexGrow: 1 }}>
            <Typography className={classes.channel}>{video.channel?.name}</Typography>
            <Typography variant="caption">
              {video.view} views * {video.timeCreated} housrs ago
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
