export const VICTORIA_SCHOOL_ID = "24a13272-80cd-43f6-85b5-96503e2970be";
export const VEEKO_SCHOOL_ID = "aa6ac9ec-f9b9-452b-b1bc-9ed42950a889";
export const VICTORIA_INTERNATIONAL_COLLEGE_SCHOOL_ID = "2dbe94bf-1584-4d0a-a1cd-3b48c38ed31b";
export const VICTORIA_IVY_EXPRESS_SCHOOL_ID = "130bd0bc-1665-49e6-8b5a-37a46bc0ae3e";


export const ErrorCode = {
    SIGN_UP_USER_ID_EXIST: 202001,
    SIGN_UP_VERIFY_CODE_INCORRECT: 202005,
    SIGN_IN_USER_ID_NOT_EXIST: 201117,
    SIGN_IN_PASSWORD_INCORRECT: 201118,
    SIGN_IN_TRY_TOO_MANY_TIMES: 201020,
    SIGN_IN_VERIFY_CODE_INCORRECT: 201109,
    REFIND_PASSWORD_USER_ID_NOT_EXIST: 202002,
    REFIND_PASSWORD_VERIFY_CODE_INCORRECT: 201111,
    TEACHER_TIME_IS_BOOKED: 301014,
    TEACHER_TIME_START_IS_PAST: 301035,
    TEACHER_TIME_IS_SCHEDULED: 301036,
    PRIVATE_LESSON_NOT_STARTED: 301049,
    PRIVATE_LESSON_WAITING_START: 301052,
}