import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'

export const thirdPartyLoginApi = createApi({
  reducerPath: 'thirdPartyLoginApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      thirdPartyLoginStep1: builder.mutation<Result<any>, { 
            thirdToken: string, 
            loginType: number, //2-Google;3-Facebook;4-Apple
            referralID?: string,
            schoolID?: string 
        }>({
        query: ({ thirdToken, loginType, referralID, schoolID }) => ({
          url: `thirdpartylogin`,
          method: 'POST',
          body: loginType === 4 ? { 
                login_type: loginType,
                token: thirdToken,
                client_type: 3,
                user_name: "Apple User",
                client_id: "com.veeko.portal",
                referral_id: referralID,
            } : {
                login_type: loginType,
                token: thirdToken,
                client_type: 3,
                referral_id: referralID,
                school_id: schoolID
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      thirdPartyLoginStep2: builder.mutation<Result<any>, { 
          loginType: number, 
          userId:string, 
          publicKey: string,
          verifyMessage: string,
          signature: string }>({
        query: ({ loginType, userId, publicKey, verifyMessage, signature }) => ({
          url: `thirdpartylogin`,
          method: 'POST',
          body: { 
            login_type: loginType,
            user_id: userId,
            public_key: publicKey,
            verify_message: verifyMessage,
            signature: signature,
            client_type: 3,
           }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      })
    }
  }
})

export const { useThirdPartyLoginStep1Mutation, useThirdPartyLoginStep2Mutation } = thirdPartyLoginApi
