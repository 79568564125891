import React from 'react'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import { BroadcastEntity } from '@dokyo/common'
import { LiveNow, UpComing, LiveNowAndUpComing } from '.'

export const getBroadcastData = (broadcastList: BroadcastEntity[]): ClassroomListCardEntity[] => {
  const classRoomDataList: ClassroomListCardEntity[] = [] 
  
  broadcastList.forEach(learnPage => {
    if (learnPage && learnPage.broadcast_id) {
      classRoomDataList.push({
        id: learnPage.broadcast_id,
        media_image: learnPage.broadcast_cover,
        class: learnPage.category.name,
        content_header: learnPage.broadcast_title,
        time: learnPage.broadcast_start_time
      })
    }
  })
  return classRoomDataList
}

export enum ListType {
  LiveNow,
  UpComing
}

export interface PropsBroadcastList {
}

export const BroadcastList: React.FC<PropsBroadcastList> =() => {


  const [listType, setListType] = React.useState<ListType>(ListType.LiveNow)
  const [isShowList, setIsShowList] = React.useState<Boolean>(false)

  const handleOnClickMore = (type: ListType) => {
    setIsShowList(true)
    setListType(type)
  }

  const handleOnClickBack = () => {
    setIsShowList(false)
  }

  
  return (
    <>
    {
      isShowList?
        listType === ListType.LiveNow?
        <LiveNow onClickBack={handleOnClickBack} />:
        listType === ListType.UpComing?
        <UpComing onClickBack={handleOnClickBack} />:
        '':
      <LiveNowAndUpComing onClickMore={handleOnClickMore} />
    }
    </>
  )
}