import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Loader } from 'src/components/loader'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import { ClassroomListCard } from 'src/components/cards/ClassroomListCard'
import { useNavigate } from 'react-router-dom'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import SvgComponent from 'src/components/svgComponent'
import { ListPagination } from 'src/components/pagination'
import { ListType, getClassroomData, isClassroomNow, navigateVideoClassroom } from './VideoClassroomList'
// import { useGetQueryMeetingListQuery } from 'src/app/service/real/coursedetail-slice'
import { useGetLearnPageQuery } from 'src/app/service/real/learnpage-slice'
import { useDispatch } from 'react-redux'


const useStyles = makeStyles(() => ({
  courseList: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '22px'
  },
  listTitle: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    marginBottom: '20px',
    marginLeft: '12px'  
  }
}))

const ListTitle = styled(Typography)({
  fontSize: '30px',
  fontWeight: 'bold',
  lineHeight: '40px',
  marginLeft: '15px'
})


export interface Props {
  onClickBack: () => void
  type: ListType
}

export const ClassroomNow: React.FC<Props> =({ onClickBack, type }) => {
  const classes = useStyles();
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const [hasMore, setHasMore] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalCount = 0;
  const [startKeyArray, setStartKeyArray] = React.useState(["", ""]);
  const [maxPage, setMaxPage] = React.useState(1)
  const [isShowPagination, setIsShowPagination] = React.useState<Boolean>(false)


  const handleOnClickPrev = () => {
    setCurrentPage(currentPage - 1)
  }
  const handleOnClickNext = () => {
    
    setCurrentPage(currentPage + 1)
    // if (maxPage <= currentPage) {
    //   setMaxPage(currentPage + 1)
    // }
  }

  const handleOnClickPage = (page) => {
    setCurrentPage(page)
  }

  const handleClick = (videoClassroom: ClassroomListCardEntity) => {
    navigateVideoClassroom(videoClassroom, dispatch, navigate)
  }

  let listData: ClassroomListCardEntity[] = []

  // const { data, isFetching } = useGetQueryMeetingListQuery({
  //   startKey: startKeyArray[currentPage],
  //   pageSize: 20
  // })

  const learnType = type === ListType.Now? 'live_now_meeting': 'upcoming_meeting';

  const { data: liveData, isFetching } = useGetLearnPageQuery({
    learnType,
    startKey: startKeyArray[currentPage],
    pageSize: 20
  }, {
    refetchOnMountOrArgChange: 60
  })

  if (liveData && !liveData.isFailed && liveData.result?.learn_page) {
    if (liveData.result.learn_page[0]?.meeting_list) {
      listData = getClassroomData(liveData.result.learn_page[0]?.meeting_list)
    } else {
      listData = []
    }
  }

  // if (data && !data.isFailed && data.result) {
  //   if (data.result.meeting_list) {
  //     // listData = getClassroomData(liveData.result.meeting_list)
  //     const total = getClassroomData(data.result.meeting_list)
  //     total.forEach(item => {
  //       if (type === ListType.Now && isClassroomNow(item.time)) {
  //         listData.push(item)
  //       } else if(type === ListType.UpComing && !isClassroomNow(item.time)) {
  //         listData.push(item)
  //       }
  //     })
  //   } else {
  //     listData = []
  //   }
    
  // }

  useEffect(() => {
    if (liveData) {
      const newStartKeyArray = [...startKeyArray]
      let newStartKey = liveData.result.learn_page[0]?.start_key
      newStartKey = typeof newStartKey === 'string'? newStartKey: ''
      newStartKeyArray[currentPage + 1] = newStartKey
      setStartKeyArray(newStartKeyArray)
      setHasMore(newStartKey !== '')
      setIsShowPagination(true)
      if (newStartKey && currentPage + 1 > maxPage) {
        setMaxPage(currentPage + 1)
      }
    }
  }, [liveData])

  const handleBack= () => {
    onClickBack()
  }

  return (
    <>
      <Loader isLoading={isFetching} />
      <Box>
        <Box className={classes.listTitle}>
          <SvgComponent onClick={handleBack} iconName={"leftArrow"} style={{ marginRight: "8px", width: "20px", height: "18px", cursor: 'pointer'}}/>
          <ListTitle>
            {
              type === ListType.Now? 
              'Classroom Now':
              'Upcoming Classroom'
            }
          </ListTitle>
        </Box>
        <Box className={classes.courseList}>
          {
            listData?
            listData.map((videoClassroom) => {
              return (
                <Box key={videoClassroom.id} onClick={() => {handleClick(videoClassroom)}}>
                  <ClassroomListCard
                    card={videoClassroom}
                    showLabel={type === ListType.Now}
                    label={'Video'}
                  />
                </Box>
              )
            }):
            ''
          }
        </Box>
        {
          isShowPagination?
          <ListPagination
            currentPage={currentPage} 
            hasMore={hasMore} 
            rowsPerPage={5}
            maxPage={maxPage}
            totalCount={totalCount}
            onClickPage={handleOnClickPage}
            onClickPrev={handleOnClickPrev} 
            onClickNext={handleOnClickNext}
          />:
          ''
        }
      </Box>
    </>
  )
}