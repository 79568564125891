import react, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MyScheduleCalendar from './MyScheduleCalendar';
import { Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MyScheduleList from './MyScheduleList';
import { styled } from '@mui/system';
import SvgComponent from 'src/components/svgComponent';
import { useParams } from 'react-router-dom';
import { buildStudioScheduleUrl } from 'src/app-config';


const useStyles = makeStyles((theme: Theme) => ({
    root:{
      textAlign: "center"
    },
    container: {
      width: "90%",
      maxWidth:'1400px',
      margin:'0 auto',
      textAlign: 'center',
      padding: '50px',
      [theme.breakpoints.down('sm')]:{
        width: "100%",
        maxWidth: '100%',
        padding: '0px 10px 0px 10px',
      }
    },
    toggleButton: {
      marginTop: "10px",
      [theme.breakpoints.down("sm")]:{
        marginTop: "20px",
      }
    },
}))

const CustomToggleButton = styled(ToggleButton) ({
  width:"160px",
  textTransform:"none",
  // background: 'rgba(48, 125, 207, 0.08)',
  // color: '#7C849C',
  // '&.Mui-selected': {
  //   background: '#307DCF',
  //   color: 'white',
  //   ':hover': {
  //     background: '#307DCFEE',
  //   }
  // },
})

const MySchedule : React.FC = () => {
  const classes = useStyles();  
  const { subTabName } = useParams();
  const [viewType, setViewType] = useState(subTabName || "list");


  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment) {
      setViewType(newAlignment)
      const newUrl = buildStudioScheduleUrl(newAlignment);
      window.history.pushState({}, "", newUrl);
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <ToggleButtonGroup
            color="primary"
            value={viewType}
            exclusive
            onChange={handleChange}
            aria-label="View Type"
            className={classes.toggleButton}
            >
            <CustomToggleButton value="list" >
              <SvgComponent iconName={viewType === "list" ? 'ico-schedule-list-blue': 'ico-schedule-list-grey'} style={{width: "16px", height: "15px", marginRight: "11px"}} />
              List
            </CustomToggleButton>
            <CustomToggleButton value="calendar" >
              <SvgComponent iconName={viewType === "calendar" ? 'ico-schedule-calendar-blue' : 'ico-schedule-calendar-grey'} style={{width: "20px", height: "20px", marginRight: "11px"}} />
              Calendar
            </CustomToggleButton>
        </ToggleButtonGroup>

        {viewType === 'list' ?
        <MyScheduleList />
        :
        <MyScheduleCalendar/>
        }
      </Box>
    </Box>
  )
}

export default MySchedule;