
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import SvgComponent from '../svgComponent'

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: "20px",
    cursor: "pointer"
  },
  icon: {
    width: "12px !important",
    height: "20px !important"
  },
}))

interface Props {
  onClickBack?: () => void
}

const PageBackButton: React.FC<Props> = ({onClickBack}) => { 
  const classes = useStyles();
  const navigate = useNavigate();
  
  const handleClickBack = () => {
    if (onClickBack) {
      onClickBack();
      return;
    }
    const prevPage = window.location.href;
    if (document.referrer) {
      window.history.back()
    } else {
      navigate(-1)
    }
    setTimeout(function(){ 
      if (window.location.href === prevPage) {
        navigate(appConfig.paths.home)
      }
    }, 500);
  }

  return (
    <>
      <Box className={classes.container} onClick={handleClickBack}>
        <SvgComponent iconName="ico-arrow-back" style={{ width:'10px', height:'16px', marginRight:'8px' }} />
        <Typography 
          style={{
            width: "35px",
            height: "17px",
            fontSize: "16px",
            fontFamily: "ArialMT",
            color: "#000000",
            lineHeight: "18px"}}
          >
          Back
        </Typography>
      </Box>
    </>
  )
}

export default PageBackButton
