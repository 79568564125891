import React from "react";
import { makeStyles } from '@mui/styles'
import { Box, Theme } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import picCourse from 'src/images/teacherinfo/pic-course.png'
import Icon from 'src/images/teacherinfo/ico-sign.png'

const useStyles = makeStyles((theme: Theme) => ({
    rootBox: {
        background: "#F9F9F9",
        padding: "55px 135px 86px 131px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            padding: "20px",
            alignItems: 'flex-start',
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        }
    },
    leftBox: {
        width: "626px",
        marginRight: "39px",
        [theme.breakpoints.down('lg')]: {
            width: "100%",
            marginRight: "0px",
        }
    },
    rightBox: {
        display: "flex",
        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
            width:"100%",
            flexDirection:"row"
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            flexDirection: "column"
        }
    },
    title1: {
        fontSize: "18px",
        fontFamily: "Avenir-Book, Avenir",
        fontWeight: "normal",
        color: "#307DCF",
        lineHeight: "30px",
        marginBottom: "23px",
        [theme.breakpoints.down('lg')]: {
            marginBottom: "10px",
        },
    },
    title2: {
        fontSize: "40px",
        fontFamily: "Avenir-Black, Avenir",
        fontWeight: "900",
        color: "#000000",
        margin: "14px 0px 3px 0px",
        lineHeight: "48px",
        [theme.breakpoints.down('lg')]: {
            margin: "0px",
            fontSize: "30px",
            lineHeight: "40px"
        }
    },
    content: {
        fontSize: "18px",
        fontFamily: "Avenir-Book, Avenir",
        fontWeight: "normal",
        color: "#555555",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        }
    },
    picCourseBox: {
        width: "464px",
        height: "261px",
        [theme.breakpoints.down('lg')]: {
            width: "50%",
            height: "100%",
            marginTop: "5px",
            marginRight:"20px"
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginRight: "0"
        }
    },
    picCourseImg: {
        width: "100%"
    },
    list: {
        marginTop: "33px",
        marginLeft: "55px",
        [theme.breakpoints.down('md')]: {
            marginTop: "5px",
            marginLeft: "0px",
        }
    },
    listItem: {
        display: "flex",
        alignItems: 'center',
        fontSize: "18px",
        fontFamily: "Avenir-Medium, Avenir",
        fontWeight: "500",
        color: "#000000",
        marginBottom: "20px",
        [theme.breakpoints.down('lg')]: {
            marginBottom: "10px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
        }
    },
    listItemIcon: {
        marginRight: "4px"
    }
}))
const AboutCourse: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const listData = {
        content: [
            "Hello everyone! My name is Manuela Sadovnik and I am one of your Fine Arts teacher here at Veeko 😁 ! My class is offered to K-12 students, and the activities are designed so that students of all experience levels can enjoy art and learn a little bit about it every time we meet! ",

            "In my class, your motivation and passion to learn about art and have fun with it is an essential requirement so we can make amazing experiences in every class. Through open conversations, usage of digital media, and hands-on activities, we will be discovering art history, and learn about various art movements, artists, and even art manifestations in ancient civilizations all while we do very fun and cool artistic activities inspired by them! And every now and then, we will be using non-conventional materials to make learning about art even more fun and expand our possibilities with it! ",

            "Come join me and have some fun while we learn!"],
        rightList: [
            {
                id: "1",
                image: Icon,
                content: "10 hands on art class videos"
            },
            {
                id: "2",
                image: Icon,
                content: "10 bonus art history class videos​"
            },
            {
                id: "3",
                image: Icon,
                content: "1 FREE live group class spot​"
            }
        ]
    }
    return (
        <Box className={classes.rootBox}>
            <Box className={classes.leftBox}>
                <Box className={classes.title1}>About Course</Box>
                <Box className={classes.title2}>Fine Arts Course with Ms. Manuela</Box>
                <Box className={classes.content}>
                    <p>{listData.content[0]}</p>
                    <p>{listData.content[1]}</p>
                    <p>{listData.content[2]}</p>
                </Box>
            </Box>
            <Box className={classes.rightBox}>
                <Box className={classes.picCourseBox}>
                    <img src={picCourse} className={classes.picCourseImg} />
                </Box>
                <Box className={classes.list}>
                    {listData.rightList.map(item => (
                        <Box className={classes.listItem} key={item.id}>
                            <img src={item.image} className={classes.listItemIcon} />
                            <Box>{item.content}</Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}
export default AboutCourse

