import React, { useState, useEffect } from 'react';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SvgComponent from 'src/components/svgComponent';
import { useGetItemInCartQuery, useAddCartItemMutation, useLazyGetItemInCartQuery } from 'src/app/service/real/cart-api';
import Loader from '../loader';
import { useSnackbar } from 'notistack';
import { getAuthStatus } from 'src/utils/auth';
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme:Theme) => ({
  btnItem: {
    width: "100%",
    height: "48px",
  },
  newBtnItem: {
    width: "148px",
    height: "44px",
    marginLeft: "20px",
    [theme.breakpoints.down('sm')]:{
      marginLeft: "0px",
    }

  },
}))


interface Props {
  purchasedSeparately?: boolean;
  seriesId: string;
  newStyle: boolean;
}

export const CartButtonComponent: React.FC<Props> = ({
  purchasedSeparately,
  seriesId,
  newStyle,
}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [itemInCart, setItemInCart ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const authStatus = getAuthStatus()();
  const dispatch = useDispatch();
  // purchasedSeparately
  // check in cart

  // click  addcartitem

  const [getItemInCartQuery, { data }] =  useLazyGetItemInCartQuery();

  // const { data  } = useGetItemInCartQuery({ seriesId }, {
  //   refetchOnMountOrArgChange: true
  // });

  useEffect(() => {
    if (seriesId && authStatus.isLogin) {
      getItemInCartQuery({ seriesId })
    }
  }, [seriesId])
  
  useEffect(() => {
    // console.log(data)
    if (data && !data.isFailed && data.result) {
      setItemInCart(data.result.itemInCart);
    }
  }, [data])
  const[addCartItem, { isLoading: addLoading }] = useAddCartItemMutation()

  const onAddToCart = async () => {
    try {
      if (!authStatus.isLogin) {
        dispatch(showSignInUpDialog())
        return;
      }



      const data = await addCartItem({
        seriesId
      }).unwrap();
      // console.log(data)
      if (data && !data.isFailed) {
        setItemInCart(true)
        enqueueSnackbar(t("cart.addSuccess"))
      }
    } catch (e) {
      // console.log(e)
      enqueueSnackbar(t("cart.addFailed"), {variant: "error"})
    }
  }
  // console.log('itemInCart', itemInCart)


  const CartButton = styled(Button)({
    borderRadius: newStyle ? "24px" : "8px",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#F5A623",
    color: "white",
    fontFamily: "ArialMT",
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: "#F5A62355",
      borderRadius:  newStyle ? "24px" : "8px",
      border: "none",
    },
    ':hover': {
      boxShadow: "none",
      backgroundColor: "#F5B600",
    }
  })

  return (
    <>
      <Loader isLoading={addLoading}/>
      <CartButton
        disabled={purchasedSeparately || itemInCart}
        color="secondary"
        variant='contained'
        className={newStyle ? classes.newBtnItem : classes.btnItem}
        style={{textTransform: "none", marginBottom: "18px"}}
        startIcon={<SvgComponent iconName={purchasedSeparately || itemInCart? "ico-purchased" : "cartSmallWhite"} className="icon-source" style={{width: '20px', height:'20px'}}/>}
        onClick={onAddToCart}
      >
      {t("class.add-to-cart")}
      </CartButton>
    </>
  )
}
