
import { Box, Avatar, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { appConfig, buildTeacherUrl } from 'src/app-config'
import { getDisplayUserName } from 'src/utils/stringUtil'
import { LearnInfoTeacher } from '@dokyo/common'
import defaultUserAvatar from 'src/images/class/ic-user-avatar-default.png'
import { Theme } from '@mui/system'
import { TEACHER_TAB_VALUES } from 'src/pages/teacher/teacher'
import SvgComponent from '../svgComponent'
import { useDispatch } from 'react-redux'
import { showChatDialog } from 'src/app/service/chat-slice'
import { useSelector } from 'src/app/toolkit/store'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'

const useStyles = makeStyles((theme: Theme) => ({
  teacherContainer: {
    display: "flex"
  },
  userInfoRightBox: {
    paddingLeft: "15px"
  },
  teacherName: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "Arial-BoldMT, Arial",
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem'
    }
  },
  teacherIntro: {
    fontSize: "14px",
    color: "#555555",
    fontFamily: "ArialMT",
    marginTop: "5px",
    height: "40px",
    lineHeight: "20px",
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    lineClamp: 2,
    WebkitLineClamp: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
      height: "fit-content",
    }
  },
  btnExplore: {
    background: "#307DCF !important",
    borderRadius: "8px !important",
    color: "white !important",
    marginTop: "18px !important",
    width: "148px !important",
    height: "48px !important",
    fontSize: "16px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      width: "100px !important",
      height: "33px !important",
      borderRadius: "5px !important",
      fontSize: "12px !important",
      marginTop: "10px !important",
    }
  },
  btnChat: {
    marginLeft: "32px !important",
    border: "1px solid #307DCF !important",
    borderRadius: "8px !important",
    color: "#307DCF !important",
    marginTop: "18px !important",
    width: "188px !important",
    height: "48px !important",
    padding: '5px !important',
    fontSize: "14px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      width: "140px !important",
      height: "33px !important",
      borderRadius: "5px !important",
      fontSize: "12px !important",
      marginTop: "10px !important",
      marginLeft: "10px !important",
    }
  },
  iconChat: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
    marginBottom: '3px',    
    [theme.breakpoints.down('md')]: {
      width: "0px",
      height: "0px",
    }
  }

}))

interface Props {
  teacherId: string;
  teacherName: string;
  teacherAvatar: string;
  teacherSignature: string;
  showChat: boolean;
}

const TeacherInfo: React.FC<Props> = ({teacherId, teacherName, teacherAvatar, teacherSignature, showChat}) => { 
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {credential} = useSelector(state => state.app);
  
  const handleClickExplore = () => {
    const teacherUrl = buildTeacherUrl(teacherId, TEACHER_TAB_VALUES.TEACHER_INFO);
    navigate(teacherUrl);
  }

  const handleClickChat = () => {
    if (!credential) {
      dispatch(showSignInUpDialog());
    } else {
      dispatch(showChatDialog(teacherId));
    } 
  }

  return (
    <>
      <Box className={classes.teacherContainer}>
        <Avatar sx={(theme: Theme) => ({
              width: '96px',
              height: '96px',
              [theme.breakpoints.down('md')]: {
                width: '64px',
                height: '64px',
              }
          })} src={teacherAvatar || defaultUserAvatar}/>
        <Box className={classes.userInfoRightBox}>
          <Box className={classes.teacherName}>{getDisplayUserName(teacherName, teacherId)}</Box>
          {teacherSignature ? 
            <Box className={classes.teacherIntro}>{teacherSignature}</Box>
          : ""}
          <Button className={classes.btnExplore} style={{textTransform: "none"}} onClick={handleClickExplore}>
            {t("class.explore-class")}
          </Button>

          {showChat &&
          <Button variant='outlined' className={classes.btnChat} style={{textTransform: "none"}} onClick={handleClickChat}>
            <SvgComponent iconName='ico-chat' className={classes.iconChat}/>
            {t("class.chat-with-teacher")}
          </Button>
          }
        </Box>
      </Box>
    </>
  )
}

export default TeacherInfo
