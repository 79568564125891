import React from 'react'
import { Box } from '@mui/material'
import SvgComponent from 'src/components/svgComponent'

export interface Props {
  name: string
}

export const EmptyList: React.FC<Props> =({ name }) => {
  return (
    <Box
        sx={{
          display: 'flex',
          height: '400px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <SvgComponent  iconName={"videoIcon"} style={{ width: "48px", height: "44px"}}/>
        <Box
          sx={{
            mt: '20px'
          }}
          >
          {name}
        </Box>
      </Box>
  )
}