
import * as React from "react";
import { Box, Button, Typography, Theme  } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => createStyles({
    page: {
      marginBottom: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '32px',    
    },
    numberPointer: {
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold'
    },
    numberMargin: {
      marginLeft: '22px',
    },
    currentNumber: {
      color: '#fff',
      background: theme.palette.primary.main,
      display: 'inline-block',
      width: '32px',
      height: '32px',
      textAlign: 'center',
      borderRadius: '6px',
    },
    buttonText: {
      textTransform: 'capitalize',
      fontSize: '14px'
    }
  })
);


interface PropsList {
  currentPage: number;
  hasMore: boolean;
  totalCount?: number;
  rowsPerPage: number;
  maxPage: number;
  onClickPage: (page: number) => void
  onClickPrev: () => void;
  onClickNext: () => void;
}

export const ListPagination: React.FC<PropsList> = (props) =>  {
  const classes = useStyles();
  const {currentPage, hasMore, totalCount, rowsPerPage, maxPage, onClickPage, onClickPrev, onClickNext} = props;
  
  const getPaginationDesc = () => {
    if (!totalCount) {
      return "";
    }
    if (totalCount === 1) {
      return "1 count, 1 page of all";
    } else if (Math.ceil(totalCount/rowsPerPage) === 1) {
      return totalCount + " counts, " + Math.ceil(totalCount/rowsPerPage) + " page of all";
    } else {
      return totalCount + " counts, " + Math.ceil(totalCount/rowsPerPage) + " pages of all";
    }
  }
  const handleClickPage = (e) => {
    const page = e?.target?.dataset?.page
    if (page) {
      onClickPage(+page)
    }
  }


  const PageList = () => {

    const pageList: JSX.Element[] = []

    if (maxPage <= 10) {
      for (let i = 1; i <= maxPage; i++) {
        pageList.push(<span key={i} data-page={i} className={clsx(classes.numberPointer, {
          [classes.numberMargin]: i !== 1,
          [classes.currentNumber]: currentPage === i
        })}>{i}</span>)
      }
    } else if (currentPage - 5 <= 1) {
      for (let i = 1; i <= 9; i++) {
        pageList.push(<span key={i} data-page={i} className={clsx(classes.numberPointer, {
          [classes.numberMargin]: i !== 1,
          [classes.currentNumber]: currentPage === i
        })}>{i}</span>)
      }
      pageList.push(<span key={'right'} className={clsx(classes.numberMargin)}>{'...'}</span>)
      pageList.push(<span key={maxPage} data-page={maxPage} className={clsx(classes.numberPointer, classes.numberMargin)}>{maxPage}</span>)
    } else if (currentPage + 5 >= maxPage) {
      pageList.push(<span key={1} data-page={1} className={clsx(classes.numberPointer)}>{1}</span>)
      pageList.push(<span key={'left'} className={clsx(classes.numberMargin)}>{'...'}</span>)
      for (let i = maxPage - 8; i <= maxPage; i++) {
        pageList.push(<span key={i} data-page={i} className={clsx(classes.numberPointer, classes.numberMargin, {
          [classes.currentNumber]: currentPage === i
        })}>{i}</span>)
      }
    } else {
      pageList.push(<span key={1} data-page={1} className={clsx(classes.numberPointer)}>{1}</span>)
      pageList.push(<span key={'left'} className={clsx(classes.numberMargin)}>{'...'}</span>)
      for (let i = currentPage - 4; i <= currentPage + 4; i++) {
        pageList.push(<span key={i} data-page={i} className={clsx(classes.numberPointer, classes.numberMargin, {
          [classes.currentNumber]: currentPage === i
        })}>{i}</span>)
      }
      pageList.push(<span key={'right'} className={clsx(classes.numberMargin)}>{'...'}</span>)
      pageList.push(<span key={maxPage} data-page={maxPage} className={clsx(classes.numberPointer, classes.numberMargin)}>{maxPage}</span>)

    }


    // for (let i = 1; i <= maxPage; i++) {
    //   pageList.push(<span key={i} data-page={i} className={clsx(classes.numberPointer, {
    //     [classes.numberMargin]: i !== 1,
    //     [classes.currentNumber]: currentPage === i
    //   })}>{i}</span>)
    // }


    return <span onClick={handleClickPage}>{pageList}</span>
  }

  return (
    <Box className={classes.page}>
      <Button sx={{marginRight: '30px'}} onClick={onClickPrev} disabled={currentPage === 1}>
         <NavigateBeforeRoundedIcon color={currentPage !== 1 ? "primary" : "inherit"} fontSize="medium"/> 
         <Box className={classes.buttonText} color={hasMore ? "primary" : "inherit"}>Previous</Box>
      </Button> 
      {PageList()}
      <Button sx={{marginLeft: '30px'}} onClick={onClickNext} disabled={!hasMore}>
        <Box className={classes.buttonText} color={hasMore ? "primary" : "inherit"}>Next</Box>
        <NavigateNextRoundedIcon  color={hasMore ? "primary" : "inherit"} fontSize="medium"/> 
      </Button> 
      <Typography component="span" color="textSecondary">{getPaginationDesc()}</Typography>
    </Box>
  );
}

export default ListPagination;