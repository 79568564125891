
import IcoAll from 'src/images/veekohomepage/veekoteachers/category/category-all.png'
import IcoBaritone from 'src/images/veekohomepage/veekoteachers/category/category-baritone.png'
import IcoDogTraining from 'src/images/veekohomepage/veekoteachers/category/category-dog.png'
import IcoStem from 'src/images/veekohomepage/veekoteachers/category/category-stem.png'
// import IcoTenor from 'src/images/veekohomepage/veekoteachers/category/category-tenor.png'
import IcoHiphop from 'src/images/veekohomepage/veekoteachers/category/category-hiphop.png'
import IcoBelcanto from 'src/images/veekohomepage/veekoteachers/category/category-belcanto.png'
import IcoMagic from 'src/images/veekohomepage/veekoteachers/category/category-magic.png'
import IcoPiano from 'src/images/veekohomepage/veekoteachers/category/category-piano.png'
import IcoGuitar from 'src/images/veekohomepage/veekoteachers/category/category-guitar.png'
import IcoYoga from 'src/images/veekohomepage/veekoteachers/category/category-yoga.png'
// import IcoLanguage from 'src/images/veekohomepage/veekoteachers/category/category-language.png'
import IcoSpanish from 'src/images/veekohomepage/veekoteachers/category/category-spanish.png'
import IcoChinese from 'src/images/veekohomepage/veekoteachers/category/category-chinese.png'
import IcoMath from 'src/images/veekohomepage/veekoteachers/category/category-math.png'
import IcoFineArts from 'src/images/veekohomepage/veekoteachers/category/category-finearts.png'
// import IcoDrawing from 'src/images/veekohomepage/veekoteachers/category/category-drawing.png'
import IcoStorytelling from 'src/images/veekohomepage/veekoteachers/category/category-storytelling.png'
import IcoBallet from 'src/images/veekohomepage/veekoteachers/category/category-ballet.png'
import IcoCello from 'src/images/veekohomepage/veekoteachers/category/category-cello.png'
import IcoClarinet from 'src/images/veekohomepage/veekoteachers/category/category-clarinet.png'
import IcoDoublebass from 'src/images/veekohomepage/veekoteachers/category/category-doublebass.png'
import IcoFlute from 'src/images/veekohomepage/veekoteachers/category/category-flute.png'
import IcoMusicTheory from 'src/images/veekohomepage/veekoteachers/category/category-music.png'
import IcoMusic2 from 'src/images/veekohomepage/veekoteachers/category/category-music2.png'
import IcoOboe from 'src/images/veekohomepage/veekoteachers/category/category-oboe.png'
import IcoSinging from 'src/images/veekohomepage/veekoteachers/category/category-singing.png'
import IcoViola from 'src/images/veekohomepage/veekoteachers/category/category-viola.png'
import IcoViolin from 'src/images/veekohomepage/veekoteachers/category/category-violin.png'
import IcoArchived from 'src/images/veekohomepage/veekoteachers/category/category-archived.png'

import { CategoryItem, CategoryTeacherItem, SchoolCategoryTeacher, TeacherItem } from 'src/app/models/category'

export const getCategoryJson = async(isSimple?: boolean): Promise<CategoryItem[]> => {
    return new Promise((resolve) => {
        fetch(isSimple ? (process.env.REACT_APP_CATEGORY_URL||"") : (process.env.REACT_APP_CATEGORY_ALL_URL||""),{
          cache: "no-cache"
        })
        .then(response => resolve(response.json()))
        .catch(error => {
          console.error(error);
        });
    });
}

/**
 * generate category list from category json
 * @param list category list from json file
 * @param replaceBackground replace the background with images
 * @returns function
 */
export const generateCategoryList = (list: CategoryItem[], replaceBackground: boolean) => {
    const result: CategoryItem[] = [];
    return (): CategoryItem[] => {
      if (result.length === 0) {
        (function inner(categoryList: CategoryItem[]) {
          categoryList.forEach(category => {
            if (category.category) {
              inner(category.category)
            } else {
              result.push({
                name: category.name,
                id: category.id,
                icon: category.icon,
                background: replaceBackground ? getCategoryBackground(category) : category.background
              })
            }
          })
        })(list)
      }
      return result;
    }
}

export const generateTwoLevelCategoryList = (list: CategoryItem[]) => {
  const result: CategoryItem[] = [];
  list.forEach(item1 => {
    const newItem1 = item1;
    result.push(newItem1)
    if (item1?.category && item1?.category?.length > 0) {
      const subCategories: CategoryItem[] = []
      const subList = item1.category;
      subList.forEach(item2 => {
        if (item2.category && item2.category?.length > 0) {
          item2.category.forEach(item3 => {
            subCategories.push(item3);
          })
        } else {
          subCategories.push(item2);
        }
      })
      newItem1.category = subCategories;
    }
  })
  return result;
}

export const findCategory = (categoryId: string, list: CategoryItem[]) : CategoryItem|undefined => {
  for (const item of list) {
    if (item.id === categoryId) {
      return item;
    } else if (item.category) {
      const find = findCategory(categoryId, item.category);
      if (find) {
        return find;
      } else {
        continue;
      }
    }
  }
  return undefined;
}

const getCategoryBackground = (category: CategoryItem) : string => {
    if (!category || !category.id) {
        return IcoAll;
    }
    if (category.id === "101") {
        return IcoPiano;
    } else if (category.id === "10201") {
        return IcoViolin;
    } else if (category.id === "10202") {
        return IcoViola;
    } else if (category.id === "10203") {
        return IcoCello;
    } else if (category.id === "10204") {
        return IcoDoublebass;
    } else if (category.id === "10301") {
        return IcoFlute;
    } else if (category.id === "10302") {
        return IcoClarinet;
    } else if (category.id === "10303") {
        return IcoOboe;
    } else if (category.id === "106") {
        return IcoGuitar;
    } else if (category.id === "107") {
        return IcoMusic2;
    } else if (category.id === "108") {
        return IcoMusicTheory;
    } else if (category.id === "201") {
        return IcoSinging;
    } else if (category.id === "205") {
        return IcoBelcanto;
    } else if (category.id === "206") {
        return IcoBaritone;
    } else if (category.id === "301") {
        return IcoBallet;
    } else if (category.id === "305") {
        return IcoHiphop;
    } else if (category.id === "50101") {
        return IcoSpanish;
    } else if (category.id === "50102") {
        return IcoChinese;
    } else if (category.id === "502") {
        return IcoFineArts;
    } else if (category.id === "503") {
        return IcoStorytelling;
    } else if (category.id === "504") {
        return IcoStem;
    } else if (category.id === "505") {
        return IcoMath;
    } else if (category.id === "601") {
        return IcoYoga;
    } else if (category.id === "701") {
        return IcoDogTraining;
    } else if (category.id === "801") {
        return IcoMagic;
    } else if (category.id === "901") {
        return IcoArchived;
    }
    return category.background || IcoAll;
}


/** 
 * Group teachers list by category
 * @param list teachers
 * @param allCategorys categories got by generateCategoryList()
 * @returns 
 */
export const transfromCategoryTeacherList = (list: SchoolCategoryTeacher[], allCategorys: CategoryItem[]): CategoryTeacherItem[] => {
    const categoryTeacherList : CategoryTeacherItem[] = [];
    const unknownCategoryTeacherList: TeacherItem[] = [];
    if (list && list.length > 0) {
      list.forEach(item => {
        const categoryList = item.category_list;
        if (categoryList && categoryList.length > 0) {
          categoryList.forEach(category => {
            const findCategory = categoryTeacherList.find(t => t.category.id === category.id);
            if (findCategory) {
              const teachers = findCategory.teachers;
              teachers.push({
                teacher_id: item.user_id,
                teacher_name: item.user_name,
                teacher_avatar: item.user_avatar,
                teacher_type: item.teacher_type,
                personalized_signature: item.personalized_signature,
                order: item.order
              })
            } else {
              const categoryOriginal = allCategorys.find(item => item.id === category.id);
              const newCategoryItem: CategoryTeacherItem = {
                category: {
                  id: category.id,
                  name: categoryOriginal?.name || category.name,
                  icon: categoryOriginal?.icon || category.icon,
                  background: categoryOriginal?.background || IcoAll,
                },
                teachers: [{
                  teacher_id: item.user_id,
                  teacher_name: item.user_name,
                  teacher_avatar: item.user_avatar,
                  teacher_type: item.teacher_type,
                  personalized_signature: item.personalized_signature,
                  order: item.order
                }]
              }
              categoryTeacherList.push(newCategoryItem);
            }
          });
        } else {
          unknownCategoryTeacherList.push({
            teacher_id: item.user_id,
            teacher_name: item.user_name,
            teacher_avatar: item.user_avatar,
            teacher_type: item.teacher_type,
            personalized_signature: item.personalized_signature,
            order: item.order
          })
        }
  
      });
  
      if (unknownCategoryTeacherList.length > 0) {
        categoryTeacherList.push({
          category: {
            id: "",
            name: "No Category",
            icon: "",
            background: IcoAll
          },
          teachers: unknownCategoryTeacherList,
        })
      }
  
    }
    return categoryTeacherList;
  }
  
  