import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import { useSelector } from 'src/app/toolkit/store'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import noTeacher from 'src/images/school/noTeacher.png'
import { useGetSchoolTeacherListMutation } from 'src/app/service/real/school-slice'
import { SeriesTeacherEntity, TeacherEntity } from '@dokyo/common'
import { SeriesTeacherCard } from 'src/components/cards/SeriesTeacherCard'
import { appConfig, buildTeacherUrl } from 'src/app-config'
import { TEACHER_TAB_VALUES } from '../teacher/teacher'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    width: "90%",
    maxWidth:'1400px',
    minHeight: "600px",
    margin:'0 auto',
    textAlign: 'center',
    padding: '0 50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
}))

interface Props {
  schoolID: string;
  seriesTeachers: SeriesTeacherEntity[];
}

const SeriesTeachers: React.FC<Props> = ({schoolID, seriesTeachers}) => {
  const navigate = useNavigate()
  const { credential } = useSelector(state => state.app)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [seriesTeacherList, setSeriesTeacherList] = useState<TeacherEntity[]>([]);

  const [getSchoolTeachers, {isLoading}] = useGetSchoolTeacherListMutation();

  useEffect(()=> {
    if (!schoolID || !seriesTeachers || seriesTeachers.length === 0) {
      return;
    }
    getSchoolTeachers({    
      request_type: 6,
      school_id: schoolID, 
      page_size: 999
    })
    .unwrap()
    .then (teacherRes => {
      if (teacherRes && !teacherRes.isFailed && teacherRes.result) {
        const list = teacherRes.result.teacher_list;
        hanleTeacherList(list)
      }
    })
    //eslint-disable-next-line
  }, [schoolID, seriesTeachers])


  const hanleTeacherList = (teacher_list: TeacherEntity[]) => {
    if (!teacher_list || teacher_list.length === 0) {
      return seriesTeachers;
    }
    const resultList: TeacherEntity[] = [];
    seriesTeachers.forEach(teacher => {
      const find = teacher_list.find(item => item.user_id === teacher.teacher_id);
      if (find) {
        resultList.push(find);
      }
    })
    setSeriesTeacherList(resultList);
  }

  const handleClick= (v: TeacherEntity) => {
    const teacherUrl = buildTeacherUrl(v.user_id);
    navigate(teacherUrl);
  }

  return(
    <Box
      className={classes.container}
      sx={isMobile?{margin: "20px auto",}:{margin: "20px 0"}}>
      <Loader isLoading={isLoading} />
    
      {
        seriesTeacherList?.map((v)=>(
          <Box style={{textAlign:"left", paddingTop: "40px",paddingBottom:'50px', borderBottom: "1px solid #97979733"}} key={v.user_id}>
            <SeriesTeacherCard
              teacher={v}
              handleClick={()=>handleClick(v)}
            />
          </Box>
          )
        )
      }
      {
      (!seriesTeacherList || seriesTeacherList.length === 0) && 
      <Box
        sx={{
          position: 'relative',
          left: '50%',
          top: '100px',
          height: '400px',
          width: '400px',
          marginLeft: '-200px',
          textAlign: 'center',
        }}
      >
        <img src={noTeacher}></img>
        <Typography
          sx={{
            fontSize: '18px',
            fontFamily: 'ArialMT',
            color: 'rgba(0, 0, 0, 1)',
            lineHeight: '21px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop:'10px'
          }}
        >There are no teachers in this course yet.</Typography>
      </Box>
      }
    </Box>
  )
}
export default SeriesTeachers