import React from 'react'
import { Button, CssBaseline } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { StylesProvider } from '@mui/styles'
import { appConfig } from 'src/app-config'
import { PermissionChecker } from '@dokyo/common'
import { Routers, Routes } from '@dokyo/mui-layout'
import { useSelector } from 'src/app/toolkit/store'
import { LoadingBar } from '@dokyo/mui'
import { SvgSprite } from 'src/components/svgSprite'
import { SvgSource } from 'src/theme/index'
import { useDispatch } from 'react-redux'
import { getCategoryJson } from 'src/utils/categoryUtil'
import { setCategories } from 'src/app/service/category-slice'
import ErrorCatch, { ErrorInfo, ReportError } from 'react-error-catch'
import { useReportErrorLogMutation } from 'src/app/service/real/logreport-slice'
import { ErrorReportEntity } from 'src/app/models/error'

interface AppProps {
  language?: string
  routes: Routes
}
const App: React.FC<AppProps> = ({ routes }) => {
  const { t } = useTranslation()
  document.title = t('app.title')
  const notistackRef = React.createRef<SnackbarProvider>()
  const dispatch = useDispatch()
  const { credential } = useSelector(s => s.app)
  const permissionChecker = new PermissionChecker(credential)
  const [isMounted, setIsMounted] = React.useState(true);

  const [reportError] = useReportErrorLogMutation();

  const authenticate = () => {
    return {
      isAuth: permissionChecker.isAuthenticated,
      loginPath: appConfig.paths.login
    }
  }

  React.useEffect(() => {
    getCategoryJson()
    .then(res => {
      if (isMounted) {
        dispatch(setCategories(res));
      }
    });
    return () => {setIsMounted(false)}
  },[])

  const handleCatchError = (report: ReportError) => {
    if (report && report.errors && report.errors.length > 0) { 
      console.log("catch errors: ", report.errors); 
      const errorInfo: ErrorInfo = report.errors[0];
      const errorParam: ErrorReportEntity = {
        error_message: errorInfo.msg||'',
        modules: window.location.pathname,
        request_action: 'normal',
        request_params: errorInfo.stack||'',
      }
      reportError({entity: errorParam});
    }
  }

  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        ref={notistackRef}
        action={key => (
          <Button onClick={() => notistackRef?.current?.closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </Button>
        )}
      >
        <ErrorCatch
          app="veeko"
          user={credential?.id}
          max={1}
          filters={[]}
          onCatch={handleCatchError}
          >
          <Routers authenticate={authenticate} routes={routes} fallback={<LoadingBar/>}/>
        </ErrorCatch>
        <SvgSprite symbols={SvgSource} />
      </SnackbarProvider>
    </StylesProvider>
  )
}

export default App
