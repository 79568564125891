import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { getDisplayUserName } from 'src/utils/stringUtil'
import { TeacherEntity } from '@dokyo/common'
import defaultAvatar from 'src/images/teacher/defaultAvatar.png'
import { useTranslation } from 'react-i18next'
import SvgComponent from 'src/components/svgComponent'
import { isMobileDevice } from 'src/utils/deviceUtil'

const useStyles = makeStyles((theme:Theme) => ({
  group: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row', 
    position: "relative"
  },
  expandedText: {
    height: "auto",
    lineClamp: "inherit",
    WebkitLineClamp: "inherit",
  },
  exTractText: {
    lineHeight: '30px',
    maxHeight: "270px",
    height: "fit-content",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    lineClamp: 9,
    WebkitLineClamp: 9,
  },
  spanvictoriaveeko3: {
    color: 'white',
    background: "#307DCF !important",
    borderRadius: "8px !important",
    fontSize: '14px !important',
    fontFamily: 'ArialMT !important',
    marginTop:'10px !important',
    width:"136px !important",
    height: "48px !important",
    boxShadow: "none !important",
    [theme.breakpoints.down('sm')]: {
      width:"100px !important",
      height: "36px !important",
      fontSize: '10px !important',
      padding: '0px !important',
    }
  },
}))

interface Props extends CardProps {
  teacher: TeacherEntity
  handleClick: () => void
}

export const SeriesTeacherCard: React.FC<Props> = ({teacher, handleClick, ...props}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [expand, setExpand] = React.useState(false);
  const [hideExpand, setHideExpand] = React.useState(true);

  // React.useEffect(() => {
  //   const hide = hideExpandButton(); 
  //   setHideExpand(hide);
  // },[])

  const handleClickExpand = () => {
    setExpand(!expand);
  }

  const hideExpandButton = () : boolean => {
    const contentDiv = document.getElementById(`content-${teacher.user_id}`);
    if (contentDiv) {
      const divHeight = contentDiv.offsetHeight;
      const lineHeight = parseInt(contentDiv.style.lineHeight);
      const lines = divHeight / lineHeight;
      return lines < 10;
    } else {
      return true;
    }
    
  }

  return (
    <Box className={classes.group}>
      <Card
        {...props}
        style={{
          width: '100%',
          borderRadius: '0px',
          overflow: 'hidden',
          display: 'flex',
          boxShadow: 'none',
        }}
      >
        <CardMedia
          component="img"
          image={teacher.user_avatar || defaultAvatar}
          sx={isMobileDevice()? {width: '100px',height: '146px',borderRadius: '5px',marginRight: "15px",}
          :{width: '255px',height: '367px',borderRadius: '10px',marginRight: "30px",}}
        />
        <CardContent sx={{width: "100%", padding: 0,}}>
          <Typography
            style={{
              letterSpacing: '-0.578823447227478px',
              fontFamily: 'Arial-BoldMT',
              lineHeight: '24px',
            }}
            sx={isMobileDevice()? {fontSize: '18px',}:{fontSize: '24px',}}
          >
            {getDisplayUserName(teacher.user_name, teacher.user_id)}
          </Typography>
          <Box
            id={`content-${teacher.user_id}`}
            style={{
              letterSpacing: '-0.34px',
              fontFamily: 'ArialMT',
              marginTop:'18px',
              color: "#555555",
            }}
            sx={isMobileDevice()? {fontSize: '12px',lineHeight: '20px',}:{fontSize: '14px',lineHeight: '30px',}}
            className={expand? classes.expandedText : classes.exTractText}
          >
            {teacher.about || teacher.personalized_signature}
          </Box>
         
          <Button
            variant='contained'
            className={classes.spanvictoriaveeko3}
            sx={{textTransform:"none"}}
            onClick={(e)=>handleClick()}
          >
            Teacher Profile
          </Button>
        </CardContent>
      </Card>
      <Box
        hidden={hideExpand}
        style={{
          position: "absolute",
          right: 0,
          bottom: "-10px",
        }}>
        <Button
          variant='text'
          style={{
            textTransform: "none",
            color: "black",
            paddingLeft: "20px",
            background: "white"
          }}
          endIcon={<SvgComponent iconName={expand ? "iconTextExtract" : "iconTextExpand"} className="icon-source" style={{paddingTop: "5px"}} />}
          onClick={handleClickExpand}
        >
          {expand ? t("class.close-view-all") : t("class.view-all")}
        </Button>
      </Box>
    </Box>
  )
}
