import React, { Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { buildDiscoverUrl } from 'src/app-config';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '16px',
    color: '#000000',
    flex: '0 0 320px',
    width: '320px'
  },
  menuGroupTitle: {
    fontWeight: 'bold',
    marginBottom: '12px',
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(249, 59, 87)',
    }
  },
  menuGroupTitleArrow: {
    verticalAlign: '-7px'
  },
  menuGroupContainer: {
    paddingBottom: '22px'
  },
  sectionTitle: {
    position: 'relative',
    fontWeight: 'bold',
    left: '9px',
    '&::before': {
      position: 'absolute',
      top: '-2px',
      left: '-9px',
      width: '4px',
      height: '24px',
      background: '#F93B57',
      content: '""',
      borderRadius: '2px'
    }
  },

  hideMenu: {
    opacity: '0 ',
    transitionDuration: '300ms',
  },
  menuItem: {
    display: 'block',
    marginBottom: '12px',
    cursor: 'pointer',
    paddingRight: '60px',
    color: '#000000',
    '&:hover': {
      color: 'rgba(249, 59, 87)',
    },
    '&:visited': {
      color: '#000000',
    }
  },
}));

export const MENU_TYPE_ORDER = 'order';

export interface MenuItem {
  name: string,
  type: string,
  id: string
};

export interface PropsCourseMenu {
  menuAction: MenuItem,
  setMenuAction: Dispatch<SetStateAction<MenuItem | undefined>>,
  menuList?: MenuGroupItem[],
  menuOrderItems?: string[],
  buildUrl:(type?: string, id?: string)=>string
};

export interface MenuGroupItem {
  title: string;
  type: string;
  items: {
    id: string;
    name: string;
  }[]
};

export const CourseMenu1: React.FC<PropsCourseMenu> = ({ menuAction, menuList, menuOrderItems ,buildUrl}) => {

  const classes = useStyles();
  const initMenuShow = {}
  menuList?.forEach(menu => {
    initMenuShow[menu.type] = false
  })
  const [menuShow, setMenuShow] = React.useState(initMenuShow)


  React.useEffect(() => {
    if (menuAction && menuList) {
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].type === menuAction.type) {
          const newMenuShow = { ...menuShow }
          newMenuShow[menuAction.type] = !newMenuShow[menuAction.type]
          setMenuShow(newMenuShow)
          console.log(setMenuShow(newMenuShow));

        }
      }
    }
  }, [])

  React.useEffect(() => {
    console.log('menuAction:', menuAction);
    if (menuAction && menuList) {
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].type === menuAction.type) {
          const newMenuShow = { ...menuShow }
          newMenuShow[menuAction.type] = true;
          setMenuShow(newMenuShow)
        }
      }
    }
  }, [menuAction])


  // const onClickOrder = (e) => {
  //   const name = e.target?.dataset?.name
  //   const type = e.target?.dataset?.action
  //   const id = e.target?.dataset?.id
  //   if (type && name && (type !== menuAction.type || name !== menuAction.name)) {
  //     setMenuAction({
  //       name,
  //       type,
  //       id
  //     })
  //   } 
  // }

  const handleClickMenuTitle = (type: string) => {
    const newMenuShow = { ...menuShow }
    newMenuShow[type] = !newMenuShow[type]
    setMenuShow(newMenuShow)
  }

  return (
    <div className={classes.root}>
      <div className={classes.menuGroupContainer}>
        {
          menuOrderItems && menuOrderItems.map((orderItem, index) => (
              <Link key={orderItem}  className={clsx(classes.menuItem, {
                [classes.sectionTitle]: menuAction.name === orderItem && menuAction.type === MENU_TYPE_ORDER
              })} to={buildUrl(MENU_TYPE_ORDER, index.toString())} >{orderItem}</Link>
          ))
        }
      </div>
      {
        menuList && menuList.map(menuItem => {
          const Items = menuItem.items.map(item => (
            <Link
              key={item.id}
              className={
                clsx(
                  classes.menuItem,
                  {
                    [classes.sectionTitle]: menuAction.id === item.id && menuAction.type === menuItem.type
                  })}
              to={buildUrl(menuItem.type, item.id)}
            >{item.name}
            </Link>
          ))
          return (
            <div key={menuItem.type} className={classes.menuGroupContainer}>
              <div className={clsx(classes.menuGroupTitle)} onClick={() => handleClickMenuTitle(menuItem.type)}>
                {menuItem.title}
              </div>
              {console.log(menuItem.type)
              }
              {Items}
            </div>
          )
        })
      }
    </div>
  )
}