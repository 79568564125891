import React, { useState }  from 'react';
import { Box, Typography, Button, Avatar, Theme, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAuthStatus } from 'src/utils/auth'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import { useSelector } from 'src/app/toolkit/store';
import { useGetSeriesByTeacherQuery } from 'src/app/service/real/teacher.slice';
import { SeriesCard } from 'src/components/cards';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import SvgComponent from 'src/components/svgComponent'
import { SeriesLessonSemesterEntity } from 'src/app/models/schedule';
import { AVATAR_DEFAULT } from 'src/utils/deviceUtil';
import { useGetBookingMutation, useSelectUserBookingMutation, useStudentCancelBookingMutation } from 'src/app/service/real/booking-slice';
import { ListPagination } from 'src/components/pagination';
import { useSnackbar } from 'notistack';
import { getOrderStatusText, OrderEntity, OrderStatusEnum } from 'src/app/models/order';
import { getTimeInWordDescription, getTimeInWordDescription2 } from 'src/utils/timeUtil';
import { appConfig } from 'src/app-config';
import { useGetPurchasedSeriesLessonMutation } from 'src/app/service/real/serieslesson-slice';
import { extractPlainTextFromRichText, getDisplayUserName } from 'src/utils/stringUtil';

const useStyles = makeStyles((theme:Theme) => ({
  root: {
    minHeight: 'calc(100vh - 369px)',
    padding: '30px 30px',
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flexFlow:'wrap',    
    [theme.breakpoints.down('sm')]:{
      padding: '20px',
    }
  },
  item: {
    margin: '0 24px 36px 0'
  },
  SignInInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: "60vh",
    paddingTop: "20vh",
  },

  courseInfoBox:{
    display:'flex',
    flexDirection:'row',
    margin: "10px 0",
    paddingBottom: '30px',
    borderBottom: '1px solid #f2f2f2',
    [theme.breakpoints.down('sm')]:{
      flexDirection:'column'
    }
  },
  courseImage:{
    width: '319px',
    height: '214px',
    objectFit: 'cover',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      height: 'auto',
    }
  },
  courseIntroBox:{
    width:'100%',
    margin: '0 20px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]:{
      maxWidth:'100%', 
      margin: '10px 0',
    }
  },
  courseTitle: {
    fontSize: '24px !important',
    lineHeight: '33px !important',
    fontFamily: 'Arial-BoldMT, Arial !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 1,
    WebkitLineClamp: 1,
    '&:hover': {
      textDecoration: 'underline !important',
    }
  },
  courseIntroduction: {
    marginTop: '10px !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Medium, Avenir !important',
    color: '#555555 !important',
    lineHeight: '20px !important',
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    lineClamp: 5,
    WebkitLineClamp: 5,
  },
  statusText: {
    marginLeft: '30px !important',
    fontSize: '14px !important',
    fontFamily: 'Avenir-Medium, Avenir !important',
    color: '#555555 !important',
  },
  courseItemTitle: {
    marginTop: '10px !important',
    fontSize: '14px !important',
    fontFamily: 'Arial !important',
    color: '#555555 !important',
    lineHeight: '28px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 1,
    WebkitLineClamp: 1,
    display: 'flex',
    alignItems: 'center'
  },
  teacherBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    cursor: 'pointer',
    width: 'fit-content',
  },
  teacherAvatar: {
      width: '32px !important',
      height: '32px !important',
  },
  teacherName: {
      marginLeft: '10px',
      color: 'black',
      fontSize: '14px',
      fontFamily: 'Avenir-Medium, Avenir',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
  },
  feeDesc: {
    marginTop: '20px',
    color: '#555555',
    fontSize: '14px',
    fontFamily: 'Avenir-Black, Avenir',
    display: 'flex',
  },
  feeDescMid: {
    color: '#F93B57',
    fontSize: '16px',
    fontFamily: 'Avenir-Black, Avenir',
  },
  viewDetailsBtn: {
    height: "36px !important",
    background: "#307DCF !important",
    color: 'white !important',
    fontFamily: 'ArialMT !important',
    fontWeight: 'bold !important',
    boxShadow: 'none !important',
    borderRadius: '18px !important',
    '&.Mui-disabled': {
      background: '#307DCF55 !important'
    }
  },
  cancelBookBtn: {
    height: "36px !important",
    marginLeft: '20px !important',
    color: '#307DCF !important',
    fontFamily: 'ArialMT !important',
    fontWeight: 'bold !important',
    boxShadow: 'none !important',
    borderRadius: '18px !important',
    '&.Mui-disabled': {
      borderColor: '#307DCF44 !important',
      color: '#307DCF44 !important',
    }
  },
}))


export const MySeriesLesson: React.FC = () => {

  const classes = useStyles();
  const navigate = useNavigate()
  const authStatus = getAuthStatus()()
  const {enqueueSnackbar} = useSnackbar()
  const {credential} = useSelector(state => state.app);
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [dataList, setDataList] = useState<SeriesLessonSemesterEntity[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startKeyArray, setStartKeyArray] = useState(["", ""]);
  const [maxPage, setMaxPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const PAGE_SIZE = 12;

  const [getDataList, {isLoading: loading1}] = useGetPurchasedSeriesLessonMutation();

  React.useEffect(()=> {
    if (credential) {
      setStartKeyArray(["", ""])
      setMaxPage(1);
      handleSearch(1);
    }
  }, [credential])

  const handleSearch = async (pageIndex: number) => {
    setCurrentPage(pageIndex);
    getDataList({
      pageSize: PAGE_SIZE, 
      startKey: startKeyArray[pageIndex],
      forwardOrder: false,
    })
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const list = dataRes.result.series_lesson_classes_list;
        if (list) {
          setDataList(list);
        }
        const nextStartKey = dataRes.result.start_key;
        startKeyArray[pageIndex+1] = nextStartKey;
        setStartKeyArray(startKeyArray);
        setHasMore(nextStartKey && nextStartKey !== "");
        if (nextStartKey && pageIndex + 1 > maxPage) {
          setMaxPage(pageIndex + 1)
        }
      } else {
        setDataList([]);
        setHasMore(false);
        enqueueSnackbar(dataRes?.message || "Can not get data")
      }
    })
  }


  const handleOnClickPrev = () => {
    handleSearch(currentPage - 1)
  }

  const handleOnClickNext = () => {
    handleSearch(currentPage + 1)
  }

  const handleOnClickPage = (page) => {
    handleSearch(page)
  }

  const handleClickButton = () => {
    dispatch(showSignInUpDialog())
  }

  const handleClickItem = (item: SeriesLessonSemesterEntity) => {
    navigate(appConfig.paths.seriesLessonClass + "?id=" + item.id);
  }

  const getNextClassStartTime = (item: SeriesLessonSemesterEntity) => {
    const lesson = item?.lesson_list?.find(item => item.end_time > new Date().getTime());
    return lesson ? getTimeInWordDescription2(lesson.start_time) : '';
    
  }

  if (loading1) {
    return (
      <Loader isLoading={loading1} />
    )
  } else if (dataList?.length > 0) {
    return (
      <Box className={classes.root}>
        {dataList?.map((item,index) => 
          <Box key={item.id||index} className={classes.courseInfoBox}  >
            <img alt='series_lesson_thumbnail' className={classes.courseImage} src={item.series_lesson?.thumbnail}/>
            <Box className={classes.courseIntroBox}>
              <Typography className={classes.courseTitle}>{item.series_lesson?.title}</Typography>

              <Typography className={classes.courseIntroduction}>{ extractPlainTextFromRichText(item.series_lesson?.introduction) }</Typography>

              <Box className={classes.teacherBox} onClick={() => navigate(`${appConfig.paths.teacher}/${item.teacher_info.id}`)}>
                <Avatar src={item.teacher_info.avatar || AVATAR_DEFAULT} className={classes.teacherAvatar}/>
                <Box className={classes.teacherName}>{getDisplayUserName(item.teacher_info.name, item.teacher_info.id)}</Box>
              </Box>
              
              <Box className={classes.courseItemTitle} >
                <SvgComponent iconName='ico-series-data' style={{width:'21px', height: '22px', marginRight: '11px'}} />
                {item.lesson_list?.length > 1 ? `${item.lesson_list?.length} lessons` : `${item.lesson_list?.length ?? 0} lesson`}
              </Box>
              
              <Typography component='span' className={classes.courseItemTitle} >{`Next Lesson: ${getNextClassStartTime(item)}`}</Typography> 
              
              {/* <Box className={classes.feeDesc}>
                fee:&nbsp;&nbsp;
                <Box className={classes.feeDescMid}>{`$${NP.divide(item.series_lesson?.total_lesson_fee, 100)}`}</Box>
              </Box> */}
              <Box sx={{display:'flex', marginTop: '10px'}}>
                <Button
                  onClick={() => handleClickItem(item)}
                  className={classes.viewDetailsBtn}
                  sx={{ textTransform: 'none' }}
                  variant="contained">
                  View Details
                </Button>
              
              </Box>
            </Box>
          </Box>
        )}

        {(currentPage !== 1 || dataList?.length !== 0) && 
        <ListPagination
          currentPage={currentPage} 
          hasMore={hasMore} 
          // totalCount={totalCount}
          rowsPerPage={PAGE_SIZE}
          maxPage={maxPage}
          onClickPage={handleOnClickPage}
          onClickPrev={handleOnClickPrev} 
          onClickNext={handleOnClickNext}
        />   
        }

        <Loader isLoading={loading1} />
      </Box>
    )
  } else {
    return (
      <Box className={classes.SignInInner}>
        <SvgComponent  iconName={"seriesIcon"} style={{ width: "48px", height: "36px"}}/>
        <Typography style={{fontSize: '18px', fontFamily: 'ArialMT', marginTop: "20px"}}>
          {authStatus.isLogin? 'No data yet.' : 'Please sign in'}
        </Typography>
        {!authStatus.isLogin? 
        <Button 
          onClick={handleClickButton}
          sx={{
            width:'136px',
            height: '48px',
            textTransform: "none", 
            backgroundColor: '#307DCF',  
            borderRadius: '8px',
            fontFamily: 'ArialMT',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            fontSize: '18px',
            marginTop: '20px'
          }}
          variant="contained">
          {t('studio.signIn')}
        </Button>:
        ''
        }
        
      </Box>
    )
  }
}


