
import { Box, Button, Typography, Avatar, Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import SvgComponent from 'src/components/svgComponent'
import { styled, Theme } from '@mui/system'
import { useGetCheckoutSessionMutation } from 'src/app/service/real/payment.slice'
import { CheckoutOneTimeItem, PaymentMode, PaymentType, PaymentProductInfo, PaidInfo, Product_Type, SeriesEntity, PaymentCurrency } from '@dokyo/common'
import { appConfig } from 'src/app-config'
import { genRandom } from 'src/utils/stringUtil'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { storeSession } from 'src/utils/localStorage'
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'src/app/toolkit/store'
import { getAuthStatus } from 'src/utils/auth'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import Loader from '../loader'
import IconMember from 'src/images/class/ico-member.png'
import { CartButtonComponent } from './CartButtonComponent'
import { MembershipConfigInfo } from 'src/app/models'
import NP from "number-precision";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isMobileDevice, isSingleSchoolSite } from 'src/utils/deviceUtil'
import BookPaymentTypeDialog from 'src/pages/seriesLesson/dialog/BookPaymentTypeDialog'
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCourseBookingUseCouponMutation } from 'src/app/service/real/booking-slice';

const useStyles = makeStyles((theme: Theme) => ({
  btnItem: {
    width: "100%",
    height: "48px",
  },
  newBtnItem: {
    width: "136px",
    height: "44px",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "column",
  },
  newButtonBox: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "space-between"
    }
  },
  feesBox: {
    alignItems: "center",
    display: "flex",
    paddingBottom: "10px",
    [theme.breakpoints.down('md')]: {
      justifyContent: "flex-start",
    }
  },
  feesTitle: {
    fontSize: "15px !important",
    color: "#555555 !important",
    fontFamily: "ArialMT !important"
  },
  feesValue: {
    fontSize: "1.9rem !important",
    color: "#E91535",
    fontWeight: "bold",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      justifyContent: "flex-start",
      fontSize: "1.25rem !important",
    }
  },
  memberFeesValue: {
    fontSize: "1.5rem !important",
    color: "#000000",
    fontWeight: "bold",
    fontFamily: "Arial-BoldMT, Arial !important",
    marginLeft: "20px !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.125rem !important",
    }
  },
  icoMember: {
    width: "53px !important",
    height: "16px !important",
    marginLeft: "5px",
    marginBottom: "5px"
  }
}))

// const DisableColoredButton = styled(Button)({
//   borderRadius: "8px",
//   border: "none",
//   boxShadow: "none",
//   '&.Mui-disabled': {
//       color: 'white',
//       backgroundColor: "#F93B5755",
//       borderRadius: "8px",
//       border: "none",
//   },
//   ':hover': {
//     boxShadow: "none",
//   }
// })

const AddWishButton = styled(Button)({
  borderRadius: "8px",
  boxShadow: "none",
  '&.Mui-disabled': {
    color: '#F93B5755',
    borderRadius: "8px",
    border: "1px solid #F93B5733"
  },
  ':hover': {
    boxShadow: "none",
  }
})

interface Props {
  price: number;
  memberPrice: number;
  seriesInfo: SeriesEntity | null;
  onClickAddToCart?: () => void;
  onClickAddToWishList?: () => void;
  purchasedSeparately?: boolean
  UIStyle?: number;
}

const AddToCartComponent: React.FC<Props> = ({ price, memberPrice, onClickAddToCart, onClickAddToWishList, purchasedSeparately, seriesInfo, UIStyle = 0 }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { cartSuccess } = appConfig.paths;
  const { credential } = useSelector(state => state.app)
  const [getcheckoutsession] = useGetCheckoutSessionMutation();
  const authStatus = getAuthStatus()();
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [showBookDialog, setShowBookDialog] = React.useState(false);
  const [couponList, setCouponList] = React.useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [courseBookingUseCoupon] = useCourseBookingUseCouponMutation();
  const [configMembership, setConfigMembership] = React.useState<MembershipConfigInfo>({ type: "", stripe_publishable_key: "", list: [] });
  const [open, setOpen] = React.useState({ IsOpen: false, Content: "", type: "" });
  const getMembershipConfig = async ({ url }): Promise<MembershipConfigInfo> => {
    return new Promise((resolve) => {
      fetch(url, {
        cache: "no-cache"
      })
        .then(response => resolve(response.json()))
        .catch(error => {
          console.error(error);
          resolve(error);
        });
    });
  }
  const getMembershipConfigCallback = React.useCallback(async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_MEMBERSHIP_URL}`;
    getMembershipConfig({ url: url })
      .then((res) => {
        if (isMounted) {
          setConfigMembership(res)
        }
      })
      .catch((error) => {
        if (isMounted) {
          setLoading(false);
          setOpen({ ...open, IsOpen: true, Content: "Get membership info failed!" })
        }
      });
    setLoading(false);
  }, [open])

  React.useEffect(() => {
    getMembershipConfigCallback()
    return () => {
      setIsMounted(false);
    };
  }, [getMembershipConfigCallback])

  const onPurchase = async () => {
    if (!authStatus.isLogin) {
      dispatch(showSignInUpDialog());
    } else {
      setLoading(true);
      onClickAddToCart && onClickAddToCart();
      const CHECKOUT_SESSION_ID: string = await genRandom();
      const paiedInfo: PaidInfo = {
        payment_type: PaymentType.Stripe,
        customer_id: credential?.id ?? "",
        cources: seriesInfo?.series_id,
        amounts: [NP.times(seriesInfo?.series_fees ?? 0, 1)]
      };
      const productInfo: PaymentProductInfo = {
        product_id: '1',
        product_type: Product_Type.Course,
        paid_info: paiedInfo
      };
      const checkoutOneTimeItem: CheckoutOneTimeItem[] = [
        {
          product_name: seriesInfo?.series_title,
          description: seriesInfo?.series_introduction ?? seriesInfo?.series_title,
          images: [seriesInfo?.series_thumbnail],
          amount: NP.times(seriesInfo?.series_fees ?? 0, 100),
          quantity: 1,
          currency: PaymentCurrency.USD
        } as CheckoutOneTimeItem
      ];

      const params = {
        paymenttype: PaymentType.Stripe,
        price_id: "",
        quantity: 1,
        mode: PaymentMode.Payment,
        coupon: "",
        allow_input_code: true,
        success_url: `${window.location.protocol}//${window.location.host}${cartSuccess}?session_id=${CHECKOUT_SESSION_ID}&productid=${1}&payment_value=${seriesInfo?.series_fees ?? 0}&returnurl=${window.location.href}`,
        cancel_url: window.location.href.replace("&purchase=1", ""),
        product_info: productInfo,
        description: seriesInfo?.series_title,
        one_time_items: checkoutOneTimeItem
      };
      const response = await getcheckoutsession(params).unwrap();
      if (response && !response.isFailed && response?.session_id) {
        storeSession(CHECKOUT_SESSION_ID, response?.session_id);
        const stripe = await loadStripe(configMembership?.stripe_publishable_key);
        if (stripe) {
          // Call Stripe.js method to redirect to the new Checkout page
          stripe.redirectToCheckout({
            sessionId: response?.session_id
          });
        }
      }
      setLoading(false);
    }
  };
  const confirmPurchase = () => {
    if (!authStatus.isLogin) {
      dispatch(showSignInUpDialog())
    } else {
      setShowBookDialog(true)
    }
  }
  const handleClickPayment = (isCoupon: boolean, coupon?: string) => {
    if (isCoupon) {
      //coupon支付
      if (coupon) {
        setLoading(true);
        courseBookingUseCoupon({
          Coupon: coupon,
          seriesId: seriesInfo?.series_id || ""
        }).unwrap().then(res => {
          if (res && !res.isFailed && res.resultCode == 201000) {
            enqueueSnackbar("payment successful!")
            setLoading(false);
            setShowBookDialog(false)
            window.location.reload();
          } else {
            enqueueSnackbar(res.message)
            setLoading(false);
          }
        })
      } else {
        enqueueSnackbar("Please enter coupon code")
      }
    } else {
      // stripe支付
      setShowBookDialog(false)
      onPurchase()
    }
  }

  const notNeedPurchase = () => {
    return !price || price === 0
      || credential?.roles?.includes("admin")
      || credential?.roles?.includes("teacher");
  }

  const PurchaseButton = styled(Button)({
    borderRadius: UIStyle === 1 ? "24px" : "8px",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#F93B57",
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: "#F93B5755",
      borderRadius: UIStyle === 1 ? "24px" : "8px",
      border: "none",
    },
    ':hover': {
      boxShadow: "none",
      backgroundColor: "red",
    }
  })

  if (UIStyle === 2) {
    return (
      <>
        <BookPaymentTypeDialog
          couponList={couponList}
          open={showBookDialog}
          onClickPayment={handleClickPayment}
          price={price}
          onClickClose={() => setShowBookDialog(false)}
        />
        <Button
          variant='contained'
          sx={{
            marginTop: '10px',
            background: '#F93B57',
            width: '149px',
            height: '48px',
            borderRadius: '24px',
            fontSize: '16px',
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              boxShadow: 'none',
              background: 'red',
            },
          }}
          startIcon={<SvgComponent iconName={"ico-purchase-card"} style={{ width: '36px', height: '36px' }} />}
          onClick={confirmPurchase}
        >
          Buy Now
        </Button>
      </>
    )
  } else return (
    <>
      <BookPaymentTypeDialog
        couponList={couponList}
        open={showBookDialog}
        onClickPayment={handleClickPayment}
        price={price}
        onClickClose={() => setShowBookDialog(false)}
      />
      <Box className={classes.feesBox} sx={UIStyle === 1 ? { justifyContent: "flex-start", marginTop: "10px" } : { justifyContent: "center", }}>
        <Typography className={classes.feesValue} sx={(notNeedPurchase() && price && price !== 0) || purchasedSeparately ? { textDecoration: "line-through" } : {}}>
          {!price || price === 0 ? "Free" : `$${price}`}
        </Typography>

        {/* {price > 0 ? 
          <Box style={{display: "flex", alignItems: "center"}}>
            <Typography className={classes.memberFeesValue}> 
              {!memberPrice || memberPrice === 0 ? "Free" : `$${memberPrice}`}
            </Typography>
            <Avatar
              className={classes.icoMember}
              variant='square'
              src={IconMember}/>
          </Box>
        : ""} */}
      </Box>
      <Box className={UIStyle === 1 ? classes.newButtonBox : classes.buttonBox}>
        {
          notNeedPurchase() ?
            '' :
            <>
              <PurchaseButton
                id='button-purchase'
                disabled={purchasedSeparately}
                variant='contained'
                className={UIStyle === 1 ? classes.newBtnItem : classes.btnItem}
                style={{ textTransform: "none", marginBottom: "18px" }}
                startIcon={<SvgComponent iconName={purchasedSeparately ? "ico-purchased" : "purchaseSmallWhite"} style={{ width: '20px', height: '20px' }} />}
                onClick={confirmPurchase}
              >
                {purchasedSeparately ? t("cart.purchased") : t("cart.purchase")}
              </PurchaseButton>

              {
                // delete !matches && 
                !purchasedSeparately && !isSingleSchoolSite() &&
                <CartButtonComponent purchasedSeparately={purchasedSeparately} seriesId={seriesInfo?.series_id || ""} newStyle={(UIStyle === 1) || false} />
              }
            </>
        }
      </Box>
      {/* { !isMobileDevice() &&
      <AddWishButton
        disabled
        variant='outlined'
        color="secondary"
        className={classes.btnItem}
        style={{textTransform: "none", marginBottom: "18px"}}
        // startIcon={<SvgComponent iconName="saveSmallRed" className="icon-source" />}
        startIcon={<SvgComponent iconName="saveSmallDisable" className="icon-source" />}
        onClick={onClickAddToWishList}
      >
        {t("class.add-to-wishlist")}
      </AddWishButton>
      } */}
      <Loader isLoading={loading} />
    </>
  )
}

export default AddToCartComponent
