const LS_AT = 'victoria_at';
const PAYMENT_SESSION = "STRIPE_PAYMENT_SESSION";
const PAYMENT_PRODUCT = "STRIPE_PAYMENT_PRODUCT";
const PAYMENY_PACKAGE = "STRIPE_PAYMENT_PACKAGE";
const PAYMENY_AMOUNT = "STRIPE_PAYMENT_AMOUNT";
const PAYMENY_PROMOTION = "STRIPE_PAYMENT_PROMOTION";

export const getStoredAccessToken = (): string =>
  localStorage.getItem(LS_AT) || '';
export const storeAccessToken = (at: string): void =>
  localStorage.setItem(LS_AT, at);
export const removeStoredAccessToken = (): void =>
  localStorage.removeItem(LS_AT);

export const storeSession = (key: string, value: string): void =>
  localStorage.setItem(key, value);
export const getSession = (key: string): string | null =>
  localStorage.getItem(key) || '';
export const removeSession = (key: string): void => 
  localStorage.removeItem(key);

export const getStoredPaymentSession = (): string =>
  localStorage.getItem(PAYMENT_SESSION) || '';
export const storePaymentSession = (at: string): void =>
  localStorage.setItem(PAYMENT_SESSION, at);
export const removeStoredPaymentSession = (): void =>
  localStorage.removeItem(PAYMENT_SESSION);

export const getStoredPaymentProduct = (): string =>
  localStorage.getItem(PAYMENT_PRODUCT) || '';
export const storePaymentProduct = (at: string): void =>
  localStorage.setItem(PAYMENT_PRODUCT, at);
export const removeStoredPaymentProduct = (): void =>
  localStorage.removeItem(PAYMENT_PRODUCT);

export const getStoredPaymentPackage = (): string =>
  localStorage.getItem(PAYMENY_PACKAGE) || '';
export const storePaymentPackage = (at: string): void =>
  localStorage.setItem(PAYMENY_PACKAGE, at);
export const removeStoredPaymentPackage = (): void =>
  localStorage.removeItem(PAYMENY_PACKAGE);

export const getStoredPaymentAmount = (): string =>
  localStorage.getItem(PAYMENY_AMOUNT) || '';
export const storePaymentAmount = (at: string): void =>
  localStorage.setItem(PAYMENY_AMOUNT, at);
export const removeStoredPaymentAmount = (): void =>
  localStorage.removeItem(PAYMENY_AMOUNT);

export const getStoredPromotion = (): string =>
  localStorage.getItem(PAYMENY_PROMOTION) || '';
export const storePromotion = (at: string): void =>
  localStorage.setItem(PAYMENY_PROMOTION, at);
export const removeStoredPromotion = (): void =>
  localStorage.removeItem(PAYMENY_PROMOTION);
