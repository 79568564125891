import {
  Box, Button, Divider, MenuItem, Select, Theme, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions, Paper
} from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TextField as MaterialTextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useSelector } from 'src/app/toolkit/store'
import { CreateAvailableTimeEntity, PrivateLessonClassSourceEntity, PrivateLessonTypeEnum, RepeatTypeEnum, TimeFee, TimeInterval, UserScheduleEntity, UserScheduleSourceTypeEnum } from 'src/app/models/schedule'
import { useSnackbar } from 'notistack'
import { useCreateCustomPrivateLessonClassMutation, useCreateTeacherAvailableTimeMutation } from 'src/app/service/real/privatelesson-slice'
import Loader from '../loader'
import { ErrorCode } from 'src/utils/constants'
import { useTranslation } from 'react-i18next'
import { getCurrentFormatDate, getFormatDate, getFormatDisplayTime } from 'src/utils/timeUtil'
import { CategoryItem } from 'src/app/models/category'
import { isProductionEnv } from 'src/utils/deviceUtil'
import { findCategory } from 'src/utils/categoryUtil'
import { getAuthRole } from 'src/utils/auth'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import NP from "number-precision";

const useStyles = makeStyles((theme: Theme) => ({

  subTitle: {
    color: '#555555',
    fontSize: '14px !important',
    fontFamily: 'ArialMT',
    marginBottom: '15px !important',
  },
  choiceItemBox: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  choiceItemLine: {
    marginRight: '20px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    marginTop: "20px",
  },
  choiceItemtitle: {
    width: '100px',
    textAlign: 'left',
    color: '#000000',
    fontSize: '14px !important',
    fontFamily: 'ArialMT',
    [theme.breakpoints.down('sm')]: {
      width: '170px',
    }
  },
  RepeatValueField: {
    width: '400px',
    height: '40px',
  },
  textField: {
    width: '400px',
    height: '40px',
  },
  hourSelect: {
    width: '70px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      // width: '190px',
    }
  },
  timeSelect: {
    width: '130px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    }
  },
  repeatDesc: {
    fontSize: '12px',
    fontFamily: 'ArialMT',
    color: '#555555',
    lineHeight: '16px',
    marginLeft: '100px',
    marginTop: '8px',
    width: '400px',
  },
  createButton: {
    fontSize: '14px !important',
    fontFamily: 'ArialMT',
    marginRight: '20px !important',
    marginBottom: '20px !important',
    background: '#307DCF !important',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
  },
  cancelButton: {
    borderColor: '#307DCF !important',
    color: '#307DCF !important',
    fontSize: '14px !important',
    fontFamily: 'ArialMT',
    marginRight: '20px !important',
    marginBottom: '20px !important',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
  },

}))

interface Props {
  custom: boolean,
  teacherId: string,
  open: boolean,
  startDate?: Date;
  timeFees: TimeFee[];
  privateLessonCategoryIDs: string[];
  onClickCancel: () => void;
  onCreateSuccess: () => void;
}

const TIME_MINUTES_LENGTH_CHOICES = [
  30,
  45,
  60,
  90
]


const LIMIT_DAYS_CHOICES = [
  7,
  15,
  30,
  60,
  90
]

const LIMIT_WEEKS_CHOICES = [
  7,
  14,
  21,
  28,
  56,
  91,
]

export const CreateAvailableTimeDialog: React.FC<Props> = (props) => {
  const { custom, teacherId, open, startDate, timeFees, privateLessonCategoryIDs, onClickCancel, onCreateSuccess } = props;
  const classes = useStyles()
  const { t } = useTranslation()
  const authRole = getAuthRole()();
  const navigate = useNavigate();
  const { credential } = useSelector(state => state.app);
  const { categories } = useSelector(state => state.categoryState);
  const { enqueueSnackbar } = useSnackbar();
  const [disableStartHours, setDisabledStartHours] = React.useState<number[]>([]);
  const [createDateStr, setCreateDateStr] = React.useState(getCurrentFormatDate());
  const [startHour, setStartHour] = React.useState<number>(0);
  const [startMinute, setStartMinute] = React.useState<number>(0);
  const [timeLength, setTimeLength] = React.useState<number>(TIME_MINUTES_LENGTH_CHOICES[2]);
  const [repeatType, setRepeatType] = React.useState<RepeatTypeEnum>(RepeatTypeEnum.NO_REPEAT);
  const [repeatDays, setRepeatDays] = React.useState<number>(0);
  const [title, setTitle] = React.useState<string>('');
  const [lessonType, setLessonType] = React.useState(PrivateLessonTypeEnum.Single);
  const [choiceCategories, setChoiceCategories] = React.useState<CategoryItem[]>([]);
  const [selectCategoryIDs, setSelectCategoryIDs] = React.useState<string[]>([]);
  const [minNum, setMinNum] = React.useState(1);
  const [maxNum, setMaxNum] = React.useState(5);
  const [groupFee, setGroupFee] = React.useState(0);
  const [customClassFee, setCustomClassFee] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [conflictData, setConflictData] = React.useState<{show:boolean, lessons:UserScheduleEntity[]}>({show: false, lessons:[]});

  const [createCustomPrivateLessonClass, {isLoading: loading1}] = useCreateCustomPrivateLessonClassMutation();
  const [createAvailableTime, { isLoading: isCreateLoading }] = useCreateTeacherAvailableTimeMutation();

  // React.useEffect(() => {
  //   setDisabledStartHours([]);
  //   if (repeatType && weekdaySchedule[repeatType]?.length>0) {
  //     const timeFees : AvailableTimeArea[] = weekdaySchedule[repeatType];
  //     timeFees.forEach(item => {
  //       let start = item.start_time;
  //       const addHours: number[] = [];
  //       while(start < item.end_time) {
  //         addHours.push(start);
  //         start++;
  //       }
  //       setDisabledStartHours(hours => [...hours, ...addHours]);
  //     })
  //   }
  // }, [repeatType])

  React.useEffect(() => {
    if (!privateLessonCategoryIDs || privateLessonCategoryIDs.length === 0
      || !categories || categories.length === 0) {
      return;
    }
    const result: CategoryItem[] = [];
    privateLessonCategoryIDs.forEach(id => {
      const find = findCategory(id, categories);
      if (find) {
        result.push(find);
      }
    })
    setChoiceCategories(result);
  }, [privateLessonCategoryIDs, categories])

  React.useEffect(() => {
    if (startDate) {
      setCreateDateStr(getFormatDate(startDate.getTime()));
      setStartHour(startDate.getHours());
    } else {
      setCreateDateStr(getFormatDate(new Date().getTime()));
      setStartHour(0);
    }
  }, [startDate])

  const handleCreateDateChange = (event) => {
    setCreateDateStr(event.target.value);
  }

  const handleStartHourChange = (event) => {
    setStartHour(event.target.value);
  }

  const handleStartMinuteChange = (event) => {
    setStartMinute(event.target.value);
  }

  const handleTimeLengthChange = (event) => {
    setTimeLength(event.target.value);
  }

  const handleRepeatTypeChange = (event) => {
    setRepeatType(event.target.value);
  }

  const handleRepeatDaysChange = (event) => {
    setRepeatDays(event.target.value)
  }

  const handleTitleChange = (event) => {
    const newText = event.target.value;
    if (newText?.length > 100) {
      enqueueSnackbar('The maximum length of the title is 100', { variant: 'info' })
    } else {
      setTitle(newText)
    }
  }

  const handleCreate = () => {
    if (custom) {
      handleCreateCustomClass();
    } else {
      handleCreateAvailableTime();
    }
  }

  const handleCreateCustomClass = () => {
    const date = new Date(createDateStr.replace("-", "/").replace("-", "/") + " 00:00:00");
    date.setHours(startHour);
    date.setMinutes(startMinute);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const startTimeStamp = date.getTime();
    // const endTimeStamp = startTimeStamp + timeLength * 60 * 1000;
    // console.log("startTimeStamp: " + startTimeStamp + ", " + getFormatDisplayTime(startTimeStamp));
    // console.log("endTimeStamp: " + endTimeStamp + ", " + getFormatDisplayTime(endTimeStamp));
    if (startTimeStamp < new Date().getTime()) {
      enqueueSnackbar(t('teacher.timeIsPast'), { variant: 'warning' })
      return;
    }
    if (custom && (customClassFee <= 0.5 || customClassFee > 99999)) {
      enqueueSnackbar("Please set valid class price", { variant: 'info' });
      return;
    }
    createCustomPrivateLessonClass({
      teacherId: authRole.isAdmin? teacherId: undefined, 
      startTime: startTimeStamp,
      title: title,
      fee: NP.times(customClassFee, 100),
      timeMinutes: timeLength,
    }).unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        if (res.result.id) {
          navigate(appConfig.paths.privateLesson + "?id=" + res.result.id);
        } else {
          enqueueSnackbar("No class id in return data", {variant: "error"});
        }
      } else if (res?.resultCode === 701003) {
        const list: UserScheduleEntity[] = res.result.user_schedule_list;
        if (list?.length > 0) {
          setConflictData({show: true, lessons: list});
        } else {
          enqueueSnackbar(res?.message || "Create class failed", {variant: "error"}); 
        }
      } else {
        enqueueSnackbar(res?.message || "Create class failed", {variant: "error"});
      }
    }).catch(e => {enqueueSnackbar("Create class failed", {variant: "error"});})
  }

  const handleCreateAvailableTime = () => {
    if (!repeatType) {
      enqueueSnackbar("Please choose the value", { variant: 'info' });
      return;
    }
    if (lessonType === PrivateLessonTypeEnum.Group && (groupFee <= 0 || groupFee > 99999)) {
      enqueueSnackbar("Please set valid group lesson price", { variant: 'info' });
      return;
    }
    const date = new Date(createDateStr.replace("-", "/").replace("-", "/") + " 00:00:00");
    date.setHours(startHour);
    date.setMinutes(startMinute);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const startTimeStamp = date.getTime();
    const endTimeStamp = startTimeStamp + timeLength * 60 * 1000;
    // console.log("startTimeStamp: " + startTimeStamp + ", " + getFormatDisplayTime(startTimeStamp));
    // console.log("endTimeStamp: " + endTimeStamp + ", " + getFormatDisplayTime(endTimeStamp));
    if (startTimeStamp < new Date().getTime()) {
      enqueueSnackbar(t('teacher.timeIsPast'), { variant: 'warning' })
      return;
    }
    // if (isProductionEnv() && startTimeStamp - new Date().getTime() < 60 * 60 * 1000) {
    if (lessonType === PrivateLessonTypeEnum.Group && startTimeStamp - new Date().getTime() < 60 * 60 * 1000) {
      enqueueSnackbar("Start time must be at least 1 hour later than now", { variant: 'warning' })
      return;
    }
    const paramRepeatType = repeatType === RepeatTypeEnum.NO_REPEAT ? undefined : repeatType;
    if (!categories || categories.length === 0) {
      enqueueSnackbar('no categories, please refresh again', { variant: 'warning' })
      return
    }
    if (!selectCategoryIDs || selectCategoryIDs.length === 0) {
      enqueueSnackbar('Please select categories', { variant: 'warning' })
      return
    }
    const paramEntity: CreateAvailableTimeEntity = {
      startTime: startTimeStamp,
      endTime: endTimeStamp,
      repeatType: paramRepeatType,
      repeatDays: repeatDays,
      title: title,
      scheduleType: lessonType,
      minStudentNum: minNum,
      maxStudentNum: maxNum,
      groupFee: groupFee,
      categoryIds: selectCategoryIDs || [],
      originalUserId: authRole.isAdmin ? teacherId : undefined,
    }
    createAvailableTime({ entity: paramEntity }).unwrap()
      .then(res => {
        if (res && !res.isFailed) {
          enqueueSnackbar("Create Successfully", { variant: 'success' })
          onCreateSuccess();
        } else if (res?.resultCode === ErrorCode.TEACHER_TIME_IS_SCHEDULED) {
          enqueueSnackbar(t('teacher.timeIsScheduled'), { variant: 'warning' })
        } else {
          enqueueSnackbar(res?.message || "Create failed", { variant: 'error' })
        }
      })
  }


  const isTimePeriodsValid = (timePeriods: TimeInterval[]): boolean => {
    for (let i = 0; i < timePeriods.length; i++) {
      const time_1 = timePeriods[i];
      if (time_1.start_time > time_1.end_time) {
        enqueueSnackbar("The end time must larger than start time", { variant: 'info' });
        return false;
      }
      for (let j = i + 1; j < timePeriods.length; j++) {
        const time_2 = timePeriods[j];
        if (isOverlapped(time_1, time_2)) {
          enqueueSnackbar("The time periods are overlapped", { variant: 'warning' });
          return false;
        }
      }
    }
    return true;
  }

  const isOverlapped = (time1: TimeInterval, time2: TimeInterval) => {
    return (time1.start_time >= time2.start_time && time1.start_time < time2.end_time) ||
      (time2.start_time >= time1.start_time && time2.start_time < time1.end_time) ||
      (time1.start_time <= time2.start_time && time1.end_time >= time2.end_time) ||
      (time2.start_time <= time1.start_time && time2.end_time >= time1.end_time);
  }

  const cancelCreate = () => {
    setTitle('');
    setRepeatType(RepeatTypeEnum.NO_REPEAT);
    setRepeatDays(0);
    setStartHour(0);
    onClickCancel();
  }

  const getWeekDesc = (daysNum: number) => {
    if (daysNum / 7 === 1) {
      return '1 week';
    } else if (daysNum / 7 === 4) {
      return '1 month (4 weeks)';
    } else if (daysNum / 7 === 8) {
      return '2 months (8 weeks)';
    } else if (daysNum / 7 === 13) {
      return '3 months (13 weeks)';
    } else {
      return daysNum / 7 + ' weeks';
    }
  }

  const handleLessonTypeChange = (event) => {
    setLessonType(event.target.value);
  }

  const handleCategoryChange = (event) => {
    setSelectCategoryIDs(event.target.value);
  }

  const handleMinNumChange = (event) => {
    setMinNum(event.target.value);
  }

  const handleMaxNumChange = (event) => {
    setMaxNum(event.target.value);
  }

  const handleGroupFeeChange = (event) => {
    setGroupFee(parseFloat(event.target.value));
  }

  const handleCustomClassFeeChange = (event) => {
    setCustomClassFee(parseFloat(event.target.value));
  }

  const getConflictTitle = (item: UserScheduleEntity) => {
    if (item.source_type === UserScheduleSourceTypeEnum.SERIES_LESSON_CLASSES) {
      return item.series_lesson_classes?.series_lesson?.title || "Unknown series lesson";
    } else if (item.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON_CLASSES) {
      return item.private_lesson_classes?.title || "Unknown private lesson";
    } else if (item.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON) {
      return item.private_lesson?.title || "Unknown private lesson";
    } else {
      return "Unknown lesson";
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        open={open}>
        <DialogTitle>{custom ? "Create Private Lesson Class" : "Create Available Time"}</DialogTitle>
        <DialogContent>
          {/* <Typography className={classes.subTitle}>Private Lesson</Typography> */}
          <Divider />

          <Box className={classes.choiceItemLine}>
            <Typography className={classes.choiceItemtitle}>Date</Typography>
            <MaterialTextField
              id="date"
              type="date"
              variant="outlined"
              size='small'
              value={createDateStr}
              onChange={handleCreateDateChange}
              InputProps={{ inputProps: { min: getCurrentFormatDate() } }}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>

          {/* <Box className={classes.choiceItemBox}> */}
          <Box component='span' className={classes.choiceItemLine}>
            <Typography className={classes.choiceItemtitle}>{'Start Time'}</Typography>
            <Box sx={{ display: "flex",width:isMobile?"400px":"" }}>
              <Select
                variant='outlined'
                className={classes.hourSelect}
                sx={{ marginRight: '2px' }}
                value={startHour}
                onChange={(event) => handleStartHourChange(event)}>
                {[...Array(24)].map((num, index) => (
                  <MenuItem key={index} value={index} disabled={disableStartHours.includes(index)}>{index < 10 ? `0${index}` : index}</MenuItem>
                ))}
              </Select>
              :
              <Select
                variant='outlined'
                className={classes.hourSelect}
                sx={{ marginLeft: '2px' }}
                value={startMinute}
                onChange={(event) => handleStartMinuteChange(event)}>
                <MenuItem value={0}>00</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={45}>45</MenuItem>
              </Select>
            </Box>
          </Box>

          {/* </Box> */}
          <Box component='span' className={classes.choiceItemLine} >
            <Typography className={classes.choiceItemtitle}>{'Length'}</Typography>
            <Select
              variant='outlined'
              className={classes.timeSelect}
              value={timeLength}
              onChange={(event) => handleTimeLengthChange(event)}>
              {[...TIME_MINUTES_LENGTH_CHOICES].map((num, index) => (
                <MenuItem key={index} value={num} disabled={!timeFees?.find(item => item.time === num)}>{`${num} mins`}</MenuItem>
              ))}
            </Select>
          </Box>

          <Box className={classes.choiceItemLine}>
            <Typography className={classes.choiceItemtitle}>Title</Typography>
            <MaterialTextField
              variant='outlined'
              size='small'
              value={title}
              onChange={handleTitleChange}
              className={classes.textField}
            />
          </Box>

          <Box className={classes.choiceItemLine}>
            <Typography variant="subtitle2" className={classes.choiceItemtitle}>
              Category
            </Typography>
            <Select
              multiple
              variant='outlined'
              className={classes.RepeatValueField}
              value={selectCategoryIDs}
              onChange={handleCategoryChange}>
              {choiceCategories?.map(item => (
                <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>
              ))}
            </Select>
          </Box>

          {/* <Box className={classes.choiceItemLine} sx={{ marginTop: '20px' }}>
            <Typography className={classes.choiceItemtitle}>Type</Typography>
            <RadioGroup className={classes.RepeatValueField} value={lessonType} onChange={handleLessonTypeChange}>
              <FormControlLabel value={PrivateLessonTypeEnum.Single} control={<Radio />} label="Private Lesson" />
              <FormControlLabel value={PrivateLessonTypeEnum.Group} control={<Radio />} label="Group Lesson" />
            </RadioGroup>
          </Box> */}

          {lessonType === PrivateLessonTypeEnum.Group &&
            <Box>
              <Box className={classes.choiceItemLine}>
                <Typography variant="subtitle2" className={classes.choiceItemtitle}>
                  Minimum Number
                </Typography>
                <Select
                  variant='outlined'
                  className={classes.RepeatValueField}
                  value={minNum}
                  onChange={handleMinNumChange}>
                  <MenuItem value={1} >1 student</MenuItem>
                  <MenuItem value={2} >2 students</MenuItem>
                  <MenuItem value={3} >3 students</MenuItem>
                  <MenuItem value={4} >4 students</MenuItem>
                  <MenuItem value={5} >5 students</MenuItem>
                </Select>
              </Box>

              <Box className={classes.choiceItemLine}>
                <Typography variant="subtitle2" className={classes.choiceItemtitle}>
                  Maximum Number
                </Typography>
                <Select
                  variant='outlined'
                  className={classes.RepeatValueField}
                  value={maxNum}
                  onChange={handleMaxNumChange}>
                  <MenuItem value={5} >5 student</MenuItem>
                  <MenuItem value={10} >10 students</MenuItem>
                  <MenuItem value={15} >15 students</MenuItem>
                  <MenuItem value={20} >20 students</MenuItem>
                  <MenuItem value={25} >25 students</MenuItem>
                </Select>
              </Box>

              <Box className={classes.choiceItemLine}>
                <Typography className={classes.choiceItemtitle}>Price Per Person</Typography>
                <MaterialTextField
                  variant='outlined'
                  placeholder='$'
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  inputMode='numeric'
                  size='small'
                  value={groupFee}
                  onChange={handleGroupFeeChange}
                  className={classes.textField}
                />
              </Box>
            </Box>
          }

          {!custom &&
          <Box className={classes.choiceItemLine}>
            <Typography className={classes.choiceItemtitle}>Repeat</Typography>
            <Select
              variant='outlined'
              className={classes.RepeatValueField}
              value={repeatType}
              onChange={handleRepeatTypeChange}>
              <MenuItem value={RepeatTypeEnum.NO_REPEAT} >No Repeat</MenuItem>
              <MenuItem value={RepeatTypeEnum.DAY} >Every Day</MenuItem>
              <MenuItem value={RepeatTypeEnum.Week} >Every Week</MenuItem>
            </Select>
          </Box>
          }

          {repeatType !== RepeatTypeEnum.NO_REPEAT &&
            <Box>
              <Box className={classes.choiceItemLine}>
                <Typography className={classes.choiceItemtitle}>Limit Repeat</Typography>
                <Select
                  variant='outlined'
                  className={classes.RepeatValueField}
                  value={repeatDays}
                  onChange={handleRepeatDaysChange}>
                  {repeatType === RepeatTypeEnum.DAY && [...LIMIT_DAYS_CHOICES].map((num, index) => (
                    <MenuItem key={index} value={num}>{`${num} days`}</MenuItem>
                  ))}

                  {repeatType === RepeatTypeEnum.Week && [...LIMIT_WEEKS_CHOICES].map((num, index) => (
                    <MenuItem key={index} value={num}>{getWeekDesc(num)}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className={classes.repeatDesc}>
                {`After selecting repeat type and limit repeat time, the available time 
            will be repeated by repeat type within limit repeat time.`}
              </Box>
            </Box>
          }
          {custom &&
          <Box className={classes.choiceItemLine}>
            <Typography className={classes.choiceItemtitle}>Price</Typography>
            <MaterialTextField
              variant='outlined'
              placeholder='$'
              type="number"
              InputProps={{ inputProps: { min: 0.5 } }}
              inputMode='numeric'
              size='small'
              value={customClassFee}
              onChange={handleCustomClassFeeChange}
              className={classes.textField}
            />
          </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' className={classes.cancelButton} onClick={cancelCreate} style={{ textTransform: "none" }}>Cancel</Button>
          <Button variant='contained' className={classes.createButton} onClick={handleCreate} style={{ textTransform: "none" }} disabled={isCreateLoading}>Create</Button>
        </DialogActions>
      </Dialog>
      
      
      <Dialog open={conflictData.show} maxWidth="lg">
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          Below times are conflicted, please update class time
          <Box sx={{marginTop: '20px'}}>
          <TableContainer component={Paper}  variant="outlined">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" >Current</TableCell>
                  <TableCell align="center" >Conflict With</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conflictData.lessons?.map((row, index) => (
                  <TableRow key={index} >
                    <TableCell align="center"> {`lesson title: ${title};  start Date: ${createDateStr}`}</TableCell>
                    <TableCell align="center">
                      <Box>
                        {`lesson title: ${getConflictTitle(row)};  lesson type: ${row.source_type?.replaceAll("_", " ")};    start time: ${getFormatDisplayTime(row.start_time)}`}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> 
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => setConflictData({show: false, lessons: []})}>Close</Button>
        </DialogActions>
      </Dialog>

      <Loader isLoading={isCreateLoading} />
    </>
  )

} 
