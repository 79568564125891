import React from 'react'
import { CourseList } from './CourseList'
import { useGetCourseListByCategoryQuery } from 'src/app/service/real/coursedetail-slice'
import { CourseEntity } from '@dokyo/common'


export interface PropsCourseListByCategoryId {
  categoryId: string,
  startKey: string,
  onDataLoad: (startKey: string) => void
}

export const CourseListByCategoryId: React.FC<PropsCourseListByCategoryId> =({ categoryId, startKey, onDataLoad }) => {

  const { data, isFetching } = useGetCourseListByCategoryQuery({ startKey, categoryId, pageSize: 12})

  let courseList: CourseEntity[] = []
  if (data && !data.isFailed && data.result) {
    if (data.result.course_list) {
      courseList = data.result.course_list
    } else {
      courseList = []
    }
    
    onDataLoad(data.result.start_key?? '')
  }


  return (
    <CourseList isLoading={isFetching} courseList={courseList} />
  )
}