import { FC } from 'react';
import {
    useAudioInputs,
    useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import DeviceInput from '../../deviceInput';

interface Props {
    label?: string;
    showLabel?: boolean;
    inline?: boolean;
}

const MicSelection: FC<Props> = ({
    showLabel,
    inline,
    label = 'Microphone source'
}) => {
    const { devices, selectedDevice } = useAudioInputs();
    const meetingManager = useMeetingManager();
    const handleSelect = async(deviceId: string): Promise<void> => {
        if (deviceId === 'not-available') {
            return;
        }
        try {
            await meetingManager.startAudioInputDevice(deviceId);
        } catch (error) {
            console.error('MicSelection failed to select mic', error);
            // throw new Error('MicSelection failed to select mic');
        }
    };
    
    return (
        <DeviceInput 
            label={label}
            inputId='mic-selection-select'
            notFoundMsg='No microphone devices found'
            devices={devices}
            selectedDevice={selectedDevice}
            onChange={handleSelect}
            showLabel={showLabel}
            inline={inline}
        />
    )
}


export default MicSelection;