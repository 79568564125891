import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
  showSignInUpDialog: boolean,
  showSignUp: boolean,
  showLogoutAlert: boolean,
}

const initState: AuthState = {
  showSignInUpDialog: false,
  showSignUp: false,
  showLogoutAlert: false,
}


const authSlice = createSlice({
  name: 'sign-in-up',
  initialState: initState,
  reducers: {
    showSignInUpDialog(state, action: PayloadAction<boolean|undefined>) {
      state.showSignInUpDialog = true;
      state.showSignUp = action?.payload || false;
    },
    // setCloseSignInUpDialogCallback(state, action: PayloadAction<{callback:() => void}>) {
    //   state.closeSignInUpDialogCallback = action.payload.callback;
    // },
    closeSignInUpDialog(state) {
      state.showSignInUpDialog = false;
      state.showSignUp = false;
      // state.closeSignInUpDialogCallback = () => {}
    },
    showLogoutDialog(state) {
      state.showLogoutAlert = true;
    },
    closeLogoutDialog(state) {
      state.showLogoutAlert = false;
    },
  }
})


export const { showSignInUpDialog, closeSignInUpDialog, showLogoutDialog, closeLogoutDialog } = authSlice.actions

export default authSlice.reducer