import React from 'react'
import { Box, Typography } from '@mui/material'
import { useGetQueryMeetingListQuery } from 'src/app/service/real/coursedetail-slice'
import { useGetLearnPageQuery } from 'src/app/service/real/learnpage-slice'
import { Loader } from 'src/components/loader'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import { ClassroomListCard } from 'src/components/cards/ClassroomListCard'
import { useNavigate } from 'react-router-dom'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useDispatch } from 'react-redux'
import { ListType, getClassroomData, isClassroomNow, navigateVideoClassroom } from './VideoClassroomList'
import { EmptyList } from '../EmptyList'
import SvgComponent from 'src/components/svgComponent'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  courseList: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '30px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '22px'
  },
  viewMoreInner: {
    width: '160px',
    height: '36px',
    background: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #CCCCCC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))

const SectionTitle = styled(Typography)({
  fontSize: '30px',
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginLeft: '12px',
  marginRight: '20px'
})


export interface Props {
  onClickMore: (type: ListType) => void
}

export const ClassroomNowAndUpcoming: React.FC<Props> =({ onClickMore }) => {
  const classes = useStyles();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = (videoClassroom: ClassroomListCardEntity) => {
    navigateVideoClassroom(videoClassroom, dispatch, navigate)
  }


  let now: ClassroomListCardEntity[] = []
  let upcoming: ClassroomListCardEntity[] = []
  let nowTotal = 0
  let upcomingTotal = 0

  // const { data: liveData, isFetching: liveIsFetching, refetch } = useGetQueryMeetingListQuery({
  //   pageSize: 18
  // })

  const { data: nowData, isFetching: nowFetching, refetch: nowRefch } = useGetLearnPageQuery({
    learnType:'live_now_meeting',
    pageSize: 6
  }, {
    refetchOnMountOrArgChange: 60
  })


  let nowHasMore = false
  let upComingHasMore = false

  if (nowData && !nowData.isFailed && nowData.result?.learn_page) {
    if (nowData.result.learn_page[0]?.meeting_list) {
      now = getClassroomData(nowData.result.learn_page[0]?.meeting_list)
    } else {
      now = []
    }
    if (nowData.result.learn_page[0]?.start_key) {
      nowHasMore = true
    }
  }




  const { data: upcomingData , isFetching: upcomingIsFetching, refetch: upcomingRefetch} = useGetLearnPageQuery({
    learnType:'upcoming_meeting',
    pageSize: 6
  })


  if (upcomingData && !upcomingData.isFailed && upcomingData.result?.learn_page) {
    if (upcomingData.result.learn_page[0]?.meeting_list) {
      upcoming = getClassroomData(upcomingData.result.learn_page[0]?.meeting_list)
    } else {
      upcoming = []
    }
    if (upcomingData.result.learn_page[0]?.start_key) {
      upComingHasMore = true
    }
  }

  

  // if (liveData && !liveData.isFailed && liveData.result) {
  //   if (liveData.result.meeting_list) {
  //     const total = getClassroomData(liveData.result.meeting_list)
  //     total.forEach(item => {
  //       if (isClassroomNow(item.time)) {
  //         if (nowTotal <= 7) {
  //           now.push(item)
  //         }
  //         nowTotal++
  //       } else {
  //         if (upcomingTotal <= 7) {
  //           upcoming.push(item)
  //         }
  //         upcomingTotal++
  //       }
  //     })
  //   } 
  //   if (nowTotal >= 8) {
  //     nowHasMore = true
  //   }
  //   if (upcomingTotal >= 8) {
  //     upComingHasMore = true
  //   }
  // }

  const handleClickMore =(type: ListType) => {
    onClickMore(type)
  }

  const handleRefresh = () => {
    nowRefch()
    upcomingRefetch()
  }

  const LiveAndUpcoming = () => {
    return (
      <Box>
        <Box className={classes.title}>
          <SectionTitle>
          Classroom Now
          </SectionTitle>
          <SvgComponent onClick={handleRefresh} iconName={"refreshIcon"} style={{ width: "21px", height: "21px", cursor: 'pointer' }}/>
        </Box>
        <Box className={classes.courseList}>
          {
            now && now.length > 0?
            now.map((videoClassroom) => {
              return (
                <Box key={videoClassroom.id} onClick={() => {handleClick(videoClassroom)}}>
                  <ClassroomListCard
                  card={videoClassroom}
                  label={'Video'}
                  />
                </Box>
              )
            }):
            <EmptyList name={t("discover.empty.classroom.live")} />
          }
        </Box>
        {
          nowHasMore?
          <Box className={classes.viewMore}>
            <Box onClick={()=> handleClickMore(ListType.Now)} className={classes.viewMoreInner}>
              <Box>View More</Box> <KeyboardArrowDownIcon/>
            </Box>
          </Box>:
          ''
        }
        <SectionTitle sx={{
          mb: '20px'
        }}>
          Upcoming Classroom
        </SectionTitle>
        <Box className={classes.courseList}>
          {
            upcoming && upcoming.length > 0?
            upcoming.map((videoClassroom) => {
              return (
                <Box key={videoClassroom.id} onClick={() => {handleClick(videoClassroom)}}>
                  <ClassroomListCard
                  card={videoClassroom}
                  showLabel={false}
                  label={'Video'}
                  />
                </Box>
              )
            }):
            <EmptyList name={t("discover.empty.classroom.upcoming")} />
          }
        </Box>
        {
          upComingHasMore?
          <Box className={classes.viewMore}>
            <Box onClick={()=> handleClickMore(ListType.UpComing)} className={classes.viewMoreInner}>
              <Box>View More</Box> <KeyboardArrowDownIcon/>
            </Box>
          </Box>:
          ''
        }
      </Box>    
    )
  }
  const isLoading = nowFetching || upcomingIsFetching
  return (
    <>
    <Loader isLoading={isLoading} />
    {
      isLoading?
      '':
      <LiveAndUpcoming />
    }
    </>
  )
}