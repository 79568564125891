import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { RawUser } from '../../models'
import { Result, Response, Value } from '@dokyo/common'

export const awsApi = createApi({
  reducerPath: 'aws',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getProfile: builder.mutation<Result<{ user_profile: RawUser }>, { userId: string }>({
        query: ({ userId }) => ({
          url: '/getuserprofile',
          method: 'POST',
          body: { user_id: userId }
        }),
        transformResponse: (res: Response<{ user_profile: RawUser }>) => new Value(res)
      }),
      getUserProfile: builder.query<Result<{ user_profile: RawUser }>, { userId: string }>({
        query: ({ userId }) => ({
          url: '/getuserprofile',
          method: 'POST',
          body: { user_id: userId }
        }),
        transformResponse: (res: Response<{ user_profile: RawUser }>) => new Value(res)
      })
    }
  }
})

export const { useGetProfileMutation, useGetUserProfileQuery } = awsApi
