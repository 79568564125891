import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Button, Avatar } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios'
import { FileEntity } from '@dokyo/common';
import { formatFileSize, getDisplayUserName } from 'src/utils/stringUtil';
import { useSnackbar } from 'notistack';
import PreviewPicture from 'src/components/preview/PreviewPicture';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import PreviewVideo from 'src/components/preview/PreviewVideo';
import { ChatMessage } from 'src/app/models';
import { useSelector } from 'src/app/toolkit/store';
import { getChatMessageTimeDesc } from 'src/utils/timeUtil';
import { SvgComponent } from '../svgComponent';

const useStyles = makeStyles(theme => ({
  chatRootBox: {
  },
  chatItem: {
    marginTop: "20px"
  },
  chatUserNameSelf: {
    color: "#AAAAAA",
    textAlign: "right",
    padding: "10px 0",
    fontSize: "14px !important",
  },
  chatUserNameOther: {
    color: "#AAAAAA",
    padding: "10px 0",
    fontSize: "14px !important",
  },
  chatMessageBoxSelf: {
    textAlign: "end"
  },
  chatMessageBoxOther: {
    textAlign: "start"
  },
  chatSelfUserInfoBox: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginLeft: "auto",
  },
  chatFileBoxSelf: {
    marginLeft: "auto",
    background: "#FCE5ED",
    borderRadius: "5px 0 5px 5px",
    width: "fit-content",
    maxWidth: "260px",
    padding: "8px 12px",
  },
  chatOtherUserInfoBox: {
    display: "flex",
    alignItems: "center",
  },
  chatFileBoxOther: {
    background: "#EEEEEE",
    borderRadius: "5px 0 5px 5px",
    width: "fit-content",
    maxWidth: "260px",
    padding: "8px 12px",
  },
  selfChatMessageLine: {
    display: 'flex',
    flexDirection: 'row',
    width: "fit-content",
    marginLeft: "auto",
  },
  chatMessageSelf: {
    color: "black",
    background: "#FCE5ED",
    borderRadius: "5px 0 5px 5px",
    width: "fit-content",
    maxWidth: "260px",
    padding: "8px 12px",
    fontSize: 14,
    textAlign: "left",
    wordBreak: "break-word",
    display: "inline-table",
  },
  chatSelfText: {
    color: "#666666",
    fontSize: "14px !important",
    textAlign: "left",
    wordBreak: "break-word",
  },
  chatOtherText: {
    color: "#666666",
    fontSize: "14px !important",
    wordBreak: "break-word",
  },
  fileStatusText: {
    fontSize: "12px !important",
    color: "#999999",
    margin: "5px !important"
  },
  chatMessageOther: {
    color: "black",
    background: "#EEEEEE",
    borderRadius: "0 5px 5px 5px",
    width: "fit-content",
    maxWidth: "260px",
    padding: "8px 12px",
    fontSize: 14,
    wordBreak: "break-word",
    display: "inline-table",
  },
  chatPicture: {
    width: "96px !important",
    height: "96px !important",
    cursor: "pointer",
  },
  chatVideo: {
    width: "96px !important",
    height: "96px !important",
    cursor: "pointer",
    color: "white",
    background: "#aaaaaa",
    borderRadius: "10px",
  },
  timeDesc: {
    fontSize: "12px !important",
    color: "#999999 !important",
    fontFamily: "ArialMT !important",
    marginTop: "5px !important",
  }
}))



interface Props {
  chatMessageList: ChatMessage[];
  enableEdit?: boolean;
  onClickMessageMenu?: (event: React.MouseEvent<HTMLElement>, chatMessage: ChatMessage) => void;
}

export const MeetingChatMessageList: React.FC<Props> = ({ chatMessageList, enableEdit, onClickMessageMenu }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const { credential } = useSelector(state => state.app);
  const [previewPictureConfig, setPreViewPictureConfig] = useState({ open: false, pictureUrl: "" });
  const [previewVideoConfig, setPreViewVideoConfig] = useState({ open: false, videoUrl: "" });
  const DEFAULT_AVATAR = "https://portal.dev.veeko.com/mobile/DefaultAvatar/ic_unspecified1.png";

  const downloadFile = (entity: FileEntity) => {
    const downloadUrl = entity.publicUrl;
    console.log("download: ", downloadUrl)
    axios({
      url: downloadUrl,
      responseType: 'blob',
      method: 'GET',
      onDownloadProgress: (progressEvent) => {
        // let percentComplete = progressEvent.loaded / progressEvent.total;
        // setProgress(percentComplete*100)
      }
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: entity.fileType }))
        const link = document.createElement("a");
        link.download = entity.fileName;
        link.href = url;
        link.click();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Download failed: " + err.message)
      })
  }

  const previewPicture = (url: string) => {
    setPreViewPictureConfig({
      open: true,
      pictureUrl: url,
    })
  }

  const previewVideo = (url: string) => {
    setPreViewVideoConfig({
      open: true,
      videoUrl: url,
    })
  }

  return (
    <>
      <Box className={classes.chatRootBox}>
        {chatMessageList?.length > 0 ? chatMessageList.map((row, index) => (
          <Box key={index} className={classes.chatItem}>
            {row.sender_id === credential?.id ?
              <Box className={classes.chatMessageBoxSelf}>
                <Box className={classes.chatSelfUserInfoBox}>
                  <Typography className={classes.chatUserNameSelf} >
                    {getDisplayUserName(row.sender_name, row.sender_id)}
                  </Typography>
                  <Avatar
                    src={row.sender_avatar || DEFAULT_AVATAR}
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }} 
                  />
                </Box>
                <Box className={classes.selfChatMessageLine}>
                  {row.type === "text" ?
                  <Box component="span" className={classes.chatMessageSelf}>{row.content}</Box>
                  :
                  <Box className={classes.chatFileBoxSelf}>
                    {(JSON.parse(row.content) as FileEntity).fileType?.startsWith("image") ?
                      <Avatar
                        className={classes.chatPicture}
                        variant="square"
                        src={(JSON.parse(row.content) as FileEntity).publicUrl || (JSON.parse(row.content) as FileEntity).filePath}
                        onClick={() => previewPicture((JSON.parse(row.content) as FileEntity).publicUrl || (JSON.parse(row.content) as FileEntity).filePath)} />
                      :
                      ((JSON.parse(row.content) as FileEntity).fileType?.startsWith("video") ?
                        <PlayCircleTwoToneIcon className={classes.chatVideo} onClick={() => previewVideo((JSON.parse(row.content) as FileEntity).publicUrl || (JSON.parse(row.content) as FileEntity).filePath)} />
                        :
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <AttachFileIcon fontSize='small' style={{ color: "blue" }} />
                          <Typography className={classes.chatSelfText}>{`${(JSON.parse(row.content) as FileEntity).fileName}`}</Typography>
                        </Box>
                      )
                    }
                    <Typography className={classes.fileStatusText}>
                      {`${formatFileSize((JSON.parse(row.content) as FileEntity).fileSize)} ${row.status === 1 ? "sending..." : "send finished"}`}
                    </Typography>
                    {row.status === 2 && !((JSON.parse(row.content) as FileEntity).fileType?.startsWith("image")) &&
                      <Button size="small" color="secondary" style={{ textTransform: "none", fontSize: "14px" }} onClick={() => downloadFile(JSON.parse(row.content) as FileEntity)}>Download</Button>
                    }
                  </Box>
                  }
                  {enableEdit &&
                  <SvgComponent 
                    iconName='ico-menu-dots' 
                    style={{paddingLeft: '8px', cursor:'pointer', width:'16px', height: '16px'}} 
                    onClick={(event) => {onClickMessageMenu && onClickMessageMenu(event, row)}}
                  />
                  }
                </Box>
                <Typography className={classes.timeDesc}>{row.time && getChatMessageTimeDesc(row.time)}</Typography>
              </Box>
              :
              <Box className={classes.chatMessageBoxOther}>
                <Box className={classes.chatOtherUserInfoBox}>
                  <Avatar
                    src={row.sender_avatar || DEFAULT_AVATAR}
                    style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                  <Typography className={classes.chatUserNameOther} >
                    {getDisplayUserName(row.sender_name, row.sender_id)}
                  </Typography>
                </Box>
                {row.type === "text" ?
                  <Box component="span" className={classes.chatMessageOther}>{row.content}</Box>
                  :
                  <Box className={classes.chatFileBoxOther}>
                    {(JSON.parse(row.content) as FileEntity).fileType?.startsWith("image") ?
                      <Avatar
                        className={classes.chatPicture}
                        variant="square"
                        src={(JSON.parse(row.content) as FileEntity).publicUrl || (JSON.parse(row.content) as FileEntity).filePath}
                        onClick={() => previewPicture((JSON.parse(row.content) as FileEntity).publicUrl || (JSON.parse(row.content) as FileEntity).filePath)} />
                      :
                      ((JSON.parse(row.content) as FileEntity).fileType?.startsWith("video") ?
                        <PlayCircleTwoToneIcon className={classes.chatVideo} onClick={() => previewVideo((JSON.parse(row.content) as FileEntity).publicUrl || (JSON.parse(row.content) as FileEntity).filePath)} />
                        :
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <AttachFileIcon fontSize='small' style={{ color: "blue" }} />
                          <Typography className={classes.chatOtherText}>{(JSON.parse(row.content) as FileEntity).fileName}</Typography>
                        </Box>
                      )
                    }

                    <Typography className={classes.fileStatusText}>
                      {`${formatFileSize((JSON.parse(row.content) as FileEntity).fileSize)}`}
                    </Typography>
                    {!((JSON.parse(row.content) as FileEntity).fileType?.startsWith("image")) &&
                      <Button size="small" color="secondary" style={{ textTransform: "none", fontSize: "14px" }} onClick={() => downloadFile(JSON.parse(row.content) as FileEntity)}>Download</Button>
                    }
                  </Box>

                }
                <Typography className={classes.timeDesc}>{row.time && getChatMessageTimeDesc(row.time)}</Typography>
              </Box>
            }
          </Box>
        ))
          :
          <Box style={{textAlign: 'center', marginTop: "30px"}}>
            <SvgComponent iconName='ico-no-chat' style={{width: '48px', height: '48px'}} />
            <Typography style={{ textAlign: "center", color: "#999999", marginTop: "10px" }}>no message</Typography>
          </Box>
        }

        <PreviewPicture
          showDialog={previewPictureConfig.open}
          pictureUrl={previewPictureConfig.pictureUrl}
          onClose={() => setPreViewPictureConfig({ open: false, pictureUrl: "" })} />

        <PreviewVideo
          showDialog={previewVideoConfig.open}
          videoUrl={previewVideoConfig.videoUrl}
          onClose={() => setPreViewVideoConfig({ open: false, videoUrl: "" })} />
      </Box>
    </>
  )
}
