import React from 'react'
import Slider from 'react-slick'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { TopBannerCard } from 'src/components/cards'
import { makeStyles } from '@mui/styles'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import SliderPagination from '../slider_pagination'

const useStyles = makeStyles(theme => ({
  masterCardSlider: {
    width: '100%',
    margin: 'auto',
    //marginBottom: '-42px',
    
    '& .slick-arrow': {
      zIndex: '1',
      '&:before': {
        fontSize: '60px',
        color: 'black',
        content: "''"
      }
    },
    
    '& .slick-list': {
      margin: '0 0'
    }
  },
  slider__content: {
    margin: 'auto'
  },
  root: {
    position: 'relative',
    display:'flex',
    justifyContent:'center',
    width: 'calc(100% - 50px)',
    minHeight:'500px'
  },
  slide: {
    minHeight: 500,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
}))

interface Header {
  title:string,
  discription:string,
  picture:string
}

interface Bar {
  title:string,
  discription:string
}

interface TopBannerSliderprops {
  config:{
    version:string,
    Headers:Header[],
    Bars:Bar[]
  },
  dots:number
}
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const TopBannerSlider:React.FC<TopBannerSliderprops> = ({config, dots,...props}) => {
  const classes = useStyles()
  
  // const SampleNextArrow = props => {
  //   const { className, style, onClick } = props
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         width: '40px',
  //         height: '40px',
  //         background: 'white',
  //         borderRadius: '50%',
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
  //       }}
  //       onClick={onClick}
  //     >
  //       <ArrowForwardIosIcon style={{ color: 'black', width: '70%', height: '70%' }} />
  //     </div>
  //   )
  // }
  // const SamplePrevArrow = props => {
  //   const { className, style, onClick } = props
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         width: '40px',
  //         height: '40px',
  //         background: 'white',
  //         borderRadius: '1%',
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
  //       }}
  //       onClick={onClick}
  //     >
  //       <ArrowForwardIosIcon
  //         style={{
  //           color: 'black',
  //           width: '70%',
  //           height: '70%',
  //           transform: 'rotate(180deg)'
  //         }}
  //       />
  //     </div>
  //   )
  // }

  // const settings = {
  //   // dots: true,
  //   infinite: false,
  //   // swipeToSlide: true,
  //   speed: 300,
  //   slidesToShow: 1,
  //   slidesToScroll: 3,
  //   initialSlide: 0,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />
  // }

  // //let isSwiping = false
  // function updateSwipeState(state) {
  //   //isSwiping = state
  // }
  const [index,SetIndex] = React.useState(0)

  const handleChangeIndex = (index:number) => {
    // console.log('handleChangeIndex')
    // console.log(index);
    SetIndex(index)
  };

  return (
    // <div className={classes.masterCardSlider}>
    //   <div className={classes.slider__content}>
    //     <Slider
    //       {...settings}
    //       afterChange={() => updateSwipeState(false)}
    //       onSwipe={() => updateSwipeState(true)}
    //     >
    //       <TopBannerCard width={"100%"} />
    //       {/*<ResourceSummary width='100%'/>*/}
    //     </Slider>
    //   </div>
    // </div>
    <div style={{position:'relative',minHeight:'500px'}}>
        <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
            interval={3000}
          >
            {
              config.Headers.map((header,index) => (
              <div className={classes.slide} key={index}>
                <TopBannerCard width={"100%"}  bar={header} />
              </div> 
              ))
            }
          </AutoPlaySwipeableViews>
        {
          dots > 1 && <div style={{    position: 'absolute',left: '50%'}}><SliderPagination dots={dots} index={index} onChangeIndex={handleChangeIndex} /></div>
        }
      </div>
  )
}
