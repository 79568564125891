import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Video } from 'src/app/models'
import React from 'react'
import DefaultLiveImage from 'src/images/defaultIcon/pic-live.png'
import { getDisplayUserName } from 'src/utils/stringUtil'


const useStyles = makeStyles(theme => ({
  content: {
    padding: '10px 0 0 0 !important'
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 10
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '48px'
  },
  title: {
    fontWeight: '600 !important',
    fontSize: '1rem !important',
    color: '#000000',
    lineHeight: '1.2rem !important',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },
  channel: {
    fontSize: '12px !important',
    // color: '#717171',
    fontWeight: 600,
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },

  image__container: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%'
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: '0',
    background: 'white',
    top: '0',
  },

  cart: {
    width: '36px',
    height: '36px',
    visibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',

    '&.active': {
      visibility: 'visible'
    }
  }
}))

interface VideoCardProps extends CardProps {
  video: Video
  onCartAdded: (video: Video) => void
}

export const VideoCard: React.FC<VideoCardProps> = ({ video, onCartAdded, onClick, ...props }) => {
  const classes = useStyles()

  return (
    <Card
      {...props}
      raised={true}
      onClick={onClick}
      style={{ background: 'transparent' }}
      sx={{ cursor: 'pointer', boxShadow: 'none', ml: 3, mr: 3 }}
    >
      <Box className={classes.image__container}>
        <CardMedia
          component="img"
          height="160"
          image={video.thumbnail || DefaultLiveImage}
          alt="Paella dish"
          className={classes.image}
        />
      </Box>
      <CardContent sx={{ boxShadow: 'none' }}>
        <Typography className={classes.title}>{video.title}</Typography>
        <div className={classes.container}>
          <Avatar
            style={{width: "20px", height: "20px"}}
            className={classes.avatar}
            alt={video.teacher?.userName}
            src={video.teacher?.userAvatar}
          />
          <div style={{ flexGrow: 1 }}>
            <Typography className={classes.channel}>{getDisplayUserName(video.teacher?.userName || '', video.teacher?.userId || '')}</Typography>
          </div>
        {/* 
          {items?.some(v => v.video.id === video.id) ? (
            <ShoppingCartOutlinedIcon color="secondary" />
          ) : (
            <IconButton
              className={clsx([classes.cart, { active: active }])}
              onClick={() => onCartAdded && onCartAdded(video)}
            >
              <AddShoppingCartIcon
                style={{
                  position: 'relative',
                  top: '1px',
                  left: '1px',
                  width: '24px',
                  height: '24px'
                }}
              />
            </IconButton>
          )} */}
        </div>
        <Typography variant="caption">
          {video.channel?.name}
        </Typography>
      </CardContent>
    </Card>
  )
}
