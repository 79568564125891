import React, { useEffect, useState } from 'react'
import { Box, TextField, Button, Typography, Divider, Dialog, DialogTitle, DialogActions } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CommentList } from './CommentList'
import { Comment } from 'src/app/models'
import { useTranslation } from 'react-i18next'
import { useDeleteUserPostInfoMutation, useLazyGetCommentListQuery, useSendCommentMutation, useSendThumbCommentMutation } from 'src/app/service/real/coursedetail-slice'
import Loader from '../loader'
import { useSelector } from 'src/app/toolkit/store'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/system'
import { getDisplayUserName } from 'src/utils/stringUtil'
import { useDispatch } from 'react-redux'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import { AutoCountInputArea } from '../AutoCountInput'

const useStyles = makeStyles(() => ({
  root: {
  },
  inputField: {
    width: "100%",
    minHeight: "80px",
    padding: "10px 15px !important",
    borderRadius: "6px",
    background: "#f5f5f5 !important",
    marginBottom: "16px !important",
  },
  btnPost: {
    float: "right",
    top: "10px",
  }
}))

const ColorButton = styled(Button)({
  color: 'white',
  '&.Mui-disabled': {
    color: 'white',
    backgroundColor: "#307DCF",
    borderRadius: "8px"
  }
})
interface PageProps {
  videoId: string;
  requestType: number;//(1: social user post, 2: social school post, 3: social course post, 4: social live post, 5: social private lesson post, 6: social teacher post)
}

export const CommentPage: React.FC<PageProps> = ({ videoId, requestType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { credential } = useSelector(state => state.app)
  const [newComment, setNewComment] = useState("");
  const [commentList, setCommentList] = useState<Comment[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState("");

  const PAGE_SIZE = 10;
  const [startKey, setStartKey] = useState("");
  const [hasMore, setHasMore] = useState<boolean>(false);

  const [sendComment, { isLoading: sendCommentLoading }] = useSendCommentMutation();
  const [sendThumbComment] = useSendThumbCommentMutation();
  const [getCommentListData, { isLoading: getMoreLoading }] = useLazyGetCommentListQuery();
  const [deleteComment, { isLoading: deleteLoading }] = useDeleteUserPostInfoMutation();

  // useEffect(()=> {
  //   window.addEventListener('scroll', () => {
  //     let scrollTop = document.documentElement.scrollTop||document.body.scrollTop ;
  //     let clientHeight = document.documentElement.clientHeight || document.body.clientHeight ;
  //     let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight ;
  //     if(scrollTop+clientHeight === scrollHeight) {
  //       handleClickMoreComment()
  //     }
  //   })
  // },[])

  useEffect(() => {
    handleClickMoreComment()
  }, [])

  const handleCommentRes = (res) => {
    if (res && !res?.isFailed && res.result) {
      const newKey = res.result.start_key;
      setStartKey(newKey);
      setHasMore(!!newKey)
      const comments = res.result.message_info;
      if (comments) {
        const addList = comments.map(item => {
          return transFormComment(item);
        });
        setCommentList(list => [...list, ...addList])
      }
    }
  }

  const transFormComment = (item: any): Comment => {
    return {
      commentId: item.message_id,
      content: item.message_content,
      time: item.message_timestamp,
      userId: item.user_id,
      userName: getDisplayUserName(item.user_name, item.user_id),
      userAvatar: item.user_avatar,
      thumbUpCount: item.thumbup_count,
      replyCount: item.comments_count,
      isThumb: item.thumbup_from_you
    }
  }

  const handleClickMoreComment = () => {
    getCommentListData({
      userId: credential?.id || "",
      courseId: videoId,
      requestType: requestType,
      startKey: startKey,
      pageSize: PAGE_SIZE
    })
      .unwrap()
      .then(res => {
        handleCommentRes(res);
      })
  }

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  }

  const handleClickPostComment = () => {
    if (!credential) {
      dispatch(showSignInUpDialog())
      return
    }
    sendComment({
      userId: credential?.id || "",
      requestId: videoId,
      requestType: requestType,
      title: newComment,
      content: newComment,
    }).unwrap()
      .then(res => {
        if (!res?.isFailed) {
          setCommentList(list => [{
            commentId: res?.result?.message_id || "",
            content: newComment,
            time: new Date().getTime(),
            userId: credential?.id || "",
            userName: credential?.name || "",
            userAvatar: credential?.avatarUrl || "",
            thumbUpCount: 0,
            replyCount: 0,
            isThumb: false
          }, ...list])

          setNewComment("")
        }
      })
      .finally()
  }

  const handleClickInput = () => {
    if (!credential) {
      dispatch(showSignInUpDialog())
    }
  }

  const handleClickThumb = (commentId: string) => {
    if (!credential) {
      dispatch(showSignInUpDialog())
      return
    }
    const comment = commentList.find(item => item.commentId === commentId);
    if (!comment) {
      return
    }
    const shouldThumb = !comment.isThumb;
    const newCommentItem = {
      commentId: comment.commentId,
      content: comment.content,
      time: comment.time,
      userId: comment.userId,
      userName: comment.userName,
      userAvatar: comment.userAvatar,
      thumbUpCount: shouldThumb ? (comment.thumbUpCount + 1) : (comment.thumbUpCount > 0 ? comment.thumbUpCount - 1 : 0),
      replyCount: comment.replyCount,
      isThumb: shouldThumb
    }
    // update local comment thumbup info
    // setCommentList(list => list.map(item=> {
    //   return (item.commentId === commentId) ? newCommentItem : item;
    // }))
    // send request
    sendThumbComment({
      userId: credential?.id || "",
      courseId: videoId,
      requestType: requestType,
      title: comment.content,
      content: comment.content,
      thumbUp: shouldThumb,
      commentId: commentId
    }).unwrap()
      .then(res => {
        if (!res?.isFailed) {
          setCommentList(list => list.map(item => {
            return (item.commentId === commentId) ? newCommentItem : item;
          }))
        } else {
          enqueueSnackbar(res?.message)
        }
      })
      .finally()
  }

  const handleclickDeleteComment = (commentId: string) => {
    setShowDeleteDialog(true);
    setDeleteCommentId(commentId);
  }

  const handleDeleteComment = () => {
    deleteComment({
      userId: credential?.id || "",
      requestType: requestType,
      messageId: deleteCommentId
    }).unwrap()
      .then(res => {
        if (!res?.isFailed) {
          const newList = commentList.filter(item => item.commentId !== deleteCommentId);
          setCommentList(newList)
        } else {
          enqueueSnackbar(res?.message)
        }
      }).finally(() => setShowDeleteDialog(false))
  }

  return (
    <>
      <Box className={classes.root}>
        <AutoCountInputArea
          placeHolder={t("class.add-a-comment")}
          value={newComment}
          onChange={handleCommentChange}
          maxCount={1000}
          onClick={handleClickInput}
        />
        <ColorButton
          variant='contained'
          size='large'
          color="secondary"
          disabled={!newComment || sendCommentLoading}
          onClick={handleClickPostComment}
          className={classes.btnPost}
          style={{ textTransform: "none", boxShadow: "none" }}>
          {t("class.post")}
        </ColorButton>
        <Divider light style={{ margin: "80px 0 20px 0" }} />
        <Typography style={{ fontSize: "24px", fontWeight: "bold", fontFamily: "Arial-BoldMT, Arial" }}>
          {t("class.comments")}
        </Typography>

        <CommentList
          userId={credential?.id}
          commentList={commentList}
          onClickThumb={handleClickThumb}
          onClickDeleteComment={handleclickDeleteComment} />

        {hasMore &&
          <Box style={{ textAlign: "center" }} >
            <Button
              variant='outlined'
              color='primary'
              onClick={handleClickMoreComment}
              style={{
                margin: "20px auto",
                textTransform: "none",
                boxShadow: "none",
              }}>
              View More
            </Button>
          </Box>}

      </Box>
      <Dialog
        open={showDeleteDialog}
      >
        <DialogTitle id="alert-dialog-title">{t("class.confirm-delete-comment")}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} size="large" sx={{ textTransform: "none", color: "#555555" }}>
            {t("class.btn-cancel")}
          </Button>
          <Button onClick={handleDeleteComment} size="large" color="secondary" autoFocus sx={{ textTransform: "none" }}>
            {t("class.btn-delete")}
          </Button>
        </DialogActions>
      </Dialog>

      <Loader isLoading={sendCommentLoading || getMoreLoading || deleteLoading} />
    </>
  )
}