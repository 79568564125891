import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { toolkitStore } from './app/toolkit/store'
import { LoadingBar } from '@dokyo/mui'
import App from './layout/App'
import { appConfig } from './app-config'
import { theme } from './theme'
import { ThemeProvider } from '@mui/material/styles'
import i18n from 'src/app/i18n'
import { AliveScope } from 'react-activation'
import 'src/index.css'
import vhCheck from 'vh-check';
vhCheck()
// import 'animate.css';
ReactDOM.render(
  <React.Suspense fallback={<LoadingBar />}>
    <ReduxProvider store={toolkitStore}>
      <ThemeProvider theme={theme}>
        <AliveScope>
          <App language={i18n.language} routes={appConfig.routes} />
        </AliveScope>
      </ThemeProvider>
    </ReduxProvider>
  </React.Suspense>,
  document.getElementById('root')
)