export interface ShareDataMessage extends ShareUserInfo {
    type: ShareDataMessagesType;
    senderAttendeeId: string;
    senderName: string;
    senderUserId: string;
}

export interface ShareUserInfo {
    senderAttendeeId: string;
    senderName: string;
    senderUserId: string;
}


export interface State {
    isTeacher: boolean;
    allowShare: boolean;
    requestShare: boolean;
    requestShareUserInfo: ShareUserInfo
}

export enum ShareDataMessagesType {
    RequestShare,
    AgreeShare,
    DisagreeShare,
    StopShare,
    ForbiddenShare,
    CloseRequestShare
}


export enum ActionType {
    ShareAction
}


export interface ShareAction {
    type: ActionType.ShareAction;
    payload: ShareDataMessage;
}


export type Action = ShareAction;

export function initialState(isTeacher: boolean): State {
   return { isTeacher, allowShare: isTeacher, requestShare: false, requestShareUserInfo: {
        senderAttendeeId: '',
        senderName: '',
        senderUserId: '',
    } };
}


export function reducer(state: State, action: Action): State {
    const { type, payload } = action;
    switch (payload.type) {
        case ShareDataMessagesType.RequestShare:
            return { ...state, requestShare: true, requestShareUserInfo: {
                senderAttendeeId: payload.senderAttendeeId,
                senderName: payload.senderName,
                senderUserId: payload.senderUserId,
            }};
        case ShareDataMessagesType.CloseRequestShare:
            return {
                ...state,
                requestShare: false,
            }
        case ShareDataMessagesType.AgreeShare:
            return {
                ...state,
                allowShare: true,
            }
        case ShareDataMessagesType.DisagreeShare:
            return {
                ...state,
                allowShare: false,
            }
        default: 
            throw new Error('Incorrect action in ShareDataMessagesProvider reducer');
    }
}

export const SHARE_DATA_MESSAGE_TOPIC = 'VeekoShareDataMessage';