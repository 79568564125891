import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settings } from '../settings'
import { CategoryItem } from '../models/category'

const keys = settings.keys

interface CategoryState {
  categories: CategoryItem[]
}

// load auth identity from local storage
function _loadState(): CategoryState {
  const appRaw = localStorage.getItem(keys.categories)
  if (appRaw !== null) {
    return JSON.parse(appRaw) as CategoryState
  }
  return {
    categories: []
  }
}

const categorySlice = createSlice({
  name: keys.categories,
  initialState: _loadState(),
  reducers: {
    setCategories(state, action: PayloadAction<CategoryItem[]>) {
      state.categories = action.payload
      localStorage.setItem(keys.categories, JSON.stringify(state))
    },
  }
})

export const { setCategories } = categorySlice.actions
export default categorySlice.reducer
