import React from 'react'
import Slider from 'react-slick'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { MasterClassCard } from 'src/components/cards'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  masterCardSlider: {
    width: '100%',
    margin: 'auto',

    '& .slick-arrow': {
      zIndex: '1',
      '&:before': {
        fontSize: '60px',
        color: 'black',
        content: "''"
      }
    },

    '& .slick-list': {
      margin: '0 -12px'
    }
  },
  slider__content: {
    margin: 'auto'
  },

  card: {}
}))

export const MasterClassSlider = ({ masterClassSeries }) => {
  const classes = useStyles()
  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon style={{ color: 'black', width: '70%', height: '70%' }} />
      </div>
    )
  }
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: 'black',
            width: '70%',
            height: '70%',
            transform: 'rotate(180deg)'
          }}
        />
      </div>
    )
  }

  const settings = {
    // dots: true,
    infinite: false,
    // swipeToSlide: true,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  let isSwiping = false
  function updateSwipeState(state) {
    isSwiping = state
  }

  interface MasterClassId {
    id: string
  }

  function handleClick(id: MasterClassId) {
    if (isSwiping) {
      return
    }
    // do something onClick
    // navigate('/watch?v=' + id)
  }

  return (
    <div className={classes.masterCardSlider}>
      <div className={classes.slider__content}>
        <Slider
          {...settings}
          afterChange={() => updateSwipeState(false)}
          onSwipe={() => updateSwipeState(true)}
        >
          {masterClassSeries?.map((v, i) => (
            <div>
              <MasterClassCard
                key={v.id}
                masterClass={v}
                className={classes.card}
                onClick={() => handleClick(v.id)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
