import React from "react";
import { makeStyles } from '@mui/styles'
import { Box, Typography, Theme, Avatar } from "@mui/material";
import Icon from 'src/images/teacherinfo/ico-sign.png'
import picVideo from 'src/images/teacherinfo/pic-video.png'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';
import MaskCoverPlayer from "../../teacherLanding/teacherLanding/MaskCoverPlayer";

const useStyles = makeStyles((theme: Theme) => ({
    rootBox: {
        background: "#F9F9F9",
        padding: "53px 135px 55px 131px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
        }
    },
    textTitle: {
        marginBottom: "37px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "10px",
        }
    },
    textTitle1: {
        fontSize: "18px",
        fontFamily: "Avenir-Book, Avenir",
        fontWeight: "normal",
        color: "#307DCF",
        lineHeight: "30px",
        marginBottom: "23px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "10px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        }
    },
    textTitle2: {
        fontSize: "40px",
        fontFamily: "Avenir-Black, Avenir",
        fontWeight: "900",
        color: "#000000",
        lineHeight: "30px",
        [theme.breakpoints.down('md')]: {
            fontSize: "30px",
            lineHeight: "40px",
        }
    },
    Content: {
        display: "flex",
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    CourseInstructorItem: {
        width: "537px",
        marginBottom: "35px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            marginBottom: "10px",
        },
    },
    ListTitle: {
        display: "flex",
        marginBottom: "14px",
        alignItems: "center",
    },
    ListTitleImage: {
        width: "21px",
        height: "21px"
    },
    ListTitleText: {
        marginLeft: "9px",
        fontSize: "18px",
        fontFamily: "Avenir-Black, Avenir",
        fontWeight: "900",
        color: "#000000",
    },
    ListContent: {
        fontSize: "18px",
        fontFamily: "Avenir-Book, Avenir",
        fontWeight: "normal",
        color: "#555555",
        lineHeight: "30px",
        [theme.breakpoints.down('md')]: {
            fontSize: "14px",
        },
    },
    Video: {
        width: "464px",
        height: "309.39px",
        marginLeft: "39px",
        marginTop: "47px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            height: "100%",
            marginLeft: "0",
            marginTop: "5px"
        }
    }
}))

const TargetingAudience: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const videoUrl = 'https://www.veeko.com/video/30sec_Promo_FineArts-ManuelaSadovnik.mp4';
    const listData = [
        {
            id: "1",
            image: Icon,
            title: "Hands-on art",
            content: "From spreading paint with a fork, to using small pieces of a regular kitchen sponge to paint lilies, we will explore a wide variety of mediums and materials to have fun with art and understand it!"
        },
        {
            id: "2",
            image: Icon,
            title: "Art and its history",
            content: "Learn about the history of the art we’re creating in a fun and conversational way. This is a class where kids can learn about art movements, styles, and artists in a laid-back scenario."
        },
        {
            id: "3",
            image: Icon,
            title: "Open to everyone",
            content: "This class will not only nurture you with knowledge of art history and artistic practices, but it will mainly give you a space of relaxation from your hectic schedules and daily stresses."
        }
    ]
    return (
        <Box className={classes.rootBox}>
            <Box>
                <Box className={classes.textTitle}>
                    <Box className={classes.textTitle1}>Why Choose This Course?</Box>
                    <Box className={classes.textTitle2}>Targeting Audience</Box>
                </Box>
                <Box className={classes.Content}>
                    <Box>
                        {listData.map(item => (
                            <Box className={classes.CourseInstructorItem} key={item.id}>
                                <Box className={classes.ListTitle}>
                                    <img src={item.image} className={classes.ListTitleImage}></img>
                                    <Box className={classes.ListTitleText}>{item.title}</Box>
                                </Box>
                                <Box className={classes.ListContent}>{item.content}</Box>
                            </Box>
                        ))}
                    </Box>
                    <Box className={classes.Video}>
                        <MaskCoverPlayer videoUrl={videoUrl} imageUrl={picVideo} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default TargetingAudience

