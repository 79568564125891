import React from 'react'
// import Slider from 'react-slick'
// import { ClassesCard } from 'src/components/cards/ClassesCard'
import {  CardItem } from 'src/components/cards/StudentViewingCard'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import SliderPagination from '../slider_pagination'
import image_course_default from 'src/images/defaultIcon/pic-course.png'

import { useGetSchoolResourceListQuery } from 'src/app/service/real/learnpage-slice'

import { useGetSeriesListQuery } from 'src/app/service/real/coursedetail-slice';
import { SeriesCard } from 'src/components/cards'
import { SeriesEntity } from '@dokyo/common'

const useStyles = makeStyles(theme => ({
    card: {},
    root: {
        position: 'relative',
        maxWidth: '1300px',
        minWidth: '1024px',
        width: '100%',
        height: '385px',
        display:'flex',
        justifyContent:'center',
        margin: '0 auto'
    },
    slide: {
        minHeight: 100,
        color: '#fff',
    },
    slide1: {
        position:'relative',
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        flexShrink: 0,
        overflow: 'hidden'
        // background:'red'
    },
    slide2: {
        display: 'none'
    },
    dot: {
        bottom: '20px'
    }
}))
const StudentsAreViewingSeries = [
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "discription": "Introducing basic data types string",
        "kind": "Data Analysis",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/GraemeJohnson/Thumbnail/E2.png",
        "tilte": "Colorful Abstract Art",
        "discription": "Ballet is a type of performance dance…",
        "kind": "Fine Arts",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "discription": "Introducing basic data types string",
        "kind": "Data Analysis",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "discription": "Introducing basic data types string",
        "kind": "Data Analysis",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "discription": "Introducing basic data types string",
        "kind": "Data Analysis",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/GraemeJohnson/Thumbnail/E2.png",
        "tilte": "Colorful Abstract Art",
        "discription": "Ballet is a type of performance dance…",
        "kind": "Fine Arts",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "discription": "Introducing basic data types string",
        "kind": "Data Analysis",
    },
    {
        "picture": "https://share.dev.veeko.com/us-east-1/AndrewTrombley/Thumbnail/D4.png",
        "tilte": "Python for Data Analyst",
        "discription": "Introducing basic data types string",
        "kind": "Data Analysis",
    },
]


const areaList = ['', '', '', ''];
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


export const StudentsAreViewingSlider = () => {
    const classes = useStyles()
    const [index, SetIndex] = React.useState<number>(0)
    const [courseList, setCourseList] = React.useState<any[]>([])
    // let configLength1: number[] = []
    const [seriesList, setSeriesList] = React.useState<SeriesEntity[]>([])


    const { data: courseListData } = useGetSeriesListQuery({ 
        pageSize: 8
        }, {
        refetchOnMountOrArgChange: 60
        }
    )

    React.useEffect(() => {
        if (courseListData) {
            if (courseListData.isFailed === false && courseListData?.result?.series_list.length > 0) {
                setSeriesList(courseListData.result.series_list);
            }
        }
    }, [courseListData]);

    const { data: coursedata } = useGetSchoolResourceListQuery({
        request_type: 9,
        forward_order: false,
        excellent: 1,
        page_size: 999,
    })

    React.useEffect(() => {
        if (coursedata && !coursedata.isFailed && coursedata.result?.class_list) {
            handleCourseList(coursedata.result?.class_list)
        }
    }, [coursedata])

    const handleChangeIndex = (index: number) => {
        if (index >= 0) {
            SetIndex(index)
        }
    };

    const handleCourseList = (class_list:any) => {
        const list:any[]=[]
        class_list?.map((t,i)=>{
          if(t.course_id){
            list.push({
              id:t.course_id,
              media_image: t.course_thumbnail || image_course_default,
              content_body: t.course_introduction,
              content_header: t.course_title,
              content_body2:t.category.name,
              type:'course'
             })
           }
        })
        setCourseList(list)
     }

     const dataAreaLength = React.useMemo(() => {
        return seriesList.length > 0? Math.floor(seriesList.length / 4): seriesList.length;
    }, [seriesList]);
    const dotsLength = React.useMemo(() => {
        return seriesList.length > 0? Math.ceil(seriesList.length / 4): seriesList.length;
    }, [seriesList])
    const areaIndexList = [] as number[];
    for(let i = 0; i < dataAreaLength; i++) {
        areaIndexList.push(i)
    }
    let itemIndex = 0;

    return (
        <>
        {
            areaList.length > 0 ? 
            <div className={classes.root}>
                <AutoPlaySwipeableViews
                    index={index}
                    onChangeIndex={handleChangeIndex}
                    interval={10000}
                    enableMouseEvents
                >
                    {areaIndexList.map((v, i) => (
                        <Box className={classes.slide1} key={i}>
                            {
                                areaList.map(() => {
                                    const itemData = seriesList[itemIndex++];
                                    if (itemData) {
                                        return <SeriesCard card={itemData} horizontal={true} key={itemIndex} />;
                                    } else {
                                        return (<></>);
                                    }
                                })
                            }
                        </Box>
                    ))}
                </AutoPlaySwipeableViews>
                {
                    dotsLength > 0 && <SliderPagination
                        dots={dotsLength} 
                        index={index} 
                        onChangeIndex={handleChangeIndex} 
                    />
                }
            </div>:
            ''
        }
        </>
    )
}
