import { useAudioVideo, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { DataMessage } from 'amazon-chime-sdk-js';
import { useEffect, useReducer, createContext, useContext, FC, useCallback } from 'react';
import { ChatMessage } from 'src/app/models';
import { useMeetingState } from '../MeetingStateProvider';
import { DataMessagesActionType, initialState, reducer, DATA_MESSAGE_LIFETIME_MS, DATA_MESSAGE_TOPIC } from './state';

interface DataMessagesStateContextType {
  addMessageLocal: (message: ChatMessage) => void;
  sendMessage: (message: ChatMessage) => void;
  messages: ChatMessage[];
  unReadMessageCount: number;
  onChatTabChange: (on: boolean) => void;
}

const DataMessagesStateContext = createContext<DataMessagesStateContextType | undefined>(undefined);

export const DataMessagesProvider: FC = ({ children }) => {
  const { localUserName } = useMeetingState();
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC, handler);
    return () => {
      audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC);
    };
  }, [audioVideo]);

  const handler = useCallback(
    (dataMessage: DataMessage) => {
      if (!dataMessage.throttled) {
        const isSelf =
          dataMessage.senderAttendeeId === meetingManager.meetingSession?.configuration.credentials?.attendeeId;
        // (() => {
        //   console.log('unReadMessageCount:', unReadMessageCount);
        //   console.log('onChatTab:', onChatTab);
        //   if (!onChatTab) {
        //     setUnReadMessageCount(unReadMessageCount + 1);
        //   }
        // })()
        
        if (isSelf) {
          const data:ChatMessage = dataMessage.json();
          dispatch({
            type: DataMessagesActionType.ADD,
            payload: data,
          });
        } else {
          const messageStr = new TextDecoder("utf-8").decode(dataMessage.data);
          const chatMessage: ChatMessage = JSON.parse(messageStr);
          dispatch({
            type: DataMessagesActionType.ADD,
            payload: chatMessage,
          });
        }
      } else {
        console.warn('DataMessage is throttled. Please resend');
      }
    },
    [meetingManager]
  );

  const addMessageLocal = useCallback(
    (message: ChatMessage) => {
      dispatch({
        type: DataMessagesActionType.ADD,
        payload: message,
      });
    }, [meetingManager]
  )

  const sendMessage = useCallback(
    (message: ChatMessage) => {
      if (
        !meetingManager ||
        !meetingManager.meetingSession ||
        !meetingManager.meetingSession.configuration.credentials ||
        !meetingManager.meetingSession.configuration.credentials.attendeeId ||
        !audioVideo
      ) {
        return;
      }

      audioVideo.realtimeSendDataMessage(DATA_MESSAGE_TOPIC, JSON.stringify(message), DATA_MESSAGE_LIFETIME_MS);
      const senderAttendeeId = meetingManager.meetingSession.configuration.credentials.attendeeId;
      handler(new DataMessage(
        Date.now(),
        DATA_MESSAGE_TOPIC,
        new TextEncoder().encode(JSON.stringify(message)),
        senderAttendeeId,
        localUserName
      ));
    },
    [meetingManager, audioVideo]
  );

  const onChatTabChange = (on: boolean): void => dispatch({
    type: DataMessagesActionType.ChangeTab,
    payload: on,
  }); 

  const value = {
    sendMessage,
    addMessageLocal,
    messages: state.messages,
    unReadMessageCount: state.unReadMessageCount,
    onChatTabChange,
  };
  return <DataMessagesStateContext.Provider value={value}>{children}</DataMessagesStateContext.Provider>;
};

export const useDataMessages = (): DataMessagesStateContextType => {
  const meetingManager = useMeetingManager();
  const context = useContext(DataMessagesStateContext);
  if (!meetingManager || !context) {
    throw new Error(
      'Use useDataMessages hook inside DataMessagesProvider. Wrap DataMessagesProvider under MeetingProvider.'
    );
  }
  return context;
};
