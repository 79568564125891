import { MenuItems, Routes } from '@dokyo/mui-layout'
import {
  ExploreRounded,
  Dashboard,
  AccountBox,
  CardMembership,
  LiveTv,
  LockOpen,
  MonetizationOn,
  Assessment
} from '@mui/icons-material'
import { SchoolEntity } from './app/models/school'
import SvgComponent from './components/svgComponent'

import {
  VEEKO_SCHOOL_ID,
  VICTORIA_SCHOOL_ID,
  VICTORIA_INTERNATIONAL_COLLEGE_SCHOOL_ID,
  VICTORIA_IVY_EXPRESS_SCHOOL_ID
} from 'src/utils/constants';
import { isProdidyAfterschool, isVictoriaSchool } from './utils/deviceUtil';
import { StudioTabValues } from './pages/studio/Studio';
import { TEACHER_TAB_VALUES } from './pages/teacher/teacher';


// define all routing in project
const paths = {
  home: '/',
  login: '/login',
  signin: '/signin',
  signup: '/signup',
  homeSecond: 'home-second',
  homeThird: 'home-third',
  cart: '/cart',
  chat: '/chat',
  coupon:"/coupon",
  PaymentSuccessful:"/PaymentSuccessful",
  cartSuccess: '/cart/success',
  cartPaySuccess: '/cart/pay/success',
  myDashboard: '/me/dashboard',
  myProfile: '/me/profile',
  result: '/result',
  result2: '/result2',
  // belong to Veeko,
  programs: '/programs',
  schedules: '/schedules',
  masters: '/masters',
  notifications: '/notifications',
  discover: '/discover',
  // discoverLive: '/discover/live',
  // discoverSchools: '/discover/schools',
  // discoverCategories: '/discover/categories',
  categories: '/categories',
  teachers: '/teachers',
  seriesLessons: '/seriesLessons',
  studio: '/studio',
  schools: '/schools',
  me: '/me',
  teacher: '/teacher',
  course: '/course',
  Notification:"/privateLesson",
  classDetail: '/classdetail',
  liveBroadcast: '/livebroadcast',
  videoBroadcast: '/videobroadcast',
  videoClassroom: '/videoclassroom',
  privateLesson: '/privateLesson',
  commonOrder: '/commonOrder',
  booking: '/booking',
  seriesLesson: '/seriesLesson',
  seriesLessonClass: '/seriesLessonClass',
  priveteLessonRecordVideo: '/privateLessonRecordVideo',
  seriesLessonRecordVideo: '/seriesLessonRecordVideo',
  privateLessonMaterials: '/privateLessonMaterials',
  seriesLessonMaterials: '/seriesLessonMaterials',
  seriesLessonClassMaterials: '/seriesLessonClassMaterials',
  customPrivateLessons: '/customPrivateLessons',
  veekoHomepage: '/prodigyafterschool',
  veekoHomepage_home: '/prodigyafterschool/home',
  veekoHomepage_classes: '/prodigyafterschool/classes',
  veekoHomepage_classes_afterschool: '/prodigyafterschool/classes/afterschool',
  veekoHomepage_classes_masterclass: '/prodigyafterschool/classes/masterclass',
  veekoHomepage_schedule: '/prodigyafterschool/schedule',
  veekoHomepage_teachers: '/prodigyafterschool/teachers',
  veekoHomepage_teachers_afterschool: '/prodigyafterschool/teachers/afterschool',
  veekoHomepage_teachers_masterclass: '/prodigyafterschool/teachers/masterclass',
  veekoHomepage_teachers_detail: '/prodigyafterschool/teachers/detail',
  veekoHomepage_social: '/prodigyafterschool/social',
  veekoHomepage_blog: '/prodigyafterschool/blog',
  veekoHomepage_blog_detail: '/prodigyafterschool/blogdetail',
  veekoHomepage_blog_guitar: '/prodigyafterschool/blog/beginner-guitar-tuning',
  veekoHomepage_blog_afterschool: '/prodigyafterschool/blog/top-afterschool-classes',
  veekoHomepage_blog_backToSchool: '/prodigyafterschool/blog/back-to-school-tips',
  veekoHomepage_about: '/prodigyafterschool/about',
  checkout: '/checkout',
  ivyExpressHomepage: '/ivyexpress',
  vicCollegeHomepage: '/vicollege',
  victoriaHomepage: '/victoria',
  mobile_veeko_teacher: '/mobile/prodigyafterschool/teachers',
  mobile_veeko_afterschool: '/mobile/prodigyafterschool/afterschool',
  mobile_veeko_masterclass: '/mobile/prodigyafterschool/masterclass',
  schools_all: '/schools/*',
  teacherLanding: '/AboutTeaching',
  followTutorials: '/FollowTheTutorials',
  teacherSignup: '/teachersignup',
}

export const ModulePath: Record<string, string> = {
  "series_lesson": paths.seriesLessonClass,
  "private_lesson": paths.privateLesson,
  "booking": paths.booking,
}

export const SchoolPath = {
  [VEEKO_SCHOOL_ID]: paths.veekoHomepage,
  [VICTORIA_SCHOOL_ID]: paths.victoriaHomepage,
  [VICTORIA_INTERNATIONAL_COLLEGE_SCHOOL_ID]: paths.vicCollegeHomepage,
  [VICTORIA_IVY_EXPRESS_SCHOOL_ID]: paths.ivyExpressHomepage,
}
// define all layouts
const layouts = {
  default: () => import('src/layout/DefaultLayout'),
  plain: () => import('src/layout/PlainLayout')
}

const defaultHomePage = () => import('src/pages/homeFour/index');
const prodigySchoolHomePage = () => import('src/pages/veekoHomepage/index');
const victoriaHomePage = () => import('src/pages/victoriaHomepage/index');

const getHomeComponent = () => {
  if (isProdidyAfterschool()) {
    return prodigySchoolHomePage;
  } else if (isVictoriaSchool()) {
    return victoriaHomePage;
  } else {
    return defaultHomePage;
  }
}
// define all the routing its component
// set "authRequired=true" if the routing required authenication
const routes: Routes = {
  [paths.home]: {
    layout: layouts.default,
    // component: () => import('src/pages/home/index'),
    component: getHomeComponent(),
    children: {
      [paths.login]: {
        component: () => import('src/pages/signinup')
      },
      [paths.signin]: {
        component: () => import('src/pages/signinup')
      },
      [paths.signup]: {
        component: () => import('src/pages/signinup')
      },
      [paths.myDashboard]: {
        siderMenu: 'profile',
        authRequired: true,
        component: () => import('src/pages/profile/Dashboard')
      },
      [paths.myProfile]: {
        siderMenu: 'profile',
        authRequired: true,
        component: () => import('src/pages/profile/MyProfile')
      },
      [paths.result]: {
        component: () => import('src/pages/searchResult')
      },
      [paths.result2]: {
        component: () => import('src/pages/searchResult2')
      },
      // belong to Veeko,
      [paths.programs]: {
        component: () => import('src/pages/programs/index')
      },
      [paths.homeSecond]: {
        component: () => import('src/pages/homeSecond/index')
      },
      [paths.homeThird]: {
        component: () => import('src/pages/homeThird/index')
      },
      [paths.schedules]: {
        component: () => import('src/pages/schedules/index')
      },
      [paths.masters]: {
        component: () => import('src/pages/masters/index')
      },
      [paths.notifications]: {
        component: () => import('src/pages/notifications/index')
      },
      [paths.discover]: {
        component: () => import('src/pages/discover/index')
      },
      [`${paths.discover}/:type/:id`]: {
        component: () => import('src/pages/discover/index')
      },
      // [`${paths.discover}/:sort`]: {
      //   component: () => import('src/pages/discover/index')
      // },
      // [`${paths.discoverLive}/:liveType`]: {
      //   component: () => import('src/pages/discover/index')
      // },
      // [`${paths.discoverSchools}/:schoolId`]: {
      //   component: () => import('src/pages/discover/index')
      // },
      // [`${paths.discoverCategories}/:categoryId`]: {
      //   component: () => import('src/pages/discover/index')
      // },
      [paths.categories]: {
        component: () => import('src/pages/category/index')
      },
      [`${paths.categories}/:type/:id`]: {
        component: () => import('src/pages/category/index')
      },
      [paths.teachers]: {
        component: () => import('src/pages/teachers/index')
      },
      [`${paths.teachers}/:type/:id`]: {
        component: () => import('src/pages/teachers/index')
      },
      [paths.seriesLessons]: {
        component: () => import('src/pages/seriesLessons/index')
      },
      [`${paths.seriesLessons}/:type/:id`]: {
        component: () => import('src/pages/seriesLessons/index')
      },
      [paths.priveteLessonRecordVideo]: {
        component: () => import('src/pages/recordVideo/PrivateLessonRecordVideo')
      },
      [paths.seriesLessonRecordVideo]: {
        component: () => import('src/pages/recordVideo/SeriesLessonRecordVideos')
      },
      [paths.privateLessonMaterials]: {
        component: () => import('src/pages/privateLesson/materials/PrivateLessonMaterialList')
      },
      [paths.seriesLessonMaterials]: {
        component: () => import('src/pages/seriesLesson/materials/SeriesLessonMaterialList')
      },
      [paths.seriesLessonClassMaterials]: {
        component: () => import('src/pages/seriesLesson/class/SeriesLessonClassMaterialList')
      },
      [paths.studio]: {
        component: () => import('src/pages/studio/index')
      },
      [`${paths.studio}/:tabName`]: {
        component: () => import('src/pages/studio/index')
      },
      [`${paths.studio}/:tabName/:subTabName`]: {
        component: () => import('src/pages/studio/index')
      },
      [paths.schools]: {
        component: () => import('src/pages/school/index'),
      },
      [paths.teacher]: {
        component: () => import('src/pages/teacher/index')
      },
      [`${paths.teacher}/:id`]: {
        component: () => import('src/pages/teacher/index')
      },
      [`${paths.teacher}/:id/:tabName`]: {
        component: () => import('src/pages/teacher/index')
      },
      [`${paths.teacher}/:id/:tabName/:subTabName`]: {
        component: () => import('src/pages/teacher/index')
      },
      [paths.course]: {
        component: () => import('src/pages/course/index')
      },
      [paths.classDetail]: {
        component: () => import('src/pages/classdetail/index')
      },
      [paths.liveBroadcast]: {
        component: () => import('src/pages/broadcastLive/index')
      },
      [paths.videoBroadcast]: {
        component: () => import('src/pages/broadcastVideo/index')
      },
      [paths.videoClassroom]: {
        component: () => import('src/pages/videoClassroom/index')
      },
      [paths.privateLesson]: {
        component: () => import('src/pages/privateLesson/index')
      },
      [paths.customPrivateLessons]: {
        component: () => import('src/pages/teacher/customPrivateLesson/index')
      },
      [`${paths.customPrivateLessons}/:id`]: {
        component: () => import('src/pages/teacher/customPrivateLesson/index')
      },
      [paths.commonOrder]: {
        component: () => import('src/pages/order/CommonOrderDetails')
      },
      [paths.booking]: {
        component: () => import('src/pages/booking/MyBookingList')
      },
      [`${paths.booking}/:id`]: {
        component: () => import('src/pages/booking/BookingDetail')
      },
      [paths.seriesLesson]: {
        component: () => import('src/pages/seriesLesson/SeriesLessonDetail')
      },
      [paths.seriesLessonClass]: {
        component: () => import('src/pages/seriesLesson/class/SeriesLessonClassDetail')
      },
      [paths.me]: {
        component: () => import('src/pages/me/index')
      },
      [paths.cart]: {
        component: () => import('src/pages/cart/cartInfo/index')
      },
      [paths.chat]: {
        component: () => import('src/pages/chat/ShowChat')
      },
      [paths.coupon]: {
        component: () => import('src/pages/coupon/index')
      },
      [paths.PaymentSuccessful]: {
        component: () => import('src/pages/PaymentSuccessful/index')
      },
      [paths.cartSuccess]: {
        component: () => import('src/pages/cart/success/index')
      },
      [paths.cartPaySuccess]: {
        component: () => import('src/pages/cart/cartInfo/CartSuccess')
      },
      [paths.veekoHomepage]: {
        component: () => import('src/pages/veekoHomepage')
      },
      [paths.veekoHomepage_home]: {
        component: () => import('src/pages/veekoHomepage')
      },
      [paths.veekoHomepage_classes]: {
        component: () => import('src/pages/veekoHomepage/classes')
      },
      [paths.veekoHomepage_classes_afterschool]: {
        component: () => import('src/pages/veekoHomepage/classes/VeekoClasses')
      },
      [paths.veekoHomepage_classes_masterclass]: {
        component: () => import('src/pages/veekoHomepage/classes/VeekoClasses')
      },
      [paths.veekoHomepage_schedule]: {
        component: () => import('src/pages/veekoHomepage/schedule')
      },
      [paths.veekoHomepage_teachers]: {
        component: () => import('src/pages/veekoHomepage/teachers')
      },
      [paths.mobile_veeko_teacher]: {
        component: () => import('src/mobile/prodigySchool/teacher')
      },
      [paths.veekoHomepage_teachers_afterschool]: {
        component: () => import('src/pages/veekoHomepage/teachers/TeachersAfterschool')
      },
      [paths.mobile_veeko_afterschool]: {
        component: () => import('src/mobile/prodigySchool/teacher/TeacherAfterschool')
      },
      [paths.veekoHomepage_teachers_masterclass]: {
        component: () => import('src/pages/veekoHomepage/teachers/TeachersMasterclass')
      },
      [paths.mobile_veeko_masterclass]: {
        component: () => import('src/mobile/prodigySchool/teacher/TeacherMasterclass')
      },
      [paths.veekoHomepage_teachers_detail]: {
        component: () => import('src/pages/veekoHomepage/teachers/VeekoTeacherDetail')
      },
      [paths.veekoHomepage_social]: {
        component: () => import('src/pages/veekoHomepage/social')
      },
      [paths.veekoHomepage_blog]: {
        component: () => import('src/pages/veekoHomepage/blog')
      },
      [paths.veekoHomepage_blog_guitar]: {
        component: () => import('src/pages/veekoHomepage/blog/detail/VeekoBlogGuitar')
      },
      [paths.veekoHomepage_blog_afterschool]: {
        component: () => import('src/pages/veekoHomepage/blog/detail/VeekoBlogAfterschoolClasses')
      },
      [paths.veekoHomepage_blog_backToSchool]: {
        component: () => import('src/pages/veekoHomepage/blog/detail/VeekoBlogBackToSchool')
      },
      [paths.veekoHomepage_about]: {
        component: () => import('src/pages/veekoHomepage/about')
      },
      [paths.ivyExpressHomepage]: {
        component: () => import('src/pages/ivyExpressHomepage')
      },
      [paths.vicCollegeHomepage]: {
        component: () => import('src/pages/vicCollegeHomepage')
      },
      [paths.victoriaHomepage]: {
        component: () => import('src/pages/victoriaHomepage')
      },
      [paths.schools_all]: {
        component: () => import('src/pages/school/index')
      },
      [paths.teacherLanding]: {
        component: () => import('src/pages/teacherLanding/teacherLanding/index')
      },
      [paths.followTutorials]: {
        component: () => import('src/pages/teacherLanding/followTutorials/index')
      },
      [paths.teacherSignup]: {
        component: () => import('src/pages/teacherLanding/signUp/index')
      },
    }
  },
  [paths.classDetail]: {
    component: () => import('src/pages/classdetail/index')
  },
  [paths.checkout]: {
    layout: layouts.plain,
    component: () => import('src/pages/checkout/index')
  }
}


export const buildDiscoverUrl = (type?: string, id?: string): string => {
  return paths.discover + (type && id ? `/${type}/${id}` : '');
}

export const buildCategoryUrl = (type?: string, id?: string): string => {
  return paths.categories + (type && id ? `/${type}/${id}` : '');
}

export const buildTeacherListUrl = (type?: string, id?: string): string => {
  return paths.teachers + (type && id ? `/${type}/${id}` : '');
}

export const buildTeacherUrl = (teacherId: string, tabName?: string): string => {
  return paths.teacher + (`/${teacherId}/${tabName||''}`);
}

export const buildTeacherScheduleUrl = (teacherId: string, scheduleTabName?: string): string => {
  return paths.teacher + (`/${teacherId}/${TEACHER_TAB_VALUES.SCHEDULE}/${scheduleTabName||'list'}`);
}

export const buildTeacherScheduleListUrl = (teacherId: string, listType?: number): string => {
  return paths.teacher + (`/${teacherId}/${TEACHER_TAB_VALUES.SCHEDULE}/list?listType=${listType||"1"}`);
}

export const buildSeriesLessonUrl = (type?: string, id?: string): string => {
  return paths.seriesLessons + (type && id ? `/${type}/${id}` : '');
}

export const buildBookingDetailUrl = (id: string): string => {
  return paths.booking + `/${id}`;
}

export const buildStudioUrl = (tabName: string): string => {
  return paths.studio + (`/${tabName||''}`);
}

export const buildStudioScheduleUrl = (scheduleTabName?: string): string => {
  return paths.studio + (`/${StudioTabValues.MY_SCHEDULE}/${scheduleTabName||'list'}`);
}

export const buildStudioScheduleListUrl = (listType: number, queryTime: string): string => {
  return paths.studio + (`/${StudioTabValues.MY_SCHEDULE}/list?listType=${listType||"1"}&listTime=${queryTime}`);
}

export interface SchoolSelect {
  school: SchoolEntity;
  action: any;
}

// Defines all menu in the sider menu
const menuItems: MenuItems = {
  // the default sider menu on default layout
  system: [
    {
      path: paths.home,
      key: 'home',
      icon_select: <SvgComponent iconName="menu-home-selected" className="icon-source" />,
      icon_unselect: <SvgComponent iconName="menu-home" className="icon-source" />,
      alwaysShow: true,
      mobileShow: true,
    },
    {
      path: paths.discover,
      key: 'discover',
      icon_select: <SvgComponent iconName="menu-discover-selected" className="icon-source" />,
      icon_unselect: <SvgComponent iconName="menu-discover" className="icon-source" />,
      alwaysShow: true
    },
    {
      //path: paths.schools,
      key: 'schools',
      icon_select: <SvgComponent iconName="menu-school-selected" className="icon-source" />,
      icon_unselect: <SvgComponent iconName="menu-school" className="icon-source" />,
      alwaysShow: true,
      subItems: [
        // {
        //   key: 'victoriaschool',
        //   path: paths.schools,
        //   options: schools[0],
        // },
        // {
        //   key: 'veekoschool',
        //   path: paths.veekoHomepage,
        //   options: schools[1],
        // },
        // {
        //   key: 'internationalcollege',
        //   path: paths.schools,
        //   options: schools[2],
        // },
        // {
        //   key: 'prestigeacademy',
        //   path: paths.schools,
        //   options: schools[3],
        // },
      ]
    },
    {
      path: paths.teachers,
      key: 'teachers',
      icon_select: <SvgComponent iconName="menu-teachers-selected" className="icon-source" />,
      icon_unselect: <SvgComponent iconName="menu-teachers" className="icon-source" />,
      alwaysShow: true,
    },
    {
      path: paths.seriesLessons,
      key: 'seriesLessons',
      icon_select: <SvgComponent iconName="menu-serieslesson-selected" className="icon-source" />,
      icon_unselect: <SvgComponent iconName="menu-serieslesson" className="icon-source" />,
      alwaysShow: true,
    },
    {
      path: paths.categories,
      key: 'category',
      icon_select: <SvgComponent iconName="menu-category-selected" className="icon-source" />,
      icon_unselect: <SvgComponent iconName="menu-category" className="icon-source" />,
      alwaysShow: true,
    },
    {
      path: paths.studio,
      key: 'studio',
      icon_select: <SvgComponent iconName="menu-studio-selected" className="icon-source" />,
      icon_unselect: <SvgComponent iconName="menu-studio" className="icon-source" />,
      alwaysShow: true
    },
    {
      path: paths.me,
      key: 'me',
      icon_select: <SvgComponent iconName="menu-me-selected" className="icon-source" style={{ paddingLeft: '2px' }} />,
      icon_unselect: <SvgComponent iconName="menu-me" className="icon-source" style={{ paddingLeft: '2px' }} />,
      alwaysShow: true,
      mobileShow: true,
    },
  ],
  profile: [
  ]
}

export class appConfig {
  // all paths
  static get paths() {
    return paths
  }
  // resitered components
  static get routes() {
    return routes
  }
  // all sider menu items
  static get menuItems() {
    return menuItems
  }
}
