import React from 'react'
import { useEffect, useState } from 'react'
import { Box, Grid, Theme, Typography } from '@mui/material'
import { makeStyles, styled } from '@mui/styles';
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import { ClassroomListCard } from 'src/components/cards/ClassroomListCard'
import pic_live from 'src/images/defaultIcon/pic-live.png'
import { useTranslation } from 'react-i18next'
import { Loader } from 'src/components/loader'
import { useNavigate } from 'react-router-dom'
import noLive from 'src/images/teacher/noLive.png'
import { BroadcastEntity, BroadcastList } from '@dokyo/common';
import { useGetBroadcastStateMutation } from 'src/app/service/real/learnpage-slice';
import { LiveInfo } from './teacher';
import { isMobileDevice } from 'src/utils/deviceUtil';

const useStyles = makeStyles((theme:Theme) => ({
  group: {
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexFlow:'wrap',
    marginTop:'30px'
  },
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'left',
    padding: '0px 50px 50px 50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
}))

const SectionTitle = styled(Typography)({
    zIndex: 77,
    height: '66px',
    display: 'block',
    overflowWrap: 'break-word',
    color: 'rgba(0, 0, 0, 1)',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    alignSelf: 'center',
  })

const transFormEntity = (m: BroadcastEntity) : ClassroomListCardEntity => {
  return {
    id:m.broadcast_id,
    media_image: m.broadcast_cover || pic_live,
    class:m.category.name,
    content_header: m.broadcast_title,
    time: m.broadcast_start_time,
    teacherId: m.teacher.user_id,
    teacherName: m.teacher?.user_name,
    teacherAvatar: m.teacher?.user_avatar,
    fees: m.broadcast_fees,
  }
}

const transFormEntityList = (list: BroadcastEntity[]) : ClassroomListCardEntity[] => {
  if (!list || list.length === 0) {
    return []
  }
  return list.map(m => transFormEntity(m));
}

interface Props {
  liveInfo: LiveInfo
}

export const TeacherLive: React.FC<Props> = ({liveInfo}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  
  const [upcomingliveList, setUpcomingLiveList] = useState<ClassroomListCardEntity[]>([])
  const [liveNowList, setLiveNowList] = useState<ClassroomListCardEntity[]>([])
  const [endedList, setEndedList] = useState<ClassroomListCardEntity[]>([])
  const [getBroadcastState] = useGetBroadcastStateMutation();

  useEffect(() => {
    if (liveInfo) {
      handleLiveList(liveInfo)
    }
  }, [liveInfo]);

  const handleLiveList = (liveInfo: LiveInfo)=>{
    const listUpcoming: BroadcastEntity[] = liveInfo.upcoming;
    const listNow: BroadcastEntity[] = liveInfo.liveNow;
    const listEnded: BroadcastEntity[] = liveInfo.ended;
   
    handleLiveNowListRealState(listNow);
    setUpcomingLiveList(transFormEntityList(listUpcoming))
    setEndedList(transFormEntityList(listEnded))
    setLoading(false)
  }

  const handleLiveNowListRealState = (listNow: BroadcastEntity[]) => {
    if (!listNow || listNow.length === 0) {
      return;
    }
    
    // query the real live status 
    const queryList: BroadcastList[] = [];
    listNow.forEach(item => {
      queryList.push({
        broadcast_id: item.broadcast_id ||"",
        user_id: item.teacher.user_id || "",
      })
    })
    getBroadcastState({list: queryList})
    .unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        const resultList : BroadcastEntity[] = res.result.broadcast_list;
        if (resultList?.length > 0) {
          const filterNowList = resultList.filter(item => item.broadcast_channel?.state === 1);
          setLiveNowList(transFormEntityList(filterNowList))
        } else {
          setLiveNowList(transFormEntityList(listNow))
        }
      } else {
        setLiveNowList(transFormEntityList(listNow))
      }
    })
  }
  
  const handleClick = (id:string) => {
    navigate("/livebroadcast?id=" + id)
  }

  return (
  <>
  <Loader isLoading={isLoading}></Loader>
  <Box className={classes.container}>
    {liveNowList.length > 0 &&
    <Box>
      <SectionTitle 
        style={{
            fontSize: '30px',
            letterSpacing: '-0.7235293388366699px',
            fontFamily: 'Arial-BoldMT',
            lineHeight: '66px',
            marginTop: "20px",
            marginLeft: "55px",
        }}
      >
        {t("class.liveNow")}
      </SectionTitle>
      <Grid
        spacing={0}
        justifyContent="left"
        alignItems="center"
        >
        <Box className={classes.group}>
        {liveNowList?.map((v, i) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} display="flex" justifyContent="center" >
            <Box onClick={(e) => {handleClick(v.id || '')}}>
              <ClassroomListCard
              key={Math.random()}
              card={v}
              label={'Live'}
              />
            </Box>
          </Grid>
        ))}
        </Box>
      </Grid>
    </Box>
    }

    {upcomingliveList.length > 0 &&
    <Box>
      <SectionTitle 
        style={{
            fontSize: '30px',
            letterSpacing: '-0.7235293388366699px',
            fontFamily: 'Arial-BoldMT',
            lineHeight: '66px',
            marginTop: "20px",
            marginLeft: "55px",
        }}
      >
        {t("class.upcoming")}
      </SectionTitle>
      <Grid
        spacing={0}
        justifyContent="left"
        alignItems="center"
        >
        <Box className={classes.group}>
        {upcomingliveList?.map((v, i) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} display="flex" justifyContent="center" >
            <Box onClick={(e) => {handleClick(v.id || '')}}>
              <ClassroomListCard
              key={Math.random()}
              card={v}
              />
            </Box>
          </Grid>
        ))}
        </Box>
      </Grid>
    </Box>
    }

    {/* {endedList.length !== 0 &&
    <Box>
      <Box
        sx={{
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center'
        }}
      >
        <SectionTitle 
          style={{
            fontSize: '30px',
            letterSpacing: '-0.7235293388366699px',
            fontFamily: 'Arial-BoldMT',
            lineHeight: '66px',
            marginTop: "20px",
            marginLeft: "55px",
          }}
        >
          {t("class.ended")}
        </SectionTitle>
      </Box>
      <Grid
        spacing={0}
        justifyContent="left"
        alignItems="center"
      >
        <Box className={classes.group}>
        {endedList?.map((v, i) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} display="flex" justifyContent="center" >
            <ClassroomListCard
              onClick={(e) => {handleClick(v.id || '')}}
              key={Math.random()}
              card={v}
            />
          </Grid>
        ))}
        </Box>
      </Grid>
    </Box>
    } */}

    {
      upcomingliveList.length === 0 && liveNowList.length === 0 && 
      <Box
        sx={{
          position: 'relative',
          left: '50%',
          top: '100px',
          height: '300px',
          width: '300px',
          marginLeft: '-150px',
          textAlign: 'center',
        }}
      >
        <img src={noLive}></img>
        <Typography
          sx={{
            fontSize: '18px',
            fontFamily: 'ArialMT',
            color: 'rgba(0, 0, 0, 1)',
            lineHeight: '21px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop:'10px'
          }}
        >No live now and upcoming live </Typography>
      </Box>
      }
    </Box>
    </>
  )
}
