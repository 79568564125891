import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircleRounded';
import { useUploadFileMutation } from 'src/app/service/real/teacher.slice';
import { getLegalFileName } from 'src/utils/stringUtil';
import { FileEntity, FileStatus } from '@dokyo/common';
import SvgComponent from 'src/components/svgComponent'

const useStyles = makeStyles(theme => ({
  boxRoot: {
  },
}))

export enum UsageType {
  LIVE = "live",
  MEETING = 'meeting',
  CHAT = 'chat',
}
interface Props {
  usage: UsageType;
  closeChat: boolean;
  onSendFailed: (message: string, isError: boolean) => void;
  onFileSelected: (entity: FileEntity) => void;
  onFileUploaded: (entity: FileEntity) => void;
}

export const FilePickerButton: React.FC<Props> = ({usage, closeChat, onSendFailed, onFileSelected, onFileUploaded}) => {
  const classes = useStyles()
  const fileInput = React.useRef<any>();

  const [uploadFile] = useUploadFileMutation();

  const handleClickSelectFile = () => {
    if (closeChat) {
      onSendFailed("Chat has been closed", false)
      return
    }
    fileInput.current.click()
  }

  const handleFileChange = (event) => {
    const fileObj = event.target.files[0];
    if (fileObj) {
      let url = '';
      if (window.URL !== undefined) {
        url = window.URL.createObjectURL(fileObj);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(fileObj);
      }

      const entity: FileEntity = {
        fileObj: fileObj,
        fileName: fileObj.name,
        filePath: url,
        fileSize: fileObj.size,
        fileType: fileObj.type,
        fileStatus: FileStatus.UPLOADING
      }
      onFileSelected(entity)

      handleUploadFile(entity)
    }
  }

  const handleUploadFile = async (entity: FileEntity) => {
    const formatAvatarName = getLegalFileName(entity.fileName);
    uploadFile({file_name: formatAvatarName, file_type: entity.fileType})
    .unwrap()
    .then(res => {
      if (res && !res.isFailed && res.result) {
        const uploadUrl = res.result.upload;
        if (uploadUrl) {
          entity.publicUrl = res.result.public;
          doUpload(entity, uploadUrl);
        }
      } else {
        onSendFailed("upload file failed", true)
      }
    })
    
  }

  const doUpload = async (entity: FileEntity, uploadUrl: string) => {
    if (!entity.publicUrl) {
      onSendFailed("Can not upload file", true)
      return;
    }
    const response2 = await fetch(uploadUrl,{
      method: "PUT",
      body: entity.fileObj,
      headers: {
          "Content-Type" : entity.fileType
      },
    });     
    if(response2.status === 200){
      entity.fileStatus = FileStatus.UPLOADED;
      onFileUploaded(entity);
    } else {
      onSendFailed('Send file failed!', true)
    }
  }

  return (
    <Box className={classes.boxRoot}>
      <IconButton onClick={()=>handleClickSelectFile()} disabled={closeChat}>
        {(usage === UsageType.LIVE || usage === UsageType.CHAT) && <SvgComponent width={"22px"} height={"22px"} iconName={ "icon-select-file"} />}
        {usage === UsageType.MEETING && <AddCircleIcon style={{color: "#fff", width: "48px", height: "48px" }}/> }
      </IconButton> 

      <input
        ref={fileInput} 
        onChange={(e)=>handleFileChange(e)}
        type="file"
        style={{ display: 'none' }} 
      />
    </Box>
  )
}
