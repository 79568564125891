import { Box, Button, Divider, MenuItem, Select, styled, TextField, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import { useSelector } from 'src/app/toolkit/store'
import { getTimeAreaDesc, getTimeInWordDescription } from 'src/utils/timeUtil'
import IconSelectTime from 'src/images/teacher/ic_lable_select.png';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppointmentItem, BookStatusEnum, PrivateLessonTypeEnum, ScheduleEnum, ScheduleLessonTypeEnum, TeacherPrivateLesson, TimeFee } from 'src/app/models/schedule'
import { getDisplayUserName } from 'src/utils/stringUtil'
import { useSnackbar } from 'notistack'
import IconPaid from 'src/images/class/bg-label-paid.png'
import IconUnPaid from 'src/images/class/bg-label-unpaid.png'
import IconAvailable from 'src/images/class/bg-label-availeble.png'
import RefundAlertDialog from 'src/pages/seriesLesson/dialog/RefundAlertDialog'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import NP from "number-precision";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'


const useStyles = makeStyles((theme: Theme) => ({
  rootBox: {
  },
  lineItemTitle: {
    marginRight: "8px",
    fontSize: '14px',
    fontFamily: 'ArialMT',
    width: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '70px',
      fontSize: '12px !important',
    }
  },
  couponBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '180px',
    }
  },
  titleText: {
    fontSize: '14px',
    fontFamily: 'ArialMT',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    }
  },
  dateText: {
    fontSize: '16px',
    fontFamily: 'Arial-BoldMT, Arial',
    color: '#307DCF',
  },
  timeSelect: {
    width: '300px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '180px',
      height: '30px',
      fontSize: '12px !important',
    }
  },
  timeSelectItem: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    }
  },
  couponSelect: {
    width: '300px',
    height: '40px',
    marginRight: "24px",
    [theme.breakpoints.down('sm')]: {
      width: '170px !important',
    }
  },
  selectButton: {
    color: '#307DCF !important',
    border: '1px solid #307DCF !important',
    '&.Mui-disabled': {
      color: '#DDDDDD !important',
      borderColor: '#EEEEEE !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      fontSize: '12px !important',
    }
  },
  unselectButton: {
    color: '#000000 !important',
    borderColor: '#DDDDDD !important',
    '&.Mui-disabled': {
      color: '#DDDDDD !important',
      borderColor: '#EEEEEE !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      fontSize: '12px !important',
    }
  },
  priceText: {
    fontSize: '24px !important',
    fontWeight: 'bold',
    color: '#F93B57',
    fontFamily: 'Arial-BoldMT, Arial',
  },
  noteField: {
    fontSize: '14px',
    color: "#555555",
    fontFamily: "ArialMT",
    width: '300px',
  },
  mobileNoteField: {
    width: '180px !important',
    '& .MuiOutlinedInput-root': {
      fontSize: '12px !important',
      padding: "10px 14px !important"
    }
  },
  couponCode: {
    color: "#555555",
    width: '300px !important',
    [theme.breakpoints.down('sm')]: {
      width: '180px !important',
      '& .MuiOutlinedInput-root': {
        fontSize: '12px !important',
        padding: "0px !important"
      }
    }
  },
  BookBtn: {
    textTransform: "none",
    background: "#307DCF",
    color: 'white',
    fontFamily: 'ArialMT',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: '500 !important',
      width: "111px",
      height: "30px",
      fontSize: "10px !important",
      padding: "0 !important",
    }
  },
  viewDetailsBtn: {
    marginLeft: '130px !important',
    background: "#307DCF !important",
    color: 'white !important',
    fontFamily: 'ArialMT !important',
    fontWeight: 'bold !important',
    boxShadow: 'none !important',
    borderRadius: '8px !important',
    [theme.breakpoints.down('sm')]: {
      width: "118px",
      height: "30px",
      fontSize: "12px !important",
    }
  },
  cancelBookBtn: {
    marginLeft: '20px !important',
    color: '#307DCF !important',
    fontFamily: 'ArialMT !important',
    fontWeight: 'bold !important',
    boxShadow: 'none !important',
    borderRadius: '8px !important',
  },
  bookStatusText: {
    fontSize: '10px',
    fontFamily: 'Arial-BoldMT, Arial',
    color: 'white',
    width: '75px',
    height: '26px',
    textAlign: 'center',
    paddingTop: '6px',
    paddingLeft: '10px',
    position: 'absolute',
    right: '-20px',
    [theme.breakpoints.down('sm')]: {
      top: "-38px",
      right: '-10px',
    }
  },
}))

const PopoverItem = styled(Typography)(({ theme }) => ({
  height: '18px',
  lineHeight: '18px',
  fontSize: '16px',
  color: '#555555',
  [theme.breakpoints.down("sm")]: {
    fontSize: '12px'
  },
}))

const PopoverItemBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '17px',
  [theme.breakpoints.down("sm")]: {
    marginBottom: '10px',
  },
}))

interface Props {
  coupon?: Array<object>,
  scheduleType: PrivateLessonTypeEnum
  groupFee: number,
  startDate: Date
  endDate: Date
  title: string
  privateLesson: TeacherPrivateLesson | undefined;
  onClickBook: (startTime: number, timeLength: number, price: number, note: string, coupon?: string) => void;
  selectCoupon: Function
  currentCouponCode: Function
}

const getStartTimeChoices = (startDate: Date, endDate: Date) => {
  const choices: number[] = [];
  let time = startDate.getTime();
  while ((time + 30 * 60 * 1000) <= endDate.getTime()) {
    choices.push(time);
    time += 30 * 60 * 1000;// 30 mins
  }
  return choices;
}

export const StudentBookLayout: React.FC<Props> = (props) => {
  const { scheduleType, groupFee, startDate, endDate, title, privateLesson, onClickBook, coupon, selectCoupon, currentCouponCode } = props;
  const classes = useStyles()
  const { credential } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [newComment, setNewComment] = React.useState("");
  const [startTime, setStartTime] = React.useState(startDate.getTime());
  const startTimeChoices = getStartTimeChoices(startDate, endDate);
  const [availablePeiods, setAvailablePeriods] = React.useState<TimeFee[] | undefined>([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState<number>(0);
  const [selectPrice, setSelectPrice] = React.useState(0);
  const [isShowCoupon, setIsShowCoupon] = React.useState<boolean>(true)//支付方式切换
  const [couponList, setcouponList] = React.useState<any>(coupon || [])//用户全部的coupon列表
  const [selectedCoupon, setSelectedCoupon] = React.useState<string>("")//用户选择的coupon
  const [text, setText] = React.useState('');
  const [showAlertRefundDialog, setShowAlertRefundDialog] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const newAvailablePeriods = privateLesson?.time_fees?.filter(item => item.time * 60 * 1000 + startTime <= endDate.getTime());
    setAvailablePeriods(newAvailablePeriods);
    setSelectedTimePeriod(0)
    setSelectPrice(0);
  }, [startTime])

  const handleCommentChange = (event) => {
    const newText = event.target.value;
    if (newText.length > 1000) {
      enqueueSnackbar('The maximum length of the note is 1000')
    } else {
      setNewComment(newText);
    }
  }

  const handleClickInput = () => {
    if (!credential) {
      dispatch(showSignInUpDialog())
    }
  }

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
  }
  const handleCouponChange = (event) => {
    // console.log("下拉选择的coupon:", event.target.value)
    const currentCoupon = event.target.value
    setSelectedCoupon(currentCoupon)
  }
  const inputCouponChange = (event) => {
    const currentInputCoupon = event.target.value
    setSelectedCoupon(currentInputCoupon)
  }
  // const debounceSetText = ((event: React.ChangeEvent<HTMLInputElement>) => {
  //   setText(event.target.value);
  // }, 500)

  const handleClickBook = (checkTime: boolean) => {
    // currentCouponCode(selectedCoupon)
    // console.log("当前的coupon：", selectedCoupon)
    if (!privateLesson || !privateLesson?.time_fees || privateLesson?.time_fees?.length === 0) {
      enqueueSnackbar('This teacher has not set private lesson time and fee')
      return
    }
    if (scheduleType === PrivateLessonTypeEnum.Single) {
      if (selectedTimePeriod === 0) {
        enqueueSnackbar('Please select time length')
        return
      }
    }
    if (isShowCoupon && !selectedCoupon) {
      enqueueSnackbar('Please enter or select coupon')
      return
    }
    const price = scheduleType === PrivateLessonTypeEnum.Group ? groupFee : selectPrice;
    const paramStartTime = scheduleType === PrivateLessonTypeEnum.Group ? startDate.getTime() : startTime;
    if (checkTime && paramStartTime - new Date().getTime() < 24 * 60 * 60 * 1000) {
      setShowAlertRefundDialog(true);
      return;
    }
    onClickBook(paramStartTime, selectedTimePeriod, price, newComment, selectedCoupon);
  }

  const handleTimePeriodSelect = (index: number) => {
    const timeFees = privateLesson?.time_fees;
    if (timeFees) {
      const selectTimeFee = timeFees[index];
      setSelectedTimePeriod(selectTimeFee.time);
      const find = timeFees.find(item => item.time === selectTimeFee.time);
      if (find) {
        setSelectPrice(find.fee);
      }
    }
  }
  const stripePayment = () => {
    setSelectedCoupon("");
    setIsShowCoupon(false)
    selectCoupon(false)
  }
  const couponPayment = () => {
    setIsShowCoupon(true)
    selectCoupon(true)

  }

  return (
    <Box className={classes.rootBox} >
      {/* <PopoverItemBox>
        <Typography className={classes.lineItemTitle}>Date</Typography>
        <Typography className={classes.dateText}>{getDateAndWeekDayStr(startDate)}</Typography>
      </PopoverItemBox> */}
      {scheduleType === PrivateLessonTypeEnum.Single ?
        <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Start Time</Typography>
          <Select
            variant='outlined'
            className={classes.timeSelect}
            value={startTime}
            onChange={(event) => handleStartTimeChange(event)}>
            {[...startTimeChoices].map((time, index) => (
              <MenuItem
                key={time}
                value={time}
                className={classes.timeSelectItem}>{getTimeInWordDescription(time)}</MenuItem>
            ))}
          </Select>
        </PopoverItemBox>
        :
        <Box>
          <PopoverItemBox>
            <Typography className={classes.lineItemTitle}>Time</Typography>
            <Typography className={classes.titleText}>{`${getTimeInWordDescription(startDate.getTime())} ~ ${getTimeInWordDescription(endDate.getTime())}`}</Typography>
          </PopoverItemBox>

          {/* <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Limit Student</Typography>
          <Typography className={classes.titleText}>{`min: 0, max: 1`}</Typography>
        </PopoverItemBox> */}
        </Box>
      }
      <PopoverItemBox>
        <Typography className={classes.lineItemTitle}>Title</Typography>
        <Typography className={classes.titleText}>{title}</Typography>
      </PopoverItemBox>

      {scheduleType === PrivateLessonTypeEnum.Single &&
        <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Length</Typography>
          <Box className={classes.couponBox} style={{ flexWrap: 'wrap' }}>
            {privateLesson?.time_fees && [...(privateLesson?.time_fees)].map((item, index) => (
              <Box key={item.time} sx={{ position: "relative", margin: '0 5px 5px 0' }}>
                <Button
                  disabled={!availablePeiods?.includes(item)}
                  variant='outlined'
                  onClick={() => handleTimePeriodSelect(index)}
                  className={item.time === selectedTimePeriod ? classes.selectButton : classes.unselectButton}
                  sx={{ textTransform: 'none', padding: isMobile ? "5px 12px" : "" }}
                >
                  {`${item.time} mins`}
                </Button>
                {item.time === selectedTimePeriod && <img alt='selected_time' src={IconSelectTime} style={{ position: "absolute", bottom: 0, right: 0 }} />}
              </Box>
            ))}
          </Box>
        </PopoverItemBox>
      }
      <PopoverItemBox>
        <Typography className={classes.lineItemTitle}>Note</Typography>
        {isMobile ?
          <TextField
            placeholder="write a sentence"
            value={newComment}
            onChange={handleCommentChange}
            multiline
            maxRows={2}
            className={classes.mobileNoteField}
          />
          :
          <TextField
            placeholder='Anything you want teacher to know'
            value={newComment}
            onChange={handleCommentChange}
            multiline
            maxRows={3}
            className={classes.noteField}
          />}

      </PopoverItemBox>

      {/* 支付方式 */}
      <PopoverItemBox>
        <Typography className={classes.lineItemTitle} >Payment</Typography>
        <Box className={classes.couponBox}>
          <Box style={{ position: "relative" }}>
            <Button
              variant='outlined'
              className={isShowCoupon ? classes.selectButton : classes.unselectButton}
              sx={{ textTransform: 'none', minWidth: isMobile ? "80px" : "144px" }}
              onClick={couponPayment}
            >coupon</Button>
            {isShowCoupon && <img alt='selected_time' src={IconSelectTime} style={{ position: "absolute", bottom: 0, right: 0 }} />}
          </Box>
          <Box style={{ position: "relative", marginLeft: "10px" }}>
            <Button
              variant='outlined'
              className={isShowCoupon ? classes.unselectButton : classes.selectButton}
              sx={{ textTransform: 'none', minWidth: isMobile ? "80px" : "144px" }}
              onClick={stripePayment}
            >stripe</Button>
            {!isShowCoupon && <img alt='selected_time' src={IconSelectTime} style={{ position: "absolute", bottom: 0, right: 0 }} />}
          </Box>
        </Box>
      </PopoverItemBox>

      {/* coupon */}
      {isShowCoupon ?
        <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Coupon</Typography>
          {
            couponList?.length > 0 ?
              <Select
                placeholder="please choose"
                variant='outlined'
                className={classes.couponSelect}
                onChange={handleCouponChange}
              >
                {couponList.map(item => (
                  <MenuItem key={item.coupon} value={item.coupon}>{item.coupon}</MenuItem>
                ))}
              </Select>
              :
              <TextField
                placeholder="Input coupon code"
                className={classes.couponCode}
                onChange={inputCouponChange}
                size="small"
              />
          }
        </PopoverItemBox>
        : ""}

      <PopoverItemBox sx={{ justifyContent: 'space-between' }}>
        <Box style={{ width: "1px" }}>
          {!isShowCoupon &&
            <Typography className={classes.priceText}>{`$${scheduleType === PrivateLessonTypeEnum.Group ? groupFee : NP.divide(selectPrice, 100)}`}</Typography>
          }
        </Box>
        <Button
          onClick={() => handleClickBook(true)}
          className={classes.BookBtn}
          variant="contained">
          Book And Pay
        </Button>
      </PopoverItemBox>

      <RefundAlertDialog
        open={showAlertRefundDialog}
        onClickClose={() => setShowAlertRefundDialog(false)}
        onClickConfirm={() => { setShowAlertRefundDialog(false); handleClickBook(false) }} />
    </Box>
  )

}

export const TeacherAvailableTimeLayout: React.FC<{ startDate: Date, endDate: Date, title: string, privateLessonScheduleType: PrivateLessonTypeEnum, isSecondTeacher: boolean, onClickDelete: () => void }> =
  ({ startDate, endDate, title, privateLessonScheduleType, isSecondTeacher, onClickDelete }) => {
    const classes = useStyles()

    return (
      <Box className={classes.rootBox}>
        <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Time</Typography>
          <PopoverItem>
            {getTimeAreaDesc(startDate, endDate)}
          </PopoverItem>
        </PopoverItemBox>
        <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Title</Typography>
          <PopoverItem>
            {title}
          </PopoverItem>
        </PopoverItemBox>
        <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Type</Typography>
          <PopoverItem>
            {privateLessonScheduleType === PrivateLessonTypeEnum.Group ? "Group Lesson" : "Private Lesson"}
          </PopoverItem>
        </PopoverItemBox>
        
        {!isSecondTeacher && <Divider />}
        {!isSecondTeacher &&
        <Button
          onClick={onClickDelete}
          sx={{
            float: 'right',
            marginTop: '20px',
            textTransform: "none",
            color: "#FF004E",
            fontFamily: 'ArialMT',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
          variant="text">
          <DeleteIcon />
          Delete
        </Button>
        }
      </Box>
    )
  }

export const getBookTimeStatusDesc = (status: number | undefined) => {
  let result = "";
  switch (status) {
    case BookStatusEnum.AVAILABLE_TIME:
      result = "Available";
      break;
    case BookStatusEnum.UNPAID:
      result = "Unpaid";
      break;
    case BookStatusEnum.PAID:
      result = "Scheduled";
      break;
    case BookStatusEnum.EXPIRED:
      result = "Expired";
      break;
    case BookStatusEnum.CANCELED:
      result = "Canceled";
      break;
    case BookStatusEnum.CLOSED:
      result = "Ended";
      break;
    default:
      break;
  }
  return result;
}

const getBookStatusBg = (status: BookStatusEnum) => {
  if (status === BookStatusEnum.AVAILABLE_TIME) {
    return `url(${IconAvailable})`;
  } else if (status === BookStatusEnum.PAID) {
    return `url(${IconPaid})`;
  } else {
    return `url(${IconUnPaid})`;
  }
}

export const BookedTimeLayout: React.FC<{
  showItem: AppointmentItem,
  onClickViewDetails: () => void,
  onClickContinuePayment: (teacherId: string, bookId: string, price: number) => void;
}> =
  ({ showItem, onClickViewDetails, onClickContinuePayment }) => {
    const classes = useStyles()
    const navigate = useNavigate();
    const { credential } = useSelector(state => state.app);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickPayment = () => {
      if (!showItem.bookFee) {
        enqueueSnackbar('Can not get fee')
        return
      }
      onClickContinuePayment(showItem.teacherId, showItem.id, showItem.bookFee)
    }

    return (
      <Box className={classes.rootBox}>

        <Box style={{ position: 'relative', display: 'flex' }} >
          <PopoverItemBox>
            <Typography className={classes.lineItemTitle}>Time</Typography>
            <PopoverItem>
              {getTimeAreaDesc(showItem.startDate, showItem.endDate)}
            </PopoverItem>
          </PopoverItemBox>

          {showItem.schedule !== ScheduleEnum.StudentSideBookedSeriesLesson && showItem.schedule !== ScheduleEnum.TeacherSideSeriesLesson &&
          showItem.lessonType !== ScheduleLessonTypeEnum.CustomPrivateLesson &&
            <Box className={classes.bookStatusText} style={{ backgroundImage: getBookStatusBg(showItem.bookStatus || 0), backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              {getBookTimeStatusDesc(showItem.bookStatus)}
            </Box>
          }

        </Box>
        <PopoverItemBox>
          <Typography className={classes.lineItemTitle}>Title</Typography>
          <PopoverItem>
            {showItem.title}
          </PopoverItem>
        </PopoverItemBox>

        {((showItem.booked && showItem.schedule === ScheduleEnum.StudentSideBookedSeriesLesson)
          || showItem.schedule === ScheduleEnum.TeacherSideSeriesLesson) &&
          <PopoverItemBox>
            <Typography className={classes.lineItemTitle}>Teacher</Typography>
            <PopoverItem>
              {getDisplayUserName(showItem.teacherName, showItem.teacherId)}
            </PopoverItem>
          </PopoverItemBox>
        }

        {!!showItem.bookTimeLength &&
          <PopoverItemBox>
            <Typography className={classes.lineItemTitle}>Length</Typography>
            <PopoverItem>
              {`${showItem.bookTimeLength ?? 'unknown'} mins`}
            </PopoverItem>
          </PopoverItemBox>
        }
        {credential && (credential?.id === showItem.teacherId || showItem.bookList?.some(item => item.user.id === credential?.id))
          && (showItem.lessonType === ScheduleLessonTypeEnum.OneToOneLesson || showItem.bookStatus === BookStatusEnum.UNPAID) &&
          <Box>
            <PopoverItemBox>
              <Typography className={classes.lineItemTitle}>Booker</Typography>
              <PopoverItem>
                {showItem.bookList && showItem.bookList.length > 0 ? getDisplayUserName(showItem.bookList[0].user.name || '', showItem.bookList[0].user.id || '') : ''}
              </PopoverItem>
            </PopoverItemBox>
            {/* <PopoverItemBox>
              <Typography className={classes.lineItemTitle}>Note</Typography>
              <PopoverItem>
                {showItem.bookList && showItem.bookList.length > 0 ? showItem.bookList[0].message : ''}
              </PopoverItem>
            </PopoverItemBox> */}
          </Box>
        }

        <Box sx={{ display: 'flex' }}>
          {(!credential || (credential.id !== showItem.teacherId && !showItem.secondTeachers?.some(item => item.id === credential?.id) && !showItem.bookList?.some(item => item.user.id === credential.id)))
            && showItem.schedule === ScheduleEnum.TeacherSideSeriesLesson &&
            <Button
              onClick={() => navigate(appConfig.paths.seriesLesson + "?id=" + showItem.id)}
              className={classes.viewDetailsBtn}
              sx={{ textTransform: 'none' }}
              variant="contained">
              Book
            </Button>
          }

          {(showItem.schedule === ScheduleEnum.StudentSideBookedSeriesLesson || showItem.schedule === ScheduleEnum.TeacherSideSeriesLesson)
            && credential && (credential?.id === showItem.teacherId || showItem.secondTeachers?.some(item => item.id === credential?.id) || showItem.bookList?.some(item => item.user.id === credential?.id)) &&
            <Button
              onClick={onClickViewDetails}
              className={classes.viewDetailsBtn}
              sx={{ textTransform: 'none' }}
              variant="contained">
              View Details
            </Button>
          }

          {showItem.schedule !== ScheduleEnum.StudentSideBookedSeriesLesson && showItem.schedule !== ScheduleEnum.TeacherSideSeriesLesson
            && credential && (credential?.id === showItem.teacherId || showItem.secondTeachers?.some(item => item.id === credential?.id) || showItem.bookList?.some(item => item.user.id === credential?.id)) && showItem.classRoomId &&
            <Button
              onClick={onClickViewDetails}
              className={classes.viewDetailsBtn}
              sx={{ textTransform: 'none' }}
              variant="contained">
              View Details
            </Button>
          }

          {showItem.schedule !== ScheduleEnum.StudentSideBookedSeriesLesson
            && credential && showItem.bookList?.some(item => item.user.id === credential?.id) && showItem.bookStatus === BookStatusEnum.UNPAID &&
            <Button
              onClick={handleClickPayment}
              className={classes.viewDetailsBtn}
              sx={{ textTransform: 'none' }}
              variant="contained">
              Continue Payment
            </Button>
          }

        </Box>
      </Box>
    )
  }