import React from 'react'
import { makeStyles } from '@mui/styles'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import SliderPagination from '../../../components/slider_pagination';
import { Avatar, Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  slide: {
    minHeight: 500,
    color: '#fff',
  },
}))

interface Header {
  title:string,
  discription:string,
  picture:string
}

interface Bar {
  title:string,
  discription:string
}

interface TopBannerSliderprops {
  config:{
    version:string,
    Headers:Header[],
    Bars:Bar[]
  },
  dots:number
}
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const AdsSlider:React.FC<TopBannerSliderprops> = ({config, dots,...props}) => {
  const classes = useStyles()
  
  const [index,SetIndex] = React.useState(0)

  const handleChangeIndex = (index:number) => {
    SetIndex(index)
  };

  return (
    <Box>
      <AutoPlaySwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
        interval={3000}
        >
        {
          config.Headers.map((header,index) => (
          <Box className={classes.slide}>
            <Avatar src={header.picture} variant='square' style={{width: "100%", height: "100%"}}/>
          </Box> 
          ))
        }
        </AutoPlaySwipeableViews>
      {
        dots > 1 && <Box style={{display:"flex", justifyContent:"center", position:"relative"}}><SliderPagination dots={dots} index={index} onChangeIndex={handleChangeIndex} /></Box>
      }
    </Box>
  )
}
