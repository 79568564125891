import React, { useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab'
import { styled } from '@mui/system';


const LocalTab = styled(Tab)({
  color: '#555555',
  '&.Mui-selected': {
    color: '#000000',
  }
})

const LocalTabList = styled(TabList)({
  paddingTop: '12px',
  background: '#F9F9F9',
  '& .MuiTabs-indicator': {
    backgroundColor: '#555555',
  }
})

const LocalTabPanel = styled(TabPanel)({
  padding: 0
})

export interface TabItem<T = any> {
  name: string,
  value: string,
  fc: React.FC<any>
  props: T
}

export interface PropsTabs {
  defaultValue?: string;
  tabItems: TabItem[];
  onChange?: (num: string) => void;
}


export const PortalTabs: React.FC<PropsTabs> = ({ defaultValue = '', tabItems, onChange }) => {
  console.log('defaultValue:', defaultValue);
  console.log('tabItems:', tabItems);
  const [tabsValue, setTabsValue] = useState(defaultValue)
  console.log('tabsValue:', tabsValue);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabsValue(newValue);
    if (onChange) {
      onChange(newValue);
    }

  }
  return (
    <>
      <TabContext value={tabsValue}>
        <Box>
          <LocalTabList
            value={tabsValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{paddingTop:"0"}}
            >
            {
              tabItems.map(item =>
                <LocalTab key={item.value} label={item.name} value={item.value} />
              )
            }
          </LocalTabList>
        </Box>
        {
          tabItems.map(item => {
            const Content = item.fc
            return (
              <LocalTabPanel key={item.value} value={item.value} >
                <Content {...item.props} />
              </LocalTabPanel>
            );
          })
        }
      </TabContext>
    </>
  )
}