import { FC, useEffect, lazy, Suspense } from 'react';
// import Container from '@mui/material/Container';
import { 
    useLocalVideo,
    useMeetingManager,
 } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '../../providers/MeetingStateProvider';
import { DataMessagesProvider } from '../../providers/dataMessagesProvider';
import { VideoTileGridProvider } from '../../providers/videoTileGridProvider';
import { ShareDataMessageProvider } from '../../providers/shareDataMessageProvider';
import { Loader } from "src/components/loader";


const PcView = lazy(() => import('./PcView'));
const MobileView = lazy(() => import('./MobileView'));



const MeetingView: FC = () => {
    const { layout, initOpenVideo, closeAllVideo, isStudent, meetingLoading, isMobile } = useMeetingState();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const meetingManager = useMeetingManager();
    useEffect(() => {
        if (meetingManager?.meetingSession && !isVideoEnabled && initOpenVideo) {
            if (closeAllVideo && isStudent ) {
                return ;
            }
            toggleVideo();
        }
    }, [meetingManager, meetingManager.meetingSession, initOpenVideo, isVideoEnabled, closeAllVideo, isStudent, toggleVideo])
    console.log('meetingLoading  MeetingView meetingLoading:', meetingLoading)
    console.log('MeetingView layout:', layout)
    return (
        <Suspense fallback={<Loader />}>
            {
                isMobile? 
                <MobileView />:
                <PcView />
            }
            <Loader isLoading={meetingLoading} />
        </Suspense>
    );
}


const MeetingViewWrapper: FC = () => {

    return (
        <DataMessagesProvider>
            {/* <ControlDataMessageProvider> */}
                <ShareDataMessageProvider>
                    <VideoTileGridProvider>
                            <MeetingView />
                    </VideoTileGridProvider>
                </ShareDataMessageProvider>
            
            {/* </ControlDataMessageProvider> */}
        </DataMessagesProvider>
    )

}

export default MeetingViewWrapper;