import React from 'react'
import { useGetSchoolResourceListQuery } from 'src/app/service/real/learnpage-slice'
import { useGetSchoolSeriesListQuery } from 'src/app/service/real/coursedetail-slice';
import { TeacherList } from './TeacherList'

import { SeriesList } from 'src/components/cardlist'

import { TeacherEntity, SeriesEntity } from '@dokyo/common'


export interface Props {
  schoolId: string,
  startKey: string,
  onDataLoad: (startKey: string) => void
}

export const TeacherListBySchoolId: React.FC<Props> =({ startKey, schoolId, onDataLoad }) => {

  const [seriesList, setSeriesList] = React.useState<SeriesEntity[]>([]);

  const {data: seriesData, isFetching } = useGetSchoolSeriesListQuery({ startKey, schoolId, pageSize: 20 }, {
    refetchOnMountOrArgChange: 60
  })

  // const { data } = useGetSchoolResourceListQuery({ start_key: startKey, school_id:schoolId, page_size: 20, request_type: 10 }, {
  //   refetchOnMountOrArgChange: 60
  // })

  // let teacherList: TeacherEntity[] = []
  // if (data && !data.isFailed && data.result) {
  //   if (data.result.series_list) {
  //     teacherList = data.result.series_list
  //   } 
  // }

  React.useEffect(() => {
    setSeriesList([]);
  }, [schoolId])

  React.useEffect(() => {
    if (seriesData) {
      onDataLoad(seriesData?.result?.start_key?? '')
      if (seriesData.isFailed === false && seriesData.result?.series_list) {
        setSeriesList(seriesData.result.series_list);
      }
    }
    
  }, [seriesData])

  // React.useEffect(() => {
  //   if (data) {
  //     onDataLoad(data?.result?.start_key?? '')
  //   }
  // }, [data])


  return (
    <SeriesList  isLoading={isFetching} seriesList={seriesList} />
    // <TeacherList isSeries={true} isLoading={isFetching} teacherList={teacherList} />
  )
}