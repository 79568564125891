import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import { CourseEntity, SeriesEntity } from '@dokyo/common'

export interface CourseListResult {
  course_list: CourseEntity[],
  start_key: string
}

export interface SeriesListResult {
  series_list: SeriesEntity[],
  start_key: string,
}

export interface TeacherSchoolSeriesItem {
  series: SeriesEntity;
  course_list: CourseEntity[];
  course_list_start_key: string;
}

export interface TeacherSchoolSeriesResult {
  series_list: TeacherSchoolSeriesItem[],
  start_key: string,
}


export const getLearnInfoApi = createApi({
  reducerPath: 'learn-api',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getLearnInfo: builder.query<Result<any>, { 
          courseId: string,
          type: number  //1-course; 2-meeting; 3-live broadcast
        }>({
        query: ({ courseId, type }) => ({
          url: `getlearninfo`,
          method: 'POST',
          body: { 
              id: courseId,
              type: type,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getPublishedCourseList: builder.mutation<Result<any>, { 
        teacherId: string,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean,
      }>({
        query: ({ teacherId, pageSize, startKey, forwardOrder}) => ({
          url: `getpublishedcourselist`,
          method: 'POST',
          body: { 
              user_id: teacherId,
              page_size: pageSize??10,
              start_key: startKey ?? '',
              forward_order: forwardOrder?? false
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getQueryPublishedCourseList: builder.query<Result<CourseListResult>, { 
        teacherId?: string,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean
      }>({
        query: ({ teacherId, pageSize, startKey, forwardOrder }) => ({
          url: `getpublishedcourselist`,
          method: 'POST',
          body: { 
              user_id: teacherId,
              page_size: pageSize ?? 10,
              start_key: startKey ?? '',
              forward_order: forwardOrder?? false
            }
        }),
        transformResponse: (res: Response<CourseListResult>) => new Value<CourseListResult>(res)
      }),
      getLessonList: builder.query<Result<any>, { 
        courseId: string
      }>({
        query: ({ courseId }) => ({
          url: `getlessonlist`,
          method: 'POST',
          body: { 
              course_id: courseId,
              page_size: 10,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getMyCourseList: builder.query<Result<CourseListResult>, {
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean
      }>({
        query: ({ pageSize, startKey, forwardOrder }) => ({
          url: 'getmycourselist',
          method: 'POST',
          body: {
            start_key: startKey ?? '',
            page_size: pageSize ?? 10,
            forward_order: forwardOrder ?? false
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      sendComment: builder.mutation<Result<any>, {
        userId: string, // user ID
        requestId: string//sting of the social id, such as user id, school id, course id and so on.
        requestType: number,//(1: social user post, 2: social school post, 3: social course post, 4: social live post, 5: social private lesson post, 6: social teacher post)
        title: string,
        content: string,
      }>({
        query: ({ userId ,requestId, requestType, title, content }) => ({
          url: 'uploaduserpostinfo',
          method: 'POST',
          body: {
            user_id: userId,
            request_id: requestId,
            request_type: requestType,
            message_title: title,
            message_content: content,
            message_type: 1,//1-text, 2-picture, 3-video, 4-mute
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      sendThumbComment: builder.mutation<Result<any>, {
        userId: string, //the user send this thumb
        courseId: string//sting of the social id, such as user id, school id, course id and so on.
        requestType: number,//(1: social user post, 2: social school post, 3: social course post, 4: social live post, 5: social private lesson post, 6: social teacher post)
        title: string,
        content: string,
        thumbUp: boolean,
        commentId: string,
      }>({
        query: ({ userId, courseId, requestType, title, content, thumbUp, commentId }) => ({
          url: 'uploaduserpostinfo',
          method: 'POST',
          body: {
            user_id: userId,
            request_id: courseId,
            request_type: requestType,
            message_title: title,
            message_content: content,
            message_type: 1,//1-text, 2-picture, 3-video, 4-mute
            thumb_up: thumbUp,
            parent_message_id: commentId,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getCommentList: builder.query<Result<any>, {
        userId: string,
        courseId: string//sting of the social id, such as user id, school id, course id and so on.
        requestType: number,//(1: social user post, 2: social school post, 3: social course post, 4: social live post, 5: social private lesson post, 6: social teacher post)
        startKey: string,
        pageSize?: number,
      }>({
        query: ({ userId, courseId, requestType, startKey, pageSize }) => ({
          url: 'getuserpostinfo',
          method: 'POST',
          body: {
            user_id: courseId,
            request_user_id: userId,
            request_type: requestType,
            page_size: pageSize ?? 10,
            start_key: startKey ?? '',
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      deleteUserPostInfo: builder.mutation<Result<any>, {
        userId: string//sting of the user id
        requestType: number,//(1: social user post, 2: social school post, 3: social course post, 4: social live post, 5: social private lesson post, 6: social teacher post)
        messageId: string,//sting of the message id.
      }>({
        query: ({ userId, requestType, messageId }) => ({
          url: 'deleteuserpostinfo',
          method: 'POST',
          body: {
            user_id: userId,
            request_type: requestType,
            message_id: messageId
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getCourseListByCategory: builder.query<Result<CourseListResult>, {
        categoryId: string,
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean
      }>({
        query: ({categoryId, pageSize, startKey, forwardOrder }) => ({
          url: 'getcourselistbycategory',
          method: 'POST',
          body: {
            category_id: categoryId,
            start_key: startKey ?? '',
            page_size: pageSize ?? 10,
            forward_order: forwardOrder ?? false
          }
        }),
        transformResponse: (res: Response<CourseListResult>) => new Value<CourseListResult>(res)
      }),
      getCourseListBySchool: builder.query<Result<CourseListResult>, {
        schoolId: string,
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean
      }>({
        query: ({schoolId, pageSize, startKey, forwardOrder }) => ({
          url: 'getschoolresourcelist',
          method: 'POST',
          body: {
            request_type: 3,
            school_id: schoolId,
            start_key: startKey ?? '',
            page_size: pageSize ?? 20,
            forward_order: forwardOrder ?? false
          }
        }),
        transformResponse: (res: Response<CourseListResult>) => {
          return new Value<CourseListResult>(res)
        }
      }),
      getMeetingList: builder.mutation<Result<any>, {
        page_size?: number,
        forwardorder?: boolean,
        user_id:string
      }>({
        query: ({ page_size, forwardorder, user_id }) => ({
          url: 'getmeetinglist',
          method: 'POST',
          body: {
            user_id: user_id,
            page_size: page_size ?? 20,
            forwardorder: forwardorder ?? false,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getQueryMeetingList: builder.query<Result<any>, {
        pageSize?: number,
        forwardorder?: boolean,
        type?: number,
        user_id?: string,
        startKey?: string
      }>({
        query: ({ pageSize, forwardorder, type, user_id, startKey }) => {
          const params = ({
            url: 'getmeetinglist',
            method: 'POST',
            body: {
              user_id: user_id?? '',
              page_size: pageSize?? 12,
              forwardorder: forwardorder?? false,
              type: type?? 1
            }
          })
          if (startKey) {
            params.body['start_key'] = startKey
          }
          return params;
        },
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getLearnSeries: builder.query<Result<any>, {
        type: number,
        series_id: string,
      }>({
        query: ({ type, series_id }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_id:series_id,
            type: type,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      getSeriesList: builder.query<Result<SeriesListResult>, {
        seriesId?: string,
        seriesTitle?: string,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean,
        startLevel?: number,
        endLevel?: number,
      }>({
        query: ({ seriesId, seriesTitle, startKey, pageSize, forwardOrder, startLevel, endLevel }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_id: seriesId,
            series_title: seriesTitle,
            start_key: startKey,
            page_size: pageSize,
            forward_order: forwardOrder,
            type: 2,
            start_level: startLevel,
            end_level: endLevel,
          }
        }),
        transformResponse: (res: Response<SeriesListResult>) => new Value(res)
      }),
      getSchoolSeriesList: builder.query<Result<SeriesListResult>, {
        schoolId: string,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean
      }>({
        query: ({ schoolId, startKey, pageSize, forwardOrder }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_school_id: schoolId,
            start_key: startKey ?? '',
            page_size: pageSize ?? 20,
            forward_order: forwardOrder ?? false,
            type: 2,
          }
        }),
        transformResponse: (res: Response<SeriesListResult>) => new Value(res)
      }),
      getSeriesListByWeight: builder.query<Result<SeriesListResult>, {
        schoolId?: string,
        categoryId?: string,
        masterclass?: boolean,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean
      }>({
        query: ({ schoolId, categoryId, masterclass, startKey, pageSize, forwardOrder }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_school_id: schoolId,
            series_category_id: categoryId,
            series_masterclass: masterclass,
            start_key: startKey ?? '',
            page_size: pageSize ?? 20,
            forward_order: forwardOrder ?? false,
            type: 7,
          }
        }),
        transformResponse: (res: Response<SeriesListResult>) => new Value(res)
      }),
      getCourseListByWeight: builder.query<Result<CourseListResult>, {
        schoolId?: string,
        categoryId?: string,
        masterclass?: boolean,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean
      }>({
        query: ({ schoolId, categoryId, masterclass, startKey, pageSize, forwardOrder }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_school_id: schoolId,
            series_category_id: categoryId,
            series_masterclass: masterclass,
            start_key: startKey ?? '',
            page_size: pageSize ?? 20,
            forward_order: forwardOrder ?? false,
            type: 11,
          }
        }),
        transformResponse: (res: Response<CourseListResult>) => new Value(res)
      }),
      getSeriesListByCategoryQuery: builder.query<Result<SeriesListResult>, {
        // schoolId?: string,
        categoryId: string,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean,
        startLevel?: number,
        endLevel?: number,
      }>({
        query: ({  categoryId,startKey, pageSize, forwardOrder, startLevel, endLevel }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            // series_school_id: schoolId,
            series_category_id: categoryId,
            start_key: startKey ?? '',
            page_size: pageSize ?? 20,
            forward_order: forwardOrder ?? false,
            type: 2,
            start_level: startLevel,
            end_level: endLevel,
          }
        }),
        transformResponse: (res: Response<SeriesListResult>) => new Value(res)
      }),
      getAllMasterclassSeriesList: builder.query<Result<SeriesListResult>, {
        schoolId: string,
        masterclass: boolean,
        forwardOrder?: boolean
      }>({
        query: ({ schoolId, masterclass, forwardOrder }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_school_id: schoolId,
            series_masterclass: masterclass,
            forward_order: forwardOrder ?? false,
            type: 12,
          }
        }),
        transformResponse: (res: Response<SeriesListResult>) => new Value(res)
      }),
      visitSeries: builder.mutation<Result<{}>, {
        seriesId: string//sting of the user id
      }>({
        query: ({ seriesId }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_id: seriesId,
            type: 6
          }
        }),
        transformResponse: (res: Response<{}>) => new Value(res)
      }),
      visitCourse: builder.mutation<Result<{}>, {
        courseId: string//sting of the user id
      }>({
        query: ({ courseId }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            course_id: courseId,
            type: 10
          }
        }),
        transformResponse: (res: Response<{}>) => new Value(res)
      }),
      selectTeacherSchoolSeriesListInfo: builder.query<Result<TeacherSchoolSeriesResult>, {
        schoolId?: string,
        teacherId?: string,
        masterclass?: boolean,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean,
        subPageSize?: number,
      }>({
        query: ({ schoolId, teacherId, masterclass, startKey, pageSize, forwardOrder, subPageSize }) => ({
          url: 'learnseries',
          method: 'POST',
          body: {
            series_school_id: schoolId,
            series_teacher_id: teacherId,
            series_masterclass: masterclass,
            start_key: startKey ?? '',
            page_size: pageSize ?? 20,
            forward_order: forwardOrder ?? false,
            type: 8,
            sub_page_size: subPageSize
          }
        }),
        transformResponse: (res: Response<TeacherSchoolSeriesResult>) => new Value(res)
      }),
      getSeriesInfo: builder.mutation<Result<any>, {
        seriesId: string
      }>({
        query: ({ seriesId }) => {
          const params = ({
            url: 'learnseries',
            method: 'POST',
            body: {
              type: 5,
              series_id: seriesId,
              count_only: false,
            }
          })
          return params;
        },
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      querySeriesInfo: builder.query<Result<any>, {
        seriesId: string
      }>({
        query: ({ seriesId }) => {
          const params = ({
            url: 'learnseries',
            method: 'POST',
            body: {
              type: 5,
              series_id: seriesId,
              count_only: false,
            }
          })
          return params;
        },
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getSeriesBroadcastList: builder.query<Result<any>, {
        seriesId: string,
        liveNow?: boolean,
        upcoming?: boolean,
        ended?: boolean,
      }>({
        query: ({ seriesId, liveNow, upcoming, ended}) => {
          const params = ({
            url: 'getseriesbroadcastlist',
            method: 'POST',
            body: {
              series_id: seriesId,
              live_now: liveNow,
              upcoming: upcoming,
              ended: ended,
            }
          })
          return params;
        },
        transformResponse: (res: Response<any>) => new Value(res)
      }),
      setPremiumMemberMasterClassChoice: builder.mutation<Result<any>, {
        userId: string,
        seriesId: string
      }>({
        query: ({ userId, seriesId}) => {
          const params = ({
            url: 'uploaduserprofile',
            method: 'POST',
            body: {
              user_id: userId,
              masterclass: seriesId,
            }
          })
          return params;
        },
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getSeriesResourceList: builder.mutation<Result<any>, {
        seriesId: string,
        subType: number, // 1-Recorded; 2-live; 5-meeting
        pageSize: number,
        startKey: string,
      }>({
        query: ({ seriesId, subType, pageSize, startKey}) => {
          const params = ({
            url: 'learnseries',
            method: 'POST',
            body: {
              type: 13,// get Recorded/live/meeting list
              sub_type: subType,
              series_id: seriesId,
              page_size: pageSize,
              start_key: startKey,
            }
          })
          return params;
        },
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const {
  useLazyGetLearnInfoQuery, 
  useGetLearnInfoQuery,
  useGetLessonListQuery,   
  useGetPublishedCourseListMutation, 
  useGetMyCourseListQuery,
  useGetCourseListByCategoryQuery, 
  useGetCourseListBySchoolQuery, 
  useGetQueryPublishedCourseListQuery,
  useLazyGetQueryPublishedCourseListQuery,
  useSendCommentMutation,
  useSendThumbCommentMutation,
  useGetCommentListQuery,
  useLazyGetCommentListQuery,
  useDeleteUserPostInfoMutation,
  useGetMeetingListMutation,
  useGetQueryMeetingListQuery,
  useGetLearnSeriesQuery,
  useLazyGetLearnSeriesQuery,
  useGetSeriesInfoMutation,
  useQuerySeriesInfoQuery,
  useGetSeriesBroadcastListQuery,
  useGetSeriesListQuery,
  useLazyGetSeriesListQuery,
  useGetSchoolSeriesListQuery,
  useLazyGetSchoolSeriesListQuery,
  useGetSeriesListByWeightQuery,
  useLazyGetSeriesListByWeightQuery,
  useGetCourseListByWeightQuery,
  useLazyGetCourseListByWeightQuery,
  useVisitSeriesMutation,
  useVisitCourseMutation,
  useSetPremiumMemberMasterClassChoiceMutation,
  useLazySelectTeacherSchoolSeriesListInfoQuery,
  useGetSeriesResourceListMutation,
  useGetAllMasterclassSeriesListQuery,
  useLazyGetAllMasterclassSeriesListQuery,
  useGetSeriesListByCategoryQueryQuery,
  useLazyGetSeriesListByCategoryQueryQuery,
} = getLearnInfoApi
