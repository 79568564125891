import * as React from 'react'
import { useState, useEffect } from 'react'
import { ClassroomMaterialCardEntity } from 'src/components/cards/ClassroomMaterialCard'
import { Box, Button, Theme, List, ListItem, } from '@mui/material'
import { makeStyles, styled } from '@mui/styles';
import { useSnackbar } from 'notistack'
import { Loader } from 'src/components/loader'
import { useSelector } from 'src/app/toolkit/store'
import { useGetSeriesLessonMaterialListMutation } from 'src/app/service/real/serieslesson-slice'
import { MeetingTypeEnum } from "../dialog/MeetingDialog";
import { useGetPrivateLessonMaterialListMutation } from 'src/app/service/real/privatelesson-slice'
import { formatFileSize, getDisplayUserName } from 'src/utils/stringUtil'
import axios from 'axios'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import LoadingButton from '@mui/lab/LoadingButton';
import { useListSeriesMaterialsMutation } from 'src/app/service/real/teacher.slice';


const useStyles = makeStyles((theme: Theme) => ({
  
  list: {
    marginBottom: '50px !important',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  listItem: {
    width: '100%',
    padding: '8px 0',
    borderBottom: '1px solid #2e2e2e',
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #e2e2e2',
    }
  },
  materialTitle: {
    fontSize: '14px',
    fontFamily: "Avenir-Heavy, Avenir",
    minWidth: '200px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      color: 'black',
    }
  },
  listUserName: {
    fontSize: '12px',
    fontFamily: "Avenir-Book, Avenir",
    width: "200px",
    minWidth: '200px',
  },
  listFileSize: {
    fontSize: '12px',
    fontFamily: "Avenir-Book, Avenir",
    width: "300px",
    minWidth: '100px',
    color: '#aaaaaa',
    marginTop: '7px',
    [theme.breakpoints.down('sm')]: {
      color: '#555555',
    }
  },
  buttonLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginTop: '7px',
  },
  listButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  listButtonText: {
    fontSize: '12px',
    fontFamily: "Avenir-Medium, Avenir",
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      color: '#555555',
    }
  },
  listButtonTextSelect: {
    fontSize: '12px',
    fontFamily: "Avenir-Medium, Avenir",
    color: '#2A86E8 !important',
    [theme.breakpoints.down('sm')]: {
      color: '#2A86E8 !important',
    }
  },
  iconChoice: {
    color: 'white !important',
    marginRight: "5px",
    width: "18px !important",
    height: "18px !important",
    [theme.breakpoints.down('sm')]: {
      color: '#555555 !important',
    }
  },
  iconChoiceSelect: {
    color: '#2A86E8 !important',
    marginRight: "5px",
    width: "18px !important",
    height: "18px !important",
    [theme.breakpoints.down('sm')]: {
      color: '#2A86E8 !important',
    }
  },
  emptyText: {
    margin: '50px 0 !important',
    fontSize: '14px',
    color: '#555555',
    fontFamily: "Avenir-Book, Avenir",
  },
  pdfShareDocument: {
    width: '100%',
  },
  pdfSharePage: {
    width: '100%',
    '& .react-pdf__Page__canvas': {
      width: '100% !important',
      height: 'auto !important',
    }
  },
  pageButton: {
    margin: '5px !important',
    '&.Mui-disabled': {
      color: 'white !important',
      background: '#aaaaaa !important',
    }
  },
})
)

const StyledButton = styled(Button)({
  border: "none",
  boxShadow: "none",
  '&.Mui-disabled': {
    color: 'white !important',
    background: "#aaaaaa !important",
    border: "none",
  },
})

interface Props {
  meetingType: MeetingTypeEnum;
  dataId: string;
  isStudent: boolean;
  onShareFile: (file : ClassroomMaterialCardEntity) => void;
}

const MeetingMaterialList: React.FC<Props> = ({meetingType, dataId, isStudent, onShareFile}) => {
	const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { credential } = useSelector(state => state.app)
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [sharingItemId, setSharingItemId] = useState<string|undefined>();
  const [downloadingItem, setDownloadingItem] = useState<ClassroomMaterialCardEntity>();

  const [dataList, setDataList] = useState<ClassroomMaterialCardEntity[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const PAGE_SIZE = 999;


  const [getCourseMaterials, {isLoading: isLoading0}] = useListSeriesMaterialsMutation();
  const [getPrivateLessonMaterialList, {isLoading: isLoading1}] = useGetPrivateLessonMaterialListMutation();
  const [getSeriesLessonMaterialList, {isLoading: isLoading2}] = useGetSeriesLessonMaterialListMutation();


  useEffect(() => {
    if (dataId) {
      handleSearch(dataId, meetingType);
    }
  }, [dataId, meetingType])

  const handleSearch = React.useCallback(async (dataId: string, meetingType: MeetingTypeEnum) => {
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      getCourseMaterials({
        series_id: dataId, page_size: PAGE_SIZE, start_key: '', 
      })
      .unwrap()
      .then(dataRes => {
        if (dataRes && !dataRes.isFailed && dataRes.result) {
          const list = dataRes.result.series_materials_list;
          if (list) {
            setDataList(list);
          }
          const nextStartKey = dataRes.result.start_key;
          setHasMore(nextStartKey && nextStartKey !== "");
        } else {
          setDataList([]);
          setHasMore(false);
          // enqueueSnackbar(dataRes?.message || "Can not get data")
        }
      })
    } else if (meetingType === MeetingTypeEnum.PrivateLesson) {
      getPrivateLessonMaterialList({
        id: dataId, page_size: PAGE_SIZE, start_key: '', 
      })
      .unwrap()
      .then(dataRes => {
        if (dataRes && !dataRes.isFailed && dataRes.result) {
          const list = dataRes.result.private_lesson_material_list;
          if (list) {
            setDataList(list);
          }
          const nextStartKey = dataRes.result.start_key;
          setHasMore(nextStartKey && nextStartKey !== "");
        } else {
          setDataList([]);
          setHasMore(false);
          // enqueueSnackbar(dataRes?.message || "Can not get data")
        }
      })
    } else if (meetingType === MeetingTypeEnum.SeriesLesson) {
      getSeriesLessonMaterialList({
        seriesLessonId: dataId, page_size: PAGE_SIZE, start_key: '', 
      })
      .unwrap()
      .then(dataRes => {
        if (dataRes && !dataRes.isFailed && dataRes.result) {
          const list = dataRes.result.series_lesson_material_list;
          if (list) {
            setDataList(list);
          }
          const nextStartKey = dataRes.result.start_key;
          setHasMore(nextStartKey && nextStartKey !== "");
        } else {
          setDataList([]);
          setHasMore(false);
          // enqueueSnackbar(dataRes?.message || "Can not get data")
        }
      })
    } else if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      // enqueueSnackbar("Developing...")
    }
  },[dataId, meetingType])

  const handleDownload = (item: ClassroomMaterialCardEntity) => {
    setIsDownloading(true)
    setDownloadingItem(item);
    setDownloadProgress(0);
    const downloadUrl = item.file_url;
    axios({
        url:downloadUrl,
        responseType:'blob',
        method: 'GET',
        onDownloadProgress:(progressEvent) => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            setDownloadProgress(percentComplete*100)
        }
    })
    .then((res)=>{
      const url=window.URL.createObjectURL(new Blob([res.data],{type:item.file_type}))
      const link = document.createElement("a");
      link.download = item.file_name;
      link.href = url;
      link.click(); 
      setIsDownloading(false)
      setDownloadingItem(undefined);
    })
    .catch((err)=>{
      console.log(err)
      setIsDownloading(false)
      setDownloadingItem(undefined);
    })
  }

  const isSharingItem = (item: ClassroomMaterialCardEntity) => {
    if (meetingType === MeetingTypeEnum.LiveVideoClass) {
      return item.file_name === sharingItemId;
    } else {
      return item.id === sharingItemId;
    }
  }

  const handleShare = async (item: ClassroomMaterialCardEntity) => {
    setSharingItemId(meetingType === MeetingTypeEnum.LiveVideoClass? item.file_name : item.id);
    if (isShareFileType(item.file_type)) {
      onShareFile(item);
    } else {
      enqueueSnackbar("Not support yet")
    }
  }

  const isShareFileType = (fileType: string) => {
    return fileType.startsWith("image") || fileType.startsWith("video") || fileType === "application/pdf";
  }

  if (isLoading0 || isLoading1 || isLoading2) {
    return (
    <Box sx={{textAlign: 'center', paddingTop: '100px'}}>
      <LoadingButton
        style={{color:"#aaaaaa", textTransform: 'none'}}
        loading={true}
        variant="outlined"
      >
        Loading...
      </LoadingButton>
    </Box>)
  } else return (
	<>
    {dataList?.length > 0 ?
    <List className={classes.list}>
      {dataList.map((item, index) => (
      <ListItem key={index} sx={{paddingLeft: '0px'}}>
        <Box className={classes.listItem}>
          <Box className={classes.materialTitle}>{item.file_title}</Box>
          <Box className={classes.listFileSize}>{ ((item.series_lesson_num !== undefined && item.series_lesson_num !== -1) ? `Lesson ${item.series_lesson_num+1}, ` : '') +  item.file_type + ", " + formatFileSize(item.file_size) }</Box>
          <Box className={classes.buttonLine}>
            {isDownloading && downloadingItem?.id === item.id && downloadingItem?.file_name === item.file_name && 
            <LoadingButton
              style={{color:"#aaaaaa", textTransform: 'none', border: 'none'}}
              loading={true}
              variant="outlined"
            >
              downloading...
            </LoadingButton>
            }
            <Box className={classes.listButton} onClick={() => handleDownload(item)}>
              <FileDownloadOutlinedIcon className={classes.iconChoice} />
              <Box className={classes.listButtonText}>Download</Box>
            </Box>
            {isShareFileType(item.file_type) && !isStudent &&
            <Box className={classes.listButton} sx={{marginLeft: '10px'}} onClick={() => handleShare(item)}>
              <ScreenShareIcon className={isSharingItem(item) ? classes.iconChoiceSelect : classes.iconChoice} />
              <Box className={isSharingItem(item) ? classes.listButtonTextSelect : classes.listButtonText}>Share</Box>
            </Box>
            }
          </Box>
        </Box>
      </ListItem>
      ))
      }
    </List>
    :
    <Box className={classes.emptyText}>No material yet.</Box>
    }
	</>
	)
}

export default MeetingMaterialList;