import * as React from 'react'
import { Box, Card, CardMedia, CardContent, Typography, Dialog, Button, DialogActions, DialogTitle, DialogContent, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles';
import defaultfile from 'src/images/teacher/defaultfile.png'
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from 'axios'
import PreviewVideo from '../preview/PreviewVideo';
import { formatFileSize } from 'src/utils/stringUtil';
import { MaterialTypeEnum } from 'src/app/models/victoriaClass';

const useStyles = makeStyles(theme => ({
  imgHover: {
    filter: 'contrast(70%)'
  },
  group: {
    width: '270px',
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #dddddd',
    borderRadius: '12px !important',
    boxShadow: 'none !important',
    // ml: 3, 
    // mr: 3,
  },
  titleMouseDown: {
    // textDecoration: 'underline'
  },
  iconChoice: {
    marginRight: '2px',
    width: "18px !important",
    height: "18px !important",
  },
  buttonText: { 
    display: 'flex', 
    alignItems: 'center', 
    cursor: "pointer",
    fontSize: '14px',
    color: 'black',
    fontFamily: 'Avenir-Medium, Avenir',
  }
})
)

export function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


export interface ClassroomMaterialCardEntity {
  file_name: string;
  file_title: string;
  file_url: string;
  file_avatar: string;
  file_size: number;
  file_type: string;
  // series/private lesson materials added
  id?: string;
  private_lesson_id?: string;
  series_lesson_id?: string;
  series_lesson_num: number;
  create_time?: number;
  lock: boolean;
  classes_lock?: boolean;// for series lesson class
}


interface ClassroomMaterialCardProps {
  materialType: MaterialTypeEnum;
  card: ClassroomMaterialCardEntity,
  enableLock: boolean,
  enableDelete: boolean,
  canDownload: boolean,
  handleDelete: (id: string) => void,
  handleLock: (id: string, lock: boolean, oldMaterial?: ClassroomMaterialCardEntity) => void,
}

const ClassroomMaterialCard: React.FC<ClassroomMaterialCardProps> = ({ materialType, card, enableDelete, enableLock, canDownload, handleDelete, handleLock, ...props }) => {
  const classes = useStyles()
  const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false)
  const [progress, setProgress] = React.useState(0);
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [showPreviewDialog, setShowPreviewDialog] = React.useState(false);
  const [previewVideoUrl, setPreviewVideoUrl] = React.useState("");

  const handleDownload = () => {
    setIsDownloading(true)
    onProgress()
  }

  const onProgress = () => {
    const downloadUrl = card.file_url;
    axios({
      url: downloadUrl,
      responseType: 'blob',
      method: 'GET',
      onDownloadProgress: (progressEvent) => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        setProgress(percentComplete * 100)
      }
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: card.file_type }))
        const link = document.createElement("a");
        link.download = card.file_name;
        link.href = url;
        link.click();
        setIsDownloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsDownloading(false)
      })
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  const handleConfirm = () => {
    setOpen(true)
  }

  const handlePreview = () => {
    setShowPreviewDialog(true);
    setPreviewVideoUrl(card.file_url)
  }

  const isFileLockedForClass = ():boolean => {
    if (card.classes_lock !== undefined) {
      return card.classes_lock;
    } else return card.lock;
  }

  return (
    <>
      <Box
        className={classes.group}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
      >
        <Card className={classes.card}>
          <Box style={{ position: "relative" }}>
            <CardMedia
              component="img"
              image={card.file_avatar || defaultfile}
              sx={{
                width: '100%',
                height: '146px',
                borderRadius: '12px 12px 0 0',
              }}
              className={clsx({ [classes.imgHover]: isMouseOver || isDownloading })}
            />
            {isDownloading && 
              <Box
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  marginTop: '77.75px',
                  width: '100%',
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                <CircularProgressWithLabel value={progress} style={{ color: 'rgba(255, 255, 255, 1)' }} />
              </Box>
            }
          </Box>
          <CardContent sx={{padding: '12px'}}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{display:'flex', alignItems:'flex-start', justifyContent:'space-between'}}>
                <Typography
                  style={{
                    flexGrow: 1,
                    fontSize: '20px',
                    letterSpacing: '-0.578823447227478px',
                    fontFamily: 'Arial-BoldMT',
                    lineHeight: '22px',
                    height: '44px',
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                  className={clsx({ [classes.titleMouseDown]: isMouseOver })}
                >
                  {card.file_title}
                </Typography>

                {enableLock && 
                <Tooltip title={isFileLockedForClass() ? "Click to unlock" : "Click to lock"}>
                {isFileLockedForClass() ?
                  <LockOutlinedIcon 
                    className={classes.iconChoice}
                    sx={{color:'black', cursor:'pointer', marginLeft:'10px'}}
                    onClick={() => handleLock(materialType === MaterialTypeEnum.Course ? card.file_name : (card.id||''), false, card)}/> 
                  :
                  <LockOpenIcon 
                    className={classes.iconChoice}
                    sx={{color:'#aaaaaa', cursor:'pointer', marginLeft:'10px'}}
                    onClick={() => handleLock(materialType === MaterialTypeEnum.Course ? card.file_name : (card.id||''), true, card)}/>
                }
                </Tooltip>}
              </Box>

              {card.series_lesson_num !== undefined && 
              <Typography
                style={{
                  fontSize: '12px',
                  letterSpacing: '-0.578823447227478px',
                  fontFamily: 'Avenir-Book, Avenir',
                  lineHeight: '24px',
                  overflow: 'hidden',
                  whiteSpace: 'normal',
                  marginTop: '6px',
                  color: '#555555',
                }}
              >
              {card.series_lesson_num === -1 ? "Whole Series" : `Lesson ${card.series_lesson_num + 1}`}
              </Typography>
              }

              <Typography
                style={{
                  fontSize: '12px',
                  letterSpacing: '-0.578823447227478px',
                  fontFamily: 'Avenir-Book, Avenir',
                  lineHeight: '24px',
                  overflow: 'hidden',
                  marginTop: '6px',
                  color: '#555555',
                  overflowX:'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {formatFileSize(card.file_size)}, {card.file_type}
              </Typography>

              <Box
                style={{
                  display: 'flex',
                  lineHeight: '14px',
                  marginTop: '6px',
                }}
              >
                {enableDelete && 
                <Box className={classes.buttonText} style={{ marginRight: '13px' }} onClick={handleConfirm}>
                  <DeleteOutlinedIcon className={classes.iconChoice} /> Delete
                </Box>
                }
                {canDownload && 
                <Box className={classes.buttonText} style={{ marginRight: '13px' }} onClick={handleDownload}>
                  <FileDownloadOutlinedIcon className={classes.iconChoice} /> Download
                </Box>
                }
                {card.file_type.startsWith("video") && canDownload &&
                  <Box className={classes.buttonText} onClick={handlePreview}>
                    <VisibilityOutlinedIcon className={classes.iconChoice} /> Preview
                  </Box>
                }
              </Box>
              
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={open}
      >
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '16px' }}>
          {"Are you sure want to delete this Material？"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={(e) => setOpen(false)} style={{ color: '#555555', textTransform: 'none' }}>Cancel</Button>
          <Button onClick={() => { handleDelete(MaterialTypeEnum.Course ? card.file_name : (card.id||'')); setOpen(false) }} style={{ textTransform: 'none', marginRight: '15px' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <PreviewVideo
        onClose={() => setShowPreviewDialog(false)}
        videoUrl={previewVideoUrl}
        showDialog={showPreviewDialog}
      />

    </>
  )
}

export default ClassroomMaterialCard;