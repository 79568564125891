import React from 'react'
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import noSeries from 'src/images/seriesLesson/ico-series.svg'
import { useGetSeriesResourceListMutation } from 'src/app/service/real/coursedetail-slice';
import { ListPagination } from 'src/components/pagination';
import { useSnackbar } from 'notistack';
import { SeriesLessonCard } from './card/SeriesLessonCard';
import { SeriesLessonEntity } from 'src/app/models/schedule';
import { appConfig } from 'src/app-config';
import { useGetTeacherSeriesLessonListMutation } from 'src/app/service/real/serieslesson-slice';

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
}))

interface Props {
  teacherId: string
}

export const SeriesLessonList: React.FC<Props> = ({teacherId}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startKeyArray, setStartKeyArray] = useState(["", ""]);
  const [maxPage, setMaxPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const PAGE_SIZE = 12;
  const [dataList, setDataList] = useState<SeriesLessonEntity[]>([]);


  const [getDataList, {isLoading: loading}] = useGetTeacherSeriesLessonListMutation()

  useEffect(()=> {
    if (teacherId) {
      setStartKeyArray(["", ""])
      setMaxPage(1);
      handleSearch(1);
    }
  }, [teacherId])

  const handleSearch = async (pageIndex: number) => {
    setCurrentPage(pageIndex);
    getDataList({
      teacherId: teacherId,
      pageSize: PAGE_SIZE, 
      startKey: startKeyArray[pageIndex],
	  forwardOrder: true,
    })
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const list = dataRes.result.series_lesson_list;
        setDataList(list);

        setTotalCount(dataRes.result.series_course_count);
        const nextStartKey = dataRes.result.start_key;
        startKeyArray[pageIndex+1] = nextStartKey;
        setStartKeyArray(startKeyArray);
        setHasMore(nextStartKey && nextStartKey !== "");
        if (nextStartKey && pageIndex + 1 > maxPage) {
          setMaxPage(pageIndex + 1)
        }
      } else {
        setDataList([]);
        setHasMore(false);
        enqueueSnackbar(dataRes?.message || "Can not get data")
      }
    })
  }


  const handleOnClickPrev = () => {
    handleSearch(currentPage - 1)
  }

  const handleOnClickNext = () => {
    handleSearch(currentPage + 1)
  }

  const handleOnClickPage = (page) => {
    handleSearch(page)
  }

  const handleClickCourse = (id: string|undefined) => {
    navigate(appConfig.paths.seriesLesson + "?id=" + id);
  }

  return (
    <Box className={classes.container}>
      <Loader isLoading={loading}/>
      <Grid 
        container
        spacing={5}
        justifyContent="left"
        alignItems="center"
        sx={{marginBottom:"50px"}}
      >
      {dataList.map((v, i) => 
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} display="flex" justifyContent="center" key={v.id}>
          <SeriesLessonCard
          card={v}
          onClick={()=>handleClickCourse(v.id)}
          />
        </Grid>
      )}
      </Grid>

      {
        (!dataList || dataList.length === 0) && 
        <Box
          sx={{
            position: 'relative',
            left: '50%',
            top: '100px',
            height: '300px',
            width: '300px',
            marginLeft: '-150px',
            textAlign: 'center',
          }}
        >
          <img src={noSeries} alt="no_data" style={{width: '48px', height: '48px'}}></img>
          <Typography
            sx={{
              fontSize: '18px',
              fontFamily: 'ArialMT',
              color: '#555555',
              lineHeight: '21px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginTop:'10px'
            }}
          >No series lesson yet.</Typography>
        </Box>
      }

      {(currentPage !== 1 || dataList?.length !== 0) && 
      <ListPagination
        currentPage={currentPage} 
        hasMore={hasMore} 
        // totalCount={totalCount}
        rowsPerPage={PAGE_SIZE}
        maxPage={maxPage}
        onClickPage={handleOnClickPage}
        onClickPrev={handleOnClickPrev} 
        onClickNext={handleOnClickNext}
      />   
      }

    </Box>
  )
}
