import react, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import TeacherScheduleCalendar from './TeacherScheduleCalendar';
import { Theme, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TeacherScheduleList from './TeacherScheduleList';
import { styled } from '@mui/system';
import SvgComponent from 'src/components/svgComponent';
import { useDispatch } from 'react-redux';
import { showChatDialog } from 'src/app/service/chat-slice';
import { useSelector } from 'src/app/toolkit/store';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import { useNavigate, useParams } from 'react-router-dom';
import { appConfig, buildTeacherScheduleUrl } from 'src/app-config';
import { CreateAvailableTimeDialog } from 'src/components/calendar/CreateAvailableTimeDialog';
import { TeacherPrivateLesson } from 'src/app/models/schedule';
import { useGetTeacherPrivateLessonDetailMutation } from 'src/app/service/real/privatelesson-slice';
import { useSnackbar } from 'notistack';
import { Loader } from 'src/components/loader';
import { getAuthRole } from 'src/utils/auth';


const useStyles = makeStyles((theme: Theme) => ({
    root:{
      textAlign: "center"
    },
    container: {
      width: "100%",
      maxWidth:'1400px',
      margin:'0 auto',
      textAlign: 'center',
      padding: '50px 20px',
      [theme.breakpoints.down('sm')]:{
        width: "100%",
        maxWidth: '100%',
        padding: '0px 10px 0px 10px',
      }
    },
    toggleButton: {
      marginTop: "10px",
      [theme.breakpoints.down("sm")]:{
        marginTop: "20px",
      }
    },
    requestBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width:"100%",
      height: "80px",
      background: "#F9F9F9",
      borderRadius: "12px",
      margin: "45px 0",
      [theme.breakpoints.down("sm")]:{
        margin: "20px 0",
        flexDirection: "column",
        height: "140px",
      }
    },
    requestText: {
      fontSize: '18px',
      fontFamily: 'Avenir-Book, Avenir',
      [theme.breakpoints.down("sm")]:{
        fontSize: '12px',
      }
    },
    materialsText: {
      marginTop: '20px',
      display: 'flex',
      alignItems: 'center',
      fontFamily: "Arial-BoldMT, Arial !important",
      fontSize: "20px !important",
      [theme.breakpoints.down("sm")]:{
        fontSize: '14px !important',
        justifyContent: 'center',
      }
    },
    materialsButton: {
      marginLeft: '24px !important',
      height: '44px !important',
      borderRadius: '24px !important',
      border: '1px solid #307DCF !important',
      color: '#307DCF !important',
      fontWeight: '900 !important',
      fontSize: '14px !important',
      fontFamily: 'Avenir-Book, Avenir !important',
      [theme.breakpoints.down("sm")]:{
        fontSize: '12px !important',
        height: '30px !important',
        borderRadius: '15px !important',
      } 
    },
    teacherCreateListText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: '10px',
      color: '#307DCF',
      fontWeight: 900,
      fontSize: '14px',
      fontFamily: 'Avenir-Book, Avenir',
      textDecoration: 'underline',
      cursor: 'pointer',
      position:'absolute', 
      right: '40px',
      [theme.breakpoints.down("sm")]:{
        marginTop: "10px",
        marginLeft: '0px !important',
        position:'static', 
      }
    },
    requestButtonText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: '10px',
      color: '#307DCF',
      fontWeight: 900,
      fontSize: '14px',
      fontFamily: 'Avenir-Book, Avenir',
      textDecoration: 'underline',
      cursor: 'pointer',      
      [theme.breakpoints.down("sm")]:{
        marginTop: "10px",
        fontSize: '12px !important',
        marginLeft: '0px !important',
      }
    },
    teacherCreateButton: {
      marginLeft: '24px !important',
      height: '44px !important',
      borderRadius: '24px !important',
      border: '1px solid #307DCF !important',
      color: '#307DCF !important',
      fontWeight: '900 !important',
      fontSize: '14px !important',
      fontFamily: 'Avenir-Book, Avenir !important',
      [theme.breakpoints.down("sm")]:{
        marginTop: "10px !important",
        fontSize: '12px !important',
        height: '30px !important',
        borderRadius: '15px !important',
      }
    },
}))

const CustomToggleButton = styled(ToggleButton) ({
  width:"160px",
  textTransform:"none",
  // background: 'rgba(48, 125, 207, 0.08)',
  // color: '#7C849C',
  // '&.Mui-selected': {
  //   background: '#307DCF',
  //   color: 'white',
  //   ':hover': {
  //     background: '#307DCFEE',
  //   }
  // },
})
interface Props {
    teacherId: string;
    teacherName: string;
}

const TeacherSchedule : React.FC<Props> = ({teacherId, teacherName}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authRole = getAuthRole()();
  const {enqueueSnackbar} = useSnackbar();
  const {credential} = useSelector(state => state.app);
  const { subTabName } = useParams();
  const [viewType, setViewType] = useState(subTabName || "list");
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [privateLessonData, setPrivateLessonData] = useState<TeacherPrivateLesson>();

  const [getTeacherPrivateLessonDetail, { isLoading: loading1 }] = useGetTeacherPrivateLessonDetailMutation();

  useEffect(() => {
    if (teacherId) {
      getPrivateLessonDetail();
    }
  }, [teacherId])

  const getPrivateLessonDetail = () => {
    getTeacherPrivateLessonDetail({ teacherId: teacherId })
      .unwrap()
      .then(res => {
        if (res && !res.isFailed && res.result) {
          setPrivateLessonData(res.result.teacher);
        } else {
          // enqueueSnackbar(res?.message || 'Can not get teacher private lesson details')
        }
      })
  }

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment) {
      setViewType(newAlignment)
      const newUrl = buildTeacherScheduleUrl(teacherId||'', newAlignment);
      window.history.pushState({}, "", newUrl);
    }
  }

  const teacherClickRequest = () => {
    if (!credential) {
      dispatch(showSignInUpDialog());
      return;
    }
    setShowCreateDialog(true);
  }

  const showRequestedSchedules = () => {
    navigate(`${appConfig.paths.customPrivateLessons}/${teacherId}`);
  }

  const studentClickRequest = () => {
    if (!credential) {
      dispatch(showSignInUpDialog());
      return;
    }
    dispatch(showChatDialog(teacherId))
  }

  const handleCreateClassSuccess = async () => {
    setShowCreateDialog(false);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <ToggleButtonGroup
            color="primary"
            value={viewType}
            exclusive
            onChange={handleChange}
            aria-label="View Type"
            className={classes.toggleButton}
            >
            <CustomToggleButton value="list" >
              <SvgComponent iconName={viewType === "list" ? 'ico-schedule-list-blue': 'ico-schedule-list-grey'} style={{width: "16px", height: "15px", marginRight: "11px"}} />
              List
            </CustomToggleButton>
            <CustomToggleButton value="calendar" >
              <SvgComponent iconName={viewType === "calendar" ? 'ico-schedule-calendar-blue' : 'ico-schedule-calendar-grey'} style={{width: "20px", height: "20px", marginRight: "11px"}} />
              Calendar
            </CustomToggleButton>
        </ToggleButtonGroup>

        <Box className={classes.materialsText} >
            Private Lesson Materials:
            <Button 
              variant='outlined'
              className={classes.materialsButton}
              sx={{textTransform:'none'}}
              onClick={() => navigate(`${appConfig.paths.privateLessonMaterials}?teacher_id=${teacherId}`)}
            >View Materials</Button>
        </Box>

        {(teacherId === credential?.id || authRole.isAdmin) ?
        <Box className={classes.requestBox} sx={{position:'relative'}}>
          <Box className={classes.requestText}>
            Create a private class for the requested student
          </Box>
          <Button variant='outlined' 
            className={classes.teacherCreateButton} 
            style={{textTransform: 'none'}}
            onClick={teacherClickRequest}
            > + Create Private Lesson
          </Button>
          <Box className={classes.teacherCreateListText} onClick={showRequestedSchedules}>
            <SvgComponent iconName='ico-request-list'  style={{width:"20px", height:"18px", marginRight: "5px"}}/>
            Created List
          </Box>
        </Box>
        :
        <Box className={classes.requestBox}>
          <Box className={classes.requestText}>
            Don't see a time that works for you?
          </Box>
          <Box className={classes.requestButtonText} onClick={studentClickRequest}>
            <SvgComponent iconName='ico-request-teacher'  style={{width:"22px", height:"24px", marginRight: "5px"}}/>
            Request a Private Lesson
          </Box>
        </Box>
        }

        {viewType === 'list' ?
        <TeacherScheduleList teacherId={teacherId} teacherName={teacherName} privateLessonData={privateLessonData}/>
        :
        <TeacherScheduleCalendar teacherId={teacherId} teacherName={teacherName}/>
        }

        <CreateAvailableTimeDialog
          custom={true}
          teacherId={teacherId}
          open={showCreateDialog}
          timeFees={privateLessonData?.time_fees||[]}
          onClickCancel={() => setShowCreateDialog(false)}
          onCreateSuccess={handleCreateClassSuccess}
          privateLessonCategoryIDs={privateLessonData?.category_ids||[]}
        />
      </Box>
      <Loader isLoading={loading1}/>
    </Box>
  )
}

export default TeacherSchedule;