import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CartItem, Video } from 'src/app/models'
import { settings } from '../settings'

const { keys, loadState, saveState } = settings
interface CartState {
  items: CartItem[]
  latestSelectedVideo: CartItem | null
}

const cartSlice = createSlice({
  name: 'abc-cart',
  initialState: (loadState(keys.cart) as CartState) ?? { items: [] },
  reducers: {
    addToCart(state, action: PayloadAction<Video>) {
      const idx = state.items?.findIndex(item => item.video.id === action.payload.id)
      if (idx === -1) {
        state.items?.push({
          video: Object.assign({}, action.payload),
          isSelected: true,
          quantity: 1
        })
      }
      saveState(keys.cart, state)
    },
    removeFromCart(state, action: PayloadAction<Video>) {
      const idx = state.items?.findIndex(item => item.video.id === action.payload.id)
      if (idx !== undefined && idx >= 0) {
        state.items?.splice(idx, 1)
      }
      saveState(keys.cart, state)
    },
    selectItem(state, action: PayloadAction<Video>) {
      const idx = state.items?.findIndex(item => item.video.id === action.payload.id)
      if (idx !== undefined && idx >= 0) {
        state.items[idx].isSelected = true
      }
      saveState(keys.cart, state)
    },
    unselectItem(state, action: PayloadAction<Video>) {
      const idx = state.items?.findIndex(item => item.video.id === action.payload.id)
      if (idx !== undefined && idx >= 0) {
        state.items[idx].isSelected = false
      }
      saveState(keys.cart, state)
    },
    addLatestSelectedVideo(state, action: PayloadAction<Video | null>) {
      state.latestSelectedVideo = {
        video: Object.assign({}, action.payload),
        isSelected: true,
        quantity: 1
      }
    }
  }
})

export const { addToCart, removeFromCart, selectItem, unselectItem, addLatestSelectedVideo } =
  cartSlice.actions
export default cartSlice.reducer
