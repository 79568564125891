import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { Calendar as CalendarComponent  } from 'src/components/calendar'
import { AppointmentItem, BookedTeacherTimeData, BookStatusEnum, BookUserNote, LessonClassesStatusEnum, PrivateLessonTypeEnum, ScheduleEnum, ScheduleLessonTypeEnum, TimeInterval, UserScheduleEntity, UserScheduleSourceTypeEnum } from 'src/app/models/schedule';
import { Loader } from 'src/components/loader'
import { useGetBookedTimeListMutation, useLazyGetPurchasedListQuery } from 'src/app/service/real/purchase-api'
import { useSelector } from 'src/app/toolkit/store';
import { getMonthFirstDayFormatTimestamp, getNextMonthFirstDayFormatTimestamp, getDayZeroClockFormatTime  } from 'src/utils/timeUtil'
import { getAuthRole } from 'src/utils/auth';
import { BroadcastEntity } from '@dokyo/common';
import { useSnackbar } from 'notistack';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGetAllLessonScheduleMutation } from 'src/app/service/real/serieslesson-slice';

export const broadcastToAppointmentItem = (broadcastList: BroadcastEntity[]): AppointmentItem[] => {
  const appointmentItems: AppointmentItem[]  = []

  broadcastList.forEach(broadcast => {
    const startTimeStamp =  broadcast.broadcast_start_time * 1000
    appointmentItems.push({
      id: broadcast.broadcast_id,
      title: broadcast.broadcast_title,
      startDate: new Date(startTimeStamp),
      endDate: new Date(startTimeStamp + 3600000),
      schedule: ScheduleEnum.Broadcast,
      teacherName: broadcast.teacher?.user_name,
      teacherId: broadcast.teacher?.user_id,
      category: broadcast.category?.name,
    })
  })
  return appointmentItems
}

export const videoClassroomToAppointmentItem = (videoClassroomList: any[]): AppointmentItem[] => {
  const appointmentItems: AppointmentItem[]  = []

  videoClassroomList.forEach(item => {
    const startTimeStamp =  item.meeting_start_time?
      item.meeting_start_time * 1000 : item.meeting_created_time * 1000;
    appointmentItems.push({
      id: item.meeting_info.meeting_id,
      title: item.meeting_title,
      startDate: new Date(startTimeStamp),
      endDate: new Date(startTimeStamp + 3600000),
      schedule: ScheduleEnum.ChimeMeeting,
      teacherName: item.teacher?.user_name,
      teacherId: item.teacher?.user_id,
      category: item.category?.name,
    })
  })
  return appointmentItems
}


const MyScheduleCalendar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {credential} = useSelector(state => state.app);
  const authRole = getAuthRole()();
  const { enqueueSnackbar } = useSnackbar();
  const [ appointmentItems, setAppointmentItems ] = React.useState<AppointmentItem[]>([])
  const [ timeInterval, setTimeInterval ] = React.useState<TimeInterval>({
    start_time: 0,
    end_time: 0
  })


  const [getPurchasedList, { data: purchasedList, isFetching, isSuccess}] = useLazyGetPurchasedListQuery()
  // const [getBookedTime, {isLoading}] = useGetBookedTimeListMutation();
  const [getLessonSchedule, {isLoading: loading5}] = useGetAllLessonScheduleMutation();

  React.useEffect(() => {
    // purchased courses contain live stream and live video class
    if (isSuccess && purchasedList && !purchasedList.isFailed && purchasedList.result) {
      if (purchasedList.result.purchased_list) {
        const result : AppointmentItem[] = [];
        purchasedList.result.purchased_list.map(seriesItem => {       
          if (seriesItem.resource_list) {
            if (seriesItem.resource_list?.broadcast_list?.length > 0) {
              const data = broadcastToAppointmentItem(seriesItem.resource_list.broadcast_list)
              result.push(...data)
            }
            if (seriesItem.resource_list?.meeting_list?.length > 0) {
              const data = videoClassroomToAppointmentItem(seriesItem.resource_list.meeting_list)
              result.push(...data)
            }
  
          }
        })
        setAppointmentItems([...result, ...appointmentItems]);
      }
    }
  }, [purchasedList])

  React.useEffect(() => {
    setAppointmentItems([])
    if (credential) {
      getPurchasedList({});
    }
    if (timeInterval.start_time > 0 && timeInterval.end_time > 0) {
      refreshBookData();
    }
  }, [timeInterval])

  const lessonToAppointmentItem = (schedules: UserScheduleEntity[]): AppointmentItem[] => {
    const appointmentItems: AppointmentItem[]  = []

    schedules.forEach(schedule => {
      if (schedule) {
        let newItem: AppointmentItem|undefined = undefined;
        if (schedule.source_type === UserScheduleSourceTypeEnum.SERIES_LESSON_CLASSES) {
          newItem = {
            id: schedule.series_lesson_classes?.series_lesson?.id || (Math.random()+''),
            classRoomId: schedule.series_lesson_classes?.id||'',
            title: schedule.series_lesson_classes?.series_lesson?.title || "Series Lesson Class",
            startDate: new Date(schedule.start_time),
            endDate: new Date(schedule.end_time),
            schedule: ScheduleEnum.StudentSideBookedSeriesLesson,
            teacherName: schedule.series_lesson_classes?.teacher_info?.name||'',
            teacherId: schedule.series_lesson_classes?.teacher_user_id||'',
            category: "",
            booked: true,
            bookStatus: BookStatusEnum.PAID,
            bookList: schedule.series_lesson_classes?.student_user_id_list ? schedule.series_lesson_classes.student_user_id_list?.map(value => {return{user: {id: value, name:'', avatar: ''}, message:''}}) : [],
            lessonType: ScheduleLessonTypeEnum.SeriesLesson,
            secondTeachers: schedule.series_lesson_classes?.second_teacher || schedule.series_lesson_classes?.series_lesson?.second_teacher || [],
          };
        } else if (schedule.source_type === UserScheduleSourceTypeEnum.PRIVATE_LESSON_CLASSES) {
          newItem = {
            id: schedule?.private_lesson_classes?.id || (Math.random()+''),
            classRoomId: schedule.private_lesson_classes?.classes_id || '',
            title: schedule.private_lesson_classes?.title||'Private Lesson Class',
            startDate: new Date(schedule.start_time),
            endDate: new Date(schedule.end_time),
            schedule: schedule.private_lesson_classes?.status === LessonClassesStatusEnum.Published ? ScheduleEnum.TeacherAvailableTime : ScheduleEnum.TeacherSideBookedTime,
            teacherName: '',
            teacherId: schedule.private_lesson_classes?.teacher_user_id || '',
            booked: true,
            bookStatus: BookStatusEnum.PAID,
            bookList: schedule.private_lesson_classes?.student_user_id ? [{user: {id: schedule.private_lesson_classes?.student_user_id, name:'', avatar: ''}, message:''}] : [],
            lessonType: ScheduleLessonTypeEnum.OneToOneLesson,
            secondTeachers: schedule.private_lesson_classes?.second_teacher || [],
          };
        }
        if (newItem) {
          appointmentItems.push(newItem)
        }
      }
    })
    return appointmentItems
  }

  const refreshBookData = () => {
    if (!credential) {
      enqueueSnackbar("Please login", {variant:'info'})
      return;
    }
    setAppointmentItems([])
    
    // get scheduled lessons
    getLessonSchedule({
      userId: credential?.id||'',
      startTime: timeInterval.start_time,
      endTime: timeInterval.end_time,
      scheduleType: 'planned',
    })
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const bookedList: UserScheduleEntity[] = dataRes.result.user_schedule_list;
        let allItems: AppointmentItem[] = [];
        if (bookedList?.length > 0){
          const bookedItems = lessonToAppointmentItem(bookedList);
          allItems = allItems.concat(bookedItems);
        }
        setAppointmentItems(items => [...allItems, ...items]);
      } else {
        enqueueSnackbar(dataRes?.message || "Can not get series data")
      }
    })
  }

  const calculationTimeInterval = (nowDate: Date, calendarType: string): TimeInterval => {
    const result = {
      start_time: 0,
      end_time: 0
    };
    if (calendarType === '1') { // week
      result.start_time = getDayZeroClockFormatTime(nowDate, true);
      result.end_time = result.start_time + 7 * 24 * 60 * 60 * 1000;
    } else if (calendarType === '2') { //month
      result.start_time = getMonthFirstDayFormatTimestamp(nowDate, true)
      result.end_time = getNextMonthFirstDayFormatTimestamp(nowDate, true)
    }
    return result;
  }

  const onCalendarChange = (currentDate, calendarType) => {
    const timeInterval = calculationTimeInterval(currentDate, calendarType);
    setTimeInterval(timeInterval)
  }

  
  return(
    <>
    <Box
      sx={{
        display:'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        margin:'38px auto',
      }}
    >
       <CalendarComponent 
        enableCreateAvailableTime={false}
        onCalendarChange={onCalendarChange} 
        appointmentItems={appointmentItems}
        onRefresh={refreshBookData}/>
    </Box>
    <Loader isLoading={isFetching||loading5} />
    </>
  )
}

export default MyScheduleCalendar;