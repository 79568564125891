import { createApi } from '@reduxjs/toolkit/query/react'
import { List } from '@dokyo/common'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { MasterClass } from '../../models'

export const massterClassSeriesApi = createApi({
  reducerPath: 'massterClassSeriesApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getMasterClassSeries: builder.query<MasterClass[], null>({
        query: () => ({
          url: `master-class-series`
        }),
        transformResponse: (res: List<MasterClass>) => res.items
      })
    }
  }
})

export const { useGetMasterClassSeriesQuery } = massterClassSeriesApi
