import { RelatedClassEntity } from '@dokyo/common'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Theme,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const DefaultCourseImage = "https://portal.veeko.com/img/pic-course.png"
const DefaultLiveImage = "https://portal.veeko.com/img/pic-live.png"
const DefaultClassroomImage = "https://portal.veeko.com/img/pic-classroom.png"

const useStyles = makeStyles((theme:Theme) => ({
  cardRoot: {
    background: "transparent !important",
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px !important',
    },
  },
  title: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    color: '#000000',
    lineHeight: '20px !important',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden',
    fontFamily: "Arial-BoldMT, Arial !important"
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%'
  },
  image: {
    borderRadius: "12px !important",
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: '0',
    background: 'white',
    top: '0'
  },
  desc: {
    marginTop: "5px  !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    lineClamp: 1,
    WebkitBoxOrient: "vertical",
    fontSize: "12px !important",
    fontFamily: 'ArialMT !important',
    lineHeight: '24px',
    color: "#555555"
  },
  category: {
    marginTop: "5px  !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    lineClamp: 1,
    WebkitBoxOrient: "vertical",
    fontSize: "12px !important",
    fontFamily: 'ArialMT !important',
    lineHeight: '24px',
    color: "#555555"
  },
  cardContent:{
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "0px !important",
    },
  }
}))

interface VideoCardProps extends CardProps {
  classData: RelatedClassEntity
  onCartAdded: (data: RelatedClassEntity) => void
  learnType: number
}

const getDefaultImage = (type: number) => {
  if (type === 1) {
    return DefaultCourseImage;
  } else if (type === 2 || type === 3) {
    return DefaultLiveImage;
  } else if (type === 4) {
    return DefaultClassroomImage;
  } else {
    return "";
  }
}

export const RelatedCard1: React.FC<VideoCardProps> = ({ learnType, classData, onCartAdded, onClick, ...props }) => {
  const classes = useStyles()
  const defaultImage = getDefaultImage(learnType);

  const [ isMouseOver, setIsMouseOver] = React.useState<boolean>(false)

  return (
    <Card
      onMouseOut={()=>setIsMouseOver(false)}
      onMouseOver={()=>setIsMouseOver(true)}
      {...props}
      raised={true}
      onClick={onClick}
      className={classes.cardRoot}
      sx={{ cursor: 'pointer', boxShadow: 'none', ml: 3, mr: 3 }}
    >
      <Box className={classes.imageContainer}>
        <CardMedia
          component="img"
        //   height="202"
          image={classData.icon || defaultImage}
          className={classes.image}
          sx={isMouseOver? {filter: 'contrast(70%)'}:{}}
        />
      </Box>
      <CardContent sx={{ boxShadow: 'none', padding: "10px 3px 0 3px" }} className={classes.cardContent}>
        <Typography className={classes.title}>{classData.title}</Typography>
        {classData.desc && 
          <Typography className={classes.desc}>
            {classData.desc}
          </Typography>
        }
        <Box className={classes.category}>
          {classData.categoryName}
        </Box>
      </CardContent>
    </Card>
  )
}
