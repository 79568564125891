import * as React from 'react'
import { useState} from 'react'
import { Box, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CourseInfoSilder } from './CourseInfoSilder'
import { CardTypeEnum, CourseInfoCard } from './card/CourseInfoCard'
import { ClassroomListCardEntity, MaterialTypeEnum } from 'src/app/models/victoriaClass'
import { ClassroomMaterialCardEntity } from 'src/components/cards/ClassroomMaterialCard'
import { TAB_VALUES } from '../Course'
import SvgComponent from 'src/components/svgComponent'
import materialSectionBG from 'src/images/course/bg-material-section.png';
import { getAuthRole } from 'src/utils/auth'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    padding: "50px 0",
    backgroundImage: `url(${materialSectionBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: '#F9F9F9',
  },
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '0px 50px 0px 50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  title: {
    marginBottom: '40px',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Avenir-Black, Avenir',
  },  
  card: {
    padding: "0px 5px"
  },
  button: {
    width: "172px !important",
    height: "48px !important",
    background: "#307DCF !important",
    borderRadius: "24px !important",
    margin: "30px auto !important",
    color: "white !important",
    fontSize: "14px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
  },
})
)
interface Props {
  materialType: MaterialTypeEnum; // course, series_lesson, series_lesson_class, private_lesson
  isLessonTeacher: boolean,
  materialList: ClassroomMaterialCardEntity[],
  apiEnableDownload: boolean,
  onClickMore: () => void,
  handleLock: (id: string, lock: boolean, oldMaterial?: ClassroomMaterialCardEntity) => void,
}

export const MaterialList: React.FC<Props> = ({materialType, isLessonTeacher, materialList, apiEnableDownload, onClickMore, handleLock}) => {
  const classes=useStyles()
  const navigate = useNavigate();
  const authRole = getAuthRole()();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [listData, setListData] = useState<ClassroomListCardEntity[]>([]);

  const enableCurrentUserDownload = (file:ClassroomListCardEntity) : boolean => {
    if (!file.material || !file.material.file_url) {
      return false;
    }
    if (!apiEnableDownload) {
      return false;
    }
    if (authRole.isAdmin) {
      return true;
    }
    if (authRole.isSchoolAdmin && materialType !== MaterialTypeEnum.PrivateLesson && materialType !== MaterialTypeEnum.PrivateLessonClass) {
      return true;
    }
    if (isLessonTeacher) {
      return true;
    }
    
    if (!file.material.lock) {
      return true;
    }
    return !file.material.classes_lock;
  }

  React.useEffect(()=> {
    if (materialList) {
      const transferList = getClassCardList(materialList);
      setListData(transferList);
    }
  }, [materialList])

  const getClassCardList = (list: ClassroomMaterialCardEntity[]): ClassroomListCardEntity[] => {
    const dataList: ClassroomListCardEntity[] = [] 
    
    list.forEach(item => {
      if (item) {
        dataList.push({
          id: item.id||item.file_name,
          media_image: item.file_avatar,
          class: item.file_type,
          time: item.file_size,
          content_header: item.file_title,
          type: CardTypeEnum.Material,
          materialType: materialType,
          material: item,
        })
      }
    })
    return dataList
  }


  const handleItemClick = (item: ClassroomListCardEntity) => {
    if (item.type === CardTypeEnum.Recorded) {
      navigate(appConfig.paths.classDetail + "?id=" + item.id, {replace:true});
    }
  }

  const shouldShowLockOption = (item: ClassroomListCardEntity):boolean => {
    if (!apiEnableDownload) {
      return false;
    }
    if (materialType === MaterialTypeEnum.Course) {
      return authRole.isTeacher;
    } else if (materialType === MaterialTypeEnum.SeriesLesson) {
      return (authRole.isTeacher || authRole.isAdmin || authRole.isSchoolAdmin);
    } else if (materialType === MaterialTypeEnum.SeriesLessonClass) {
      return (authRole.isTeacher || authRole.isAdmin || authRole.isSchoolAdmin) && !!item.material && !!item.material.lock ;
    } else if (materialType === MaterialTypeEnum.PrivateLesson) {
      return authRole.isTeacher || authRole.isAdmin;
    } else if (materialType === MaterialTypeEnum.PrivateLessonClass) {
      return (authRole.isTeacher || authRole.isAdmin) && !!item.material && !!item.material.lock;
    } else {
      return false;
    }
  }


  return (
  <>
    {listData?.length > 0 ?
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.title}>
          Materials
        </Box>
        <CourseInfoSilder arrowTop={65} maxShowNumber={4} itemWidth={350} marginCount={300}>
          {
            listData.map((item => {
              return (
                <Box key={item.id} onClick={() => { handleItemClick(item) }} className={classes.card}>
                  <CourseInfoCard
                    bgTransparent={false}
                    showLabel={false}
                    card={item}
                    showTime={false}
                    enableLock={shouldShowLockOption(item)}
                    canDownload={enableCurrentUserDownload(item)}
                    handleLock={handleLock}
                  />
                </Box>
              )
            }))
          }
        </CourseInfoSilder>

        <Button className={classes.button} sx={{textTransform:"none"}} onClick={onClickMore}>
          View More
          <SvgComponent iconName='ico-next-small' style={{width:"8px", height:"13px", marginLeft:"13px"}}/>
        </Button>
      </Box>
    </Box>
    :
    ""
    }
  </>  
  )
}