import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Avatar, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles';
import clsx from 'clsx'
import { SeriesLessonEntity } from 'src/app/models/schedule'
import { AVATAR_DEFAULT } from 'src/utils/deviceUtil'
import { getDisplayUserName } from 'src/utils/stringUtil'
import NP from "number-precision";

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    width: '100%',
    maxWidth: '250px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  cardRoot: {
    width: '100%',
    background: 'transparent !important',
    cursor: 'pointer', 
    boxShadow: 'none !important', 
    border: '1px solid #E9E9E9 !important', 
    borderRadius: '12px !important',
  },
  spanvictoriaveeko: {
    overflowWrap: 'break-word',
    color: 'rgba(0, 0, 0, 1)',
    textAlign: 'left',
    width: '100%',
    fontSize: '16px !important',
    fontFamily: 'Avenir-Black, Avenir !important',
    fontWeight: "900 !important",
    lineHeight: '20px',
    height: '48px',
    // height: 'fit-content',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    lineClamp: 2,
    WebkitLineClamp: 2,
  },
  spanvictoriaveeko2: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '16px!important',
    fontSize: '12px !important',
    color: '#555555 !important',
    fontFamily: 'Avenir-Book, Avenir !important',
  },

  liveIcon: {
    width: '10px',
    height: '11px'
  },
  titleMouseDown: {
    textDecoration: 'underline'
  },
  imgHover: {
    filter: 'contrast(70%)'
  },
  feeBox: {
    display: 'flex',
    alignItems: 'center',
    fontSize: "14px !important",
    color: "#555555",
  },
  feesValue: {
    fontSize: "18px !important",
    color: "#E91535",
    fontWeight: "bold",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      justifyContent: "flex-start",
      fontSize: "1.25rem !important",
    }
  },
  teacherBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  teacherAvatar: {
    width: '32px !important',
    height: '32px !important',
  },
  teacherName: {
    marginLeft: '10px',
    color: 'black',
    fontSize: '14px',
    fontFamily: 'Avenir-Medium, Avenir',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeLessonBox:{
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  timeLessonItem:{
    flexGrow: 1,
    background:"#F6F6F6",
    borderRadius: "8px",
    height: "54px",
    width: "30%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
  },
  itemValue:{
    fontWeight: '800 !important',
    color: 'black !important',
    fontSize: '16px !important',
    fontFamily: 'Avenir-Medium, Avenir !important',
  },
  itemDesc:{
    color: '#555555 !important',
    fontSize: '12px !important',
    fontFamily: 'Avenir-Medium, Avenir !important',
  }
}))

interface ListCardProps extends CardProps {
  showTeacher?: boolean;
  card: SeriesLessonEntity,
}

export const SeriesLessonCard: React.FC<ListCardProps> = ({ card, showTeacher, ...props }) => {
  const classes = useStyles()
  const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false)

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  // const { color } =props
  return (
    <Box
      className={classes.group}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <Card
        {...props}
        className={classes.cardRoot}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%',
          }}>
          <CardMedia
            component="img"
            image={card.thumbnail}
            sx={{
              borderRadius: '11px 11px 0 0',
              position: 'absolute',
              width: '100%',
              height: '100%',
              inset: '0',
              background: 'white',
              top: '0'
            }}
            className={clsx({ [classes.imgHover]: isMouseOver })}
          />

        </Box>


        <CardContent
          sx={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            padding: '14px',
            paddingBottom: '14px !important',
          }}>
          <Typography
            className={clsx(classes.spanvictoriaveeko, { [classes.titleMouseDown]: isMouseOver })}
          >
            {card.title}
          </Typography>


          <Box className={classes.feeBox}>
            <Typography className={classes.feesValue} >{`$${NP.divide(card.single_lesson_fee, 100)}`}</Typography>
            &nbsp;per lesson
          </Box>

          {showTeacher &&
          <Box className={classes.teacherBox}>
              <Avatar src={card.user?.avatar||AVATAR_DEFAULT} className={classes.teacherAvatar}></Avatar>
              <Box className={classes.teacherName}>{getDisplayUserName(card.user?.name||"", card.user?.id||"")}</Box>
          </Box>
          }

          <Box className={classes.timeLessonBox}>
            <Box className={classes.timeLessonItem} >
              <Typography className={classes.itemValue}>{card.single_lesson_time}</Typography>
              <Typography className={classes.itemDesc}>mins</Typography>
            </Box>
            <Box className={classes.timeLessonItem}sx={{marginRight:'7px', marginLeft:'7px'}}>
              <Typography className={classes.itemValue}>{card.total_lesson}</Typography>
              <Typography className={classes.itemDesc}>Lessons</Typography>
            </Box>            
            <Box className={classes.timeLessonItem}>
              <Typography className={classes.itemValue}>{`${card.min_student} ~ ${card.max_student}`}</Typography>
              <Typography className={classes.itemDesc}>Students</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
