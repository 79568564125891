import React from 'react'
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { VictoriaClassCardEntity } from 'src/app/models/victoriaClass'
import { TeacherCourseCard } from 'src/components/cards/TeacherCourseCard'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import noCourse from 'src/images/teacher/noCourse.png'
import { CourseEntity } from '@dokyo/common'
import { useGetSeriesResourceListMutation } from 'src/app/service/real/coursedetail-slice';
import { ListPagination } from 'src/components/pagination';
import { useSnackbar } from 'notistack';
import image_course_default from 'src/images/defaultIcon/pic-course.png'
import { getDateTimeInWordDescription } from 'src/utils/timeUtil';

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  group: {
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexFlow:'wrap',
    textAlign: 'left',
  },
}))

interface Props {
  seriesId: string
}

export const SeriesPreRecorded: React.FC<Props> = ({seriesId}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startKeyArray, setStartKeyArray] = useState(["", ""]);
  const [maxPage, setMaxPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const PAGE_SIZE = 12;
  const [dataList, setDataList] = useState<VictoriaClassCardEntity[]>([]);


  const [getDataList, {isLoading: loading}] = useGetSeriesResourceListMutation()

  useEffect(()=> {
    if (seriesId) {
      setStartKeyArray(["", ""])
      setMaxPage(1);
      handleSearch(1);
    }
  }, [seriesId])

  const handleSearch = async (pageIndex: number) => {
    setCurrentPage(pageIndex);
    getDataList({
      seriesId: seriesId,
      subType: 1,
      pageSize: PAGE_SIZE, 
      startKey: startKeyArray[pageIndex]
    })
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const list = dataRes.result.series_course_list;
        const cardList = getCardList(list);
        setDataList(cardList);

        setTotalCount(dataRes.result.series_course_count);
        const nextStartKey = dataRes.result.start_key;
        startKeyArray[pageIndex+1] = nextStartKey;
        setStartKeyArray(startKeyArray);
        setHasMore(nextStartKey && nextStartKey !== "");
        if (nextStartKey && pageIndex + 1 > maxPage) {
          setMaxPage(pageIndex + 1)
        }
      } else {
        setDataList([]);
        setHasMore(false);
        enqueueSnackbar(dataRes?.message || "Can not get data")
      }
    })
  }

  const getCardList = (list: CourseEntity[]) : VictoriaClassCardEntity[] => {
    if (!list || list.length === 0) {
      return [];
    }
    return list.map(c => {
      return {
      id:c.course_id,
      media_image: c.course_thumbnail || image_course_default,
      content_subheader:c.category.name,
      content_header: c.course_title,
      content_body: c.create_time? getDateTimeInWordDescription(c.create_time): ''
     }})
  }

  const handleOnClickPrev = () => {
    handleSearch(currentPage - 1)
  }

  const handleOnClickNext = () => {
    handleSearch(currentPage + 1)
  }

  const handleOnClickPage = (page) => {
    handleSearch(page)
  }

  const handleClickCourse = (id: string|undefined) => {
    navigate("/classdetail?id="+id)
  }

  return (
    <Box className={classes.container}>
      <Loader isLoading={loading}/>
      <Grid 
        container
        spacing={0}
        justifyContent="left"
        alignItems="center"
      >
      <Box className={classes.group}>
      {dataList.map((v, i) => 
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} display="flex" justifyContent="center" key={Math.random()}>
          <Box sx={{marginBottom:'20px'}}>
            <TeacherCourseCard
            key={Math.random()}
            card={v}
            onClick={()=>handleClickCourse(v.id)}
            />
          </Box>
        </Grid>
      )}
      </Box>
      </Grid>
       {
          (!dataList || dataList.length === 0) && 
          <Box
            sx={{
              position: 'relative',
              left: '50%',
              top: '100px',
              height: '300px',
              width: '300px',
              marginLeft: '-150px',
              textAlign: 'center',
            }}
          >
            <img src={noCourse} alt="no_data"></img>
            <Typography
              sx={{
                fontSize: '18px',
                fontFamily: 'ArialMT',
                color: 'rgba(0, 0, 0, 1)',
                lineHeight: '21px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginTop:'10px'
              }}
            >No recorded class yet.</Typography>
          </Box>
        }

        {(currentPage !== 1 || dataList?.length !== 0) && 
        <ListPagination
          currentPage={currentPage} 
          hasMore={hasMore} 
          // totalCount={totalCount}
          rowsPerPage={PAGE_SIZE}
          maxPage={maxPage}
          onClickPage={handleOnClickPage}
          onClickPrev={handleOnClickPrev} 
          onClickNext={handleOnClickNext}
        />   
        }

    </Box>
  )
}
