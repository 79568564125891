import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Loader } from 'src/components/loader'
import { SeriesEntity, SeriesTeacherEntity } from '@dokyo/common'
import SvgComponent from 'src/components/svgComponent'
import { CardTypeEnum, CourseInfoCard } from 'src/pages/course/courseInfo/card/CourseInfoCard'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import { Theme } from "@mui/system";

export interface Props {
  isLoading: boolean;
  seriesList: SeriesEntity[];
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },

  },
  item: {
    margin: '0 24px 36px 0',
    width: '280px',
    [theme.breakpoints.down('sm')]: {
      margin: "0 0 36px 0",
      width: '100%',
    },
  }
}))


const EmptyList: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '400px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <SvgComponent iconName={"seriesIcon"} style={{ width: "48px", height: "36px" }} />
      <Box
        sx={{
          mt: '20px'
        }}
      >
        No course yet.
      </Box>
    </Box>
  )
}


export const getClassCardEntity = (series: SeriesEntity): ClassroomListCardEntity => {
  const teachers: SeriesTeacherEntity[] = series.series_teachers;
  const teacher: SeriesTeacherEntity | undefined = teachers?.length > 0 ? teachers[0] : undefined;
  return {
    id: series.series_id,
    media_image: series.series_thumbnail,
    class: series.series_category.name,
    content_header: series.series_title,
    time: series.course_purchased_time,
    fees: series.series_fees,
    memberFees: series.series_membership_fees,
    type: CardTypeEnum.Course,
    teacherId: teacher?.teacher_id || '',
    teacherName: teacher?.teacher_name || '',
    teacherAvatar: teacher?.teacher_avatar || '',
    videoClassCount: series.series_course_count ?? 0,
  }
}

export const SeriesList: React.FC<Props> = ({ isLoading, seriesList }) => {
  const classes = useStyles();
  return (
    <Box className={classes.list}>
      <Loader isLoading={isLoading} />
      {
        seriesList && seriesList.length > 0 ?
          seriesList.map((series) => {
            const card: ClassroomListCardEntity = getClassCardEntity(series);
            return (
              <div key={card.id} className={classes.item}>
                <CourseInfoCard card={card} showTime={false} />
              </div>
            )
          }) :
          isLoading ? '' : <EmptyList />
      }
    </Box>
  )
}
