import React from 'react';

const styles = {
  root: {
    height: 18,
    width: 18,
    cursor: 'pointer',
    border: 0,
    background: 'none',
    padding: 0,
  },
  dot: {
    backgroundColor: '#e4e6e7',
    height: 12,
    width: 12,
    borderRadius: 6,
    margin: 3,
  },
  active: {
    backgroundColor: '#319fd6',
  },
};

interface PaginationDotProps {
    onClick:Function,
    index:number,
    active:Boolean
}

const PaginationDot:React.FC<PaginationDotProps> = ({onClick, index, active , ...props}) => {
  const handleClick = event => {
    onClick(index);
  };

    let styleDot;

    if (active) {
      styleDot = Object.assign({}, styles.dot, styles.active);
    } else {
      styleDot = styles.dot;
    }

  return (
    <button type="button" style={styles.root} onClick={handleClick}>
      <div style={styleDot} />
     </button>
  ) 
}

export default PaginationDot;