import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'


export const getPurchaseInfoApi = createApi({
  reducerPath: 'purchase-api',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getPurchasedList: builder.query<Result<any>, { 
        teacherId?: string,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean,
        seriesStartKey?: string,
        seriesId?: string
      }>({
        query: ({ teacherId, pageSize, startKey, forwardOrder, seriesStartKey, seriesId }) => ({
          url: `getpurchasedlist`,
          method: 'POST',
          body: { 
              user_id: teacherId,
              page_size: pageSize ?? 999,
              start_key: startKey ?? '',
              forward_order: forwardOrder?? false,
              series_start_key: seriesStartKey ?? '',
              series_id: seriesId ?? ''
            }
        }),
        transformResponse: (res: Response<any>) => new Value<any>(res)
      }),
      
      getBookedTimeList: builder.mutation<Result<any>, {
        startTime: number, 
        endTime?: number, 
        byList?: boolean,
        pageSize?: number,
        startKey?: string,
        forwardOrder?: boolean,
      }>({
        query: ({ startTime, endTime, byList, pageSize, startKey, forwardOrder }) => ({
          url: `privateLesson/TeacherSchedule`,
          method: 'POST',
          body: {
            request_type: "select_student_schedule",
            select_student_schedule: {
              start_time: startTime,
              end_time: byList ? undefined : endTime,
              select_type: byList ? 'list' : undefined,
              page_size: pageSize ?? 10,
              start_key: startKey ?? '',
              forward_order: forwardOrder?? false,
          }
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { useGetPurchasedListQuery, useLazyGetPurchasedListQuery, useGetBookedTimeListMutation } = getPurchaseInfoApi