import React, { useState, useEffect, useMemo, ChangeEvent, KeyboardEvent } from 'react'
import { useLocation } from 'react-router-dom'
//import { Banner } from "src/components/banner"
import { Box, Tabs, Tab, Typography, TextField, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import LiveInfo from './LiveInfo'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'
import { useSelector } from 'src/app/toolkit/store'
import { useSnackbar } from 'notistack'
import useWebSocket from 'react-use-websocket'
import { getStoredAccessToken } from 'src/utils/localStorage'
import { isJSON } from 'src/utils/stringUtil'
import { ChatMessage } from 'src/app/models'
import { useGetLearnInfoQuery, useGetSeriesInfoMutation } from 'src/app/service/real/coursedetail-slice'
import { BroadcastEntity, createEmptyBroadcast, FileEntity, FileStatus, SeriesEntity } from '@dokyo/common'
import { useDispatch } from 'react-redux'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import { PageBackButton } from 'src/components/pageBackButton'
import Loader from 'src/components/loader'
import { AddToCartComponent } from 'src/components/addToCart'
import { getFinalFees, getFinalMemberFees } from 'src/components/playerContainer/PlayerContainer'
import { useGetBroadcastStateMutation } from 'src/app/service/real/learnpage-slice'
import { FilePickerButton, UsageType } from '../../components/filePicker/FilePickerButton'
import { MeetingChatMessageList } from '../../components/chat/MeetingChatMessageList'
import { Theme } from '@mui/system';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet';

const SPECIAL_SHADOW =
  'drop-shadow(rgba(239, 243, 249, 0.15) 0.5px 1px 1px) drop-shadow(rgba(239, 243, 249, 0.15) 1px 2px 2px) drop-shadow(rgba(239, 243, 249, 0.15) 2px 4px 4px) drop-shadow(rgba(239, 243, 249, 0.15) 4px 8px 8px) drop-shadow(rgba(239, 243, 249, 0.15) 8px 16px 16px)'

const useStyles = makeStyles((theme: Theme) => ({
  rootPage: {
    backgroundColor: '#FFFFFF',
  },
  root: {    
    width: '100%',
    maxWidth: "1200px",
    margin: "0 auto",
    minHeight: 'calc(100% - 98px)',
    padding: '34px 0 0 30px',
    [theme.breakpoints.down('md')]: {
      padding: "12px 0"
    }
  },
  backButton: {
    display:"flex", 
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      marginTop: "12px",
      padding: "0 10px"
    }
  },
  tab: {
    margin: "0 auto"
  },
  body: {
    display: "flex",    
    marginTop: "30px",
    [theme.breakpoints.down('md')]: {
      marginTop: "15px",
      // flexDirection:"column-reverse",
    }
  },
  leftPart: {
    width: "70%",
    minHeight: "1000px",
    paddingRight: "30px",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      paddingRight: "0"
    }
  },
  rightPart: {
    width: "30%",
    paddingRight: "20px",
    [theme.breakpoints.down('md')]:{
      width: "100%",
      paddingRight: "0px",
    }
  },
  live_chat__wrapper: {
    height: '500px',
    border: "1px solid #eeeeee",
    borderRadius: "2px",
    background: '#EFF3F9',
    [theme.breakpoints.down('md')]:{
      height: '400px',
    }
  },
  live_chat__bg: {
    display: 'flex',
    flexDirection: 'column',
    height: '452px',
    backdropFilter: 'saturate(180%) blur(10px)',
    padding: "0 5px",
    borderRadius: "2px",
    [theme.breakpoints.down('md')]:{
      height: '352px',
    }
  },
  live_chat__header: {
    padding: '8px',
    background: 'white',
  },
  live_chat__inbox: {
    background: 'white',
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'flex-end',
    filter: SPECIAL_SHADOW,
    boxShadow: '0px -4px 20px 0px #eff3f9'
  },
}))

const GreyTab = styled(Tab)({
  color:'grey',
  '&.Mui-selected': {
      color: 'rgb(0,0,0)',
  }
})

const GreyTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
      backgroundColor: 'rgb(0,0,0)',
  }
})

const ClassDetail: React.FC = () => {
  const classes = useStyles(); 
  const dispatch = useDispatch();
  const query = useQuery();
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const token = getStoredAccessToken()
  const { credential } = useSelector(state => state.app)
  const videoId = query.get('id')?.toString()
  const [tabIndex, setTabIndex] = useState(0);
  const [newChatMessage, setNewChatMessage] = useState('');
  const [chatMessageList, setChatMessageList] = useState<ChatMessage[]>([])
  const [broadcastInfo, setBroadcastInfo] = useState<BroadcastEntity>(createEmptyBroadcast());
  const [liveStatus, setLiveStatus] = useState(0);
  const [webSocketEnable, setWebSocketEnable] = useState(false);
  const [sendFile, setSendFile] = useState<FileEntity>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  

  const {data: broadcastInfoRes, isLoading: loading} = useGetLearnInfoQuery({courseId: videoId || '', type: 3});
  const [getSeriesInfo, {isLoading: loading2}] = useGetSeriesInfoMutation();
  const [seriesInfo, setSeriesInfo] = useState<SeriesEntity|null>(null);
  const [getBroadcastState, {isLoading: loading3}] = useGetBroadcastStateMutation();

  const CLOSE_LIVE_CONTENT = "close live";

  useEffect(() => {
    if (sendFile) {
      if (sendFile.fileStatus === FileStatus.UPLOADING) {
        handleSelectFile(sendFile);
      } else if (sendFile.fileStatus === FileStatus.UPLOADED) {
        handleFileUploaded(sendFile);
      }
    }
  }, [sendFile])

  useEffect(() => {
    setChatMessageList([]);
    if (broadcastInfoRes && !broadcastInfoRes?.isFailed && broadcastInfoRes.result) {
      const info : BroadcastEntity = broadcastInfoRes.result.learn_info;
      if (info) {
        const purchased = info.broadcast_purchased;
        if (info.broadcast_status === 2) {
          getBroadcastState({list: [{broadcast_id: info.broadcast_id, user_id: info.teacher.user_id}]})
          .unwrap()
          .then(res => {
            if (res && !res.isFailed && res.result) {
              const resultList : BroadcastEntity[] = res.result.broadcast_list;
              if (resultList?.length > 0) {
                const result = resultList[0];
                result.broadcast_purchased = purchased;
                setLiveStatus(result.broadcast_status);
                setBroadcastInfo(result);
              } else {
                setBroadcastInfo(info)
                setLiveStatus(info.broadcast_status)
              }
            } else {
              setBroadcastInfo(info)
              setLiveStatus(info.broadcast_status)
            }

          })
        } else {
          setBroadcastInfo(info)
          setLiveStatus(info.broadcast_status)
        }
       

        if (info.broadcast_series?.series_id) {
          getSeriesInfo({
            seriesId: info.broadcast_series.series_id
          }).unwrap()
          .then(seriesData => {
            if (!seriesData || seriesData.isFailed || !seriesData.result) {
              return
            }
            setSeriesInfo(seriesData.result)
            // const meetingList = seriesData.result.series_meeting_list;  
          })
        }

      }
    } else if (broadcastInfoRes) {
      enqueueSnackbar(broadcastInfoRes?.message || "Get broadcast data failed")
    }
  // eslint-disable-next-line  
  }, [broadcastInfoRes]) 

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  /* websocket */
  const socketUrl = process.env.REACT_APP_WEBSOCKET_URL + '?broadcast_id=' + videoId + '&token=' + token
  
  // useEffect(() => {
  //   // console.log("token chanaged: ", token)
  //   if (!token || !webSocketEnable) {
  //     getWebSocket()?.close()
  //   }
  //   // eslint-disable-next-line
  // },[token, webSocketEnable])

  const { sendJsonMessage, getWebSocket } =
    useWebSocket(socketUrl, {
      onOpen: () => {
        console.log('websocket opened')
        const params = {
          action: "getmessage",
          messageid: videoId || '',
          message_type: 1,
          pagesize: 99,
          // startkey: '',
          countonly: false,
          forwardorder: false,
          // sequenceid: "thumb",
          user_id: credential?.id
        }
        // console.log("get comment list params: ", JSON.stringify(params))
        sendJsonMessage(params)
      },
      onMessage: (e) => {
        console.log('websocket return message', e.data)
        if (e.data) {
          const msg = JSON.parse(e.data);
          if (msg.action === "getmessage") {
            // msg.messages
            if (msg.messages && msg.messages.length > 0) {
              msg.messages.forEach(item => {
                 if (item.messageid !== videoId) {
                   if (isJSON(item.message)){
                   const chatMessage: ChatMessage = JSON.parse(item.message);
                   
                  // console.log('add chat message: ', addedChat)
                  if (chatMessage.sender_id !== broadcastInfo.teacher.user_id || chatMessage.content !== CLOSE_LIVE_CONTENT) {
                    chatMessageList.push(chatMessage)
                    setChatMessageList(chatMessageList)
                  }
                 }
                }
              })
            }
          } else if (msg.action === "sendmessage") {
            const contentStr = msg.message.content;
            const msgContentData = isJSON(contentStr) ? JSON.parse(contentStr) : "";
            if (msg.sequenceid === "sendchat") {
              handleReceiveChatMessage(msg)
              setNewChatMessage("")
            } else if (msgContentData?.sender_id === broadcastInfo.teacher.user_id && msg.sequenceid === `close-${broadcastInfo.broadcast_title}`) {
              setLiveStatus(3);
              broadcastInfo.broadcast_status = 3;
              if (broadcastInfo.broadcast_channel) {
                broadcastInfo.broadcast_channel.state = 0;
              }
              setBroadcastInfo(broadcastInfo);
            } else {
              // console.log("re websocket message: ", msg);
              // receive from other device, no sequenceid
              if (isJSON(msg.message?.content)) {
                handleReceiveChatMessage(msg)
              }
            }
          } else if (msg.action === "disconnect") {
            //{"action":"disconnect","user":{"id":"wuchen","name":"li wuchen","avatar":"https://share.dev.veeko.com/ap-northeast-1/wuchen/1638771018.jpg","is_anonymous":false},"connection":{"count":2,"is_mute":false,"is_voice_mute":false}}
            console.log(msg.user.name + "leave!")
          } else if (msg.action === "connect") {
            //{"action":"connect","user":{"id":"wuchen","name":"li wuchen","avatar":"https://share.dev.veeko.com/ap-northeast-1/wuchen/1638771018.jpg","is_anonymous":false},"connection":{"count":2,"is_mute":false,"is_voice_mute":false}}
            console.log(msg.user.name + "join in!")
          }
        }
      },
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: closeEvent => true
    })

  const handleReceiveChatMessage = (msg: any) => {
    const messageContent =  msg?.message?.content;
    if (messageContent) {
      const message : ChatMessage = JSON.parse(messageContent);
      const findMessage = chatMessageList.find(item => item.id === message.id);
      if (!findMessage) {
        console.log('add chat message: ', messageContent);
        chatMessageList.push(message)
        setChatMessageList(chatMessageList)
      }
    }
  } 
  
  const handleInputChatMessageChange = (event) => {
    setNewChatMessage(event.target.value)
  }

  const onChatKeyDown = (event) => {
    if(event.keyCode === 13) {
      handleClickSendChatMessage()
    }
  }

  const handleClickSendChatMessage = () => {
    if (!credential) {
      dispatch(showSignInUpDialog())
      return
    }

    if (!newChatMessage) {
      return
    }
    const newMessage : ChatMessage = {
      id: new Date().getTime().toString(),
      type: 'text',
      content: newChatMessage,
      sender_id: credential?.id || "",
      sender_name: credential?.name || "",
      sender_avatar: credential?.avatarUrl || "",
    }
    const params = {
      action: "sendmessage",
      sequenceid: "sendchat",
      message_type: 1,//1-text,2-file
      message: JSON.stringify(newMessage)
    };
    // console.log("send chat message: ", JSON.stringify(params))
    scrollToBottom();
    sendJsonMessage(params)
  }

  const refreshSendFileStatus = (entity: FileEntity) => {
    setSendFile({
      fileName: entity.fileName,
      filePath: entity.filePath,
      fileType: entity.fileType,
      fileSize: entity.fileSize,
      fileStatus: entity.fileStatus,
      publicUrl: entity.publicUrl
    })
  }

  const handleSelectFile = (entity: FileEntity) => {
    const newMessage: ChatMessage = {
      id: entity.filePath,
      type: "file",
      content: JSON.stringify(entity),
      time: new Date().getTime(),
      sender_id: credential?.id||"",
      sender_name: credential?.name||"",
      sender_avatar: credential?.avatarUrl||"",
      status: FileStatus.UPLOADING,
    }
    setChatMessageList(list => [...list, newMessage]);
    scrollToBottom();
  }

  const handleFileUploaded = (entity: FileEntity) => {
    const newChatList = chatMessageList.map(item => {
      if (item.id === entity.filePath) {
        item.status = FileStatus.UPLOADED;
        item.content = JSON.stringify(entity);
        const params = {
          action: "sendmessage",
          sequenceid: "sendchat",
          message_type: 2,//1-text,2-file
          message: JSON.stringify(item)
        };
        // console.log("send chat message: ", JSON.stringify(params))
        sendJsonMessage(params)
        return item;
      } else {
        return item;
      }
    });
    setChatMessageList(newChatList);
  }

  const scrollToBottom = () => {
    // TODO 
    // messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleWebSocketEnable = (enable: boolean) => {
    setWebSocketEnable(enable)
  }

  const handleCloseLive = () => {
    const newMessage = {
      type: 'text',
      content: CLOSE_LIVE_CONTENT,
      sender_id: credential?.id,
      sender_name: credential?.name,
    }
    const params = {
      action: "sendmessage",
      sequenceid: `close-${broadcastInfo.broadcast_title}`,
      message_type: 1,//1-text,2-photo,3-video,4-mute...
      message: JSON.stringify(newMessage)
    };
    // console.log("send chat message: ", JSON.stringify(params))
    sendJsonMessage(params)

    setLiveStatus(3);
    broadcastInfo.broadcast_status = 3;
    if (broadcastInfo.broadcast_channel) {
      broadcastInfo.broadcast_channel.state = 0;
    }
    setBroadcastInfo(broadcastInfo);
  }

  const shouldDisableChat = (): boolean => {
    return liveStatus !== 2 
      || (liveStatus === 2 && broadcastInfo.broadcast_thirdparty && !broadcastInfo.broadcast_channel?.enable_playback) 
      || (!webSocketEnable && !broadcastInfo.broadcast_purchased);
  }

  const getInputChatFieldStatusHint = () : string=> {
    if (!credential) {
      return "Please sign in to send message";
    } else if (shouldDisableChat()) {
      if (liveStatus === 1) {
        return t('class.live-chat-not-start');
      } else if (liveStatus === 3) {
        return t('class.live-chat-ended')
      } else {
        if (liveStatus === 2 && broadcastInfo.broadcast_thirdparty && !broadcastInfo.broadcast_channel?.enable_playback) {
          return t('class.live-chat-not-start');
        } else if (!webSocketEnable) {
          return t('class.chat-disabled');
        } else {
          return t('class.chat-disabled')
        }
      }
    } else {
      return t('class.input-chat-message');
    }
  }

  return (
    <Box className={classes.rootPage}>
      <Helmet>
        {/*Google Tag Manager*/}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KX6N85S');`}</script>
        {/*End Google Tag Manager*/}
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KX6N85S"
          title='Google Tag Manager'
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      {/*<Banner/>*/}
      <Box className={classes.root}>
        <Box className={classes.backButton}>
          <PageBackButton/>
          <Box hidden>
            <GreyTabs hidden value={tabIndex} onChange={handleTabChange} textColor='secondary' indicatorColor='secondary' className={classes.tab}>
              <GreyTab label="Class Info" />
            </GreyTabs>
          </Box>
        </Box>

        <Box className={classes.body}>
          <Box className={classes.leftPart}>
            <Box>
              <LiveInfo 
                seriesInfo={seriesInfo}
                learnInfo={broadcastInfo} 
                setWebScocketEnable={handleWebSocketEnable} 
                onCloseLive={handleCloseLive}
                liveStatus={liveStatus} 
                chatMessageList={chatMessageList} 
                shouldDisableChat={shouldDisableChat} 
                getInputChatFieldStatusHint={getInputChatFieldStatusHint} 
                handleInputChatMessageChange={handleInputChatMessageChange} 
                newChatMessage={newChatMessage} 
                onChatKeyDown={onChatKeyDown} 
                broadcastInfo={broadcastInfo} 
                refreshSendFileStatus={refreshSendFileStatus}
                />
            </Box>
          </Box>
          {
            !matches && 
            <Box className={classes.rightPart}>
              <Box hidden={liveStatus === 3}>
                <AddToCartComponent
                  purchasedSeparately={seriesInfo?.course_purchased || broadcastInfo.broadcast_purchased} 
                  price={getFinalFees(seriesInfo, broadcastInfo.broadcast_fees)}
                  memberPrice={getFinalMemberFees(seriesInfo, broadcastInfo.broadcast_member_fees)}
                  seriesInfo={seriesInfo}
                />
              </Box>
              <ChatMessageComponent 
                liveStatus={liveStatus} 
                chatMessageList={chatMessageList} 
                shouldDisableChat={shouldDisableChat} 
                getInputChatFieldStatusHint={getInputChatFieldStatusHint} 
                handleInputChatMessageChange={handleInputChatMessageChange} 
                newChatMessage={newChatMessage} 
                onChatKeyDown={onChatKeyDown} 
                broadcastInfo={broadcastInfo} 
                refreshSendFileStatus={refreshSendFileStatus}
              />
            </Box>
          }
        </Box>
       
      </Box>
      <Loader isLoading={loading || loading2 || loading3}/>
    </Box>
  )
}


export interface ChatMessageProps {
  liveStatus: number;
  chatMessageList: ChatMessage[];
  shouldDisableChat: () => boolean;
  getInputChatFieldStatusHint: () => string;
  handleInputChatMessageChange: (e: ChangeEvent<HTMLInputElement>) => void;
  newChatMessage: string;
  onChatKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void;
  broadcastInfo: BroadcastEntity;
  refreshSendFileStatus: (entity: FileEntity) => void;
}

export const ChatMessageComponent: React.FC<ChatMessageProps> = ({liveStatus, chatMessageList, shouldDisableChat, getInputChatFieldStatusHint, handleInputChatMessageChange,newChatMessage, onChatKeyDown, broadcastInfo, refreshSendFileStatus}) => {
  const classes = useStyles(); 
  const messagesEndRef = React.useRef<any>(null);
  const { credential } = useSelector(state => state.app);
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Box className={classes.live_chat__wrapper} hidden={liveStatus === 0 || liveStatus === 1 }>
      <Box className={classes.live_chat__header}>
        <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Chat Messages</Typography>
      </Box>
      <Divider/>
      <Box className={classes.live_chat__bg}>
        <Box style={{height: "100%", overflow: "auto", paddingBottom: "100px", paddingRight: "10px"}}>
          <MeetingChatMessageList chatMessageList={chatMessageList} />
          <div ref={messagesEndRef} />
        </Box>

        <Box className={classes.live_chat__inbox}>
          <TextField
            disabled={shouldDisableChat()}
            id="text-input-chat"
            label={getInputChatFieldStatusHint()}
            multiline
            rows={1}
            variant="standard"
            style={{ flexGrow: 1 }}
            value={newChatMessage}
            onChange={handleInputChatMessageChange}
            onKeyDown={onChatKeyDown}
          />
          {broadcastInfo?.teacher.user_id === credential?.id &&
          <FilePickerButton
            usage={UsageType.LIVE}
            onSendFailed={(message, isError) => {enqueueSnackbar(message, {variant: isError? "error": "info"})}} 
            closeChat={shouldDisableChat()} 
            onFileSelected={refreshSendFileStatus} 
            onFileUploaded={refreshSendFileStatus}/>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default ClassDetail


function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}