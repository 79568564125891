import { configureStore } from '@reduxjs/toolkit'
import app from 'src/app/service/app-slice'
import tokenRes from 'src/app/service/access-slice'
import cart from 'src/app/service/cart-slice'
import school from 'src/app/service/school-slice'
import signinup from 'src/app/service/signinup-slice'
import chat from 'src/app/service/chat-slice'
import categoryState from 'src/app/service/category-slice'
import { demoApi } from 'src/app/service/mock/demo-api'
import { categoryApi } from 'src/app/service/mock/category-api'
import { historyApi } from '../service/mock/history-api'
import { massterClassSeriesApi } from '../service/mock/master-class-api'
import { trendingTeachersApi } from '../service/mock/trending-teachers-api'
import { learnPageApi } from '../service/real/learnpage-slice'
import { watchPageApi } from '../service/real/watch-slice'
import { chatApi } from '../service/real/chat-slice'
import { couponApi } from '../service/real/coupon-slice'
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux'
import { authApi } from '../service/real/auth-api'
import { awsApi } from '../service/real/aws-api'
import { getLearnInfoApi } from '../service/real/coursedetail-slice'
import { signUpApi } from '../service/real/signup-slice'
import { forgetPasswordApi } from '../service/real/forgetpassword-slice'
import { thirdPartyLoginApi } from '../service/real/thirdpartylogin-slice'
import { videoClassroomApi } from '../service/real/videoclassroom-slice'
import { assignmentApi } from '../service/real/assignment-slice'
import { schoolInfoApi } from '../service/real/school-slice'
import { getTeacherInfoApi } from '../service/real/teacher.slice'
import { getSchoolsInfo } from '../service/real/schools-api'
import { paymentApi } from '../service/real/payment.slice'
import { getPurchaseInfoApi } from '../service/real/purchase-api'
import { cartApi } from '../service/real/cart-api'
import { privateLessonApi } from '../service/real/privatelesson-slice'
import { notificationApi } from "../service/real/notification-slice"
import { seriesLessonApi } from '../service/real/serieslesson-slice'
import { bookingApi } from "../service/real/booking-slice";
import { logReportApi } from "../service/real/logreport-slice";
import { meetingBackgroundApi } from "../service/real/meeting-background-slice";

export const toolkitStore = configureStore({
  reducer: {
    app,
    tokenRes,
    cart,
    school,
    signinup,
    chat,
    categoryState,
    [assignmentApi.reducerPath]: assignmentApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [awsApi.reducerPath]: awsApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [demoApi.reducerPath]: demoApi.reducer,
    [forgetPasswordApi.reducerPath]: forgetPasswordApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    [massterClassSeriesApi.reducerPath]: massterClassSeriesApi.reducer,
    [trendingTeachersApi.reducerPath]: trendingTeachersApi.reducer,
    [learnPageApi.reducerPath]: learnPageApi.reducer,
    [watchPageApi.reducerPath]: watchPageApi.reducer,
    [getLearnInfoApi.reducerPath]: getLearnInfoApi.reducer,
    [signUpApi.reducerPath]: signUpApi.reducer,
    [thirdPartyLoginApi.reducerPath]: thirdPartyLoginApi.reducer,
    [videoClassroomApi.reducerPath]: videoClassroomApi.reducer,
    [schoolInfoApi.reducerPath]: schoolInfoApi.reducer,
    [seriesLessonApi.reducerPath]: seriesLessonApi.reducer,
    [getTeacherInfoApi.reducerPath]: getTeacherInfoApi.reducer,
    [getSchoolsInfo.reducerPath]: getSchoolsInfo.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [privateLessonApi.reducerPath]: privateLessonApi.reducer,
    [getPurchaseInfoApi.reducerPath]: getPurchaseInfoApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [logReportApi.reducerPath]: logReportApi.reducer,
    [meetingBackgroundApi.reducerPath]: meetingBackgroundApi.reducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      //https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: {
        ignoredActionPaths: [
          'payload',
          'meta.baseQueryMeta.request',
          'meta.baseQueryMeta.response'
        ],
        ignoredPaths: [
          'veeko.queries', 
          'veeko.mutations', 
          'auth.mutations', 
          'aws.mutations', 
          'school-api.queries', 
          'learn-api.queries', 
          'learn-api.mutations', 
          'learnPageApi.queries', 
          'learnPageApi.mutations', 
          'watchPageApi.queries', 
          'watchPageApi.mutations', 
          'teacher-api.queries', 
          'teacher-api.mutations', 
          'purchase-api.queries', 
          'purchase-api.mutations', 
          'meetingApi.mutations', 
          'meetingApi.queries',  
          'cartApi.mutations', 
          'cartApi.queries', 
          'assignmentApi.mutations' , 
          'assignmentApi.queries', 
          'school-api.mutations' , 
          'aws.queries', 
          'aws.mutations', 
          'paymentApi.mutations', 
          'privateLessonApi.mutations', 
          'privateLessonApi.queries', 
          'thirdPartyLoginApi.mutations',
          'chat-api.mutations',
          'coupon-api.mutations',
          'notificationApi.queries', 
          'notificationApi.mutations',
          'seriesLessonApi.mutations', 
          'seriesLessonApi.queries', 
          'bookingApi.mutations', 
          'bookingApi.queries', 
          'logReportApi.mutations', 
          'logReportApi.queries',
          'meetingBackgroundApi.mutations', 
          'meetingBackgroundApi.queries', 
        ]
      }
    })
      .concat(assignmentApi.middleware)
      .concat(authApi.middleware)
      .concat(awsApi.middleware)
      .concat(cartApi.middleware)
      .concat(chatApi.middleware)
      .concat(couponApi.middleware)
      .concat(logReportApi.middleware)
      .concat(getLearnInfoApi.middleware)
      .concat(forgetPasswordApi.middleware)
      .concat(learnPageApi.middleware)
      .concat(meetingBackgroundApi.middleware)
      .concat(paymentApi.middleware)
      .concat(privateLessonApi.middleware)
      .concat(schoolInfoApi.middleware)
      .concat(getSchoolsInfo.middleware)
      .concat(seriesLessonApi.middleware)
      .concat(signUpApi.middleware)
      .concat(getTeacherInfoApi.middleware)
      .concat(thirdPartyLoginApi.middleware)
      .concat(videoClassroomApi.middleware)
      .concat(watchPageApi.middleware)
      // mock apis
      .concat(categoryApi.middleware)
      .concat(demoApi.middleware)
      .concat(historyApi.middleware)
      .concat(massterClassSeriesApi.middleware)
      .concat(trendingTeachersApi.middleware)
      .concat(getPurchaseInfoApi.middleware)
      .concat(cartApi.middleware)
      .concat(notificationApi.middleware)
      .concat(bookingApi.middleware)
  }
})

export type AppDispatch = typeof toolkitStore.dispatch
export type RootState = ReturnType<typeof toolkitStore.getState>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
