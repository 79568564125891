import React, {useEffect, useState} from 'react'
import { Box, Typography, Divider, Button, Avatar, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { RelatedClassSlider } from 'src/components/slider'
import { TeacherInfo } from './TeacherInfo'
import { useSnackbar } from 'notistack'
import { VideoClassroomEntity, Result, SeriesEntity } from '@dokyo/common'
import { RelatedClassEntity } from '@dokyo/common'
// import { useGetSignedUrlMutation } from 'src/app/service/real/watch-slice'
import { useSelector } from 'src/app/toolkit/store'
import { useDispatch } from 'react-redux'
import { showSignInUpDialog } from 'src/app/service/signinup-slice'
import { PlayerContainer } from 'src/components/playerContainer'
import { useGetSchoolListQuery } from 'src/app/service/real/school-slice'
import MeetingDialog , { MeetingTypeEnum } from 'src/pages/videoClassroom/dialog/MeetingDialog'
import { handleClickCopyLink } from 'src/pages/broadcastLive/LiveInfo'
import { getFinalFees, getFinalMemberFees, PlayerLearnTypeEnum } from 'src/components/playerContainer/PlayerContainer'
import SvgComponent from 'src/components/svgComponent'
import IcoClassroom from 'src/images/class/ico-classroom.png'
import { SetPremiumMemberCourseDialog } from 'src/pages/broadcastLive/setPremiumDialog'
import { isMobileDevice } from 'src/utils/deviceUtil'
import { Theme } from '@mui/system';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AddToCartComponent } from 'src/components/addToCart'
import { appConfig } from 'src/app-config'
import { TAB_VALUES } from 'src/pages/course/Course'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width:"100%",
    textAlign:"left",
  },
  body: {
    display: "flex",
    [theme.breakpoints.down('md')]: {
      flexDirection:"column",
    }
  },
  teacherPageLeftPart: {
    width: "70%",
    paddingRight: "30px",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      paddingRight: "0"
    }
  },
  coursePageLeftPart: {
    width: "100%",
  },
  rightPart: {
    width: "30%",
    paddingRight: "20px",
    [theme.breakpoints.down('md')]:{
      width: "100%",
      paddingRight: "0px",
    }
  },
  infoTitle: {
    display: "flex",
    fontSize: "15px !important",
    color: "#555555 !important",
    marginTop: "5px !important",    
    fontFamily: "ArialMT !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "12px !important",
      marginLeft: "12px !important",
    }
  },
  infoValue: {
    fontSize: "15px !important",
    color: "black",
    fontWeight: "bold",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "12px !important",
    }
  },
  titleBox: {
    display: "flex", 
    alignItems: "baseline",    
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse", 
    }
  },
  copyLinkButton: {
    color: "#000000 !important", 
    fontSize: "14px !important", 
    width: "120px !important",
    height: "40px !important",
    [theme.breakpoints.down('md')]: {
      marginLeft:"auto !important", 
    }
  },
  linkIcon: {
    margin: "0 0 0 5px", 
    maxWidth:"18px", 
    maxHeight:"18px",
  },
  titleText: {
    flexGrow: 1,
    fontWeight: "bold !important", 
    fontSize: "34px !important",
    marginTop: "20px !important", 
    marginBottom: "10px !important", 
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "24px !important",
      marginRight:"auto !important", 
      marginTop: "0 !important", 
      marginLeft: "12px !important",
    }
  }
}))

const SectionTitle = styled(Typography)({
  marginLeft: isMobileDevice()? '18px' : '0px',
  fontSize: isMobileDevice()? '20px' : '30px',
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontFamily: "Arial-BoldMT, Arial"
})

interface MeetingInfoProps {
  isTeacherPage: boolean,
  seriesInfo: SeriesEntity | null,
  meetingList: any[],
  learnInfo: VideoClassroomEntity,
}

const TeacherMeetingInfo: React.FC<MeetingInfoProps> = ({isTeacherPage,meetingList, seriesInfo, learnInfo}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {credential} = useSelector(state => state.app);
  const { enqueueSnackbar } = useSnackbar()

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [finishedList, setFinishedList] = useState<RelatedClassEntity[]>([]);
  const [upcomingList, setUpcomingList] = useState<RelatedClassEntity[]>([]);

  const [joinCount, setJoinCount] = useState(0);
  const [showMeetingDialog, setShowMeetingDialog] = useState(false); 


  const { data: schoolData }= useGetSchoolListQuery({is_all: false})
  const [schoolName, setSchoolName] = useState("")
  const KEY_MEETING_STATE = "meetingState";

  React.useLayoutEffect(() => {
    onResize();
    window && window.addEventListener("resize", onResize);
    return () => {
      window && window.removeEventListener("resize", onResize);
    }
  }, []);

  const onResize = () => {
    if (!showMeetingDialog && sessionStorage.getItem(KEY_MEETING_STATE) === "1") {
      setShowMeetingDialog(true);
    }
  };

  React.useEffect(()=>{
    if (learnInfo && schoolData && !schoolData.isFailed && schoolData.result?.schoolInfoList) {
       const schoolList = schoolData.result?.schoolInfoList;
       if (schoolList && schoolList.length > 0) {
         const findSchool = schoolList.find(item => item.school_id === learnInfo.schoolId);
         if (findSchool) {
           setSchoolName(findSchool.school_name);
         }
       }
     }
  },[schoolData, learnInfo])

  const playerLearnInfo : RelatedClassEntity = {
    id: learnInfo?.meetingId,
    type: 4, //1-course; 2-video broadcast; 3-live broadcast; 4-video classroom
    title: learnInfo?.meetingTitle,
    desc: "",
    icon: learnInfo?.meetingThumbnail,
    videoUrl: "",
    time: learnInfo?.meetingStartTime,
    price: learnInfo?.meetingFees,
    teacherId: learnInfo?.teacherId,
    teacherAvatar: learnInfo?.teacherAvatar,
    teacherName: learnInfo?.teacherName,
    categoryName: learnInfo?.meetingCategoryName,
    status: learnInfo.status,
    purchasedSeperately: learnInfo?.meetingPurchased || false,
  } 

  useEffect(() => {
    if (meetingList) {
      transRelatedClassList(meetingList);
    }
    //eslint-disable-next-line
  }, [meetingList]) 

  const transRelatedClassList = (list: any[]) => {
    if (!list) {
      return;
    }
    setFinishedList([]);
    setUpcomingList([]);
    
    const nowTime = Date.now();
    list.forEach((item) => {
      const classroomTime = item.meeting_start_time ?? item.meeting_created_time;
      const diff = nowTime - classroomTime * 1000;
      const twoHour = 2 * 60 * 60 * 1000;

      const newItem : RelatedClassEntity = {
        id: item.meeting_info.meeting_id,
        icon: item.meeting_thumbnail,
        title: item.meeting_title,
        type: 4,
        time: classroomTime,
        price: item.meeting_fees,
        teacherId: item.teacher?.user_id,
        teacherAvatar: item.teacher?.user_avatar,
        teacherName: item.teacher?.user_name,
        categoryName: item.category?.name,
        purchasedSeperately: learnInfo?.meetingPurchased || false,
        status: item.status,
      } 
      if (item.status === 1 || item.status === 2) {
        setUpcomingList(list => [...list, newItem])
      } else if (item.status === 3) {
        setFinishedList(list => [...list, newItem])
      } else {
        if (diff > twoHour) {
          setFinishedList(list => [...list, newItem])
        } else {
          setUpcomingList(list => [...list, newItem])
        }
      }

    })
  }  

  const handleClickJoin = () => {
    if (!credential) {
      dispatch(showSignInUpDialog())
      return
    }
    setJoinCount(joinCount + 1);
    setShowMeetingDialog(true);
    sessionStorage.setItem(KEY_MEETING_STATE, "1");
  }

  const handleMeetingDialogExit = (message?: string) => {
    setShowMeetingDialog(false);
    sessionStorage.setItem(KEY_MEETING_STATE, "0");
    if (message) {
      enqueueSnackbar(message);
    }
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.body}>
          <Box className={isTeacherPage ? classes.teacherPageLeftPart : classes.coursePageLeftPart}>
          {learnInfo?.meetingId ?
            <Box>
              <PlayerContainer
                learnType={PlayerLearnTypeEnum.LIVE_VIDEO_CLASS}
                courseInfo={seriesInfo}
                learnInfo={playerLearnInfo}
                playUrl={""}
                onClickJoinMeeting={handleClickJoin}
                />

              <Box className={classes.titleBox}>
                <Typography className={classes.titleText}>
                  {learnInfo?.meetingTitle}
                </Typography>
                <Button
                  variant='text'
                  color='info'
                  className={classes.copyLinkButton}
                  style={{textTransform: "none"}}
                  startIcon={<SvgComponent iconName="iconLink" className={classes.linkIcon}/>}
                  onClick={()=>handleClickCopyLink(4, learnInfo?.meetingId)}
                >
                  {t("class.copy-link")}
                </Button>
              </Box>


              <Box className={classes.infoTitle}>
                {t("class.school")}: &nbsp;<Typography className={classes.infoValue}> {schoolName || "Veeko School"} </Typography>
              </Box> 
              <Box className={classes.infoTitle}>
                {t("class.course")}: &nbsp;<Typography className={classes.infoValue}> {learnInfo.meetingSeries?.series_title || seriesInfo?.series_title}</Typography>
              </Box> 
              <Box className={classes.infoTitle}>
              {t("class.category")}: &nbsp;<Typography className={classes.infoValue}> {seriesInfo?.series_category.name || learnInfo?.meetingCategoryName}</Typography>
              </Box>
              {/* <Box className={classes.infoTitle}>
              {t("class.type")}: &nbsp;<Typography className={classes.infoValue}> {t("class.video-classroom")}</Typography>
              </Box> */}
            </Box>
            : 
            <Box>
              <Avatar variant='square' src={IcoClassroom} style={{width: "48px", height: "35px", margin: "100px auto 0"}}/>
              <Typography style={{
                fontSize: "18px",
                color: "black",
                fontFamily: "ArialMT",
                margin: "24px auto 150px",
                width: "fit-content",
              }}>No video class and upcoming video classes</Typography>

              <Divider light style={{margin: "30px 0"}} />
            </Box>
            }
          </Box>
          {isTeacherPage &&
          <Box className={classes.rightPart}>
            <Box style={{marginLeft:"12px", marginTop:"20px"}}>
              <AddToCartComponent
                purchasedSeparately={seriesInfo?.course_purchased || learnInfo.meetingPurchased}
                price={getFinalFees(seriesInfo, learnInfo.meetingFees)}
                memberPrice={getFinalMemberFees(seriesInfo, learnInfo.meetingMemberFees)}
                seriesInfo={seriesInfo}
              />
            </Box>
          </Box>
          }
        </Box>

        <Divider light style={matches? {margin: "15px 0 34px"}:{margin: "34px 0"}} />

        <TeacherInfo 
          teacherId={learnInfo?.teacherId}
          teacherName={learnInfo?.teacherName}
          teacherAvatar={learnInfo?.teacherAvatar}/>
        <Divider light style={{margin: "30px 0"}} />

        <SectionTitle>
        Materials
        </SectionTitle>
        <Box style={{marginBottom: "30px"}}>
          <Link href={`${appConfig.paths.course}?id=${seriesInfo?.series_id}&tabValue=${TAB_VALUES.CLASSROOM_MATERIAL}`}>Click Here</Link> to view materials
        </Box>

        <Box style={{paddingBottom: "30px"}}>
          <SectionTitle>
          {t("class.upcoming")}
          </SectionTitle>
          <Box sx={isMobileDevice() ? {margin: "10px 10px 0 18px"} : {marginTop: "10px"}}>
            <RelatedClassSlider classList={upcomingList} learnType={4}/>
          </Box>
          <Typography color="InfoText" hidden={upcomingList.length !== 0}> None</Typography>
        </Box>


        <Box style={{paddingBottom: "60px"}} hidden={finishedList.length === 0}>
          <SectionTitle>
          {t("class.finished")}
          </SectionTitle>
          <Box sx={isMobileDevice() ? {margin: "10px 10px 0 18px"} : {marginTop: "10px"}}>
            <RelatedClassSlider classList={finishedList} learnType={4}/>
          </Box>
          <Typography color="InfoText" hidden={finishedList.length !== 0}> None</Typography>
        </Box>
      </Box>

      <MeetingDialog
        meetingType={MeetingTypeEnum.LiveVideoClass}
        queryMaterialId={seriesInfo?.series_id||''}
        tryCount={joinCount}
        meetingId={learnInfo?.meetingId}
        secondTeachers={[]}
        teacherId={learnInfo?.teacherId}
        showMeeting={showMeetingDialog} 
        onExitMeeting={handleMeetingDialogExit}/>
      
    </>
  )
}

export default TeacherMeetingInfo