import * as React from 'react'
import { useState} from 'react'
import { Box, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CourseEntity } from '@dokyo/common'
import { CourseInfoSilder } from './CourseInfoSilder'
import { CardTypeEnum, CourseInfoCard } from './card/CourseInfoCard'
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
import { TAB_VALUES } from '../Course'
import SvgComponent from 'src/components/svgComponent'
import { useGetSeriesResourceListMutation } from 'src/app/service/real/coursedetail-slice'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    padding: "40px 0",
    background: '#F9F9F9',
  },
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '0px 50px 0px 50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
  title: {
    marginBottom: '33px',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Avenir-Black, Avenir',
  },  
  card: {
    padding: "0px 5px"
  },
  button: {
    width: "172px !important",
    height: "48px !important",
    background: "#307DCF !important",
    borderRadius: "24px !important",
    margin: "30px auto !important",
    color: "white",
    fontSize: "14px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
  },
})
)
interface Props {
  courseId: string,
  recordedList: CourseEntity[],
  onClickMore: () => void,
}

export const RecordedClass: React.FC<Props> = ({courseId, recordedList, onClickMore}) => {
  const classes=useStyles()
  const navigate = useNavigate();
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [listData, setListData] = useState<ClassroomListCardEntity[]>([]);

  // temp solution - because father page can not get recordedList, so call another API to get
  const [getDataList] = useGetSeriesResourceListMutation()

  React.useEffect(()=> {
    if (courseId) {
      handleSearch();
    }
  }, [courseId])

  const handleSearch = async () => {
    getDataList({
      seriesId: courseId,
      subType: 1,
      pageSize: 20, 
      startKey: ''
    })
    .unwrap()
    .then(dataRes => {
      if (dataRes && !dataRes.isFailed && dataRes.result) {
        const list = dataRes.result.series_course_list;
        const transferList = getClassCardList(list);
        setListData(transferList);
      }
    })
  }
  // end temp

  // React.useEffect(()=> {
  //   if (recordedList) {
  //     const transferList = getClassCardList(recordedList);
  //     setListData(transferList);
  //   }
  // }, [recordedList])

  const getClassCardList = (recordedList: CourseEntity[]): ClassroomListCardEntity[] => {
    const classRoomDataList: ClassroomListCardEntity[] = [] 
    
    recordedList.forEach(learnPage => {
      if (learnPage && learnPage.course_id) {
        classRoomDataList.push({
          id: learnPage.course_id,
          media_image: learnPage.course_thumbnail,
          class: learnPage.category.name,
          content_header: learnPage.course_title,
          time: learnPage.create_time||learnPage.course_created_time,
          type: CardTypeEnum.Recorded,
          teacherId: learnPage.teacher.user_name,
          teacherName: learnPage.teacher.user_id,
          teacherAvatar: learnPage.teacher.user_avatar,
        })
      }
    })
    return classRoomDataList
  }

  const handleClickButton = () => {
    navigate(`${appConfig.paths.course}?id=${courseId}&tabValue=${TAB_VALUES.RECORDED_CLASS}`, {replace:true})
  }

  return (
    <>
    {listData?.length > 0 ?
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.title}>
          Classes
        </Box>
        <CourseInfoSilder arrowTop={65} maxShowNumber={4} itemWidth={350} marginCount={300}>
          {
            listData.map((item => {
              return (
                <Box key={item.id} className={classes.card}>
                  <CourseInfoCard
                    bgTransparent={false}
                    showLabel={false}
                    card={item}
                    enable={true}
                    showTime={false}
                  />
                </Box>
              )
            }))
          }
          </CourseInfoSilder>

          <Button className={classes.button} sx={{textTransform:"none"}} onClick={onClickMore}>
            Explore Class
            <SvgComponent iconName='ico-next-small' style={{width:"8px", height:"13px", marginLeft:"13px"}}/>
          </Button>
        </Box>
      </Box>
      :
      ""
    }
  </>  
  )
}