import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import { SchoolComponent, SchoolEntity } from 'src/app/models/school'


export interface SchoolListResult {
  schoolInfoList: SchoolEntity[],
  start_key: string
}

export interface SchoolComponentList {
  schoolList: SchoolComponent[];
  start_key?: string
}


export const schoolInfoApi = createApi({
  reducerPath: 'school-api',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getSchoolList: builder.query<Result<SchoolListResult>, {
        startKey?: string,
        pageSize?: number,
        forwardOrder?: boolean,
        is_all?: boolean,
        append_oauth?: boolean
      }>({
      query: ({ startKey, pageSize, forwardOrder, is_all, append_oauth }) => ({
        url: `getschoollist`,
        method: 'POST',
        body: { 
          start_key: startKey ?? '',
          page_size: pageSize ?? 10,
          forward_order: forwardOrder ?? false,
          is_all: is_all,
          append_oauth: append_oauth
        }
      }),
      transformResponse: (res: Response<SchoolListResult>) => new Value<SchoolListResult>(res)
    }),

    getSchoolTeacherList:builder.mutation<Result<any>,{
      request_type: number,
      school_id: string,
      teacher_type?: number,
      start_key?: string,
      page_size?: number,
    }>({
      query: ({request_type, teacher_type, school_id, page_size, start_key }) => ({
        url:'getschoolresourcelist',
        method: 'POST',
        body: {
          request_type: request_type,
          school_id: school_id,
          page_size: page_size || 99,
          start_key: start_key,
          teacher_type: teacher_type,
        }
      }),
      transformResponse: (res:Response<any>) => new Value<any>(res)
    }),

    getSchoolInfo: builder.mutation<Result<SchoolEntity>, {
      school_id: string,
    }>({
      query: ({ school_id }) => ({
        url: `requestschoolresource`,
        method: 'POST',
        body: { 
          school_id: school_id,
          request_type: 6
        }
      }),
      transformResponse: (res: Response<SchoolEntity>) => new Value<SchoolEntity>(res)
    }),

    }
  }
})


export const { useGetSchoolListQuery, useGetSchoolTeacherListMutation, useGetSchoolInfoMutation } = schoolInfoApi