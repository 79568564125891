import { FC, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
    useMeetingManager,
    useLocalVideo,
} from 'amazon-chime-sdk-component-library-react';
import {
    useMeetingState,
} from '../../providers/MeetingStateProvider';
import { Loader } from "src/components/loader";
import { useTheme } from '@material-ui/core/styles';
import { useRecordMeetingVideoMutation } from 'src/app/service/real/videoclassroom-slice';
import { MeetingTypeEnum } from 'src/pages/videoClassroom/dialog/MeetingDialog';


const MeetingJoinDetails: FC = () => {
    const meetingManager = useMeetingManager();
    const { isTeacher, setMeetingStarted, onExitMeeting, setInitOpenVideo, meetingRecord, meetingId, recordStart, meetingType } = useMeetingState();
    const [isLoading, setIsLoading] = useState(false);
    const { isVideoEnabled } = useLocalVideo();
    const theme = useTheme();
    const [ recordMeetingVideo ] = useRecordMeetingVideoMutation();

    const handleJoinMeeting = async () => {
        setIsLoading(true);
    
        try {
            await meetingManager.start();
            if (isTeacher && meetingRecord && !recordStart) {
                await recordMeetingVideo({
                    meetingId: meetingId, 
                    meetingType: (meetingType === MeetingTypeEnum.LiveVideoClass) ? "broadcast" : "class",
                    recordType: "teacher",
                })
            }
            setIsLoading(false);
            setMeetingStarted(true);
            setInitOpenVideo(isVideoEnabled);
        } catch (error) {
            setIsLoading(false);
            setMeetingStarted(false);
            console.error((error as Error).message);
        }
    };

    const handleCancel = () => {
        onExitMeeting();
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '> *' : {
                        flexBasis: '50%'
                    },
                    mt: '70px',
                    [theme.breakpoints.down('sm')]: {
                        margin: '20px 0'
                    }
                }}
            >
                <Box
                    sx={{
                        textAlign: 'right',
                        mr: '40px'
                    }}
                >
                    <Button 
                        variant='contained' 
                        onClick={handleJoinMeeting}
                        sx={{
                            background: '#307DCF',
                            textTransform: 'none',
                            borderRadius: '24px'
                        }}
                    >
                        {isTeacher? 'Start': 'Join'} meeting
                    </Button>
                </Box>
                <Box>
                    <Button 
                        variant='outlined' 
                        onClick={handleCancel}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '24px',
                            color: 'white'
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
            <Loader isLoading={isLoading} />
        </>
    );
}

export default MeetingJoinDetails;