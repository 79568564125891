import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material'
import { VictoriaClassCardEntity } from 'src/app/models/victoriaClass'
import { useCallback } from 'react';
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles(() => ({
  imgHover: {
    filter: 'contrast(70%)'
  },
  titleMouseOver: {
    textDecoration: 'underline'
  }
}))

export const VictoriaClassCard2: React.FC<{school: VictoriaClassCardEntity, handleClick: Function,}> = ({school, handleClick}) => {
  
  const classes = useStyles();
 
  const handleClickSchool = useCallback(() => {
    handleClick()
  }, [])

  const [ isMouseOver, setIsMouseOver] = useState<boolean>(false)

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  return (
    <Box
    onMouseOut={handleMouseOut}
    onMouseOver={handleMouseOver}
      sx={{
        cursor: 'pointer',
        borderRadius: '12px'
      }}
      onClick={handleClickSchool}
    >
      <Card sx={{ 
        boxShadow:'0px 0px 0px 0px rgba(0 0 0 / 0)',
      }}>
          <CardMedia
            component="img"
            image={school.media_thumbnail}
            className={clsx({[classes.imgHover]: isMouseOver})}
            sx={{
              width:"280px",
              height:"186px",
              // marginTop:'24px'
              borderRadius:'12px',
            }}
          />
          <CardContent
          sx={{
            padding:'0px'
          }}>
            <Typography component="div"
            className={clsx( {[classes.titleMouseOver]: isMouseOver})}
            style={{
              fontSize: '20px',
              marginTop:'20px',
              letterSpacing: '-0.43px',
              fontFamily: 'ArialMT',
              color:'#000000',
              lineHeight: '21px',
              fontWeight: 'bold'
            }}>
              {school.content_header}
            </Typography>
            <Typography
            style={{
              fontSize: '14px',
              marginTop:'10px',
              width: '280px',
              height:'54px',
              letterSpacing: '-0.34px',
              fontFamily: 'ArialMT',
              color:'#555555',
              lineHeight: '24px',
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',
            }}>
              {school.content_body}
            </Typography>
          </CardContent>
      </Card>
    </Box>
  )
}

