import React from 'react'
import { 
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import defaultAvatar from 'src/images/teacher/defaultAvatar.png'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { getDisplayUserName } from 'src/utils/stringUtil';
import { appConfig, buildTeacherUrl } from 'src/app-config';
import { TAB_VALUES } from 'src/pages/course/Course';
import { TEACHER_TAB_VALUES } from 'src/pages/teacher/teacher';

const useStyles = makeStyles(() => ({
  root: {
    width: 220,
    height: 390,
    cursor: 'pointer'
  },
  avatar: {
    borderRadius: '8px',
  },
  name: {
    width: '220px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  total: {
    color: '#555555',
    
  },
  content: {
    boxShadow: 'none',
  },
  imgHover: {
    filter: 'contrast(70%)'
  },
  nameHover: {
    textDecoration: 'underline'
  },  
}))

interface Props extends CardProps {
  isSeries: boolean;
  card: {
    id: string;
    avatar: string;
    avatarHd: string;
    name: string;
    totalLesson: number;
    signature: string;
    category_tittle?: string;
  }
}



export const TeacherCard: React.FC<Props> = ({ isSeries, card }) => {
  const classes = useStyles();
  const navigate = useNavigate()

  const [ imgSrc, setImgSrc ] = React.useState<string>(!!card.avatar? card.avatar: defaultAvatar)
  const [ isMouseOver, setIsMouseOver] = React.useState<boolean>(false)


  const handleOnImgError = () => {
    setImgSrc(
      defaultAvatar
    )
  }

  const handleClick= (v) => {
    if (isSeries) {
      navigate(`${appConfig.paths.course}?id=${card.id}&tabValue=${TAB_VALUES.COURSE_INFO}`)
    } else {
      const newUrl = buildTeacherUrl(card.id, TEACHER_TAB_VALUES.TEACHER_INFO);
      navigate(newUrl);
    }
  }

  
  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  return (
    <Card
      className={classes.root}
      onClick={handleClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      sx={{
        boxShadow: 'none'
      }}
    >
      <CardMedia
        onError={handleOnImgError} 
        component="img"
        width="220"
        height="287"
        image={imgSrc}
        className={clsx(classes.avatar, {[classes.imgHover]: isMouseOver})}
      />
      <CardContent
       className={classes.content}
       sx={{
        padding: 0,
       }}
      >
        <Typography 
          title={getDisplayUserName(card.name, card.id)}  
          className={
            clsx(
              classes.name, 
              {[classes.nameHover]: isMouseOver}
            )
          }
          sx={{
            margin: '11px 0 4px 0',
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: 'Arial-BoldMT, Arial;',
          }}
        >
         { getDisplayUserName(card.name, card.id)}
        </Typography>
        
        {!isSeries && 
        <Typography 
          className={classes.total}
          sx={{
            fontSize: '12px',
            fontFamily: 'Arial;',
            lineHeight: '16px',
          }}
        >
          {card.totalLesson} Master Lessons
        </Typography>
        }
        <Typography 
          className={classes.total}
          sx={{
            fontSize: '12px',
            fontFamily: 'Arial;',
            lineHeight: '12px',
            marginTop:'10px',
            width: '220px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {card.category_tittle}
        </Typography>
      </CardContent>
    </Card>
  )
}