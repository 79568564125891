import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Avatar, Box, Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { ClassroomListCardEntity } from 'src/app/models/victoriaClass'
// import DateRangeIcon from '@mui/icons-material/DateRange'
import { getDateTimeInWordDescription } from 'src/utils/timeUtil'
import pic_classroom from 'src/images/defaultIcon/pic-classroom.png'
import pic_live from 'src/images/defaultIcon/pic-live.png'
import pic_material from 'src/images/teacher/defaultfile.png'
import SvgComponent from 'src/components/svgComponent'
import clsx from 'clsx'
import { formatFileSize, getDisplayUserName } from 'src/utils/stringUtil'
import IconMember from 'src/images/class/ico-member.png'
import { AVATAR_DEFAULT } from 'src/utils/deviceUtil'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CircularProgressWithLabel, ClassroomMaterialCardEntity } from 'src/components/cards/ClassroomMaterialCard'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { appConfig } from 'src/app-config'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';

const useStyles = makeStyles((theme:Theme) => ({
    group: {
        maxWidth: '500px',
    },
    card: {
    },
    bgTransparent: {
        background: 'transparent !important'
    },
    spanvictoriaveeko: {
        overflowWrap: 'break-word',
        color: 'rgba(0, 0, 0, 1)',
        textAlign: 'left',
        width: '100%',
        fontSize: '18px',
        letterSpacing: '-0.578823447227478px',
        fontFamily: 'Arial-BoldMT',
        lineHeight: '24px',
        height: '48px',
        // height: 'fit-content',
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxOrient: "vertical",
        WebkitBoxOrient: "vertical",
        lineClamp: 2,
        WebkitLineClamp: 2,
    },
    spanvictoriaveeko2: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '16px!important',
        fontSize: '12px !important',
        color: '#555555 !important',
        fontFamily: 'Avenir-Book, Avenir !important',
    },
    label: {
        zIndex: 100,
        width: 56,
        height: 18,
        borderRadius: 5,
        position: 'absolute',
        left: 10,
        top: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: '12px',
        letterSpacing: '-0.19200000166893005px',
        fontFamily: 'ArialMT',
        whiteSpace: 'nowrap',
        lineHeight: '18px',
        textAlign: 'left',
        backgroundColor: '#F20052',
    },
    labelIcon: {
        marginRight: 4
    },
    calendarIcon: {
        marginTop: '2px',
        marginRight: '10px',
        width: "18px",
        height: "17px"
    },
    liveIcon: {
        width: '10px',
        height: '11px'
    },
    titleMouseDown: {
        textDecoration: 'underline'
    },
    imgHover: {
        filter: 'contrast(70%)'
    },
    feeBox: {
        display:'flex',
        alignItems: 'baseline',
        marginTop: '20px',
    },
    feesValue: {
        fontSize: "18px !important",
        color: "#E91535",
        fontWeight: "bold",
        fontFamily: "Arial-BoldMT, Arial !important",
        [theme.breakpoints.down('md')]: {
            justifyContent: "flex-start",
            fontSize: "1.25rem !important",
        }
    },
    memberFeesValue: {
        fontSize: "14px !important",
        color: "#000000",
        fontWeight: "bold",
        fontFamily: "Arial-BoldMT, Arial !important",
        marginLeft: "20px !important",
        [theme.breakpoints.down('md')]: {
            fontSize: "1.125rem !important",
        }
    },
    icoMember: {
        width: "53px !important", 
        height: "16px !important", 
        marginLeft: "5px",
    },
    teacherBox: {
        display: 'flex',
        alignItems: 'center',
        margin: '14px 0',
    },
    teacherAvatar: {
        width: '32px !important',
        height: '32px !important',
    },
    teacherName: {
        marginLeft: '10px',
        color: 'black',
        fontSize: '14px',
        fontFamily: 'Avenir-Medium, Avenir',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    iconChoice: {
        marginRight: "5px",
        width: "18px !important",
        height: "18px !important",
    },
    buttonBox:{
        marginTop: '20px',
        marginRight: '10px',
        display:'flex',
        cursor:"pointer",
        fontSize: '14px',
        fontFamily: 'Avenir-Medium, Avenir',
    },
    cursorPointer: {
        cursor: 'pointer',
    }
}))

interface ListCardProps extends CardProps {
    card: ClassroomListCardEntity,
    showLabel?: boolean,
    bgTransparent?: boolean;
    enable?: boolean;
    showTime?: boolean;
    enableLock?: boolean,
    canDownload?: boolean,
    handleLock?: (id: string, lock: boolean, oldMaterial?: ClassroomMaterialCardEntity) => void,
}

export const enum CardTypeEnum {
    Course = "Course",
    Recorded = "Classes",
    LiveStream = "Live Stream",
    LiveVideoClass = "Live Video Class",
    Material = "Material",
}

export const CourseInfoCard: React.FC<ListCardProps> = ({ card, enable=true, showLabel = true, bgTransparent = false, showTime = true,
     enableLock, canDownload, handleLock, ...props }) => {
    const classes = useStyles()
    const navigate = useNavigate();
    const [progress, setProgress] = React.useState(0);
    const [isDownloading, setIsDownloading] = React.useState(false)
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false)

    let defaultImg = ''

    if (card.type === CardTypeEnum.LiveStream) {
        defaultImg = pic_live
    } else if (card.type === CardTypeEnum.LiveVideoClass) {
        defaultImg = pic_classroom
    } else if (card.type === CardTypeEnum.Material) {
        defaultImg = pic_material
    }

    const Label = () => {
        if (showLabel) {
            if (card.type === CardTypeEnum.LiveVideoClass) {
                return (
                    <Box className={classes.label}>
                        <Box className={classes.labelIcon}>●</Box> {"Video"}
                    </Box>
                )
            } else if (card.type === CardTypeEnum.LiveStream) {
                return (
                    <Box className={classes.label}>
                        <SvgComponent iconName="liveIcon" className={clsx(classes.labelIcon, classes.liveIcon)} /> {"Live"}
                    </Box>
                )
            }
        }
        return (<></>)
    }

    const getTypeText = (card: ClassroomListCardEntity) => {
        if (card.type === CardTypeEnum.Material) {
            return `${formatFileSize(card.time)}, ${card.class}`;
        } else if (card.type === CardTypeEnum.Course) {
            return card.class;
        } else if (card.type === CardTypeEnum.Recorded) {
            return card.class;
        } else {
            return card.type;
        }
    }

    const handleMouseOver = () => {
        setIsMouseOver(true)
    }

    const handleMouseOut = () => {
        setIsMouseOver(false)
    }

    const handleDownload = () => {
        setIsDownloading(true)
        onProgress()
    }

    const onProgress = () => {
        const downloadUrl = card.material?.file_url;
        axios({
            url:downloadUrl,
            responseType:'blob',
            method: 'GET',
            onDownloadProgress:(progressEvent) => {
                let percentComplete = progressEvent.loaded / progressEvent.total;
                setProgress(percentComplete*100)
            }
        })
        .then((res)=>{
            const url=window.URL.createObjectURL(new Blob([res.data],{type:card.class}))
            const link = document.createElement("a");
            link.download = card.content_header;
            link.href = url;
            link.click(); 
            setIsDownloading(false)
        })
        .catch((err)=>{
            console.log(err)
            setIsDownloading(false)
        })
    }
    
    const handleItemClick = () => {
        if (card.type === CardTypeEnum.Course) {
            navigate(appConfig.paths.course + "?id=" + card.id);
        } else if (card.type === CardTypeEnum.LiveStream) {
            navigate(appConfig.paths.liveBroadcast + "?id=" + card.id);
        } else if (card.type === CardTypeEnum.LiveVideoClass) {
            navigate(appConfig.paths.videoClassroom + "?id=" + card.id);
        } else if (card.type === CardTypeEnum.Recorded) {
            navigate(appConfig.paths.classDetail + "?id=" + card.id);
        } else if (card.type === CardTypeEnum.Material) {

        }
    }

    const isFileLockedForClass = ():boolean => {
    if (card.material?.classes_lock !== undefined) {
        return card.material?.classes_lock;
    } else return !!card.material?.lock;
    }
    
    // const { color } =props
    return (
        <Box
            className={classes.group}
            onMouseOut={handleMouseOut}
            onMouseOver={handleMouseOver}
            onClick={handleItemClick}
        >
            <Card
                {...props}
                className={clsx({
                    [classes.bgTransparent]: bgTransparent,
                    [classes.cursorPointer]: card.type !== CardTypeEnum.Material,
                })}
                sx={{ boxShadow: 'none', border: '1px solid #E9E9E9', borderRadius:'12px' }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        paddingTop: '56.25%',
                    }}>
                    <CardMedia
                        component="img"
                        image={card.media_image || defaultImg}
                        sx={{
                            borderRadius: '11px 11px 0 0',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            inset: '0',
                            background: 'white',
                            top: '0'
                        }}
                        className={clsx({ [classes.imgHover]: isMouseOver && enable })}
                    />

                    {isDownloading &&
                    <Box
                        style={{
                            margin: "-100px auto auto auto",
                            textAlign: 'center',
                            color:'rgba(255, 255, 255, 1)'
                        }}
                    >
                    <CircularProgressWithLabel value={progress} style={{color:'rgba(255, 255, 255, 1)'}}/>
                    </Box>
                    }
                </Box>
                

                <CardContent
                    sx={{
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '14px'
                    }}>
                    <Typography
                        className={clsx(classes.spanvictoriaveeko, { [classes.titleMouseDown]: isMouseOver && enable })}
                    >
                        {card.content_header}
                    </Typography>
                    
                    {card.type === CardTypeEnum.Course &&
                    <Box className={classes.feeBox}>
                        <Typography className={classes.feesValue} >{!card.fees || card.fees === 0 ? "Free" : `$${card.fees}`}</Typography>
                        {/* <Typography className={classes.memberFeesValue}> 
                            {!card.memberFees || card.memberFees === 0 ? "Free" : `$${card.memberFees}`}
                        </Typography>
                        <Avatar
                            className={classes.icoMember}
                            variant='square'
                            src={IconMember}/> */}
                    </Box>
                    }

                    {card.type !== CardTypeEnum.Material &&
                    <Box className={classes.teacherBox}>
                        <Avatar src={card.teacherAvatar||AVATAR_DEFAULT} className={classes.teacherAvatar}></Avatar>
                        <Box className={classes.teacherName}>{getDisplayUserName(card.teacherName||"", card.teacherId||"")}</Box>
                    </Box>
                    }

                    {showTime &&
                    <Typography
                        className={classes.spanvictoriaveeko2}
                        sx={{marginBottom: '12px'}}
                    >
                        <SvgComponent iconName="calendar" className={classes.calendarIcon} />
                        {getDateTimeInWordDescription(card.time * 1000)}
                    </Typography>
                    }

                    <Typography
                        className={classes.spanvictoriaveeko2} 
                        sx={{overflow:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    >
                        {getTypeText(card)}
                    </Typography>

                    {card.type === CardTypeEnum.Course &&
                    <Typography className={classes.spanvictoriaveeko2} sx={{marginTop:'10px'}}>
                        {card.videoClassCount + " classes"}
                    </Typography>
                    }

                    <Box sx={{display: 'flex', alignItems: 'left',}}>
                        {enableLock && 
                        <Tooltip title={isFileLockedForClass() ? "Click to unlock" : "Click to lock"}>
                        {isFileLockedForClass() ?
                        <Box className={classes.buttonBox} sx={{color:'black', cursor:'pointer', }}
                            onClick={() => handleLock && handleLock(card.id||'', false, card.material)}>
                            <LockOutlinedIcon 
                                className={classes.iconChoice}
                                sx={{color:'black',  }}/>  Unlock
                        </Box>
                        :
                        <Box className={classes.buttonBox} 
                            sx={{color:'#aaaaaa', cursor:'pointer', }}
                            onClick={() => handleLock && handleLock(card.id||'', true, card.material)}>
                            <LockOpenIcon 
                                className={classes.iconChoice}
                                sx={{color:'#aaaaaa', }}/> Lock
                        </Box>
                        }
                        </Tooltip>}

                        {card.type === CardTypeEnum.Material && canDownload && card.material?.file_url &&
                        <Box className={classes.buttonBox} onClick={handleDownload}>
                            <FileDownloadOutlinedIcon className={classes.iconChoice} /> Download
                        </Box>
                        }
                    </Box>
                </CardContent>
                <Label />
            </Card>
        </Box>
    )
}
