import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'
import { Response, Result, Value } from '@dokyo/common'
import { FuntionTypeEnum } from 'src/pages/videoClassroom/chimeMeeting/MeetingScreen'

export const videoClassroomApi = createApi({
  reducerPath: 'meetingApi',
  baseQuery: baseQueryWithReauth, 
  endpoints(builder) {
    return {
      getMeetingList: builder.query<Result<any>, { 
        userId: string,
        pageSize?: number,
      }>({
        query: ({ userId, pageSize }) => ({
          url: `getmeetinglist`,
          method: 'POST',
          body: { 
              user_id: userId,
              page_size: pageSize ?? 999,
              type: 1,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getAttendeeList: builder.mutation<Result<any>, { 
        meetingId: string
      }>({
        query: ({ meetingId }) => ({
          url: `getmeetinglist`,
          method: 'POST',
          body: { 
              meeting_id: meetingId,
              page_size: 999,
              type: 2,
            }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      joinMeeting: builder.mutation<Result<any>, { 
        meetingId: string
      }>({
        query: ({ meetingId }) => ({
          url: `joinmeeting`,
          method: 'POST',
          body: { 
            meeting_id: meetingId,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      setMeetingFunction: builder.mutation<Result<any>, { 
        meetingId: string, 
        // 1-StartTranscription; 2-EndTranscription;
        // 3-StartCapture; 4-EndCapture;
        // 5-Mute; 6-Unmute;
        // 7-OpenVideo; 8-CloseVideo;
        // 11-cancel forbid share screen; 12-forbid share screen
        type: FuntionTypeEnum,
        language?: string,
        status?: number,
      }>({
        query: ({ meetingId, type, language, status }) => ({
          url: `functionmeeting`,
          method: 'POST',
          body: (type === 1||type === 2) ? {
            meeting_id: meetingId,
            type: type,
            engine: "transcribe",
            language: language
          } : {
            meeting_id: meetingId,
            type: type,
            status
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      recordMeetingVideo: builder.mutation<Result<any>, { 
        meetingId: string,
        meetingType: string,
        recordType: string,
      }>({
        query: ({ meetingId, meetingType, recordType }) => ({
          url: `functionmeeting`,
          method: 'POST',
          body: {
            meeting_id: meetingId,
            type: FuntionTypeEnum.StartRecord,
            meeting_type: meetingType, // "broadcast(live video class) | class(private lesson/series lesson)"
            record_type: recordType, // "all | teacher"
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      setMeetingViewMode: builder.mutation<Result<any>, { 
        meetingId: string, 
        status: number,
      }>({
        query: ({ meetingId, status }) => ({
          url: `functionmeeting`,
          method: 'POST',
          body: {
            meeting_id: meetingId,
            type: 9,
            status: status,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      setToppingStudent: builder.mutation<Result<any>, { 
        meetingId: string, 
        topping_user: string,
      }>({
        query: ({ meetingId, topping_user }) => ({
          url: `functionmeeting`,
          method: 'POST',
          body: {
            meeting_id: meetingId,
            type: 10,
            topping_user: topping_user,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      deleteAttendee: builder.mutation<Result<any>, { 
        meetingId: string, 
        attendeeId: string
      }>({
        query: ({ meetingId, attendeeId }) => ({
          url: `deleteattendee`,
          method: 'POST',
          body: {
            meeting_id: meetingId,
            attendee_id: attendeeId
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      updateAttendeeShareInfo: builder.mutation<Result<any>, {
        meetingId: string, 
        shareAttendeeId: string,
        stopShareAttendeeId: string,
      }>({
        query: ({ meetingId, shareAttendeeId, stopShareAttendeeId }) => ({
          url: `getorupdateattendinfo`,
          method: 'POST',
          body: {
            type: 2, // 1-get; 2-update
            meeting_id: meetingId,
            update_list: [{
              attendee_id: shareAttendeeId,
              share_screen: true
            },{
              attendee_id: stopShareAttendeeId,
              share_screen: false
            }],
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      disableAttendeeShare: builder.mutation<Result<any>, {
        meetingId: string,
        disableAttendeeId: string,
      }>({
        query: ({ meetingId, disableAttendeeId }) => ({
          url: `getorupdateattendinfo`,
          method: 'POST',
          body: {
            type: 2, // 1-get; 2-update
            meeting_id: meetingId,
            update_list: [{
              attendee_id: disableAttendeeId,
              share_screen: false
            }],
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      getAttendeeShareInfo: builder.mutation<Result<any>, { 
        meetingId: string, 
        attendeeId: string
      }>({
        query: ({ meetingId, attendeeId }) => ({
          url: `getorupdateattendinfo`,
          method: 'POST',
          body: {
            type: 1,
            meeting_id: meetingId,
            attendee_id: attendeeId,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),

      closeMeeting: builder.mutation<Result<any>, { 
        meetingId: string,
        isAdmin?: boolean,
        teacherID?: string,
      }>({
        query: ({ meetingId, isAdmin, teacherID }) => ({
          url: `closemeeting`,
          method: 'POST',
          body: isAdmin && teacherID ?  {
            meeting_id: meetingId,
            user_id: teacherID,
          } : {
            meeting_id: meetingId,
          }
        }),
        transformResponse: (res: Response<any>) => new Value(res)
      }),
    }
  }
})

export const { 
  useGetMeetingListQuery, 
  useLazyGetMeetingListQuery,
  useGetAttendeeListMutation, 
  useJoinMeetingMutation,
  useSetMeetingFunctionMutation,
  useRecordMeetingVideoMutation,
  useSetMeetingViewModeMutation,
  useSetToppingStudentMutation,
  useDeleteAttendeeMutation,
  useUpdateAttendeeShareInfoMutation,
  useDisableAttendeeShareMutation,
  useGetAttendeeShareInfoMutation,
  useCloseMeetingMutation,
} = videoClassroomApi
