import {
  Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, MenuItem,
  Select, TextField, Theme, Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles'
import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconSelectTime from 'src/images/teacher/ic_lable_select.png';

const useStyles = makeStyles((theme: Theme) => ({
  lineItemTitle: {
    fontSize: '14px',
    fontFamily: 'Avenir-Black, Avenir !important',
    marginTop: '22px !important',
    color: '#555555 !important'
  },
  buttonContentBox: {
    display: "flex",
    margin: "22px 0"
  },
  contentBox: {
    textAlign: 'left',
    paddingRight: '20px',
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '200px',
    }
  },
  couponSelect: {
    width: '288px',
    height: '40px',
    marginRight: "24px"
  },
  selectButton: {
    color: '#307DCF !important',
    border: '1px solid #307DCF !important',
    '&.Mui-disabled': {
      color: '#DDDDDD !important',
      borderColor: '#EEEEEE !important',
    }
  },
  unselectButton: {
    color: '#000000 !important',
    borderColor: '#DDDDDD !important',
    '&.Mui-disabled': {
      color: '#DDDDDD !important',
      borderColor: '#EEEEEE !important',
    }
  },
  priceText: {
    fontSize: '24px !important',
    fontWeight: '900 !important',
    color: '#F93B57 !important',
    fontFamily: 'Avenir-Black, Avenir !important',
  },
  couponCode: {
    // fontSize: '14px',
    color: "#555555",
    // fontFamily: "ArialMT",
    width: '288px !important',
  },
}))


interface Props {
  couponList: string[],
  price: number, // 美元
  open: boolean,
  onClickPayment: (isCoupon: boolean, coupon?: string) => void;
  onClickClose: () => void;
}

const BookPaymentTypeDialog: React.FC<Props> = ({ couponList, price, open, onClickPayment, onClickClose }) => {
  const classes = useStyles()
  const [isCouponPaymentType, setIsCouponPaymentType] = React.useState(true);
  const [selectCoupon, setselectedCoupon] = React.useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCouponChange = (event) => {
    const currentCoupon = event.target.value
    setselectedCoupon(currentCoupon)
  }

  return (
    <Dialog open={open} maxWidth='lg'>
      <DialogTitle>Payment</DialogTitle>
      <IconButton onClick={() => onClickClose()} style={{ position: "absolute", right: 10, top: 10, zIndex: 10 }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Divider style={{ marginTop: '-20px' }} />
        <Box className={classes.contentBox}>
          <Typography className={classes.lineItemTitle}>Payment Methods</Typography>
          <Box className={classes.buttonContentBox}>
            <Box style={{ position: "relative" }}>
              <Button
                variant='outlined'
                className={isCouponPaymentType ? classes.selectButton : classes.unselectButton}
                sx={{ textTransform: 'none', width: isMobile ? "110px" : "132px", fontFamily: 'Avenir-Black, Avenir', fontWeight: 900, }}
                onClick={() => { setIsCouponPaymentType(true) }}
              >Coupon</Button>
              {isCouponPaymentType && <img alt='selected_time' src={IconSelectTime} style={{ position: "absolute", bottom: 0, right: 0 }} />}
            </Box>
            <Box style={{ position: "relative", left: "24px" }}>
              <Button
                variant='outlined'
                className={!isCouponPaymentType ? classes.selectButton : classes.unselectButton}
                sx={{ textTransform: 'none', width: isMobile ? "110px" : "132px", fontFamily: 'Avenir-Black, Avenir', fontWeight: 900, }}
                onClick={() => { setIsCouponPaymentType(false) }}
              >Stripe</Button>
              {!isCouponPaymentType && <img alt='selected_time' src={IconSelectTime} style={{ position: "absolute", bottom: 0, right: 0 }} />}
            </Box>
          </Box>

          {isCouponPaymentType &&
          <Box style={{display:"flex", margin:"22px 0"}}>
            {couponList?.length > 0 ?
            <Select
              placeholder="Select Coupon"
              variant='outlined'
              className={classes.couponSelect}
              value={selectCoupon}
              onChange={(event) => handleCouponChange(event)}
            >
              {couponList.map((item, index) => (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))}
            </Select>
            :
            <TextField
              size="small"
              placeholder="Input coupon"
              className={classes.couponCode}
              value={selectCoupon}
              onChange={(event) => handleCouponChange(event)}
            />
            }
          </Box>
          }

          {!isCouponPaymentType && <Typography className={classes.priceText}>{`$${price}`}</Typography>}

          <Button
            onClick={() => onClickPayment(isCouponPaymentType, selectCoupon)}
            sx={{
              marginTop: '22px',
              width: '120px',
              height: '48px',
              borderRadius: '24px',
              textTransform: "none",
              background: "#307DCF",
              color: 'white',
              fontFamily: 'ArialMT',
              fontWeight: 'bold',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
            }}
            variant="contained">
            Pay
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default BookPaymentTypeDialog