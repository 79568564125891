import React from 'react';
import { PortalTabs, TabItem } from '../../components/tabs/PortalTabs';
import { MyCourse } from './myCourse';
// import { WishList } from './WishList';
import { MySchedule } from './mySchedule';
import { MyTeachingCourse } from './myTeachingCourse';
import { Box } from '@mui/material';
// import KeepAlive from 'react-activation'
import { getAuthRole } from 'src/utils/auth'
import { MySeriesLesson } from './mySeriesLesson';
import { useSelector } from 'src/app/toolkit/store';
import { buildStudioUrl } from 'src/app-config';
import { useParams } from 'react-router-dom';


export const StudioTabValues = {
  MY_SCHEDULE: 'myschedule',
  PURCHASED_SERIES_LESSON: 'purchasedserieslesson',
  PURCHASED_COURSE: 'purchasedcourse',
  TEACHER_COURSE: 'teachercourse',
}

export const Studio: React.FC = () => {
  const {credential} = useSelector(state => state.app);
  const isTeacher = getAuthRole()()?.isTeacher;
  const { tabName } = useParams();
  const [value, setValue] = React.useState(tabName || StudioTabValues.MY_SCHEDULE);

  const studentTabItems: TabItem[] = [
    {
      name: 'MY SCHEDULE',
      value: StudioTabValues.MY_SCHEDULE,
      fc: MySchedule,
      props: {}
    },
    {
      name: 'MY SERIES',
      value: StudioTabValues.PURCHASED_SERIES_LESSON,
      fc: MySeriesLesson,
      props: {}
    },
    {
      name: 'MY COURSE',
      value: StudioTabValues.PURCHASED_COURSE,
      fc: MyCourse,
      props: {}
    },
    // {
    //   name: 'WISHLIST',
    //   value: '1',
    //   fc: WishList,
    //   props: {}
    // },
  ];

  const teacherTabItems = [
    {
      name: 'MY SCHEDULE',
      value: StudioTabValues.MY_SCHEDULE,
      fc: MySchedule,
      props: {}
    },
    {
      name: 'MY PURCHASED SERIES',
      value: StudioTabValues.PURCHASED_SERIES_LESSON,
      fc: MySeriesLesson,
      props: {}
    }, 
    {
      name: 'TEACHING COURSES',
      value: StudioTabValues.TEACHER_COURSE,
      fc: MyTeachingCourse,
      props: {}
    },
  ]

  const handleTabChange = (newValue: string) => {
    setValue(newValue);
    const newUrl = buildStudioUrl(newValue);
    window.history.pushState({}, "", newUrl);
  }

  return (
    <Box
      sx={{
        background: "#ffffff"
      }}
    >
      <PortalTabs defaultValue={value} tabItems={isTeacher ? teacherTabItems : studentTabItems} onChange={handleTabChange}/>
    </Box>
  )
}

// const Keep = (props) => (
//   <KeepAlive>
//       <Studio {...props} />
//   </KeepAlive>
// )
export default Studio