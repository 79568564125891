import Card, { CardProps } from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import background from 'src/images/topBanner/background.png'
import topbanner1 from 'src/images/topBanner/topbanner1.png'
import { Button } from '@mui/material'
import mouse from 'src/images/topBanner/mouse.png'
import { appConfig } from 'src/app-config'
import { useNavigate } from 'react-router-dom'

interface TopBannerCardProps extends CardProps {
  width: string,
  bar:Bar
}

interface Bar {
  title: string,
  discription: string,
  picture: string,
}

export const TopBannerCard: React.FC<TopBannerCardProps> = ({width,bar, ...props}) => {
  const navigate = useNavigate()
  const onLineLearningclick = () => {
    // navigate to discover page
    navigate(appConfig.paths.discover, { replace: true })
  };
  return (
    <Box
      sx={{
        position: 'relative',
        height: '540px',
        overflow: 'hidden',
        background: `url(${background}) 100% no-repeat`,
        display: 'flex',
        flexDirection: 'column',
        width: width
      }}
    >
      <Box
        sx={{
          height: '540px',
          position: 'absolute',
          left: 0,
          top: 0,
          display: 'flex',
          flexDirection: 'column',
          width: width
        }}
      >
        <Card
          sx={{
            cursor: 'pointer',
            boxShadow: 'none',
            background: 'transparent',
            height: '540px',
            width: width,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardMedia
            component="img"
            image={bar.picture}
            sx={{
              position: 'absolute',
              inset: '0',
              width: '100%',
              height: '100%',
            }}
          />
            <Box
              sx={{
                position: 'absolute',
                inset: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignContent: 'center'
              }}
            >
              {/* <Typography
                sx={{
                  //zIndex: 31,
                  width: '923px',
                  height: '66px',
                  display: 'block',
                  mt: '93px',
                  overflowWrap: 'break-word',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '48px',
                  textTransform: 'uppercase',
                  letterSpacing: '-1.157646894454956px',
                  fontFamily: 'Avenir-Roman',
                  whiteSpace: 'nowrap',
                  lineHeight: '66px',
                  textAlign: 'left',
                  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)'
                }}
              >
                {bar.title}
              </Typography>
              <Typography
                sx={{
                  margin: '18px 0 0 0',
                  width: '350px',
                  height: '90px',
                  display: 'block',
                  overflowWrap: 'break-word',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '18px',
                  letterSpacing: '-0.4341175854206085px',
                  fontFamily: 'ArialMT',
                  lineHeight: '30px',
                  textAlign: 'left',
                  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.7)',
                  //marginLeft: '103px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                  {bar.discription}
              </Typography> */}
              {/* <Box
                sx={{
                  width: '388px',
                  height: '48px',
                  justifyContent: 'space-between',
                  margin: '48px 0 0 0px',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Button
                  style={{
                  height: '48px',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(249, 59, 87, 1)',
                  width: '148px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowWrap: 'break-word',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '16px',
                  letterSpacing: '-0.38588231801986694px',
                  fontFamily: 'ArialMT',
                  whiteSpace: 'nowrap',
                  lineHeight: '16px',
                  textAlign: 'center',
                  textTransform: 'none'
                  }}
                  onClick={onLineLearningclick}
                >
                  Learn More
                </Button>
                <Button
                  style={{
                    height: '48px',
                    borderRadius: '8px',
                    border: `1.2px solid rgba(255, 255, 255, 1)`,
                    width: '198px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    overflowWrap: 'break-word',
                    color: 'rgba(255, 255, 255, 1)',
                    fontSize: '16px',
                    letterSpacing: '-0.38588231801986694px',
                    fontFamily: 'ArialMT',
                    whiteSpace: 'nowrap',
                    lineHeight: '16px',
                    textAlign: 'center',
                    textTransform: 'none'
                  }}
                  startIcon={
                    <img
                      style={{
                        width: '17px',
                        height: '31px',
                        marginRight: '6px'
                      }}
                      alt='Online Learning'
                      src={mouse}
                    />
                  }
                  onClick={onLineLearningclick}
                >
                  Online Learning
                </Button>
              </Box> */}
            </Box>
        </Card>
      </Box>
    </Box>
  )
}
