import * as React from 'react'
import { Box, Button, Theme, Typography } from '@mui/material'
import { TeacherAbout } from './teacherAbout'
import { getDisplayUserName } from 'src/utils/stringUtil';
import picBanner from 'src/images/teacher/pic-banner.png'
import TeacherDiscussion from './teacherDiscussion';
import { PageBackButton } from 'src/components/pageBackButton'
import { BroadcastEntity, Result, SeriesEntity, useQuery } from '@dokyo/common';
import { useGetUserProfileQuery } from 'src/app/service/real/aws-api';
import { getFormatDisplayTime } from 'src/utils/timeUtil';
import { useDispatch } from 'react-redux';
import { useGetSeriesByTeacherQuery, useGetUserUpcomingNowLiveListQuery } from 'src/app/service/real/teacher.slice';
import { TeacherLive } from './teacherLive';
import { RawUser } from 'src/app/models';
import SvgComponent from 'src/components/svgComponent'
import { useTranslation } from 'react-i18next'
import { scrollToAnchor, SecondTab, SecondTabs } from '../course/Course';
import Loader from 'src/components/loader';
import { TeacherSeries } from './teacherSeries';
import { makeStyles } from '@mui/styles'
import { useSearchParam } from 'react-use';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TeacherSchedule from './schedule'
import { appConfig, buildTeacherUrl } from 'src/app-config'
import { Helmet } from 'react-helmet';
import { useSelector } from 'src/app/toolkit/store';
import { useSnackbar } from 'notistack';
import imgManuelaBanner from 'src/images/teacher/banner-manuela.png'
import { useNavigate, useParams } from 'react-router-dom';
import { showChatDialog } from 'src/app/service/chat-slice';
import { showSignInUpDialog } from 'src/app/service/signinup-slice';
import { SeriesLessonList } from './seriesLesson/SeriesLessonList';

const useStyles = makeStyles((theme: Theme) => ({
  icoTopBox: {
    position: "fixed",
    cursor: "pointer !important",
    zIndex: 99,
    background: "#555555 !important",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
    width: "40px !important",
    height: "40px !important",
    borderRadius: "20px !important"
  },
  iconTop: {
    width: "40px", 
    height: "40px",
    padding: "14px 10px 10px 10px",
  },
  teacherInfoBox: {
    width: '100%',
    height: '400px',
    paddingLeft: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      minHeight: '280px',
      height: '280px',
      paddingLeft: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '180px',
      minHeight: '180px',
      paddingLeft: '50px',
    }
  },
  teacherName: {
    color: 'rgba(255, 255, 255, 1)',
    fontSize:'40px !important',
    fontFamily:'Avenir-Heavy, Avenir !important',
    fontWeight:'800 !important',
    [theme.breakpoints.down('md')]: {
      fontSize:'20px !important',
    }
  },
  teacherCategory: {
    marginTop: '16px !important',
    color: 'rgba(255, 255, 255, 1)',
    fontSize:'24px !important',
    lineHeight: '24px !important',
    fontFamily:'Avenir-Heavy, Avenir !important',
    fontWeight:'800 !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
      marginTop: '8px !important',
    }
  },
  teacherDesc: {
    maxWidth: '70% !important',
    marginTop: '16px !important',
    color: 'rgba(255, 255, 255, 1)',
    fontSize:'18px !important',
    lineHeight: '24px !important',
    fontFamily:'Avenir-Heavy, Avenir !important',
    fontWeight:'500 !important',
    overflow: 'hidden',
    lineClamp: 2,
    boxOrient: 'vertical',
    display:'-webkit-box',
    [theme.breakpoints.down('md')]: {
      fontSize:'12px !important',
      marginTop: '8px !important',
      maxWidth: '300px !important',
    }
  },
  teacherButtonBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    [theme.breakpoints.down('md')]: {
      marginTop: '13px !important',
    }
  },
  scheduleButton: {
    width: "148px !important",
    height: "48px !important",
    background: "#307DCF !important",
    borderRadius: "24px !important",
    color: "white !important",
    fontSize: "14px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      width: "125px !important",
      height: "40px !important",
      borderRadius: "20px !important",
      fontSize: "10px !important",
    }
  },
  chatButton: {
    marginLeft: "24px !important",
    width: "128px !important",
    height: "48px !important",
    background: "rgba(0,0,0,0.1) !important",
    borderRadius: "24px !important",
    border: "2px solid #FFFFFF !important",
    color: "white !important",
    fontSize: "14px !important",
    fontFamily: "Arial-BoldMT, Arial !important",
    [theme.breakpoints.down('md')]: {
      width: "100px !important",
      height: "40px !important",
      borderRadius: "20px !important",
      fontSize: "10px !important",
    }
  },
  tabBox: {
    margin: "0 auto",
    display:"flex", 
    overflowX:"scroll",
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
      width: "100vw",
    },
    '& .MuiTabs-flexContainer': {
      padding: '0 20px',
    }
  },
  tabContentBox: {
  }
}))


interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
  }
  
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
      <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      >
      {value === index && (
        <Box>{children}</Box> 
      )}
      </div>
  );
}

export const TEACHER_TAB_VALUES = {
  TEACHER_INFO : 'introduction',
  COURSE: 'courses',
  SERIES: 'series',
  // LIVE_VIDEO_CLASS: 'live-video-class',
  // LIVE_STREAM: 'live-stream',
  SCHEDULE: 'schedule',
  DISCUSSION: 'discussion',
}

export interface LiveInfo {
  liveNow: BroadcastEntity[],
  upcoming: BroadcastEntity[],
  ended: BroadcastEntity[]
}

const Teacher: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const query = useQuery();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { credential } = useSelector(state => state.app);
  const {enqueueSnackbar} = useSnackbar();
  const { id, tabName } = useParams();
  const dataID = id || query.get("id")?.toString();
  const [value, setValue] = React.useState(tabName || TEACHER_TAB_VALUES.TEACHER_INFO);
  // const [latestMeetingID, setLatestMeetingID] = React.useState<string|undefined>()
  const [seriesList, setSeriesList] = React.useState<SeriesEntity[]>([]);
  const [sampleCourse, setSampleCourse] = React.useState<SeriesEntity>();
  // const [courseList, setCourseList] = React.useState<CourseEntity[]>([]);
  // const [liveList, setLiveList] = React.useState<LiveInfo>({
  //   liveNow: [],
  //   upcoming: [],
  //   ended: []
  // });
  // const [meeting_list, setMeetingList] = React.useState<any[]>([]);
  const [teacherProfile, setTeacherProfile] = React.useState<RawUser>();
  // const [seriesInfo, setSeriesInfo] = React.useState<SeriesEntity|null>(null);
  // const [accountProfile, setAccountProfile] = React.useState<RawUser>();

  // const [getSeriesInfo] = useGetSeriesInfoMutation();
  // const {data: liveRes, isLoading: liveLoading} = useGetUserUpcomingNowLiveListQuery({user_id: dataID||"", liveNow: true, upcoming: true, ended: true})
  // const {data: preRecordRes, isLoading: loading2} = useGetQueryPublishedCourseListQuery({teacherId: dataID, pageSize: 20, forwardorder: true})
  // const {data: meetingRes, isLoading: meetingLoading} = useGetQueryMeetingListQuery({user_id: dataID||"", pageSize: 20, forwardorder: true})
  const {data: profileRes, isLoading: userProfileLoading} = useGetUserProfileQuery({userId: dataID||""})
  const {data: seriesRes, isLoading: seriesLoading} = useGetSeriesByTeacherQuery({
    teacher_id: dataID||"",
    page_size: 99,
    forward_order: true,
  })

  React.useEffect(()=> {
    if (seriesRes && !seriesRes.isFailed && seriesRes.result) {
      const seriesData : SeriesEntity[] = seriesRes.result.series_list;
      if (seriesData && seriesData.length > 0) {
        setSeriesList(seriesData);
        if (seriesData?.length > 0) {
          const findItem = seriesData.find(item => !item.series_masterclass);
          setSampleCourse(findItem || seriesData[0]);
        }
      }
    }
  }, [seriesRes])

  // React.useEffect(()=> {
  //   handleUserPreRecordData(preRecordRes);
  // }, [preRecordRes])

  // React.useEffect(()=> {
  //   handleUserLiveData(liveRes);
  // }, [liveRes])

  // React.useEffect(()=> {
  //   handleUserMeetingData(meetingRes);
  // }, [meetingRes])

  React.useEffect(() => {
    if (profileRes && !profileRes.isFailed && profileRes.result) {
      const userProfile = profileRes.result.user_profile;
      setTeacherProfile(userProfile);
    }
  }, [profileRes]);

  // const handleUserPreRecordData = (res: Result<any>|undefined) => {
  //   if (res && !res.isFailed && res.result) {
  //     const list = res.result.course_list;
  //     if (list && list.length > 0) {
  //       setCourseList(list);
  //     }
  //   }
  // }

  // const handleUserLiveData = (res: Result<any>|undefined)=> {
  //   if (res && !res.isFailed && res.result) {
  //     const liveNowList = res.result.live_now;
  //     const upcomingList = res.result.upcoming;
  //     const endedList = res.result.ended;
  //     if (liveNowList?.length > 0 || upcomingList?.length > 0) {
  //       setLiveList({
  //         liveNow: liveNowList,
  //         upcoming: upcomingList,
  //         ended: endedList,
  //       })
  //     }
  //   }
  // }

  const findTimeClosestClassroom = (totalList: any[]) => {
    if (!totalList || totalList.length === 0) {
      return "";
    }
    const meetingList = totalList.filter(item => item.status !== 3);
    if (meetingList.length === 0) {
      return "";
    }
    if (meetingList[0].status === 2) {
      return meetingList[0].meeting_info?.meeting_id;
    }
    const nowTimeSeconds = new Date().getTime() / 1000;
    const meetingTime0 = meetingList[0].meeting_start_time || meetingList[0].meeting_created_time;
    let findTimeInterval = Math.abs(meetingTime0 - nowTimeSeconds);
    let findMeeting = meetingList[0];

    meetingList.forEach(item => {
      const itemTime = item.meeting_start_time || item.meeting_created_time;
      const timeInterval = Math.abs(itemTime - nowTimeSeconds);
      console.log("meeting: "+ item.meeting_title + ", time: ", getFormatDisplayTime(itemTime * 1000) + ", time interval: " + timeInterval)
      if (timeInterval < findTimeInterval) {
        findMeeting = item;
        findTimeInterval = timeInterval;
      }
    })
    return findMeeting
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    const newUrl = buildTeacherUrl(dataID||'', newValue);
    window.history.pushState({}, "", newUrl);
  };
   
  /*
  const handleUserMeetingData = (meetingListRes: Result<any>|undefined) => {
    if (meetingListRes && !meetingListRes.isFailed && meetingListRes.result) {
      if (meetingListRes.result.meeting_list?.length > 0) {
        setMeetingList(meetingListRes.result.meeting_list);
        // find the meeting whose time is closest to the present
        const meeting = findTimeClosestClassroom(meetingListRes.result.meeting_list);
        if (meeting) {
          setLatestMeetingID(meeting.meeting_info?.meeting_id);
          
          if (credential && meeting?.meeting_series?.series_id) {
            getSeriesInfo({
              seriesId: meeting?.meeting_series?.series_id //TODO
            }).unwrap()
            .then(res => {
              if (res && !res.isFailed && res.result) {
                setSeriesInfo(res.result);
              }
            })
  
            getUserProfile({userId: credential.id})
            .unwrap()
            .then(res => {
              if (res && !res.isFailed && res.result) {
                setAccountProfile(res.result.user_profile);
              }
            })
          }
        }
      }
    }
  }
  */

  const handleClickSchedule = () => {
    const teacherUrl = buildTeacherUrl(id||'', TEACHER_TAB_VALUES.SCHEDULE);
    navigate(teacherUrl);
  }

  const handleClickChat = () => {
    if (!credential) {
      dispatch(showSignInUpDialog());
      return;
    }
    dispatch(showChatDialog(dataID));
  }

  return (
    <>
      <Helmet>
        {/*Google Tag Manager*/}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KX6N85S');`}</script>
        {/*End Google Tag Manager*/}
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KX6N85S"
          title='Google Tag Manager'
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      {/* This is used for scroll to top */}
      <Box className={classes.icoTopBox} onClick={()=>{scrollToAnchor("page-top")}}
        sx={matches? {right:"20px", bottom:"20px"}:{right: "60px", bottom: "60px",}}>
        <SvgComponent iconName="icon-scroll-top" className={classes.iconTop}/>
      </Box>
      
      <Box 
        style={{ background: 'rgb(255, 255, 255)', }}>
        
        <Box className={classes.teacherInfoBox} sx={dataID === "ManuelaSadovnik" ? {backgroundImage: `url(${imgManuelaBanner})`} : {backgroundImage: `url(${teacherProfile?.user_avatar_back_up?.user_banner||picBanner})`}}>
          <Typography className={classes.teacherName} >
            {getDisplayUserName(teacherProfile?.user_name||'', dataID||'')}
          </Typography>
          <Typography className={classes.teacherCategory} >
            {dataID === "ManuelaSadovnik" ? "Fine Arts" : ""}
          </Typography>
          <Typography className={classes.teacherDesc} >
          {dataID === "ManuelaSadovnik" ? "Enjoy learning with fun and hands-on activities." : (teacherProfile?.personalized_signature || "")}
          </Typography>
          <Box className={classes.teacherButtonBox}>
            <Button 
              className={classes.scheduleButton} 
              sx={{textTransform:"none"}}
              onClick={handleClickSchedule}>
              {dataID === credential?.id ? "Schedule" : "Book a Class"}
              <SvgComponent iconName='ico-next-small' style={{width:"8px", height:"13px", marginLeft: "10px"}}/>
            </Button>
            <Button 
              className={classes.chatButton} 
              sx={{textTransform:"none"}}
              onClick={handleClickChat}>
              <SvgComponent iconName='ico-chat-white' style={{width:"18px", height:"18px", marginRight: "10px"}}/>
              Chat
            </Button>
          </Box>
        </Box>

        <Box 
          sx={{
            display:'flex',
            alignItems:'center',
            marginTop:'10px',
          }}
          style={matches?{flexDirection:"column"}:{flexDirection:"row"}}
        >
          <Box sx={matches?{marginBottom:"20px",marginLeft:"12px",marginRight: "auto" }:{marginLeft:"25px", }}>
            <PageBackButton />
          </Box>
          <Box className={classes.tabBox}>
            <SecondTabs value={value} onChange={handleChange} TabIndicatorProps={{hidden:true}}>
              <SecondTab label="Teacher Info"  value={TEACHER_TAB_VALUES.TEACHER_INFO}/>
              <SecondTab label="Courses"  value={TEACHER_TAB_VALUES.COURSE}/>
              <SecondTab label="Series"  value={TEACHER_TAB_VALUES.SERIES}/>
              {/* {latestMeetingID && <SecondTab label="Live Video Class" value={TEACHER_TAB_VALUES.LIVE_VIDEO_CLASS}/>} */}
              {/* {(liveList.upcoming?.length !== 0 || liveList.liveNow?.length !== 0) &&
              <SecondTab label="Live Stream" value={TEACHER_TAB_VALUES.LIVE_STREAM}/>
              } */}
              <SecondTab label="Class Schedule" value={TEACHER_TAB_VALUES.SCHEDULE}/>
              <SecondTab label="Discussion" value={TEACHER_TAB_VALUES.DISCUSSION}/>
            </SecondTabs>
          </Box>
        </Box>
        <Box className={classes.tabContentBox}>
          <TabPanel value={value} index={TEACHER_TAB_VALUES.TEACHER_INFO}>
            <TeacherAbout teacherProfile={teacherProfile} sampleCourse={sampleCourse} />
          </TabPanel>
        
          <TabPanel value={value} index={TEACHER_TAB_VALUES.COURSE}>
            <TeacherSeries seriesList={seriesList} loading={seriesLoading} teacherID={teacherProfile?.user_id || ''} />
          </TabPanel>

          <TabPanel value={value} index={TEACHER_TAB_VALUES.SERIES}>
            <SeriesLessonList teacherId={dataID||""} />
          </TabPanel>
          
          {/* {latestMeetingID &&
          <TabPanel value={value} index={TEACHER_TAB_VALUES.LIVE_VIDEO_CLASS}>
            <Classroom 
              isTeacherPage={true} 
              membershipState={membershipState} 
              videoId={latestMeetingID||""} 
              paramSeriesInfo={seriesInfo} 
              meetingList={meeting_list}/>
          </TabPanel>
          } */}

          {/* {(liveList.upcoming?.length !== 0 || liveList.liveNow?.length !== 0) &&
          <TabPanel value={value} index={TEACHER_TAB_VALUES.LIVE_STREAM}>
            <TeacherLive liveInfo={liveList} />
          </TabPanel>
          } */}

          <TabPanel value={value} index={TEACHER_TAB_VALUES.SCHEDULE}>
            <TeacherSchedule teacherId={dataID || teacherProfile?.user_id || '' } teacherName={teacherProfile?.user_name ||''} />
          </TabPanel>
          
          <TabPanel value={value} index={TEACHER_TAB_VALUES.DISCUSSION}>
            <TeacherDiscussion resourceId={dataID || teacherProfile?.user_id || ''} />
          </TabPanel>
        </Box>
      </Box>

      <Loader isLoading={userProfileLoading }/>
    </>
  )
}

export default Teacher;
