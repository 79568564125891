
import { Box, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getDisplayUserName } from 'src/utils/stringUtil'
import { LearnInfoTeacher } from '@dokyo/common'
import defaultUserAvatar from 'src/images/class/ic-user-avatar-default.png'

const useStyles = makeStyles(() => ({
  teacherContainer: {
    display: "flex"
  },
  avatar: {
    width: "96px !important",
    height: "96px !important"
  },
  userInfoRightBox: {
    paddingLeft: "15px"
  },
  teacherName: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "Arial-BoldMT, Arial",
    marginTop: "10px",
    textAlign: "left",
  },
  teacherIntro: {
    fontSize: "14px",
    color: "#555555",
    fontFamily: "ArialMT",
    marginTop: "10px",
    textAlign: "left",
  },
  btnExplore: {
    background: "#F93B57 !important",
    borderRadius: "8px !important",
    color: "white !important",
    marginTop: "18px !important",
    width: "148px !important",
    height: "48px !important",
    fontSize: "16px !important",
    fontFamily: "ArialMT !important"
  },

}))

export const TeacherInfo: React.FC<LearnInfoTeacher> = ({teacherId, teacherName, teacherAvatar, teacherSignature, teacherGender, teacherAvatar_hd}) => { 
  const classes = useStyles();

  return (
    <>
      <Box className={classes.teacherContainer}>
        <Avatar className={classes.avatar} src={teacherAvatar || defaultUserAvatar}/>
        <Box className={classes.userInfoRightBox}>
          <Box className={classes.teacherName}>{getDisplayUserName(teacherName, teacherId)}</Box>
          <Box className={classes.teacherIntro}>{teacherSignature || "No introduction"}</Box>
        </Box>
      </Box>
    </>
  )
}

