import { createApi } from '@reduxjs/toolkit/query/react'
import { List } from '@dokyo/common'
import { baseQueryWithReauth } from 'src/app/toolkit/helper'

export interface Category {
  name: String
}

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getCategory: builder.query<Category[], String | null>({
        query: (text?: String | null) => ({
          url: `category`
        }),
        transformResponse: (res: List<Category>) => res.items
      })
    }
  }
})

export const { useGetCategoryQuery } = categoryApi
