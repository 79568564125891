import React from "react";
import { makeStyles } from '@mui/styles'
import { Box, Theme } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

import Icon1 from 'src/images/teacherinfo/ico.png'
import Icon2 from 'src/images/teacherinfo/ico2.png'
import Icon3 from 'src/images/teacherinfo/ico3.png'


const useStyles = makeStyles((theme: Theme) => ({
    rootBox: {
    },
    listBox: {
        margin:"91px 20px",
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            margin: "8px 0"
        },
    },
    labels: {
        background: "#FFFFFF",
        boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.05)",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "24px 32px 30px 24px",
        margin: "0px 20px",
        [theme.breakpoints.down('md')]: {
            fontSize: "14px",
            padding: "14px 22px 20px 14px",
            margin: "5px 0"
        },
    },
    textStyle: {
        width: "190px",
        height: "60px",
        fontFamily: "Avenir-Black, Avenir",
        fontWeight: "900",
        color: "#000000",
        lineHeight: "30px",
        marginLeft: "26px"
    }
}))


const Introduction: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const listData = [
        {
            id: '1',
            image: Icon1,
            content: 'Everyone is welcome for ages 6-10'
        },
        {
            id: '2',
            image: Icon2,
            content: 'Artistic activities every lesson'
        },
        {
            id: '3',
            image: Icon3,
            content: 'Build up an interesting portfolio'
        }
    ]
    return (
        <Box className={classes.rootBox}>
            <Box className={classes.listBox}>
                {listData.map(item => (
                    <Box key={item.id} className={classes.labels}>
                        <img src={item.image} />
                        <Box className={classes.textStyle}>{item.content}</Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
export default Introduction