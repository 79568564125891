import React, { useState, useEffect, useMemo } from 'react'
//import { Banner } from "src/components/banner"
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import { MenuItem, MENU_TYPE_ORDER, CourseMenu, MenuGroupItem } from 'src/components/menu'
import { useGetSchoolListQuery } from 'src/app/service/real/school-slice'
// import { CourseListBySort } from './CourseListBySort'
// import { CourseListBySchoolId } from './CourseListBySchoolId'
// import { CourseListByCategoryId } from './CourseListByCategoryId'
import { useTranslation } from 'react-i18next';
import { getCategoryJson, generateCategoryList } from 'src/utils/categoryUtil'
import { CategoryItem } from 'src/app/models/category'

import { ListPagination } from 'src/components/pagination'
import { BroadcastList } from './broadcastList'
import { VideoClassroomList } from './videoClassroomList2'
import { TeacherListBySort, TeacherListByCategoryId, TeacherListBySchoolId } from '.'
import KeepAlive, { useAliveController } from 'react-activation'
import { useSearchParam } from 'react-use';
import { useParams } from 'react-router-dom';   
import { buildDiscoverUrl } from 'src/app-config';
import { Theme } from "@mui/system";
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    minHeight: 'calc(100vh - 369px)',
    padding: '34px 0 0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '18px',
    },
  },
  body: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
  },
  courseList: {
    flex: '1 0 300px',
    display: 'flex',
    flexWrap: 'wrap',
    
  },
  courseMenu: {
    flex: '0 0 320px',
    [theme.breakpoints.down('sm')]: {
      flex: "0"
    },
  },
  list: {
    width: 'calc(100% - 320px)',
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    },
  }
}))

const showCategoryList = ["Bel Canto",
"Violin",
"Viola",
"Cello",
"Double Bass",
"Guitar",
"Piano",
"Flute",
"Clarinet",
"Oboe",
"Music Theory"];


const SectionTitle = styled(Typography)({
  fontSize: '30px',
  fontWeight: 'bold',
  paddingBottom: '40px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const menuOrderItems = [
  'All Courses',
  'Trending',
  'Latest'
]

export const MENU_TYPE_LIVE = 'live';
export const MENU_TYPE_SCHOOL = 'school';
export const MENU_TYPE_CATEGORY = 'category';

const Discover: React.FC = () => {
  // console.log('Discover View', count);
  const { t } = useTranslation()
  const classes = useStyles();

  const {type, id} = useParams();
  const [searchType, setSearchType] = React.useState<string>('');
  const [searchId, setSearchId] = React.useState<string>('');

  const [hasMore, setHasMore] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalCount = 0;
  const [startKeyArray, setStartKeyArray] = React.useState(["", ""]);
  const [maxPage, setMaxPage] = React.useState(1)
  const [isShowPagination, setIsShowPagination] = React.useState<Boolean>(false)
  const [allCategoryList, setAllCategoryList] = React.useState<CategoryItem[]>([])

  useEffect(() => {
    getCategoryJson(true)
    .then(res => {
      const list = generateCategoryList(res, false)();
      setAllCategoryList(list);
    });
  },[])

  useEffect(() => {
    setSearchId(id ?? '');
    setSearchType(type ?? '');
  }, [type, id]);

  useEffect(() => {
  }, [searchType, searchId])

  const [menuChoice, setMenuChoice] = useState<MenuItem>()

  const handleOnClickPrev = () => {
    setCurrentPage(currentPage - 1)
  }
  const handleOnClickNext = () => {
    setCurrentPage(currentPage + 1)
  }

  const handleOnClickPage = (page) => {
    setCurrentPage(page)
  }

  const handleOnDataLoad = (startKey: string) => {
    
    startKeyArray[currentPage + 1] = startKey

    Promise.resolve().then(() => {
      setHasMore(startKey !== "")
      setIsShowPagination(true)
      if (startKey && currentPage + 1 > maxPage) {
        setMaxPage(currentPage + 1)
      }
    })
  }


  useEffect(() => {
    setStartKeyArray(['',''])
    setCurrentPage(1)
    setHasMore(false)
    setMaxPage(1)
    setIsShowPagination(false)
  }, [menuChoice])


  const { data: schoolData } = useGetSchoolListQuery({});


  const menuList = useMemo(() => {
    const menuListBase: MenuGroupItem[] = [
      {
        title: t("discover.menu.type-classes"),
        type: MENU_TYPE_LIVE,
        items: [{
          name: t("discover.menu.live"),
          id: '1'
        },{
          name: t("discover.menu.video"),
          id: '2'
        }]
      },
      // {
      //   title: t("discover.menu.type-schools"),
      //   type: MENU_TYPE_SCHOOL,
      //   items: []
      // },
      // {
      //   title: t("discover.menu.type-categories"),
      //   type: MENU_TYPE_CATEGORY,
      //   items: []
      // }
    ];
    // allCategoryList.forEach(category => {
    //   if (showCategoryList.indexOf(category.name) !== -1)
    //     menuListBase[1].items.push({
    //     name: category.name,
    //     id: category.id
    //     })
    //   }
    // )
    // if (schoolData && !schoolData.isFailed && schoolData.result?.schoolInfoList) {
      
    //   schoolData.result.schoolInfoList.forEach(item =>{
    //     menuListBase[1].items.push({
    //       name: item.school_name,
    //       id: item.school_id
    //     })
    //   })
    // }
    return menuListBase;
  }, [schoolData, allCategoryList])


  useEffect(() => {
    const newMenuChoice = {} as MenuItem;

      if (searchType && searchId) {
        if (searchType === MENU_TYPE_SCHOOL && menuList[1].items.length === 0) {
          return;
        }
        newMenuChoice.type = searchType;
        if (MENU_TYPE_ORDER === searchType) {
          newMenuChoice.name = menuOrderItems[searchId];
        } else {
          newMenuChoice.id = searchId;
          for (let i = 0; i < menuList.length; i++) {
            if (newMenuChoice.name) {
              break;
            }
            const menu = menuList[i];
            if (searchType !== menu.type) {
              continue;
            }
            for (let j = 0; j < menu.items.length; j++) {
              if (searchId === menu.items[j].id) {
                newMenuChoice.name = menu.items[i].name;
                break;
              }
            }

          }
        }
        if (!newMenuChoice.name) {
          newMenuChoice.type = MENU_TYPE_ORDER;
          newMenuChoice.name = menuOrderItems[0];
        }
      } else {
        newMenuChoice.type = MENU_TYPE_ORDER;
        newMenuChoice.name = menuOrderItems[0];
      }
      if (newMenuChoice.name !== menuChoice?.name || newMenuChoice.type !== menuChoice?.type || newMenuChoice.id !== menuChoice?.id) {
        setMenuChoice(newMenuChoice);
      }
  }, [menuList, searchType, searchId]);

  return (
    <>
      <Box className={classes.root}>
        {
          menuChoice && 
          (
            <>
            <SectionTitle>
              {menuChoice.name}
            </SectionTitle>
            <div className={classes.body}>
                <Box className={classes.courseMenu}>
                  <CourseMenu menuAction={menuChoice} setMenuAction={setMenuChoice} menuList={menuList} menuOrderItems={menuOrderItems}  buildUrl={buildDiscoverUrl}
                    defaultMenuShow={true}
                  />
                </Box>
                <Box className={classes.list}>
                {
                  menuChoice.type === MENU_TYPE_ORDER &&
                  <TeacherListBySort startKey={startKeyArray[currentPage]} onDataLoad={handleOnDataLoad} sortName={menuChoice.name} />
                } 
                { menuChoice.type === MENU_TYPE_LIVE && menuChoice.id === '1' && <BroadcastList /> }
                { menuChoice.type === MENU_TYPE_LIVE && menuChoice.id === '2' && <VideoClassroomList /> }
                {
                  menuChoice.type !== MENU_TYPE_LIVE && isShowPagination &&
                  <Box>
                    <ListPagination
                      currentPage={currentPage} 
                      hasMore={hasMore} 
                      rowsPerPage={5}
                      maxPage={maxPage}
                      totalCount={totalCount}
                      onClickPage={handleOnClickPage}
                      onClickPrev={handleOnClickPrev} 
                      onClickNext={handleOnClickNext}
                    />
                  </Box>
                }
                </Box>
              </div>
            </>
          )
        }
      </Box>
    </>
  )
}


 const Keep = (props) => {
  const refreshDate = useSearchParam('refresh');

  const { refresh } = useAliveController()
  
  useEffect(() => {
    if (refreshDate) {
      refresh("Discover");
    }
  }, [])

  return (
    <KeepAlive name="Discover">
        <Discover {...props} />
    </KeepAlive>
  )
}
export default Keep