
import * as React from 'react';
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ReactPlayer from 'react-player';

interface PropsForm {
  showDialog: boolean;
  videoUrl: string;
  onClose: () => void;
}

const PreviewVideo: React.FC<PropsForm> = (props) => {
  const { showDialog, onClose, videoUrl } = props;
  
  return (
    <>
      <Dialog
        // PaperProps={{style: { backgroundColor: '#252525', boxShadow: 'none'}}}
        // BackdropProps={{style: {backgroundColor: '#ffffffcc'}}}
        maxWidth="lg"
        open={showDialog}
      >
        <DialogContent style={{fontSize:'16px'}}>
          
          <ReactPlayer
            url={videoUrl}
            playing={true}
            muted={false} 
            width={"100%"}
            height={500}
            controls
            // Disable download button
            config={{ file: { attributes: { controlsList: 'nodownload' }}}}
            // Disable right click
            onContextMenu={e => e.preventDefault()}
            playsinline={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={{color:'black', textTransform:'none'}}>Close</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default PreviewVideo;