import React from 'react'
import Slider from 'react-slick'
import { Feedback } from 'src/app/models/feedback'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { FeedbackCard1 } from 'src/components/cards'
import feedback2 from 'src/images/home/feedback/2.png'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import SliderPagination from '../slider_pagination'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  masterCardSlider: {
    width: '100%',
    margin: 'auto',
    marginLeft: '6px',
    '& .slick-arrow': {
      zIndex: '1',
      '&:before': {
        fontSize: '60px',
        color: 'black',
        content: "''"
      },
      margin: "0 35px 0 35px",
    },

    '& .slick-list': {
      //margin: '0 -12px'
    },
    background: `80% 0% url(${feedback2}) no-repeat`,
    height: '414px',
  },
  slider__content: {
    margin: 'auto'
  },

  card: {},
  root: {
    position: 'relative',
    display:'flex',
    justifyContent:'center',
    width: 'calc(100% - 50px)',
  },
  background:{
    display: 'flex',
    justifyContent: 'center'
  },
  img: {
    height: '500px',
    marginLeft: '76px'
  },
  slide: {
    minHeight: 100,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
}))

interface TopBannerSliderprops {
  dots:number,
  feedbackSeries:Feedback[]
}
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const FeedbackSlider1:React.FC<TopBannerSliderprops> = ({ feedbackSeries, dots,...props }) => {
  const classes = useStyles()
  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon style={{ color: 'black', width: '70%', height: '70%' }} />
      </div>
    )
  }
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          width: '40px',
          height: '40px',
          background: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: 'black',
            width: '70%',
            height: '70%',
            transform: 'rotate(180deg)'
          }}
        />
      </div>
    )
  }

  const settings = {
    // dots: true,
    infinite: false,
    // swipeToSlide: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  let isSwiping = false
  function updateSwipeState(state) {
    isSwiping = state
  }

  interface ExcellentClassId {
    id: string
  }

  function handleClick(id: string) {
    if (isSwiping) {
      return
    }
    // do something onClick
    // navigate('/watch?v=' + id)
  }

  const [index,SetIndex] = React.useState(0)

  const handleChangeIndex = (index:number) => {
    SetIndex(index)
  };

  return (
    // <div className={classes.masterCardSlider}>
    //   <div className={classes.slider__content}>
    //     <Slider
    //       {...settings}
    //       afterChange={() => updateSwipeState(false)}
    //       onSwipe={() => updateSwipeState(true)}
    //     >
    //       {feedbackSeries?.map((v, i) => (
    //         <div>
    //           <FeedbackCard
    //             key={v.id}
    //             feedback={v}
    //             className={classes.card}
    //             onClick={() => handleClick(v.id)}
    //           />
    //         </div>
    //       ))}
    //     </Slider>
    //   </div>
    // </div>
     <div className={classes.root}>
     <AutoPlaySwipeableViews
       index={index}
       onChangeIndex={handleChangeIndex}
       enableMouseEvents
       interval={5000000}
     >
        {feedbackSeries?.map((v, i) => (
            <div className={classes.background} key={i}>
              <FeedbackCard1
                key={v.id}
                feedback={v}
                className={classes.card}
                onClick={() => handleClick(v.id || '')}
              />
              <img className={classes.img} src={feedback2} />
            </div>
          ))}
     </AutoPlaySwipeableViews>
     {
       dots > 1 && <SliderPagination dots={dots} index={index} onChangeIndex={handleChangeIndex} />
     }
   </div>
  )
}
