import { ClassroomMaterialCardEntity } from "src/components/cards/ClassroomMaterialCard"
import { CardTypeEnum } from "src/pages/course/courseInfo/card/CourseInfoCard"

export interface VictoriaClassCardEntity {
  media_image: string
  media_thumbnail?: string
  content_header: string
  content_subheader?:string
  content_body: string,
  content_body2?:string,
  id?: string,
  type?: string,
  card_about?: string,
  school_logo?: string,
  school_key?: string,
}

export interface ClassroomListCardEntity {
  media_image: string
  class: string
  content_header: string
  time: number
  id?: string
  teacherId?: string
  type?: CardTypeEnum
  teacherName?: string;
  teacherAvatar?: string;
  fees?: number;
  memberFees?: number;
  videoClassCount?: number;
  materialType?: MaterialTypeEnum;
  material?: ClassroomMaterialCardEntity,
}

export enum MaterialTypeEnum {
  Course = 0,
  SeriesLesson = 1,
  SeriesLessonClass = 2,
  PrivateLesson = 3,
  PrivateLessonClass = 4,
}
export interface SchoolUserPostCardEntity {
  id?:string,
  user_id?:string
  avator: string
  user_name: string
  time: string
  post_content: string
  post_content_meida? : string
  like: number
  comments: number
  status?:boolean,
  fromyou?:boolean
}