import { Box, Theme } from '@mui/material';
import React from 'react'
import { CommentPage } from 'src/components/comment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
interface DiscussionProps {
  resourceId: string;
}

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    width: "90%",
    maxWidth:'1400px',
    margin:'0 auto',
    textAlign: 'center',
    padding: '50px',
    [theme.breakpoints.down('sm')]:{
      maxWidth: '100%',
      padding: '0px 10px 0px 10px',
    }
  },
}))

const TeacherDiscussion: React.FC<DiscussionProps> = ({resourceId}) => {
  const REQUEST_TYPE_CLASS = 3;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  
  return (
    <Box className={classes.container}
      sx={isMobile?{margin: "50px auto",}:{}}>
      <Box style={{textAlign:"left"}}>
        <CommentPage videoId={resourceId} requestType={REQUEST_TYPE_CLASS}/>
      </Box>
    </Box>
  )
}

export default TeacherDiscussion